import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'

type Props = {
  percent: number
}

export const ProgressBar: React.VFC<Props> = ({ percent }) => {
  return (
    <Wrapper>
      <Filler percent={percent} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  border-radius: 4px;
  background: ${theme.color.gray[3]};
`
const Filler = styled.div<{ percent: number }>`
  ${Wrapper} && {
    height: 100%;
    ${({ percent }) =>
      css`
        width: ${percent}%;
      `}
  }
  transition: width 0.2s ease-in;
  background: ${theme.color.green[4]};
  border-radius: 4px;
`
