import { useMutation, useQueryClient } from 'react-query'
import {
  DeleteApplicantCsvFormatRequestValue,
  useRequestDeleteApplicantCsvFormat,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteApplicantCsvFormat() {
  const queryClient = useQueryClient()
  const { requestDeleteApplicantCsvFormat } =
    useRequestDeleteApplicantCsvFormat()

  return useMutation(
    async (value: DeleteApplicantCsvFormatRequestValue) => {
      await requestDeleteApplicantCsvFormat(value)
    },
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicantCsvFormats)
        queryClient.invalidateQueries([QUERY_KEY.applicantCsvFormat, req.guid])
      },
    }
  )
}
