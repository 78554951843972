import { useMutation, useQueryClient } from 'react-query'
import {
  RecInterviewTemplateInputValue,
  useRequestUpdateRecInterviewTemplate,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateRecInterviewTemplate(guid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateRecInterviewTemplate } =
    useRequestUpdateRecInterviewTemplate()

  return useMutation(
    (value: RecInterviewTemplateInputValue) =>
      requestUpdateRecInterviewTemplate(guid, value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.allRecInterviewTemplates)
        queryClient.invalidateQueries([QUERY_KEY.recInterviewTemplate, guid])
      },
    }
  )
}
