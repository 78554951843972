import React, { useCallback } from 'react'
import { Icon, WeakColorSeparator, theme } from '@blue-agency/rogue'
import { FormRow, TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { Box } from '@/components/Box'
import { SecondaryLink } from '@/components/Link'
import { Loading } from '@/components/Loading'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import { VideoPlayer } from '@/components/VideoPlayer'
import { useModal } from '@/hooks/useModal'
import { mapRecInterviewTemplateDetailQuestion } from '@/services/recInterviewTemplateService/mapFromProto'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Deadline } from '../RecInterviewTemplatesPage/Deadline'
import { DeleteModal } from './DeleteModal'
import { QuestionRow } from './QuestionRow'
import { useGetRecInterviewTemplate } from './useGetRecInterviewTemplate'
import { useRecInterviewTemplatePage } from './useRecInterviewTemplatePage'

export const Content: React.VFC = () => {
  const { guid } = useParams<{ guid: string }>()
  const ctx = useGetRecInterviewTemplate(guid)
  const { copyRecInterviewTemplateGuid } = useRecInterviewTemplatePage(guid)

  const deleteModal = useModal(false)

  const handleClickDelete = useCallback(() => {
    deleteModal.open()
  }, [deleteModal])

  if (ctx.isLoading) {
    return <Loading />
  }

  const template = ctx.data
  assertIsDefined(template)

  return (
    <div>
      <SettingsSection title="録画面接テンプレート">
        <EditButtonWrapper>
          <EditButton
            comlinkPushParams={{ action: 'edit_rec_interview_template' }}
            to={generatePath(INTERNAL_PATHS.editRecInterviewTemplate, {
              guid: template.getGuid(),
            })}
          >
            <EditIcon />
            編集
          </EditButton>
          <DeleteButton
            comlinkPushParams={{ action: 'delete_rec_interview_template' }}
            to="#"
            onClick={handleClickDelete}
          >
            <DeleteIcon />
            削除
          </DeleteButton>
        </EditButtonWrapper>
        <C.FormBlockContainer>
          <Row title="テンプレート名">
            <C.ItemValue>{template.getName()}</C.ItemValue>
          </Row>
          <WeakColorSeparator />
          <Row title="録画面接テンプレートID">
            <C.ItemValue>
              {guid}
              <CopyTemplateGUIDButton
                comlinkPushParams={{
                  action: 'click_copy_rec_interview_template_guid',
                }}
                onClick={copyRecInterviewTemplateGuid}
              >
                コピー
              </CopyTemplateGUIDButton>
            </C.ItemValue>
          </Row>
          <WeakColorSeparator />
          <Row title={'作成済みの\n動画ファイルの提出'}>
            <C.ItemValue>
              {template.getAllowRecordedVideo() ? '許可する' : '許可しない'}
            </C.ItemValue>
          </Row>
          <WeakColorSeparator />
          <Row title="提出期限">
            <C.ItemValue>
              <Deadline template={template} />
            </C.ItemValue>
          </Row>
          <WeakColorSeparator />
          <Row title="応募者の開始ページ" subtitle={'企業からの\nメッセージ'}>
            <C.ItemValue>
              {template.getIntroVideoPresignedUrl() && (
                <Box width={270}>
                  <VideoPlayer src={template.getIntroVideoPresignedUrl()} />
                </Box>
              )}
              <p>{template.getIntroMessage()}</p>
            </C.ItemValue>
          </Row>
          <WeakColorSeparator />
          {template.getQuestionsList().map((q, index) => (
            <div key={q.getQuestionTemplateGuid()}>
              {index > 0 ? <QuestionSeparator /> : null}
              <QuestionRow
                question={mapRecInterviewTemplateDetailQuestion(q)}
                index={index}
              />
            </div>
          ))}
          <WeakColorSeparator />
          <Row title="応募者の終了ページ" subtitle={'企業からの\nメッセージ'}>
            <C.ItemValue>
              {template.getOutroVideoPresignedUrl() && (
                <Box width={270}>
                  <VideoPlayer src={template.getOutroVideoPresignedUrl()} />
                </Box>
              )}
              <p>{template.getOutroMessage()}</p>
            </C.ItemValue>
          </Row>
        </C.FormBlockContainer>
      </SettingsSection>
      <DeleteModal
        active={deleteModal.active}
        onClose={deleteModal.close}
        guid={template.getGuid()}
        name={template.getName()}
      />
    </div>
  )
}

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px 0;
`

const EditButton = styled(SecondaryLink).attrs({ size: 's' })``

const EditIcon = styled(Icon).attrs({ name: 'edit' })`
  vertical-align: middle;
  margin-right: 7px;
`

const DeleteButton = styled(SecondaryLink).attrs({ size: 's' })`
  color: ${theme.color.red[2]};
  margin-left: 30px;
`

const DeleteIcon = styled(Icon).attrs({ name: 'delete' })`
  vertical-align: middle;
  margin-right: 7px;
`

const CopyTemplateGUIDButton = styled(TertiaryButton).attrs({
  widthSize: 'M',
  heightSize: 'S',
})`
  padding: 6px;
  margin-left: 20px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '180px',
  subtitleWidth: '108px',
})`
  padding: 20px 0;
`

const QuestionSeparator = styled(WeakColorSeparator)`
  width: calc(100% - 180px);
  margin-left: 180px;
`
