import React from 'react'
import { Modal, theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { Box } from '@/components/Box'
import { ProgressBar } from '@/components/ProgressBar'

type Props = {
  active: boolean
  type: 'intro' | 'outro'
  onCancel: () => void
  fileName: string
  percent: number
}

export const UploadingModal: React.VFC<Props> = (props) => {
  const titlePrefix = props.type === 'intro' ? 'オープニング' : 'エンディング'

  return (
    <Modal active={props.active} title={`${titlePrefix}動画のアップロード`}>
      <Box paddingX="20px" paddingY="12px">
        <Txt>アップロード中</Txt>
        <Box mt="30px">
          <Txt>{props.fileName}</Txt>
        </Box>
        <Box mt="4px">
          <ProgressBar percent={props.percent} />
        </Box>
      </Box>
      <Box
        mt="30px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderTop={`1px solid ${theme.color.gray[4]}`}
        paddingY="11px"
      >
        <Box width={144}>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onCancel}
            comlinkPushParams={{
              action: `click_cancel_upload_${props.type}_template_video_button`,
            }}
          >
            キャンセル
          </TertiaryButton>
        </Box>
      </Box>
    </Modal>
  )
}
