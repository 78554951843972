import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  guideName: string | undefined
}

export function WebInterviewGuideName({ guideName }: Props) {
  return (
    <Wrapper>
      <Label>面接ガイド：</Label>
      <Txt>{guideName ?? '未設定'}</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

const Label = styled(_Txt).attrs({ size: 'm', color: theme.color.gray[1] })``
const Txt = styled(_Txt).attrs({ size: 'm' })``
