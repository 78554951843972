import * as Sentry from '@sentry/react'
import type { CsvBody, CSVData, CsvHeader } from '../types'
import { parseCsv } from './parseCsv'

export type PreconditionError =
  | 'extensionError'
  | 'maxRowLengthError'
  | 'noHeaderError'
  | 'noBodyError'
  | 'requestedMultipleFilesError'

const maxRowLength = 1000

type Response =
  | {
      type: 'error'
      message: string
    }
  | {
      type: 'valid'
      fileName: string
      csvData: CSVData
    }

const errorMessages: Record<PreconditionError, string> = {
  extensionError:
    'ファイルの拡張子がcsvではありません。適切なファイルをアップロードしてください。',
  maxRowLengthError: `一度に更新できる面接数は、${maxRowLength.toLocaleString()}件までとなります。`,
  noHeaderError:
    '未入力の項目があります。\nすべての項目に入力して再度アップロードしてください。',
  noBodyError:
    '未入力の項目があります。\nすべての項目に入力して再度アップロードしてください。',
  requestedMultipleFilesError:
    '複数のファイルを一度にアップロードすることはできません。',
}

export async function validatePrecondition(files: File[]): Promise<Response> {
  if (files.length !== 1) {
    return {
      type: 'error',
      message: errorMessages['requestedMultipleFilesError'],
    }
  }

  const [file] = files

  if (file === undefined) {
    throw new Error('file is undefined')
  }

  if (!validateFileExtension(file.name, 'csv')) {
    return {
      type: 'error',
      message: errorMessages['extensionError'],
    }
  }

  const csvString = (await getFileText(file)).trimEnd()

  let header: CsvHeader | undefined
  let body: CsvBody

  try {
    ;[header, body] = parseCsv(csvString)
  } catch (e) {
    Sentry.captureException(e)
    return {
      type: 'error',
      message: errorMessages['noHeaderError'],
    }
  }

  if (header === undefined) {
    return {
      type: 'error',
      message: errorMessages['noHeaderError'],
    }
  }

  if (body.length === 0) {
    return {
      type: 'error',
      message: errorMessages['noBodyError'],
    }
  }

  const isMaxRowLengthError = body.length > maxRowLength
  if (isMaxRowLengthError) {
    return {
      type: 'error',
      message: errorMessages['maxRowLengthError'],
    }
  }

  return {
    type: 'valid',
    fileName: file.name,
    csvData: {
      csvHeader: header,
      csvBody: body,
    },
  }
}

function validateFileExtension(fileName: string, extension: string): boolean {
  const chunks = fileName.split('.')
  return chunks.length > 1 && chunks[chunks.length - 1] === extension
}

function getFileText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (!e.target || !e.target.result) {
        reject()
        return
      }
      const buffer = new Uint8Array(e.target.result as ArrayBufferLike)

      // 環境依存文字が含まれたファイルの文字コードを判別することが困難なので、
      // shift-jis, utf-8 でデコードしてみて、対応していない文字コードでデコードした場合に変換される � がファイル中に含まれるかどうかをみる
      // デコードして � が含まれていない場合、対応する文字コードでデコードできたものとする
      const encodingList: Encoding[] = ['shift-jis', 'utf-8']

      for (const encoding of encodingList) {
        const [succeeded, decoded] = decode(buffer, encoding)
        if (succeeded) {
          resolve(decoded)
        }
      }

      reject()
    }

    reader.readAsArrayBuffer(file)
  })
}

type Encoding = 'utf-8' | 'shift-jis'

function decode(buffer: Uint8Array, encoding: Encoding): [boolean, string] {
  const decoder = new TextDecoder(encoding)

  const decoded = decoder.decode(buffer)
  const succeeded = !decoded.includes('�')

  return [succeeded, decoded]
}
