import * as yup from 'yup'

const WEB_INTERVIEW_DURATION_MINUTES_MAX = 180

// 整数でない場合などもこのエラーメッセージでOK
// https://stadium-co-jp.slack.com/archives/C02A51MH1PD/p1633311315138600?thread_ts=1633310085.136700&cid=C02A51MH1PD
const durationValidationErrorMessage = `${WEB_INTERVIEW_DURATION_MINUTES_MAX}分以内で入力してください`

export const durationMinutesSchema = yup
  .number()
  .typeError(durationValidationErrorMessage)
  .min(1, durationValidationErrorMessage)
  .max(WEB_INTERVIEW_DURATION_MINUTES_MAX, durationValidationErrorMessage)
  .integer(durationValidationErrorMessage)
  .nullable()
  .transform((value, originalValue) => {
    // 未入力のときは undefined として扱う
    return originalValue.toString().trim().length === 0 ? undefined : value
  })
