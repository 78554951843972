import React, { useMemo } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  count: number
}>

export const Count = (props: Props): React.ReactElement => {
  const count = useMemo<String>(() => {
    if (typeof props.count !== 'number') {
      return ''
    }
    return String(props.count).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }, [props])

  return (
    <Txt color={theme.color.green[4]} size="xxl" {...props}>
      {count}
    </Txt>
  )
}
