import {
  // eslint-disable-next-line rulesdir/use-extended-custom-grpc-error
  CustomGrpcError,
  GrpcError,
  PermissionDenied,
} from '@blue-agency/im-shared-front'
import { Code } from '@blue-agency/proton/web/google/rpc/code_pb'
import { ErrorReason } from '@blue-agency/proton/web/v2/biz_hutt_bff/error_reason_pb'
import { BadRequest } from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import { ErrorInfo } from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import * as jspb from 'google-protobuf'

type Reason = keyof typeof ErrorReason

export class CustomBizHuttBffGrpcError extends CustomGrpcError {
  /**
   * 特定のErrorDetailのReasonを持っているか確かめる
   * @param reason ErrorInfoで定義されたErrorReason
   * @returns 引数のreason === 実際のreason
   */
  hasMatchErrorDetail(reason: Reason): boolean {
    return this.errorDetailsOfGoogleApis.some((d) => {
      if (d instanceof ErrorInfo) {
        const r = d.getReason() as Reason
        return r === reason
      }
      return false
    })
  }

  /**
   * @param reason ErrorInfoで定義されたErrorReason
   * @returns 該当のErrorReasonのMetadata
   */
  getErrorDetailMetadataMap(
    reason: Reason
  ): jspb.Map<string, string> | undefined {
    let res: jspb.Map<string, string> | undefined = undefined
    this.errorDetailsOfGoogleApis.forEach((d) => {
      if (d instanceof ErrorInfo) {
        const r = d.getReason() as Reason
        if (r === reason) {
          res = d.getMetadataMap()
          return
        }
      }
    })
    return res
  }

  /**
   * @returns FieldViolationの一覧
   */
  getFieldViolations(): BadRequest.FieldViolation[] {
    let res: BadRequest.FieldViolation[] = []
    for (const d of this.errorDetailsOfGoogleApis) {
      if (d instanceof BadRequest) {
        res = d.getFieldViolationsList()
        break
      }
    }
    return res
  }
}

export function buildErrorByGrpcError(error: GrpcError): Error {
  if (error.code === Code.PERMISSION_DENIED) {
    return new PermissionDenied(error as unknown as Error)
  }
  return new CustomBizHuttBffGrpcError(error)
}
