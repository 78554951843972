import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetRecInterviewTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetRecInterviewTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetRecInterviewTemplate = useCallback(
    async (questionTemplateGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetRecInterviewTemplateRequest()

      req.setGuid(questionTemplateGuid)

      return callBizHuttBffService(
        'getRecInterviewTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetRecInterviewTemplate }
}
