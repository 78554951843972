import { useCallback, useState } from 'react'
import { toast } from '@blue-agency/rogue'
import { useRequestExportWebInterviewsCsv } from '@/services/bffService'
import { WebInterviewsPageContainer } from './WebInterviewsPageContainer'

const exportToCsv = ({
  data,
  fileName,
}: {
  data: Uint8Array
  fileName: string
}) => {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = fileName
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const useCsvDownload = () => {
  const fileName = 'Web面接リスト.csv'
  const { validatedFormValue } = WebInterviewsPageContainer.useContainer()

  const [isLoading, setIsLoading] = useState(false)

  const { requestExportWebInterviewsCsv } = useRequestExportWebInterviewsCsv()

  const download = useCallback(async () => {
    setIsLoading(true)
    const data = await requestExportWebInterviewsCsv({
      condition: validatedFormValue,
    })
    exportToCsv({ data, fileName })
    setIsLoading(false)
    toast('ダウンロードが完了しました')
  }, [requestExportWebInterviewsCsv, validatedFormValue])

  return {
    isLoading,
    download,
  }
}
