import { useMutation, useQueryClient } from 'react-query'
import {
  BatchDeleteApplicantsRequestValue,
  useRequestBatchDeleteApplicants,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchDeleteApplicants() {
  const queryClient = useQueryClient()
  const { requestBatchDeleteApplicants } = useRequestBatchDeleteApplicants()

  return useMutation(
    async (value: BatchDeleteApplicantsRequestValue) => {
      const res = await requestBatchDeleteApplicants(value)
      return res.getDeletedCount()
    },
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        switch (req.condition.type) {
          case 'guids':
            queryClient.invalidateQueries({
              predicate: (query) =>
                query.queryKey[0] === QUERY_KEY.applicant &&
                req.condition.type.includes(query.queryKey[1] as string),
            })
            break
          case 'searchCondition':
            // NOTE:検索条件を指定した削除の場合、応募者GUIDが不明なので、応募者詳細全体をinvalidateする
            queryClient.invalidateQueries(QUERY_KEY.applicant)
            break
        }
      },
    }
  )
}
