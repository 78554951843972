import { useMutation, useQueryClient } from 'react-query'
import {
  ChangeWebInterviewGuideOfWebInterviewsRequestValue,
  useRequestChangeWebInterviewGuideOfWebInterviews,
} from '@/services/bffService/useRequestChangeWebInterviewGuideOfWebInterviews'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useChangeWebInterviewGuideOfWebInterviewsMutation() {
  const queryClient = useQueryClient()
  const { requestChangeWebInterviewGuideOfWebInterviews } =
    useRequestChangeWebInterviewGuideOfWebInterviews()

  return useMutation(
    async (value: ChangeWebInterviewGuideOfWebInterviewsRequestValue) => {
      await requestChangeWebInterviewGuideOfWebInterviews(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviews)
      },
    }
  )
}
