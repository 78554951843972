import { setHours, setMinutes } from 'date-fns'
import * as yup from 'yup'
import type { TestOptions } from 'yup'

type FromType = TestOptions['options']['from']

export const scheduledStartTimeShape = {
  scheduledDate: yup
    .date()
    .nullable()
    .test({
      name: 'scheduledStartTimeValidation',
      message: '予定日時は「年月日」「時間」どちらかのみの登録はできません。',
      test: (_, ctx) => {
        // ctx の型定義に from が無いので、無理矢理対応する
        // https://github.com/jquense/yup/issues/1445
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const from = (ctx as any).from as FromType
        if (from === undefined) {
          return false
        }
        const parent = from[0]
        if (parent === undefined || parent.value === undefined) {
          return false
        }

        const isScheduledHourPresent =
          typeof parent.value.scheduledHour === 'string' &&
          parent.value.scheduledHour !== ''
        const isScheduledMinPresent =
          typeof parent.value.scheduledMin === 'string' &&
          parent.value.scheduledMin !== ''
        const isScheduledDatePresent = parent.value.scheduledDate !== undefined

        // * 年月日および時間、分がすべて入力されている
        // * 年月日、時間、分のいずれも入力されていない (=予定日時の設定をしない)
        // のいずれかの場合を有効な入力とする
        const allPresent =
          isScheduledHourPresent &&
          isScheduledMinPresent &&
          isScheduledDatePresent
        const nonePresent =
          !isScheduledHourPresent &&
          !isScheduledMinPresent &&
          !isScheduledDatePresent

        return allPresent || nonePresent
      },
    }),
  scheduledHour: yup.string(),
  scheduledMin: yup.string(),
}

export const minOptions = [...range(0, 60, 5)].map((x) => ({
  label: x.toString(),
  value: x,
}))

export const hourOptions = [...range(0, 24)].map((x) => ({
  label: x.toString(),
  value: x,
}))

export function buildScheduledStartTime({
  scheduledDate,
  scheduledHour,
  scheduledMin,
}: {
  scheduledDate?: Date
  scheduledHour: string
  scheduledMin: string
}): Date | undefined {
  if (
    scheduledDate === undefined ||
    scheduledHour === '' ||
    scheduledMin === ''
  ) {
    return undefined
  }

  const hour = parseInt(scheduledHour)
  const min = parseInt(scheduledMin)
  if (Number.isNaN(hour) || Number.isNaN(min)) {
    return undefined
  }

  const hourSet = setHours(scheduledDate, hour)
  return setMinutes(hourSet, min)
}

function* range(start: number, end: number, step: number = 1) {
  for (let i = start; i < end; i += step) {
    yield i
  }
}
