import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  upcomingPassedRecentlyCount: number
  onClick: () => void
}

export const NotStartedInterviewsAlert: React.VFC<Props> = (props) => {
  const primaryColor =
    props.upcomingPassedRecentlyCount === 0
      ? theme.color.green[4]
      : theme.color.red[2]
  const backgroundColor =
    props.upcomingPassedRecentlyCount === 0
      ? theme.color.green[8]
      : theme.color.red[4]
  const iconName =
    props.upcomingPassedRecentlyCount === 0 ? 'check' : 'exclamation'

  return (
    <Wrapper
      borderColor={primaryColor}
      backgroundColor={backgroundColor}
      onClick={props.onClick}
    >
      <Left>
        <Title>
          <AlertIcon color={primaryColor} name={iconName} />
          <TitleTxt color={primaryColor}>開始されていない面接</TitleTxt>
        </Title>
        <Supplement>
          <Txt size="s" color={theme.color.gray[1]}>
            開始予定時間から30分以内のみ
          </Txt>
        </Supplement>
      </Left>
      <Right>
        <CountTxt color={primaryColor}>
          <span>{props.upcomingPassedRecentlyCount}</span>件
        </CountTxt>
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ borderColor: string; backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-top: 2px solid;
  border-color: ${(props) => props.borderColor};
  border-radius: 2px 2px 4px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`

const TitleTxt = styled(Txt).attrs<{ color: string }>((props) => ({
  size: 'm',
  color: props.color,
}))<{ color: string }>`
  line-height: 18px;
`

const Supplement = styled.div`
  margin-left: 26px;
`

const Right = styled.div``

const CountTxt = styled(Txt).attrs<{ color: string }>((props) => ({
  size: 'm',
  color: props.color,
}))<{ color: string }>`
  > span {
    font-size: 32px;
  }
`

type AlertIconProps = {
  name: string
  color: string
}

const AlertIcon = styled(Icon).attrs<AlertIconProps>((props) => ({
  name: props.name,
}))<AlertIconProps>`
  color: ${(props) => props.color};
`
