import React, { useMemo } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { mapProtoToVideoSeminarForList } from '@/services/videoSeminarService'
import { Header } from './Header'
import { List } from './List'
import { useListVideoSeminars } from './useListVideoSeminars'

export const VideoSeminarsPage = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('ListVideoSeminars')

  const { data, isLoading } = useListVideoSeminars()

  const videoSeminars = useMemo(() => {
    if (data === undefined) return undefined
    return data.getVideoSeminarsList().map(mapProtoToVideoSeminarForList)
  }, [data])

  if (isLoading || !videoSeminars) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Header />
      <Main>
        <Sidebar />
        <List videoSeminars={videoSeminars} />
      </Main>
    </PageLayoutWithGlonavi>
  )
}

const Main = styled.main`
  display: flex;
  height: 100%;
`

const Sidebar = styled.aside`
  width: 248px;
`
