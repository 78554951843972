import { isBefore } from 'date-fns'
import * as yup from 'yup'
import {
  SearchCondition,
  SelectionStatus,
  SelectionSubStatus,
} from '@/services/applicantService'

export type SearchConditionForm = {
  keywords?: string
  statuses: {
    SELECTION_STATUS_ARRIVAL: boolean
    SELECTION_STATUS_IN_PROGRESS: boolean
    SELECTION_STATUS_NOT_PASSED: boolean
    SELECTION_STATUS_PENDING: boolean
    SELECTION_STATUS_FINISHED: boolean
    SELECTION_STATUS_DECLINED: boolean
  }
  subStatuses: {
    SELECTION_SUB_STATUS_NO_SELECTION_STEP: boolean
    SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE: boolean
    SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT: boolean
    SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP: boolean
    SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED: boolean
    SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT: boolean
    SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED: boolean
  }
  notes?: string
  registerTimeFrom?: Date
  registerTimeTo?: Date
} & Pick<
  SearchCondition,
  | 'currentSelectionStepMasterGuid'
  | 'externalApplicantId'
  | 'mailFailure'
  | 'currentSelectionStepExternalRecruitmentName'
  | 'currentSelectionStepExternalSelectionName'
>

export const formSchema: yup.SchemaOf<SearchConditionForm> = yup.object({
  keywords: yup.string(),
  statuses: yup
    .object({
      SELECTION_STATUS_ARRIVAL: yup.bool().required(),
      SELECTION_STATUS_IN_PROGRESS: yup.bool().required(),
      SELECTION_STATUS_NOT_PASSED: yup.bool().required(),
      SELECTION_STATUS_PENDING: yup.bool().required(),
      SELECTION_STATUS_FINISHED: yup.bool().required(),
      SELECTION_STATUS_DECLINED: yup.bool().required(),
    })
    .required(),
  subStatuses: yup
    .object({
      SELECTION_SUB_STATUS_NO_SELECTION_STEP: yup.bool().required(),
      SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE: yup.bool().required(),
      SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT: yup.bool().required(),
      SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP: yup.bool().required(),
      SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED: yup.bool().required(),
      SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT: yup.bool().required(),
      SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED: yup.bool().required(),
    })
    .required(),
  currentSelectionStepMasterGuid: yup.string(),
  externalApplicantId: yup.string(),
  notes: yup.string(),
  registerTimeFrom: yup.date(),
  registerTimeTo: yup.date(),
  mailFailure: yup.bool().required(),
  currentSelectionStepExternalRecruitmentName: yup.string(),
  currentSelectionStepExternalSelectionName: yup.string(),
})

export const mapSearchConditionForm = (
  searchCondition: SearchCondition
): SearchConditionForm => ({
  keywords: searchCondition.keywords?.join(' '),
  statuses: {
    SELECTION_STATUS_ARRIVAL: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_ARRIVAL
    ),
    SELECTION_STATUS_IN_PROGRESS: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_IN_PROGRESS
    ),
    SELECTION_STATUS_NOT_PASSED: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_NOT_PASSED
    ),
    SELECTION_STATUS_PENDING: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_PENDING
    ),
    SELECTION_STATUS_FINISHED: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_FINISHED
    ),
    SELECTION_STATUS_DECLINED: searchCondition.statuses.includes(
      SelectionStatus.SELECTION_STATUS_DECLINED
    ),
  },
  subStatuses: {
    SELECTION_SUB_STATUS_NO_SELECTION_STEP:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP
      ),
    SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE
      ),
    SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT
      ),
    SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP
      ),
    SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED
      ),
    SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT
      ),
    SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED:
      searchCondition.subStatuses.includes(
        SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED
      ),
  },
  currentSelectionStepMasterGuid:
    searchCondition.currentSelectionStepMasterGuid,
  externalApplicantId: searchCondition.externalApplicantId,
  notes: searchCondition.notes?.join(' '),
  registerTimeFrom: searchCondition.registerTime?.from,
  registerTimeTo: searchCondition.registerTime?.to,
  mailFailure: searchCondition.mailFailure,
  currentSelectionStepExternalRecruitmentName:
    searchCondition.currentSelectionStepExternalRecruitmentName,
  currentSelectionStepExternalSelectionName:
    searchCondition.currentSelectionStepExternalSelectionName,
})

export type SelectionStatusKey = Exclude<
  keyof typeof SelectionStatus,
  'SELECTION_STATUS_UNKNOWN'
>

export type SelectionSubStatusKey = Exclude<
  keyof typeof SelectionSubStatus,
  'SELECTION_SUB_STATUS_UNKNOWN'
>

export const mapSearchCondition = (
  searchConditionForm: SearchConditionForm
): SearchCondition => ({
  keywords: searchConditionForm.keywords?.trim().split(/\s+/) ?? [],
  statuses: Object.keys(searchConditionForm.statuses)
    .filter((key) => searchConditionForm.statuses[key as SelectionStatusKey])
    .map((key) => SelectionStatus[key as SelectionStatusKey]),
  subStatuses: Object.keys(searchConditionForm.subStatuses)
    .filter(
      (key) => searchConditionForm.subStatuses[key as SelectionSubStatusKey]
    )
    .map((key) => SelectionSubStatus[key as SelectionSubStatusKey]),
  currentSelectionStepMasterGuid:
    searchConditionForm.currentSelectionStepMasterGuid,
  externalApplicantId: searchConditionForm.externalApplicantId,
  notes: searchConditionForm.notes?.trim().split(/\s+/) ?? [],
  registerTime: {
    from: searchConditionForm.registerTimeFrom,
    to: searchConditionForm.registerTimeTo,
  },
  mailFailure: searchConditionForm.mailFailure,
  currentSelectionStepExternalRecruitmentName:
    searchConditionForm.currentSelectionStepExternalRecruitmentName,
  currentSelectionStepExternalSelectionName:
    searchConditionForm.currentSelectionStepExternalSelectionName,
})

/**
 * フリーワード・備考の語数validation
 */
export const validateWordsCount = (
  words: string[]
): { valid: boolean; errorMessage: string } => {
  if (words.length > 3) {
    return {
      valid: false,
      errorMessage: 'スペース区切り, 3語以下で入力してください',
    }
  }
  return {
    valid: true,
    errorMessage: '',
  }
}

/**
 * 登録日時の範囲のvalidation
 */
export const validateRegisterTime = (
  from: Date | undefined,
  to: Date | undefined
): { valid: boolean; errorMessage: string } => {
  if (from === undefined || to === undefined) {
    return {
      valid: true,
      errorMessage: '',
    }
  }
  if (isBefore(to, from)) {
    return {
      valid: false,
      errorMessage: '開始日は終了日以前の日付を選択してください',
    }
  }
  return {
    valid: true,
    errorMessage: '',
  }
}
