import React from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { Txt } from '@blue-agency/rogue'
import { DevTool } from '@hookform/devtools'
import { UpdateSelectionStepMasterRequestValue } from '@/services/bffService'
import * as C from '../modalComponents'
import { useUpdateModal } from './useUpdateModal'

type Props = {
  active: boolean
  onClose: () => void
  selectionStepMaster: UpdateSelectionStepMasterRequestValue
}

export const UpdateModal: React.VFC<Props> = (props) => {
  const ctx = useUpdateModal({
    onSuccessUpdate: props.onClose,
    selectionStepMaster: props.selectionStepMaster,
  })

  const onClose = useCallback(() => {
    ctx.formCtx.clearErrors()
    ctx.formCtx.reset()
    props.onClose()
  }, [props, ctx.formCtx])

  const setFocus = ctx.formCtx.setFocus
  useEffect(() => {
    if (props.active) {
      setFocus('name')
    }
  }, [props.active, setFocus])

  return (
    <C.Modal
      active={props.active}
      title="選考ステップの編集"
      size="l"
      onClose={onClose}
    >
      <C.Main>
        <C.Row>
          <C.Title>選考ステップ名</C.Title>
          <C.Required />
          <C.InputStepName
            {...ctx.formCtx.register('name')}
            onBlurError={ctx.formCtx.formState.errors.name?.message}
          />
        </C.Row>
        <C.Separator />
        <C.Row>
          {/* NOTE: 選考種別は現在は変更できない */}
          <C.Title>選考種別</C.Title>
          <C.NotRequired />
          <Txt>
            {
              C.dropdownOptions.find(
                (o) => o.value === props.selectionStepMaster.type
              )?.label
            }
          </Txt>
        </C.Row>
      </C.Main>
      <C.Bottom>
        <C.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_update_selection_step_master_modal',
          }}
        >
          キャンセル
        </C.CancelButton>
        <C.SubmitButton
          onClick={ctx.update}
          disabled={ctx.formCtx.formState.isSubmitting}
          comlinkPushParams={{
            action: 'click_submit_button_on_update_selection_step_master_modal',
          }}
        >
          保存
        </C.SubmitButton>
      </C.Bottom>
      <DevTool control={ctx.formCtx.control} placement="bottom-right" />
    </C.Modal>
  )
}
