import { useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { SelectionStatus, SearchCondition } from '@/services/applicantService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { BatchAction } from '../../useBatchAction'
import { BatchReqRecInterviewAnswerModalOnCondition } from '../BatchReqRecInterviewAnswerModal'
import { BatchUpdateRecInterviewDeadlineModalOnCondition } from '../BatchUpdateRecInterviewDeadlineModal'
import { useBatchDeleteApplicants } from '../useBatchDeleteApplicants'
import { useBatchUpdateSelectionStatus } from '../useBatchUpdateSelectionStatus'
import { BatchDeleteApplicantsOnConditionModal as DeleteModal } from './BatchDeleteApplicantsOnConditionModal'
import {
  BatchUpdateSelectionStatusOnConditionModal as UpdateModal,
  BatchUpdateSelectionStatusOnConditionErrorType,
} from './BatchUpdateSelectionStatusOnConditionModal'

type Props = {
  action: BatchAction | undefined
  condition: SearchCondition
  targetCount: number
  close: () => void
}
export const BatchActionOnSearchConditionModal: React.VFC<Props> = (props) => {
  switch (props.action) {
    case 'delete':
      return <BatchDeleteApplicantsOnSearchConditionModal {...props} />
    case 'updateRecInterviewDeadline':
      return <BatchUpdateRecInterviewDeadlineModalOnCondition {...props} />
    case 'finished':
      return (
        <BatchUpdateSelectionStepToFinishedOnSearchConditionModal {...props} />
      )
    case 'notPassed':
      return (
        <BatchUpdateSelectionStepToNotPassedOnSearchConditionModal {...props} />
      )
    case 'reqRecInterviewAnswer':
      return <BatchReqRecInterviewAnswerModalOnCondition {...props} />
    default:
      throw new Error('No corresponding batch action')
  }
}

type ChildrenProps = Omit<Props, 'action'>

const maxActionCount = 10_000
const BatchDeleteApplicantsOnSearchConditionModal: React.VFC<ChildrenProps> = (
  props
) => {
  const { mutateAsync } = useBatchDeleteApplicants()
  const [deletable, setDeletable] = useState<boolean>(
    props.targetCount <= maxActionCount
  )

  const request = useCallback(async () => {
    try {
      const count = await mutateAsync({
        condition: {
          type: 'searchCondition',
          condition: props.condition,
        },
      })
      toast(`${count}件の応募者を削除しました`)
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          if (e.hasMatchErrorDetail('NUM_BATCH_ACTION_LIMIT_REACHED')) {
            setDeletable(false)
            return
          }
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }
    props.close()
  }, [mutateAsync, props])

  return (
    <DeleteModal
      onSubmit={request}
      onClose={props.close}
      deletable={deletable}
      limit={maxActionCount}
      info={[
        '対象の応募者に関連するデータが完全に削除されます。',
        '削除後は、お問い合わせいただいても復元できませんのでご注意ください。',
        '検索条件に一致する応募者が処理中に追加された場合、その応募者も対象となります。',
      ]}
    />
  )
}

const BatchUpdateSelectionStepToNotPassedOnSearchConditionModal: React.VFC<
  ChildrenProps
> = (props) => {
  const { mutateAsync } = useBatchUpdateSelectionStatus()
  const [errType, setErrType] =
    useState<BatchUpdateSelectionStatusOnConditionErrorType>(
      (): BatchUpdateSelectionStatusOnConditionErrorType => {
        // NOTE: 検索条件で新着ステータスが含まれうる可能性を排除する
        if (
          (props.condition.statuses.length === 0 &&
            props.condition.subStatuses.length === 0) ||
          props.condition.statuses.includes(
            SelectionStatus.SELECTION_STATUS_ARRIVAL
          )
        ) {
          return { level: 'includeArrival' }
        }
        return { level: 'none' }
      }
    )

  const request = useCallback(async () => {
    try {
      await mutateAsync({
        status: SelectionStatus.SELECTION_STATUS_NOT_PASSED,
        condition: {
          type: 'searchCondition',
          condition: props.condition,
        },
      })
      toast('一括変更を開始しました')
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          if (e.hasMatchErrorDetail('NUM_BATCH_ACTION_LIMIT_REACHED')) {
            setErrType({ level: 'overLimit' })
            return
          }
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }
    props.close()
  }, [mutateAsync, props])

  return (
    <UpdateModal
      onSubmit={request}
      onClose={props.close}
      toStatus={SelectionStatus.SELECTION_STATUS_NOT_PASSED}
      limit={maxActionCount}
      error={errType}
    />
  )
}

const BatchUpdateSelectionStepToFinishedOnSearchConditionModal: React.VFC<
  ChildrenProps
> = (props) => {
  const { mutateAsync } = useBatchUpdateSelectionStatus()
  const [errType, setErrType] =
    useState<BatchUpdateSelectionStatusOnConditionErrorType>({
      level: 'none',
    })

  const request = useCallback(async () => {
    try {
      await mutateAsync({
        status: SelectionStatus.SELECTION_STATUS_FINISHED,
        condition: {
          type: 'searchCondition',
          condition: props.condition,
        },
      })
      toast('一括変更を開始しました')
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          if (e.hasMatchErrorDetail('NUM_BATCH_ACTION_LIMIT_REACHED')) {
            setErrType({ level: 'overLimit' })
            return
          }
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }
    props.close()
  }, [mutateAsync, props])

  return (
    <UpdateModal
      onSubmit={request}
      onClose={props.close}
      toStatus={SelectionStatus.SELECTION_STATUS_FINISHED}
      limit={maxActionCount}
      error={errType}
    />
  )
}
