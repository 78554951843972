import React from 'react'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  webInterviewGUID: string
  atsInterviewID: string | undefined
}

export const IDItems = (props: Props): React.ReactElement => {
  return (
    <Wrapper>
      <Item>
        <Label>Web面接ID：</Label>
        <Txt>{props.webInterviewGUID}</Txt>
      </Item>
      {props.atsInterviewID && (
        <Item>
          <Label>連携先Web面接ID：</Label>
          <Txt>{props.atsInterviewID}</Txt>
        </Item>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 5px;
`
const Item = styled.div`
  display: flex;
  margin-top: 4px;
`
const Label = styled(_Txt).attrs({ size: 's', color: theme.color.gray[1] })``
const Txt = styled(_Txt).attrs({ size: 's' })``
