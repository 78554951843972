import React, { useMemo } from 'react'
import { WebInterviewStatus } from '@blue-agency/proton/biz_hutt_bff'
import { theme, Txt, WeakColorSeparator, Icon } from '@blue-agency/rogue'
import { isBefore, isAfter } from 'date-fns'
import styled from 'styled-components'
import { assertNever } from '@/assertions'
import { TimelineItem } from './TimelineItem'
import type { WebInterview } from './WebInterviewPageContainer'

export type TimelineEvent =
  | Registered
  | Started
  | Finished
  | Expired
  | InterviewerJoin
  | IntervieweeJoin

type Registered = {
  kind: 'registered'
  webInterviewGUID: string
  atsInterviewID?: string
  date: Date
}

type Started = {
  kind: 'started'
  date: Date
}

type Finished = {
  kind: 'finished'
  date: Date
}

type Expired = {
  kind: 'expired'
  date: Date
}

type InterviewerJoin = {
  kind: 'interviewerJoin'
  name: string
  date: Date
}

type IntervieweeJoin = {
  kind: 'intervieweeJoin'
  name: string
  date: Date
}

type Props = {
  webInterview: WebInterview
}

export const Timeline: React.VFC<Props> = ({ webInterview }) => {
  const events = useMemo(() => {
    const events: TimelineEvent[] = []

    if (
      webInterview.status === WebInterviewStatus.EXPIRED &&
      webInterview.expireTime !== undefined
    ) {
      events.push({
        kind: 'expired',
        date: webInterview.expireTime,
      })
    }

    if (webInterview.finishTime !== undefined) {
      events.push({
        kind: 'finished',
        date: webInterview.finishTime,
      })
    }

    if (webInterview.startTime !== undefined) {
      events.push({
        kind: 'started',
        date: webInterview.startTime,
      })
    }

    for (const e of webInterview.timelineList) {
      switch (e.kind) {
        case 'interviewerJoin':
          events.push({
            kind: 'interviewerJoin',
            name: `${e.name.familyName} ${e.name.givenName}`,
            date: e.joinTime,
          })
          break
        case 'intervieweeJoin':
          events.push({
            kind: 'intervieweeJoin',
            name: e.name,
            date: e.joinTime,
          })
          break
        default:
          assertNever(e)
      }
    }

    events.push({
      kind: 'registered',
      webInterviewGUID: webInterview.guid,
      atsInterviewID: webInterview.atsInterviewId?.value,
      date: webInterview.registerTime,
    })

    events.sort((a, b) => {
      if (isBefore(a.date, b.date)) {
        return 1
      }
      if (isAfter(a.date, b.date)) {
        return -1
      }
      return 0
    })

    return events
  }, [webInterview])

  return (
    <TimelineWrapper>
      <TimelineHeader>
        <LogIcon />
        <Txt>information</Txt>
      </TimelineHeader>
      {events.map((event, i) => {
        const isLast = i === events.length - 1
        return (
          <React.Fragment key={event.date.getTime()}>
            <TimelineItem event={event} />
            {!isLast && <WeakColorSeparator />}
          </React.Fragment>
        )
      })}
    </TimelineWrapper>
  )
}

const TimelineWrapper = styled.div`
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 5px;
  box-sizing: content-box;
  padding: 0 12px;
`

const TimelineHeader = styled.div`
  padding: 14px 0 8px 0;
  display: flex;
`

const LogIcon = styled(Icon).attrs({
  name: 'log',
})`
  margin-right: 9px;
`
