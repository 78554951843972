import { ReactNode } from 'react'
import {
  WeakColorSeparator,
  Modal,
  Txt,
  CheckBox as _CheckBox,
  theme,
  ErrorBox,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { FormRow } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Count as Count_ } from '@/components/Count'
import { InfoBox } from '@/components/InfoBox'
import { useToggle } from '@/hooks/useToggle'
import { DeadlinePicker } from '@/pages/ApplicantPage/Content/Selection/DeadlinePicker'
import { bufferHours } from '@/services/recInterviewTemplateService'

export type ReqMode =
  | {
      type: 'guids'
      applicantsLength: number
    }
  | {
      type: 'searchCondition'
    }
export type ErrorType = 'overLimit' | 'pastDate' | 'includeUnupdatableStatus'

type Props = {
  mode: ReqMode
  error?: ErrorType

  deadline: Date

  onSubmit: () => void
  onClose: () => void
  onChangeDeadline: (date: Date | null) => void
}

export const BatchUpdateRecInterviewDeadlineModal: React.VFC<Props> = (
  props
) => {
  const [confirmed, toggleConfirmed] = useToggle(false)
  const canSubmit =
    (props.mode.type === 'searchCondition' ||
      props.mode.applicantsLength > 0) &&
    confirmed &&
    props.error === undefined

  return (
    <Modal
      active
      title="提出期限を変更（録画面接）"
      size="l"
      onClose={props.onClose}
      overflowVisible
      stopScroll
    >
      <ModalBody>
        {props.error && (
          <ErrorMessage>{errorMessageMap[props.error]}</ErrorMessage>
        )}
        {props.mode.type === 'guids' && (
          <>
            <Count count={props.mode.applicantsLength} />
            <InlineSpan>
              件の応募者の録画面接の提出期限を変更します。
            </InlineSpan>
            <InfoBox
              info={[
                '変更対象は「最新の選考ステップ」のみとなります。',
                '処理の完了まで、数分かかる場合があります。',
              ]}
            />
          </>
        )}
        {props.mode.type === 'searchCondition' && (
          <>
            <Txt>
              指定した検索条件に一致したすべての応募者の録画面接の提出期限を変更します。
              <br />
              検索条件に一致する応募者が処理中に追加された場合、その応募者も対象となります。
            </Txt>
            <InfoBox
              info={[
                '変更対象は「最新の選考ステップ」のみとなります。',
                '処理の完了まで、数分かかる場合があります。',
              ]}
            />
          </>
        )}
        <DeadlineWrapper>
          <Row title="提出期限" required>
            <DeadlinePicker
              deadline={props.deadline}
              onChangeDeadline={props.onChangeDeadline}
              disabled={
                props.error === 'overLimit' ||
                props.error === 'includeUnupdatableStatus'
              }
            />
          </Row>
        </DeadlineWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ConfirmRow title="確認" required>
          <CheckBox
            checked={confirmed}
            onChange={toggleConfirmed}
            size={18}
            checkboxLabel="内容に誤りがないことを確認しました"
          />
        </ConfirmRow>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_batch_update_selection_rec_interview_deadline_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={props.onSubmit}
            comlinkPushParams={{
              action:
                'click_batch_update_selection_rec_interview_deadline_button',
            }}
          >
            一括変更
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrCount = styled(Count_).attrs({
  color: theme.color.red[2],
  size: theme.fontSize.s,
})`
  display: inline-block;
`

const ErrorMessage = styled(ErrorBox)`
  margin-bottom: 20px;
`

const Count = styled(Count_)`
  display: inline-block;
  line-height: 1;
`

const InlineSpan = styled.span`
  font-family: inherit;
  font-size: ${theme.fontSize.m};
  color: ${theme.color.navy[1]};
`

const titleWidth = '130px'
const Row = styled(FormRow).attrs({
  titleWidth: titleWidth,
  subtitleWidth: '0',
})`
  margin-top: 20px;
`

const ConfirmRow = styled(FormRow).attrs({
  titleWidth: titleWidth,
  subtitleWidth: '0',
})`
  margin-bottom: 20px;
`

const DeadlineWrapper = styled.div`
  height: 32px;
  margin-top: 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding: 10px 20px 20px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`

const overLimit = 10000
const errorMessageMap: Record<ErrorType, ReactNode> = {
  overLimit: (
    <>
      対象件数が上限の
      <ErrCount count={overLimit} />
      件を超えています。
      <br />
      件数を減らして、再度実行してください。
    </>
  ),
  pastDate: `提出期限は現在時刻から${bufferHours}時間以上先で設定してください。`,
  includeUnupdatableStatus: (
    <>
      「未依頼」「提出待ち」「提出期限切れ」以外の応募者が含まれている可能性があります。
      <br />
      検索条件を確認してください。
    </>
  ),
}
