import React, { useCallback, useMemo, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Dropdown,
  DropdownOption,
  Modal,
  RequireTxt as _RequireTxt,
  theme,
  toast,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { captureException } from '@sentry/minimal'
import styled from 'styled-components'
import { SecondaryLink } from '@/components/Link'
import { useListWebInterviewGuides } from '@/pages/WebInterviewPage/ChangeWebInterviewGuideModal/useListWebInterviewGuides'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { WebInterview } from '../types'
import { useChangeWebInterviewGuideOfWebInterviewsMutation } from './useChangeWebInterviewGuideOfWebInterviewsMutation'

type Props = {
  webInterviews: WebInterview[]
  active: boolean
  onCloseModal: () => void
}

export const ChangeWebInterviewGuidesModal: React.VFC<Props> = ({
  webInterviews,
  active,
  onCloseModal,
}) => {
  const selected = useMemo(
    () => webInterviews.filter((w) => w.selected),
    [webInterviews]
  )

  const [selectedGuideGuid, setSelectedGuideGuid] = useState('')
  const onChangeSelectBox = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGuideGuid(event.target.value)
  }

  // 選択したWeb面接群に面接ガイドが紐付いているかの判定 / 1面接でも紐付いていたらtrue
  const containConfigured = useMemo(
    () => selected.filter((w) => w.interviewGuide).length > 0,
    [selected]
  )

  // Formの値未設定の空文字と、面接ガイド未設定を意味するGuideのGUIDを""にするのをFormで未設定を除外するために区別したい
  // GUIDに使用されない文字(記号)を含めた特別な文字列を定義、その文字列のときは、面接ガイドを空にするように設定して実現する
  const deleteInterviewGuideSpecialString = '//UNSET-GUIDE##'

  const canSubmit = selected.length > 0 && !!selectedGuideGuid

  const guideList = useListWebInterviewGuides()
  const intewviewGuideOptions: DropdownOption[] = useMemo(
    () =>
      // 未設定に戻す(GUID=""でリクエストする)を追加する必要があるため、Optionの先頭にJoinする
      [
        { label: '未設定に戻す', value: deleteInterviewGuideSpecialString },
      ].concat(guideList.map((g) => ({ label: g.name, value: g.guid }))),
    [guideList]
  )

  const onClose = onCloseModal

  const { mutateAsync: changeWebInterviewGuides } =
    useChangeWebInterviewGuideOfWebInterviewsMutation()

  const { permittedRpcs } = AuthzContainer.useContainer()

  const onSubmit = useCallback(async () => {
    if (!selectedGuideGuid) {
      captureException(new Error('InterviewGuideGuid is undefined'))
      commonErrorToast()
      return
    }

    const guideGuid =
      selectedGuideGuid === deleteInterviewGuideSpecialString
        ? ''
        : selectedGuideGuid
    try {
      await changeWebInterviewGuides({
        webInterviewGuids: selected.map((w) => w.guid),
        guideGuid: guideGuid,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    toast(`${selected.length}件の面接の面接ガイドを変更しました`)
    onClose()
  }, [selected, onClose, changeWebInterviewGuides, selectedGuideGuid])

  return (
    <Modal
      active={active}
      title="面接ガイドを一括設定"
      onClose={onClose}
      size="l"
      stopScroll
    >
      <ModalBody>
        <DescriptionWrapper>
          <Count>{selected.length}</Count>
          <InlineTxt> 件の面接に対してガイドを一括設定します。</InlineTxt>
          {containConfigured && (
            <Description>
              設定済のガイドは、上書きされますのでご注意ください。
            </Description>
          )}
        </DescriptionWrapper>
        <WeakColorSeparator />
        <GuideWrapper>
          <Row>
            <Label>面接ガイド</Label>
            <DropdownWrapper>
              <RequireTxt size="s">必須</RequireTxt>
              <Dropdown
                size="ll"
                onChange={onChangeSelectBox}
                options={intewviewGuideOptions}
                placeholder="選択してください"
              />
            </DropdownWrapper>
          </Row>
          <LinkToGuideCreationWrapper
            visible={permittedRpcs?.includes('CreateWebInterviewGuide')}
          >
            <Txt>
              <SecondaryLink
                to={INTERNAL_PATHS.newWebInterviewGuide}
                comlinkPushParams={{
                  action:
                    'click_new_web_interview_guide_link_on_bulk_change_web_interview_guides_modal',
                }}
              >
                新しいガイドの追加はこちら
              </SecondaryLink>
            </Txt>
          </LinkToGuideCreationWrapper>
        </GuideWrapper>
        <WeakColorSeparator />
        <ListWrapper>
          <ListLabelTxt>対象の面接</ListLabelTxt>
          <ListContent>
            <Ul>
              {selected.map((webInterview) => {
                return (
                  <Li key={webInterview.guid}>
                    <Txt>{webInterview.name}</Txt>
                  </Li>
                )
              })}
            </Ul>
          </ListContent>
        </ListWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_change_web_interview_guides_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={onSubmit}
            comlinkPushParams={{
              action: 'click_change_web_interview_guides_button',
            }}
          >
            登録
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const GuideWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Row = styled.div`
  display: flex;
`

const DropdownWrapper = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: start;
`

const RequireTxt = styled(_RequireTxt)`
  font-weight: normal;
  margin-right: 6px;
  white-space: nowrap;
`

const Label = styled(Txt).attrs({ size: 'm' })`
  flex: 2.2;
  line-height: 32px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  display: inline-block;
  line-height: 1;
`

const InlineTxt = styled(Txt).attrs({ size: 'm' })`
  display: inline-block;
`

const Description = styled(Txt).attrs({ size: 'm', bold: true })``

const ListLabelTxt = styled(Txt).attrs({ size: 'm' })`
  flex: 1.2;
`

const ListWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  justify-content: start;
`
const ListContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  overflow-y: auto;
  max-height: 150px;
  flex: 3;
`

const Ul = styled.ul`
  padding-left: 30px;
`

const Li = styled.li`
  font-size: ${theme.fontSize.m};
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`

const LinkToGuideCreationWrapper = styled.div<{ visible?: boolean }>`
  display: flex;
  justify-content: end;
  width: 100%;
  padding-top: 16px;

  ${(props) => {
    // 面接ガイド作成ページへのリンクがある場合/ない場合でモーダルの高さが変わらないようにするため、visibilityで表示を制御している
    if (!props.visible) {
      return 'visibility: hidden'
    }
  }}
`
