import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { WebInterviewDuration } from '@blue-agency/proton/im'
import {
  WebInterviewAssignmentInput,
  WebInterviewAssignee,
  WebInterviewAssigneeRole,
} from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { assertNever } from '@/assertions'
import type { Assignee, Assignment } from '@/services/webInterviewService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateWebInterviewsRequestValue = {
  count: number
  name: string
  assignment: Assignment
  assignees?: Assignee[]
  guideGuid: string | undefined
  durationMinutes?: number
  scheduledStartTime?: Date
}

const encodeAssignee = (assignee: Assignee): WebInterviewAssignee => {
  const encodedAssignee = new WebInterviewAssignee()
  const assigneeRole = (() => {
    switch (assignee.role) {
      case 'interviewer':
        return WebInterviewAssigneeRole.INTERVIEWER
      case 'viewer':
        return WebInterviewAssigneeRole.VIEWER
    }
  })()

  encodedAssignee.setStaffGuid(assignee.guid)
  encodedAssignee.setAssigneeRole(assigneeRole)
  return encodedAssignee
}

export function useRequestCreateWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateWebInterviews = useCallback(
    async (value: CreateWebInterviewsRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateWebInterviewsRequest()
      const assignmentInput = new WebInterviewAssignmentInput()
      switch (value.assignment) {
        case 'specified':
          const specifiedInput = new WebInterviewAssignmentInput.Specified()
          const assigneeList = value.assignees?.map(encodeAssignee) ?? []
          specifiedInput.setAssigneesList(assigneeList)
          specifiedInput.setSpecified(true)
          assignmentInput.setSpecified(specifiedInput)
          break
        case 'unspecified':
          assignmentInput.setUnspecified(true)
          break
        case 'unspecified-cannot-view-detail':
          assignmentInput.setUnspecifiedCannotViewDetail(true)
          break
        default:
          assertNever(value.assignment)
      }

      req.setCount(value.count)
      req.setName(value.name)
      req.setAssignmentInput(assignmentInput)
      if (value.guideGuid !== undefined) {
        req.setWebInterviewGuideGuid(value.guideGuid)
      }

      const duration = new WebInterviewDuration()
      if (value.durationMinutes === undefined) {
        duration.setUnspecified(true)
      } else {
        const specified = new WebInterviewDuration.Specified()
        specified.setSpecified(true)
        specified.setDurationSeconds(value.durationMinutes * 60)
        duration.setSpecified(specified)
      }
      req.setDuration(duration)

      if (value.scheduledStartTime !== undefined) {
        req.setScheduledStartTime(Timestamp.fromDate(value.scheduledStartTime))
      }

      return callBizHuttBffService(
        'createWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateWebInterviews,
  }
}
