import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import {
  UpdateWebInterviewGuideRequest,
  WebInterviewGuideInput,
} from '@blue-agency/proton/biz_hutt_bff'
import { secondsMax } from '@/services/webInterviewGuideService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateWebInterviewGuideRequestValue = {
  guid: string
  name: string
  parts: ReadonlyArray<{
    startOffsetMin: number
    title: string
    description: string
  }>
}

export function useRequestUpdateWebInterviewGuide() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateWebInterviewGuide = useCallback(
    async (value: UpdateWebInterviewGuideRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateWebInterviewGuideRequest()
      const guideInput = new WebInterviewGuideInput()
      guideInput.setName(value.name)

      const parts = value.parts.map((part, index) => {
        const partInput =
          new WebInterviewGuideInput.WebInterviewGuidePartInput()
        partInput.setTitle(part.title)
        partInput.setDescription(part.description)
        let durationSeconds: number
        if (index === value.parts.length - 1) {
          // 最後のパートのdurationは 180分 - 開始オフセット で計算する
          durationSeconds = secondsMax - 60 * part.startOffsetMin
        } else {
          const nextPart = value.parts[index + 1]
          durationSeconds = 60 * (nextPart.startOffsetMin - part.startOffsetMin)
        }
        partInput.setDurationSeconds(durationSeconds)
        return partInput
      })

      guideInput.setPartsList(parts)
      guideInput.setFirstPartStartSeconds(value.parts[0].startOffsetMin * 60)
      req.setWebInterviewGuide(guideInput)
      req.setWebInterviewGuideGuid(value.guid)

      return callBizHuttBffService(
        'updateWebInterviewGuide',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateWebInterviewGuide,
  }
}
