import { useCallback, useEffect } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { UpdateSelectionStepMasterRequestValue } from '@/services/bffService'
import { useUpdateSelectionStepMaster } from './useUpdateSelectionStepMaster'

type Form = Omit<UpdateSelectionStepMasterRequestValue, 'guid'>
const formSchema: yup.SchemaOf<Form> = yup
  .object({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
    type: yup.number().required(),
  })
  .defined()

type Args = {
  selectionStepMaster: UpdateSelectionStepMasterRequestValue
  onSuccessUpdate: () => void
}

export const useUpdateModal = ({
  selectionStepMaster,
  onSuccessUpdate,
}: Args) => {
  const { mutateAsync } = useUpdateSelectionStepMaster()

  const formCtx = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      name: selectionStepMaster.name,
      type: selectionStepMaster.type,
    },
  })

  const setValue = formCtx.setValue
  useEffect(() => {
    setValue('name', selectionStepMaster.name)
    setValue('type', selectionStepMaster.type)
  }, [selectionStepMaster, setValue])

  const submit = useCallback(
    async (data: Form) => {
      try {
        await mutateAsync({ ...data, guid: selectionStepMaster.guid })
      } catch (e) {
        Sentry.captureException(e)
        commonErrorToast()
        return
      }
      toast('選考ステップを保存しました')
      onSuccessUpdate()
      formCtx.reset()
    },
    [mutateAsync, onSuccessUpdate, selectionStepMaster, formCtx]
  )

  const update = useCallback(() => {
    formCtx.handleSubmit(submit)()
  }, [formCtx, submit])

  return {
    formCtx,
    update,
  }
}
