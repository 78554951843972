import { Icon, RequireTxt, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  margin-top: 20px;
`

const RowLabel = styled.div`
  height: 32px;
  display: grid;
  grid-template-columns: 120px 100px 35px;
  align-items: center;
  grid-template-areas: 'main-label sub-label required-label';
`

const RowLabelMain = styled(Txt)`
  grid-area: main-label;
`

const RowLabelSub = styled(Txt).attrs({
  size: 's',
  bold: true,
})`
  grid-area: sub-label;
  padding-bottom: 2px;
`

const RowLabelRequired = styled(RequireTxt).attrs({
  children: '必須',
  size: 's',
})`
  grid-area: required-label;
  padding-bottom: 2px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`

const Add = ({ text, color }: { text: string; color?: string }) => (
  <>
    <Icon name="add" width="12px" />
    <AddTxt color={color}>{text}</AddTxt>
  </>
)

const AddTxt = styled(Txt).attrs({
  size: 's',
})`
  margin-left: 5px;
`

export {
  Row,
  RowLabel,
  RowLabelMain,
  RowLabelSub,
  RowLabelRequired,
  Separator,
  Add,
}
