import { useCallback, useState } from 'react'
import { theme, Icon as _Icon, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { generatePath, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useModal } from '@/hooks/useModal'
import { INTERNAL_PATHS } from '@/services/urlService'
import { maxContentsLength } from '@/services/videoSeminarService'
import { Content, ContentForList } from './Content'
import { DeleteContentModal } from './DeleteContentModal'

type Props = {
  videoSeminarGuid: string
  contents: ContentForList[]
}
export const ContentsPage: React.VFC<Props> = (props) => {
  const deleteContentModal = useModal()
  const history = useHistory()

  const editContent = useCallback(
    (videoSeminarContentGuid: string) => {
      history.push(
        generatePath(INTERNAL_PATHS.editVideoSeminarContent, {
          videoSeminarGuid: props.videoSeminarGuid,
          videoSeminarContentGuid,
        })
      )
    },
    [history, props.videoSeminarGuid]
  )

  const [targetContent, setTargetContent] = useState<ContentForList>()

  const deleteContent = useCallback(
    (content: ContentForList) => {
      setTargetContent(content)
      deleteContentModal.open()
    },
    [deleteContentModal]
  )

  if (props.contents.length === 0)
    return (
      <Wrapper>
        <EmptyContent>
          <EmptyMessage>コンテンツを登録しましょう</EmptyMessage>
          <RegisterButtonLink
            to={generatePath(INTERNAL_PATHS.newVideoSeminarContent, {
              videoSeminarGuid: props.videoSeminarGuid,
            })}
          >
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_register_video_seminar_content_button_on_empty_content_list_page',
              }}
            >
              コンテンツ登録
            </PrimaryButton>
          </RegisterButtonLink>
        </EmptyContent>
      </Wrapper>
    )

  const enableAddContent = props.contents.length < maxContentsLength
  return (
    <>
      <Wrapper>
        <List>
          {props.contents.map((content) => (
            <Content
              key={content.guid}
              content={content}
              onClickEdit={editContent}
              onClickDelete={deleteContent}
            />
          ))}
        </List>
        <MenuArea>
          {enableAddContent ? (
            <MenuButton
              to={generatePath(INTERNAL_PATHS.newVideoSeminarContent, {
                videoSeminarGuid: props.videoSeminarGuid,
              })}
            >
              <Icon name="add" />
              追加
            </MenuButton>
          ) : (
            <DisabledMenuButton>
              <Icon name="add" />
              追加
            </DisabledMenuButton>
          )}
          {props.contents.length > 1 ? (
            <ReorderButton
              to={generatePath(INTERNAL_PATHS.reorderVideoSeminarContents, {
                videoSeminarGuid: props.videoSeminarGuid,
              })}
            >
              <Icon name="reorder" />
              表示順
            </ReorderButton>
          ) : (
            <DisabledReorderButton>
              <Icon name="reorder" />
              表示順
            </DisabledReorderButton>
          )}
        </MenuArea>
      </Wrapper>
      {deleteContentModal.active && targetContent && (
        <DeleteContentModal
          videoSeminarGuid={props.videoSeminarGuid}
          videoSeminarContent={targetContent}
          onClose={deleteContentModal.close}
        />
      )}
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const List = styled.div`
  margin-top: 12px;
  width: 660px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;
`

const MenuArea = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
`

const MenuButton = styled(Link)`
  color: ${theme.color.navy[1]};
  text-decoration: none;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`

const ReorderButton = styled(Link)`
  margin-left: 26px;
  color: ${theme.color.navy[1]};
  text-decoration: none;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`

const DisabledMenuButton = styled(Txt).attrs({ color: theme.color.navy[2] })``

const DisabledReorderButton = styled(Txt).attrs({
  color: theme.color.navy[2],
  size: 'l',
})`
  margin-left: 26px;
  display: inline-block;
`

const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 3px;
`

const EmptyContent = styled.div`
  width: 100%;
  height: 80px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.color.white[1]};
`

const EmptyMessage = styled(Txt).attrs({ size: 'l' })``

const RegisterButtonLink = styled(Link)`
  margin-top: 28px;
  color: inherit;
  text-decoration: none;
`
