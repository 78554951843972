import { useCallback } from 'react'
import {
  theme,
  toast,
  Icon,
  NewTabLink,
  Modal,
  Toggle as _Toggle,
  Tooltip,
  Txt,
  PrimaryLinkTxt,
  SecondaryLinkTxt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'
import urlJoin from 'url-join'
import { Box } from '@/components/Box'
import { writeTextToClipboard } from '@/services/clipboardService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { INTERNAL_PATHS, EXTERNAL_PATHS } from '@/services/urlService'

type Props = {
  stepName?: string
  stepGuid: string
  isSharing: boolean
  changeSharingType: () => void
  close: () => void
}

export const ShareSelectionModal: React.VFC<Props> = ({
  stepName,
  stepGuid,
  isSharing,
  changeSharingType,
  close,
}) => {
  const path =
    stepGuid === ''
      ? ''
      : generatePath(INTERNAL_PATHS.selectionRecInterview, {
          selectionStepGuid: stepGuid,
        })
  const url = urlJoin(window.location.origin, path)

  const copyUrl = useCallback(() => {
    writeTextToClipboard(url)
    toast('評価画面リンクをコピーしました')
  }, [url])

  return (
    <Modal active title="評価画面の共有" onClose={close} size="l">
      <ModalContent>
        <ToggleArea>
          <Box display="flex" alignItems="center">
            <Txt>一般権限への共有</Txt>
            <div
              data-tip
              data-for={TOOLTIP_ID_MAP.shareSelection}
              style={{ cursor: 'pointer' }}
            >
              <QuestionIcon />
            </div>
            <Tooltip id={TOOLTIP_ID_MAP.shareSelection} arrowPosition="topLeft">
              <Txt>
                この設定をONにすると、「すべての一般権限のユーザー」が、
                <br />
                この応募者の評価画面を閲覧可能になります。
              </Txt>
              <HelpArea>
                <NewTabLink
                  href={EXTERNAL_PATHS.help.shareSelection}
                  action="open_help_page_on_share_selection_modal"
                >
                  詳細はこちら
                </NewTabLink>
              </HelpArea>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <Toggle checked={isSharing} onChange={changeSharingType}></Toggle>
            <Txt>{isSharing ? '共有中' : '共有停止中'}</Txt>
          </Box>
        </ToggleArea>
        <WeakColorSeparator />
        {stepGuid === '' ? (
          <InfoBox>
            <LightBulbIcon />
            <Txt>評価画面のリンクは選考登録後に発行されます。</Txt>
          </InfoBox>
        ) : (
          <CopyArea>
            <CopyTitle>
              <Txt>評価画面</Txt>
              <StepNameTxt>（{stepName}）</StepNameTxt>
            </CopyTitle>
            <UrlArea>
              <UrlBox>
                <Link to={path} target="_blank" rel="noopener noreferrer">
                  <LinkTxt>{url}</LinkTxt>
                </Link>
              </UrlBox>
              <CopyButton onClick={copyUrl}>
                <PrimaryLinkTxt>リンクをコピー</PrimaryLinkTxt>
              </CopyButton>
            </UrlArea>
          </CopyArea>
        )}
      </ModalContent>
      <WeakColorSeparator />
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={close}
          comlinkPushParams={{
            action: 'click_close_button_on_share_selection_modal',
          }}
        >
          閉じる
        </TertiaryButton>
      </ButtonArea>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const ToggleArea = styled.div`
  display: inline-flex;
  padding-bottom: 20px;
`

const Toggle = styled(_Toggle)`
  margin-left: 72px;
  margin-right: 8px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question', size: 'l' })`
  margin-left: 4px;
  margin-top: 2px;
`

const LightBulbIcon = styled(Icon).attrs({ name: 'light-bulb', size: 'l' })`
  margin-right: 8px;
`
const HelpArea = styled.div`
  margin-top: 12px;
  text-align: right;
`

const InfoBox = styled.div`
  display: inline-flex;
  width: 100%;
  background-color: ${theme.color.gray[5]};
  padding: 16px 20px;
  margin-top: 20px;
`

const CopyArea = styled.div`
  padding-top: 20px;
`

const UrlArea = styled.div`
  display: inline-flex;
  padding-top: 8px;
`

const UrlBox = styled.div`
  background-color: ${theme.color.gray[5]};
  padding: 4px 8px;
  width: 520px;
`

const LinkTxt = styled(SecondaryLinkTxt)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CopyButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
`

const CopyTitle = styled.div`
  display: flex;
  align-items: end;
`

const StepNameTxt = styled(Txt).attrs({ size: 's' })`
  margin-left: 4px;
  color: ${theme.color.gray[1]};
`
