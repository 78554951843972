import { useState, useCallback, ChangeEvent, ReactNode } from 'react'
import {
  WeakColorSeparator,
  Modal,
  Txt,
  CheckBox as _CheckBox,
  theme,
  Icon as _Icon,
  ErrorBox,
} from '@blue-agency/rogue'
import {
  Dropdown,
  DropdownOption,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { FormRow } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Count as Count_ } from '@/components/Count'
import { InfoBox } from '@/components/InfoBox'
import { DeadlinePicker } from '@/pages/ApplicantPage/Content/Selection/DeadlinePicker'
import { bufferHours } from '@/services/recInterviewTemplateService'

export type ReqMode =
  | {
      type: 'guids'
      applicantsLength: number
    }
  | {
      type: 'searchCondition'
    }
export type ErrorType = 'overLimit' | 'pastDate' | 'includeNotInProgress'

type Props = {
  mode: ReqMode
  error?: ErrorType

  deadline: Date

  selectionStepMasterGuidOptions: DropdownOption[]
  hasSelectionStepMasterGuidBlurError: boolean

  recInterviewTemplateGuidOptions: DropdownOption[]
  hasRecInterviewTemplateGuidBlurError: boolean

  showDeadlinePicker: boolean
  showDeadlineChangeWarning: boolean

  onSubmit: () => void
  onClose: () => void
  onChangeDeadline: (date: Date | null) => void
  onChangeSelectionStepMasterGuid: (
    event: ChangeEvent<HTMLSelectElement>
  ) => void
  onChangeRecInterviewTemplateGuid: (
    event: ChangeEvent<HTMLSelectElement>
  ) => void
}

export const BatchReqRecInterviewAnswerModal: React.VFC<Props> = (props) => {
  const [confirmed, setConfirmed] = useState(false)
  const toggleConfirmed = useCallback(() => {
    setConfirmed((prevState) => !prevState)
  }, [])
  const canSubmit =
    (props.mode.type === 'searchCondition' ||
      props.mode.applicantsLength > 0) &&
    confirmed &&
    props.error === undefined

  return (
    <Modal
      active
      title="選考を登録（録画面接）"
      size="l"
      onClose={props.onClose}
      overflowVisible
      stopScroll
    >
      <ModalBody>
        {props.error && (
          <ErrorMessage>{errorMessageMap[props.error]}</ErrorMessage>
        )}
        {props.mode.type === 'guids' && (
          <>
            <Count count={props.mode.applicantsLength} />
            <InlineTxt>件の応募者に選考（録画面接）を登録します。</InlineTxt>
            <InfoBox
              info={[
                '「現在の選考」は通過に変更され、新しい選考が登録されます。',
                '処理の完了まで、数分かかる場合があります。',
                <ProcessInfoWrapper key="info-process-key">
                  <Txt>進捗は</Txt>
                  <ProcessIcon />
                  <Txt>処理状況から確認ができます。</Txt>
                </ProcessInfoWrapper>,
              ]}
            />
          </>
        )}
        {props.mode.type === 'searchCondition' && (
          <>
            <Txt>
              指定した検索条件に一致したすべての応募者に選考（録画面接）を登録します。
              <br />
              検索条件に一致する応募者が処理中に追加された場合、その応募者も対象になります。
            </Txt>
            <InfoBox
              info={[
                '「現在の選考」は通過に変更され、新しい選考が登録されます。',
                '処理の完了まで、数十分かかる場合があります。（目安：5,000件30分程度）',
                <ProcessInfoWrapper key="info-process-key">
                  <Txt>進捗は</Txt>
                  <ProcessIcon />
                  <Txt>処理状況から確認ができます。</Txt>
                </ProcessInfoWrapper>,
              ]}
            />
          </>
        )}
        <Row title="選考" required>
          <Dropdown
            name="selectionStepMasterGuid"
            onChange={props.onChangeSelectionStepMasterGuid}
            options={props.selectionStepMasterGuidOptions}
            onBlurError={
              props.hasSelectionStepMasterGuidBlurError
                ? '選択してください'
                : undefined
            }
            size="ll"
            disabled={
              props.error === 'overLimit' ||
              props.error === 'includeNotInProgress'
            }
          />
        </Row>
        <Row title="テンプレート" required>
          <Dropdown
            name="recInterviewTemplateGuid"
            onChange={props.onChangeRecInterviewTemplateGuid}
            options={props.recInterviewTemplateGuidOptions}
            onBlurError={
              props.hasRecInterviewTemplateGuidBlurError
                ? '選択してください'
                : undefined
            }
            size="ll"
            disabled={
              props.error === 'overLimit' ||
              props.error === 'includeNotInProgress'
            }
          />
        </Row>
        <DeadlineWrapper>
          {props.showDeadlinePicker && (
            <Row title="提出期限" required>
              <DeadlinePicker
                deadline={props.deadline}
                onChangeDeadline={props.onChangeDeadline}
                disabled={
                  props.error === 'overLimit' ||
                  props.error === 'includeNotInProgress'
                }
              />
            </Row>
          )}
        </DeadlineWrapper>
        {props.showDeadlinePicker && props.showDeadlineChangeWarning && (
          <Warning>
            <Icon />
            <WarningBox>
              テンプレートを再選択したため、提出期限が自動変更されました。
            </WarningBox>
          </Warning>
        )}
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ConfirmRow title="確認" required>
          <CheckBox
            checked={confirmed}
            onChange={toggleConfirmed}
            size={18}
            checkboxLabel="内容に誤りがないことを確認しました"
          />
        </ConfirmRow>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_batch_request_selection_rec_interview_answer_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={props.onSubmit}
            comlinkPushParams={{
              action:
                'click_batch_request_selection_rec_interview_answer_button',
            }}
          >
            一括登録
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrCount = styled(Count_).attrs({
  color: theme.color.red[2],
  size: theme.fontSize.s,
})`
  display: inline-block;
`

const ErrorMessage = styled(ErrorBox)`
  margin-bottom: 20px;
`

const Count = styled(Count_)`
  display: inline-block;
  line-height: 1;
`

const InlineTxt = styled(Txt).attrs({ size: 'm' })`
  display: inline-block;
`

const titleWidth = '130px'
const Row = styled(FormRow).attrs({
  titleWidth: titleWidth,
  subtitleWidth: '0',
})`
  margin-top: 20px;
`

const ConfirmRow = styled(FormRow).attrs({
  titleWidth: titleWidth,
  subtitleWidth: '0',
})`
  margin-bottom: 20px;
`

const Warning = styled.div`
  display: inline-flex;
  background-color: ${theme.color.orange[2]};
  padding: 10px;
  margin-top: 8px;
  margin-left: 158px;
`

const Icon = styled(_Icon).attrs({ name: 'exclamation', size: 'm' })`
  padding-left: 5px;
  margin-top: 2px;
  color: ${theme.color.orange[1]};
`

const DeadlineWrapper = styled.div`
  height: 32px;
  margin-top: 20px;
`

const WarningBox = styled(Txt).attrs({ size: 'm' })`
  padding-left: 5px;
  color: ${theme.color.orange[1]};
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  padding: 10px 20px 20px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`

const ProcessInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ProcessIcon = styled(_Icon).attrs({ name: 'process' })`
  display: 'inline-block';
  width: 18px;
  height: 18px;
  margin: 0 5px;
`

const overLimit = 10000
const errorMessageMap: Record<ErrorType, ReactNode> = {
  overLimit: (
    <>
      対象件数が上限の
      <ErrCount count={overLimit} />
      件を超えています。
      <br />
      件数を減らして、再度実行してください。
    </>
  ),
  pastDate: `提出期限は現在時刻から${bufferHours}時間以上先で設定してください。`,
  includeNotInProgress: (
    <>
      「新着」「選考中」以外の応募者が含まれている可能性があります。
      <br />
      検索条件を確認してください。
    </>
  ),
}
