import React, { useMemo } from 'react'
import { StaffIcon } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { Avatar } from '@/pages/ControlCenterPage/Avatar'
import { ControlCenterTooltip } from '@/pages/ControlCenterPage/ControlCenterTooltip'
import type { Interviewer } from '@/pages/ControlCenterPage/types'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

type Props = {
  interviewers: Interviewer[]
  interviewGuid: string
}

type InterviewerAvatar = {
  type: 'interviewer'
  staffGuid: string
  familyName: string
  fullName: string
  iconColorCode: string
  joined: boolean
}

type OmittedAvatar = {
  type: 'omit'
  label: string
  // 面接にアサインされているが、未入室であるような面接官が省略表示の中に存在するか否か
  containsAssignedButNotJoined: boolean
}

type AvatarItem = InterviewerAvatar | OmittedAvatar

// 3人以下の場合は、全員のアイコンを表示する。
// 4人以上の場合は、3人目の空間を利用して「＋ 2」のように隠れている人数を表示し、Tooltipで含まれている人の名前を表示する。
export const OmittedInterviewerAvatarList: React.VFC<Props> = (props) => {
  const wrappedList = useMemo<AvatarItem[]>(() => {
    if (props.interviewers.length <= 3) {
      return props.interviewers.map(
        ({ staffGuid, familyName, fullName, iconColorCode, joined }) => {
          return {
            type: 'interviewer',
            staffGuid,
            familyName,
            fullName,
            iconColorCode,
            joined,
          }
        }
      )
    }

    const containsAssignedButNotJoined = props.interviewers
      .slice(2)
      .some((interviewer) => !interviewer.joined)

    return [
      {
        type: 'interviewer',
        staffGuid: props.interviewers[0].staffGuid,
        familyName: props.interviewers[0].familyName,
        fullName: props.interviewers[0].fullName,
        iconColorCode: props.interviewers[0].iconColorCode,
        joined: props.interviewers[0].joined,
      },
      {
        type: 'interviewer',
        staffGuid: props.interviewers[1].staffGuid,
        familyName: props.interviewers[1].familyName,
        fullName: props.interviewers[1].fullName,
        iconColorCode: props.interviewers[1].iconColorCode,
        joined: props.interviewers[1].joined,
      },
      {
        type: 'omit',
        label: `＋ ${props.interviewers.length - 2}`,
        containsAssignedButNotJoined,
      },
    ]
  }, [props.interviewers])

  return (
    <Wrapper>
      {wrappedList.map((item) => {
        return item.type === 'interviewer' ? (
          <Avatar
            key={item.staffGuid}
            role="interviewer"
            tooltipId={`${TOOLTIP_ID_MAP.interviewerFullNameOnControlCenter}-${item.staffGuid}-${props.interviewGuid}`}
            {...item}
          />
        ) : (
          <OmittedCountWrapper key="$OMIT$">
            <OmittedCount
              label={item.label}
              containsAssignedButNotJoined={item.containsAssignedButNotJoined}
              omitList={props.interviewers.slice(2)}
              tooltipId={`${TOOLTIP_ID_MAP.omittedInterviewersOnControlCenterOngoing}-${props.interviewGuid}`}
            />
          </OmittedCountWrapper>
        )
      })}
    </Wrapper>
  )
}

const OmittedCount: React.VFC<{
  label: string
  containsAssignedButNotJoined: boolean
  omitList: ReadonlyArray<Interviewer>
  tooltipId: string
}> = ({ label, containsAssignedButNotJoined, omitList, tooltipId }) => {
  const iconProps = useMemo(() => {
    if (containsAssignedButNotJoined) {
      return {
        textColor: theme.color.gray[1],
        color: theme.color.gray[4],
        dashedBorder: true,
      }
    }
    return {
      textColor: theme.color.white[1],
      color: theme.color.gray[3],
      dashedBorder: false,
    }
  }, [containsAssignedButNotJoined])

  return (
    <>
      <IconWrapper data-tip data-for={tooltipId}>
        <StaffIcon name={label} {...iconProps} />
      </IconWrapper>
      <ControlCenterTooltip tooltipId={tooltipId} arrowPosition="topRight">
        <OmittedDetailWrapper>
          {omitList.map((interviewer) => {
            return (
              <OmittedInterviewerItem key={interviewer.staffGuid}>
                {interviewer.joined ? (
                  <>
                    <ConnectingIcon visible />
                    <Txt>・{interviewer.fullName}</Txt>
                  </>
                ) : (
                  <>
                    <ConnectingIcon visible={false} />
                    <Txt color={theme.color.gray[1]}>
                      ・{interviewer.fullName}
                    </Txt>
                  </>
                )}
              </OmittedInterviewerItem>
            )
          })}
        </OmittedDetailWrapper>
      </ControlCenterTooltip>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`

const OmittedCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
`

const OmittedDetailWrapper = styled.div``

const OmittedInterviewerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ConnectingIcon = styled.div<{ visible: boolean }>`
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: ${theme.color.limeGreen[1]};

  ${({ visible }) => {
    if (!visible) {
      return css`
        visibility: hidden;
      `
    }
  }}
`
