import type { DropdownOption } from '@blue-agency/rogue/dist/im'
import { useQuery } from 'react-query'
import { useRequestListAllRecInterviewTemplatesForOption } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import {
  mapRecInterviewTemplateDeadline,
  RecInterviewTemplateDeadline,
} from '@/services/recInterviewTemplateService'

export function useRecInterviewTemplateOptions() {
  const listAllRecInterviewTemplatesForOptionRes =
    useListAllRecInterviewTemplatesForOption()

  const recInterviewTemplateGuidOptions: DropdownOption[] =
    listAllRecInterviewTemplatesForOptionRes.data
      ?.getTemplatesList()
      .map((t) => ({
        value: t.getGuid(),
        label: t.getName(),
      })) ?? []

  const deadlineMap = listAllRecInterviewTemplatesForOptionRes.data
    ?.getTemplatesList()
    .reduce<Record<string, RecInterviewTemplateDeadline>>(
      (map, item) => ({
        ...map,
        [item.getGuid()]: mapRecInterviewTemplateDeadline(item),
      }),
      {}
    )

  return { recInterviewTemplateGuidOptions, deadlineMap }
}

function useListAllRecInterviewTemplatesForOption() {
  const { requestListAllRecInterviewTemplatesForOption } =
    useRequestListAllRecInterviewTemplatesForOption()

  return useQuery(
    [QUERY_KEY.allRecInterviewTemplatesForOption],
    requestListAllRecInterviewTemplatesForOption
  )
}
