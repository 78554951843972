import { ChangeEvent, useCallback, useState } from 'react'
import {
  SelectionStatus,
  getSelectionStatusText,
} from '@/services/applicantService'

export type BatchAction =
  | 'delete'
  | 'finished'
  | 'notPassed'
  | 'reqRecInterviewAnswer'
  | 'updateRecInterviewDeadline'
type BatchActionOption = {
  value: BatchAction
  label: string
}

export const batchActionOptions: BatchActionOption[] = [
  {
    value: 'reqRecInterviewAnswer',
    label: '選考を登録する（録画面接）',
  },
  {
    value: 'updateRecInterviewDeadline',
    label: '提出期限を変更する（録画面接）',
  },
  {
    value: 'finished',
    label: `${getSelectionStatusText(
      SelectionStatus.SELECTION_STATUS_FINISHED
    )}に変更する`,
  },
  {
    value: 'notPassed',
    label: `${getSelectionStatusText(
      SelectionStatus.SELECTION_STATUS_NOT_PASSED
    )}に変更する`,
  },
  {
    value: 'delete',
    label: '応募者を削除する',
  },
]

export const useBatchAction = () => {
  const [batchAction, setBatchAction] = useState<BatchAction | undefined>(
    undefined
  )
  const onChangeBatchAction = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const target = e.target.value as BatchAction
      switch (target) {
        case 'delete':
          setBatchAction(target)
          break
        case 'finished':
          setBatchAction(target)
          break
        case 'notPassed':
          setBatchAction(target)
          break
        case 'reqRecInterviewAnswer':
          setBatchAction(target)
          break
        case 'updateRecInterviewDeadline':
          setBatchAction(target)
          break
        default:
          throw new Error('No corresponding batch action')
      }
    },
    []
  )

  return {
    batchAction,
    onChangeBatchAction,
  }
}
