import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListStaffsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListStaffs() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListStaffs = useCallback(async () => {
    const metadata = await buildMetadata()
    const req = new ListStaffsRequest()

    return callBizHuttBffService(
      'listStaffs',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff])

  return { requestListStaffs }
}
