import { useCallback } from 'react'
import {
  LOCAL_STORAGE_KEY,
  saveToLocalStorageJson,
  getFromLocalStorageJson,
  deleteItemFromLocalStorageJson,
} from '../storageService'

type ListType = 'webInterviews' | 'assignedWebInterviews'

export const useWebInterviewsStorage = () => {
  /**
   * ブラウザバック以外で一覧画面に戻るときのためにlocalStorageにqueryと一覧の種類を保存する
   */
  const saveQueryAndListToBack = useCallback(
    (type: ListType, guid: string, query: string) => {
      saveToLocalStorageJson(LOCAL_STORAGE_KEY.listToBack, guid, type)
      if (type === 'webInterviews') {
        saveToLocalStorageJson(
          LOCAL_STORAGE_KEY.webInterviewsQuery,
          guid,
          query
        )
      } else {
        saveToLocalStorageJson(
          LOCAL_STORAGE_KEY.assignedWebInterviewsQuery,
          guid,
          query
        )
      }
    },
    []
  )

  const getQuery = useCallback((type: ListType, guid: string) => {
    if (type === 'webInterviews') {
      return getFromLocalStorageJson(LOCAL_STORAGE_KEY.webInterviewsQuery, guid)
    } else {
      return getFromLocalStorageJson(
        LOCAL_STORAGE_KEY.assignedWebInterviewsQuery,
        guid
      )
    }
  }, [])

  const getListToBack = useCallback((guid: string): ListType => {
    const type = getFromLocalStorageJson(LOCAL_STORAGE_KEY.listToBack, guid)
    if (type === 'webInterviews' || type === 'assignedWebInterviews') {
      return type
    }
    return 'webInterviews' // default
  }, [])

  const clearStorages = useCallback((guid: string) => {
    deleteItemFromLocalStorageJson(LOCAL_STORAGE_KEY.listToBack, guid)
    deleteItemFromLocalStorageJson(LOCAL_STORAGE_KEY.webInterviewsQuery, guid)
    deleteItemFromLocalStorageJson(
      LOCAL_STORAGE_KEY.assignedWebInterviewsQuery,
      guid
    )
  }, [])

  return {
    saveQueryAndListToBack,
    getQuery,
    getListToBack,
    clearStorages,
  }
}
