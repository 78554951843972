import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  theme,
  Modal as M,
  Txt,
  WeakColorSeparator,
  CheckBox,
  Icon,
  Tooltip,
} from '@blue-agency/rogue'
import {
  DatePicker,
  Dropdown,
  DropdownOption,
  FormRow as FR,
  InputText,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import {
  getSelectionStatusText,
  getSelectionSubStatusText,
  isCorrespondingStatus,
  SearchCondition,
  SelectionStatus,
  SelectionSubStatus,
} from '@/services/applicantService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import {
  SearchConditionForm,
  SelectionStatusKey,
  SelectionSubStatusKey,
  validateRegisterTime,
} from './form'
import {
  formSchema,
  mapSearchCondition,
  mapSearchConditionForm,
  validateWordsCount,
} from './form'

type Props = {
  condition: SearchCondition
  totalLength: number
  selectionStepMasters: DropdownOption[]
  onClose: () => void
  onChange: (condition: SearchCondition) => void
  onSearch: (condition: SearchCondition) => void
}

export const SearchApplicantsModal: React.VFC<Props> = ({
  condition,
  totalLength,
  selectionStepMasters,
  onClose,
  onChange,
  onSearch,
}) => {
  const { register, watch, setValue, getValues, formState } =
    useForm<SearchConditionForm>({
      resolver: yupResolver(formSchema),
      mode: 'onChange',
      defaultValues: mapSearchConditionForm(condition),
    })

  const [keywordsError, setKeywordsError] = useState('')
  const [notesError, setNotesError] = useState('')
  const [registerTimeError, setRegisterTimeError] = useState('')

  const validate = (c: SearchCondition): boolean => {
    const keywordsValidateRes = validateWordsCount(c.keywords)
    const notesValidateRes = validateWordsCount(c.notes)
    const registerTimeValidateRes = validateRegisterTime(
      c.registerTime?.from,
      c.registerTime?.to
    )
    setKeywordsError(keywordsValidateRes.errorMessage)
    setNotesError(notesValidateRes.errorMessage)
    setRegisterTimeError(registerTimeValidateRes.errorMessage)

    return (
      keywordsValidateRes.valid &&
      notesValidateRes.valid &&
      registerTimeValidateRes.valid
    )
  }

  const watchAll = watch()
  useEffect(() => {
    // onChangeイベントのdebounce
    const id = setTimeout(() => {
      const c = mapSearchCondition(watchAll)
      const isValid = validate(c)
      if (!isValid) return
      // FIXME: storyshotsで「TypeError: onChange is not a function」が出る
      onChange && onChange(c)
    }, 500)

    return () => clearTimeout(id)
  }, [watchAll, onChange])

  const onStatusChange = useCallback(
    (statusKey: SelectionStatusKey) => (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.checked
      // 子サブステータスはcheckedを連動させる
      Object.keys(SelectionSubStatus)
        .filter((key) =>
          isCorrespondingStatus(
            SelectionStatus[statusKey],
            SelectionSubStatus[key as SelectionSubStatusKey]
          )
        )
        .forEach((key) => {
          setValue(`subStatuses.${key as SelectionSubStatusKey}`, val)
        })
      setValue(`statuses.${statusKey}`, val)
    },
    [setValue]
  )

  const watchSubStatuses = watch('subStatuses')
  const onSubStatusChange = useCallback(
    (subStatusKey: SelectionSubStatusKey) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.checked

        /**
         * 関連するステータスはcheckedを連動させる
         * ・子サブステータスが全てcheckedになったら親ステータスもcheckedにする
         * ・子サブステータスが1つでもuncheckedになったら親ステータスもuncheckedにする
         */

        // 「新着」ステータス
        if (
          isCorrespondingStatus(
            SelectionStatus.SELECTION_STATUS_ARRIVAL,
            SelectionSubStatus[subStatusKey]
          )
        ) {
          // 今のところサブステータスが1つだけなので、単純に連動させるだけでOK
          setValue('statuses.SELECTION_STATUS_ARRIVAL', val)
        }

        // 「選考中」ステータス
        if (
          isCorrespondingStatus(
            SelectionStatus.SELECTION_STATUS_IN_PROGRESS,
            SelectionSubStatus[subStatusKey]
          )
        ) {
          if (
            val &&
            // ↓「選考中」ステータスに紐づくサブステータスが全てcheckedになっているかどうかを確認している
            Object.keys(watchSubStatuses)
              .filter((key) =>
                isCorrespondingStatus(
                  SelectionStatus.SELECTION_STATUS_IN_PROGRESS,
                  SelectionSubStatus[key as SelectionSubStatusKey]
                )
              )
              .filter(
                // このChangeEventが起きたとき、該当サブステータスのwatchSubStatusesはcheck前のfalseになっているので除く
                (key) => (key as SelectionSubStatusKey) !== subStatusKey
              )
              .every((key) => watchSubStatuses[key as SelectionSubStatusKey])
          ) {
            setValue('statuses.SELECTION_STATUS_IN_PROGRESS', true)
          } else {
            setValue('statuses.SELECTION_STATUS_IN_PROGRESS', false)
          }
        }

        setValue(`subStatuses.${subStatusKey}`, val)
      },
    [setValue, watchSubStatuses]
  )

  const genStatusCheckboxParams = useMemo(
    () => genStatusCheckboxParamsBase({ register, onStatusChange }),
    [register, onStatusChange]
  )

  const genSubStatusCheckboxParams = useMemo(
    () => genSubStatusCheckboxParamsBase({ register, onSubStatusChange }),
    [register, onSubStatusChange]
  )

  const _onSearch = useCallback(() => {
    const values = getValues()
    const c = mapSearchCondition(values)
    const isValid = validate(c)
    if (!isValid) return
    onSearch(c)
    onClose()
  }, [getValues, onSearch, onClose])

  const currentSelectionStepLabel = '現在の\n選考ステップ'

  return (
    <Modal active title="応募者検索" onClose={onClose} size="full" stopScroll>
      <ModalContent>
        <ConditionColumn>
          <LeftFormRow title="フリーワード">
            <LeftInputText
              placeholder="氏名・電話番号・メールアドレス"
              {...register('keywords')}
              onBlurError={formState.errors.keywords?.message || keywordsError}
            />
          </LeftFormRow>
          <Separator />
          <LeftFormRow title="連携先応募者ID">
            <LeftInputText {...register('externalApplicantId')} />
          </LeftFormRow>
          <Separator />
          <LeftFormRow title="備考">
            <LeftInputText
              {...register('notes')}
              onBlurError={formState.errors.notes?.message || notesError}
            />
          </LeftFormRow>
          <Separator />
          <LeftFormRow title="登録日時">
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <DatePicker
                  value={watch('registerTimeFrom') ?? null}
                  onChange={(date) =>
                    setValue('registerTimeFrom', date ?? undefined)
                  }
                  onBlurError={registerTimeError}
                  errorNoWrap
                />
                <DatePickerTxt>から</DatePickerTxt>
              </Box>
              <Box display="flex" alignItems="center" mt="12px">
                <DatePicker
                  value={watch('registerTimeTo') ?? null}
                  onChange={(date) =>
                    setValue('registerTimeTo', date ?? undefined)
                  }
                />
                <DatePickerTxt>まで</DatePickerTxt>
              </Box>
            </Box>
          </LeftFormRow>
          <Separator />
          <LeftFormRow title={<MailFailureTitle />}>
            <CheckBox
              checkboxLabel="メール未到達のみ対象に含める"
              {...register('mailFailure')}
            />
          </LeftFormRow>
        </ConditionColumn>
        <ColumnSpacer />
        <ConditionColumn>
          <RightFormRow title="ステータス">
            <Box display="flex" flexDirection="column" mt="6px">
              <Box display="flex" flexDirection="column">
                <CheckBox
                  {...genStatusCheckboxParams('SELECTION_STATUS_ARRIVAL')}
                />
                <Box ml="30px">
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_NO_SELECTION_STEP'
                    )}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" mt="8px">
                <CheckBoxWithMT
                  {...genStatusCheckboxParams('SELECTION_STATUS_IN_PROGRESS')}
                />
                <Box ml="30px">
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE'
                    )}
                  />
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED'
                    )}
                  />
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT'
                    )}
                  />
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED'
                    )}
                  />
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT'
                    )}
                  />
                  <CheckBoxWithMT
                    {...genSubStatusCheckboxParams(
                      'SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP'
                    )}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" mt="8px">
                <CheckBoxWithMT
                  {...genStatusCheckboxParams('SELECTION_STATUS_PENDING')}
                />
              </Box>
              <Box display="flex" flexDirection="column" mt="8px">
                <CheckBoxWithMT
                  {...genStatusCheckboxParams('SELECTION_STATUS_NOT_PASSED')}
                />
              </Box>
              <Box display="flex" flexDirection="column" mt="8px">
                <CheckBoxWithMT
                  {...genStatusCheckboxParams('SELECTION_STATUS_FINISHED')}
                />
              </Box>
              <Box display="flex" flexDirection="column" mt="8px">
                <CheckBoxWithMT
                  {...genStatusCheckboxParams('SELECTION_STATUS_DECLINED')}
                />
              </Box>
            </Box>
          </RightFormRow>
          <Separator />
          <RightFormRow title={currentSelectionStepLabel} subtitle="ステップ名">
            <Dropdown
              size="l"
              options={selectionStepMasters}
              allowEmpty
              {...register('currentSelectionStepMasterGuid')}
            />
          </RightFormRow>
          <RightFormRow subtitle="連携先求人名">
            <InputText
              size="l"
              {...register('currentSelectionStepExternalRecruitmentName')}
            />
          </RightFormRow>
          <SelectionStepRow>
            <SelctionStepTitle />
            <SelctionStepSubTitle>連携先ステップ名</SelctionStepSubTitle>
            <InputText
              size="l"
              {...register('currentSelectionStepExternalSelectionName')}
            />
          </SelectionStepRow>
        </ConditionColumn>
      </ModalContent>
      <WeakColorSeparator />
      <ModalFooter>
        <CountWrapper>
          <Txt>該当数</Txt>
          <Count>{digitSeparator(totalLength)}</Count>
          <Txt>件</Txt>
        </CountWrapper>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_cancel_button_on_search_applicants_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={_onSearch}
            comlinkPushParams={{
              action: 'click_search_button_on_search_applicants_modal',
            }}
          >
            検索
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const MailFailureTitle = () => (
  <Box display="flex" alignItems="center">
    <Txt>メール未到達</Txt>
    <div
      data-tip
      data-for={TOOLTIP_ID_MAP.searchApplicantsMailFailure}
      style={{ cursor: 'pointer' }}
    >
      <QuestionIcon />
    </div>
    <Tooltip
      id={TOOLTIP_ID_MAP.searchApplicantsMailFailure}
      arrowPosition="topLeft"
    >
      登録されたメールアドレスへのメール送信が失敗した応募者
    </Tooltip>
  </Box>
)

const genStatusCheckboxParamsBase =
  (params: {
    register: UseFormRegister<SearchConditionForm>
    onStatusChange: (
      statusKey: SelectionStatusKey
    ) => (e: ChangeEvent<HTMLInputElement>) => void
  }) =>
  (key: SelectionStatusKey) => ({
    ...params.register(`statuses.${key}`),
    checkboxLabel: getSelectionStatusText(SelectionStatus[key]),
    onChange: params.onStatusChange(key),
  })

const genSubStatusCheckboxParamsBase =
  (params: {
    register: UseFormRegister<SearchConditionForm>
    onSubStatusChange: (
      subStatusKey: SelectionSubStatusKey
    ) => (e: ChangeEvent<HTMLInputElement>) => void
  }) =>
  (key: SelectionSubStatusKey) => ({
    ...params.register(`subStatuses.${key}`),
    checkboxLabel: getSelectionSubStatusText(SelectionSubStatus[key]),
    onChange: params.onSubStatusChange(key),
  })

function digitSeparator(digit: number) {
  return String(digit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const Modal = styled(M).attrs({ overflowVisible: true })`
  max-width: 1020px;
  max-height: 724px;
`

const ModalContent = styled.div`
  display: flex;
  flex: 1;
  padding: 0 20px 20px;
`

const ConditionColumn = styled.div``

const ColumnSpacer = styled.div`
  width: 50px;
`

const ModalFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 77px;
  padding: 0 20px;
`

const ButtonGroup = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const CountWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  padding: 12px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  line-height: 1;
  margin: 0 4px;
`

const LeftFormRow = styled(FR).attrs({
  titleWidth: '132px',
  subtitleWidth: '0',
})`
  width: 460px;
  margin-top: 16px;
`

/* NOTE: FormRowとの組合せで `size: 'max'` だけでは幅いっぱいにならないのwidthを指定 */
const LeftInputText = styled(InputText).attrs({ size: 'max' })`
  width: 300px;
`

const RightFormRow = styled(FR).attrs({
  titleWidth: '102px',
  subtitleWidth: '80px',
})`
  width: 470px;
  margin-top: 16px;
`

/* NOTE: FormRowの `必須` の表示幅を取れないので独自定義 */
const SelectionStepRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const SelctionStepTitle = styled.div`
  width: 102px;
`

const SelctionStepSubTitle = styled(Txt).attrs({ size: 's', bold: true })`
  width: 96px;
  margin-right: 12px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 16px;
`

const DatePickerTxt = styled(Txt).attrs({ size: 'm' })`
  margin-left: 12px;
`

const CheckBoxWithMT = styled(CheckBox)`
  margin-top: 4px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question' })`
  margin-left: 4px;
  margin-top: 2px;
`
