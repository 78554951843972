import { getInternalUrls } from '@blue-agency/im-shared-front'
import {
  ListVideoSeminarsResponse,
  GetVideoSeminarResponse,
} from '@blue-agency/proton/web/v2/biz_hutt_bff'
import assert from 'assert'
import { assertNever } from '@/assertions'
import { Cookie } from '../cookieService'

const ProtoMovieTranscodeStatus = GetVideoSeminarResponse.MovieTranscodeStatus

export const maxContentsLength = 100

export type MovieTranscodeStatus = 'done' | 'processing' | 'failed'

export type VideoSeminarContent = {
  guid: string
  title: string
  description: string
  sequence: number
  thumbnailUrl: string
  movieUrl: string
  movieTranscodeStatus: MovieTranscodeStatus
}

export type VideoSeminar = {
  guid: string
  title: string
  description: string
  isPublic: boolean
  contents: VideoSeminarContent[]
  presignedCookies: Cookie[]
}

export type VideoSeminarForList = Pick<
  VideoSeminar,
  'guid' | 'title' | 'isPublic'
> & {
  registeredTime: Date
}

export const mapProtoToVideoSeminarForList = (
  proto: ListVideoSeminarsResponse.VideoSeminar
): VideoSeminarForList => {
  const registeredTime = proto.getRegisteredTime()?.toDate()
  assert(registeredTime)

  return {
    guid: proto.getGuid(),
    title: proto.getTitle(),
    isPublic: proto.getIsPublic(),
    registeredTime,
  }
}

export const mapProtoToVideoSeminar = (
  proto: GetVideoSeminarResponse,
  videoSeminarGuid: string
): VideoSeminar => {
  return {
    guid: videoSeminarGuid,
    title: proto.getTitle(),
    description: proto.getDescription(),
    isPublic: proto.getIsPublic(),
    contents: proto.getContentsList().map((c) => ({
      guid: c.getGuid(),
      title: c.getTitle(),
      description: c.getDescription(),
      sequence: c.getSequence(),
      thumbnailUrl: c.getThumbnailUrl(),
      movieUrl: c.getMovieUrl(),
      movieTranscodeStatus: mapProtoToMovieTranscodeStatus(
        c.getMovieTranscodeStatus()
      ),
    })),
    presignedCookies: proto.getPresignedCookiesList().map((c) => ({
      key: c.getKey(),
      value: c.getValue(),
      domain: c.getDomain(),
    })),
  }
}

const mapProtoToMovieTranscodeStatus = (
  proto: GetVideoSeminarResponse.MovieTranscodeStatus
): MovieTranscodeStatus => {
  switch (proto) {
    case ProtoMovieTranscodeStatus.MOVIE_TRANSCODE_STATUS_DONE:
      return 'done'
    case ProtoMovieTranscodeStatus.MOVIE_TRANSCODE_STATUS_PROCESSING:
      return 'processing'
    case ProtoMovieTranscodeStatus.MOVIE_TRANSCODE_STATUS_FAILED:
      return 'failed'
    case ProtoMovieTranscodeStatus.MOVIE_TRANSCODE_STATUS_UNKNOWN:
      throw new Error('invalid movie transcode status')
    default:
      assertNever(proto)
  }
}

export const generateGuestUrl = (videoSeminarGuid: string) =>
  getInternalUrls().myVideoSeminar.replace(
    ':videoSeminarGuid',
    videoSeminarGuid
  )
