import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import * as C from '@/components/RecInterviewTemplate'
import { QuestionRequired } from '@/services/recInterviewTemplateService'

type Question = {
  guid: string
  name: string
}

type QuestionRowProps = {
  index: number
  question: Question
  required: QuestionRequired
}

export const QuestionRow: React.VFC<QuestionRowProps> = ({
  index,
  question,
  required,
}) => {
  return (
    <QuestionItem>
      <C.Item>
        <C.ItemLabel>
          <ItemLabelQuestionNumber>問{index + 1}</ItemLabelQuestionNumber>
          <ItemLabelQuestionLabel>設問名</ItemLabelQuestionLabel>
        </C.ItemLabel>
        <C.ItemValue>{question.name}</C.ItemValue>
      </C.Item>
      <C.Item>
        <C.ItemLabel>
          <ItemLabelQuestionNumber />
          <ItemLabelQuestionLabel>必須 / 任意</ItemLabelQuestionLabel>
        </C.ItemLabel>
        <C.ItemValue>{required === '1' ? '必須' : '任意'}</C.ItemValue>
      </C.Item>
    </QuestionItem>
  )
}

const ItemLabelQuestionNumber = styled.div`
  min-width: 30px;
  padding-bottom: 10px;
`

const ItemLabelQuestionLabel = styled.div`
  min-width: 70px;
  padding-bottom: 10px;
`

const QuestionItem = styled.div`
  padding: 20px 0 0;
  :not(:last-child) {
    padding: 12px 0;
    border-bottom: solid 1px ${theme.color.gray[4]};
  }
  :first-child {
    padding: 8px 0 12px;
  }
`
