import { useMutation, useQueryClient } from 'react-query'
import {
  BatchReqRecInterviewAnswerRequestValue,
  useRequestBatchRequestSelectionRecInterviewAnswer,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchReqSelectionRecInterviewAnswer() {
  const queryClient = useQueryClient()
  const { requestBatchReqSelectionRecInterviewAnswer } =
    useRequestBatchRequestSelectionRecInterviewAnswer()

  return useMutation(
    async (value: BatchReqRecInterviewAnswerRequestValue) => {
      await requestBatchReqSelectionRecInterviewAnswer(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries(QUERY_KEY.applicant)
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEY.applicants)
          queryClient.invalidateQueries(QUERY_KEY.applicant)
        }, 10000) // NOTE：非同期処理が完了することを期待して10秒後にキャッシュを飛ばす(10秒は根拠のない値)
      },
    }
  )
}
