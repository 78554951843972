import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { OpenVideoSeminarRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestOpenVideoSeminar() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestOpenVideoSeminar = useCallback(
    async (videoSeminarGuid: string) => {
      const metadata = await buildMetadata()

      const req = new OpenVideoSeminarRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)

      return callBizHuttBffService(
        'openVideoSeminar',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestOpenVideoSeminar,
  }
}
