import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = {
  imgSrc: string
  message: string
}

export function SimpleStatus(props: PropsWithClassName<Props>) {
  return (
    <Wrapper className={props.className}>
      <ImageWrapper>
        <WebInterviewStatusImage src={props.imgSrc} alt="" />
      </ImageWrapper>
      <Message>
        <Txt>{props.message}</Txt>
      </Message>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 214px;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
`

const WebInterviewStatusImage = styled.img`
  height: 200px;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
`
