import { useMemo, useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { set, addDays } from 'date-fns'
import {
  SearchCondition,
  SelectionSubStatus,
} from '@/services/applicantService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { isValidDeadline } from '@/services/recInterviewTemplateService'
import { Applicant } from '../types'
import { useBatchUpdateSelectionRecInterviewDeadline } from '../useBatchUpdateSelectionRecInterviewDeadline'
import {
  BatchUpdateRecInterviewDeadlineModal as Modal,
  ErrorType,
} from './BatchUpdateRecInterviewDeadlineModal'

export type ReqMode =
  | {
      type: 'guids'
      selectedApplicants: Applicant[]
    }
  | {
      type: 'searchCondition'
      condition: SearchCondition
    }
type Props = {
  mode: ReqMode
  close: () => void
}
const BatchUpdateRecInterviewDeadlineModal: React.VFC<Props> = (props) => {
  const { mutateAsync } = useBatchUpdateSelectionRecInterviewDeadline()

  const initialDate = set(addDays(new Date(), 14), {
    hours: 17,
    minutes: 0,
    seconds: 0,
  })
  const [deadline, setDeadline] = useState<Date>(initialDate)

  const [error, setError] = useState<ErrorType | undefined>(
    // NOTE: 録画面接選考が設定されていて提出できるステータス以外は処理を実行させない
    (): ErrorType | undefined => {
      if (props.mode.type === 'guids') {
        if (
          props.mode.selectedApplicants.some((a) =>
            isUnupdatableStatus(a.selectionSubStatus)
          )
        ) {
          return 'includeUnupdatableStatus'
        }
      } else {
        if (
          (props.mode.condition.statuses.length === 0 &&
            props.mode.condition.subStatuses.length === 0) ||
          props.mode.condition.subStatuses.some(isUnupdatableStatus)
        ) {
          return 'includeUnupdatableStatus'
        }
      }
      return undefined
    }
  )
  const onChangeDeadline = useCallback((date: Date | null) => {
    setError(undefined)
    if (date === null) {
      setError('pastDate')
      return
    }

    setDeadline(date)
    if (!isValidDeadline(date, new Date())) {
      setError('pastDate')
      return
    }
  }, [])

  const request = useCallback(async () => {
    if (deadline === undefined || !isValidDeadline(deadline, new Date())) {
      return setError('pastDate')
    }
    setError(undefined)

    try {
      if (props.mode.type === 'guids') {
        await mutateAsync({
          condition: {
            type: 'guids',
            applicantGuids: props.mode.selectedApplicants.map((a) => a.guid),
          },
          deadline: deadline,
        })
      } else {
        await mutateAsync({
          condition: {
            type: 'searchCondition',
            condition: props.mode.condition,
          },
          deadline: deadline,
        })
      }
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          if (e.hasMatchErrorDetail('NUM_BATCH_ACTION_LIMIT_REACHED')) {
            setError('overLimit')
            return
          }
          if (
            e.hasMatchErrorDetail('SELECTION_REC_INTERVIEW_ALREADY_EXPIRED')
          ) {
            setError('pastDate')
            return
          }
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }

    toast('提出期限を変更しました')
    props.close()
  }, [deadline, props, mutateAsync])

  return (
    <Modal
      mode={
        props.mode.type === 'guids'
          ? {
              type: 'guids',
              applicantsLength: props.mode.selectedApplicants.length,
            }
          : { type: 'searchCondition' }
      }
      error={error}
      deadline={deadline}
      onSubmit={request}
      onClose={props.close}
      onChangeDeadline={onChangeDeadline}
    />
  )
}

const isUnupdatableStatus = (subStatus: SelectionSubStatus): boolean => {
  return ![
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED,
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT,
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED,
  ].includes(subStatus)
}

type GuidsProps = {
  applicants: Applicant[]
  close: () => void
}
export const BatchUpdateRecInterviewDeadlineModalOnGuids: React.VFC<
  GuidsProps
> = (props) => {
  const selected = useMemo(
    () => props.applicants.filter((a) => a.selected),
    [props.applicants]
  )

  return (
    <BatchUpdateRecInterviewDeadlineModal
      mode={{ type: 'guids', selectedApplicants: selected }}
      close={props.close}
    />
  )
}

type ConditionProps = {
  condition: SearchCondition
  close: () => void
}
export const BatchUpdateRecInterviewDeadlineModalOnCondition: React.VFC<
  ConditionProps
> = (props) => {
  return (
    <BatchUpdateRecInterviewDeadlineModal
      mode={{ type: 'searchCondition', condition: props.condition }}
      close={props.close}
    />
  )
}
