import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { format } from 'date-fns'
import { generatePath, useHistory } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { TableBase } from '@/components/TableBase'
import { OmittedInterviewerAvatarList } from '@/pages/ControlCenterPage/OmittedInterviewerAvatarList'
import { RequestingInterviewees } from '@/pages/ControlCenterPage/RequestingInterviewees'
import { UpcomingInterview } from '@/pages/ControlCenterPage/types'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  upcomingList: UpcomingInterview[]
  loadTime: Date
}

export const UpcomingList: React.VFC<Props> = (props) => {
  const history = useHistory()

  return (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="scheduledStartTime">
              <Txt>予定時間</Txt>
            </Th>
            <Th property="interviewName">
              <Txt>面接名</Txt>
            </Th>
            <Th property="interviewers">
              <Txt>面接官（待機中 / 参加予定）</Txt>
            </Th>
            <Th property="requestingInterviewees">
              <Txt>応募者（入室待ち）</Txt>
            </Th>
            <Th property="latestIntervieweeLog">
              <Txt>応募者ログ</Txt>
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.upcomingList.map((interview) => {
            const webInterviewPagePath = generatePath(
              INTERNAL_PATHS.webInterview,
              { webInterviewGuid: interview.guid }
            )

            const onClick: React.MouseEventHandler<HTMLTableRowElement> = (
              e
            ) => {
              // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
              if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
                window.open(webInterviewPagePath)
                return
              }
              history.push({
                pathname: webInterviewPagePath,
              })
            }

            return (
              <TableRow
                key={interview.guid}
                isPassedRecently={interview.isPassedRecently(props.loadTime)}
                onClick={onClick}
              >
                <Td property="scheduledStartTime">
                  <StartTimeTxt isPassed={interview.isPassed(props.loadTime)}>
                    <span>{format(interview.scheduledStartTime, 'HH:mm')}</span>
                    ~
                    {interview.scheduledFinishTime &&
                      format(interview.scheduledFinishTime, 'HH:mm')}
                  </StartTimeTxt>
                </Td>
                <Td property="interviewName">
                  <Txt size="m">{interview.name}</Txt>
                </Td>
                <Td property="interviewers">
                  <OmittedInterviewerAvatarList
                    interviewers={interview.interviewers}
                    interviewGuid={interview.guid}
                  />
                </Td>
                <Td property="requestingInterviewees">
                  <RequestingInterviewees
                    interviewGuid={interview.guid}
                    requestingInterviewees={interview.requestingInterviewees}
                  />
                </Td>
                <Td property="latestIntervieweeLog">
                  {interview.latestIntervieweeLogTimeDiff &&
                    (interview.latestIntervieweeLogTimeDiff < 60
                      ? `${interview.latestIntervieweeLogTimeDiff}分前`
                      : `${Math.floor(
                          interview.latestIntervieweeLogTimeDiff / 60
                        )}時間前`)}
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const width = {
  scheduledStartTime: 132,
  interviewName: 220,
  interviewers: 220,
  requestingInterviewees: 168,
  latestIntervieweeLog: 168,
}

type Property = keyof typeof width

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;

  tbody tr {
    border-bottom: 1px solid ${theme.color.gray[3]};
  }

  td {
    height: auto;
    border-bottom: none;
  }
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr<{ isPassedRecently?: boolean }>`
  display: flex;
  align-items: center;
  ${TableBody} & {
    cursor: pointer;
  }

  ${({ isPassedRecently }) =>
    isPassedRecently &&
    css`
      background-color: ${theme.color.red[4]};
    `}
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  scheduledStartTime: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.scheduledStartTime}px;
    justify-content: flex-start;
  `,
  interviewName: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.interviewName}px;
    justify-content: flex-start;
  `,
  interviewers: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.interviewers}px;
    justify-content: flex-start;

    ${TableHead} && {
      > :first-child {
        margin-left: 8px;
      }
    }
  `,
  requestingInterviewees: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.requestingInterviewees}px;
    justify-content: flex-start;
  `,
  latestIntervieweeLog: css`
    display: flex;
    align-items: center;
    flex: 1 0 ${width.latestIntervieweeLog}px;
    justify-content: flex-start;
  `,
}

const StartTimeTxt = styled(Txt).attrs({
  size: 'm',
  color: theme.color.navy[1],
})<{ isPassed: boolean }>`
  color: ${({ isPassed }) =>
    isPassed ? theme.color.red[2] : theme.color.navy[1]};

  > span {
    font-size: 18px;
  }
`
