import { Txt } from '@blue-agency/rogue'
import { Box } from '@/components/Box'
import { Loading } from '@/components/Loading'
import { ModalBaseProps } from '.'
import { ApplicantErrorDetailTable } from './ApplicantErrorDetailTable'
import { DetailBaseModal } from './DetailBaseModal'
import { useListRequestRecInterviewAnswerFailureDetails } from './useListRequestRecInterviewAnswerFailureDetails'

type Props = ModalBaseProps
export const ReqSelectionRecInterviewErrorDetailModal: React.VFC<Props> = (
  props
) => {
  const ctx = useListRequestRecInterviewAnswerFailureDetails(props.job.jobGuid)
  if (ctx.isLoading || ctx.failure === undefined) {
    return <Loading />
  }

  return (
    <DetailBaseModal
      {...props}
      error={`${ctx.failure.failedNum}件の処理が実行されませんでした。`}
      modalSize={944}
      detail={
        <>
          <Box mb="18px">
            <Txt>※エラー詳細は最大100件の表示となります。</Txt>
          </Box>
          <ApplicantErrorDetailTable failure={ctx.failure} />
        </>
      }
    />
  )
}
