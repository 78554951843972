import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { BatchUpdateSelectionRecInterviewDeadlineRequest } from '@blue-agency/proton/biz_hutt_bff'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { assertNever } from '@/assertions'
import { SearchCondition } from '../applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import { toSearchApplicantCondition } from './toSearchApplicantCondition'

export type BatchUpdateSelectionRecInterviewDeadlineRequestValue = {
  condition: BatchUpdateSelectionRecInterviewDeadlineRequestCondition
  deadline: Date
}

type BatchUpdateSelectionRecInterviewDeadlineRequestCondition =
  | {
      type: 'guids'
      applicantGuids: string[]
    }
  | {
      type: 'searchCondition'
      condition: SearchCondition
    }

export function useRequestBatchUpdateSelectionRecInterviewDeadline() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchUpdateSelectionRecInterviewDeadline = useCallback(
    async (value: BatchUpdateSelectionRecInterviewDeadlineRequestValue) => {
      const metadata = await buildMetadata()

      const req = new BatchUpdateSelectionRecInterviewDeadlineRequest()
      const reqType = value.condition
      switch (reqType.type) {
        case 'guids': {
          const guids =
            new BatchUpdateSelectionRecInterviewDeadlineRequest.Guids()
          guids.setGuidsList(reqType.applicantGuids)
          req.setGuids(guids)
          break
        }
        case 'searchCondition': {
          const c =
            new BatchUpdateSelectionRecInterviewDeadlineRequest.Condition()
          c.setCondition(toSearchApplicantCondition(reqType.condition))
          req.setCondition(c)
          break
        }
        default:
          assertNever(reqType)
      }
      req.setDeadline(Timestamp.fromDate(value.deadline))

      return callBizHuttBffService(
        'batchUpdateSelectionRecInterviewDeadline',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestBatchUpdateSelectionRecInterviewDeadline,
  }
}
