import React from 'react'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { BackgroundJobStatus as statusType } from '@/services/applicantService'

type Props = PropsWithClassName<{
  status: statusType
}>

export const BackgroundJobStatus = (props: Props): React.ReactElement => {
  return (
    <Wrapper>
      <Box className={props.className} status={props.status}>
        <Txt status={props.status}>{textMap[props.status]}</Txt>
      </Box>
    </Wrapper>
  )
}

const textMap: Record<statusType, string> = {
  inProgress: '処理中',
  success: '完了',
  failed: '失敗',
}

const colorMap: Record<statusType, string> = {
  inProgress: theme.color.navy[1],
  success: theme.color.green[4],
  failed: theme.color.red[2],
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Box = styled.div<{ status: statusType }>`
  border: 1px solid ${({ status }) => colorMap[status]};

  height: 24px;
  width: 62px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Txt = styled(_Txt).attrs({ size: 's' })<{ status: statusType }>`
  color: ${({ status }) => colorMap[status]};
`
