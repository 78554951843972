import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateVideoSeminar,
  UpdateVideoSeminarRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateVideoSeminar() {
  const queryClient = useQueryClient()
  const { requestUpdateVideoSeminar } = useRequestUpdateVideoSeminar()
  return useMutation(
    (value: UpdateVideoSeminarRequestValue) => requestUpdateVideoSeminar(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.videoSeminars)
        queryClient.invalidateQueries([QUERY_KEY.videoSeminar, req.guid])
      },
    }
  )
}
