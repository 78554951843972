import { Icon, IconProps, theme, Txt } from '@blue-agency/rogue'
import { format } from 'date-fns'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import {
  getSendMailPurposeText,
  SendMailPurposeType,
  SendMailStatus,
  SendMailStatusType,
} from '@/services/applicantService'

type Props = PropsWithClassName<{
  selectionStepName: string
  requestedAt: Date
  purpose: SendMailPurposeType
  status: SendMailStatusType
}>
export const SendMailHistory: React.VFC<Props> = (props) => {
  const icon = getIcon(props.status)
  return (
    <Wrapper className={props.className}>
      <IconArea color={icon.color}>
        {icon.name !== undefined && <Icon name={icon.name} />}
      </IconArea>
      <TitleArea>
        <Name>{props.selectionStepName}</Name>
        <RequestedAt>
          {format(props.requestedAt, 'yyyy/MM/dd  HH:mm')}
        </RequestedAt>
      </TitleArea>
      <ContentArea>
        <Content color={getContentColor(props.status)}>
          {getContent(props.purpose, props.status)}
        </Content>
      </ContentArea>
    </Wrapper>
  )
}

const getIcon = (
  status: SendMailStatusType
): { name: IconProps['name'] | undefined; color: string } => {
  switch (status) {
    case SendMailStatus.STATUS_SENDING:
      return {
        name: undefined,
        color: theme.color.green[4],
      }
    case SendMailStatus.STATUS_SUCCEEDED:
      return {
        name: 'check',
        color: theme.color.green[4],
      }
    case SendMailStatus.STATUS_FAILED:
    case SendMailStatus.STATUS_DEFERRED:
      return {
        name: 'exclamation',
        color: theme.color.red[2],
      }
    case SendMailStatus.STATUS_UNKNOWN:
      throw new Error('invalid send mail status')
  }
}

const getContent = (
  purpose: SendMailPurposeType,
  status: SendMailStatusType
): string => {
  const purposeText = getSendMailPurposeText(purpose)
  switch (status) {
    case SendMailStatus.STATUS_SENDING:
    case SendMailStatus.STATUS_SUCCEEDED:
      return `${purposeText}メールを送信しました。`
    case SendMailStatus.STATUS_FAILED:
      return '有効ではないまたは受信できないアドレスであるため、送信されませんでした。\nメールアドレスを変更して、再送信してください。'
    case SendMailStatus.STATUS_DEFERRED:
      return '送信完了まで時間がかかっているため、再送信処理中です。'
    case SendMailStatus.STATUS_UNKNOWN:
      throw new Error('invalid send mail status')
  }
}

const getContentColor = (status: SendMailStatusType): string => {
  switch (status) {
    case SendMailStatus.STATUS_SENDING:
    case SendMailStatus.STATUS_SUCCEEDED:
      return theme.color.navy[1]
    case SendMailStatus.STATUS_FAILED:
    case SendMailStatus.STATUS_DEFERRED:
      return theme.color.red[2]
    case SendMailStatus.STATUS_UNKNOWN:
      throw new Error('invalid send mail status')
  }
}

const Wrapper = styled.div`
  display: flex;
`

const IconArea = styled.div<{ color: string }>`
  width: 30px;
  padding: 5px;
  color: ${({ color }) => color};
`

const TitleArea = styled.div``

const ContentArea = styled.div`
  padding: 3px;
  margin-left: 10px;
`

const Name = styled(Txt).attrs({ size: 'm' })`
  text-align: center;
  padding: 4px 15px;
  background-color: ${theme.color.navy[3]};
`

const RequestedAt = styled(Txt).attrs({ color: theme.color.gray[1] })`
  margin-top: 4px;
`

const Content = styled(Txt)``
