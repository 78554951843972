import { useMemo, useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { SelectionStatus } from '@/services/applicantService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { Applicant } from '../types'
import { useBatchUpdateSelectionStatus } from '../useBatchUpdateSelectionStatus'
import { BatchUpdateSelectionStatusToNotPassedModal as Modal } from './BatchUpdateSelectionStatusToNotPassedModal'

type Props = {
  applicants: Applicant[]
  close: () => void
}
export const BatchUpdateSelectionStatusToNotPassedModal: React.VFC<Props> = (
  props
) => {
  const { mutateAsync } = useBatchUpdateSelectionStatus()

  const selected = useMemo(
    () => props.applicants.filter((a) => a.selected),
    [props.applicants]
  )

  // NOTE:新着ステータスの応募者は選考ステップがないので不通過にできない
  const [updatable, setUpdatable] = useState<boolean>(
    !selected.some(
      (a) => a.selectionStatus === SelectionStatus.SELECTION_STATUS_ARRIVAL
    )
  )
  const request = useCallback(async () => {
    try {
      await mutateAsync({
        condition: {
          type: 'guids',
          applicantGuids: selected.map((a) => a.guid),
        },
        status: SelectionStatus.SELECTION_STATUS_NOT_PASSED,
      })
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isInvalidArgument) {
          setUpdatable(false)
          return
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }

    toast(`${selected.length}件のステータスを変更しました`)
    props.close()
  }, [mutateAsync, props, selected])

  return (
    <Modal
      applicants={selected}
      updatable={updatable}
      onSubmit={request}
      onClose={props.close}
    />
  )
}
