import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { ProgressBar } from '@/components/ProgressBar'

type Props = {
  progress: number
  filename: string
  onCancel: () => void
}

// TODO: Storybook作る
export const MovieUpload: React.VFC<Props> = ({
  progress,
  filename,
  onCancel,
}) => {
  return (
    <UploadProgressBox>
      <FilenameTxt>{filename}</FilenameTxt>
      <ProgressBarWrapper>
        <ProgressBar percent={progress} />
      </ProgressBarWrapper>
      <CancelWrapper onClick={onCancel}>
        <CancelTxt>アップロードをキャンセル</CancelTxt>
      </CancelWrapper>
    </UploadProgressBox>
  )
}

const FilenameTxt = styled(Txt).attrs({ textAlign: 'center' })`
  color: ${theme.color.gray[1]};
`

const UploadProgressBox = styled.div`
  width: 460px;
  height: 97px;
  background: ${theme.color.gray[5]};
  padding: 12px;
`

const ProgressBarWrapper = styled.div`
  padding: 10px 0 16px;
`

const CancelWrapper = styled.div``

const CancelTxt = styled(Txt).attrs({ size: 's', textAlign: 'right' })`
  text-decoration: underline;
  cursor: pointer;
`
