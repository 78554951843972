import { useState, useCallback } from 'react'
import {
  WeakColorSeparator,
  Modal,
  Txt,
  CheckBox as _CheckBox,
  theme,
  ErrorBox,
} from '@blue-agency/rogue'
import { FormRow, TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Count as Count_ } from '@/components/Count'
import { InfoBox } from '@/components/InfoBox'

type Props = {
  deletable: boolean
  limit: number
  info: string[]
  onSubmit: () => void
  onClose: () => void
}

export const BatchDeleteApplicantsOnConditionModal: React.VFC<Props> = ({
  limit,
  deletable,
  info,
  onSubmit,
  onClose,
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const toggleConfirmed = useCallback(() => {
    setConfirmed((prevState) => !prevState)
  }, [])
  const canSubmit = deletable && confirmed

  return (
    <Modal active title="応募者を削除" size="l" onClose={onClose} stopScroll>
      <ModalBody>
        {!deletable && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                対象件数が上限の
                <Count count={limit} />
                件を超えています。
                <br />
                件数を減らして、再度実行してください。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <DescriptionWrapper>
          <Txt bold color={theme.color.red[2]}>
            指定した検索条件に一致したすべての応募者を削除します。
          </Txt>
          <InfoBox info={info} />
        </DescriptionWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <Row title="確認" required>
          <CheckBox
            checked={confirmed}
            onChange={toggleConfirmed}
            size={18}
            checkboxLabel="内容に誤りがないことを確認しました"
          />
        </Row>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action:
                'click_batch_delete_applicants_on_search_condition_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={onSubmit}
            comlinkPushParams={{
              action:
                'click_batch_delete_applicants_on_search_condition_button',
            }}
          >
            一括削除
          </DangerButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const Count = styled(Count_).attrs({
  color: theme.color.red[2],
  size: theme.fontSize.s,
})`
  display: inline-block;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '183px', subtitleWidth: '0' })`
  margin-bottom: 10px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
