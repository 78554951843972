import React, { useCallback } from 'react'
import { CheckBox, theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { InputText } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { AssignedWebInterviewsPageContainer } from './AssignedWebInterviewsContainer'

type Props = {
  webInterviewCount: number
}

export const Sidebar: React.VFC<Props> = ({ webInterviewCount }) => {
  const ctx = AssignedWebInterviewsPageContainer.useContainer()
  if (ctx.isLoading) throw Error('Loading Not Finished')
  const resetForm = useCallback(() => {
    ctx.dispatch({ type: 'RESET' })
  }, [ctx])

  const setFreeword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      ctx.dispatch({ type: 'SET_FREEWORD', payload: e.target.value })
    },
    [ctx]
  )

  const setStatusNotStarted = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_NOT_STARTED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setStatusInterviewing = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_INTERVIEWING',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setStatusInterviewed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_INTERVIEWED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )
  const setisOnlyViewed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_IS_ONLY_VIEWED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )
  return (
    <Wrapper>
      <WebInterviewCount>
        <Txt>該当数</Txt>
        <Count>{digitSeparator(webInterviewCount)}</Count>
        <Txt>件</Txt>
      </WebInterviewCount>
      <WeakColorSeparator />
      <SearchHeader>
        <Txt>検索</Txt>
        <ResetButton onClick={resetForm}>
          <ResetTxt>初期状態に戻す</ResetTxt>
        </ResetButton>
      </SearchHeader>
      <FormGroupWrapper>
        <FormGroupTitle size="s">フリーワード</FormGroupTitle>
        <InputText
          name="freeword"
          size="max"
          placeholder="面接名、面接官用リンクで検索"
          value={ctx.formValue.freeword}
          onChange={setFreeword}
          onBlurError={ctx.freewordErrorMessage}
          errorNoWrap
        />
      </FormGroupWrapper>
      <FormGroupWrapper>
        <FormGroupTitle size="s">ステータス</FormGroupTitle>
        <StatusCheckboxWrapper>
          <CheckBox
            name="status.notStarted"
            size={20}
            checkboxLabel="未実施"
            checked={ctx.formValue.status.notStarted}
            onChange={setStatusNotStarted}
          />
          <CheckBox
            name="status.interviewing"
            size={20}
            checkboxLabel="面接中"
            checked={ctx.formValue.status.interviewing}
            onChange={setStatusInterviewing}
          />
          <CheckBox
            name="status.interviewed"
            size={20}
            checkboxLabel="実施済"
            checked={ctx.formValue.status.interviewed}
            onChange={setStatusInterviewed}
          />
        </StatusCheckboxWrapper>
      </FormGroupWrapper>
      <SeparatorWrapper>
        <WeakColorSeparator />
      </SeparatorWrapper>
      <FormGroupWrapper>
        <InterviewerCheckBox
          name="isOnlyViewed"
          size={20}
          checkboxLabel="閲覧のみの面接"
          checked={ctx.formValue.isOnlyViewed}
          onChange={setisOnlyViewed}
        />
      </FormGroupWrapper>
      <ButtomSpacer />
    </Wrapper>
  )
}

function digitSeparator(digit: number) {
  return String(digit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[4]};
  border-left-width: 0;
  border-radius: 0 4px 4px 0;
  diplay: flex;
  flex-direction: column;
`

const WebInterviewCount = styled.div`
  align-items: center;
  display: flex;
  padding: 12px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  line-height: 1;
  margin: 0 12px 0 auto;
`

const SearchHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 12px;
`

const ResetButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const ResetTxt = styled(Txt).attrs({ size: 's' })`
  text-decoration: underline;
`

const FormGroupWrapper = styled.div`
  padding: 0 12px;
`

const FormGroupTitle = styled(Txt)`
  margin: 16px 0 8px;
`

const StatusCheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 70px;
  grid-gap: 16px;
`

const SeparatorWrapper = styled.div`
  margin-top: 16px;
  padding 0 8px;
`

const InterviewerCheckBox = styled(CheckBox)`
  margin-top: 16px;
`

const ButtomSpacer = styled.div`
  margin-bottom: 16px;
`
