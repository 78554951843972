import { assertNever } from '@/assertions'
import type { KeyTarget, MappingTarget, MappingResult } from '../types'

type Response =
  | {
      type: 'error'
      message: string
    }
  | {
      type: 'valid'
      result: MappingResult
    }

export function validateMapping(
  targets: (MappingTarget | undefined)[]
): Response {
  const keyTargets = targets.filter((target) => {
    return target === 'web-interview-guid' || target === 'ats-interview-id'
  }) as KeyTarget[]

  if (keyTargets.length === 0) {
    return {
      type: 'error',
      message: 'Web面接IDか連携先Web面接IDのいずれかの項目を設定してください。',
    }
  }

  if (keyTargets.length > 1) {
    return {
      type: 'error',
      message:
        'Web面接IDや連携先Web面接IDを複数項目に指定できません。片方のみを設定してください。',
    }
  }

  const result = targets.reduce<MappingResult>((res, target, index) => {
    switch (target) {
      case 'web-interview-guid':
        return {
          ...res,
          keyType: 'web-interview-guid',
          key: index,
        }
      case 'ats-interview-id':
        return {
          ...res,
          keyType: 'ats-interview-id',
          key: index,
        }
      case 'name':
        return {
          ...res,
          name: index,
        }
      case 'scheduled-start-time':
        return {
          ...res,
          'scheduled-start-time': index,
        }
      case 'duration':
        return {
          ...res,
          duration: index,
        }
      case 'interview-guide-guid':
        return {
          ...res,
          'interview-guide-guid': index,
        }
      case 'assignment':
        return {
          ...res,
          assignment: index,
        }
      case 'interviewer':
        return {
          ...res,
          interviewer: [...(res.interviewer ?? []), index],
        }
      case 'viewer':
        return {
          ...res,
          viewer: [...(res.viewer ?? []), index],
        }
      case undefined:
        return res
      default:
        assertNever(target)
        // eslintのarray-callback-returnを防ぐ
        return res
    }
  }, {} as MappingResult)

  return {
    type: 'valid',
    result,
  }
}
