import React from 'react'
import { SecondaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'

export const CsvUploadButton = () => {
  return (
    <CsvUploadLink to={INTERNAL_PATHS.uploadWebInterviewsCSV}>
      <SecondaryButton
        widthSize="L1"
        comlinkPushParams={{
          action: 'click_upload_web_interviews_csv_for_update',
        }}
      >
        CSV更新
      </SecondaryButton>
    </CsvUploadLink>
  )
}

const CsvUploadLink = styled(Link)`
  display: block;
  text-decoration: none;
`
