export const EXTERNAL_PATHS = {
  privacy: 'https://interview-maker.jp/terms/privacy-policy/',
  terms: 'https://interview-maker.jp/terms/terms-of-service/',
  help: {
    recommendedEnvironment:
      'https://im-cp.zendesk.com/hc/ja/articles/900005273926-%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83-%E9%9D%A2%E6%8E%A5%E5%AE%98%E5%90%91%E3%81%91-',
    interviewAssignment:
      'https://im-cp.zendesk.com/hc/ja/articles/900005877783',
    interviewName: 'https://im-cp.zendesk.com/hc/ja/articles/900005312626',
    interviewGuideExamples:
      'https://im-cp.zendesk.com/hc/ja/articles/4407116242841',
    controlCenter:
      'https://im-cp.zendesk.com/hc/ja/articles/4413679417881--%E4%BC%81%E6%A5%AD-%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%AB%E3%82%BB%E3%83%B3%E3%82%BF%E3%83%BC%E6%A9%9F%E8%83%BD%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB',
    shareSelection: 'https://im-cp.zendesk.com/hc/ja/articles/4561339561881',
    webInterviewUpdateByFile:
      'https://im-cp.zendesk.com/hc/ja/articles/5443240705689',
    importApplicants: 'https://im-cp.zendesk.com/hc/ja/articles/5514983463193',
  },
}
