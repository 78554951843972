import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateWebInterviewNameRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateWebInterviewNameRequestValue = {
  webInterviewGuid: string
  name: string
}

export function useRequestUpdateWebInterviewName() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateWebInterviewName = useCallback(
    async (value: UpdateWebInterviewNameRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateWebInterviewNameRequest()

      req.setName(value.name)
      req.setWebInterviewGuid(value.webInterviewGuid)

      return callBizHuttBffService(
        'updateWebInterviewName',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestUpdateWebInterviewName }
}
