import React, { useCallback, useState } from 'react'
import { CheckBox, theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { InputText, DatePicker } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { StaffSelect } from './StaffSelect'
import { WebInterviewsPageContainer } from './WebInterviewsPageContainer'

export const Sidebar: React.VFC = () => {
  const ctx = WebInterviewsPageContainer.useContainer()
  if (ctx.isLoading) throw Error('Loading Not Finished')

  const [inputValue, setInputValue] = useState(() => {
    const staff = ctx.staffs.find(
      (s) => s.guid === ctx.validatedFormValue.interviewerGuid
    )
    if (staff) {
      return `${staff.name.familyName} ${staff.name.givenName}`
    }
    return ''
  })

  const onStaffChange = useCallback(
    (newValue: string) => {
      ctx.dispatch({ type: 'SET_INTERVIEWER_GUID', payload: null })
      setInputValue(newValue)
    },
    [ctx]
  )

  const onStaffSelected = useCallback(
    (staff) => {
      ctx.dispatch({ type: 'SET_INTERVIEWER_GUID', payload: staff.guid })
      setInputValue(`${staff.name.familyName} ${staff.name.givenName}`)
    },
    [ctx]
  )

  const onStaffClear = useCallback(() => {
    ctx.dispatch({ type: 'SET_INTERVIEWER_GUID', payload: null })
    setInputValue('')
  }, [ctx])

  const setFreeword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      ctx.dispatch({ type: 'SET_FREEWORD', payload: e.target.value })
    },
    [ctx]
  )

  const resetForm = useCallback(() => {
    ctx.dispatch({ type: 'RESET' })
    setInputValue('')
  }, [ctx])

  const setStatusNotStarted = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_NOT_STARTED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setStatusInterviewing = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_INTERVIEWING',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setStatusInterviewed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_STATUS_INTERVIEWED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setIsAssignmentSpecified = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.dispatch({
        type: 'SET_IS_ASSIGNMENT_SPECIFIED',
        payload: e.target.checked,
      })
    },
    [ctx]
  )

  const setRegisterFrom = useCallback(
    (date: Date | null) => {
      ctx.dispatch({ type: 'SET_REGISTER_FROM', payload: date })
    },
    [ctx]
  )

  const setRegisterTo = useCallback(
    (date: Date | null) => {
      ctx.dispatch({ type: 'SET_REGISTER_TO', payload: date })
    },
    [ctx]
  )

  const setScheduledStartFrom = useCallback(
    (date: Date | null) => {
      ctx.dispatch({ type: 'SET_SCHEDULED_START_FROM', payload: date })
    },
    [ctx]
  )

  const setScheduledStartTo = useCallback(
    (date: Date | null) => {
      ctx.dispatch({ type: 'SET_SCHEDULED_START_TO', payload: date })
    },
    [ctx]
  )

  return (
    <Wrapper>
      <InterviewCount>
        <Txt>該当数</Txt>
        <Count>{digitSeparator(ctx.totalLength)}</Count>
        <Txt>件</Txt>
      </InterviewCount>
      <WeakColorSeparator />
      <SearchHeader>
        <Txt>検索</Txt>
        <ResetButton onClick={resetForm}>
          <ResetTxt>初期状態に戻す</ResetTxt>
        </ResetButton>
      </SearchHeader>
      <FormGroupWrapper>
        <FormGroupTitle size="s">フリーワード</FormGroupTitle>
        <InputText
          name="freeword"
          size="max"
          placeholder="面接名、面接官用リンクで検索"
          value={ctx.formValue.freeword}
          onChange={setFreeword}
          onBlurError={ctx.freewordErrorMessage}
          errorNoWrap
        />
      </FormGroupWrapper>
      <FormGroupWrapper>
        <FormGroupTitle size="s">ステータス</FormGroupTitle>
        <StatusCheckboxWrapper>
          <CheckBox
            name="status.notStarted"
            size={20}
            checkboxLabel="未実施"
            checked={ctx.formValue.status.notStarted}
            onChange={setStatusNotStarted}
          />
          <CheckBox
            name="status.interviewing"
            size={20}
            checkboxLabel="面接中"
            checked={ctx.formValue.status.interviewing}
            onChange={setStatusInterviewing}
          />
          <CheckBox
            name="status.interviewed"
            size={20}
            checkboxLabel="実施済"
            checked={ctx.formValue.status.interviewed}
            onChange={setStatusInterviewed}
          />
        </StatusCheckboxWrapper>
      </FormGroupWrapper>
      <SeparatorWrapper>
        <WeakColorSeparator />
      </SeparatorWrapper>
      <FormGroupWrapper>
        <FormGroupTitle size="s">面接官</FormGroupTitle>
        <StaffSelect
          name="interviewerGuid"
          staffs={ctx.staffs}
          value={inputValue}
          onChange={onStaffChange}
          onSelected={onStaffSelected}
          onClear={onStaffClear}
        />
        <InterviewerCheckBox
          name="isAssignmentSpecified"
          size={20}
          checkboxLabel="面接官が未設定のみ表示"
          checked={ctx.formValue.isAssignmentSpecified}
          onChange={setIsAssignmentSpecified}
        />
      </FormGroupWrapper>
      <FormGroupWrapper>
        <FormGroupTitle size="s">予定日時</FormGroupTitle>
        <DatePicker
          value={ctx.validatedFormValue.scheduledStartTime.from}
          onChange={setScheduledStartFrom}
          placeholder="開始年月日を選択"
          width="100%"
        />
        <WebInterviewDatePickerTo
          value={ctx.validatedFormValue.scheduledStartTime.to}
          onChange={setScheduledStartTo}
          placeholder="終了年月日を選択"
          width="100%"
        />
      </FormGroupWrapper>
      <FormGroupWrapper>
        <FormGroupTitle size="s">登録日</FormGroupTitle>
        <DatePicker
          value={ctx.validatedFormValue.registerTime.from}
          onChange={setRegisterFrom}
          placeholder="開始年月日を選択"
          width="100%"
        />
        <WebInterviewDatePickerTo
          value={ctx.validatedFormValue.registerTime.to}
          onChange={setRegisterTo}
          placeholder="終了年月日を選択"
          width="100%"
        />
      </FormGroupWrapper>
    </Wrapper>
  )
}

function digitSeparator(digit: number | undefined): string {
  if (typeof digit === undefined) {
    return ''
  }
  return String(digit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[4]};
  border-left-width: 0;
  border-radius: 0 4px 4px 0;
  display: flex;
  flex-direction: column;
`

const InterviewCount = styled.div`
  align-items: center;
  display: flex;
  padding: 12px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  line-height: 1;
  margin: 0 6px 0 auto;
`

const SearchHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 12px;
`

const ResetButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const ResetTxt = styled(Txt).attrs({ size: 's' })`
  text-decoration: underline;
`

const FormGroupWrapper = styled.div`
  padding: 0 12px;
`

const FormGroupTitle = styled(Txt)`
  margin: 16px 0 8px;
`

const StatusCheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 70px;
  grid-gap: 16px;
`

const SeparatorWrapper = styled.div`
  margin-top: 16px;
  padding: 0 8px;
`

const InterviewerCheckBox = styled(CheckBox)`
  margin-top: 16px;
`

const WebInterviewDatePickerTo = styled(DatePicker)`
  margin: 12px 0 20px;
`
