import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateWebInterviewScheduledStartTime,
  UpdateWebInterviewScheduledStartTimeRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateWebInterviewScheduledStartTime() {
  const queryClient = useQueryClient()
  const { requestUpdateWebInterviewScheduledStartTime } =
    useRequestUpdateWebInterviewScheduledStartTime()

  return useMutation(
    (value: UpdateWebInterviewScheduledStartTimeRequestValue) =>
      requestUpdateWebInterviewScheduledStartTime(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries([
          QUERY_KEY.webInterview,
          req.webInterviewGuid,
        ])
      },
    }
  )
}
