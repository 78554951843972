import { useQuery } from 'react-query'
import { useRequestListWebInterviewAssigneeCandidates } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListWebInterviewAssigneeCandidates() {
  const { requestListWebInterviewAssigneeCandidates } =
    useRequestListWebInterviewAssigneeCandidates()

  return useQuery(
    [QUERY_KEY.assigneeCandidates],
    requestListWebInterviewAssigneeCandidates
  )
}
