import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteRecInterviewTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type DeleteRecInterviewTemplateRequestValue = {
  guid: string
}

export function useRequestDeleteRecInterviewTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteRecInterviewTemplate = useCallback(
    async (value: DeleteRecInterviewTemplateRequestValue) => {
      const metadata = await buildMetadata()

      const req = new DeleteRecInterviewTemplateRequest()

      req.setGuid(value.guid)

      return callBizHuttBffService(
        'deleteRecInterviewTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestDeleteRecInterviewTemplate,
  }
}
