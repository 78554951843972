import { useQuery } from 'react-query'
import { useListAllRecInterviewQuestionTemplates } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useAllRecInterviewQuestionTemplates() {
  const { requestListAllRecInterviewQuestionTemplates } =
    useListAllRecInterviewQuestionTemplates()

  return useQuery([QUERY_KEY.allRecInterviewQuestionTemplates], () =>
    requestListAllRecInterviewQuestionTemplates()
  )
}
