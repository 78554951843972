import React, { useReducer } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { Applicant, Selection } from '@/services/applicantService'
import { AuthzContainer } from '@/services/authzService'
import { Content as C } from './Content'
import { Header as H } from './Header'
import { Input } from './Input'
import { Tabs } from './Tabs'
import reducer, { initialState } from './applicantInputsReducer'
import { useApplicant } from './useApplicant'
import { useApplicantTabs } from './useApplicantTabs'
import { useNextAction } from './useNextAction'

export const ApplicantPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('GetApplicant')

  const ctx = useApplicant()

  if (
    !ctx.applicant ||
    !ctx.registerTime ||
    !ctx.selection ||
    !ctx.registrationRoute ||
    ctx.organizationRecInterviewTranscriptionSetting === undefined
  ) {
    return <Loading />
  }

  return (
    <ApplicantPageInner
      applicantGuid={ctx.applicantGuid}
      applicant={ctx.applicant}
      registerTime={ctx.registerTime}
      selection={ctx.selection}
      registrationRoute={ctx.registrationRoute}
      organizationRecInterviewTranscriptionSetting={
        ctx.organizationRecInterviewTranscriptionSetting ?? false
      }
    />
  )
}

type Props = {
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  selection: Selection
  registrationRoute: string
  organizationRecInterviewTranscriptionSetting: boolean
}
export const ApplicantPageInner: React.VFC<Props> = (props) => {
  const tabsCtx = useApplicantTabs()
  const [applicantInputState, applicantInputDispatch] = useReducer(
    reducer,
    initialState
  )

  const nextActionCtx = useNextAction(
    props.selection.status,
    props.selection.subStatus,
    tabsCtx.switchTab
  )

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.white[1]}>
      <Wrapper>
        <Header
          applicantGuid={props.applicantGuid}
          applicant={props.applicant}
          selection={props.selection}
          nextAction={nextActionCtx.nextAction}
          nextActionFn={nextActionCtx.nextActionFn}
        />
        <BottomArea>
          <TabsArea
            selectedTab={tabsCtx.selectedTab}
            onClick={tabsCtx.switchTab}
          />
          <ContentArea>
            <Content
              selectedTab={tabsCtx.selectedTab}
              applicantGuid={props.applicantGuid}
              applicant={props.applicant}
              registerTime={props.registerTime}
              applicantInputState={applicantInputState}
              applicantInputDispatch={applicantInputDispatch}
              selection={props.selection}
              setNextActionFn={nextActionCtx.setNextActionFn}
              registrationRoute={props.registrationRoute}
              organizationRecInterviewTranscriptionSetting={
                props.organizationRecInterviewTranscriptionSetting
              }
            />
            {applicantInputState.input !== undefined && (
              <InputArea
                applicantGuid={props.applicantGuid}
                applicantInputState={applicantInputState}
                applicantInputDispatch={applicantInputDispatch}
                selection={props.selection}
              />
            )}
          </ContentArea>
        </BottomArea>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled(H)`
  width: 100%;
  flex: 0 0 134px;
`

const BottomArea = styled.div`
  display: flex;
  flex: 1 1 auto;
`

const TabsArea = styled(Tabs)`
  flex: 0 0 160px;
  position: sticky;
  top: 0;
  align-self: start;
`

const ContentArea = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: ${theme.color.gray[5]};
  height: 100%;
`

const Content = styled(C)`
  flex: 1 0 auto;
  border-left: 1px solid ${theme.color.gray[4]};
  padding: 14px 12px 0 8px;
  width: 510px;
`

const InputArea = styled(Input)`
  flex: 1 1 auto;
  position: sticky;
  top: 0;
  align-self: start;
  background-color: ${theme.color.gray[5]};
  max-width: 510px;
  min-width: 300px;
  margin: 14px 0 0 10px;
`
