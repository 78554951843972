import React, { useMemo, useState } from 'react'
import { theme, InputText, Icon, LineClampedTxt } from '@blue-agency/rogue'
import Autosuggest from 'react-autosuggest'
import styled from 'styled-components'
import type { FormatOption } from './useInputPage'

type Props = {
  formats: FormatOption[]
  suggestionsMax?: number
  name: string
  value: string
  onChange: (newValue: string) => void
  onClear: () => void
  onSelected: (format: FormatOption) => void
  onEdit: (format: FormatOption) => void
  onDelete: (format: FormatOption) => void
}

export const FormatSelect = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      formats: _formats,
      suggestionsMax = 30,
      name,
      value,
      onChange,
      onClear,
      onSelected,
      onEdit,
      onDelete,
    },
    ref
  ) => {
    const formats = useMemo(() => new FormatOptions(_formats), [_formats])
    const suggestions = useMemo(
      () => formats.filter(value, suggestionsMax),
      [formats, value, suggestionsMax]
    )

    const getSuggestionValue = (_format: FormatOption) => value

    const renderSuggestion: Autosuggest.RenderSuggestion<FormatOption> = (
      suggestion,
      { isHighlighted }
    ) => (
      <Option
        format={suggestion}
        isHighlighted={isHighlighted}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    )

    // FIXME: ESCの後にinputを2回クリックしないとサジェストが再表示されない
    const [suggestionsRevealed, setSuggestionsRevealed] = useState<
      boolean | undefined
    >()

    return (
      <Wrapper>
        <Autosuggest
          theme={myTheme}
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          renderInputComponent={(props) => <Input {...props} />}
          renderSuggestion={renderSuggestion}
          focusInputOnSuggestionClick={false}
          shouldRenderSuggestions={(_value, reason) => {
            switch (reason) {
              case 'suggestions-revealed':
                setSuggestionsRevealed(true)
                return true
              case 'input-blurred':
              case 'escape-pressed':
                setSuggestionsRevealed(false)
                return false
              case 'render':
                return suggestionsRevealed ?? false
            }
            return true
          }}
          inputProps={{
            ref,
            name,
            value,
            placeholder: 'フォーマット名を入力または選択',
            onChange: (_event, { method, newValue }) => {
              switch (method) {
                case 'type':
                  setSuggestionsRevealed(true)
                  onChange(newValue)
                  return
                case 'enter':
                  setSuggestionsRevealed(false)
                  return
              }
            },
            onClick: (event) => {
              if (suggestionsRevealed) {
                const target = event.target as HTMLInputElement
                target.blur()
              }
              setSuggestionsRevealed(!suggestionsRevealed)
            },
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            },
          }}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {
            setSuggestionsRevealed(true)
          }}
          onSuggestionSelected={(_event, { suggestion, suggestionValue }) => {
            setSuggestionsRevealed(false)
            onSelected(suggestion)
          }}
        />
        {value && (
          <IconButton onClick={onClear}>
            <CancelIcon />
          </IconButton>
        )}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
`

const IconButton = styled.div.attrs({
  role: 'button',
  'aria-label': '選択をクリア',
})`
  align-items: center;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
`

const CancelIcon = styled(Icon).attrs({
  name: 'close2',
  height: '10px',
  width: '10px',
})`
  color: ${theme.color.gray[5]};
  & path:not(:first-child) {
    fill: black;
  }
`

class FormatOptions {
  private formats: FormatOption[]

  constructor(formats: FormatOption[]) {
    this.formats = formats.sort((a, b) => a.name.localeCompare(b.name))
  }

  get all() {
    return this.formats
  }

  filter(value: string, max: number): FormatOption[] {
    return this.formats.filter(this.filterformat(value)).slice(0, max)
  }

  private filterformat(query: string) {
    return (format: FormatOption): boolean => {
      if (query === '') return true
      return query.split(/ |　/).every((q) => format.name.includes(q.trim()))
    }
  }
}

type OptionProps = {
  format: FormatOption
  isHighlighted: boolean
  onEdit: (format: FormatOption) => void
  onDelete: (format: FormatOption) => void
}
const Option: React.VFC<OptionProps> = ({
  format,
  isHighlighted,
  onEdit,
  onDelete,
}) => (
  <OptionRow isHighlighted={isHighlighted}>
    <OptionName>{format.name}</OptionName>
    {isHighlighted && (
      <>
        <EditIcon
          onClick={() => {
            onEdit(format)
          }}
        />
        <DeleteIcon
          onClick={() => {
            onDelete(format)
          }}
        />
      </>
    )}
  </OptionRow>
)

type OptionRowParams = {
  isHighlighted: boolean
}
const OptionRow = styled.div<OptionRowParams>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  :hover {
    cursor: pointer;
  }
  border-bottom: solid 1px ${theme.color.gray[4]};
`

const OptionName = styled(LineClampedTxt).attrs({ line: 1, size: 's' })`
  padding: 12px;
  flex: 1;
  :hover {
    background-color: ${theme.color.gray[4]};
  }
`

const EditIcon = styled(Icon).attrs({
  name: 'edit',
})`
  height: 100%;
  padding: 0 12px;
  background-color: ${theme.color.white[1]};
  :hover {
    background-color: ${theme.color.gray[4]};
  }
`

const DeleteIcon = styled(Icon).attrs({
  name: 'delete',
})`
  height: 100%;
  padding: 0 12px;
  background-color: ${theme.color.white[1]};
  :hover {
    background-color: ${theme.color.gray[4]};
  }
`

type InputProps = Omit<
  Autosuggest.RenderInputComponentProps,
  'size' | 'type'
> & {
  label?: string
}
const Input: React.VFC<InputProps> = React.forwardRef((props, ref) => {
  return (
    <InputText
      {...{
        ...props,
        height: undefined,
        defaultValue: undefined,
      }}
      ref={ref}
      size="max"
    />
  )
})

const myTheme: Autosuggest.Theme = {
  suggestionsContainer: {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 4px 4px',
    display: 'block',
    position: 'absolute',
    top: '32px',
    backgroundColor: theme.color.white[1],
    zIndex: 1,
    maxHeight: '364px',
    width: '100%',
    overflowY: 'auto',
  },
  suggestion: {
    listStyleType: 'none',
  },
  input: {
    cursor: 'pointer',
  },
}
