import React, { useCallback } from 'react'
import styled from 'styled-components'

type Props = {
  srcObject?: Blob
  src?: string
  maxHeight?: number
}

export const VideoPlayer: React.VFC<Props> = (props) => {
  const elRef = useCallback(
    (el: HTMLVideoElement | null) => {
      if (!el) return
      if (!props.src && !props.srcObject) return

      if (props.srcObject) {
        const src = props.src ?? URL.createObjectURL(props.srcObject)
        el.src = src

        el.onloadedmetadata = function () {
          if (el.duration === Infinity) {
            el.currentTime = Number.MAX_SAFE_INTEGER

            el.ontimeupdate = () => {
              el.ontimeupdate = null
              // https://stackoverflow.com/a/55093702
              el.currentTime = 0.1
              el.currentTime = 0
            }
          }
        }
      }
    },
    [props.srcObject, props.src]
  )

  return (
    <Video
      ref={elRef}
      controls
      disablePictureInPicture
      controlsList="nodownload"
      onContextMenu={(e) => e.preventDefault()}
      maxHeight={props.maxHeight}
    />
  )
}

const Video = styled.video<{ maxHeight?: number }>`
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight ?? 180}px;
`
