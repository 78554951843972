import { SelectionStepType } from '@blue-agency/proton/web/v2/im'
import {
  Dropdown,
  InputText,
  Modal as _Modal,
  RequireTxt,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const Modal = styled(_Modal)`
  /* NOTE: 一番上のInputTextのエラー表示がモーダルヘッダーに隠れないようにしている */
  && > div:last-of-type {
    overflow: visible;
  }
`

export const Main = styled.div`
  width: 100%;
  height: 230px;
  padding: 0 20px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
`

export const Title = styled(Txt)`
  width: 150px;
`

export const Required = styled(RequireTxt).attrs({
  children: '必須',
  size: 's',
})``

export const NotRequired = styled.div`
  width: 25px;
`

export const InputStepName = styled(InputText).attrs({
  size: 'll',
})`
  margin-left: 5px;
`

export const InputStepType = styled(Dropdown).attrs({
  size: 'll',
})`
  margin-left: 5px;
`

export const Bottom = styled.div`
  width: 100%;
  height: 76px;
  border-top: 1px solid ${theme.color.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CancelButton = styled(TertiaryButton).attrs({
  widthSize: 'L1',
})``

export const SubmitButton = styled(PrimaryButton).attrs({
  widthSize: 'L1',
})`
  margin-left: 20px;
`

export const Separator = WeakColorSeparator

export const dropdownOptions = [
  {
    label: '録画面接',
    value: SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW,
  },
]
