import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'

export const Header = () => {
  return (
    <Wrapper>
      <Txt size="xl">ビデオセミナー</Txt>
      <NewVideoSeminarPageLink to={INTERNAL_PATHS.newVideoSeminar}>
        <PrimaryButton
          widthSize="L1"
          comlinkPushParams={{
            action: 'click_new_video_seminar_button_on_guide_list_page',
          }}
        >
          セミナー登録
        </PrimaryButton>
      </NewVideoSeminarPageLink>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding: 0 20px;
  background: ${theme.color.white[1]};
`

const NewVideoSeminarPageLink = styled(Link)`
  text-decoration: none;
`
