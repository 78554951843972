import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useQuery } from 'react-query'
import { useRequestListUpcomingWebInterviews } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListUpcomingWebInterviews() {
  const { requestListUpcomingWebInterviews } =
    useRequestListUpcomingWebInterviews()

  const todayPB = Timestamp.fromDate(new Date())
  return useQuery([QUERY_KEY.upcomingWebInterviews], () =>
    requestListUpcomingWebInterviews(todayPB)
  )
}
