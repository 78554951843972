import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LinkToInterviewGuideExamples } from '@/components/LinkToInterviewGuideExamples'
import { INTERNAL_PATHS } from '@/services/urlService'

export function Header() {
  return (
    <Heading>
      <Left>
        <Title>面接ガイド一覧</Title>
        <SubTextWrapper>
          <LinkToInterviewGuideExamples pageType="interview_guide_list_page" />
        </SubTextWrapper>
      </Left>
      <Right>
        <RegisterButtonContainer>
          <NewWebInterviewGuideLink to={INTERNAL_PATHS.newWebInterviewGuide}>
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{
                action:
                  'click_new_web_interview_guide_button_on_guide_list_page',
              }}
            >
              ガイド登録
            </PrimaryButton>
          </NewWebInterviewGuideLink>
        </RegisterButtonContainer>
      </Right>
    </Heading>
  )
}

const Left = styled.div`
  display: flex;
`

const Right = styled.div``

const Heading = styled.header`
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: ${theme.color.white[1]};
`

const Title = styled(Txt).attrs({ size: 'xl' })`
  padding: 15px 20px;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`

const RegisterButtonContainer = styled.div`
  padding: 12px 20px;
`

const NewWebInterviewGuideLink = styled(Link)`
  display: block;
  text-decoration: none;
`
