import { theme, Txt, Icon } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/dist/im'
import styled, { css } from 'styled-components'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { TabType, tabs } from './useTabs'

const mapTabName = (tab: TabType) => {
  switch (tab) {
    case 'info':
      return '基本情報'
    case 'contents':
      return 'コンテンツ'
    case 'report':
      return 'レポート'
  }
}

type ContentsTabStatusProps = {
  hasFailedStatus: boolean
  hasProcessingStatus: boolean
}
type Props = {
  selectedTab: TabType
  onClick: (tab: TabType) => void
} & ContentsTabStatusProps
export const Tabs: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      {tabs.map((tab) => {
        const selected = props.selectedTab === tab
        return (
          <Tab key={tab} selected={selected} onClick={() => props.onClick(tab)}>
            <Txt color={selected ? theme.color.green[4] : theme.color.navy[1]}>
              {mapTabName(tab)}
            </Txt>
            {tab === 'contents' && (
              <ContentsTabStatus
                hasFailedStatus={props.hasFailedStatus}
                hasProcessingStatus={props.hasProcessingStatus}
              />
            )}
          </Tab>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Tab = styled.div<{ selected: boolean }>`
  width: 100%;
  padding: 8px 0 8px 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  background-color: ${({ selected }) =>
    selected ? theme.color.green[8] : theme.color.white[1]};
  border-right: 3px solid
    ${({ selected }) => (selected ? theme.color.green[4] : 'transparent')};
  cursor: pointer;
`

export const ContentsTabStatus: React.VFC<ContentsTabStatusProps> = (props) => {
  if (!props.hasFailedStatus && !props.hasProcessingStatus) return null

  return (
    <>
      <div
        data-tip
        data-for={TOOLTIP_ID_MAP.videoSeminarContentStatus}
        style={{ cursor: 'pointer' }}
      >
        {props.hasFailedStatus && (
          <StatusIcon name="caution-filled" color={theme.color.red[2]} />
        )}
        {props.hasProcessingStatus && (
          <StatusIcon name="exclamation" color={theme.color.orange[1]} />
        )}
      </div>
      <Tooltip
        id={TOOLTIP_ID_MAP.videoSeminarContentStatus}
        arrowPosition="leftTop"
      >
        {props.hasFailedStatus && (
          <Status>
            <StatusIcon name="caution-filled" color={theme.color.red[2]} />
            <StatusTxt color={theme.color.red[2]}>
              変換に失敗した動画があります
            </StatusTxt>
          </Status>
        )}
        {props.hasProcessingStatus && (
          <Status>
            <StatusIcon name="exclamation" color={theme.color.orange[1]} />
            <StatusTxt color={theme.color.orange[1]}>
              変換中の動画があります
            </StatusTxt>
          </Status>
        )}
      </Tooltip>
    </>
  )
}

const Status = styled.div`
  display: flex;
  align-items: center;
`

const StatusIcon = styled(Icon)<{ color: string }>`
  margin-right: 4px;
  ${({ color }) =>
    css`
      color: ${color};
    `}
`

const StatusTxt = styled(Txt)`
  line-height: 1.8;
`
