import { useMutation, useQueryClient } from 'react-query'
import {
  CreateRecInterviewQuestionTemplateRequestValue,
  useRequestCreateRecInterviewQuestionTemplate,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateQuestionTemplate() {
  const queryClient = useQueryClient()
  const { requestCreateRecInterviewQuestionTemplate } =
    useRequestCreateRecInterviewQuestionTemplate()

  return useMutation(
    (value: CreateRecInterviewQuestionTemplateRequestValue) =>
      requestCreateRecInterviewQuestionTemplate(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.questionTemplates)
      },
    }
  )
}
