import { useCallback } from 'react'
import { toast } from '@blue-agency/rogue'
import { Presenter } from './Presenter'
import { useCloseVideoSeminar } from './useCloseVideoSeminar'
import { useOpenVideoSeminar } from './useOpenVideoSeminar'

type Props = {
  videoSeminarGuid: string
  isPublic: boolean
  onClose: () => void
}
export const PublicSettingModal: React.VFC<Props> = (props) => {
  const { mutateAsync: open } = useOpenVideoSeminar(props.videoSeminarGuid)
  const { mutateAsync: close } = useCloseVideoSeminar(props.videoSeminarGuid)

  const onToggle = useCallback(async () => {
    if (props.isPublic) {
      await close()
      toast('セミナーを公開停止しました')
    } else {
      await open()
      toast('セミナーを公開しました')
    }
  }, [props.isPublic, open, close])

  return (
    <Presenter
      isPublic={props.isPublic}
      onToggle={onToggle}
      onClose={props.onClose}
    />
  )
}
