import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestDeleteSelectionStepMaster,
  DeleteSelectionStepMasterRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteSelectionStepMaster() {
  const queryClient = useQueryClient()
  const { requestDeleteSelectionStepMaster } =
    useRequestDeleteSelectionStepMaster()

  return useMutation(
    (value: DeleteSelectionStepMasterRequestValue) =>
      requestDeleteSelectionStepMaster(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.selectionStepMasters)
      },
    }
  )
}
