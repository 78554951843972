import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateWebInterviewDurationRequest } from '@blue-agency/proton/biz_hutt_bff'
import { WebInterviewDuration } from '@blue-agency/proton/im'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateWebInterviewDurationRequestValue = {
  webInterviewGuid: string
  durationMinutes: number | undefined
}

export function useRequestUpdateWebInterviewDuration() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateWebInterviewDuration = useCallback(
    async (value: UpdateWebInterviewDurationRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateWebInterviewDurationRequest()

      const duration = new WebInterviewDuration()
      if (value.durationMinutes === undefined) {
        duration.setUnspecified(true)
      } else {
        const specified = new WebInterviewDuration.Specified()
        specified.setSpecified(true)
        specified.setDurationSeconds(value.durationMinutes * 60)
        duration.setSpecified(specified)
      }

      req.setWebInterviewGuid(value.webInterviewGuid)
      req.setDuration(duration)

      return callBizHuttBffService(
        'updateWebInterviewDuration',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestUpdateWebInterviewDuration }
}
