import { LOCAL_STORAGE_KEY } from './localStorageKey'

type StorageKey = keyof typeof LOCAL_STORAGE_KEY

const emptyJsonStr = '{}'

/**
 * localStorageにあるJSON形式のデータに値を追加
 * @param storageKey
 * @param key
 * @param value
 */
export const saveToLocalStorageJson = (
  storageKey: StorageKey,
  key: string,
  value: string
) => {
  const jsonStr = window.localStorage.getItem(storageKey) ?? emptyJsonStr
  const json = JSON.parse(jsonStr)
  json[key] = value
  window.localStorage[storageKey] = JSON.stringify(json)
}

/**
 * localStorageにあるJSON形式のデータから値を取得
 * @param storageKey
 * @param key
 * @returns value
 */
export const getFromLocalStorageJson = (
  storageKey: StorageKey,
  key: string
) => {
  const jsonStr = window.localStorage.getItem(storageKey) ?? emptyJsonStr
  const json = JSON.parse(jsonStr)
  return (json[key] as string) ?? ''
}

/**
 * localStorageにあるJSON形式のデータからプロパティを削除
 * @param storageKey
 * @param key
 */
export const deleteItemFromLocalStorageJson = (
  storageKey: StorageKey,
  key: string
) => {
  const jsonStr = window.localStorage.getItem(storageKey) ?? emptyJsonStr
  const json = JSON.parse(jsonStr)
  delete json[key]
  window.localStorage[storageKey] = JSON.stringify(json)
}
