import { useCallback, useState } from 'react'
import {
  Icon,
  SecondaryLinkTxt,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { useEllipsisTxt, EllipsisTxt } from '@/components/EllipsisTxt'
import { ChangePlayingAnswerVideo } from '@/pages/SelectionStepPage/SpRoot/AnswerVideoPreview/useAnswerVideoPreview'
import {
  getSelectionStepResultText,
  mapResultMeta,
  SelectionStep as SelectionStepType,
  SelectionStepResult,
} from '@/services/applicantService'
import { SelectionRecInterviewQuestions } from './SelectionRecInterviewQuestions'

type Props = PropsWithClassName<{
  targetSelectionStep: SelectionStepType
  changePlayingAnswerVideo: ChangePlayingAnswerVideo
  organizationRecInterviewTranscriptionSetting: boolean
}>

export const SelectionStep: React.VFC<Props> = (props) => {
  const resultMeta = mapResultMeta(props.targetSelectionStep.result)
  const [disableEllipsis, setDisableEllipsis] = useState(false)
  const showMore = useCallback(() => setDisableEllipsis(true), [])

  const { ellipsisTxtRef, isEllipsized } = useEllipsisTxt()

  return (
    <Wrapper className={props.className}>
      <Title>
        <TitleTxt>{props.targetSelectionStep.name}</TitleTxt>
      </Title>
      {/* 選考(≠選考ステップ)未評価の場合は、「未評価」が出るのが紛らわしいので選考結果部分を表示しない */}
      {props.targetSelectionStep.result !==
        SelectionStepResult.SELECTION_STEP_RESULT_NOT_ASSESSED && (
        <Content>
          <Result color={resultMeta.color}>
            <Icon name={resultMeta.iconName} width="22px" height="22px" />
            <ResultTxt color={resultMeta.color}>
              {getSelectionStepResultText(props.targetSelectionStep.result)}
            </ResultTxt>
          </Result>
          {props.targetSelectionStep.comment !== '' && (
            <>
              <Separator />
              <OmittedWrapper>
                <Comment ref={ellipsisTxtRef} disableEllipsis={disableEllipsis}>
                  {props.targetSelectionStep.comment}
                </Comment>
                {isEllipsized && !disableEllipsis && (
                  <ShowMore onClick={showMore}>
                    <SecondaryLinkTxt size="s">もっと見る</SecondaryLinkTxt>
                  </ShowMore>
                )}
              </OmittedWrapper>
            </>
          )}
        </Content>
      )}
      {props.targetSelectionStep.selectionRecInterview && (
        <SelectionRecInterviewQuestions
          questionAnswerPairsList={
            props.targetSelectionStep.selectionRecInterview
              .questionAnswerPairsList
          }
          beforeSubmitAnswer={
            props.targetSelectionStep.selectionRecInterview.submissionTime ===
            undefined
          }
          changePlayingAnswerVideo={props.changePlayingAnswerVideo}
          organizationRecInterviewTranscriptionSetting={
            props.organizationRecInterviewTranscriptionSetting
          }
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};
`

const Title = styled.div`
  height: 32px;
  background-color: ${theme.color.navy[3]};
  display: flex;
  align-items: center;
  padding: 0 8px;
`

const TitleTxt = styled(Txt)`
  white-space: nowrap;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  max-width: 500px;
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
`

const Result = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`

const ResultTxt = styled(Txt).attrs({ size: 'xl' })`
  margin-left: 12px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 10px;
`

const Comment = styled(EllipsisTxt)`
  margin-top: 10px;
`

const OmittedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ShowMore = styled.div.attrs({ role: 'button' })`
  align-self: flex-end;
`
