import { useCallback, useState } from 'react'

type Step = 'input' | 'confirm' | 'completed'

export const useStep = () => {
  const [step, setStep] = useState<Step>('input')

  const toInput = useCallback(() => setStep('input'), [])
  const toConfirm = useCallback(() => setStep('confirm'), [])
  const toCompleted = useCallback(() => setStep('completed'), [])

  return {
    step,
    toInput,
    toConfirm,
    toCompleted,
  }
}
