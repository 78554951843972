import React from 'react'
import {
  AuthContainer,
  EmbeddedSignInPage,
  EmbeddedSignInCallbackPage,
  EmbeddedSignOutPage,
} from '@blue-agency/im-shared-front'
import { Switch, Route } from 'react-router-dom'
import { EmbeddedWebInterviewPage } from '@/pages/EmbeddedWebInterviewPage'
import { EMBEDDED_INTERNAL_PATHS } from '@/services/urlService'

export const EmbeddedRoutes: React.VFC = () => {
  const { isLoggedIn, authClient } = AuthContainer.useContainer()

  if (!isLoggedIn) {
    return <EmbeddedSignInPage />
  }

  return (
    <Switch>
      <Route
        exact
        path={EMBEDDED_INTERNAL_PATHS.webInterview}
        component={EmbeddedWebInterviewPage}
      />
      <Route
        exact
        path={authClient.embeddedSignInCallbackPath}
        component={EmbeddedSignInCallbackPage}
      />
      <Route
        exact
        path={authClient.embeddedSignOutPath}
        component={EmbeddedSignOutPage}
      />
    </Switch>
  )
}
