import { useCallback, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { useForm } from 'react-hook-form'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'
import { useChangeWebInterviewGuide } from './useChangeWebInterviewGuide'
import { useListWebInterviewGuides } from './useListWebInterviewGuides'

export type Form = {
  guideGuid: string
}

export function useChangeWebInterviewGuideForm() {
  const { webInterview } = WebInterviewPageContainer.useContainer()
  assert(webInterview)
  const currentGuide = webInterview.guide

  const guideList = useListWebInterviewGuides()

  const guideOptions = useMemo(() => {
    const guides = guideList.map((g) => ({
      label: g.name,
      value: g.guid,
    }))

    if (currentGuide === undefined) {
      return guides
    }

    const { guid: curGuid, name: curName } = currentGuide

    // 現在紐付いているガイドがアーカイブ済みの場合、`guides` にはそのアーカイブ済みのものが含まれない
    // その場合はここで追加して、選択肢上には出現するようにする
    if (guides.every((g) => g.value !== curGuid)) {
      guides.push({
        label: curName,
        value: curGuid,
      })

      guides.sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    }

    return guides
  }, [guideList, currentGuide])

  const { register, handleSubmit, formState } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      guideGuid: webInterview.guide?.guid ?? '',
    },
  })

  const { mutateAsync: changeWebInterviewGuide } = useChangeWebInterviewGuide()

  const submit_ = useCallback(
    async (form: Form) => {
      // 選択された面接ガイド(変更先)と、現在紐付いている面接ガイドが同じものである場合は、
      // バックエンドにリクエストしない
      // (現在紐付いているガイドがアーカイブ済みのケースをカバーするため)
      if (form.guideGuid === currentGuide?.guid) {
        toast('面接ガイドを保存しました')
        return
      }

      try {
        await changeWebInterviewGuide({
          guideGuid: form.guideGuid,
          webInterviewGuid: webInterview.guid,
        })
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }

      toast('面接ガイドを保存しました')
    },
    [webInterview, changeWebInterviewGuide, currentGuide]
  )

  const submit = useMemo(() => handleSubmit(submit_), [handleSubmit, submit_])

  return {
    submit,
    register,
    isSubmitting: formState.isSubmitting,
    guideOptions,
  }
}
