import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteSelectionStepFromApplicantRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type DeleteSelectionStepFromApplicantRequestValue = {
  selectionGuid: string
  selectionStepGuid: string
}

export function useRequestDeleteSelectionStepFromApplicant() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteSelectionStepFromApplicant = useCallback(
    async (value: DeleteSelectionStepFromApplicantRequestValue) => {
      const metadata = await buildMetadata()

      const req = new DeleteSelectionStepFromApplicantRequest()

      req.setSelectionGuid(value.selectionGuid)
      req.setSelectionStepGuid(value.selectionStepGuid)

      return callBizHuttBffService(
        'deleteSelectionStepFromApplicant',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestDeleteSelectionStepFromApplicant,
  }
}
