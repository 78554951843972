import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { BatchCreateApplicantsRequest } from '@blue-agency/proton/biz_hutt_bff'
import {
  Applicant,
  EducationalBackground,
  WorkHistory,
  SharingTypeInput,
  ApplicantCsvMappingDestination,
} from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  ApplicantForm,
  getGenderValue,
  SelectionSharingType,
} from '@/services/applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

type Mapping = {
  columnNumber: number
  src: string
  dst: ApplicantCsvMappingDestination
}

type Format = {
  guid: string
  mappings: Mapping[]
}

export type BatchCreateApplicantsRequestValue = {
  applicants: ApplicantForm[]
  selectionSharingType: SelectionSharingType
  updateFormat?: Format
}

export function useRequestBatchCreateApplicants() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchCreateApplicants = useCallback(
    async (v: BatchCreateApplicantsRequestValue) => {
      const metadata = await buildMetadata()

      const req = new BatchCreateApplicantsRequest()

      const applicants: Applicant[] = []
      v.applicants.forEach((a) => {
        const applicant = new Applicant()
        applicant.setExternalApplicantId(a.externalApplicantId ?? '')
        applicant.setFamilyName(a.familyName)
        applicant.setGivenName(a.givenName ?? '')
        applicant.setFamilyNameKana(a.familyNameKana ?? '')
        applicant.setGivenNameKana(a.givenNameKana ?? '')
        applicant.setEmail(a.email ?? '')
        applicant.setPostalCode(a.postalCode ?? '')
        applicant.setAddress(a.address ?? '')
        applicant.setGender(getGenderValue(a.gender))
        applicant.setGenderNote(a.genderNote ?? '')
        applicant.setBirthDate(
          a.birthDate ? Timestamp.fromDate(a.birthDate) : undefined
        )
        applicant.setPhoneNumber(a.phoneNumber ?? '')
        const educationalBackgroundsList = a.educationalBackgroundsList
          ?.filter((v) => v.schoolName !== '')
          .map((v) => {
            const educationalBackground = new EducationalBackground()
            educationalBackground.setSchoolName(v.schoolName ?? '')
            educationalBackground.setFaculty(v.faculty ?? '')
            educationalBackground.setNote(v.note ?? '')
            return educationalBackground
          })
        applicant.setEducationalBackgroundsList(educationalBackgroundsList)
        applicant.setEducationalBackgroundNote(
          a.educationalBackgroundNote ?? ''
        )
        const workHistoriesList = a.workHistoriesList
          ?.filter((v) => v.companyName !== '')
          .map((v) => {
            const workHistory = new WorkHistory()
            workHistory.setCompanyName(v.companyName ?? '')
            workHistory.setJobDescription(v.jobDescription ?? '')
            workHistory.setNote(v.note ?? '')
            return workHistory
          })
        applicant.setWorkHistoriesList(workHistoriesList)
        applicant.setWorkHistoryNote(a.workHistoryNote ?? '')
        applicant.setNote(a.note ?? '')

        applicants.push(applicant)
      })

      req.setApplicantsList(applicants)

      const sharingType = new SharingTypeInput()
      if (v.selectionSharingType === 'SHARING_TYPE_UNSHARED') {
        sharingType.setUnshared(true)
      } else {
        sharingType.setAll(true)
      }
      req.setSelectionSharingType(sharingType)

      if (v.updateFormat) {
        const updateFormat = new BatchCreateApplicantsRequest.UpdateFormat()
        updateFormat.setApplicantCsvFormatGuid(v.updateFormat.guid)
        updateFormat.setMappingsList(
          v.updateFormat.mappings.map((m) => {
            const mapping = new BatchCreateApplicantsRequest.Mapping()
            mapping.setSrc(m.src)
            mapping.setDst(m.dst)
            mapping.setColumnNumber(m.columnNumber)
            return mapping
          })
        )
        req.setUpdateFormat(updateFormat)
      } else {
        req.setNoUpdateFormat(true)
      }

      return callBizHuttBffService(
        'batchCreateApplicants',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestBatchCreateApplicants,
  }
}
