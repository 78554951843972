import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateApplicantCsvFormatNameRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateApplicantCsvFormatNameRequestValue = {
  guid: string
  name: string
}

export function useRequestUpdateApplicantCsvFormatName() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateApplicantCsvFormatName = useCallback(
    async (value: UpdateApplicantCsvFormatNameRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateApplicantCsvFormatNameRequest()

      req.setApplicantCsvFormatGuid(value.guid)
      req.setName(value.name)

      return callBizHuttBffService(
        'updateApplicantCsvFormatName',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateApplicantCsvFormatName,
  }
}
