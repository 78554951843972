import { CommonConfirmPage } from '@/components/WebInterviewGuideForm/CommonConfirmPage'
import { NewWebInterviewGuidePageContainer } from './newWebInterviewGuidePageContainer'

export function ConfirmPage() {
  const { backToInput, completedForm, register, isRegisterLoading } =
    NewWebInterviewGuidePageContainer.useContainer()

  return (
    <CommonConfirmPage
      type="new"
      completedForm={completedForm}
      isRegisterLoading={isRegisterLoading}
      exec={register}
      backToInput={backToInput}
    />
  )
}
