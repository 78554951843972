import { useCallback, useState, useEffect } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { isValidDeadline } from '@/services/recInterviewTemplateService'
import {
  ChangeSelectionRecInterviewDeadlineModal as Modal,
  ErrorType,
} from './ChangeSelectionRecInterviewDeadlineModal'
import { useChangeSelectionRecInterviewDeadline } from './useChangeSelectionRecInterviewDeadline'

type Props = {
  active: boolean
  applicantGuid: string
  deadline: Date
  selectionStepGuid: string
  close: () => void
}
export const ChangeSelectionRecInterviewDeadlineModal: React.VFC<Props> = (
  props
) => {
  const [error, setError] = useState<ErrorType | undefined>(undefined)
  const [deadline, setDeadline] = useState<Date>(props.deadline)
  const { mutateAsync } = useChangeSelectionRecInterviewDeadline()

  const onChangeDeadline = useCallback((date: Date | null) => {
    if (date === null || !isValidDeadline(date, new Date())) {
      setError('pastDate')
      return
    }
    setError(undefined)
    setDeadline(date)
  }, [])

  const request = useCallback(async () => {
    if (!isValidDeadline(deadline, new Date())) {
      setError('pastDate')
      return
    }
    setError(undefined)

    try {
      await mutateAsync({
        applicantGuid: props.applicantGuid,
        selectionStepGuid: props.selectionStepGuid,
        notifyApplicant: false, // メール送信しない
        deadline,
      })
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        // メール送信はしないので、APPLICANT_EMAIL_NOT_FOUNDは返ってこない
        if (e.hasMatchErrorDetail('APPLICANT_EMAIL_NOT_FOUND')) {
          throw new Error('unexpected error: APPLICANT_EMAIL_NOT_FOUND')
        }
        if (e.hasMatchErrorDetail('SELECTION_REC_INTERVIEW_ALREADY_EXPIRED')) {
          setError('pastDate')
          return
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }

    toast('提出期限を変更しました')
    props.close()
  }, [props, mutateAsync, deadline])

  const onClose = useCallback(() => {
    setError(undefined)
    props.close()
  }, [props])

  useEffect(() => {
    setDeadline(props.deadline)
  }, [props.active, props.deadline])

  return (
    <Modal
      active={props.active}
      deadline={deadline}
      error={error}
      onClose={onClose}
      onSendMail={request}
      onChangeDeadline={onChangeDeadline}
    />
  )
}
