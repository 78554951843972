import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import {
  theme,
  Txt,
  PrimaryLinkTxt,
  NewTabLink,
  Icon,
} from '@blue-agency/rogue'
import { Pagination } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { Loading } from '@/components/Loading'
import { PageHeader } from '@/components/PageHeader'
import { ControlCenterPageContainer } from '@/pages/ControlCenterPage/ControlCenterPageContainer'
import { NotStartedInterviewsAlert } from '@/pages/ControlCenterPage/NotStartedInterviewsAlert'
import { OngoingList } from '@/pages/ControlCenterPage/OngoingList'
import { ReloadButton } from '@/pages/ControlCenterPage/ReloadButton'
import { Tabs } from '@/pages/ControlCenterPage/Tabs'
import { UpcomingList } from '@/pages/ControlCenterPage/UpcomingList'
import { AuthzContainer } from '@/services/authzService'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const ControlCenterPage: React.VFC = provide(ControlCenterPageContainer)(
  () => {
    const { authorizeByRpcName } = AuthzContainer.useContainer()
    authorizeByRpcName && authorizeByRpcName('ListUpcomingWebInterviews')

    const {
      tabType,
      setTabType,
      currentPage,
      pageCount,
      onSelectPage,
      isEnablePageControl,
      upcomingList,
      upcomingPassedRecentlyCount,
      ongoingList,
      loadTime,
      onReload,
      isLoading,
      isRefetching,
      loggedInStaff,
    } = ControlCenterPageContainer.useContainer()

    if (isLoading) {
      return <Loading />
    }
    assertIsDefined(loggedInStaff)

    const PageHeaderLeft = () => (
      <PageHeaderLeftWrapper>
        <ReloadButton
          loadTime={loadTime}
          onReload={onReload}
          isRefetching={isRefetching}
          staffGuid={loggedInStaff.guid}
        />
        <NotStartedInterviewsAlert
          upcomingPassedRecentlyCount={upcomingPassedRecentlyCount(loadTime)}
          onClick={() => setTabType('upcoming')}
        />
      </PageHeaderLeftWrapper>
    )

    const PageHeaderRight = () => (
      <PageHeaderRightWrapper>
        <Txt size="s">
          <QuestionIcon />
          ご利用方法は
          <NewTabLink
            href={EXTERNAL_PATHS.help.controlCenter}
            action="click_link_to_control_center_help_page_on_control_center_page"
          >
            <PrimaryLinkTxt size="s">ヘルプページ</PrimaryLinkTxt>
          </NewTabLink>
          をご確認ください
        </Txt>
        {isEnablePageControl && (
          <PaginationWrapper>
            <Pagination
              page={currentPage}
              count={pageCount}
              onRequestChange={onSelectPage}
            />
          </PaginationWrapper>
        )}
      </PageHeaderRightWrapper>
    )

    return (
      <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
        <PageHeaderWrapper>
          <PageHeader
            title="コントロールセンター"
            maxWidth="100%"
            left={<PageHeaderLeft />}
            right={<PageHeaderRight />}
          />
        </PageHeaderWrapper>
        <Wrapper>
          <SidebarWrapper>
            <Tabs />
          </SidebarWrapper>
          <MainWrapper>
            {tabType === 'upcoming' ? (
              <UpcomingList upcomingList={upcomingList} loadTime={loadTime} />
            ) : (
              <OngoingList ongoingList={ongoingList} loadTime={loadTime} />
            )}
          </MainWrapper>
        </Wrapper>
      </PageLayoutWithGlonavi>
    )
  }
)

const PageHeaderWrapper = styled.div`
  padding: 11px 0 15px;
  background-color: ${theme.color.white[1]};
`

const PageHeaderLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 50px;
  > *:not(:last-child) {
    margin-right: 40px;
  }
`

const PageHeaderRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  width: 100%;
  height: 100%;
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
`

const MainWrapper = styled.div`
  grid-area: main;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  position: relative;
  top: 7px;
  right: 4px;
`
