import { useState, useCallback } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { useModal } from '@/hooks/useModal'
import { BackgroundJob } from '@/services/applicantService'
import { ErrorDetailModal } from './ErrorDetailModal'
import { List } from './List'
import { useApplicantsBackgroundJob } from './useApplicantsBackgroundJob'

export const ApplicantsBackgroundJobsPage: React.VFC = () => {
  const ctx = useApplicantsBackgroundJob()
  const [selectedJob, setSelectedJob] = useState<BackgroundJob>()

  const detail = useModal()
  const handleOpenErrorDetail = useCallback(
    (job: BackgroundJob) => {
      setSelectedJob(job)
      detail.open()
    },
    [detail]
  )

  if (ctx.isLoading || ctx.jobs === undefined) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader title="処理状況" />
      <List jobs={ctx.jobs} onClickErrorDetail={handleOpenErrorDetail} />
      {detail.active && selectedJob && (
        <ErrorDetailModal job={selectedJob} onClose={detail.close} />
      )}
    </PageLayoutWithGlonavi>
  )
}
