import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  name: string
}

export const IntervieweeAvatar: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="interviewee-avatar" width="42px" height="42px" />
      </IconWrapper>
      <TxtWrapper>
        <EllipsisTxt size="s" color={theme.color.navy[1]}>
          {props.name}
        </EllipsisTxt>
      </TxtWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 48px;
  width: 63px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`

const TxtWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

const EllipsisTxt = styled(Txt)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
