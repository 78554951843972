import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  WeakColorSeparator,
  Txt as _Txt,
  LineClampedTxt,
  Icon,
  NewTabLink,
  theme,
} from '@blue-agency/rogue'
import { Tooltip, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import styled from 'styled-components'
import { AssignmentTooltipInner } from '@/components/AssignmentTooltipInner'
import { SettingsSection } from '@/components/SettingsSection'
import { StaffIcon } from '@/components/StaffIcon'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { EXTERNAL_PATHS } from '@/services/urlService'
import { getAssignmentOption } from '@/services/webInterviewService'
import { WEB_INTERVIEW_COUNT_MAX } from '../../services/webInterviewService'
import type { Staff } from './NewWebInterviewPageContainer'
import * as C from './components'
import { useConfirmPage } from './useConfirmPage'

const AssigneeList: React.VFC<{
  assignees: Staff[]
}> = ({ assignees }) => {
  return !assignees?.length ? (
    <Txt>未設定</Txt>
  ) : (
    <>
      {assignees.map((assignee) => (
        <SelectedAssignee key={assignee.guid}>
          <StaffIcon
            name={assignee.name.familyName}
            color={assignee.iconColorCode}
            size="s"
          />
          <AssigneeDescription>
            <AssigneeName>{assignee.name.fullName}</AssigneeName>
            <AssigneeEmail>{assignee.email}</AssigneeEmail>
          </AssigneeDescription>
        </SelectedAssignee>
      ))}
    </>
  )
}

export const ConfirmPage = (): React.ReactElement => {
  const {
    form,
    register,
    isRegisterLoading,
    backToInput,
    interviewers,
    viewers,
    lookupGuideNameByGuid,
  } = useConfirmPage()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>Web面接登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容の確認">
            <C.SectionInner>
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>追加数</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.countOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.countOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <C.TooltipWrapper>
                        <C.TooltipTxt>
                          面接の追加数を入力してください。
                          <br />
                          同時に{WEB_INTERVIEW_COUNT_MAX}
                          件まで追加できます。
                        </C.TooltipTxt>
                      </C.TooltipWrapper>
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ValueTxt>{form.count}</ValueTxt>
                  <Txt>
                    複数の面接を登録する場合は、登録するすべての面接に共通する項目のみを入力してください。
                  </Txt>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>面接名</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.nameOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.nameOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <C.TooltipWrapper>
                        <C.TooltipTxt>
                          社内管理用の面接名です。応募者には表示されません。複数追加される場合、面接名の末尾に自動で連番が付与されます。
                          <br />
                          例）　新卒総合職　→　新卒総合職-0001
                        </C.TooltipTxt>
                        <C.TooltipDetailLinkWrapper>
                          <Txt>
                            <NewTabLink
                              href={EXTERNAL_PATHS.help.interviewName}
                              action="open_help_page_via_register_web_interview__tooltip_name"
                            >
                              詳細はこちら
                            </NewTabLink>
                          </Txt>
                        </C.TooltipDetailLinkWrapper>
                      </C.TooltipWrapper>
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ValueTxt>
                    {
                      // form.nameが空文字列のときも「面接名未設定」にしたいため、nullish coalescingは使えない
                      form.name ? form.name : '面接名未設定'
                    }
                  </ValueTxt>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>予定時間</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ValueTxt>
                    {form.scheduledDate !== undefined &&
                    form.scheduledHour !== undefined &&
                    form.scheduledMin !== undefined
                      ? `${format(form.scheduledDate, 'yyyy/MM/dd(E)', {
                          locale: ja,
                        })} ${form.scheduledHour}:${form.scheduledMin}`
                      : '未設定'}
                  </ValueTxt>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>目安時間</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ValueTxt>
                    {form.durationMinutes
                      ? `${form.durationMinutes}分`
                      : '未設定'}
                  </ValueTxt>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>担当設定</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.assignmentOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.assignmentOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <AssignmentTooltipInner />
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea data-testid="assignment">
                  <ValueTxt>
                    {getAssignmentOption(form.assignment)?.label}
                  </ValueTxt>
                  {form.assignment === 'specified' && (
                    <>
                      <div>
                        <AssigneeRoleTxt>面接官</AssigneeRoleTxt>
                        <AssigneeList assignees={interviewers} />
                      </div>
                      <div>
                        <AssigneeRoleTxt>閲覧者</AssigneeRoleTxt>
                        <AssigneeList assignees={viewers} />
                      </div>
                    </>
                  )}
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>ガイド</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ValueTxt>
                    {lookupGuideNameByGuid(form.guideGuid) ?? '未設定'}
                  </ValueTxt>
                </C.InputArea>
              </C.FormBlock>
            </C.SectionInner>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_cancel_register_web_interview_button_on_confirm_page',
              }}
              onClick={backToInput}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              disabled={isRegisterLoading}
              comlinkPushParams={{
                action: 'click_register_web_interview_button',
              }}
              onClick={register}
            >
              登録
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })``

const ValueTxt = styled(Txt)`
  height: 32px;
  line-height: 32px;
`

const AssigneeRoleTxt = styled(_Txt).attrs({ size: 's' })`
  line-height: 21px;
  font-weight: bold;
`

const SelectedAssignee = styled.li`
  align-items: center;
  display: flex;
  padding: 2px 6px;
`

const AssigneeDescription = styled.div`
  margin-left: 4px;
`

const AssigneeName = styled(LineClampedTxt).attrs({ size: 'm', line: 1 })``
const AssigneeEmail = styled(LineClampedTxt).attrs({
  size: 's',
  line: 1,
  color: theme.color.gray[1],
})``
