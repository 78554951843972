import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Icon, SecondaryLinkTxt, theme, Txt } from '@blue-agency/rogue'
import { Switch, Case } from 'react-if'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { EllipsisTxt, useEllipsisTxt } from '@/components/EllipsisTxt'
import {
  TabWrapper,
  TabsHeader,
  Tabs,
  TabsInner,
  TabItem,
  TabContentsWrapper,
  TabContents,
  TabNavPrevButton,
  TabNavNextButton,
} from '@/components/RecInterviewTemplate'
import { useSelectionRecInterviewTab } from '@/hooks/useSelectionRecInterviewTab'
import { shouldShowMore } from '@/pages/ApplicantPage/Content/Selection/SelectionStep/SelectionRecInterviewQuestions'
import { ChangePlayingAnswerVideo } from '@/pages/SelectionStepPage/SpRoot/AnswerVideoPreview/useAnswerVideoPreview'
import {
  QuestionAnswerPair,
  TextQuestion,
  VideoQuestion,
  VideoFileTranscodeStatus,
  VideoFileTranscriptionStatus,
} from '@/services/applicantService'

type Props = PropsWithClassName<{
  questionAnswerPairsList: QuestionAnswerPair[]
  beforeSubmitAnswer: boolean
  changePlayingAnswerVideo: ChangePlayingAnswerVideo
  organizationRecInterviewTranscriptionSetting: boolean
}>
export const SelectionRecInterviewQuestions: React.VFC<Props> = (props) => {
  const tabHooks = useSelectionRecInterviewTab({
    questionAnswerPairsList: props.questionAnswerPairsList,
  })
  const {
    currentTabIndex,
    showTabNavPrev,
    showTabNavNext,
    nextNavAction,
    prevNavAction,
    tabOverflow,
    tabsRef,
    setCurrentTabIndex,
    videoPlaybackRate,
    setVideoPlaybackRate,
  } = tabHooks

  return (
    <div className={props.className}>
      <TabWrapper>
        <TabsHeader isMobile>
          {tabOverflow && (
            <TabNavPrevButton active={showTabNavPrev} onClick={prevNavAction}>
              <Icon name="arrow" size="m" />
            </TabNavPrevButton>
          )}
          <Tabs isMobile isOverflow={tabOverflow} ref={tabsRef}>
            <TabsInner isOverflow={tabOverflow}>
              {props.questionAnswerPairsList.map((q, i) => (
                <TabItem
                  key={i}
                  onClick={() => setCurrentTabIndex(i)}
                  current={i === currentTabIndex}
                >
                  {q.format === 'text' && q.text?.question?.name}
                  {q.format === 'video' && q.video?.question?.name}
                </TabItem>
              ))}
            </TabsInner>
          </Tabs>
          {tabOverflow && (
            <TabNavNextButton active={showTabNavNext} onClick={nextNavAction}>
              <Icon name="arrow" size="m" />
            </TabNavNextButton>
          )}
        </TabsHeader>
        <TabContentsWrapper>
          {props.questionAnswerPairsList.map((q, i) => (
            <TabContents
              key={q.questionGuid}
              current={i === currentTabIndex}
              isMobile
            >
              {q.format === 'text' && (
                <TextQuestionComponent
                  key={q.questionGuid}
                  index={i}
                  textQuestionAnsertPair={q.text!}
                  beforeSubmitAnswer={props.beforeSubmitAnswer}
                />
              )}
              {q.format === 'video' && (
                <VideoQuestionComponent
                  key={q.questionGuid}
                  questionGuid={q.questionGuid}
                  index={i}
                  videoQuestionAnsertPair={q.video!}
                  beforeSubmitAnswer={props.beforeSubmitAnswer}
                  currentTabIndex={currentTabIndex}
                  videoPlaybackRate={videoPlaybackRate}
                  setVideoPlaybackRate={setVideoPlaybackRate}
                  organizationRecInterviewTranscriptionSetting={
                    props.organizationRecInterviewTranscriptionSetting
                  }
                />
              )}
            </TabContents>
          ))}
        </TabContentsWrapper>
      </TabWrapper>
    </div>
  )
}

type TextQuestionProps = {
  index: number
  textQuestionAnsertPair: TextQuestion
  beforeSubmitAnswer: boolean
}
const TextQuestionComponent: React.VFC<TextQuestionProps> = (props) => {
  const content = props.textQuestionAnsertPair.answer?.content
  const answerCondition = getAnswerCondition({
    status: undefined,
    content,
    beforeSubmitAnswer: props.beforeSubmitAnswer,
  })
  return (
    <Wrapper>
      <Title>
        <Bold>問 {props.index + 1}</Bold>
        {props.textQuestionAnsertPair.question?.name}
      </Title>
      <ContentWithShowMore
        content={props.textQuestionAnsertPair.question?.content}
        note={props.textQuestionAnsertPair.question?.note}
      />
      <Switch>
        <Case condition={answerCondition === 'beforeSubmitAnswer'} />
        <Case condition={answerCondition === 'noAnswer'}>
          <NoAnswerTxt>未回答</NoAnswerTxt>
        </Case>
        <Case condition={answerCondition === 'hasAnswer'}>
          <Answer>{content}</Answer>
        </Case>
      </Switch>
    </Wrapper>
  )
}

type VideoQuestionProps = {
  questionGuid: string
  index: number
  videoQuestionAnsertPair: VideoQuestion
  beforeSubmitAnswer: boolean
  currentTabIndex: number
  videoPlaybackRate: number
  setVideoPlaybackRate: (value: number) => void
  organizationRecInterviewTranscriptionSetting: boolean
}
const VideoQuestionComponent: React.VFC<VideoQuestionProps> = (props) => {
  const status = props.videoQuestionAnsertPair.answer?.transcodeStatus
  const filekey = props.videoQuestionAnsertPair.answer?.fileKey
  const answerCondition = getAnswerCondition({
    status,
    content: filekey,
    beforeSubmitAnswer: props.beforeSubmitAnswer,
  })
  const videoRef = useRef<HTMLVideoElement>(null)

  const videoFileTranscription =
    props.videoQuestionAnsertPair.answer?.videoFileTranscription

  const { index, currentTabIndex, videoPlaybackRate, setVideoPlaybackRate } =
    props

  const changePlaybackRate = useCallback(
    (event: Event) => {
      const target = event.target as HTMLVideoElement
      setVideoPlaybackRate(target.playbackRate)
    },
    [setVideoPlaybackRate]
  )

  useEffect(() => {
    const ref = videoRef.current
    if (!ref) return
    ref.addEventListener('ratechange', changePlaybackRate)
    return () => {
      ref.removeEventListener('ratechange', changePlaybackRate)
    }
  }, [changePlaybackRate])

  useEffect(() => {
    const ref = videoRef.current
    if (!ref) return

    if (index !== currentTabIndex) {
      // アクティブなタブでなければ一時停止させる
      ref.pause()
    } else {
      // アクティブなタブなら再生させる
      ref.play()
    }
    ref.playbackRate = videoPlaybackRate
  }, [index, currentTabIndex, videoPlaybackRate])

  const hiddenVideoFileTranscription = useMemo(
    () =>
      !props.organizationRecInterviewTranscriptionSetting ||
      status === VideoFileTranscodeStatus.TRANSCODING ||
      (videoFileTranscription?.videoFileTranscriptionStatus ===
        VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_COMPLETED &&
        !videoFileTranscription?.transcription),
    [
      status,
      videoFileTranscription,
      props.organizationRecInterviewTranscriptionSetting,
    ]
  )

  return (
    <Wrapper>
      <Title>
        <Bold>問 {props.index + 1}</Bold>
        {props.videoQuestionAnsertPair.question?.name}
      </Title>
      <ContentWithShowMore
        content={props.videoQuestionAnsertPair.question?.content}
        note={props.videoQuestionAnsertPair.question?.note}
      />
      <Switch>
        <Case condition={answerCondition === 'beforeSubmitAnswer'} />
        <Case condition={answerCondition === 'noAnswer'}>
          <NoAnswerTxt>未回答</NoAnswerTxt>
        </Case>
        <Case condition={answerCondition === 'hasAnswer'}>
          <Switch>
            <Case condition={status === VideoFileTranscodeStatus.TRANSCODED}>
              <VideoWrapper>
                <Video
                  ref={videoRef}
                  src={filekey}
                  controlsList="nodownload"
                  controls
                />
                {!hiddenVideoFileTranscription && (
                  <VideoFileTranscriptionComponent
                    videoFileTranscriptionStatus={
                      videoFileTranscription?.videoFileTranscriptionStatus
                    }
                    transcription={videoFileTranscription?.transcription}
                  />
                )}
              </VideoWrapper>
            </Case>
            <Case condition={status === VideoFileTranscodeStatus.TRANSCODING}>
              <>
                <Margin />
                <TranscodingStatus />
              </>
            </Case>
            <Case condition={status === VideoFileTranscodeStatus.FAILED}>
              <>
                <Margin />
                <FailedStatus />
              </>
            </Case>
          </Switch>
        </Case>
      </Switch>
    </Wrapper>
  )
}

type VideoFileTranscriptionProps = {
  videoFileTranscriptionStatus: VideoFileTranscriptionStatus | undefined
  transcription: string | undefined
}

const VideoFileTranscriptionComponent: React.FC<
  VideoFileTranscriptionProps
> = ({ videoFileTranscriptionStatus, transcription }) => {
  return (
    <VideoTransliteration>
      <VideoTransliterationTitle>文字起こし（β版）</VideoTransliterationTitle>

      {videoFileTranscriptionStatus ===
      VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_COMPLETED ? (
        <VideoTransliterationSuccess>
          {transcription}
        </VideoTransliterationSuccess>
      ) : videoFileTranscriptionStatus ===
        VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_FAILED ? (
        <VideoTransliterationIconWrapper color={theme.color.red.iM}>
          <Icon name="exclamation" />
          <VideoTransliterationIconBody>
            <VideoTransliterationIconTitle>
              文字起こしができませんでした
            </VideoTransliterationIconTitle>
            音声が入ってない、音声が小さい、動画が短すぎる
            などの可能性があります。
          </VideoTransliterationIconBody>
        </VideoTransliterationIconWrapper>
      ) : (
        <VideoTransliterationIconWrapper color="#3182CE">
          <Icon name="exclamation" />
          <VideoTransliterationIconBody>
            <VideoTransliterationIconTitle>
              文字起こし準備中...
            </VideoTransliterationIconTitle>
            しばらくお待ちください。
          </VideoTransliterationIconBody>
        </VideoTransliterationIconWrapper>
      )}
    </VideoTransliteration>
  )
}

const FailedStatus = () => (
  <TranscodedStatusTxt>
    <FaildIconWrapper>
      <Icon name="exclamation" />
    </FaildIconWrapper>
    録画データの変換に失敗しました
  </TranscodedStatusTxt>
)

const TranscodingStatus = () => (
  <TranscodedStatusTxt>録画データを変換しています…</TranscodedStatusTxt>
)

type ContentWithShowMoreProps = {
  content?: string
  note?: string
}
const ContentWithShowMore: React.VFC<ContentWithShowMoreProps> = (props) => {
  const contentEllipsis = useEllipsisTxt()
  const [disableEllipsis, setDisableEllipsis] = useState(false)
  const showMore = useCallback(() => setDisableEllipsis(true), [])

  return (
    <ContentWithShowMoreWrapper>
      <Content
        ref={contentEllipsis.ellipsisTxtRef}
        disableEllipsis={disableEllipsis}
      >
        {props.content}
      </Content>
      {props.note && disableEllipsis && <Note>{props.note}</Note>}
      {shouldShowMore({
        isContentEllipsized: contentEllipsis.isEllipsized,
        disableEllipsis,
        hasNote: !!props.note,
      }) && (
        <ShowMore onClick={showMore}>
          <SecondaryLinkTxt size="s">もっと見る</SecondaryLinkTxt>
        </ShowMore>
      )}
    </ContentWithShowMoreWrapper>
  )
}

type GetAnswerConditionParam = {
  status: VideoFileTranscodeStatus | undefined
  content: string | undefined
  beforeSubmitAnswer: boolean
}
const getAnswerCondition = (
  param: GetAnswerConditionParam
): 'beforeSubmitAnswer' | 'noAnswer' | 'hasAnswer' => {
  if (param.beforeSubmitAnswer) return 'beforeSubmitAnswer'
  if (param.content || param.status === VideoFileTranscodeStatus.TRANSCODING)
    return 'hasAnswer'
  return 'noAnswer'
}

const Wrapper = styled.div`
  &&:not(:first-of-type) {
    margin-top: 20px;
  }
`

const Title = styled(Txt).attrs({
  size: 's',
  color: theme.color.white[1],
})`
  display: inline-block;
  background-color: ${theme.color.navy[1]};
  border-radius: 4px;
  padding: 2px 7px;
`

const Content = styled(EllipsisTxt).attrs({ size: 's' })`
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const Note = styled(Txt).attrs({
  size: 's',
  color: theme.color.gray[1],
})`
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const Bold = styled.span`
  font-weight: bold;
  margin-right: 4px;
`

const Answer = styled(Txt).attrs({ bold: true, size: 'm' })`
  border-radius: 4px;
  background-color: ${theme.color.gray[5]};
  margin-top: 8px;
  padding: 4px 8px;
  max-width: 1012px;
`

const VideoWrapper = styled.div`
  padding: 8px;
  margin-top: 8px;
`

const Video = styled.video`
  max-width: 515px;
  max-height: 330px;
  margin-top: 8px;
  width: 100%;
`

const VideoTransliteration = styled.div`
  margin-top: 12px;
  line-height: 21px;
`

const VideoTransliterationTitle = styled(Txt).attrs({
  bold: true,
  size: 'm',
})`
  margin-bottom: 8px;
`

const VideoTransliterationSuccess = styled(Txt).attrs({
  size: 's',
})`
  overflow-y: auto;
`

const VideoTransliterationIconWrapper = styled.div<{ color: string }>`
  display: flex;
  svg {
    font-size: ${theme.fontSize.xl};
    color: ${({ color }) => color};
  }
`

const VideoTransliterationIconTitle = styled.div`
  font-weight: bold;
`

const VideoTransliterationIconBody = styled.div`
  margin-left: 14px;
  flex: 1;
`

const TranscodedStatusTxt = styled(Txt)`
  display: inline-flex;
  background-color: ${theme.color.gray[4]};
  border-radius: 4px;
  padding: 0 10px;
  height: 40px;
  align-items: center;
`

const Margin = styled.div`
  height: 8px;
`

const FaildIconWrapper = styled.span`
  color: ${theme.color.red[2]};
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const NoAnswerTxt = styled(Txt).attrs({ size: 'm', bold: true })`
  margin-top: 4px;
  margin-left: 2px;
`

const ContentWithShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ShowMore = styled.div.attrs({ role: 'button' })`
  align-self: flex-end;
`
