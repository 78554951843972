import { theme, Icon, WeakColorSeparator, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { timeFormat } from '@/pages/WebInterviewPage/WebInterviewGuide/types'

type Part = {
  title: string
  description: string
  startSeconds: number
}

type Props = {
  parts: Part[]
}

export function WebInterviewGuide({ parts }: Props) {
  return (
    <Container>
      <Header>
        <GuideIcon />
        <Txt>面接ガイド</Txt>
      </Header>
      <WeakColorSeparator />
      <Content>
        {parts.map((part) => {
          return (
            <GuidePart key={part.startSeconds}>
              <Time>
                <Txt size="l" color={theme.color.navy[1]}>
                  {timeFormat(part.startSeconds)}
                </Txt>
              </Time>
              <GuidePartBody>
                <GuidePartTitle>
                  <Txt size="m" color={theme.color.navy[1]} bold>
                    {part.title}
                  </Txt>
                </GuidePartTitle>
                <GuidePartDescription>
                  <Txt size="m" color={theme.color.navy[1]}>
                    {part.description}
                  </Txt>
                </GuidePartDescription>
              </GuidePartBody>
            </GuidePart>
          )
        })}
      </Content>
    </Container>
  )
}

const GuideIcon = styled(Icon).attrs({ name: 'guide' })`
  color: ${theme.color.navy[1]};
  margin-right: 8px;
`

const Container = styled.div`
  border: 1px solid ${theme.color.gray[4]};
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 12px;
  height: 40px;
  display: flex;
`

const Content = styled.div`
  padding: 10px 0;
  overflow-y: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  flex: 1;

  > *:not(:last-child) {
    margin-bottom: 24px;
  }
`

const GuidePart = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  background-color: ${theme.color.white[1]};
`

const Time = styled.div`
  margin: 0 4px 0 12px;
  width: 50px;
  min-width: 50px;
`

const GuidePartBody = styled.div`
  display: flex;
  flex-direction: column;
`

const GuidePartTitle = styled.div``

const GuidePartDescription = styled.div``
