import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListRequestRecInterviewAnswerFailureDetailsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from './metadata'

export function useRequestListRequestRecInterviewAnswerFailureDetails() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListRequestRecInterviewAnswerFailureDetailsRequest = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new ListRequestRecInterviewAnswerFailureDetailsRequest()
      req.setJobGuid(guid)

      return callBizHuttBffService(
        'listRequestRecInterviewAnswerFailureDetails',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestListRequestRecInterviewAnswerFailureDetailsRequest }
}
