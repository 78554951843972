import { useCallback, useState } from 'react'

type Column = string

export interface SortByState<C extends Column> {
  readonly column: C
  readonly desc: boolean
}

export function useSortBy<S extends SortByState<Column>>({
  initialSortBy,
  descFirstColumns = [], // NOTE: 未ソート状態のカラムをソートするときdesc/ascどちらでソートするか
}: {
  initialSortBy: S
  descFirstColumns?: Array<S['column']>
}) {
  const [sortBy, setSortBy] = useState(initialSortBy)

  const toggleSortBy = useCallback(
    (column: S['column']) => {
      setSortBy((prevState) => {
        if (prevState.column === column) {
          return {
            ...prevState,
            desc: !prevState.desc,
          }
        }

        return {
          ...initialSortBy,
          column,
          desc: descFirstColumns.includes(column),
        }
      })
    },
    [initialSortBy, descFirstColumns]
  )

  return {
    sortBy,
    toggleSortBy,
  }
}
