import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { Form } from '@/services/webInterviewGuideService'
import { useCreateWebInterviewGuide } from './useCreateWebInterviewGuide'

type Step = 'input' | 'confirm'

type Response = {
  step: Step
  backToInput: () => void
  completedForm: Form | undefined
  completeInput: (form: Form) => void
  register: () => Promise<void>
  isRegisterLoading: boolean
  isCreationLimitExceeded: boolean
}

function useNewWebInterviewGuidePage(): Response {
  const { mutateAsync: createWebInterviewGuide, isLoading } =
    useCreateWebInterviewGuide()
  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<Form>()
  const [isCreationLimitExceeded, setIsCreationLimitExceeded] = useState(false)
  const history = useHistory()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: Form) => {
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      throw new Error('completedForm is undefined')
    }

    try {
      await createWebInterviewGuide(completedForm)
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError && e.isResourceExhausted) {
        setStep('input')
        setIsCreationLimitExceeded(true)
      } else {
        commonErrorToast()
      }
      Sentry.captureException(e)
      return
    }

    toast('面接ガイドを登録しました')
    history.push(INTERNAL_PATHS.webInterviewGuides)
  }, [completedForm, createWebInterviewGuide, history])

  return {
    step,
    backToInput,
    completedForm,
    completeInput,
    register,
    isRegisterLoading: isLoading,
    isCreationLimitExceeded,
  }
}

export const NewWebInterviewGuidePageContainer = createContainer(
  useNewWebInterviewGuidePage
)
