import { useQuery } from 'react-query'
import {
  SearchApplicantsArgs,
  useRequestSearchApplicants,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSearchApplicants(args: SearchApplicantsArgs) {
  const { requestSearchApplicants } = useRequestSearchApplicants()

  return useQuery(
    [QUERY_KEY.applicants, args.page, args.orderBy, args.condition],
    () => requestSearchApplicants(args),
    {
      keepPreviousData: true,
      staleTime: 1 * 60 * 1000,
    }
  )
}
