import { useCallback, RefObject } from 'react'
import { Icon, theme, Txt, Sort } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { DateTimeFormat } from '@/components/DateTimeFormat'
import { TableBase } from '@/components/TableBase'
import { WebInterviewStatusComponent } from '@/components/WebInterviewStatusComponent'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { SortBy, WebInterview } from './AssignedWebInterviewsContainer'
import { AssigneeIcons } from './AssigneeIcons'

type Props = {
  sortBy: SortBy
  toggleSortBy: (column: SortBy['column']) => void
  webInterviews: WebInterview[]
  setScrollTopQuery: (st: number) => void
  saveQueryAndListToBack: (guid: string) => void
  wrapperRef: RefObject<HTMLDivElement>
}

export const WebInterviewsTable: React.VFC<Props> = ({
  sortBy,
  toggleSortBy,
  webInterviews,
  setScrollTopQuery,
  saveQueryAndListToBack,
  wrapperRef,
}) => {
  const buildToggleSortBy = useCallback(
    (column: SortBy['column']) => () => toggleSortBy(column),
    [toggleSortBy]
  )

  const history = useHistory()

  return (
    <Table>
      <TableHead>
        <TableRow>
          <Th property="status">ステータス</Th>
          <Th property="name">
            <span>面接名</span>
            <Sort
              weakColor={theme.color.navy[2]}
              order={
                sortBy.column === 'name'
                  ? sortBy.desc
                    ? 'desc'
                    : 'asc'
                  : 'none'
              }
              onClick={buildToggleSortBy('name')}
            />
            <QuestionIconWrapper
              data-tip
              data-for={TOOLTIP_ID_MAP.nameOnWebInterviewList}
            >
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip
              id={TOOLTIP_ID_MAP.nameOnWebInterviewList}
              arrowPosition="topLeft"
            >
              <Txt>社内管理用の面接名。応募者には表示されません。</Txt>
            </Tooltip>
          </Th>
          <Th property="scheduledStartTimeAndDuration">
            <VerticalLabels>
              <VerticalLabel>予定日時</VerticalLabel>
              <VerticalLabel>目安時間</VerticalLabel>
            </VerticalLabels>
            <Sort
              weakColor={theme.color.navy[2]}
              order={
                sortBy.column === 'scheduledStartTime'
                  ? sortBy.desc
                    ? 'desc'
                    : 'asc'
                  : 'none'
              }
              onClick={() => toggleSortBy('scheduledStartTime')}
            />
          </Th>
          <Th property="viewOnly" />
          <Th property="interviewers">面接官</Th>
          <Th property="padding" />
          <Th property="registerTime">
            <span>登録日時</span>
            <Sort
              weakColor={theme.color.navy[2]}
              order={
                sortBy.column === 'registerTime'
                  ? sortBy.desc
                    ? 'desc'
                    : 'asc'
                  : 'none'
              }
              onClick={buildToggleSortBy('registerTime')}
            />
          </Th>
        </TableRow>
      </TableHead>
      <TableBody>
        {webInterviews.map((webInterview) => {
          const webInterviewPagePath = generatePath(
            INTERNAL_PATHS.webInterview,
            { webInterviewGuid: webInterview.guid }
          )
          const onClick = (
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
          ) => {
            const st = wrapperRef.current?.scrollTop ?? 0
            setScrollTopQuery(st)
            saveQueryAndListToBack(webInterview.guid)
            // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
            if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
              window.open(webInterviewPagePath)
              return
            }
            history.push({
              pathname: webInterviewPagePath,
            })
          }
          return (
            <TableRow key={webInterview.guid}>
              <Td property="status">
                <TableCellLink onClick={onClick}>
                  <WebInterviewStatusComponent status={webInterview.status} />
                </TableCellLink>
              </Td>
              <Td property="name">
                <TableCellLink onClick={onClick}>
                  <Txt>{webInterview.name}</Txt>
                </TableCellLink>
              </Td>
              <Td property="scheduledStartTimeAndDuration">
                <TableCellLink onClick={onClick}>
                  <VerticalLabels>
                    {webInterview.scheduledStartTime && (
                      <VerticalLabel>
                        {format(
                          webInterview.scheduledStartTime,
                          'yy/MM/dd HH:mm'
                        )}
                      </VerticalLabel>
                    )}
                    {webInterview.durationSeconds && (
                      <VerticalLabel>
                        {Math.floor(webInterview.durationSeconds / 60)}分
                      </VerticalLabel>
                    )}
                  </VerticalLabels>
                </TableCellLink>
              </Td>
              <Td property="viewOnly">
                <TableCellLink marginValue={0} onClick={onClick}>
                  {webInterview.assignedRole === 'viewer' && <ViewOnlyLabel />}
                </TableCellLink>
              </Td>
              <Td property="interviewers">
                <TableCellLink onClick={onClick}>
                  <AssigneeIcons
                    assignees={webInterview.interviewers}
                    tooltipId={`${TOOLTIP_ID_MAP.viewersOnWebInterviews}_${webInterview.guid}`}
                    maxDisplayCount={3}
                  />
                </TableCellLink>
              </Td>
              <Td property="padding">
                <TableCellLink onClick={onClick} />
              </Td>
              <Td property="registerTime">
                <TableCellLink onClick={onClick}>
                  <DateTimeFormat dateTime={webInterview.registerTime} />
                </TableCellLink>
              </Td>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

type Property =
  | 'status'
  | 'scheduledStartTimeAndDuration'
  | 'name'
  | 'viewOnly'
  | 'interviewers'
  | 'registerTime'
  | 'padding'

const width: Record<Property, number> = {
  name: 220,
  scheduledStartTimeAndDuration: 140,
  viewOnly: 56,
  status: 104,
  interviewers: 204,
  registerTime: 120,
  padding: 0,
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  scheduledStartTimeAndDuration: css`
    flex: 0 0 ${width.scheduledStartTimeAndDuration}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  viewOnly: css`
    flex: 0 0 ${width.viewOnly}px;
    justify-content: flex-start;
  `,
  interviewers: css`
    flex: 0 0 ${width.interviewers}px;
    justify-content: flex-start;
  `,
  status: css`
    flex: 0 0 ${width.status}px;
    justify-content: flex-start;
  `,
  registerTime: css`
    flex: 0 0 ${width.registerTime}px;
    justify-content: flex-start;
    > *:first-child {
      margin-right: 7px;
    }
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const QuestionIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
`

const TableCellLink = styled.a<{ marginValue?: number }>(
  ({ marginValue = 15 }) => `
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  padding: 0 ${marginValue}px;
  color: inherit;
  cursor: pointer;
`
)

const ViewOnlyLabel = styled.div.attrs({ children: '閲覧のみ' })`
  align-items: center;
  background: ${theme.color.gray[1]};
  color: ${theme.color.white[1]};
  display: flex;
  justify-content: center;
  height: 24px;
  width: 56px;
  font-size: ${theme.fontSize.s};
`

const VerticalLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`

const VerticalLabel = styled.div``
