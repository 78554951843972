import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { BatchUpdateSelectionStatusRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SelectionStatus } from '@blue-agency/proton/im'
import { assertNever } from '@/assertions'
import { SearchCondition } from '../applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import { toSearchApplicantCondition } from './toSearchApplicantCondition'

export type BatchUpdateSelectionStatusRequestValue = {
  condition: BatchUpdateSelectionStatusRequestCondition
  status: SelectionStatus
}

type BatchUpdateSelectionStatusRequestCondition =
  | {
      type: 'guids'
      applicantGuids: string[]
    }
  | {
      type: 'searchCondition'
      condition: SearchCondition
    }

export function useRequestBatchUpdateSelectionStatus() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchUpdateSelectionStatus = useCallback(
    async (value: BatchUpdateSelectionStatusRequestValue) => {
      const metadata = await buildMetadata()

      const req = new BatchUpdateSelectionStatusRequest()
      req.setStatus(value.status)
      const reqType = value.condition
      switch (reqType.type) {
        case 'guids': {
          const guids = new BatchUpdateSelectionStatusRequest.Guids()
          guids.setGuidsList(reqType.applicantGuids)
          req.setGuids(guids)
          break
        }
        case 'searchCondition': {
          const c = new BatchUpdateSelectionStatusRequest.Condition()
          c.setCondition(toSearchApplicantCondition(reqType.condition))
          req.setCondition(c)
          break
        }
        default:
          assertNever(reqType)
      }

      return callBizHuttBffService(
        'batchUpdateSelectionStatus',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestBatchUpdateSelectionStatus,
  }
}
