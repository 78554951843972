import { useState, useCallback } from 'react'
import type { StaffItem } from '@blue-agency/im-shared-front'
import { isAfter, isValid } from 'date-fns'
import { LOCAL_STORAGE_KEY } from '@/services/storageService'

type AssigneeLatestSelectedDateStr = {
  [guid: string]: string
}

/**
 * Web面接の担当選択のドロップダウンを最終選択日時降順に並び替える
 *
 * select: ユーザー選択時に呼び出す
 *
 * compareFn: Dropdownに表示するStaffItem[]のsort関数に渡す
 */
export const useLatestSelectedUser = () => {
  // compareFnの実行のたびにローカルストレージにアクセスしたくないので、useStateで持っておく
  const [assigneeSelectedDate, setAssigneeSelectedDate] =
    useState<AssigneeLatestSelectedDateStr>(
      () =>
        JSON.parse(
          window.localStorage.getItem(
            LOCAL_STORAGE_KEY.webInterviewAssigneeSelectedDate
          ) ?? '{}'
        ) as AssigneeLatestSelectedDateStr
    )

  const select = useCallback(
    (staffGuid: string) => {
      const json = {
        ...assigneeSelectedDate,
        [staffGuid]: new Date().toString(),
      }
      setAssigneeSelectedDate(json)
      window.localStorage[LOCAL_STORAGE_KEY.webInterviewAssigneeSelectedDate] =
        JSON.stringify(json)
    },
    [assigneeSelectedDate]
  )

  // 最終選択日時降順
  const compareFn = useCallback(
    (a: StaffItem, b: StaffItem): number => {
      const aDateStr = assigneeSelectedDate[a.guid]
      const bDateStr = assigneeSelectedDate[b.guid]
      return compareDateStr(aDateStr, bDateStr)
    },
    [assigneeSelectedDate]
  )

  return {
    select,
    compareFn,
  }
}

export const compareDateStr = (
  aDateStr: string | undefined,
  bDateStr: string | undefined
) => {
  // どちらも選択されたことがない → そのまま
  if (!aDateStr && !bDateStr) return 0
  // bが選択されたことがある && aが選択されたことがない → bを前にする
  if (!aDateStr) return 1
  // aが選択されたことがある && bが選択されたことがない → aを前にする
  if (!bDateStr) return -1

  const aDate = new Date(aDateStr)
  const bDate = new Date(bDateStr)

  // Date以外の文字列があった場合はソートしない
  if (!isValid(aDate) || !isValid(bDate)) return 0

  // 完全に同じ時刻だった場合ソートしない
  if (aDate.getTime() === bDate.getTime()) return 0

  return isAfter(bDate, aDate) ? 1 : -1
}
