import { useQuery } from 'react-query'
import { useRequestGetRecInterviewTemplate } from '@/services/bffService/useRequestGetRecInterviewTemplate'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetRecInterviewTemplate(templateGuid: string) {
  const { requestGetRecInterviewTemplate } = useRequestGetRecInterviewTemplate()

  return useQuery([QUERY_KEY.recInterviewTemplate, templateGuid], () =>
    requestGetRecInterviewTemplate(templateGuid)
  )
}
