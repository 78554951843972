import {
  WeakColorSeparator,
  Modal,
  ModalSize,
  Txt,
  theme,
  ErrorBox,
} from '@blue-agency/rogue'
import { TertiaryButton, SecondaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getBackgroundJobName } from '@/services/applicantService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { ModalBaseProps } from '.'

type Props = ModalBaseProps & {
  error: string
  detail?: React.ReactElement
  modalSize: ModalSize
}
export const DetailBaseModal: React.VFC<Props> = (props) => {
  return (
    <Modal
      active
      title="エラー詳細"
      size={props.modalSize}
      onClose={props.onClose}
      stopScroll
    >
      <ModalBody>
        <ErrorBoxWrapper>
          <ErrorBox>
            <Txt color={theme.color.red[2]}>{props.error}</Txt>
          </ErrorBox>
        </ErrorBoxWrapper>
        <DescriptionWrapper>
          <Txt color={theme.color.navy[1]}>
            <JobTypeTitle>処理名：</JobTypeTitle>
            {getBackgroundJobName(props.job.job)}
          </Txt>
          {props.detail && <DetailWrapper>{props.detail}</DetailWrapper>}
        </DescriptionWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_applicants_background_job_error_detail_modal_cancel_button',
            }}
          >
            閉じる
          </TertiaryButton>
          <ApplicantsLink to={INTERNAL_PATHS.applicants}>
            <SecondaryButton
              widthSize="L1"
              comlinkPushParams={{
                action:
                  'click_applicants_background_job_error_detail_modal_to_applicants_button',
              }}
            >
              応募者一覧画面へ
            </SecondaryButton>
          </ApplicantsLink>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 12px;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`
const JobTypeTitle = styled.span`
  color: ${theme.color.gray[1]};
  display: inline-block;
`

const DetailWrapper = styled.div`
  margin-top: 12px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`

const ApplicantsLink = styled(Link)`
  text-decoration: none;
`
