import { useMutation, useQueryClient } from 'react-query'
import { useRequestUpdateWebInterviewName } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateWebInterviewName() {
  const queryClient = useQueryClient()
  const { requestUpdateWebInterviewName } = useRequestUpdateWebInterviewName()

  return useMutation(requestUpdateWebInterviewName, {
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(QUERY_KEY.webInterviews)
      queryClient.invalidateQueries([
        QUERY_KEY.webInterview,
        req.webInterviewGuid,
      ])
      queryClient.invalidateQueries(QUERY_KEY.assignedWebInterviews, {
        refetchInactive: true,
      })
      queryClient.invalidateQueries(QUERY_KEY.upcomingWebInterviews)
      queryClient.invalidateQueries(QUERY_KEY.ongoingWebInterviews)
    },
  })
}
