import React from 'react'
import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import notInterviewedImage from './not-interviewed.svg'

export const NotInterviewedStatus = (): React.ReactElement => {
  return (
    <Wrapper>
      <ImageWrapper>
        <WebInterviewStatusImage src={notInterviewedImage} alt="" />
      </ImageWrapper>
      <Txt>面接を終了すると、録画データが表示されます。</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 40%;
  max-width: 300px;
`

const WebInterviewStatusImage = styled.img`
  margin-top: 28px;
  margin-bottom: 30px;
  width: 100%;
`
