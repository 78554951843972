import React from 'react'
import { NewTabLink, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const AssignmentTooltipInner: React.VFC = () => {
  return (
    <TooltipWrapper>
      <Txt bold>指定する</Txt>
      <Txt>面接官を指定して運用する場合に利用します。</Txt>
      <MarginedTxt bold>指定しない</MarginedTxt>
      <Txt>
        面接官を指定せずに、面接開始ページのURLを直接共有して運用する場合に利用します。
      </Txt>
      <MarginedTxt bold>指定しない（面接詳細の閲覧不可）</MarginedTxt>
      <Txt>
        面接官を指定せずに、面接開始ページのURLを直接共有して運用する場合に利用します。
        <br />
        ※どうしても面接官に録画データを見せたくない場合にご利用ください
      </Txt>
      <TooltipDetailLinkWrapper>
        <NewTabLink
          href={EXTERNAL_PATHS.help.interviewAssignment}
          action="open_assignment_help_page"
        >
          詳細はこちら
        </NewTabLink>
      </TooltipDetailLinkWrapper>
    </TooltipWrapper>
  )
}

const TooltipWrapper = styled.div`
  width: 460px;
`

const MarginedTxt = styled(Txt).attrs({ size: 'm' })`
  margin-top: 1em;
`

const TooltipDetailLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`
