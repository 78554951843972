import { A, Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  minutesUrl: string
}

export function MinutesLink({ minutesUrl }: Props) {
  return (
    <Container>
      <LabelWrapper>
        <Txt color={theme.color.navy[1]} size="l" textAlign="center" bold>
          議事録機能
        </Txt>
        <BetaTextWrapper>
          <Txt color={theme.color.navy[1]} size="xs">
            β版
          </Txt>
        </BetaTextWrapper>
      </LabelWrapper>
      <MainTextWrapper>
        <Txt color={theme.color.navy[1]} size="m" bold>
          面接中の会話がリアルタイムで文字起こしされます。
        </Txt>
      </MainTextWrapper>
      <OpenMinutesPageAnchor
        href={minutesUrl}
        target="_blank"
        rel="noopener noreferrer"
        comlinkPushParams={{
          action: 'open_minutes_page',
        }}
      >
        <AnchorContentsWrapper>
          <Txt color={theme.color.green[3]} size="m">
            議事録
          </Txt>
          <Icon name="target-blank" size="m" />
        </AnchorContentsWrapper>
      </OpenMinutesPageAnchor>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;

  margin: 0 0 12px 0;
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.gray[5]};
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  min-width: 133px;
  height: 38px;
  gap: 4px;
  background-color: ${theme.color.gray[3]};
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px 0 0 4px;
  padding: 0 12px;
`

const BetaTextWrapper = styled.div`
  border: 0.5px solid ${theme.color.navy[1]};
  border-radius: 2px;
  padding: 2px;
`

const MainTextWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100%;
  padding: 0 0 0 12px;
`

const OpenMinutesPageAnchor = styled(A)`
  width: 100px;
  padding: 0 12px 0 0;
  text-decoration: none;
`

const AnchorContentsWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 83px;
  height: 28px;
  gap: 5px;

  background-color: ${theme.color.white[1]};
  color: ${theme.color.green[3]};
  border-radius: 8px;
  border: 0.5px solid ${theme.color.green[4]};
  padding: 5px 8px 5px 8px;
`
