import React, { Suspense, useEffect } from 'react'
import {
  AuthContainer,
  BizAuthorizedErrorBoundary,
  BizUnauthorizedErrorBoundary,
  CommonErrorToastContainer,
  GeneralErrorPage,
  injectAlertBox,
  usePageLayoutComponent,
} from '@blue-agency/im-shared-front'
import '@blue-agency/im-shared-front/dist/assets/PageLayout.css'
import { ToastContainer, useResponsive } from '@blue-agency/rogue'
import '@blue-agency/rogue/dist/assets/style.css'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { AuthRoutes } from './AuthRoutes'
import { Loading } from './components/Loading'
import { useTracking } from './hooks/useTracking'
import { initSentry } from './initSentry'
import { initializeImSharedFront } from './initializeImSharedFront'

const alertJsonUrl = process.env.REACT_APP_ALERT_JSON_URL
assert(alertJsonUrl, 'alertJsonUrl is required')

initializeImSharedFront()

const history = createBrowserHistory()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  initSentry()
}

const App: React.FC = () => {
  const { responsive } = useResponsive()
  useTracking()
  usePageLayoutComponent()

  useEffect(() => {
    injectAlertBox(alertJsonUrl)
  }, [])

  return (
    <Router history={history}>
      <Sentry.ErrorBoundary
        fallback={() => <GeneralErrorPage showDashboardLink={false} />}
      >
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeProvider theme={{ responsive }}>
            <Suspense fallback={<Loading />}>
              <AuthContainer.Provider>
                <SharedErrorBoundary>
                  <QueryClientProvider client={queryClient}>
                    <AuthRoutes />
                    <ToastContainer />
                    <CommonErrorToastContainer />
                    <ReactQueryDevtools />
                  </QueryClientProvider>
                </SharedErrorBoundary>
              </AuthContainer.Provider>
            </Suspense>
          </ThemeProvider>
        </QueryParamProvider>
      </Sentry.ErrorBoundary>
    </Router>
  )
}

export default App

const SharedErrorBoundary: React.FC = (props) => {
  const { isLoggedIn, logout } = AuthContainer.useContainer()

  return isLoggedIn ? (
    <BizAuthorizedErrorBoundary onUnauthenticated={logout}>
      {props.children}
    </BizAuthorizedErrorBoundary>
  ) : (
    <BizUnauthorizedErrorBoundary>
      {props.children}
    </BizUnauthorizedErrorBoundary>
  )
}
