import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { RequestSelectionRecInterviewAnswerRequest } from '@blue-agency/proton/biz_hutt_bff'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type RequestSelectionRecInterviewAnswerRequestValue = {
  applicantGuid: string
  deadline: Date
  notifyApplicant: boolean
  selectionStepGuid: string
}

export function useRequestRequestSelectionRecInterviewAnswer() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestRequestSelectionRecInterviewAnswer = useCallback(
    async (value: RequestSelectionRecInterviewAnswerRequestValue) => {
      const metadata = await buildMetadata()

      const req = new RequestSelectionRecInterviewAnswerRequest()

      req.setApplicantGuid(value.applicantGuid)
      req.setDeadline(Timestamp.fromDate(value.deadline))
      req.setNotifyApplicant(value.notifyApplicant)
      req.setSelectionStepGuid(value.selectionStepGuid)

      return callBizHuttBffService(
        'requestSelectionRecInterviewAnswer',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestRequestSelectionRecInterviewAnswer,
  }
}
