import {
  GetRecInterviewTemplateResponse,
  ListAllRecInterviewTemplatesForOptionResponse,
} from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { RecInterviewTemplateDeadline, TemplateDetailQuestion } from './types'

export const mapRecInterviewTemplateDeadline = (
  proto: ListAllRecInterviewTemplatesForOptionResponse.RecInterviewTemplate
): RecInterviewTemplateDeadline => {
  switch (proto.getDeadlineCase()) {
    case ListAllRecInterviewTemplatesForOptionResponse.RecInterviewTemplate
      .DeadlineCase.DUE_DURATION:
      const duration = proto.getDueDuration()
      if (duration === undefined) {
        throw new Error('invalid duration')
      }
      return {
        case: 'dueDuration',
        dueDuration: duration.toObject(),
      }
    case ListAllRecInterviewTemplatesForOptionResponse.RecInterviewTemplate
      .DeadlineCase.DUE_TIME:
      const dueTime = proto.getDueTime()
      if (dueTime === undefined) {
        throw new Error('invalid dueTime')
      }
      return {
        case: 'dueTime',
        dueTime: dueTime.toDate(),
      }
    case ListAllRecInterviewTemplatesForOptionResponse.RecInterviewTemplate
      .DeadlineCase.DEADLINE_NOT_SET:
      throw new Error('invalid deadlineTemplate')
  }
}

export const mapRecInterviewTemplateDetailQuestion = (
  proto: GetRecInterviewTemplateResponse.Question
): TemplateDetailQuestion => {
  return {
    name: proto.getName(),
    content: proto.getContent(),
    note: proto.getNote(),
    required: proto.getRequired(),
    format: mapFormat(proto),
  }
}

const mapFormat = (
  proto: GetRecInterviewTemplateResponse.Question
): TemplateDetailQuestion['format'] => {
  switch (proto.getFormatCase()) {
    case GetRecInterviewTemplateResponse.Question.FormatCase.TEXT:
      return { format: 'text' }
    case GetRecInterviewTemplateResponse.Question.FormatCase.VIDEO:
      return {
        format: 'video',
        maxDurationSeconds: proto.getVideo()?.getMaxDurationSeconds() ?? 0,
        maxRecCount: proto.getVideo()?.getMaxRecCount() ?? 0,
      }
    case GetRecInterviewTemplateResponse.Question.FormatCase.FORMAT_NOT_SET:
      throw new Error('Got unknown rec interview question format')
  }
}
