import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateWebInterviewDuration,
  UpdateWebInterviewDurationRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateWebInterviewDuration() {
  const queryClient = useQueryClient()
  const { requestUpdateWebInterviewDuration } =
    useRequestUpdateWebInterviewDuration()

  return useMutation(
    (value: UpdateWebInterviewDurationRequestValue) =>
      requestUpdateWebInterviewDuration(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries([
          QUERY_KEY.webInterview,
          req.webInterviewGuid,
        ])
      },
    }
  )
}
