import React from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const BackToListButton = styled(TertiaryButton).attrs({
  children: '一覧に戻る',
  widthSize: 'L2',
})``

export const PrevInputButton = styled(TertiaryButton).attrs({
  children: '前の設定画面へ',
  widthSize: 'L2',
})``

export const NextInputButton = styled(PrimaryButton).attrs({
  children: '次の設定画面へ',
  widthSize: 'L2',
})``

export const ConfirmButton = styled(PrimaryButton).attrs({
  children: '内容を確認',
  widthSize: 'L2',
})``

export const SubmitButton = styled(PrimaryButton).attrs({
  children: '登録',
  widthSize: 'L2',
})``

export const SubmitEditButton = styled(PrimaryButton).attrs({
  children: '保存',
  widthSize: 'L2',
})``

export const CancelEditButton = styled(TertiaryButton).attrs({
  children: 'キャンセル',
  widthSize: 'L2',
})``

export const PreviewButton = styled(TertiaryButton).attrs({
  children: 'プレビュー',
  widthSize: 'M',
  heigtSize: 'S',
})``

export const CancelButton = styled(TertiaryButton).attrs({
  widthSize: 'L1',
  heigtSize: 'S',
  children: 'キャンセル',
})``

export const SaveButton = styled(PrimaryButton).attrs({
  widthSize: 'L1',
  heigtSize: 'S',
  children: '保存',
})``

type EditButtonProps = {
  onClick: () => void
}
export const EditButton: React.VFC<EditButtonProps> = ({ onClick }) => (
  <EditButtonInner
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    <Icon name="edit" size="s" />
    <EditButtonInnerText>編集</EditButtonInnerText>
  </EditButtonInner>
)

const EditButtonInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`
const EditButtonInnerText = styled.span`
  margin: auto 6px;
  font-size: ${theme.fontSize.m};
`
