import { Txt, theme } from '@blue-agency/rogue'
import assert from 'assert'
import styled from 'styled-components'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import type { Assignment } from '@/services/webInterviewService'
import { AssigneeIcons } from './AssigneeIcons'
import type { Assignee } from './types'

type Props = {
  webInterviewGuid: string
  assignment: Assignment
  interviewers?: Assignee[]
  maxDisplayCount?: number
}

export const InterviewersOrAssignment: React.VFC<Props> = ({
  webInterviewGuid,
  assignment,
  interviewers,
  maxDisplayCount,
}) => {
  switch (assignment) {
    case 'specified':
      assert(interviewers)
      return interviewers.length === 0 ? (
        <UnsetAssigneeTxt>未設定</UnsetAssigneeTxt>
      ) : (
        <AssigneeIcons
          assignees={interviewers}
          tooltipId={`${TOOLTIP_ID_MAP.interviewersOnWebInterviews}_${webInterviewGuid}`}
          maxDisplayCount={maxDisplayCount}
        />
      )
    case 'unspecified':
      return <Txt>指定しない</Txt>
    case 'unspecified-cannot-view-detail':
      return (
        <Txt>
          指定しない
          <br />
          （面接詳細の閲覧不可）
        </Txt>
      )
  }
}

const UnsetAssigneeTxt = styled(Txt).attrs({ size: 'm' })`
  color: ${theme.color.red[2]};
`
