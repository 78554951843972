import { format, differenceInYears } from 'date-fns'
import { Gender } from './applicantEnums'
import { Applicant, GenderType } from './applicantTypes'

export const maxEducationalBackgroundsNum = 3
export const maxWorkHistoriesNum = 5

export const getGenderValue = (type?: GenderType): Gender => {
  if (!type) return Gender.GENDER_UNKNOWN
  return Gender[type]
}

export const getGenderName = (gender: Gender): string => {
  switch (gender) {
    case Gender.GENDER_UNKNOWN:
      return ''
    case Gender.GENDER_MALE:
      return '男性'
    case Gender.GENDER_FEMALE:
      return '女性'
    case Gender.GENDER_OTHER:
      return 'その他'
    case Gender.GENDER_NO_ANSWER:
      return '無回答'
  }
}

export const getGenderType = (gender: Gender): GenderType => {
  switch (gender) {
    case Gender.GENDER_UNKNOWN:
      return 'GENDER_UNKNOWN'
    case Gender.GENDER_MALE:
      return 'GENDER_MALE'
    case Gender.GENDER_FEMALE:
      return 'GENDER_FEMALE'
    case Gender.GENDER_OTHER:
      return 'GENDER_OTHER'
    case Gender.GENDER_NO_ANSWER:
      return 'GENDER_NO_ANSWER'
  }
}

export const getApplicantFullName = (applicant: Applicant) =>
  `${applicant.familyName} ${applicant.givenName}`

export const birthDateFormat = (date: Date) => {
  const age = differenceInYears(new Date(), date)
  return `${format(date, 'yyyy/MM/dd')} (${age}歳)`
}

export const registerTimeFormat = (date: Date) => {
  return format(date, 'yyyy/MM/dd HH:mm')
}
