import { RequireTxt, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

export const SectionInner = styled.div`
  padding: 16px 20px;
`

export const AttentionCardWrapper = styled.div`
  padding: 0 16px;
`

export const AttentionCard = styled.div`
  background: ${theme.color.gray[5]};
  display: flex;
  margin-top: 4px;
  padding: 20px;
`

export const AttentionParagraph = styled.div`
  margin-left: 8px;
`

export const FormBlock = styled.div`
  padding: 20px 12px;
  display: grid;
  grid-template-columns: 140px 28px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'label-area required-area input-area';
`

export const FormBlockWithComplementaryText = styled.div`
  padding: 20px 12px;
  display: grid;
  grid-template-columns: 140px 28px 1fr;
  grid-template-rows: 2fr;
  grid-template-areas:
    'label-area required-area input-area'
    '. . complementary-text';
`

export const LabelArea = styled.div`
  grid-area: label-area;
`

export const LabelAreaInner = styled.div`
  height: 32px;
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 8px;
  }
`

export const Required = styled(RequireTxt).attrs({
  size: 's',
  children: '必須',
})`
  grid-area: required-area;
  align-items: center;
  display: flex;
  height: 32px;
`

export const InputArea = styled.div`
  grid-area: input-area;

  > *:not(:first-child) {
    margin-top: 12px;
  }
`

export const TooltipWrapper = styled.div`
  width: 280px;
`

export const TooltipTxt = styled(Txt).attrs({ size: 'm' })`
  line-height: 1.8;
`

export const TooltipDetailLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`

export const ComplementaryTextArea = styled.div`
  grid-area: complementary-text;
`
