import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  organizationName: string
  body: React.ReactNode
  bottom: React.ReactNode
}

export const PreviewLayout: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Content>
        <Header>
          <OrganizationName>{props.organizationName}</OrganizationName>
          <div>
            <Icon name="logo" width="67" height="48" />
          </div>
        </Header>
        <Body>{props.body}</Body>
      </Content>
      {props.bottom && <Bottom>{props.bottom}</Bottom>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[3]};
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
`

const OrganizationName = styled(Txt)`
  text-overflow: ellipsis;
  overflow: hidden;
`

const Content = styled.div`
  flex: 1;
  overflow: auto;
`

const Body = styled.div`
  padding: 16px;
`

const Bottom = styled.div`
  border-top: 1px solid ${theme.color.gray[3]};
  padding: 12px;
`
