import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QuestionAnswerPair } from '@/services/applicantService'

const TAB_ITEM_WIDTH = 114
export type UseSelectionRecInterviewTabPropsType = {
  questionAnswerPairsList: QuestionAnswerPair[]
}

export type UseSelectionRecInterviewTabResultType = {
  currentTabIndex: number
  showTabNavPrev: boolean
  showTabNavNext: boolean
  numberTabsDisplay: number
  tabOverflow: boolean
  tabsRef: RefObject<HTMLDivElement>
  nextNavAction: () => void
  prevNavAction: () => void
  setCurrentTabIndex: Dispatch<SetStateAction<number>>
  videoPlaybackRate: number
  setVideoPlaybackRate: (value: number) => void
}

export const useSelectionRecInterviewTab = ({
  questionAnswerPairsList,
}: UseSelectionRecInterviewTabPropsType): UseSelectionRecInterviewTabResultType => {
  // アクティブなタブ
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  // タブがBoxの横幅からはみ出る場合はtrue
  const [tabOverflow, setTabOverflow] = useState<boolean>(false)
  // 一度に表示されるタブの数
  const [numberTabsDisplay, setNumberTabsDisplay] = useState<number>(0)
  // 見えているタブグループの位置
  // ex) タブ数10で、一度に表示されるタブの数(numberTabsDisplay)が5の場合、グループのアイテム数は2つになる。1なら前半5つのタブ、2なら後半5つのタブが見えている事になる。
  const [tabsDisplayGroupIndex, setTabsDisplayGroupIndex] = useState<number>(0)
  const [showTabNavPrev, setShowTabNavPrev] = useState<boolean>(false)
  const [showTabNavNext, setShowTabNavNext] = useState<boolean>(false)
  const tabsRef = useRef<HTMLDivElement>(null)

  const [videoPlaybackRate, setVideoPlaybackRate] = useState<number>(1)

  const nextNavAction = () => {
    const questionNums = questionAnswerPairsList.length
    if (tabsDisplayGroupIndex >= Math.floor(questionNums / numberTabsDisplay)) {
      return
    }
    setTabsDisplayGroupIndex(tabsDisplayGroupIndex + 1)
  }

  const prevNavAction = () => {
    if (!tabsDisplayGroupIndex) {
      navScrollX()
      return
    }
    setTabsDisplayGroupIndex(tabsDisplayGroupIndex - 1)
  }

  const navScrollX = useCallback(() => {
    tabsRef.current?.scrollTo(
      TAB_ITEM_WIDTH * numberTabsDisplay * tabsDisplayGroupIndex,
      0
    )
  }, [tabsRef, numberTabsDisplay, tabsDisplayGroupIndex])

  useEffect(() => {
    navScrollX()
  }, [tabsDisplayGroupIndex, navScrollX])

  const onScrollTab = useCallback(
    (event: Event) => {
      const scLeft = (event.target as HTMLDivElement).scrollLeft
      setShowTabNavPrev(!!scLeft)

      const questionNums = questionAnswerPairsList.length
      const tabsWidth = tabsRef.current?.offsetWidth
      const allTabWidth = questionNums * TAB_ITEM_WIDTH

      if (!tabsWidth) {
        return
      }

      const scMoveNum = scLeft + tabsWidth

      // MEMO: 見えているタブグループの位置を設定する
      const displayTabIndex = scLeft / TAB_ITEM_WIDTH / numberTabsDisplay

      setTabsDisplayGroupIndex(displayTabIndex)

      // MEMO: 横幅全体から左スクロールした量を引いて1px以上ならNextボタンをアクティブとしたい
      const isShowNext = allTabWidth - scMoveNum > 1

      setShowTabNavNext(isShowNext)
    },
    [questionAnswerPairsList, numberTabsDisplay]
  )

  useEffect(() => {
    setShowTabNavNext(tabOverflow)
  }, [tabOverflow])

  useEffect(() => {
    if (!tabsRef.current) {
      return
    }
    const tabsWidth = tabsRef.current?.offsetWidth
    const questionNums = questionAnswerPairsList.length
    const overflow = tabsWidth - questionNums * TAB_ITEM_WIDTH < 0

    setTabOverflow(overflow)

    const sliceCount = Math.floor(tabsWidth / TAB_ITEM_WIDTH)
    setNumberTabsDisplay(sliceCount)

    const { current } = tabsRef

    if (!current) {
      return
    }

    current.addEventListener('scroll', onScrollTab)

    return () => {
      current.removeEventListener('scroll', onScrollTab)
    }
  }, [tabsRef, questionAnswerPairsList, onScrollTab])

  return {
    currentTabIndex,
    showTabNavPrev,
    showTabNavNext,
    nextNavAction,
    prevNavAction,
    tabOverflow,
    tabsRef,
    setCurrentTabIndex,
    numberTabsDisplay,
    videoPlaybackRate,
    setVideoPlaybackRate,
  }
}
