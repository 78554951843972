import { genTab } from '@/components/Tabs'
import { TabType, tabs } from '../useTabs'

const mapTabName = (tab: TabType): string => {
  switch (tab) {
    case 'profile':
      return 'プロフィール'
    case 'selection':
      return '選考'
    case 'event':
      return 'イベント'
  }
}

export const Tabs = genTab<TabType>(tabs, mapTabName)
