import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { EditQuestionTemplatePageContainer } from './EditQuestionTemplatePageContainer'
import { InputPage } from './InputPage'

export const EditQuestionTemplatePage: React.VFC = provide(
  EditQuestionTemplatePageContainer
)(() => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('GetRecInterviewQuestionTemplate')
  authorizeByRpcName && authorizeByRpcName('UpdateRecInterviewQuestionTemplate')

  usePromptBeforeUnload()

  const { step } = EditQuestionTemplatePageContainer.useContainer()

  switch (step) {
    case 'input':
      return <InputPage />
    case 'confirm':
      return <ConfirmPage />
  }
})
