import { useState } from 'react'
import { useCallback } from 'react'
import { ChangeEvent } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Modal as M,
  RequireTxt,
  toast,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import {
  Dropdown as D,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { captureException } from '@sentry/react'
import styled from 'styled-components'
import { useRecInterviewTemplateOptions } from '../../useRecInterviewTemplateOptions'
import { useAssignRecInterviewToSelectionStep } from './useAssignRecInterviewToSelectionStep'

type Props = {
  active: boolean
  applicantGuid: string
  selectionGuid: string
  selectionStepGuid: string
  close: () => void
}

export const AssignTemplateModal: React.VFC<Props> = ({
  active,
  applicantGuid,
  selectionGuid,
  selectionStepGuid,
  close,
}) => {
  const [recInterviewTemplateGuid, setRecInterviewTemplateGuid] = useState('')
  const [hasBlurError, setHasBlurError] = useState(false)

  const onChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setHasBlurError(false)
    setRecInterviewTemplateGuid(event.target.value)
  }, [])

  const options = useRecInterviewTemplateOptions()

  const { mutateAsync } = useAssignRecInterviewToSelectionStep(applicantGuid)

  const onClose = useCallback(() => {
    setRecInterviewTemplateGuid('')
    setHasBlurError(false)
    close()
  }, [close])

  const register = useCallback(async () => {
    if (recInterviewTemplateGuid === '') {
      setHasBlurError(true)
      return
    }
    try {
      await mutateAsync({
        selectionGuid,
        selectionStepGuid,
        recInterviewTemplateGuid,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    toast('テンプレート設定を保存しました')
    onClose()
  }, [
    mutateAsync,
    selectionGuid,
    selectionStepGuid,
    recInterviewTemplateGuid,
    onClose,
  ])

  return (
    <Modal
      active={active}
      title="テンプレートの設定"
      onClose={onClose}
      size="l"
    >
      <ModalBody>
        <Title>テンプレート</Title>
        <RequireTxt size="s">必須</RequireTxt>
        <Dropdown
          name="recInterviewTemplate"
          onChange={onChange}
          options={options}
          onBlurError={hasBlurError ? '選択してください' : undefined}
          size="ll"
        />
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_cancel_button_on_assign_template_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={register}
            comlinkPushParams={{
              action: 'click_register_button_on_assign_template_modal',
            }}
          >
            登録
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const Modal = styled(M)`
  /* NOTE: 一番上のDropdownのエラー表示がモーダルヘッダーに隠れないようにしている */
  && > div:last-of-type {
    overflow: visible;
  }
`

const ModalBody = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`

const Title = styled(Txt)`
  width: 140px;
`

const Dropdown = styled(D)`
  margin-left: 8px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
