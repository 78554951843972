import { theme, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

type Props = PropsWithClassName<{
  count: number
  max: number
}>
export const Count: React.VFC<Props> = ({ count, max, className }) => {
  const tooltipID = TOOLTIP_ID_MAP.videoSeminarEventReportFormatTime + uuid()

  return count > max ? (
    <>
      <CountTxt data-tip data-for={tooltipID} className={className}>
        {max.toLocaleString()}+<SmallSpan>回</SmallSpan>
      </CountTxt>
      <Tooltip id={tooltipID} arrowPosition="topLeft">
        <Txt>{count.toLocaleString()}回</Txt>
      </Tooltip>
    </>
  ) : (
    <CountTxt className={className}>
      {count.toLocaleString()}
      <SmallSpan>回</SmallSpan>
    </CountTxt>
  )
}

const CountTxt = styled(Txt)`
  font-size: 18px;
  display: inline-block;
`

const SmallSpan = styled.span`
  font-size: ${theme.fontSize.s};
  color: ${theme.color.gray[1]};
`
