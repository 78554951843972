import { useMemo } from 'react'
import { Name, NameKana } from '@blue-agency/im-shared-front'
import assert from 'assert'
import { useListWebInterviewAssigneeCandidates } from './useListWebInterviewAssigneeCandidates'

export const useAllAssigneeCandidates = () => {
  const { data } = useListWebInterviewAssigneeCandidates()

  const allAssigneeCandidates = useMemo(() => {
    return (
      data?.getAssigneeCandidatesList().map((assigneeCandidate) => {
        const assignee = assigneeCandidate.toObject()
        assert(assignee.name)
        assert(assignee.nameKana)
        return {
          guid: assignee.staffGuid,
          name: new Name(assignee.name.familyName, assignee.name.givenName),
          nameKana: new NameKana(
            assignee.nameKana.familyNameKana,
            assignee.nameKana.givenNameKana
          ),
          email: assignee.email,
          iconColorCode: assignee.iconColorCode,
        }
        // API から値が返却されるまでは空リストを返す（担当者候補がいない時の振る舞いをする）
      }) ?? []
    )
  }, [data])

  return allAssigneeCandidates
}
