import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestDeleteRecInterviewTemplate,
  DeleteRecInterviewTemplateRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteRecInterviewTemplate() {
  const queryClient = useQueryClient()
  const { requestDeleteRecInterviewTemplate } =
    useRequestDeleteRecInterviewTemplate()

  return useMutation(
    (value: DeleteRecInterviewTemplateRequestValue) =>
      requestDeleteRecInterviewTemplate(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.allRecInterviewTemplates)
      },
    }
  )
}
