import React from 'react'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { Completed } from './Completed'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { Mapping } from './Mapping'
import { Submit } from './Submit'
import { useBatchNewApplicantsPage } from './useBatchNewApplicantsPage'

export const BatchNewApplicantsPage: React.VFC = () => {
  const { authorizeByRpcName, permittedRpcs } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('BatchCreateApplicants')

  const ctx = useBatchNewApplicantsPage()

  if (permittedRpcs === undefined) return <Loading />

  switch (ctx.step) {
    case 'input':
      return <Input {...ctx} />
    case 'mapping':
      return <Mapping {...ctx} />
    case 'confirm':
      return <Confirm {...ctx} />
    case 'submit':
      return <Submit {...ctx} />
    case 'completed':
      return <Completed {...ctx} />
  }
}
