import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestCreateVideoSeminarContent,
  CreateVideoSeminarContentRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateVideoSeminarContent() {
  const queryClient = useQueryClient()
  const { requestCreateVideoSeminarContent } =
    useRequestCreateVideoSeminarContent()
  return useMutation(
    (value: CreateVideoSeminarContentRequestValue) =>
      requestCreateVideoSeminarContent(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries([
          QUERY_KEY.videoSeminar,
          req.videoSeminarGuid,
        ])
      },
    }
  )
}
