import { useMemo, useState, useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { useModal } from '@/hooks/useModal'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import type { CSVInputData } from '@/services/csvService'
import { readFile } from '@/services/csvService'
import { Format, Form } from '../types'
import { useApplicantCsvForamt } from './useApplicantCsvFormat'
import { useCreateApplicantCsvFormat } from './useCreateApplicantCsvFormat'
import { useDeleteApplicantCsvFormat } from './useDeleteApplicantCsvFormat'
import { useListApplicantCsvFormats } from './useListApplicantCsvFormats'
import { useUpdateApplicantCsvFormatName } from './useUpdateApplicantCsvFormatName'

export type FormatOption = {
  guid: string
  name: string
}

type Props = {
  initialInputData?: CSVInputData
  goNext: (
    csvInputData: CSVInputData,
    formatData: Format,
    formData: Form
  ) => void
}

const maxRowLength = 3000

export const useInputPage = (props: Props) => {
  const [inputData, setInputData] = useState(props.initialInputData)
  const [formatText, setFormatText] = useState<string>('')
  const [formatGuid, setFormatGuid] = useState<string>('')
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<
    string | undefined
  >(undefined)
  const { format } = useApplicantCsvForamt(formatGuid)

  const onDrop = useCallback(
    async (files: File[]) => {
      if (
        inputData &&
        !window.confirm(
          '既に選択されている取込ファイルが存在します。\n上書きしてよろしいでしょうか？'
        )
      ) {
        return
      }

      setErrorMessageOnTop(undefined)
      setInputData(undefined)
      try {
        const result = await readFile(files)

        if (result.type === 'error') {
          setErrorMessageOnTop(result.message)
          return
        }

        if (result.csvData.csvBody.length > maxRowLength) {
          setErrorMessageOnTop(
            `一度に登録できる応募者数は、${maxRowLength.toLocaleString()}件までとなります。`
          )
          return
        }

        setInputData({
          fileName: result.fileName,
          csvData: result.csvData,
        })
      } catch (e) {
        setErrorMessageOnTop(
          'ファイルの取り込みに失敗しました。ファイルの内容を確認して、再度お試しください。'
        )
        return
      }
    },
    [inputData]
  )

  const { isLoading, data } = useListApplicantCsvFormats()

  const formatOptions = useMemo<FormatOption[] | undefined>(() => {
    if (isLoading || data === undefined) return undefined
    return data
      .getApplicantCsvFormatsList()
      .filter((staff) => staff !== undefined)
      .map(
        (format): FormatOption => ({
          guid: format.getApplicantCsvFormatGuid(),
          name: format.getName(),
        })
      )
  }, [isLoading, data])

  const onFormatChange = useCallback((newValue: string) => {
    setFormatText(newValue)
    setFormatGuid('')
  }, [])

  const onFormatClear = useCallback(() => {
    setFormatText('')
    setFormatGuid('')
  }, [])

  const onFormatSelected = useCallback((format) => {
    setErrorMessageOnTop(undefined)
    setFormatText(format.name)
    setFormatGuid(format.guid)
  }, [])

  const createFormatModal = useModal()
  const [createFormatError, setCreateFormatError] = useState<
    string | undefined
  >(undefined)
  const openCreateFormatModal = useCallback(() => {
    createFormatModal.open()
  }, [createFormatModal])
  const closeCreateFormatModal = useCallback(() => {
    setCreateFormatError(undefined)
    createFormatModal.close()
  }, [createFormatModal])
  const { mutateAsync: requestCreateFormat } = useCreateApplicantCsvFormat()
  const createFormat = useCallback(
    async (name: string) => {
      try {
        await requestCreateFormat({ name: name })
      } catch (e) {
        if (e instanceof CustomBizHuttBffGrpcError) {
          if (e.isFailedPrecondition) {
            setCreateFormatError(
              'フォーマット数が上限（30件）に達しているため、登録できません。'
            )
            return
          }
        }
        captureException(e)
        commonErrorToast()
        return
      }

      toast('フォーマットを登録しました')
      createFormatModal.close()
    },
    [requestCreateFormat, createFormatModal]
  )

  const [targetFormat, setTargetFormat] = useState<FormatOption | undefined>(
    undefined
  )
  const deleteFormatModal = useModal()
  const openDeleteFormatModal = useCallback(
    (format: FormatOption) => {
      setTargetFormat(format)
      deleteFormatModal.open()
    },
    [deleteFormatModal]
  )
  const closeDeleteFormatModal = useCallback(() => {
    setTargetFormat(undefined)
    onFormatClear()
    deleteFormatModal.close()
  }, [deleteFormatModal, onFormatClear])
  const { mutateAsync: requestDeleteFormat } = useDeleteApplicantCsvFormat()
  const deleteFormat = useCallback(async () => {
    if (targetFormat === undefined) throw new Error('targetFormat is undefined')
    try {
      await requestDeleteFormat({ guid: targetFormat.guid })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast('フォーマットを削除しました')
    closeDeleteFormatModal()
  }, [targetFormat, requestDeleteFormat, closeDeleteFormatModal])

  const editFormatNameModal = useModal()
  const openEditFormatNameModal = useCallback(
    (format: FormatOption) => {
      setTargetFormat(format)
      editFormatNameModal.open()
    },
    [editFormatNameModal]
  )
  const closeEditFormatNameModal = useCallback(() => {
    setTargetFormat(undefined)
    editFormatNameModal.close()
  }, [editFormatNameModal])
  const { mutateAsync: requestUpdateFormatName } =
    useUpdateApplicantCsvFormatName()
  const updateFormatName = useCallback(
    async (name: string) => {
      if (targetFormat === undefined)
        throw new Error('targetFormat is undefined')
      try {
        await requestUpdateFormatName({ guid: targetFormat.guid, name: name })
      } catch (e) {
        captureException(e)
        commonErrorToast()
        return
      }

      toast('フォーマット名を保存しました')
      setFormatText(name)
      closeEditFormatNameModal()
    },
    [targetFormat, requestUpdateFormatName, closeEditFormatNameModal]
  )

  const unfitFormatWarning = useMemo(() => {
    if (!inputData || !format) {
      return false
    }
    if (format.mappings.length === 0) {
      return false
    }
    if (inputData.csvData.csvHeader.length !== format.mappings.length) {
      return true
    }
    for (let i = 0; i < format.mappings.length; i++) {
      if (inputData.csvData.csvHeader[i] !== format.mappings[i].src) {
        return true
      }
    }
    return false
  }, [inputData, format])

  const onNextButtonClick = useCallback(() => {
    if (inputData === undefined) throw new Error('inputData is undefined')

    if (!format) {
      if (formatOptions?.length === 0) {
        setErrorMessageOnTop('フォーマットを登録して下さい。')
      } else {
        setErrorMessageOnTop('フォーマットを選択してください。')
      }
      return
    }
    const form = {
      targets: inputData.csvData.csvHeader.map((column) => {
        return format.mappings.find((m) => m.src === column)?.dst || ''
      }),
      selectionSharing: format.selectionSharing || 'SHARING_TYPE_UNSHARED',
    }

    props.goNext(inputData, format, form)
  }, [props, inputData, format, formatOptions])

  return {
    inputData,
    errorMessageOnTop,
    formatOptions,
    formatText,
    formatGuid,
    onDrop,
    onNextButtonClick,
    onFormatChange,
    onFormatClear,
    onFormatSelected,
    openCreateFormatModal,
    closeCreateFormatModal,
    createFormatModalActive: createFormatModal.active,
    createFormatError,
    createFormat,
    targetFormat,
    openDeleteFormatModal,
    closeDeleteFormatModal,
    deleteFormatModalActive: deleteFormatModal.active,
    deleteFormat,
    openEditFormatNameModal,
    closeEditFormatNameModal,
    editFormatNameModalActive: editFormatNameModal.active,
    updateFormatName,
    unfitFormatWarning,
  }
}
