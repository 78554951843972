import { useMemo } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { StaffIcon } from './StaffIcon'

const defaultMaxDisplayCount = 5

type Props = {
  staffs: {
    guid: string
    name: string
    iconColorCode: string
  }[]
  maxDisplayCount?: number
}

export const StaffIcons: React.VFC<Props> = (props) => {
  const staffs = props.staffs
  const maxDisplayCount = props.maxDisplayCount ?? defaultMaxDisplayCount

  const displayedStaffs = useMemo(
    () =>
      staffs.length > maxDisplayCount
        ? staffs.slice(0, maxDisplayCount - 1)
        : staffs,
    [staffs, maxDisplayCount]
  )

  const lastIndex = useMemo(
    () =>
      staffs.length > maxDisplayCount ? maxDisplayCount : staffs.length - 1,
    [staffs, maxDisplayCount]
  )

  return (
    <Container>
      {displayedStaffs.map((staff, index) => (
        <StaffIconWrapper key={staff.guid}>
          <StaffIcon
            name={staff.name}
            color={staff.iconColorCode}
            overlapped={index !== lastIndex}
          />
        </StaffIconWrapper>
      ))}
      {staffs.length > maxDisplayCount && (
        <StaffIconWrapper key="__otherStaffs">
          <StaffIcon
            name={
              maxDisplayCount > 1
                ? `＋ ${staffs.length - maxDisplayCount + 1}`
                : `${staffs.length - maxDisplayCount + 1}`
            }
            color={theme.color.gray[3]}
          />
        </StaffIconWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const StaffIconWrapper = styled.span`
  align-items: center;
  display: flex;

  &:nth-child(n + 2) {
    margin-left: -9px;
  }
`
