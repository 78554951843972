import { useQuery } from 'react-query'
import { useRequestGetVideoSeminar } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetVideoSeminar(videoSeminarGuid: string) {
  const { requestGetVideoSeminar } = useRequestGetVideoSeminar()

  return useQuery([QUERY_KEY.videoSeminar, videoSeminarGuid], () =>
    requestGetVideoSeminar(videoSeminarGuid)
  )
}
