import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CompleteRecInterviewTemplateFileUploadRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCompleteRecInterviewTemplateFileUpload() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()
  const requestCompleteRecInterviewTemplateFileUpload = useCallback(
    async (key: string, uploadId: string, etagList: string[]) => {
      const metadata = await buildMetadata()
      const req = new CompleteRecInterviewTemplateFileUploadRequest()
      req.setKey(key)
      req.setUploadId(uploadId)
      const parts = etagList.map((etag, index) => {
        const part = new CompleteRecInterviewTemplateFileUploadRequest.Part()
        part.setPartNumber(index + 1)
        part.setEtag(etag)
        return part
      })
      req.setPartsList(parts)
      return callBizHuttBffService(
        'completeRecInterviewTemplateFileUpload',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestCompleteRecInterviewTemplateFileUpload }
}
