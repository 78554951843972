import { GetRecInterviewQuestionTemplateResponse } from '@blue-agency/proton/biz_hutt_bff'
import { useQuery } from 'react-query'
import { useRequestGetRecInterviewQuestionTemplate } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { QuestionTemplate } from './types'

const mapFormat = (
  qt: GetRecInterviewQuestionTemplateResponse
): QuestionTemplate['format'] => {
  switch (qt.getFormatCase()) {
    case GetRecInterviewQuestionTemplateResponse.FormatCase.TEXT:
      return 'text'
    case GetRecInterviewQuestionTemplateResponse.FormatCase.VIDEO:
      return 'video'
    case GetRecInterviewQuestionTemplateResponse.FormatCase.FORMAT_NOT_SET:
      throw new Error('Got unknown rec interview question format')
  }
}

export const useGetQuestionTemplate = (questionTemplateGuid: string) => {
  const { requestGetRecInterviewQuestionTemplate } =
    useRequestGetRecInterviewQuestionTemplate()

  return useQuery<QuestionTemplate, unknown, QuestionTemplate>(
    [QUERY_KEY.questionTemplate, questionTemplateGuid],
    async () => {
      const qt = await requestGetRecInterviewQuestionTemplate(
        questionTemplateGuid
      )
      return {
        name: qt.getName(),
        content: qt.getContent(),
        note: qt.getNote(),
        format: mapFormat(qt),
        videoFileKey: qt.getVideoFileKey(),
        videoPresignedUrl: qt.getVideoPresignedUrl(),
        maxDurationSeconds: qt.getVideo()?.getMaxDurationSeconds() ?? 0,
        maxRecCount: qt.getVideo()?.getMaxRecCount() ?? 0,
      }
    }
  )
}
