import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListUpcomingWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from '@/services/bffService/metadata'

export function useRequestListUpcomingWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListUpcomingWebInterviews = useCallback(
    async (today: Timestamp) => {
      const metadata = await buildMetadata()
      const req = new ListUpcomingWebInterviewsRequest()
      req.setToday(today)

      return callBizHuttBffService(
        'listUpcomingWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListUpcomingWebInterviews }
}
