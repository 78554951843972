import { CommonInputPage } from '@/components/WebInterviewGuideForm/CommonInputPage'
import { NewWebInterviewGuidePageContainer } from './newWebInterviewGuidePageContainer'

export function InputPage() {
  const { isCreationLimitExceeded, completedForm, completeInput } =
    NewWebInterviewGuidePageContainer.useContainer()

  return (
    <CommonInputPage
      type="new"
      isCreationLimitExceeded={isCreationLimitExceeded}
      completedForm={completedForm}
      completeInput={completeInput}
    />
  )
}
