import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import {
  ExportWebInterviewsCSVRequest,
  WebInterviewStatus as Status,
} from '@blue-agency/proton/biz_hutt_bff'
import { TimestampRange } from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  freewordToArray,
  SEARCH_FREEWORD_COUNT_MAX,
} from '@/services/webInterviewService'
import type { WebInterviewStatus } from '@/services/webInterviewService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ExportWebInterviewsCsvArgs = {
  condition: {
    freeword: string
    status: { [p in WebInterviewStatus]: boolean }
    interviewerGuid: string | null
    isAssignmentSpecified: boolean
    registerTime: {
      from: Date | null
      to: Date | null
    }
    scheduledStartTime: {
      from: Date | null
      to: Date | null
    }
  }
}

const buildCondition = (
  condition_: ExportWebInterviewsCsvArgs['condition']
) => {
  const condition = new ExportWebInterviewsCSVRequest.Condition()

  const statusList = []
  if (condition_.status.notStarted) statusList.push(Status.NOT_STARTED)
  if (condition_.status.interviewing) statusList.push(Status.INTERVIEWING)
  if (condition_.status.interviewed) statusList.push(Status.INTERVIEWED)
  condition.setStatusesList(statusList)

  condition.setInterviewerUnassigned(condition_.isAssignmentSpecified)

  if (condition_.freeword) {
    condition.setKeywordsList(
      // NOTE: 空白で区切られた、先頭から SEARCH_FREEWORD_COUNT_MAX ワードまでを検索対象とする
      freewordToArray(condition_.freeword).slice(0, SEARCH_FREEWORD_COUNT_MAX)
    )
  }

  if (condition_.interviewerGuid) {
    condition.setStaffGuid(condition_.interviewerGuid)
  }

  const timestampRange = new TimestampRange()
  if (condition_.registerTime.from) {
    const from = new Timestamp()
    from.fromDate(condition_.registerTime.from)
    timestampRange.setFrom(from)
  }
  if (condition_.registerTime.to) {
    const to = new Timestamp()
    to.fromDate(condition_.registerTime.to)
    timestampRange.setTo(to)
  }
  condition.setRegisterTime(timestampRange)

  const scheduledStartTimeRange = new TimestampRange()
  if (condition_.scheduledStartTime.from) {
    const from = new Timestamp()
    from.fromDate(condition_.scheduledStartTime.from)
    scheduledStartTimeRange.setFrom(from)
  }
  if (condition_.scheduledStartTime.to) {
    const to = new Timestamp()
    to.fromDate(condition_.scheduledStartTime.to)
    scheduledStartTimeRange.setTo(to)
  }
  condition.setScheduledStartTime(scheduledStartTimeRange)

  return condition
}

export function useRequestExportWebInterviewsCsv() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestExportWebInterviewsCsv = useCallback(
    async ({ condition: condition_ }) => {
      const metadata = await buildMetadata()

      const req = new ExportWebInterviewsCSVRequest()

      const condition = buildCondition(condition_)
      req.setCondition(condition)

      const data = await callBizHuttBffService(
        'exportWebInterviewsCSV',
        [req, metadata],
        loggedInStaff?.guid
      )

      return data.getCsv_asU8()
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestExportWebInterviewsCsv,
  }
}
