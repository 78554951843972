import { useEffect, useMemo } from 'react'

export function useObjectURL(file: File | undefined) {
  const previewURL = useMemo(() => {
    if (file === undefined) {
      return undefined
    }
    return URL.createObjectURL(file)
  }, [file])

  useEffect(() => {
    return () => {
      if (previewURL !== undefined) {
        URL.revokeObjectURL(previewURL)
      }
    }
  }, [previewURL])

  return previewURL
}
