import {
  ErrorBox,
  Modal,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export type DetailValidationError = {
  rowNumber: number
  message: string
}

type Props = {
  title: string
  errorBoxText: string
  errorLimit: number
  closeComlinkAction: string
  active: boolean
  detailValidationErrors: DetailValidationError[]
  close: () => void
}

export const CSVUploadValidationErrorModal: React.VFC<Props> = ({
  title,
  errorBoxText,
  errorLimit,
  closeComlinkAction,
  active,
  detailValidationErrors,
  close,
}) => {
  return (
    <Modal active={active} title={title} onClose={close} size="l" stopScroll>
      <ModalBody>
        <ErrorBoxWrapper>
          <ErrorBox size="s">{errorBoxText}</ErrorBox>
        </ErrorBoxWrapper>
        <AnnotationTxt>
          ※エラー対象は最大{errorLimit}件の表示となります。
        </AnnotationTxt>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <Th property="target">
                  <Txt size="m">エラー対象</Txt>
                </Th>
                <Th property="content">
                  <Txt size="m">エラー内容</Txt>
                </Th>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailValidationErrors.slice(0, errorLimit).map((error) => (
                <TableRow key={String(error.rowNumber) + '-' + error.message}>
                  <Td property="target">
                    <ErrorTxt>
                      {error.rowNumber + 1}
                      <RowUnit>行</RowUnit>
                    </ErrorTxt>
                  </Td>
                  <Td property="content">
                    <ErrorTxt>{error.message}</ErrorTxt>
                  </Td>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <TertiaryButton
          onClick={close}
          comlinkPushParams={{
            action: closeComlinkAction,
          }}
          widthSize="L1"
        >
          閉じる
        </TertiaryButton>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  max-height: 456px;
  overflow-y: scroll;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

const ErrorBoxWrapper = styled.div`
  width: 100%;
`

const AnnotationTxt = styled(Txt).attrs({
  size: 'm',
})``

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${theme.color.gray[4]};
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  &:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

type Property = 'target' | 'content'

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  target: css`
    flex: 0 0 108px;
  `,
  content: css`
    flex: 1 0 300px;
  `,
}

const Th = styled.th<{ property: Property }>`
  display: flex;
  align-items: start;
  margin-left: 20px;
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<{ property: Property }>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 20px 0;
  ${({ property }) => propertyStyles[property]}
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 28px;
`

const ErrorTxt = styled(Txt).attrs({
  color: theme.color.red[2],
  size: 'm',
})``

const RowUnit = styled.span`
  font-size: ${theme.fontSize.xs};
`
