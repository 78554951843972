import { theme, Txt } from '@blue-agency/rogue'
import assert from 'assert'
import styled from 'styled-components'
import { getAssignmentOption } from '@/services/webInterviewService'
import type { Assignee, Assignment } from '@/services/webInterviewService'
import { SpecifiedAssignees } from './SpecifiedAssignees'
import type { Staff } from './WebInterviewPageContainer'

type Props = {
  assignment: Assignment
  assignees?: (Assignee & Staff)[]
}

export function WebInterviewAssignmentInner(props: Props) {
  const assignment = getAssignmentOption(props.assignment)
  assert(assignment)
  if (assignment.value === 'specified') {
    assert(props.assignees)
    return <SpecifiedAssignees assignees={props.assignees} />
  } else {
    return (
      <>
        <WebInterviewAssignmentTitle>担当設定: </WebInterviewAssignmentTitle>
        <WebInterviewAssignmentOption>
          {getAssignmentOption(props.assignment)?.label}
        </WebInterviewAssignmentOption>
      </>
    )
  }
}

const WebInterviewAssignmentTitle = styled(Txt).attrs({
  size: 'm',
  color: theme.color.gray[1],
})``

const WebInterviewAssignmentOption = styled(Txt).attrs({
  size: 'm',
  color: theme.color.navy[1],
  bold: true,
})``
