import { useCallback, useState } from 'react'
import { assertNever } from '@/assertions'
import { WebInterviewUpdate } from '@/pages/WebInterviewUpdateByFilePage/csv/WebInterviewUpdate'
import type { CSVData, CSVInputData, MappingResult } from './types'

type Step = 'input' | 'mapping' | 'confirm' | 'completed'
export type MappingOutput = {
  mapping: MappingResult
  webInterviewUpdates: WebInterviewUpdate[]
}

type Result =
  | {
      step: 'input'
      initialInputData?: CSVInputData
      goNext: (csvInputData: CSVInputData) => void
    }
  | {
      step: 'mapping'
      csvData: CSVData
      initialMappingResult?: MappingResult
      goPrevious: () => void
      goNext: (input: MappingOutput) => void
    }
  | {
      step: 'confirm'
      csvData: CSVData
      mapping: MappingResult
      webInterviewUpdates: WebInterviewUpdate[]
      goPrevious: () => void
      goNext: () => void
    }
  | {
      step: 'completed'
      completedCount: number
    }

export function useWebInterviewUpdateByFilePage(): Result {
  const [step, setStep] = useState<Step>('input')
  const [csvInputData, setCSVInputData] = useState<CSVInputData>()
  const [mapping, setMapping] = useState<MappingResult>()
  const [webInterviewUpdates, setWebInterviewUpdates] =
    useState<WebInterviewUpdate[]>()

  const goNextOnInput = useCallback(
    (csvInputData: CSVInputData) => {
      setCSVInputData(csvInputData)
      setStep('mapping')
    },
    [setStep, setCSVInputData]
  )

  const goNextOnMapping = useCallback(
    ({ targets, mapping, webInterviewUpdates }) => {
      setMapping(mapping)
      setWebInterviewUpdates(webInterviewUpdates)
      setStep('confirm')
    },
    [setMapping, setWebInterviewUpdates, setStep]
  )

  const goNextOnConfirm = useCallback(() => {
    setStep('completed')
  }, [setStep])

  const goPreviousOnMapping = useCallback(() => {
    setMapping(undefined)
    setStep('input')
  }, [setMapping, setStep])

  const goPreviousOnConfirm = useCallback(() => {
    setWebInterviewUpdates(undefined)
    setStep('mapping')
  }, [setWebInterviewUpdates, setStep])

  if (step === 'input') {
    return {
      step: 'input',
      initialInputData: csvInputData,
      goNext: goNextOnInput,
    }
  }

  if (!csvInputData) throw new Error('csvData is undefined')

  if (step === 'mapping') {
    return {
      step: 'mapping',
      csvData: csvInputData.csvData,
      initialMappingResult: mapping,
      goPrevious: goPreviousOnMapping,
      goNext: goNextOnMapping,
    }
  }

  if (!mapping) throw new Error('mapping is undefined')
  if (!webInterviewUpdates) throw new Error('webInterviewUpdates is undefined')

  if (step === 'confirm') {
    return {
      step: 'confirm',
      csvData: csvInputData.csvData,
      mapping,
      webInterviewUpdates: webInterviewUpdates,
      goPrevious: goPreviousOnConfirm,
      goNext: goNextOnConfirm,
    }
  }

  if (step === 'completed') {
    return {
      step: 'completed',
      completedCount: webInterviewUpdates.length,
    }
  }

  assertNever(step)
}
