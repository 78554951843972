import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import { useParams, useHistory, generatePath } from 'react-router-dom'
import { useObjectURL } from '@/hooks/useObjectURL'
import { setCookie } from '@/services/cookieService'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  VideoSeminarContentForm,
  videoSeminarContentEditFormSchema,
  useMovieUpload,
  useGetVideoSeminar,
  mapProtoToVideoSeminar,
  MovieTranscodeStatus,
} from '@/services/videoSeminarService'
import {
  MovieRequest,
  ThumbnailRequest,
  useUpdateVideoSeminarContent,
} from './useUpdateVideoSeminarContent'

function useVideoSeminarContentEditPage() {
  const { videoSeminarGuid, videoSeminarContentGuid } = useParams<{
    videoSeminarGuid: string
    videoSeminarContentGuid: string
  }>()

  /**
   * 対象のVideoSeminarContent取得
   */
  const { data } = useGetVideoSeminar(videoSeminarGuid)

  const videoSeminar = useMemo(() => {
    if (data === undefined) return undefined
    return mapProtoToVideoSeminar(data, videoSeminarGuid)
  }, [data, videoSeminarGuid])

  const videoSeminarContent = useMemo(() => {
    if (videoSeminar === undefined) return undefined
    return videoSeminar.contents.find((c) => c.guid === videoSeminarContentGuid)
  }, [videoSeminar, videoSeminarContentGuid])

  const uploadedThumbnailUrl = videoSeminarContent?.thumbnailUrl
  const movieTranscodeStatus = videoSeminarContent?.movieTranscodeStatus

  useEffect(() => {
    videoSeminar?.presignedCookies.forEach((c) => {
      setCookie(c)
    })
  }, [videoSeminar])

  /**
   * Step管理
   */
  const [step, setStep] = useState<'input' | 'confirm'>('input')

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback(() => {
    setStep('confirm')
  }, [])

  /**
   * Form管理
   */
  const form = useForm<VideoSeminarContentForm>({
    resolver: yupResolver(videoSeminarContentEditFormSchema),
    mode: 'onBlur',
    defaultValues: {
      title: '',
      movieFile: undefined,
      movieKey: '',
      thumbnailFile: undefined,
      description: '',
    },
  })
  const { setValue, resetField, getValues } = form

  const thumbnailPreviewURL = useObjectURL(form.watch('thumbnailFile'))
  const moviePreviewURL = useObjectURL(form.watch('movieFile'))
  const movieKey = form.watch('movieKey')

  useEffect(() => {
    // BFFから取得したフォームの初期値を設定
    if (!videoSeminarContent) return
    setValue('title', videoSeminarContent.title)
    setValue('description', videoSeminarContent.description)
  }, [videoSeminarContent, setValue])

  /**
   * 動画アップロード管理
   */
  const movieUpload = useMovieUpload(videoSeminarGuid)
  const videoRefForMovieDuration = useRef<HTMLVideoElement>(null)

  type MovieState = MovieTranscodeStatus | 'uploading' | 'uploaded' | undefined
  const movieState = useMemo<MovieState>(() => {
    if (
      movieTranscodeStatus === 'done' ||
      movieTranscodeStatus === 'processing'
    ) {
      return movieTranscodeStatus
    }
    if (movieUpload.multipartUpload.isUploading) {
      return 'uploading'
    }
    if (movieKey.length > 0) {
      return 'uploaded'
    }
    return movieTranscodeStatus
  }, [movieKey, movieUpload, movieTranscodeStatus])

  const deleteMovie = useCallback(() => {
    resetField('movieKey')
    resetField('movieFile')
  }, [resetField])

  /**
   * サムネアップロード管理
   */
  const [isUploadedThumbnailDeleted, setIsUploadedThumbnailDeleted] =
    useState(false)
  const deleteUploadedThumbnail = useCallback(() => {
    setIsUploadedThumbnailDeleted(true)
  }, [])

  type ThumbnailState = 'uploadedThumbnailExists' | 'noThumbnail' | 'selected'
  const thumbnailState = useMemo<ThumbnailState>(() => {
    if (uploadedThumbnailUrl && !isUploadedThumbnailDeleted) {
      return 'uploadedThumbnailExists'
    }
    if (thumbnailPreviewURL === undefined) {
      return 'noThumbnail'
    }
    return 'selected'
  }, [thumbnailPreviewURL, uploadedThumbnailUrl, isUploadedThumbnailDeleted])

  const deleteThumbnail = useCallback(() => {
    resetField('thumbnailFile')
  }, [resetField])

  /**
   * 更新処理
   */
  const { mutateAsync } = useUpdateVideoSeminarContent(videoSeminarContentGuid)

  const history = useHistory()
  const onSubmit = useCallback(async () => {
    if (videoSeminarContent === undefined) return
    const form = getValues()

    let movieReq: MovieRequest = { type: 'noUpdate' }
    if (
      videoSeminarContent.movieTranscodeStatus === 'failed' &&
      form.movieKey
    ) {
      const movieDurationSeconds = videoRefForMovieDuration.current?.duration
      if (movieDurationSeconds === undefined) {
        throw new Error('movieDurationSeconds not found')
      }
      movieReq = {
        type: 'update',
        originalMovieKey: form.movieKey,
        movieDurationSeconds,
      }
    }

    const thumbnailReq: ThumbnailRequest = await (async () => {
      if (form.thumbnailFile !== undefined) {
        const buf = await form.thumbnailFile.arrayBuffer()
        return {
          type: 'update',
          thumbnailImage: new Uint8Array(buf),
        } as const
      }

      if (isUploadedThumbnailDeleted) {
        return { type: 'delete' } as const
      }

      return { type: 'noUpdate' } as const
    })()

    try {
      await mutateAsync({
        videoSeminarContentGuid,
        title: form.title,
        description: form.description,
        movie: movieReq,
        thumbnail: thumbnailReq,
      })
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    history.push(
      generatePath(INTERNAL_PATHS.videoSeminarContents, {
        videoSeminarGuid,
      })
    )
    toast('コンテンツを保存しました')
  }, [
    getValues,
    mutateAsync,
    videoSeminarGuid,
    videoSeminarContentGuid,
    history,
    isUploadedThumbnailDeleted,
    videoSeminarContent,
  ])

  return {
    videoSeminarGuid,
    videoSeminarContent,
    step,
    form,
    thumbnailPreviewURL,
    moviePreviewURL,
    movieUpload,
    isUploadedThumbnailDeleted,
    thumbnailState,
    movieState,
    videoRefForMovieDuration,
    completeInput,
    backToInput,
    deleteUploadedThumbnail,
    deleteThumbnail,
    deleteMovie,
    onSubmit,
  }
}

export const VideoSeminarContentEditPageContainer = createContainer(
  useVideoSeminarContentEditPage
)
