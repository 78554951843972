import { useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Modal,
  toast,
  Txt,
  WeakColorSeparator,
  CheckBox,
} from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import { captureException } from '@sentry/react'
import styled from 'styled-components'
import { useDeleteSelectionStepFromApplicant } from './useDeleteSelectionStepFromApplicant'

type Props = {
  applicantGuid: string
  selectionGuid: string
  selectionStepGuid: string
  selectionStepName: string
  close: () => void
  onDelete: () => void
}

export const DeleteSelectionStepModal: React.VFC<Props> = ({
  applicantGuid,
  selectionGuid,
  selectionStepGuid,
  selectionStepName,
  close,
  onDelete,
}) => {
  const { mutateAsync } = useDeleteSelectionStepFromApplicant(applicantGuid)
  const [isChecked, setCheckBoxIsChecked] = useState(false)
  const toggleCheckBox = useCallback(
    () => setCheckBoxIsChecked((isChecked) => !isChecked),
    []
  )
  const onClick = useCallback(async () => {
    try {
      await mutateAsync({ selectionGuid, selectionStepGuid })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    onDelete()
    toast('選考を削除しました')
    close()
  }, [mutateAsync, selectionGuid, selectionStepGuid, close, onDelete])

  return (
    <Modal active title="選考を削除" onClose={close} size="l">
      <ModalTxt>
        「{selectionStepName}」を削除します。
        <br />
        <Txt color="red">削除したデータを復元することはできません。</Txt>
      </ModalTxt>
      <WeakColorSeparator />
      <ModalFooter>
        <WrapperCheckBox>
          <Txt>削除確認</Txt>
          <WrapperText>
            <Txt size="s" color="red">
              必須
            </Txt>
            <CheckBox
              name="delete"
              checkboxLabel="該当の選考を完全に削除"
              onChange={toggleCheckBox}
            />
          </WrapperText>
        </WrapperCheckBox>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={close}
            comlinkPushParams={{
              action: 'click_cancel_button_on_delete_selection_step_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            onClick={onClick}
            comlinkPushParams={{
              action: 'click_delete_button_on_delete_selection_step_modal',
            }}
            disabled={!isChecked}
          >
            削除
          </DangerButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const WrapperText = styled.div`
  display: flex;
  align-content: center;
  gap: 10px;
`

const WrapperCheckBox = styled.div`
  display: flex;
  gap: 180px;
  padding-bottom: 20px;
`

const ModalTxt = styled(Txt)`
  padding: 20px;
  margin-bottom: 50px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
