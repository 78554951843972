import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { BatchDeleteWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type BatchDeleteWebInterviewsRequestValue = {
  guids: string[]
}

export function useRequestBatchDeleteWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchDeleteWebInterviews = useCallback(
    async (value: BatchDeleteWebInterviewsRequestValue) => {
      const metadata = await buildMetadata()

      const req = new BatchDeleteWebInterviewsRequest()

      req.setWebInterviewGuidsList(value.guids)

      return callBizHuttBffService(
        'batchDeleteWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestBatchDeleteWebInterviews,
  }
}
