import { useEffect, useState } from 'react'
import { WeakColorSeparator, Icon, Txt, theme } from '@blue-agency/rogue'
import { FormRow, InputText, Tooltip } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { Switch, Case } from 'react-if'
import styled from 'styled-components'
import { FileSelectBox } from '@/components/FileSelectBox'
import { MovieUpload } from '@/components/MovieUpload'
import { SettingsSection } from '@/components/SettingsSection'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { HlsVideo } from '../HlsVideo'
import { TranscodeStatus as _TranscodeStatus } from '../TranscodeStatus'
import { VideoSeminarContentEditPageContainer } from '../VideoSeminarContentEditPageContainer'

export const InputBody: React.VFC = () => {
  const {
    videoSeminarContent,
    movieUpload,
    form,
    thumbnailPreviewURL,
    moviePreviewURL,
    thumbnailState,
    movieState,
    deleteUploadedThumbnail,
    deleteThumbnail,
    deleteMovie,
  } = VideoSeminarContentEditPageContainer.useContainer()

  const { register } = form

  useEffect(() => {
    register('movieFile')
    register('movieKey')
    register('thumbnailFile')
  }, [register])

  type MovieUploadError =
    | { success: true }
    | { success: false; filename: string }
  const [movieUploadError, setMovieUploadError] = useState<MovieUploadError>({
    success: true,
  })

  return (
    <SettingsSection title="コンテンツ">
      <FormWrapper>
        <Row title="タイトル" required>
          <InputTitle
            {...form.register('title')}
            placeholder="例）先輩社員の声"
            onBlurError={form.formState.errors['title']?.message}
          />
        </Row>
        <WeakColorSeparator />
        <Row
          required
          title={
            <>
              <Txt>
                動画
                <QuestionIconWrapper
                  data-tip
                  data-for={
                    TOOLTIP_ID_MAP.movieUploadOnVideoSeminarContentRegister
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Icon name="question" size="l" />
                </QuestionIconWrapper>
              </Txt>

              <Tooltip
                id={TOOLTIP_ID_MAP.movieUploadOnVideoSeminarContentRegister}
                arrowPosition="topLeft"
              >
                <Txt bold size="s">
                  動画を削除 / 変更する場合
                </Txt>
                <Txt size="s">
                  コンテンツ登録完了後は、動画のみの削除 / 変更はできません。
                  <br />
                  コンテンツごと削除してください。
                </Txt>
              </Tooltip>
            </>
          }
        >
          <MovieWrapper>
            {videoSeminarContent && movieState !== 'uploaded' && (
              <TranscodeStatus
                status={videoSeminarContent.movieTranscodeStatus}
              />
            )}
            {!movieUploadError.success && (
              <UploadErrorBox>
                <UploadErrorBoxText>
                  <CautionIcon />
                  アップロードに失敗しました。再度アップロードしてください。
                </UploadErrorBoxText>
                <UploadErrorFilename>
                  {movieUploadError.filename}
                </UploadErrorFilename>
              </UploadErrorBox>
            )}
            <Switch>
              <Case condition={movieState === 'uploading'}>
                <MovieUpload
                  progress={movieUpload.multipartUpload.progress}
                  filename={form.watch('movieFile')?.name}
                  onCancel={movieUpload.onCancel}
                />
              </Case>
              <Case condition={movieState === 'uploaded'}>
                <PreviewWrapper>
                  <DeletePreviewIcon onClick={deleteMovie} />
                  <MoviePreview>
                    <source src={moviePreviewURL} type="video/mp4" />
                  </MoviePreview>
                </PreviewWrapper>
              </Case>
              <Case condition={movieState === 'done'}>
                {videoSeminarContent && (
                  <PreviewWrapper>
                    <HlsMoviePreview src={videoSeminarContent.movieUrl} />
                  </PreviewWrapper>
                )}
              </Case>
              <Case condition={movieState === 'failed'}>
                <FileSelectBox
                  kind="video_seminar_movie"
                  onSelect={async (files) => {
                    if (files.length !== 1) {
                      form.setError('movieFile', {
                        type: 'disallowed_to_upload_multiple_files',
                        message:
                          '複数のファイルを一度にアップロードすることはできません。',
                      })
                      return
                    }

                    const file = files[0]
                    form.clearErrors(['movieFile', 'movieKey'])
                    form.setValue('movieFile', file)
                    const isValid = await form.trigger('movieFile')
                    if (!isValid) {
                      form.resetField('movieFile', { keepError: true })
                      return
                    }

                    let key: string
                    try {
                      key = await movieUpload.upload(file)
                    } catch (e) {
                      if (e instanceof Error) {
                        if (e.name === 'AbortError') {
                          // キャンセル押下
                          form.resetField('movieFile')
                          setMovieUploadError({
                            success: true,
                          })
                          return
                        }
                        Sentry.captureException(e)
                        setMovieUploadError({
                          success: false,
                          filename: file.name,
                        })
                      }
                      return
                    }

                    form.setValue('movieKey', key)
                    setMovieUploadError({
                      success: true,
                    })
                  }}
                />
              </Case>
            </Switch>
          </MovieWrapper>
        </Row>
        <WeakColorSeparator />
        <Row title="サムネイル画像">
          <Switch>
            <Case condition={thumbnailState === 'noThumbnail'}>
              <FileSelectBox
                kind="thumbnail_image"
                onSelect={async (files) => {
                  if (files.length !== 1) {
                    form.setError('thumbnailFile', {
                      type: 'disallowed_to_upload_multiple_files',
                      message:
                        '複数のファイルを一度にアップロードすることはできません。',
                    })
                    return
                  }

                  form.setValue('thumbnailFile', files[0])
                  const isValid = await form.trigger('thumbnailFile')
                  if (!isValid) {
                    form.resetField('thumbnailFile', { keepError: true })
                  }
                }}
              />
            </Case>
            <Case condition={thumbnailState === 'selected'}>
              <PreviewWrapper>
                <DeletePreviewIcon onClick={deleteThumbnail} />
                <ThumbnailPreview
                  alt="サムネイル画像"
                  src={thumbnailPreviewURL}
                />
              </PreviewWrapper>
            </Case>
            <Case condition={thumbnailState === 'uploadedThumbnailExists'}>
              <PreviewWrapper>
                <DeletePreviewIcon onClick={deleteUploadedThumbnail} />
                <ThumbnailPreview
                  alt="サムネイル画像"
                  src={videoSeminarContent?.thumbnailUrl}
                />
              </PreviewWrapper>
            </Case>
          </Switch>
        </Row>
        <WeakColorSeparator />
        <Row title="説明">
          <InputDescription
            {...form.register('description')}
            placeholder="例）こちらの動画では、＊＊事業部の営業職（3年目）の先輩社員へのインタビュー内容を紹介しています。"
            onBlurError={form.formState.errors['description']?.message}
          />
        </Row>
      </FormWrapper>
    </SettingsSection>
  )
}

const FormWrapper = styled.div`
  padding: 0 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '148px', subtitleWidth: '0' })`
  padding: 20px 0;
`

const InputTitle = styled(InputText).attrs({ size: 'll' })``

const InputDescription = styled(InputText).attrs({
  size: 'll',
  type: 'textarea',
  height: '86px',
})``

const QuestionIconWrapper = styled.span`
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 3px;
`

const MovieWrapper = styled.div``

const PreviewWrapper = styled.div`
  position: relative;
  width: 280px;
`

const ThumbnailPreview = styled.img`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const MoviePreview = styled.video.attrs({ controls: true })`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const HlsMoviePreview = styled(HlsVideo)`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const DeletePreviewIcon = styled(Icon).attrs({ name: 'close2', size: 'l' })`
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  // アイコンとvideoタグの領域が被っている部分をクリックしたときに、アイコンのクリックが優先されるようにするため、z-indexを指定している
  z-index: 2;
`

const UploadErrorBox = styled.div`
  width: 460px;
  height: 60px;
  background-color: ${theme.color.red[4]};
  border-radius: 4px;
  padding: 8px;
`

const CautionIcon = styled(Icon).attrs({ name: 'caution' })`
  position: relative;
  top: 4px;
  margin: 0 8px;
`

const UploadErrorBoxText = styled(Txt)`
  color: ${theme.color.red[2]};
`

const UploadErrorFilename = styled(Txt).attrs({ textAlign: 'center' })`
  color: ${theme.color.gray[1]};
`

const TranscodeStatus = styled(_TranscodeStatus)`
  margin-bottom: 8px;
`
