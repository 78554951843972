import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateApplicantRequest } from '@blue-agency/proton/biz_hutt_bff'
import {
  Applicant,
  EducationalBackground,
  WorkHistory,
} from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  ApplicantForm as ApplicantType,
  getGenderValue,
} from '@/services/applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateApplicantRequestValue = {
  allowDuplicate: boolean
  applicant: ApplicantType
}

export function useRequestCreateApplicant() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateApplicant = useCallback(
    async (v: CreateApplicantRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateApplicantRequest()

      const applicant = new Applicant()
      applicant.setExternalApplicantId(v.applicant.externalApplicantId ?? '')
      applicant.setFamilyName(v.applicant.familyName)
      applicant.setGivenName(v.applicant.givenName ?? '')
      applicant.setFamilyNameKana(v.applicant.familyNameKana ?? '')
      applicant.setGivenNameKana(v.applicant.givenNameKana ?? '')
      applicant.setEmail(v.applicant.email ?? '')
      applicant.setPostalCode(v.applicant.postalCode ?? '')
      applicant.setAddress(v.applicant.address ?? '')
      applicant.setGender(getGenderValue(v.applicant.gender))
      applicant.setGenderNote(v.applicant.genderNote ?? '')
      applicant.setBirthDate(
        v.applicant.birthDate
          ? Timestamp.fromDate(v.applicant.birthDate)
          : undefined
      )
      applicant.setPhoneNumber(v.applicant.phoneNumber ?? '')
      const educationalBackgroundsList = v.applicant.educationalBackgroundsList
        .filter((v) => v.schoolName !== '')
        .map((v) => {
          const educationalBackground = new EducationalBackground()
          educationalBackground.setSchoolName(v.schoolName ?? '')
          educationalBackground.setFaculty(v.faculty ?? '')
          educationalBackground.setNote(v.note ?? '')
          return educationalBackground
        })
      applicant.setEducationalBackgroundsList(educationalBackgroundsList)
      applicant.setEducationalBackgroundNote(
        v.applicant.educationalBackgroundNote ?? ''
      )
      const workHistoriesList = v.applicant.workHistoriesList
        .filter((v) => v.companyName !== '')
        .map((v) => {
          const workHistory = new WorkHistory()
          workHistory.setCompanyName(v.companyName ?? '')
          workHistory.setJobDescription(v.jobDescription ?? '')
          workHistory.setNote(v.note ?? '')
          return workHistory
        })
      applicant.setWorkHistoriesList(workHistoriesList)
      applicant.setWorkHistoryNote(v.applicant.workHistoryNote ?? '')
      applicant.setNote(v.applicant.note ?? '')

      req.setAllowDuplicate(v.allowDuplicate)
      req.setApplicant(applicant)

      return callBizHuttBffService(
        'createApplicant',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateApplicant,
  }
}
