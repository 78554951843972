import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { useMount } from 'react-use'
import { Loading } from '@/components/Loading'
import { ApplicantForm } from '@/services/applicantService'
import type { CsvHeader } from '@/services/csvService/types'
import { Form, Format } from '../types'
import { buildUpdateFormat } from './builder'
import { useBatchCreateApplicants } from './useBatchCreateApplicants'

type Props = {
  applicants: ApplicantForm[]
  csvHeader: CsvHeader
  formData: Form
  formatData: Format
  goNext: () => void
}

export const Submit: React.VFC<Props> = ({
  applicants,
  csvHeader,
  formData,
  formatData,
  goNext,
}) => {
  const mutation = useBatchCreateApplicants()
  usePromptBeforeUnload()

  useMount(() => {
    mutation.mutate({
      applicants: applicants,
      selectionSharingType: formData.selectionSharing,
      updateFormat: buildUpdateFormat(formData, formatData, csvHeader),
    })
  })

  if (mutation.isLoading) {
    return <Loading />
  }
  if (mutation.isSuccess) {
    goNext()
  }
  if (mutation.isError) {
    throw mutation.error
  }

  return null
}
