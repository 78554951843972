import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { SearchApplicantsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SearchCondition } from '../applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import { toSearchApplicantCondition } from './toSearchApplicantCondition'

export type SearchApplicantsArgs = {
  pageSize: number
  page: number
  orderBy: SearchApplicantsRequest.OrderBy
  condition: SearchCondition
}

export function useRequestSearchApplicants() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestSearchApplicants = useCallback(
    async ({ pageSize, page, orderBy, condition }: SearchApplicantsArgs) => {
      const metadata = await buildMetadata()

      const req = new SearchApplicantsRequest()

      const pagination = new SearchApplicantsRequest.Pagination()
      pagination.setPageSize(pageSize)
      pagination.setPage(page)

      req.setPagination(pagination)

      req.setOrderBy(orderBy)

      req.setCondition(toSearchApplicantCondition(condition))

      return callBizHuttBffService(
        'searchApplicants',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestSearchApplicants }
}
