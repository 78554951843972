import { Fragment, useMemo } from 'react'
import {
  Icon as _Icon,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'
import { calcPartOffsets } from '@/services/webInterviewGuideService'
import type { PartWithOffset } from '@/services/webInterviewGuideService'
import type { Guide } from './types'

type Props = {
  guide: Guide
  openDeleteModal: () => void
  copyGuideGUID: () => void
}

export function Content({ guide, openDeleteModal, copyGuideGUID }: Props) {
  const partsWithOffset = useMemo(() => {
    return calcPartOffsets(guide.parts, guide.firstPartStartSeconds)
  }, [guide])

  return (
    <Wrapper>
      <InfoHeaderWrapper>
        <InfoHeader>ガイド情報</InfoHeader>
      </InfoHeaderWrapper>
      <BodyWrapper>
        <GuideButtonsWrapper>
          <ButtonWrapper>
            <EditButton
              to={generatePath(INTERNAL_PATHS.editWebInterviewGuide, {
                webInterviewGuideGuid: guide.guid,
              })}
            >
              <Icon name="edit" />
              編集
            </EditButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <DeleteButton to="#" onClick={openDeleteModal}>
              <Icon name="delete" />
              削除
            </DeleteButton>
          </ButtonWrapper>
        </GuideButtonsWrapper>
        <GuideID>
          <Left>
            <Txt>面接ガイドID</Txt>
          </Left>
          <Middle />
          <Right>
            <GuideIDGroup>
              <Txt>{guide.guid}</Txt>
              <CopyGuideIDButton
                comlinkPushParams={{
                  action: 'click_copy_guide_guid',
                }}
                onClick={copyGuideGUID}
              >
                コピー
              </CopyGuideIDButton>
            </GuideIDGroup>
          </Right>
        </GuideID>
        <WeakColorSeparator />
        <GuideTitle>
          <Left>
            <Txt>タイトル</Txt>
          </Left>
          <Middle />
          <Right>
            <Txt>{guide.name}</Txt>
          </Right>
        </GuideTitle>
        {partsWithOffset.map((part, index) => {
          return (
            <Fragment key={part.startSeconds}>
              <WeakColorSeparator />
              <Part partWithOffset={part} index={index} />
            </Fragment>
          )
        })}
      </BodyWrapper>
    </Wrapper>
  )
}

type PartProps = {
  partWithOffset: PartWithOffset
  index: number
}

function Part({ partWithOffset, index }: PartProps) {
  // 基本的に60で割り切れるはずだが、念の為切り捨てにしている
  const startMin = Math.floor(partWithOffset.startSeconds / 60)

  return (
    <GuidePartWrapper>
      <Row>
        <Left>
          <Txt>パート{index + 1}</Txt>
        </Left>
        <Middle>
          <MiddleText>開始時間</MiddleText>
        </Middle>
        <Right>
          <Txt>{startMin}分時点</Txt>
        </Right>
      </Row>
      <Row>
        <Left />
        <Middle>
          <MiddleText>見出し</MiddleText>
        </Middle>
        <Right>
          <Txt>{partWithOffset.title}</Txt>
        </Right>
      </Row>
      <Row>
        <Left />
        <Middle>
          <MiddleText>本文</MiddleText>
        </Middle>
        <Right>
          <Txt>{partWithOffset.description}</Txt>
        </Right>
      </Row>
    </GuidePartWrapper>
  )
}

const Row = styled.div`
  display: flex;
`

const Left = styled.div`
  width: 137px;
  padding: 13px 21px 13px 0;
`

const Middle = styled.div`
  width: 137px;
  padding: 13px 21px 13px 0;
`

const Right = styled.div`
  padding: 13px 14px;
  width: calc(100% - 205px);
  overflow-wrap: break-word;
  word-break: break-word;
`

const MiddleText = styled(Txt).attrs({ size: 's', bold: true })`
  line-height: 18px;
`

const EditButton = styled(Link)`
  color: ${theme.color.navy[1]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const DeleteButton = styled(Link)`
  color: ${theme.color.red[2]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 3px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 26px;
`

const Wrapper = styled.div``

const InfoHeaderWrapper = styled.div`
  height: 40px;
  background: ${theme.color.navy[3]};
`

const BodyWrapper = styled.div`
  padding: 0 20px;
`

const InfoHeader = styled(Txt).attrs({ size: 'l' })`
  padding: 8px 20px;
`

const GuideButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`

const GuideTitle = styled.div`
  display: flex;
  padding-bottom: 5px;
`
const GuideID = styled.div`
  display: flex;
  padding-bottom: 5px;
`
const GuidePartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 15px;
`
const GuideIDGroup = styled.div`
  display: flex;
`
const CopyGuideIDButton = styled(TertiaryButton).attrs({
  widthSize: 'M',
  heightSize: 'S',
})`
  padding: 6px;
  margin-left: 20px;
`
