import { iconMap, theme } from '@blue-agency/rogue'
import { format } from 'date-fns'
import { SendMailPurpose, SendMailPurposeType } from '.'
import {
  SelectionStatus,
  SelectionStepResult,
  SelectionSubStatus,
  InProgressSubStatus,
  SharingType,
} from './selectionEnums'
import { SelectionStep } from './selectionTypes'

export const getSelectionStatusText = (status: SelectionStatus): string => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return '新着'
    case SelectionStatus.SELECTION_STATUS_IN_PROGRESS:
      return '選考中'
    case SelectionStatus.SELECTION_STATUS_NOT_PASSED:
      return '不通過'
    case SelectionStatus.SELECTION_STATUS_PENDING:
      return '保留'
    case SelectionStatus.SELECTION_STATUS_FINISHED:
      return '選考終了'
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return '辞退'
    case SelectionStatus.SELECTION_STATUS_UNKNOWN:
      throw new Error('invalid status')
  }
}

export const getNextSelectionStatuses = (
  currentStatus: SelectionStatus,
  steps: SelectionStep[]
) => {
  if (0 < steps.length) return []
  switch (currentStatus) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return [
        SelectionStatus.SELECTION_STATUS_FINISHED,
        SelectionStatus.SELECTION_STATUS_DECLINED,
        SelectionStatus.SELECTION_STATUS_PENDING,
      ]
    case SelectionStatus.SELECTION_STATUS_FINISHED:
      return [
        SelectionStatus.SELECTION_STATUS_ARRIVAL,
        SelectionStatus.SELECTION_STATUS_DECLINED,
        SelectionStatus.SELECTION_STATUS_PENDING,
      ]
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return [
        SelectionStatus.SELECTION_STATUS_ARRIVAL,
        SelectionStatus.SELECTION_STATUS_FINISHED,
        SelectionStatus.SELECTION_STATUS_PENDING,
      ]
    case SelectionStatus.SELECTION_STATUS_PENDING:
      return [
        SelectionStatus.SELECTION_STATUS_ARRIVAL,
        SelectionStatus.SELECTION_STATUS_FINISHED,
        SelectionStatus.SELECTION_STATUS_DECLINED,
      ]
    default:
      return []
  }
}

export const getSelectionStatusTransitionText = (
  status: SelectionStatus
): string => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return '新着に戻す'
    case SelectionStatus.SELECTION_STATUS_FINISHED:
      return '選考終了にする'
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return '辞退にする'
    case SelectionStatus.SELECTION_STATUS_PENDING:
      return '保留にする'
    default:
      return ''
  }
}

export const getSelectionStatusColor = (status: SelectionStatus): string => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return theme.color.orange[1]
    case SelectionStatus.SELECTION_STATUS_IN_PROGRESS:
      return theme.color.green[4]
    case SelectionStatus.SELECTION_STATUS_NOT_PASSED:
    case SelectionStatus.SELECTION_STATUS_PENDING:
    case SelectionStatus.SELECTION_STATUS_FINISHED:
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return theme.color.gray[2]
    case SelectionStatus.SELECTION_STATUS_UNKNOWN:
      throw new Error('invalid status')
  }
}

export const getSelectionSubStatusText = (
  subStatus: SelectionSubStatus
): string => {
  switch (subStatus) {
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP:
      return '選考未追加'
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE:
      return 'テンプレート未設定'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED:
      return '未依頼'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT:
      return '提出待ち'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED:
      return '提出期限切れ'
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT:
      return '結果未確定'
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP:
      return '次回選考未定'
    default:
      return ''
  }
}

export type SelectionStepResultKey = keyof typeof SelectionStepResult

export const selectionStepResultKeys = Object.entries(SelectionStepResult)
  .map(([key]) => key as SelectionStepResultKey)
  .filter((v) => v !== 'SELECTION_STEP_RESULT_UNKNOWN')

export const getSelectionStepResultText = (
  result: SelectionStepResult
): string => {
  switch (result) {
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_ASSESSED:
      return '未評価'
    case SelectionStepResult.SELECTION_STEP_RESULT_PASSED:
      return '通過'
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_PASSED:
      return '不通過'
    case SelectionStepResult.SELECTION_STEP_RESULT_DECLINED:
      return '辞退'
    case SelectionStepResult.SELECTION_STEP_RESULT_PENDING:
      return '保留'
    case SelectionStepResult.SELECTION_STEP_RESULT_FINISHED:
      return '選考終了'
    case SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN:
      throw new Error('invalid result')
  }
}

export const getSelectionStepResultKey = (
  result: SelectionStepResult
): keyof typeof SelectionStepResult => {
  switch (result) {
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_ASSESSED:
      return 'SELECTION_STEP_RESULT_NOT_ASSESSED'
    case SelectionStepResult.SELECTION_STEP_RESULT_PASSED:
      return 'SELECTION_STEP_RESULT_PASSED'
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_PASSED:
      return 'SELECTION_STEP_RESULT_NOT_PASSED'
    case SelectionStepResult.SELECTION_STEP_RESULT_DECLINED:
      return 'SELECTION_STEP_RESULT_DECLINED'
    case SelectionStepResult.SELECTION_STEP_RESULT_PENDING:
      return 'SELECTION_STEP_RESULT_PENDING'
    case SelectionStepResult.SELECTION_STEP_RESULT_FINISHED:
      return 'SELECTION_STEP_RESULT_FINISHED'
    case SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN:
      throw new Error('invalid result')
  }
}

export type StepActionType =
  | 'DELETE' // 削除
  | 'COPY_REQUEST_SUBMISSION_URL' // 回答ページリンクをコピー
  | 'SEND_SUBMISSION_REQUEST_EMAIL' // 提出依頼メールを送信
  | 'ASSIGN_REC_INTERVIEW_TEMPLATE' // テンプレート設定
  | 'REQUEST_SUBMISSION_REC_INTERVIEW' // 提出依頼
  | 'REVOKE_REQUEST_SUBMISSION_REC_INTERVIEW' // 提出依頼取消
  | 'CHANGE_SELECTION_REC_INTERVIEW_DEADLINE' // 提出期限変更
  | 'DETAIL' // 選考ステップの詳細
  | 'COPY_SELECTION_STEP_PAGE_URL' // 評価画面リンクをコピー

export const getCurrentStepActions = (
  status: SelectionStatus,
  subStatus: SelectionSubStatus
): StepActionType[] => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return []
    case SelectionStatus.SELECTION_STATUS_IN_PROGRESS: {
      if (
        subStatus === SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP
      ) {
        throw new Error('invalid in progress subStatus')
      }
      return getCurrentStepActionsByInProgressSubStatus(subStatus)
    }
    case SelectionStatus.SELECTION_STATUS_NOT_PASSED:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionStatus.SELECTION_STATUS_PENDING:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionStatus.SELECTION_STATUS_FINISHED:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionStatus.SELECTION_STATUS_UNKNOWN:
      throw new Error('invalid status')
  }
}

const getCurrentStepActionsByInProgressSubStatus = (
  subStatus: InProgressSubStatus
): StepActionType[] => {
  switch (subStatus) {
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE:
      return [
        'ASSIGN_REC_INTERVIEW_TEMPLATE',
        'COPY_SELECTION_STEP_PAGE_URL',
        'DETAIL',
        'DELETE',
      ]
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED:
      return [
        'REQUEST_SUBMISSION_REC_INTERVIEW',
        'COPY_SELECTION_STEP_PAGE_URL',
        'DETAIL',
        'DELETE',
      ]
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT:
      return [
        'SEND_SUBMISSION_REQUEST_EMAIL',
        'COPY_REQUEST_SUBMISSION_URL',
        'CHANGE_SELECTION_REC_INTERVIEW_DEADLINE',
        'REVOKE_REQUEST_SUBMISSION_REC_INTERVIEW',
        'COPY_SELECTION_STEP_PAGE_URL',
        'DETAIL',
        'DELETE',
      ]
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED:
      return [
        'SEND_SUBMISSION_REQUEST_EMAIL',
        'COPY_REQUEST_SUBMISSION_URL',
        'CHANGE_SELECTION_REC_INTERVIEW_DEADLINE',
        'REVOKE_REQUEST_SUBMISSION_REC_INTERVIEW',
        'COPY_SELECTION_STEP_PAGE_URL',
        'DETAIL',
        'DELETE',
      ]
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP:
      return ['COPY_SELECTION_STEP_PAGE_URL', 'DETAIL', 'DELETE']
    case SelectionSubStatus.SELECTION_SUB_STATUS_UNKNOWN:
      throw new Error('invalid subStatus')
  }
}

export function deadlineFormat(date: Date) {
  return format(date, 'yyyy/MM/dd HH:mm')
}

export const getSendMailPurposeText = (
  purpose: SendMailPurposeType
): string => {
  switch (purpose) {
    case SendMailPurpose.PURPOSE_REQUEST_SUBMISSION:
      return '提出依頼'
    case SendMailPurpose.PURPOSE_REMIND:
      return 'リマインド'
    case SendMailPurpose.PURPOSE_UNKNOWN:
      throw new Error('invalid send mail purpose')
  }
}

export type NextAction =
  | 'NONE' // なし
  | 'REGISTER_INITIAL_SELECTION_STEP' // 最初の選考追加
  | 'ASSIGN_REC_INTERVIEW_TEMPLATE' // 録画面接テンプレート設定
  | 'REQUEST_SUBMISSION_REC_INTERVIEW' // 録画面接提出依頼
  | 'CHANGE_REC_INTERVIEW_DEADLINE' // 提出期限変更
  | 'ASSESS_SELECTION_STEP' // 選考結果確定
  | 'REGISTER_NEXT_SELECTION_STEP' // 次回の選考追加
export const getNextAction = (
  status: SelectionStatus,
  subStatus: SelectionSubStatus
): NextAction => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return 'REGISTER_INITIAL_SELECTION_STEP'
    case SelectionStatus.SELECTION_STATUS_IN_PROGRESS: {
      if (
        subStatus === SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP
      ) {
        throw new Error('invalid in progress subStatus')
      }
      return getNextActionByInProgressSubStatus(subStatus)
    }
    case SelectionStatus.SELECTION_STATUS_NOT_PASSED:
    case SelectionStatus.SELECTION_STATUS_PENDING:
    case SelectionStatus.SELECTION_STATUS_FINISHED:
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return 'NONE'
    case SelectionStatus.SELECTION_STATUS_UNKNOWN:
      throw new Error('invalid status')
  }
}

const getNextActionByInProgressSubStatus = (
  subStatus: InProgressSubStatus
): Exclude<NextAction, 'REGISTER_INITIAL_SELECTION_STEP'> => {
  switch (subStatus) {
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE:
      return 'ASSIGN_REC_INTERVIEW_TEMPLATE'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED:
      return 'REQUEST_SUBMISSION_REC_INTERVIEW'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT:
      return 'NONE'
    case SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED:
      return 'CHANGE_REC_INTERVIEW_DEADLINE'
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT:
      return 'ASSESS_SELECTION_STEP'
    case SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP:
      return 'REGISTER_NEXT_SELECTION_STEP'
    case SelectionSubStatus.SELECTION_SUB_STATUS_UNKNOWN:
      throw new Error('invalid subStatus')
  }
}

/**
 * 与えられたステータスとサブステータスが親子関係にあるならtrueを返す
 */
export const isCorrespondingStatus = (
  status: SelectionStatus,
  subStatus: SelectionSubStatus
): boolean => {
  switch (status) {
    case SelectionStatus.SELECTION_STATUS_ARRIVAL:
      return (
        subStatus === SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP
      )
    case SelectionStatus.SELECTION_STATUS_IN_PROGRESS:
      return (
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP ||
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE ||
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT ||
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED ||
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED ||
        subStatus ===
          SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT
      )
    case SelectionStatus.SELECTION_STATUS_NOT_PASSED:
      return false
    case SelectionStatus.SELECTION_STATUS_PENDING:
      return false
    case SelectionStatus.SELECTION_STATUS_FINISHED:
      return false
    case SelectionStatus.SELECTION_STATUS_DECLINED:
      return false
    case SelectionStatus.SELECTION_STATUS_UNKNOWN:
      throw new Error('invalid status')
  }
}

/**
 * 選考結果のテーマカラーとアイコンを取得する
 */
export const mapResultMeta = (
  result: SelectionStepResult
): { color: string; iconName: keyof typeof iconMap } => {
  switch (result) {
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_ASSESSED:
      return {
        color: theme.color.navy[1],
        iconName: 'selection-not-evaluated',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_PASSED:
      return {
        color: theme.color.green[4],
        iconName: 'selection-passed',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_NOT_PASSED:
      return {
        color: theme.color.navy[1],
        iconName: 'selection-not-passed',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_DECLINED:
      return {
        color: theme.color.navy[1],
        iconName: 'selection-declined',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_PENDING:
      return {
        color: theme.color.navy[1],
        iconName: 'selection-pending',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_FINISHED:
      return {
        color: theme.color.navy[1],
        iconName: 'selection-finished',
      }
    case SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN:
      throw new Error('invalid result')
  }
}

/**
 * 「終了済」の判定になるselectionStatusかどうか判定する
 * @param selectionStatus
 * @returns
 */
export const isSelectionFinished = (selectionStatus: SelectionStatus) =>
  selectionStatus === SelectionStatus.SELECTION_STATUS_DECLINED ||
  selectionStatus === SelectionStatus.SELECTION_STATUS_FINISHED ||
  selectionStatus === SelectionStatus.SELECTION_STATUS_NOT_PASSED

export const isSharing = (sharingType?: SharingType): boolean => {
  return sharingType === SharingType.SHARING_TYPE_ALL
}

/**
 * 「新着」「選考中」以外のステータスで非選考中かどうかを判定する
 * @param selectionStatus
 * @returns
 */
export const isNotInProgress = (selectionStatus: SelectionStatus) =>
  [
    SelectionStatus.SELECTION_STATUS_NOT_PASSED,
    SelectionStatus.SELECTION_STATUS_PENDING,
    SelectionStatus.SELECTION_STATUS_FINISHED,
    SelectionStatus.SELECTION_STATUS_DECLINED,
  ].includes(selectionStatus)
