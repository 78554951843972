import { addDays, set } from 'date-fns'
import { RecInterviewTemplateDeadline } from '@/services/recInterviewTemplateService'

export const calculateDeadline = (args: {
  baseDate: Date
  templateDeadline: RecInterviewTemplateDeadline
}): Date => {
  switch (args.templateDeadline.case) {
    case 'dueDuration': {
      const days = args.templateDeadline.dueDuration?.days
      const hour = args.templateDeadline.dueDuration?.hour
      if (!days) throw new Error('days is undefined')
      if (!hour) throw new Error('hour is undefined')
      const deadline = set(addDays(args.baseDate, days), {
        hours: hour,
        minutes: 0,
        seconds: 0,
      })
      return deadline
    }
    case 'dueTime': {
      if (!args.templateDeadline.dueTime)
        throw new Error('dueTime is undefined')
      return args.templateDeadline.dueTime
    }
  }
}
