import { ApplicantCsvMappingDestination } from '@/services/applicantService'
import type { CsvHeader } from '@/services/csvService/types'
import { Form, Format, Target } from '../types'

export function buildUpdateFormat(
  formData: Form,
  formatData: Format,
  csvHeader: CsvHeader
) {
  if (!diff(formData, formatData, csvHeader)) {
    return undefined
  }

  return {
    guid: formatData.guid,
    mappings: formData.targets.map((m, i) => {
      return {
        src: csvHeader[i],
        dst: mapApplicantCsvMappingDestination(m),
        columnNumber: i + 1,
      }
    }),
  }
}

function diff(formData: Form, formatData: Format, csvHeader: CsvHeader) {
  if (formData.selectionSharing !== formatData.selectionSharing) {
    return true
  }
  if (formData.targets.length !== formatData.mappings.length) {
    return true
  }
  for (let i = 0; i < formData.targets.length; i++) {
    if (
      formData.targets[i] !== formatData.mappings[i].dst ||
      csvHeader[i] !== formatData.mappings[i].src
    ) {
      return true
    }
  }
  return false
}

const mapApplicantCsvMappingDestination = (
  dst: Target
): ApplicantCsvMappingDestination => {
  switch (dst) {
    case 'familyName':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_FAMILY_NAME
    case 'givenName':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_GIVEN_NAME
    case 'familyNameKana':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_FAMILY_NAME_KANA
    case 'givenNameKana':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_GIVEN_NAME_KANA
    case 'email':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_EMAIL
    case 'phoneNumber':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_PHONE_NUMBER
    case 'externalApplicantId':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_EXTERNAL_APPLICANT_ID
    case 'note':
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_NOTE
    default:
      return ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_NOTHING
  }
}
