import { theme, LineClampedTxt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  show: boolean
  videoRef: React.RefObject<HTMLVideoElement>
  content: string
}>
export const AnswerVideoPreview: React.VFC<Props> = (props) => {
  return (
    <Wrapper className={props.className} show={props.show}>
      <Video ref={props.videoRef} muted />
      <TxtWrapper>
        <LineClampedTxt line={3} size="xs">
          {props.content}
        </LineClampedTxt>
      </TxtWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ show: boolean }>`
  display: flex;
  width: 100%;
  height: 55px;
  background-color: ${theme.color.gray[5]};
  ${({ show }) =>
    !show &&
    css`
      visibility: hidden;
    `}
`

const Video = styled.video`
  height: 100%;
`

const TxtWrapper = styled.div`
  padding: 4px 8px;
`
