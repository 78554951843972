import React from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { ErrorBox, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import * as C from '../modalComponents'
import { useCreateModal } from './useCreateModal'

type Props = {
  active: boolean
  onClose: () => void
}

export const CreateModal: React.VFC<Props> = (props) => {
  const ctx = useCreateModal({ onSuccessCreate: props.onClose })

  const onClose = useCallback(() => {
    ctx.formCtx.clearErrors()
    ctx.formCtx.reset()
    ctx.resetSubmitError()
    props.onClose()
  }, [props, ctx])

  const setFocus = ctx.formCtx.setFocus
  useEffect(() => {
    if (props.active) {
      setFocus('name')
    }
  }, [props.active, setFocus])

  return (
    <C.Modal
      active={props.active}
      title="選考ステップの登録"
      size="l"
      onClose={onClose}
    >
      <C.Main>
        {ctx.submitError && <SubmitError>{ctx.submitError}</SubmitError>}
        <C.Row>
          <C.Title>選考ステップ名</C.Title>
          <C.Required />
          <C.InputStepName
            {...ctx.formCtx.register('name')}
            onBlurError={ctx.formCtx.formState.errors.name?.message}
          />
        </C.Row>
        <C.Separator />
        <C.Row>
          <C.Title>選考種別</C.Title>
          <C.Required />
          <C.InputStepType
            {...ctx.formCtx.register('type')}
            onBlurError={ctx.formCtx.formState.errors.type?.message}
            value={ctx.formCtx.watch('type')}
            options={C.dropdownOptions}
          />
        </C.Row>
      </C.Main>
      <C.Bottom>
        <C.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_create_selection_step_master_modal',
          }}
        >
          キャンセル
        </C.CancelButton>
        <C.SubmitButton
          onClick={ctx.register}
          disabled={ctx.formCtx.formState.isSubmitting}
          comlinkPushParams={{
            action: 'click_submit_button_on_create_selection_step_master_modal',
          }}
        >
          登録
        </C.SubmitButton>
      </C.Bottom>
    </C.Modal>
  )
}

const SubmitError = styled(ErrorBox)`
  font-size: ${theme.fontSize.m};
  margin-top: 20px;
`
