import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetApplicantCsvFormatRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type GetApplicantCsvFormatRequestValue = {
  guid?: string
}

export function useRequestGetApplicantCsvFormat() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetApplicantCsvFormat = useCallback(
    async (value: GetApplicantCsvFormatRequestValue) => {
      if (!value.guid) {
        return
      }

      const metadata = await buildMetadata()

      const req = new GetApplicantCsvFormatRequest()

      req.setApplicantCsvFormatGuid(value.guid)

      return callBizHuttBffService(
        'getApplicantCsvFormat',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestGetApplicantCsvFormat,
  }
}
