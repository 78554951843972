import { useCallback, useEffect, useRef, useState } from 'react'

export const useWebInterviewNameEdit = () => {
  const [webInterviewNameEditing, setWebInterviewNameEditing] = useState(false)
  const startEditingWebInterviewName = useCallback(
    () => setWebInterviewNameEditing(true),
    []
  )
  const finishEditingWebInterviewName = useCallback(
    () => setWebInterviewNameEditing(false),
    []
  )
  const webInterviewNameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    webInterviewNameEditing && webInterviewNameRef.current?.focus()
  }, [webInterviewNameEditing])

  return {
    webInterviewNameEditing,
    startEditingWebInterviewName,
    finishEditingWebInterviewName,
    webInterviewNameRef,
  }
}
