import { useCallback, useMemo, useReducer } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useObjectURL } from '@/hooks/useObjectURL'
import {
  VideoSeminarContentForm,
  videoSeminarContentFormSchema,
  useMovieUpload,
} from '@/services/videoSeminarService'

type InputStep = {
  phase: 'input'
}

type ConfirmStep = {
  phase: 'confirm'
  completedForm: VideoSeminarContentForm
}

type Step = InputStep | ConfirmStep

type Action =
  | { type: 'backToInput' }
  | { type: 'goToConfirm'; completedForm: VideoSeminarContentForm }

function reducer(step: Step, action: Action): Step {
  switch (action.type) {
    case 'backToInput':
      return { phase: 'input' }
    case 'goToConfirm':
      return {
        phase: 'confirm',
        completedForm: action.completedForm,
      }
  }
}

function useVideoSeminarContentNewPage() {
  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()

  const [step, dispatch] = useReducer(reducer, { phase: 'input' })

  const form = useForm<VideoSeminarContentForm>({
    resolver: yupResolver(videoSeminarContentFormSchema),
    mode: 'onBlur',
    defaultValues: {
      title: '',
      movieFile: undefined,
      movieKey: '',
      thumbnailFile: undefined,
      description: '',
    },
  })

  const errorsOnTop = useMemo(() => {
    const { errors } = form.formState
    const res = []

    // movieFile で発生するエラーはファイル形式、ファイルサイズなど、アップロード前のバリデーションエラー
    // 一方、movieKeyで発生するエラーは、アップロード中が何らかの理由で失敗したことを表す
    // 発生タイミングが異なるため別のエラーとなっているが、ユーザーに見えるエラー内容
    // としては同じようなものになるので、どちらか一方のみを表示するようにする
    if (errors.movieFile !== undefined) {
      res.push({
        message: errors.movieFile.message,
        field: 'movieFile',
      })
    } else if (errors.movieKey !== undefined) {
      res.push({
        message: errors.movieKey.message,
        field: 'movieKey',
      })
    }

    if (errors.thumbnailFile !== undefined) {
      res.push({
        message: errors.thumbnailFile.message,
        field: 'thumbnailFile',
      })
    }

    return res
  }, [form.formState])

  const thumbnailPreviewURL = useObjectURL(form.watch('thumbnailFile'))

  const moviePreviewURL = useObjectURL(form.watch('movieFile'))

  const backToInput = useCallback(() => {
    dispatch({ type: 'backToInput' })
  }, [])

  const completeInput = useCallback(() => {
    dispatch({ type: 'goToConfirm', completedForm: form.getValues() })
  }, [form])

  const movieUpload = useMovieUpload(videoSeminarGuid)

  return {
    videoSeminarGuid,
    step,
    completeInput,
    backToInput,
    form,
    errorsOnTop,
    thumbnailPreviewURL,
    moviePreviewURL,
    movieUpload,
  }
}

export const VideoSeminarContentNewPageContainer = createContainer(
  useVideoSeminarContentNewPage
)
