import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateApplicantRequestValue,
  useRequestUpdateApplicant,
} from '@/services/bffService/useRequestUpdateApplicant'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateApplicant() {
  const queryClient = useQueryClient()
  const { requestUpdateApplicant } = useRequestUpdateApplicant()

  return useMutation(
    (value: UpdateApplicantRequestValue) => requestUpdateApplicant(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.guid])
      },
    }
  )
}
