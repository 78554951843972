import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateVideoSeminarContent,
  UpdateVideoSeminarContentRequestValue,
  UpdateVideoSeminarContentRequestValueMovie,
  UpdateVideoSeminarContentRequestValueThumbnail,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export type MovieRequest = UpdateVideoSeminarContentRequestValueMovie
export type ThumbnailRequest = UpdateVideoSeminarContentRequestValueThumbnail

export function useUpdateVideoSeminarContent(videoSeminarContentGuid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateVideoSeminarContent } =
    useRequestUpdateVideoSeminarContent()
  return useMutation(
    (value: UpdateVideoSeminarContentRequestValue) =>
      requestUpdateVideoSeminarContent(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERY_KEY.videoSeminar,
          videoSeminarContentGuid,
        ])
      },
    }
  )
}
