import { useEffect, useState } from 'react'
import { WebInterview } from '@/pages/WebInterviewPage/WebInterviewPageContainer'

export function useCalendarDates(
  scheduledStartTime?: WebInterview['scheduledStartTime'],
  durationMinutes?: WebInterview['durationMinutes']
) {
  const [startTime, setStartTime] = useState<Date>(new Date())
  const [endTime, setEndTime] = useState<Date>(new Date())

  useEffect(() => {
    const nowDateObj = new Date()
    nowDateObj.setHours(nowDateObj.getHours(), 1)

    // MEMO: 予定日時がなければ現在の時刻から見て次の00分とする
    if (!scheduledStartTime) {
      nowDateObj.setHours(nowDateObj.getHours() + 1)
      nowDateObj.setMinutes(0)
    }

    // MEMO: 予定日時をフォーマットする
    const startTime = scheduledStartTime ? scheduledStartTime : nowDateObj

    const scheduledEndTimeDateObj = new Date(scheduledStartTime ?? '')
    const endDateObj = nowDateObj

    if (scheduledStartTime) {
      // MEMO: 予定時刻が設定されている場合
      scheduledEndTimeDateObj.setMinutes(
        // MEMO: 目安時間を加算する
        scheduledEndTimeDateObj.getMinutes() +
          (!!durationMinutes ? durationMinutes : 60) // MEMO: 目安時間がなければ、60分とする
      )
    } else {
      // MEMO: 予定時刻がない場合
      endDateObj.setMinutes(
        // MEMO: 目安時間を加算する
        endDateObj.getMinutes() + (!!durationMinutes ? durationMinutes : 60) // MEMO: 目安時間がなければ、60分とする
      )
    }

    // MEMO: 終了日時をフォーマットする
    const endTime = scheduledStartTime ? scheduledEndTimeDateObj : endDateObj

    // MEMO: dates=に入れる文字列
    setStartTime(startTime)
    setEndTime(endTime)
  }, [scheduledStartTime, durationMinutes])

  return {
    startTime,
    endTime,
  }
}
