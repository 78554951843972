import { LoggedInStaff, useLoggedInStaff } from '@blue-agency/im-shared-front'
import { useResponsive } from '@blue-agency/rogue'
import { Loading } from '@/components/Loading'
import {
  Applicant,
  Selection,
  SelectionStep,
} from '@/services/applicantService'
import { PcRoot } from './PcRoot'
import { SpRoot } from './SpRoot'
import { useSelectionStep } from './useSelectionStep'

export const SelectionStepPage: React.VFC = () => {
  const ctx = useSelectionStep()

  const loggedInStaff = useLoggedInStaff()

  if (
    !ctx.applicantGuid ||
    !ctx.applicant ||
    !ctx.registerTime ||
    !ctx.selection ||
    !ctx.targetSelectionStep ||
    !loggedInStaff ||
    ctx.organizationRecInterviewTranscriptionSetting === undefined
  ) {
    return <Loading />
  }

  return (
    <SelectionStepPageInner
      applicantGuid={ctx.applicantGuid}
      applicant={ctx.applicant}
      registerTime={ctx.registerTime}
      selection={ctx.selection}
      targetSelectionStep={ctx.targetSelectionStep}
      loggedInStaff={loggedInStaff}
      organizationRecInterviewTranscriptionSetting={
        ctx.organizationRecInterviewTranscriptionSetting
      }
    />
  )
}

export type SelectionStepPageProps = {
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  selection: Selection
  targetSelectionStep: SelectionStep
  loggedInStaff: LoggedInStaff
  organizationRecInterviewTranscriptionSetting: boolean
}
const SelectionStepPageInner: React.VFC<SelectionStepPageProps> = (props) => {
  const { responsive } = useResponsive()
  return responsive.pc ? <PcRoot {...props} /> : <SpRoot {...props} />
}
