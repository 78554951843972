import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetAddressByPostalCodeRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetAddressByPostalCode() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetAddressByPostalCode = useCallback(
    async (postalCode: string) => {
      const metadata = await buildMetadata()

      const req = new GetAddressByPostalCodeRequest()

      req.setPostalCode(postalCode)

      return callBizHuttBffService(
        'getAddressByPostalCode',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetAddressByPostalCode }
}
