import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  ErrorBox,
  Icon,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  FormRow as _FormRow,
} from '@blue-agency/rogue/im'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import type { CSVInputData } from '@/services/csvService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { LinkToHelp } from '../LinkToHelp'
import { Format, Form } from '../types'
import { CreateFormatModal } from './CreateFormatModal'
import { DeleteFormatModal } from './DeleteFormatModal'
import { EditFormatNameModal } from './EditFormatNameModal'
import { FormatSelect } from './FormatSelect'
import { useInputPage } from './useInputPage'

type Props = {
  initialInputData?: CSVInputData
  goNext: (
    csvInputData: CSVInputData,
    formatData: Format,
    formData: Form
  ) => void
}

export const Input: React.VFC<Props> = ({ initialInputData, goNext }) => {
  const {
    inputData,
    onDrop,
    formatOptions,
    formatText,
    errorMessageOnTop,
    onNextButtonClick,
    onFormatChange,
    onFormatClear,
    onFormatSelected,
    openCreateFormatModal,
    closeCreateFormatModal,
    createFormatModalActive,
    createFormatError,
    createFormat,
    targetFormat,
    openDeleteFormatModal,
    closeDeleteFormatModal,
    deleteFormatModalActive,
    deleteFormat,
    openEditFormatNameModal,
    closeEditFormatNameModal,
    editFormatNameModalActive,
    updateFormatName,
    unfitFormatWarning,
  } = useInputPage({ initialInputData, goNext })

  usePromptBeforeUnload()

  const dropzone = useDropzone({ multiple: true, onDrop })
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>応募者一括登録</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToHelp />
            </SubTextWrapper>
          </Left>
        </Page.Header>
        <Page.Body>
          {errorMessageOnTop && (
            <ErrorBoxWrapper>
              <ErrorBox>{errorMessageOnTop}</ErrorBox>
            </ErrorBoxWrapper>
          )}
          {unfitFormatWarning && (
            <WarningBoxWrapper>
              <WarningIcon />
              <WarningBox>
                選択したファイルとフォーマットのヘッダー情報が合致していません。
                <br />
                選択した内容に誤りがないかご確認のうえ、「次に進む」を押下してください。
              </WarningBox>
            </WarningBoxWrapper>
          )}
          <SettingsSection title="取込設定">
            <FormRow title="ファイル選択" required>
              <FileSelectAreaWrapper>
                {inputData && (
                  <FileNameWrapper>
                    <Txt>{inputData.fileName}</Txt>
                  </FileNameWrapper>
                )}
                {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                <FileSelectArea {...dropzone.getRootProps()}>
                  {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                  <input {...dropzone.getInputProps()} />
                  <FileSelectTxtWrapper>
                    <Icon name="add-file" width="24" />
                    <Txt>ファイルをドラッグ＆ドロップ</Txt>
                  </FileSelectTxtWrapper>
                  <TertiaryButton
                    widthSize="L1"
                    comlinkPushParams={{
                      action:
                        'click_upload_csv_button_on_batch_new_applicants_input',
                    }}
                  >
                    ファイル選択
                  </TertiaryButton>
                </FileSelectArea>
              </FileSelectAreaWrapper>
            </FormRow>
            <SeparatorWrapper>
              <WeakColorSeparator />
            </SeparatorWrapper>
            <FormRow title="フォーマット" required>
              {formatOptions && formatOptions.length > 0 && (
                <FormatSelectAreaWrapper>
                  <FormatSelectArea>
                    <FormatSelect
                      formats={formatOptions}
                      name="name"
                      value={formatText}
                      onChange={onFormatChange}
                      onClear={onFormatClear}
                      onSelected={onFormatSelected}
                      onEdit={openEditFormatNameModal}
                      onDelete={openDeleteFormatModal}
                    />
                  </FormatSelectArea>
                  <FormatCreateButton onClick={openCreateFormatModal}>
                    <FormatCreateTxt>フォーマット追加</FormatCreateTxt>
                  </FormatCreateButton>
                </FormatSelectAreaWrapper>
              )}
              {formatOptions && formatOptions.length === 0 && (
                <FormatEmptyWrapper>
                  <FormatEmptyTxt>フォーマットがありません。</FormatEmptyTxt>
                  <SecondaryButton
                    widthSize="L2"
                    comlinkPushParams={{
                      action:
                        'click_create_format_button_on_batch_new_applicants_input',
                    }}
                    onClick={openCreateFormatModal}
                  >
                    フォーマット登録
                  </SecondaryButton>
                </FormatEmptyWrapper>
              )}
            </FormRow>
          </SettingsSection>
          {createFormatModalActive && (
            <CreateFormatModal
              onSubmit={createFormat}
              onClose={closeCreateFormatModal}
              errorMessage={createFormatError}
            />
          )}
          {deleteFormatModalActive && targetFormat && (
            <DeleteFormatModal
              name={targetFormat.name}
              onSubmit={deleteFormat}
              onClose={closeDeleteFormatModal}
            />
          )}
          {editFormatNameModalActive && targetFormat && (
            <EditFormatNameModal
              name={targetFormat.name}
              onSubmit={updateFormatName}
              onClose={closeEditFormatNameModal}
            />
          )}
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.applicants}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_previous_button_on_batch_new_applicants_input',
                }}
              >
                一覧に戻る
              </TertiaryButton>
            </NoStyledLink>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_next_button_on_batch_new_applicants_input',
              }}
              disabled={inputData === undefined}
              onClick={onNextButtonClick}
            >
              次に進む
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const ErrorBoxWrapper = styled.div`
  margin: 14px 0 20px;
`

const WarningBoxWrapper = styled.div`
  display: inline-flex;
  background-color: ${theme.color.orange[2]};
  padding: 10px;
  margin: 20px 0;
`

const WarningIcon = styled(Icon).attrs({ name: 'exclamation' })`
  padding-left: 8px;
  margin-top: 2px;
  color: ${theme.color.orange[1]};
`

const WarningBox = styled(Txt)`
  padding-left: 8px;
  color: ${theme.color.orange[1]};
`

const FormRow = styled(_FormRow).attrs({})`
  padding: 20px;
`

const FileSelectAreaWrapper = styled.div``

const FileNameWrapper = styled.div`
  margin: 6px 0 16px;
`

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;
  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const FileSelectTxtWrapper = styled.div`
  display: flex;
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const SeparatorWrapper = styled.div`
  margin: 0 20px;
`

const FormatSelectAreaWrapper = styled.div`
  display: flex;
`

const FormatSelectArea = styled.div`
  width: 460px;
`

const FormatCreateButton = styled.div.attrs({ role: 'button' })`
  margin-left: 24px;
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const FormatCreateTxt = styled(Txt).attrs({ size: 's' })`
  text-decoration: underline;
`

const FormatEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FormatEmptyTxt = styled(Txt).attrs({
  size: 'm',
})`
  margin: 6px 0 12px;
`
