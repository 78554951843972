import { SelectionStep as SelectionStepType } from '@/services/applicantService'
import { SelectionStep } from './SelectionStep'

type Props = {
  targetSelectionStep: SelectionStepType
  organizationRecInterviewTranscriptionSetting: boolean
}
export const Selection: React.VFC<Props> = (props) => {
  return (
    <SelectionStep
      targetSelectionStep={props.targetSelectionStep}
      organizationRecInterviewTranscriptionSetting={
        props.organizationRecInterviewTranscriptionSetting
      }
    />
  )
}
