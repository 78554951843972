import { NewTabLink } from '@blue-agency/rogue'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { useCalendarDates } from '@/hooks/useCalendarDates'
import { WebInterview } from '@/pages/WebInterviewPage/WebInterviewPageContainer'

type Props = {
  name: WebInterview['name']
  interviewerUrl: WebInterview['intervieweeUrl']
  intervieweeUrl: WebInterview['intervieweeUrl']
  scheduledStartTime?: WebInterview['scheduledStartTime']
  durationMinutes?: WebInterview['durationMinutes']
  assigneesEmails?: string
}

export const GoogleCalendarLink: React.VFC<Props> = ({
  name,
  interviewerUrl,
  intervieweeUrl,
  scheduledStartTime,
  durationMinutes,
  assigneesEmails,
}) => {
  const dateFormat = (dateObj: Date) => {
    return `${format(dateObj, 'yyyyMMdd', { locale: ja })}T${format(
      dateObj,
      'HHmm',
      { locale: ja }
    )}00`
  }

  const { startTime, endTime } = useCalendarDates(
    scheduledStartTime,
    durationMinutes
  )

  return (
    <NewTabLink
      href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${name}&details=面接官：${interviewerUrl}<br />応募者：${intervieweeUrl}<br />録画：${
        window.location.href
      }&add=${assigneesEmails}&dates=${dateFormat(startTime)}/${dateFormat(
        endTime
      )}`}
      action="click_subscribe_to_google_calendar"
    >
      Googleカレンダーに登録
    </NewTabLink>
  )
}
