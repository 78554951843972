import { Icon, LineClampedTxt, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { VideoSeminarContent } from '@/services/videoSeminarService'
import { StatusOverlay } from './StatusOverlay'

export type ContentForList = Pick<
  VideoSeminarContent,
  'guid' | 'title' | 'description' | 'thumbnailUrl' | 'movieTranscodeStatus'
>
type Props = {
  content: ContentForList
  onClickEdit: (videoSeminarContentGuid: string) => void
  onClickDelete: (content: ContentForList) => void
}
export const Content: React.VFC<Props> = ({
  content,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <Wrapper
      $lowHeight={
        content.thumbnailUrl === '' && content.movieTranscodeStatus === 'done'
      }
    >
      {content.thumbnailUrl && <ThumbnailImg src={content.thumbnailUrl} />}
      <ContentText>
        <ContentTitle>{content.title}</ContentTitle>
        <ContentDescription>{content.description}</ContentDescription>
      </ContentText>
      <StatusOverlay status={content.movieTranscodeStatus} />
      <Menu>
        <IconWrapper onClick={() => onClickEdit(content.guid)}>
          <EditIcon />
        </IconWrapper>
        <IconWrapper onClick={() => onClickDelete(content)}>
          <DeleteIcon />
        </IconWrapper>
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $lowHeight: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${theme.color.white[1]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  width: 300px;
  height: 269px;
  ${(props) =>
    props.$lowHeight &&
    css`
      height: 100px;
    `}
`

const ThumbnailImg = styled.img`
  width: 100%;
  object-fit: contain;
  background-color: ${theme.color.darkGray[1]};
  height: 170px;
`

const ContentText = styled.div`
  padding: 4px 12px;
  height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
`

const ContentTitle = styled(LineClampedTxt).attrs({ bold: true, line: 2 })`
  flex: 1;
`

const ContentDescription = styled(LineClampedTxt).attrs({
  line: 2,
  color: theme.color.gray[1],
})`
  flex: 1;
`

const Menu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 34px;
  width: 84px;
  border-bottom-left-radius: 12px;
  background-color: ${theme.color.white[1]};
  opacity: 0.9;
`

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`

const EditIcon = styled(Icon).attrs({
  name: 'edit',
  width: '24px',
  height: '24px',
})``

const DeleteIcon = styled(Icon).attrs({
  name: 'delete',
  width: '24px',
  height: '28px',
})`
  color: ${theme.color.red[2]};
  margin-top: 4px;
`
