import { Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

type Props = {
  title: string
  maxLength?: number
}
export const EllipsisTitleSpan: React.VFC<Props> = ({ title, maxLength }) => {
  const tooltipID = TOOLTIP_ID_MAP.videoSeminarEventReportEllipsisTitle + uuid()

  if (maxLength && title.length > maxLength) {
    return (
      <>
        <span data-tip data-for={tooltipID}>
          {title.slice(0, maxLength)}…
        </span>
        <Tooltip id={tooltipID} arrowPosition="topLeft">
          <TooltipTxt>{title}</TooltipTxt>
        </Tooltip>
      </>
    )
  }

  return <span>{title}</span>
}

const TooltipTxt = styled(Txt)`
  max-width: 250px;
`
