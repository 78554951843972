import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { Name } from '@blue-agency/im-shared-front'
import { WebInterviewStatus } from '@blue-agency/proton/biz_hutt_bff'
import { SearchWebInterviewsResponse } from '@blue-agency/proton/biz_hutt_bff'
import { WebInterviewAssigneeRole } from '@blue-agency/proton/im'
import assert from 'assert'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { WithLoading } from '@/@types/withLoading'
import { timestampToDate } from '@/services/bffService'
import { validateNumberParamQuery } from '@/services/queryParamService'
import type { AssigneeRole } from '@/services/webInterviewService'
import { useWebInterviewsStorage } from '@/services/webInterviewService'
import { useSearchAssignedWebInterviews } from './useSearchAssignedWebInterviews'
import {
  useSearchForm,
  compressSearchForm,
  decompressSearchForm,
} from './useSearchForm'

const sizePerPage = 100 as const

export type Assignee = {
  guid: string
  name: Name
  iconColorCode: string
  role: AssigneeRole
}

export type WebInterview = {
  guid: string
  name: string
  status: WebInterviewStatus
  registerTime: Date
  interviewers: Assignee[]
  assignedRole: AssigneeRole
  durationSeconds?: number
  scheduledStartTime?: Date
}

/**
 * ソート情報を示す型（どのカラムの 昇順/降順 でソートされているか）
 */
export type SortBy = {
  column: 'registerTime' | 'name' | 'scheduledStartTime'
  desc: boolean
}

// クエリパラメータにJSON.stringifyしたものを入れるので、極力プロパティ名を短くする
type CompressedSortBy = {
  c: 'r' | 'n' | 's'
  d: 't' | 'f'
}

export const compressSortBy = (s: SortBy): CompressedSortBy => ({
  c: s.column === 'registerTime' ? 'r' : s.column === 'name' ? 'n' : 's',
  d: s.desc ? 't' : 'f',
})

export const decompressSortBy = (c: CompressedSortBy): SortBy => ({
  column:
    c.c === 'r' ? 'registerTime' : c.c === 'n' ? 'name' : 'scheduledStartTime',
  desc: c.d === 't' ? true : false,
})

export type UsePageState = {
  currentPage: number
  pageCount: number
  sortBy: SortBy
  changePage: (page: number) => void
  toggleSortBy: (column: SortBy['column']) => void
  webInterviewCount: number
  webInterviews: WebInterview[]
  setScrollTopQuery: (st: number) => void
  scrollTopQuery: number
  saveQueryAndListToBack: (guid: string) => void
} & ReturnType<typeof useSearchForm>

const usePageState = (): WithLoading<UsePageState> => {
  const [query, setQuery] = useQueryParams({
    cp: NumberParam, // currentPage
    sb: StringParam, // sortBy
    sc: StringParam, // searchCondition
    st: NumberParam, // scrollTop
  })
  const scrollTopQueryRef = useRef(0)
  const setScrollTopQuery = (st: number) => {
    scrollTopQueryRef.current = st
    setQuery({ st: st }, 'pushIn')
  }
  const scrollTopQuery = validateNumberParamQuery(query.st) ?? 0

  const wis = useWebInterviewsStorage()
  const saveQueryAndListToBack = (guid: string) => {
    // NOTE: この関数が呼ばれるタイミングでstを計算しており、setQueryしてからquery.st経由でstを取得すると計算前のstが入ってしまうのでref経由で渡す
    const queryStr = `?cp=${query.cp}&sb=${query.sb}&sc=${query.sc}&st=${scrollTopQueryRef.current}`
    wis.saveQueryAndListToBack('assignedWebInterviews', guid, queryStr)
  }

  const [currentPage, setPagination] = useState(
    validateNumberParamQuery(query.cp, { shouldBeInt: true }) || 1
  )

  const defaultSortBy = (() => {
    if (!query.sb) return undefined
    try {
      return decompressSortBy(JSON.parse(query.sb))
    } catch {
      return undefined
    }
  })()
  const [sortBy, setSortBy] = useState<SortBy>({
    column: defaultSortBy?.column ?? 'scheduledStartTime',
    desc: defaultSortBy?.desc ?? true,
  })

  const defaultSearchFormValue = (() => {
    if (!query.sc) return undefined
    try {
      const j = decompressSearchForm(JSON.parse(query.sc))
      return {
        ...j,
        registerTime: {
          from: j.registerTime.from && new Date(j.registerTime.from),
          to: j.registerTime.to && new Date(j.registerTime.to),
        },
      }
    } catch {
      return undefined
    }
  })()
  const { formValue, dispatch, validatedFormValue, freewordErrorMessage } =
    useSearchForm(defaultSearchFormValue)

  const { data, isLoading } = useSearchAssignedWebInterviews({
    pageSize: sizePerPage,
    page: currentPage,
    sortBy,
    condition: validatedFormValue,
  })

  const { webInterviews, webInterviewCount } = useMemo(() => {
    const webInterviews = data
      ?.toObject()
      .webInterviewsList.map((interview) => {
        const registerTime = timestampToDate(interview.registerTime)
        assert(registerTime)

        const decodeAssigneeRole = (role: WebInterviewAssigneeRole) => {
          switch (role) {
            case WebInterviewAssigneeRole.INTERVIEWER:
              return 'interviewer' as const
            case WebInterviewAssigneeRole.VIEWER:
              return 'viewer' as const
            case WebInterviewAssigneeRole.WEB_INTERVIEW_ASSIGNEE_ROLE_UNKNOWN:
              throw Error('Unexpected assigneeRole received')
          }
        }

        const decodeAssignee = (
          assignee: SearchWebInterviewsResponse.Assignee.AsObject
        ) => {
          assert(assignee.name)

          return {
            ...assignee,
            guid: assignee.staffGuid,
            name: new Name(assignee.name.familyName, assignee.name.givenName),
            role: decodeAssigneeRole(assignee.role),
          }
        }
        const interviewers: Assignee[] = interview.assigneesList
          .map(decodeAssignee)
          .filter((assignee) => assignee.role === 'interviewer')

        const durationSeconds =
          interview.duration &&
          interview.duration.specified &&
          interview.duration.specified.durationSeconds

        return {
          guid: interview.guid,
          name: interview.name,
          status: interview.status,
          registerTime,
          interviewers,
          assignedRole: decodeAssigneeRole(interview.assignedRole),
          durationSeconds,
          scheduledStartTime: timestampToDate(interview.scheduledStartTime),
        }
      })
    return {
      webInterviews: webInterviews ?? [],
      webInterviewCount: data?.toObject().totalLength ?? 0,
    }
  }, [data])

  const pageCount = useMemo(
    () => Math.ceil(webInterviewCount / sizePerPage) || 1,
    [webInterviewCount]
  )

  const changePage = useCallback((page: number) => setPagination(page), [])

  const toggleSortBy = useCallback((column: SortBy['column']) => {
    setSortBy((prev) => ({
      column,
      desc: prev.column !== column || !prev.desc,
    }))
  }, [])

  useEffect(() => {
    setQuery(
      {
        cp: currentPage,
        sb: JSON.stringify(compressSortBy(sortBy)),
        sc: JSON.stringify(compressSearchForm(validatedFormValue)),
      },
      'replaceIn'
    )
  }, [currentPage, sortBy, validatedFormValue, setQuery])

  /**
   * 検索条件が変わった時に1ページ目に戻す
   * queryからcurrentPageを復元する場合、queryの値で初期化した後に初回useEffectが走ると1ページ目に戻されてしまうので、
   * 初回レンダリングでは処理をスキップしてvalidatedFormValueに変更があった時のみ1ページ目に戻すようにする
   */
  const isFirstRenderingRef = useRef(true)
  useEffect(() => {
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false
      return
    }
    setPagination(1)
  }, [validatedFormValue])

  return isLoading
    ? { isLoading }
    : {
        isLoading,
        currentPage,
        pageCount,
        sortBy,
        changePage,
        toggleSortBy,
        webInterviewCount,
        webInterviews,
        dispatch,
        formValue,
        validatedFormValue,
        freewordErrorMessage,
        setScrollTopQuery,
        scrollTopQuery,
        saveQueryAndListToBack,
      }
}

export const AssignedWebInterviewsPageContainer = createContainer(usePageState)
