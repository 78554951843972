import React, { useMemo } from 'react'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import { format, isSameDay } from 'date-fns'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { assertNever } from '@/assertions'
import { IDItems } from './IDItems'
import type { TimelineEvent } from './Timeline'

type Props = PropsWithClassName<{
  event: TimelineEvent
}>

export const TimelineItem: React.VFC<Props> = (props) => {
  const now = useMemo(() => new Date(), [])

  return (
    <Log className={props.className}>
      <div>
        {eventToTextElement(props.event)}
        {props.event.kind === 'registered' && (
          <IDItems
            webInterviewGUID={props.event.webInterviewGUID}
            atsInterviewID={props.event.atsInterviewID}
          />
        )}
        <LogFooter>
          <Txt color={theme.color.gray[2]} size="s">
            {formatDate(props.event.date, now)}
          </Txt>
        </LogFooter>
      </div>
    </Log>
  )
}

function formatDate(date: Date, now: Date): string {
  if (isSameDay(now, date)) {
    return format(date, 'M/d H:mm')
  }
  return format(date, 'yyyy/M/d H:mm')
}

function eventToTextElement(event: TimelineEvent): JSX.Element {
  switch (event.kind) {
    case 'expired':
      return <Txt>この面接の有効期限が切れました。</Txt>
    case 'finished':
      return <Txt>面接が終了されました。</Txt>
    case 'started':
      return <Txt>面接が開始されました。</Txt>
    case 'registered': {
      if (event.atsInterviewID === undefined) {
        return <Txt>面接が登録されました。</Txt>
      }
      return <Txt>面接がAPI経由で登録されました。</Txt>
    }
    case 'interviewerJoin': {
      return (
        <JoinTextWrapper>
          <RoleLabelWrapper>
            <Txt color={theme.color.green[4]}>面接官</Txt>
          </RoleLabelWrapper>
          <JoinText>
            <Txt>
              <Name>{event.name}</Name>
              さん が面接に参加しました。
            </Txt>
          </JoinText>
        </JoinTextWrapper>
      )
    }
    case 'intervieweeJoin': {
      return (
        <JoinTextWrapper>
          <RoleLabelWrapper>
            <Txt color={theme.color.red.iM}>応募者</Txt>
          </RoleLabelWrapper>
          <JoinText>
            <Txt>
              <Name>{event.name}</Name>
              さん が面接に参加しました。
            </Txt>
          </JoinText>
        </JoinTextWrapper>
      )
    }
    default:
      assertNever(event)
  }
}

const Txt = styled(_Txt).attrs({ size: 's' })``

const Log = styled.div`
  display: flex;
  padding: 11px 0 8px 0;
`

const LogFooter = styled.div`
  margin-top: 4px;
`

const JoinTextWrapper = styled.div`
  display: flex;
`

const RoleLabelWrapper = styled.div`
  width: 42px;
  padding-right: 4px;
  flex: 0 0 auto;
`

const JoinText = styled.div`
  display: flex;
  flex: 1 1 auto;
`

const Name = styled.span`
  font-weight: 700;
`
