import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ChangeWebInterviewGuideRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ChangeWebInterviewGuideRequestValue = {
  guideGuid: string
  webInterviewGuid: string
}

export function useRequestChangeWebInterviewGuide() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestChangeWebInterviewGuide = useCallback(
    async (value: ChangeWebInterviewGuideRequestValue) => {
      const metadata = await buildMetadata()

      const req = new ChangeWebInterviewGuideRequest()
      req.setWebInterviewGuid(value.webInterviewGuid)
      req.setWebInterviewGuideGuid(value.guideGuid)

      return callBizHuttBffService(
        'changeWebInterviewGuide',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestChangeWebInterviewGuide,
  }
}
