import { Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
  description?: string
  editable: boolean
  width?: string
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>

export const EditableContent: React.FC<Props> = ({
  children,
  description,
  editable,
  onClick,
  width,
}) => {
  return editable ? (
    <Container
      role="button"
      aria-label={description}
      editable={editable}
      onClick={onClick}
      width={width}
    >
      {children}
      <IconContainer>
        <IconWrapper>
          <Icon name="edit" />
        </IconWrapper>
      </IconContainer>
    </Container>
  ) : (
    <Container editable={editable} width={width}>
      {children}
    </Container>
  )
}

const Container = styled.div<{ editable: boolean; width?: string }>(
  ({ editable, width }) => `
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: 4px;
  width: ${width ?? 'auto'};

  ${
    editable &&
    `&:hover {
      background: ${theme.color.gray[4]};
    }`
  }
`
)

const IconContainer = styled.div`
  height: 18px;
  width: 18px;
`

const IconWrapper = styled.div`
  display: none;

  ${Container}:hover & {
    display: initial;
  }
`
