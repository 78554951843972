import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { generatePath, Link } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { TableBase } from '@/components/TableBase'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Deadline } from './Deadline'
import { useListAllRecInterviewTemplates } from './useListAllRecInterviewTemplates'

export const RecInterviewTemplatesPage: React.VFC = () => {
  const ctx = useListAllRecInterviewTemplates()

  if (ctx.isLoading) {
    return <Loading />
  }

  const templates = ctx.data?.getTemplatesList() ?? []

  const emptyListMessage = templates.length === 0 && (
    <EmptyListMessage>
      <Txt size="l">登録された情報はありません</Txt>
    </EmptyListMessage>
  )

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="録画面接テンプレート一覧"
        right={
          <NewRecInterviewTemplateLink
            to={INTERNAL_PATHS.newRecInterviewTemplate}
          >
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{
                action: 'click_register_rect_interview_template',
              }}
            >
              テンプレート登録
            </PrimaryButton>
          </NewRecInterviewTemplateLink>
        }
      />
      <Wrapper>
        <MainWrapper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <Th property="name">テンプレート名</Th>
                  <Th property="deadline">提出期限</Th>
                  <Th property="padding" />
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map((template) => {
                  const templatePagePath = generatePath(
                    INTERNAL_PATHS.recInterviewTemplate,
                    { guid: template.getGuid() }
                  )
                  return (
                    <TableRow key={template.getGuid()}>
                      <Td property="name">
                        <TableCellLink to={templatePagePath}>
                          {template.getName()}
                        </TableCellLink>
                      </Td>
                      <Td property="deadline">
                        <TableCellLink to={templatePagePath}>
                          <Deadline template={template} />
                        </TableCellLink>
                      </Td>
                      <Td property="padding">
                        <TableCellLink to={templatePagePath} />
                      </Td>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {emptyListMessage}
          </TableWrapper>
        </MainWrapper>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const NewRecInterviewTemplateLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  overflow: hidden;
  flex: 1;
`

const MainWrapper = styled.div`
  grid-area: main;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

type Property = 'name' | 'deadline' | 'padding'

const width: Record<Property, number> = {
  name: 366,
  deadline: 400,
  padding: 30,
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  th:last-of-type {
    justify-content: flex-start;
    padding-left: ${width.padding}px;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 28px;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 70px !important;
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  deadline: css`
    flex: 0 0 ${width.deadline}px;
    justify-content: flex-start;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const TableCellLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -28px;
  padding: 0 28px;
  color: inherit;
`
