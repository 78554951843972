import { theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { TabType, tabs } from '../useTabs'

const mapTabName = (tab: TabType): string => {
  switch (tab) {
    case 'profile':
      return 'プロフィール'
    case 'selection':
      return '選考'
    case 'event':
      return 'イベント'
  }
}

type Props = PropsWithClassName<{
  selectedTab: TabType
  onClick: (tab: TabType) => void
}>
export const FooterTabs: React.VFC<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      {tabs.map((tab) => {
        const selected = props.selectedTab === tab
        return (
          <Tab key={tab} selected={selected} onClick={() => props.onClick(tab)}>
            <Txt color={selected ? theme.color.green[4] : theme.color.navy[1]}>
              {mapTabName(tab)}
            </Txt>
          </Tab>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: 40px;
  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[1]};
`

const Tab = styled.div<{ selected: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selected }) =>
    selected
      ? css`
          border-bottom: 3px solid ${theme.color.green[4]};
        `
      : css`
          border-bottom: 3px solid transparent;
        `};
  cursor: pointer;
`
