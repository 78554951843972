import React, { useCallback, useState } from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { AuthzContainer } from '@/services/authzService'
import { VideoSeminarForm } from '@/services/videoSeminarService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'

type Step = 'input' | 'confirm'

export const NewVideoSeminarPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('CreateVideoSeminar')

  usePromptBeforeUnload()

  const [step, setStep] = useState<Step>('input')
  const [form, setForm] = useState<VideoSeminarForm>()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: VideoSeminarForm) => {
    setForm(form)
    setStep('confirm')
  }, [])

  switch (step) {
    case 'input':
      return <InputPage form={form} completeInput={completeInput} />
    case 'confirm':
      return <ConfirmPage form={form} backToInput={backToInput} />
  }
}
