import { useMemo, useCallback, useState, ChangeEvent } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { set, addDays } from 'date-fns'
import { isSameHour, isSameDay } from 'date-fns'
import { isNotInProgress, SearchCondition } from '@/services/applicantService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { isValidDeadline } from '@/services/recInterviewTemplateService'
import { calculateDeadline } from '@/services/selectionRecInterviewService/calculateDeadline'
import { Applicant } from '../types'
import { useBatchReqSelectionRecInterviewAnswer } from '../useBatchReqSelectionRecInterviewAnswer'
import {
  BatchReqRecInterviewAnswerModal as Modal,
  ErrorType,
} from './BatchReqRecInterviewAnswerModal'
import { useRecInterviewTemplateOptions } from './useRecInterviewTemplateOptions'
import { useSelectionStepMasterGuidOptions } from './useSelectionStepMasterGuidOptions'

export type ReqMode =
  | {
      type: 'guids'
      selectedApplicants: Applicant[]
    }
  | {
      type: 'searchCondition'
      condition: SearchCondition
    }
type Props = {
  mode: ReqMode
  close: () => void
}
const BatchReqRecInterviewAnswerModal: React.VFC<Props> = (props) => {
  const selectionStepMasterGuidOptions = useSelectionStepMasterGuidOptions()
  const { recInterviewTemplateGuidOptions, deadlineMap } =
    useRecInterviewTemplateOptions()
  const { mutateAsync } = useBatchReqSelectionRecInterviewAnswer()
  const [selectionStepMasterGuid, setSelectionStepMasterGuid] = useState('')
  const [
    hasSelectionStepMasterGuidBlurError,
    setHasSelectionStepMasterGuidBlurError,
  ] = useState(false)

  const [recInterviewTemplateGuid, setRecInterviewTemplateGuid] = useState('')
  const [
    hasRecInterviewTemplateGuidBlurError,
    setHasRecInterviewTemplateGuidBlurError,
  ] = useState(false)

  // NOTE:録画面接テンプレートを選択するまで提出期限は決められないので、
  // 初期値としては確実に選択できる翌日の日付を設定
  const tomorrow = set(addDays(new Date(), 1), {
    minutes: 0,
    seconds: 0,
  })
  const [deadline, setDeadline] = useState<Date>(tomorrow)
  const [showDeadlineChangeWarning, setShowDeadlineChangeWarning] =
    useState(false)
  const [showDeadlinePicker, setShowDeadlinePicker] = useState(false)

  const onChangeSelectionStepMasterGuid = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setHasSelectionStepMasterGuidBlurError(false)
      setSelectionStepMasterGuid(event.target.value)
    },
    []
  )

  const [error, setError] = useState<ErrorType | undefined>(
    // NOTE: 選考追加できないステータスを含む場合は処理を実行させない
    (): ErrorType | undefined => {
      if (props.mode.type === 'guids') {
        if (
          props.mode.selectedApplicants.some((a) =>
            isNotInProgress(a.selectionStatus)
          )
        ) {
          return 'includeNotInProgress'
        }
      } else {
        if (
          (props.mode.condition.statuses.length === 0 &&
            props.mode.condition.subStatuses.length === 0) ||
          props.mode.condition.statuses.some(isNotInProgress)
        ) {
          return 'includeNotInProgress'
        }
      }
      return undefined
    }
  )
  const onChangeRecInterviewTemplateGuid = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setHasRecInterviewTemplateGuidBlurError(false)
      const guid = event.target.value
      setRecInterviewTemplateGuid(guid)

      if (deadlineMap === undefined) return
      const td = deadlineMap[guid]
      const now = new Date()
      const templateDeadline = calculateDeadline({
        baseDate: now,
        templateDeadline: td,
      })
      setDeadline(templateDeadline)

      setError(undefined)
      if (!isValidDeadline(templateDeadline, new Date())) {
        setError('pastDate')
      }

      // NOTE:初回はDeadlinePickerを表示する(warning不要)
      if (!showDeadlinePicker) {
        setShowDeadlinePicker(true)
        return
      }

      // NOTE:提出期限が変わらない場合は、warning不要
      if (
        isSameDay(deadline, templateDeadline) &&
        isSameHour(deadline, templateDeadline)
      ) {
        return
      }
      setShowDeadlineChangeWarning(true)
    },
    [deadline, deadlineMap, showDeadlinePicker]
  )

  const onChangeDeadline = useCallback((date: Date | null) => {
    setError(undefined)
    if (date === null) {
      setError('pastDate')
      return
    }

    setDeadline(date)
    setShowDeadlineChangeWarning(false)
    if (!isValidDeadline(date, new Date())) {
      setError('pastDate')
      return
    }
  }, [])

  const request = useCallback(async () => {
    let hasError = false
    if (selectionStepMasterGuid === '') {
      hasError = true
      setHasSelectionStepMasterGuidBlurError(true)
    }
    if (recInterviewTemplateGuid === '') {
      hasError = true
      setHasRecInterviewTemplateGuidBlurError(true)
    }
    if (deadline === undefined || !isValidDeadline(deadline, new Date())) {
      hasError = true
      setError('pastDate')
    }
    if (hasError) {
      return
    }
    setHasSelectionStepMasterGuidBlurError(false)
    setHasRecInterviewTemplateGuidBlurError(false)
    setError(undefined)

    try {
      if (props.mode.type === 'guids') {
        await mutateAsync({
          condition: {
            type: 'guids',
            applicantGuids: props.mode.selectedApplicants.map((a) => a.guid),
          },
          selectionStepMasterGuid: selectionStepMasterGuid,
          recInterviewTemplateGuid: recInterviewTemplateGuid,
          deadline: deadline,
        })
      } else {
        await mutateAsync({
          condition: {
            type: 'searchCondition',
            condition: props.mode.condition,
          },
          selectionStepMasterGuid: selectionStepMasterGuid,
          recInterviewTemplateGuid: recInterviewTemplateGuid,
          deadline: deadline,
        })
      }
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          if (e.hasMatchErrorDetail('NUM_BATCH_ACTION_LIMIT_REACHED')) {
            setError('overLimit')
            return
          }
          if (
            e.hasMatchErrorDetail('SELECTION_REC_INTERVIEW_ALREADY_EXPIRED')
          ) {
            setError('pastDate')
            return
          }
        }
      }
      captureException(e)
      commonErrorToast()
      return
    }

    toast('選考登録を開始しました')
    props.close()
  }, [
    selectionStepMasterGuid,
    recInterviewTemplateGuid,
    deadline,
    props,
    mutateAsync,
  ])

  return (
    <Modal
      mode={
        props.mode.type === 'guids'
          ? {
              type: 'guids',
              applicantsLength: props.mode.selectedApplicants.length,
            }
          : { type: 'searchCondition' }
      }
      error={error}
      deadline={deadline}
      selectionStepMasterGuidOptions={selectionStepMasterGuidOptions}
      hasSelectionStepMasterGuidBlurError={hasSelectionStepMasterGuidBlurError}
      recInterviewTemplateGuidOptions={recInterviewTemplateGuidOptions}
      hasRecInterviewTemplateGuidBlurError={
        hasRecInterviewTemplateGuidBlurError
      }
      showDeadlineChangeWarning={showDeadlineChangeWarning}
      showDeadlinePicker={showDeadlinePicker}
      onSubmit={request}
      onClose={props.close}
      onChangeDeadline={onChangeDeadline}
      onChangeSelectionStepMasterGuid={onChangeSelectionStepMasterGuid}
      onChangeRecInterviewTemplateGuid={onChangeRecInterviewTemplateGuid}
    />
  )
}

type GuidsProps = {
  applicants: Applicant[]
  close: () => void
}
export const BatchReqRecInterviewAnswerModalOnGuids: React.VFC<GuidsProps> = (
  props
) => {
  const selected = useMemo(
    () => props.applicants.filter((a) => a.selected),
    [props.applicants]
  )

  return (
    <BatchReqRecInterviewAnswerModal
      mode={{ type: 'guids', selectedApplicants: selected }}
      close={props.close}
    />
  )
}

type ConditionProps = {
  condition: SearchCondition
  close: () => void
}
export const BatchReqRecInterviewAnswerModalOnCondition: React.VFC<
  ConditionProps
> = (props) => {
  return (
    <BatchReqRecInterviewAnswerModal
      mode={{ type: 'searchCondition', condition: props.condition }}
      close={props.close}
    />
  )
}
