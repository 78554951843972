import { Fragment, useMemo } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  WeakColorSeparator,
  Txt as _Txt,
  theme,
  Icon,
  ErrorBox,
} from '@blue-agency/rogue'
import {
  FormRow,
  InputText,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LinkToInterviewGuideExamples } from '@/components/LinkToInterviewGuideExamples'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { placeholder } from '@/services/webInterviewGuideService'
import type { Form } from '@/services/webInterviewGuideService'
import { useInputPage } from '@/services/webInterviewGuideService'
import { AppendButton } from './AppendButton'

type Base = {
  completedForm: Form | undefined
  completeInput: (form: Form) => void
}

type NewPageProps = {
  type: 'new'
  isCreationLimitExceeded: boolean
} & Base

type EditPageProps = {
  type: 'edit'
  guid: string
  originalGuide: Form
} & Base

type Props = NewPageProps | EditPageProps

// Web面接ガイドの新規作成画面と編集画面の共通コンポーネント
export const CommonInputPage: React.VFC<Props> = (props) => {
  const defaultForm = useMemo(() => {
    switch (props.type) {
      case 'new':
        return {
          name: '',
          parts: [
            {
              startOffsetMin: 0,
              title: '',
              description: '',
            },
          ],
        }
      case 'edit':
        return props.originalGuide
    }
  }, [props])

  const {
    register,
    partFields,
    appendPart,
    removePart,
    moveUp,
    moveDown,
    errors,
    isSubmitting,
    onInputComplete,
    preventSubmitOnEnter,
  } = useInputPage(defaultForm, props.completedForm, props.completeInput)

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={preventSubmitOnEnter}>
        <Page.Header>
          <Page.HeaderTitle>
            面接ガイド{props.type === 'new' ? '登録' : '編集'}
          </Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          {props.type === 'new' && props.isCreationLimitExceeded && (
            <ErrorBoxWrapper>
              <ErrorBox>
                <ErrorBoxTxt>
                  登録可能な面接ガイドが上限に達しているため、登録できません。
                </ErrorBoxTxt>
              </ErrorBox>
            </ErrorBoxWrapper>
          )}
          <SettingsSection title="ガイド情報">
            <FormWrapper>
              <ExampleLinkWrapper>
                <LinkToInterviewGuideExamples pageType="interview_guide_create_page" />
              </ExampleLinkWrapper>
              <FormElement>
                <Row title="タイトル" subtitle="" required>
                  <InputText
                    {...register('name')}
                    size="ll"
                    placeholder={placeholder.name}
                    onBlurError={errors['name']?.message}
                    autoComplete="off"
                  />
                </Row>
              </FormElement>
              <WeakColorSeparator />
              {partFields.map((field, index) => {
                return (
                  <Fragment key={field.id}>
                    <FormElement>
                      <PartControl>
                        {index > 0 && <MoveUpButton onClick={moveUp(index)} />}
                        {index < partFields.length - 1 && (
                          <MoveDownButton onClick={moveDown(index)} />
                        )}
                        <DeleteButton onClick={removePart(index)} />
                      </PartControl>
                      <Row
                        title={`パート${index + 1}`}
                        subtitle="開始時間"
                        required
                      >
                        <InputText
                          {...register(
                            `parts.${index}.startOffsetMin` as const
                          )}
                          onBlurError={
                            errors.parts
                              ? errors.parts[index]?.startOffsetMin?.message
                              : undefined
                          }
                          size="ss"
                          errorNoWrap
                        />
                        <OffsetSuffix />
                      </Row>
                      <Row title="" subtitle="見出し" required>
                        <InputText
                          {...register(`parts.${index}.title` as const)}
                          size="ll"
                          placeholder={placeholder.title}
                          onBlurError={
                            errors.parts
                              ? errors.parts[index]?.title?.message
                              : undefined
                          }
                        />
                      </Row>
                      <Row title="" subtitle="本文" required>
                        <InputText
                          {...register(`parts.${index}.description` as const)}
                          size="ll"
                          height="120px"
                          type="textarea"
                          placeholder={placeholder.description}
                          onBlurError={
                            errors.parts
                              ? errors.parts[index]?.description?.message
                              : undefined
                          }
                        />
                      </Row>
                    </FormElement>
                    <WeakColorSeparator />
                  </Fragment>
                )
              })}
              <AppendButtonWrapper>
                <AppendButton
                  disabled={partFields.length >= 20}
                  onClick={appendPart}
                />
              </AppendButtonWrapper>
            </FormWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink to={INTERNAL_PATHS.webInterviewGuides}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_cancel_register_web_interview_guide_button',
                }}
              >
                一覧に戻る
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              disabled={isSubmitting}
              comlinkPushParams={{
                action: 'click_proceed_to_confirm_web_interview_guide_button',
              }}
              onClick={onInputComplete}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const AppendButtonWrapper = styled.div`
  padding: 20px 13px;
`

const Txt = styled(_Txt).attrs({ size: 'm' })``

const ErrorBoxTxt = styled(Txt).attrs({ color: 'red' })``

const ErrorBoxWrapper = styled.div`
  padding: 20px 0;
`

const BackButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const FormWrapper = styled.div`
  padding: 0 20px;
`

const FormElement = styled.div`
  padding: 9px 0;
  display: flex;
  flex-direction: column;
`

const PartControl = styled.div`
  display: flex;
  justify-content: end;
  padding: 7px 0;
`

const OffsetSuffix = styled(Txt).attrs({ children: '分時点' })`
  margin-left: 8px;
`

const Row = styled(FormRow)`
  padding: 10px 0;
`

const ExampleLinkWrapper = styled.div`
  padding-top: 20px;
`

const DeleteButton = styled(Icon).attrs({ name: 'delete' })`
  cursor: pointer;
  color: ${theme.color.red[2]};
  margin-right: 20px;
  margin-left: 10px;
`

const MoveUpButton = styled(Icon).attrs({ name: 'arrow-up2' })`
  cursor: pointer;
  margin: 0 10px;
`

const MoveDownButton = styled(Icon).attrs({ name: 'arrow-down2' })`
  cursor: pointer;
  margin: 0 10px;
`
