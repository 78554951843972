import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetVideoSeminarReportRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetVideoSeminarReport() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetVideoSeminarReport = useCallback(
    async (videoSeminarGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetVideoSeminarReportRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)

      return callBizHuttBffService(
        'getVideoSeminarReport',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetVideoSeminarReport }
}
