import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { assertNever } from '@/assertions'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { VideoSeminarContentNewPageContainer } from './VideoSeminarContentNewPageContainer'

export const VideoSeminarContentNewPage = provide(
  VideoSeminarContentNewPageContainer
)(() => {
  const { step } = VideoSeminarContentNewPageContainer.useContainer()

  usePromptBeforeUnload()

  const { authorizeByRpcName } = AuthzContainer.useContainer()
  if (authorizeByRpcName !== undefined) {
    authorizeByRpcName('StartVideoSeminarMovieUpload')
    authorizeByRpcName('CompleteVideoSeminarMovieUpload')
    authorizeByRpcName('CreateVideoSeminarContent')
  }

  switch (step.phase) {
    case 'input':
      return <InputPage />
    case 'confirm':
      return <ConfirmPage completedForm={step.completedForm} />
    default:
      assertNever(step)
  }
})
