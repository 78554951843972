import { Fragment, useMemo } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt as _Txt, theme } from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import type { Form } from '@/services/webInterviewGuideService'

type Base = {
  backToInput: () => void
  completedForm: Form | undefined
  isRegisterLoading: boolean
  exec: () => Promise<void>
}

type NewConfirmProps = {
  type: 'new'
} & Base

type EditConfirmProps = {
  type: 'edit'
} & Base

type Props = NewConfirmProps | EditConfirmProps

// Web面接ガイドの新規作成画面と編集画面の共通コンポーネント（入力確認画面）
export const CommonConfirmPage: React.VFC<Props> = (props) => {
  if (props.completedForm === undefined) {
    throw new Error('completedForm is undefined')
  }

  const actionNames = useMemo(() => {
    return {
      new: {
        back: 'click_go_back_to_web_interview_guide_input_button',
        exec: 'click_register_web_interview_guide_button',
      },
      edit: {
        back: 'click_go_back_to_edit_web_interview_guide_button',
        exec: 'click_update_web_interview_guide_button',
      },
    }
  }, [])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>
            面接ガイド{props.type === 'new' ? '登録' : '編集'}
          </Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容の確認">
            <FormWrapper>
              <FormElement>
                <Row title="タイトル" subtitle="">
                  <Txt>{props.completedForm.name}</Txt>
                </Row>
              </FormElement>

              <WeakColorSeparator />
              {props.completedForm.parts.map((part, index) => {
                return (
                  <Fragment key={part.startOffsetMin}>
                    <FormElement key={part.startOffsetMin}>
                      <Row title={`パート${index + 1}`} subtitle="開始時間">
                        <Txt>{part.startOffsetMin}分時点</Txt>
                      </Row>
                      <Row title="" subtitle="見出し">
                        <Txt>{part.title}</Txt>
                      </Row>
                      <Row title="" subtitle="本文">
                        <Description>{part.description}</Description>
                      </Row>
                    </FormElement>
                    <WeakColorSeparator />
                  </Fragment>
                )
              })}
            </FormWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: actionNames[props.type].back,
              }}
              onClick={props.backToInput}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              disabled={props.isRegisterLoading}
              comlinkPushParams={{
                action: actionNames[props.type].exec,
              }}
              onClick={props.exec}
            >
              {props.type === 'new' ? '登録' : '保存'}
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })``

const Description = styled(Txt)`
  word-break: break-all;
`

const FormWrapper = styled.div`
  padding: 0 20px;
`

const FormElement = styled.div`
  padding: 9px 0;
  display: flex;
  flex-direction: column;
`

const Row = styled(FormRow).attrs({
  titleWidth: '145px',
  subtitleWidth: '120px',
})`
  padding: 4px 0;
`
