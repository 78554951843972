import type { DropdownOption } from '@blue-agency/rogue/dist/im'
import { useQuery } from 'react-query'
import { useRequestListAllRecInterviewTemplatesForOption } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useRecInterviewTemplateOptions() {
  const listAllRecInterviewTemplatesForOptionRes =
    useListAllRecInterviewTemplatesForOption()

  const options: DropdownOption[] =
    listAllRecInterviewTemplatesForOptionRes.data
      ?.getTemplatesList()
      .map((t) => ({
        value: t.getGuid(),
        label: t.getName(),
      })) ?? []

  return options
}

function useListAllRecInterviewTemplatesForOption() {
  const { requestListAllRecInterviewTemplatesForOption } =
    useRequestListAllRecInterviewTemplatesForOption()

  return useQuery(
    [QUERY_KEY.allRecInterviewTemplatesForOption],
    requestListAllRecInterviewTemplatesForOption
  )
}
