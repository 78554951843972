import { useMutation, useQueryClient } from 'react-query'
import {
  CreateApplicantCsvFormatRequestValue,
  useRequestCreateApplicantCsvFormat,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateApplicantCsvFormat() {
  const queryClient = useQueryClient()
  const { requestCreateApplicantCsvFormat } =
    useRequestCreateApplicantCsvFormat()

  return useMutation(
    async (value: CreateApplicantCsvFormatRequestValue) => {
      await requestCreateApplicantCsvFormat(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicantCsvFormats)
      },
    }
  )
}
