import { useState, useMemo } from 'react'
import { useCallback } from 'react'
import { ChangeEvent } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Modal as M,
  toast,
  Txt,
  WeakColorSeparator,
  theme,
} from '@blue-agency/rogue'
import {
  Dropdown as D,
  DropdownOption,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/dist/im'
import { captureException } from '@sentry/react'
import styled from 'styled-components'
import { SelectionStepType } from '@/services/applicantService'
import { useListSelectionStepMasters } from '../../useListSelectionStepMasters'
import { useRecInterviewTemplateOptions } from '../../useRecInterviewTemplateOptions'
import {
  useAssignSelectionStepToApplicant,
  useAssignSelectionStepToApplicantWithRecInterviewTemplate,
} from './useAssignSelectionStepToApplicant'

type Props = {
  active: boolean
  applicantGuid: string
  close: () => void
}

export const RegisterSelectionStepModal: React.VFC<Props> = ({
  active,
  applicantGuid,
  close,
}) => {
  const [selectionStepMasterGuid, setSelectionStepMasterGuid] = useState('')
  const [recInterviewTemplateGuid, setRecInterviewTemplateGuid] = useState('')
  const [hasBlurError, setHasBlurError] = useState(false)

  const onChangeSelectionStepMaster = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setHasBlurError(false)
      setSelectionStepMasterGuid(event.target.value)
    },
    []
  )

  const onChangeRecInterviewTemplate = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setRecInterviewTemplateGuid(event.target.value)
    },
    []
  )

  const ssms = useListSelectionStepMasters()
  const options: DropdownOption[] =
    ssms.data?.getSelectionStepMastersList().map((s) => ({
      value: s.getGuid(),
      label: s.getName(),
    })) ?? []

  const { mutateAsync: assignSelectionStepToApplicant } =
    useAssignSelectionStepToApplicant()
  const {
    mutateAsync: assignSelectionStepToApplicantWithRecInterviewTemplate,
  } = useAssignSelectionStepToApplicantWithRecInterviewTemplate()

  const templateOptions = useRecInterviewTemplateOptions()

  const showTemplate = useMemo(() => {
    const type = ssms.data
      ?.getSelectionStepMastersList()
      .find((s) => s.getGuid() === selectionStepMasterGuid)
      ?.getType()
    return type === SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW
  }, [selectionStepMasterGuid, ssms.data])

  const onClose = useCallback(() => {
    setSelectionStepMasterGuid('')
    setHasBlurError(false)
    close()
  }, [close])

  const register = useCallback(async () => {
    if (selectionStepMasterGuid === '') {
      setHasBlurError(true)
      return
    }

    try {
      if (recInterviewTemplateGuid === '') {
        await assignSelectionStepToApplicant({
          applicantGuid,
          selectionStepMasterGuid,
        })
      } else {
        await assignSelectionStepToApplicantWithRecInterviewTemplate({
          applicantGuid,
          selectionStepMasterGuid,
          recInterviewTemplateGuid,
        })
      }
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }
    toast('選考を追加しました')
    onClose()
  }, [
    assignSelectionStepToApplicant,
    assignSelectionStepToApplicantWithRecInterviewTemplate,
    applicantGuid,
    selectionStepMasterGuid,
    recInterviewTemplateGuid,
    onClose,
  ])

  return (
    <Modal active={active} title="選考を追加" onClose={onClose} size="l">
      <ModalBody>
        <Row>
          <RowTxt>選考</RowTxt>
          <RequireTxt size="s">必須</RequireTxt>
          <Dropdown
            name="selectionStepMaster"
            onChange={onChangeSelectionStepMaster}
            options={options}
            onBlurError={hasBlurError ? '選択してください' : undefined}
          />
        </Row>
        {showTemplate && (
          <Row>
            <RowTxt>テンプレート</RowTxt>
            <RequireTxt />
            <Dropdown
              name="recInterviewTemplate"
              onChange={onChangeRecInterviewTemplate}
              options={templateOptions}
            />
          </Row>
        )}
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_cancel_button_on_register_selection_step_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={register}
            comlinkPushParams={{
              action: 'click_register_button_on_register_selection_step_modal',
            }}
          >
            追加
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const Modal = styled(M)`
  /* NOTE: 一番上のDropdownのエラー表示がモーダルヘッダーに隠れないようにしている */
  && > div:last-of-type {
    overflow: visible;
  }
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 50px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`

const RowTxt = styled(Txt)`
  width: 140px;
  padding-top: 5px;
`

const RequireTxt = styled(Txt).attrs({
  size: 's',
  color: theme.color.red[2],
  bold: true,
})`
  width: 50px;
  padding-top: 8px;
`

const Dropdown = styled(D).attrs({ size: 'max' })`
  width: 100%;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
