import { useCallback } from 'react'
import { toast } from '@blue-agency/rogue'
import { writeTextToClipboard } from '@/services/clipboardService'

export const useRecInterviewTemplatePage = (templateGuid: string) => {
  const copyRecInterviewTemplateGuid = useCallback(() => {
    writeTextToClipboard(templateGuid)
    toast('録画面接テンプレートIDをコピーしました')
  }, [templateGuid])

  return {
    copyRecInterviewTemplateGuid,
  }
}
