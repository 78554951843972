import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SimpleStatus } from './SimpleStatus'
import failedImage from './failed.svg'

export const FailedStatus = (
  props: PropsWithClassName
): React.ReactElement | null => {
  return (
    <SimpleStatus
      className={props.className}
      imgSrc={failedImage}
      message="録画データの生成に失敗しました"
    />
  )
}
