import { SelectionSharingType } from '@/services/applicantService/applicantTypes'
import type { MappingTarget } from './types'

export const targetOptions: {
  label: string
  value: MappingTarget
  max?: number
}[] = [
  { label: '姓', value: 'familyName', max: 1 },
  { label: '名', value: 'givenName', max: 1 },
  { label: '姓（カナ）', value: 'familyNameKana', max: 1 },
  { label: '名（カナ）', value: 'givenNameKana', max: 1 },
  { label: 'メールアドレス', value: 'email', max: 1 },
  { label: '電話番号', value: 'phoneNumber', max: 1 },
  { label: '連携先応募者ID', value: 'externalApplicantId', max: 1 },
  { label: '備考', value: 'note' },
]

export const selectionSharingOptions: {
  label: string
  value: SelectionSharingType
}[] = [
  { label: '共有する', value: 'SHARING_TYPE_ALL' },
  { label: '共有しない', value: 'SHARING_TYPE_UNSHARED' },
]
