import { useMutation, useQueryClient } from 'react-query'
import {
  BatchUpdateSelectionRecInterviewDeadlineRequestValue,
  useRequestBatchUpdateSelectionRecInterviewDeadline,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchUpdateSelectionRecInterviewDeadline() {
  const queryClient = useQueryClient()
  const { requestBatchUpdateSelectionRecInterviewDeadline } =
    useRequestBatchUpdateSelectionRecInterviewDeadline()

  return useMutation(
    async (value: BatchUpdateSelectionRecInterviewDeadlineRequestValue) => {
      await requestBatchUpdateSelectionRecInterviewDeadline(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries(QUERY_KEY.applicant)
      },
    }
  )
}
