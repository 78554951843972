import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { Name, NameKana } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  Assignment,
  buildScheduledStartTime,
} from '@/services/webInterviewService'
import type { Assignee } from '../AssigneesForm'
import { useAllAssigneeCandidates } from './useAllAssigneeCandidates'
import { useCreateWebInterviews } from './useCreateWebInterviews'
import { useListWebInterviewGuides } from './useListWebInterviewGuides'

type Step = 'input' | 'confirm'

export type Form = {
  name?: string
  count: number
  assignment: Assignment
  assignees: Assignee[]
  guideGuid: string
  durationMinutes?: number
  scheduledDate?: Date

  // 選択される値は数値だが、
  // - dropdown で値が選択されなかった場合、空文字列となること
  // - 入力確認画面から入力画面に戻ったときに、dropdownのデフォルト値を設定する必要があること
  // などの理由により、string型で取り扱うほうが都合が良い
  scheduledHour: string

  // 選択される値は数値だが、
  // - dropdown で値が選択されなかった場合、空文字列となること
  // - 入力確認画面から入力画面に戻ったときに、dropdownのデフォルト値を設定する必要があること
  // などの理由により、string型で取り扱うほうが都合が良い
  scheduledMin: string
}

export type Staff = {
  guid: string
  email: string
  name: Name
  nameKana: NameKana
  iconColorCode: string
}

function useNewWebInterviewPage() {
  const { mutateAsync: createWebInterviews, isLoading } =
    useCreateWebInterviews()
  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<Form>()
  const history = useHistory()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: Form) => {
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      throw new Error('completedForm is undefined')
    }

    const guideGuid =
      completedForm.guideGuid.length > 0 ? completedForm.guideGuid : undefined

    try {
      await createWebInterviews({
        count: completedForm.count,
        name: completedForm.name ?? '',
        assignment: completedForm.assignment,
        assignees: completedForm.assignees,
        guideGuid,
        durationMinutes: completedForm.durationMinutes,
        scheduledStartTime: buildScheduledStartTime(completedForm),
      })
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    toast('Web面接を登録しました')
    history.push(INTERNAL_PATHS.webInterviews)
  }, [completedForm, createWebInterviews, history])

  const allAssigneeCandidates = useAllAssigneeCandidates()

  const guides = useListWebInterviewGuides()

  return {
    step,
    completeInput,
    backToInput,
    completedForm,
    register,
    isRegisterLoading: isLoading,
    allAssigneeCandidates,
    guides,
  }
}

export const NewWebInterviewPageContainer = createContainer(
  useNewWebInterviewPage
)
