import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { Form } from '@/services/questionTemplateService/formSchema'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useUpdateRecInterviewQuestionTemplate } from './useUpdateRecInterviewQuestionTemplate'

type Step = 'input' | 'confirm'

function useEditQuestionTemplatePage() {
  const { questionTemplateGuid } = useParams<{ questionTemplateGuid: string }>()
  const { mutateAsync: updateQuestionTemplate, isLoading: isUpdateLoading } =
    useUpdateRecInterviewQuestionTemplate()
  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<Form>()
  const history = useHistory()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: Form) => {
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      throw new Error('completedForm is undefined')
    }

    try {
      await updateQuestionTemplate({
        ...completedForm,
        questionTemplateGuid,
        videoFileKey: completedForm.videoFile?.key,
      })
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    toast('設問を保存しました')
    history.push(
      generatePath(INTERNAL_PATHS.questionTemplate, { questionTemplateGuid })
    )
  }, [completedForm, updateQuestionTemplate, history, questionTemplateGuid])

  return {
    questionTemplateGuid,
    step,
    completeInput,
    backToInput,
    completedForm,
    register,
    isUpdateLoading,
  }
}

export const EditQuestionTemplatePageContainer = createContainer(
  useEditQuestionTemplatePage
)
