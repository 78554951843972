import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SimpleStatus } from './SimpleStatus'
import expiredImage from './expired.svg'

export const ExpiredStatus = (
  props: PropsWithClassName
): React.ReactElement | null => {
  return (
    <SimpleStatus
      className={props.className}
      imgSrc={expiredImage}
      message="面接URLの有効期限が切れたため、この面接は実施できません。"
    />
  )
}
