export type AssigneeRole = 'interviewer' | 'viewer'
export type Assignee = {
  guid: string
  role: AssigneeRole
}

type AssigneeRoleOption = {
  label: string
  value: AssigneeRole
}

export const assigneeRoleOptions: Array<AssigneeRoleOption> = [
  {
    label: '面接官',
    value: 'interviewer',
  },
  {
    label: '閲覧者',
    value: 'viewer',
  },
]

export function getAssigneeRoleOption(
  value: AssigneeRole
): AssigneeRoleOption | undefined {
  return assigneeRoleOptions.find((option) => option.value === value)
}
