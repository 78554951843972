import { useQuery } from 'react-query'
import { SelectionStepType } from '@/services/applicantService'
import { useRequestListSelectionStepMasters } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSelectionStepMasterGuidOptions() {
  const { data } = useListSelectionStepMasters()

  const selectionStepMasterGuidOptions =
    data
      ?.getSelectionStepMastersList()
      .filter(
        (s) =>
          // NOTE:録画面接専用の提出依頼モーダルなので設問タイプが録画面接のものに絞る
          s.getType() === SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW
      )
      .map((s) => ({
        value: s.getGuid(),
        label: s.getName(),
      })) ?? []
  return selectionStepMasterGuidOptions
}

function useListSelectionStepMasters() {
  const { requestListSelectionStepMasters } =
    useRequestListSelectionStepMasters()

  return useQuery(
    [QUERY_KEY.selectionStepMasters],
    requestListSelectionStepMasters,
    {
      staleTime: 5 * 60 * 1000,
    }
  )
}
