import { useState, useRef, useEffect } from 'react'
import {
  NextAction,
  SelectionStatus,
  SelectionSubStatus,
  getNextAction,
} from '@/services/applicantService'
import { SwitchTab } from './useApplicantTabs'

export type NextActionFn = () => void
export type SetNextActionFn = (nextAction: NextAction, fn: NextActionFn) => void
type NextActionFnRef = Record<NextAction, NextActionFn | undefined>
type NextActionResponse = {
  nextAction: NextAction
  setNextActionFn: SetNextActionFn
  nextActionFn: NextActionFn
}

const setIntervalDuration = 10 // ms
const maxWaitingTimeForSetNextActionFn = 10000 // ms
const maxSetIntervalCnt = maxWaitingTimeForSetNextActionFn / setIntervalDuration

export const useNextAction = (
  status: SelectionStatus,
  subStatus: SelectionSubStatus,
  switchTab: SwitchTab
): NextActionResponse => {
  const [nextAction, setNextAction] = useState<NextAction>(
    getNextAction(status, subStatus)
  )

  const nextActionFnRef = useRef<NextActionFnRef>({
    ASSESS_SELECTION_STEP: undefined,
    ASSIGN_REC_INTERVIEW_TEMPLATE: undefined,
    CHANGE_REC_INTERVIEW_DEADLINE: undefined,
    NONE: () => {
      throw new Error('next action NONE does NOT have fn to exec.')
    },
    REGISTER_INITIAL_SELECTION_STEP: undefined,
    REGISTER_NEXT_SELECTION_STEP: undefined,
    REQUEST_SUBMISSION_REC_INTERVIEW: undefined,
  })

  useEffect(() => {
    setNextAction(getNextAction(status, subStatus))
  }, [status, subStatus])

  const setNextActionFn: SetNextActionFn = (
    nextAction: NextAction,
    fn: () => void
  ) => {
    nextActionFnRef.current[nextAction] = fn
  }

  const nextActionFn = () => {
    // NOTE: 現状は次のアクションは全て[選考]タブ
    switchTab('selection')

    // ↑で[選考]タブ以外から移動した場合など、nextActionFnRefがnextActionFn実行時にまだ登録されていない場合がある
    // setIntervalで、該当nextActionFnRefが登録されるまで待ってから実行する
    let cnt = 0
    const handle = setInterval(() => {
      const fn = nextActionFnRef.current[nextAction]
      if (fn === undefined) {
        if (cnt > maxSetIntervalCnt) {
          clearInterval(handle)
          throw new Error('timeout: nextActionFn was NOT set.')
        }
        cnt++
        return
      }
      fn()
      clearInterval(handle)
    }, setIntervalDuration)
  }

  return {
    nextAction,
    setNextActionFn,
    nextActionFn,
  }
}
