import React, { useCallback, useMemo, useState } from 'react'
import {
  WeakColorSeparator,
  Modal,
  theme,
  Txt,
  RequireTxt as _RequireTxt,
  CheckBox as _CheckBox,
  toast,
  ErrorBox,
} from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { WebInterview } from './types'
import { useBatchDeleteWebInterviewsMutation } from './useBatchDeleteWebInterviewsMutation'

type Props = {
  webInterviews: WebInterview[]
  active: boolean
  onCloseModal: () => void
}

export const BatchDeleteWebInterviewsModal: React.VFC<Props> = ({
  webInterviews,
  active,
  onCloseModal,
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const toggleConfirmed = useCallback(() => {
    setConfirmed((prevState) => !prevState)
  }, [])

  const selected = useMemo(
    () => webInterviews.filter((w) => w.selected),
    [webInterviews]
  )

  const onClose = useCallback(() => {
    onCloseModal()
    setConfirmed(false)
    setFailedPreconditionError(false)
  }, [onCloseModal])

  const { mutateAsync: deleteWebInterviews } =
    useBatchDeleteWebInterviewsMutation()

  const [failedPreconditionError, setFailedPreconditionError] =
    useState<boolean>(false)
  const onSubmit = useCallback(async () => {
    try {
      await deleteWebInterviews({ guids: selected.map((w) => w.guid) })
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.isFailedPrecondition) {
          setFailedPreconditionError(true)
          return
        }
      }
      throw e
    }
    toast(`${selected.length}件の面接を削除しました`)
    onClose()
  }, [selected, onClose, deleteWebInterviews])

  const canSubmit = confirmed && selected.length > 0

  return (
    <Modal active={active} title="面接を削除" onClose={onClose}>
      <ModalBody>
        {failedPreconditionError && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                実施中の面接が含まれるため、削除できませんでした。
                <br />
                該当の面接を終了させてから、再度お試しください。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <DescriptionWrapper>
          <Count>{selected.length}</Count>
          <InlineTxt>件の面接を削除します。</InlineTxt>
          <Description>
            削除後は、お問い合わせいただいても復元することはできませんのでご注意ください。
          </Description>
        </DescriptionWrapper>
        <WeakColorSeparator />
        <ListWrapper>
          <ListLabelTxt>対象の面接</ListLabelTxt>
          <ListContent>
            <Ul>
              {selected.map((webInterview) => {
                return (
                  <Li key={webInterview.guid}>
                    <Txt>{webInterview.name}</Txt>
                  </Li>
                )
              })}
            </Ul>
          </ListContent>
        </ListWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ConfirmWrapper>
          <ConfirmTxt>削除確認</ConfirmTxt>
          <CheckBoxWrapper>
            <RequireTxt size="s">必須</RequireTxt>
            <CheckBox
              checked={confirmed}
              onChange={toggleConfirmed}
              size={18}
            />
            <Txt>該当の面接を完全に削除</Txt>
          </CheckBoxWrapper>
        </ConfirmWrapper>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_batch_delete_web_interviews_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={onSubmit}
            comlinkPushParams={{
              action: 'click_batch_delete_web_interviews_button',
            }}
          >
            削除
          </DangerButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  display: inline-block;
  line-height: 1;
`

const InlineTxt = styled(Txt).attrs({ size: 'm' })`
  display: inline-block;
`

const Description = styled(Txt).attrs({ size: 'm', bold: true })``

const ListLabelTxt = styled(Txt).attrs({ size: 'm' })`
  flex: 2.2;
`

const ListWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  justify-content: start;
`
const ListContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  overflow-y: auto;
  max-height: 150px;
  flex: 3;
`

const Ul = styled.ul`
  padding-left: 30px;
`

const Li = styled.li`
  font-size: ${theme.fontSize.m};
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ConfirmWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
`

const ConfirmTxt = styled(Txt).attrs({ size: 'm' })`
  flex: 1.8;
`

const CheckBoxWrapper = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: start;
`

const RequireTxt = styled(_RequireTxt)`
  font-weight: normal;
  margin-right: 6px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
