import { useCallback, useState } from 'react'
import { assertNever } from '@/assertions'
import { ApplicantForm } from '@/services/applicantService'
import type {
  CSVInputData,
  CSVData,
  CsvHeader,
} from '@/services/csvService/types'
import type { Form, Format } from './types'

type Step = 'input' | 'mapping' | 'confirm' | 'submit' | 'completed'
type StepContext =
  | {
      step: 'input'
      initialInputData?: CSVInputData
      goNext: (
        csvInputData: CSVInputData,
        formatData: Format,
        formData: Form
      ) => void
    }
  | {
      step: 'mapping'
      csvData: CSVData
      initialFormData?: Form
      goPrevious: () => void
      goNext: (formData: Form, applicantsData: ApplicantForm[]) => void
    }
  | {
      step: 'confirm'
      csvData: CSVData
      formData: Form
      goPrevious: () => void
      goNext: () => void
    }
  | {
      applicants: ApplicantForm[]
      step: 'submit'
      csvHeader: CsvHeader
      formData: Form
      formatData: Format
      goNext: () => void
    }
  | {
      applicants: ApplicantForm[]
      step: 'completed'
    }

export function useBatchNewApplicantsPage(): StepContext {
  const [step, setStep] = useState<Step>('input')
  const [csvInputData, setCSVInputData] = useState<CSVInputData | undefined>()
  const [formData, setFormData] = useState<Form | undefined>()
  const [formatData, setFormatData] = useState<Format | undefined>()
  const [applicantsData, setApplicantsData] = useState<ApplicantForm[]>([])

  const goNextOnInput = useCallback(
    (
      csvInputData: CSVInputData,
      formatData: Format | undefined,
      formData: Form
    ) => {
      setCSVInputData(csvInputData)
      setFormatData(formatData)
      setFormData(formData)
      setStep('mapping')
    },
    []
  )

  const goNextOnMapping = useCallback(
    (formData: Form, applicantsData: ApplicantForm[]) => {
      setFormData(formData)
      setApplicantsData(applicantsData)
      setStep('confirm')
    },
    []
  )

  const goNextOnConfirm = useCallback(() => {
    setStep('submit')
  }, [])

  const goNextOnSubmit = useCallback(() => {
    setStep('completed')
  }, [])

  const goPreviousOnMapping = useCallback(() => {
    setFormData(undefined)
    setStep('input')
  }, [])

  const goPreviousOnConfirm = useCallback(() => {
    setStep('mapping')
  }, [])

  if (step === 'input') {
    return {
      step: 'input',
      initialInputData: csvInputData,
      goNext: goNextOnInput,
    }
  }

  if (!csvInputData) throw new Error('csvData is undefined')
  if (!formatData) throw new Error('formatData is undefined')

  if (step === 'mapping') {
    return {
      step: 'mapping',
      csvData: csvInputData.csvData,
      initialFormData: formData,
      goPrevious: goPreviousOnMapping,
      goNext: goNextOnMapping,
    }
  }

  if (!formData) throw new Error('formData is undefined')

  if (step === 'confirm') {
    return {
      step: 'confirm',
      csvData: csvInputData.csvData,
      formData: formData,
      goPrevious: goPreviousOnConfirm,
      goNext: goNextOnConfirm,
    }
  }

  if (step === 'submit') {
    return {
      step: 'submit',
      applicants: applicantsData,
      csvHeader: csvInputData.csvData.csvHeader,
      formData: formData,
      formatData: formatData,
      goNext: goNextOnSubmit,
    }
  }

  if (step === 'completed') {
    return {
      step: 'completed',
      applicants: applicantsData,
    }
  }

  assertNever(step)
}
