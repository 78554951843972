import { RequireTxt, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { DueDuration, DueTime } from '@/services/recInterviewTemplateService'

export const FormBlockContainer = styled.div`
  padding: 0 16px;
  background-color: ${theme.color.white[1]};
`

export const FormBlock = styled.div`
  margin: 20px 12px;
  display: grid;
  grid-template-columns: 140px 28px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'label-area required-area input-area';
`

export const SText = styled.span`
  font-size: ${theme.fontSize.s};
  margin: auto 0.5em;
`

export const LabelArea = styled.div`
  grid-area: label-area;
`

export const LabelAreaInner = styled.div`
  height: 22px;
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 8px;
  }
`

export const SmallTxt = styled(Txt).attrs({ size: 'm' })``

export const InputArea = styled.div`
  grid-area: input-area;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > *:not(:first-child) {
    margin-top: 12px;
  }
`

export const Item = styled.div<{ closed?: boolean }>`
  display: ${({ closed }) => (closed ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: space-between;
`

export const ItemLabel = styled.div`
  width: 100px;
  display: flex;
  margin-top: 2px;
  flex-direction: row;
  font-weight: bold;
  font-size: ${theme.fontSize.s};
  line-height: 1.5;
`

export const ItemValue = styled(Txt).attrs({ size: 'm' })`
  flex: 1;
  align-items: center;
  line-height: 1.5;
  overflow-wrap: anywhere;
`

export type FormDueTime = Omit<DueTime, 'timestamp'> & { time: string }

export const DueTimeText: React.VFC<{ time: FormDueTime }> = ({ time }) => (
  <SmallTxt>
    {time.time}
    <SText>の</SText>
    {time.hour}:00
    <SText>まで</SText>
  </SmallTxt>
)

export const DueDurationText: React.VFC<{ duration: DueDuration }> = ({
  duration,
}) => (
  <SmallTxt>
    提出依頼
    <SText>から</SText>
    {duration.days}日後
    <SText>の</SText>
    {duration.hour}:00
    <SText>まで</SText>
  </SmallTxt>
)

export const Required = styled(RequireTxt).attrs({
  size: 's',
  children: '必須',
})`
  grid-area: required-area;
  display: flex;
  margin-top: 8px;
`

export const MediumTxt = styled(Txt).attrs({ size: 'm' })``
