import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { generatePath, useHistory } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Form } from '../RecInterviewTemplateNewPage/steps/formSchema'
import { useRecInterviewTemplateForm } from '../RecInterviewTemplateNewPage/steps/useRecInterviewTemplateForm'
import { toRecInterviewTemplateInputValue } from '../RecInterviewTemplateNewPage/useCreateRecInterviewTemplate'
import { useStep } from '../RecInterviewTemplateNewPage/useStep'
import { useUpdateRecInterviewTemplate } from './useUpdateRecInterviewTemplate'

export const useRecInterviewTemplateEditPage = (
  guid: string,
  initialForm: Form
) => {
  const history = useHistory()

  const moveToDetailPage = useCallback(
    () =>
      history.push(
        generatePath(INTERNAL_PATHS.recInterviewTemplate, { guid: guid })
      ),
    [history, guid]
  )

  const step = useStep()

  const form = useRecInterviewTemplateForm(initialForm)

  const { mutateAsync: updateRecInterviewTemplate } =
    useUpdateRecInterviewTemplate(guid)

  const onClickPrev = useCallback(() => {
    if (step.step === 'basicInfo') {
      moveToDetailPage()
      return
    }
    form.updateTemplate()
    form.clearErrors()
    step.prevStep()
  }, [form, step, moveToDetailPage])

  const onClickNext = useCallback(async () => {
    const isValid = await (async () => {
      switch (step.step) {
        case 'basicInfo':
          return await form.trigger(['allowRecordedVideo', 'due', 'name'])
        case 'intro':
          return await form.trigger('introMessage')
        case 'questions':
          return await form.trigger('questions')
        case 'outro':
          return await form.trigger('outroMessage')
        default:
          return true
      }
    })()
    if (!isValid) return

    if (step.step === 'questions' && !form.checkQuestionDuplicates()) return

    if (step.step === 'confirm') {
      try {
        await updateRecInterviewTemplate(
          toRecInterviewTemplateInputValue(form.template)
        )
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }
      toast('録画面接テンプレートを保存しました')
      moveToDetailPage()
      return
    }

    form.updateTemplate()
    step.nextStep()
  }, [form, step, updateRecInterviewTemplate, moveToDetailPage])

  return {
    step: step.step,
    onClickPrev,
    onClickNext,
    form,
  }
}
