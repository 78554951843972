import { useCallback, useMemo, useState, useEffect } from 'react'
import { SearchApplicantsRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import {
  SearchCondition,
  useApplicantsQueryParam,
  SelectionStatus,
  SelectionSubStatus,
} from '@/services/applicantService'
import { timestampToDate } from '@/services/bffService'
import { useSearchApplicants } from './useSearchApplicants'

export const OrderBy = SearchApplicantsRequest.OrderBy
export const pageSize = 100

const defaultSearchCondition: SearchCondition = {
  keywords: [],
  statuses: [
    SelectionStatus.SELECTION_STATUS_ARRIVAL,
    SelectionStatus.SELECTION_STATUS_IN_PROGRESS,
  ],
  subStatuses: [
    SelectionSubStatus.SELECTION_SUB_STATUS_NO_NEXT_SELECTION_STEP,
    SelectionSubStatus.SELECTION_SUB_STATUS_NO_REC_INTERVIEW_TEMPLATE,
    SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP,
    SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP_RESULT,
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_EXPIRED,
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_NOT_REQUESTED,
    SelectionSubStatus.SELECTION_SUB_STATUS_REC_INTERVIEW_WAITING_SUBMIT,
  ],
  notes: [],
  mailFailure: false,
}

/**
 * conditionに末尾スペースをtrimしたデータを渡すと、入力値の末尾スペースが自動で削除されてしまうので、リクエスト前に削除するための関数
 */
export const trimEmptyWordsCondition = (
  c: SearchCondition
): SearchCondition => {
  const keywords = c.keywords.filter((v) => v !== '')
  const notes = c.notes.filter((v) => v !== '')
  return {
    ...c,
    keywords,
    notes,
  }
}

export const useApplicantsPage = () => {
  const {
    setQueryParams,
    currentPageParam,
    orderByParam,
    searchConditionParam,
  } = useApplicantsQueryParam()

  // NOTE: `??` だと0が入ってきたときに0で設定されてしまう
  const [currentPage, setCurrentPage] = useState(currentPageParam || 1)

  const [orderBy, setOrderBy] = useState(
    orderByParam ?? OrderBy.ORDER_BY_REGISTER_TIME_DESC
  )

  const [condition, setCondition] = useState<SearchCondition>(
    searchConditionParam || defaultSearchCondition
  )

  const [actionOnCondition, setActionOnCondition] = useState<boolean>(false)

  const [selectedApplicantGuidMap, setSelectedApplicantGuidMap] = useState<
    Record<string, boolean>
  >({})

  const { data, isLoading } = useSearchApplicants({
    pageSize,
    page: currentPage,
    orderBy,
    condition: trimEmptyWordsCondition(condition),
  })

  const onChangeSearchCondition = useCallback((condition: SearchCondition) => {
    setCondition(condition)
    setCurrentPage(1)
  }, [])

  const onChangeKeywordForSidebar = useCallback(
    (keywords: string[]) => {
      // NOTE: ページ戻ってきたときには、currentPageが1になるのを回避
      if (condition.keywords.toString() === keywords.toString()) {
        return
      }
      setCondition((prev) => ({
        ...prev,
        keywords,
      }))
      setCurrentPage(1)
    },
    [condition]
  )

  const resetSearchCondition = useCallback(() => {
    setCondition(defaultSearchCondition)
    setCurrentPage(1)
  }, [])

  const totalLength = useMemo(() => data?.toObject().totalLength, [data])

  const pageCount = useMemo(() => {
    return (totalLength && Math.ceil(totalLength / pageSize)) ?? 0
  }, [totalLength])

  const applicants = useMemo(() => {
    if (data?.getApplicantsList() === undefined) return []

    return data?.toObject().applicantsList.map((applicant) => {
      const registerTime = timestampToDate(applicant.registerTime)
      const selected = !!selectedApplicantGuidMap[applicant.guid]
      return {
        ...applicant,
        selected,
        registerTime,
      }
    })
  }, [data, selectedApplicantGuidMap])

  const onRequestChangePage = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  const toggleOrderBy = useCallback(() => {
    setOrderBy((prev) =>
      prev === OrderBy.ORDER_BY_REGISTER_TIME_ASC
        ? OrderBy.ORDER_BY_REGISTER_TIME_DESC
        : OrderBy.ORDER_BY_REGISTER_TIME_ASC
    )
  }, [])

  const selectedApplicantsCount = useMemo(() => {
    return Object.values(selectedApplicantGuidMap).filter(Boolean).length
  }, [selectedApplicantGuidMap])

  const onSelectApplicant = useCallback((applicantGuid: string) => {
    setSelectedApplicantGuidMap((prev) => ({
      ...prev,
      [applicantGuid]: !prev[applicantGuid],
    }))
  }, [])

  const allApplicantsSelected = useMemo(() => {
    return data?.getApplicantsList().length === selectedApplicantsCount
  }, [data, selectedApplicantsCount])
  const onSelectAllApplicants = useCallback(() => {
    if (allApplicantsSelected) {
      setSelectedApplicantGuidMap({})
      return
    }

    const applicantsList = data?.toObject().applicantsList
    if (!applicantsList) {
      throw new Error('applicantsList is undefined')
    }

    const newMap = applicantsList.reduce<Record<string, boolean>>(
      (acc, current) => {
        acc[current.guid] = true
        return acc
      },
      {}
    )
    setSelectedApplicantGuidMap(newMap)
  }, [allApplicantsSelected, data])

  const onClearSelectApplicant = useCallback(() => {
    setSelectedApplicantGuidMap({})
  }, [])

  const onClearActionOnCondition = useCallback(() => {
    setSelectedApplicantGuidMap({})
    setActionOnCondition(false)
  }, [])

  const onActionOnCondition = useCallback(() => {
    setActionOnCondition(true)
  }, [])

  useEffect(() => {
    const cleanupSelected = () => {
      setSelectedApplicantGuidMap({})
      setActionOnCondition(false)
    }
    cleanupSelected()
  }, [data])

  useEffect(() => {
    if (!allApplicantsSelected) {
      setActionOnCondition(false)
    }
  }, [allApplicantsSelected])

  useEffect(() => {
    setQueryParams(currentPage, orderBy, condition)
  }, [currentPage, orderBy, condition, setQueryParams])

  return {
    applicants,
    isLoading,
    currentPage,
    pageCount,
    onRequestChangePage,
    actionOnCondition,
    onActionOnCondition,
    onClearActionOnCondition,
    selectedApplicantsCount,
    allApplicantsSelected,
    onSelectApplicant,
    onSelectAllApplicants,
    onClearSelectApplicant,
    orderBy,
    toggleOrderBy,
    totalLength,
    condition,
    onChangeSearchCondition,
    onChangeKeywordForSidebar,
    resetSearchCondition,
  }
}
