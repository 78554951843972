import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  Applicant,
  Selection,
  mapApplicant,
  mapSelection,
} from '@/services/applicantService'
import { useRequestGetApplicant } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useApplicant = () => {
  const { applicantGuid } = useParams<{ applicantGuid: string }>()

  const { data } = useGetApplicant(applicantGuid)

  const applicant = useMemo<Applicant | undefined>(() => {
    return mapApplicant(data?.getApplicant())
  }, [data])

  const registerTime = useMemo(() => data?.getRegisterTime()?.toDate(), [data])

  const selection = useMemo<Selection | undefined>(() => {
    return mapSelection(data?.getSelection())
  }, [data])

  const registrationRoute = useMemo(() => {
    if (data?.getAtsConnectName() === '') {
      return '手動登録'
    }
    return data?.getAtsConnectName()
  }, [data])

  const organizationRecInterviewTranscriptionSetting = useMemo<
    boolean | undefined
  >(() => {
    return data?.getOrganizationRecInterviewTranscriptionSetting()
  }, [data])

  return {
    applicantGuid,
    applicant,
    registerTime,
    selection,
    registrationRoute,
    organizationRecInterviewTranscriptionSetting,
  }
}

const useGetApplicant = (guid: string) => {
  const { requestGetApplicant } = useRequestGetApplicant()

  return useQuery([QUERY_KEY.applicant, guid], () => requestGetApplicant(guid))
}
