import { useCallback, useRef } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt, toast } from '@blue-agency/rogue'
import { theme } from '@blue-agency/rogue'
import { FormRow, TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useHistory, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { VideoSeminarContentForm } from '@/services/videoSeminarService'
import { VideoSeminarContentNewPageContainer } from './VideoSeminarContentNewPageContainer'
import { useCreateVideoSeminarContent } from './useCreateVideoSeminarContent'

type Props = {
  completedForm: VideoSeminarContentForm
}

export const ConfirmPage: React.VFC<Props> = ({ completedForm }) => {
  const history = useHistory()
  const {
    videoSeminarGuid,
    backToInput,
    moviePreviewURL,
    thumbnailPreviewURL,
  } = VideoSeminarContentNewPageContainer.useContainer()

  const videoRef = useRef<HTMLVideoElement>(null)

  const { mutateAsync } = useCreateVideoSeminarContent()

  const onSubmit = useCallback(async () => {
    let thumbnailImage = new Uint8Array()
    if (completedForm.thumbnailFile !== undefined) {
      const buf = await completedForm.thumbnailFile.arrayBuffer()
      thumbnailImage = new Uint8Array(buf)
    }

    const movieDurationSeconds = videoRef.current?.duration
    if (movieDurationSeconds === undefined) {
      throw new Error('movieDurationSeconds not found')
    }

    try {
      await mutateAsync({
        videoSeminarGuid,
        title: completedForm.title,
        description: completedForm.description,
        originalMovieKey: completedForm.movieKey,
        thumbnailImage,
        movieDurationSeconds,
      })
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    history.push(
      generatePath(INTERNAL_PATHS.videoSeminarContents, {
        videoSeminarGuid,
      })
    )
    toast('コンテンツを登録しました')
  }, [completedForm, mutateAsync, videoSeminarGuid, history])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={() => {}} onKeyDown={() => {}}>
        <Page.Header>
          <Page.HeaderTitle>コンテンツ登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容を確認">
            <FormWrapper>
              <Row title="タイトル">
                <Txt>{completedForm.title}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="動画">
                <video ref={videoRef} controls width="280">
                  <source src={moviePreviewURL} type="video/mp4" />
                </video>
              </Row>
              <WeakColorSeparator />
              <Row title="サムネイル画像">
                {thumbnailPreviewURL !== undefined && (
                  <img
                    width="280"
                    alt="サムネイル画像"
                    src={thumbnailPreviewURL}
                  />
                )}
              </Row>
              <WeakColorSeparator />
              <Row title="説明">
                <Txt>{completedForm.description}</Txt>
              </Row>
            </FormWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              onClick={backToInput}
              comlinkPushParams={{
                action:
                  'click_back_to_new_video_seminar_content_input_page_button_on_confirm_page',
              }}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              onClick={onSubmit}
              comlinkPushParams={{
                action: 'click_register_new_video_seminar_content_button',
              }}
            >
              登録
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const FormWrapper = styled.div`
  padding: 0 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '148px', subtitleWidth: '0' })`
  padding: 20px 0;
`
