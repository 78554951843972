import { StaffIcon } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { ControlCenterTooltip } from '@/pages/ControlCenterPage/ControlCenterTooltip'

type Role = 'interviewer' | 'interviewee'

type Props = {
  role: Role
  familyName: string
  fullName: string
  staffGuid: string
  iconColorCode: string
  joined: boolean
  tooltipId: string
}

export const Avatar: React.VFC<Props> = (props) => {
  return (
    <>
      <AvatarWrapper data-tip data-for={props.tooltipId}>
        {props.joined ? (
          <JoinedInterviewerIcon {...props} />
        ) : (
          <NotJoinedInterviewerIcon {...props} />
        )}
      </AvatarWrapper>
      <ControlCenterTooltip tooltipId={props.tooltipId} arrowPosition="topLeft">
        <Txt>{props.fullName}</Txt>
      </ControlCenterTooltip>
    </>
  )
}

const JoinedInterviewerIcon: React.VFC<{
  familyName: string
  iconColorCode: string
}> = (props) => {
  return <StaffIcon name={props.familyName} color={props.iconColorCode} />
}

const NotJoinedInterviewerIcon: React.VFC<{ familyName: string }> = (props) => {
  return (
    <StaffIcon
      name={props.familyName}
      color={theme.color.gray[4]}
      dashedBorder
      textColor={theme.color.gray[1]}
    />
  )
}

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
`
