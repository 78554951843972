import { isValid, parse } from 'date-fns'
import * as yup from 'yup'
import { assertNever } from '@/assertions'
import type { KeyTarget, MappingResult } from '../types'

export type Specified<T> =
  | {
      specified: true
      value: T
    }
  | {
      specified: false
    }

export const validationMessage = {
  webInterviewGUIDInput: 'Web面接IDは必須項目です。データを登録してください。',
  atsInterviewIDInput:
    '連携先Web面接IDは必須項目です。データを登録してください。',
  nameLength30: '面接名は30文字以下で登録してください。',
  scheduledStartTimeFormat:
    '予定日時は、以下の形式で登録してください。\n・2022/01/01 01:01\n・2022/01/01 01:01:01\n・2022-01-01 01:01\n・2022-01-01 01:01:01',
  durationInteger: '目安時間は1～180の半角数字で登録してください。',
  assignmentInteger:
    '担当設定は0~2の半角数字で登録してください。\n0：指定する、1：指定しない、2：指定しない（面接詳細の閲覧不可）',
  interviewerEmail: (input: string) =>
    `面接官のメールアドレス（${input}）が不正です。`,
  interviewersUnSpecified:
    '面接官を指定する場合は、担当設定を「0」に設定してください。',
  viewerEmail: (input: string) =>
    `閲覧者のメールアドレス（${input}）が不正です。`,
  viewersUnSpecified:
    '閲覧者を指定する場合は、担当設定を「0」に設定してください。',
  staffEmailDuplicate:
    '面接官、閲覧者のいずれかのメールアドレスが取込ファイル内で重複しています。',
  // Mappingの仕様により本来発生し得ないエラーパターン
  interviewersLength10: '面接官は10名以下で指定してください。',
  viewersLength10: '閲覧者は10名以下で指定してください。',
  accessFailure: '必要なデータを取得できません。',
}

// 0: 指定する, 1: 指定しない, 2: 指定しない（面接詳細の閲覧不可）
export type Assignment = 0 | 1 | 2

export type WebInterviewUpdate = {
  key: string
  name?: string
  durationMinutes?: Specified<number>
  scheduledStartTime?: Specified<Date>
  webInterviewGuideGuid?: Specified<string>
  assignment?: Assignment
  interviewers: string[]
  viewers: string[]
}

export const buildWebInterviewUpdate = async (
  row: string[],
  mapping: MappingResult
): Promise<WebInterviewUpdate> => {
  const webInterviewUpdateInput = {
    key: getByIndex(row, mapping.key),
    name: buildName(row, mapping),
    durationMinutes: buildDurationMinutes(row, mapping),
    scheduledStartTime: buildScheduledStartTime(row, mapping),
    webInterviewGuideGuid: buildWebInterviewGuideGUID(row, mapping),
    assignment: buildAssignment(row, mapping),
    interviewers: buildInterviewers(row, mapping),
    viewers: buildViewers(row, mapping),
  }

  const schema = buildSchema(mapping.keyType)

  const validatedWebInterviewUpdate = await schema.validate(
    {
      ...webInterviewUpdateInput,
    },
    { abortEarly: false }
  )

  return validatedWebInterviewUpdate
}

export const buildSchema = (keyTarget: KeyTarget) => {
  let keyInputValidationMessage: string
  switch (keyTarget) {
    case 'web-interview-guid':
      keyInputValidationMessage = validationMessage.webInterviewGUIDInput
      break
    case 'ats-interview-id':
      keyInputValidationMessage = validationMessage.atsInterviewIDInput
      break
    default:
      assertNever(keyTarget)
  }

  return yup
    .object({
      key: yup.string().required(keyInputValidationMessage),
      name: nameSchema,
      durationMinutes: durationMinutesSchema,
      scheduledStartTime: scheduledStartTimeSchema,
      webInterviewGuideGuid: webInterviewGuideGUIDSchema,
      assignment: assignmentSchema,
      interviewers: interviewersSchema,
      viewers: viewersSchema,
    })
    .test({
      name: '担当設定が「指定しない」の場合、面接官は指定されていない',
      message: validationMessage.interviewersUnSpecified,
      test: (value) => {
        if (value.assignment === 1 || value.assignment === 2) {
          const interviewers = value.interviewers ?? []
          if (interviewers.length > 0) {
            return false
          }
        }
        return true
      },
    })
    .test({
      name: '担当設定が「指定しない」の場合、閲覧者は指定されていない',
      message: validationMessage.viewersUnSpecified,
      test: (value) => {
        if (value.assignment === 1 || value.assignment === 2) {
          const viewers = value.viewers ?? []
          if (viewers.length > 0) {
            return false
          }
        }
        return true
      },
    })
    .test({
      name: '担当設定が「指定しない」でない場合、面接官の最大設定数は10件である',
      message: validationMessage.interviewersLength10,
      test: (value) => {
        if (value.assignment === undefined || value.assignment === 0) {
          const interviewers = value.interviewers ?? []
          if (interviewers.length > 10) {
            return false
          }
        }
        return true
      },
    })
    .test({
      name: '担当設定が「指定しない」でない場合、閲覧者の最大設定数は10件である',
      message: validationMessage.viewersLength10,
      test: (value) => {
        if (value.assignment === undefined || value.assignment === 0) {
          const viewers = value.viewers ?? []
          if (viewers.length > 10) {
            return false
          }
        }
        return true
      },
    })
    .test({
      name: '面接官と閲覧者のメールアドレスが重複していない',
      message: validationMessage.staffEmailDuplicate,
      test: (value) => {
        const interviewers = value.interviewers ?? []
        const viewers = value.viewers ?? []
        const emailSet = new Set([...interviewers, ...viewers])
        if (emailSet.size < interviewers.length + viewers.length) {
          return false
        }
        return true
      },
    })
}

// MappingResultで指定されたindexが範囲外である場合にエラーハンドリングされるように、Errorをthrowする
const getByIndex = (row: string[], index: number): string => {
  const res = row[index]
  if (res === undefined) {
    // yup の validate に合わせるために errors プロパティを持つオブジェクトを throw する
    // eslint-disable-next-line no-throw-literal
    throw {
      errors: [validationMessage.accessFailure],
    }
  } else {
    return res
  }
}

const buildName = (
  row: string[],
  mapping: MappingResult
): string | undefined => {
  return mapping.name === undefined ? undefined : getByIndex(row, mapping.name)
}

const nameSchema = yup.string().max(30, validationMessage.nameLength30)

const buildDurationMinutes = (
  row: string[],
  mapping: MappingResult
): Specified<number | undefined> | undefined => {
  if (mapping.duration === undefined) {
    return undefined
  }
  const str = getByIndex(row, mapping.duration)

  if (str === '') {
    return { specified: false }
  }

  const durationMinutes = parseFloat(str)
  if (Number.isNaN(durationMinutes)) {
    // 数字としてパースできなかったケース
    // yupのバリデーションエラーに集約されるように、invalidな値を入れておく
    return { specified: true, value: undefined }
  }
  return { specified: true, value: durationMinutes }
}

const durationMinutesSchema = yup.lazy(
  (value: Specified<number> | undefined) => {
    if (
      value === undefined ||
      value.specified === undefined ||
      typeof value.specified !== 'boolean'
    ) {
      return yup.mixed<undefined>()
    }

    if (value.specified) {
      return yup.object({
        specified: yup.mixed<true>().required(),
        value: yup
          .number()
          // integerやmin,maxとの組み合わせでも条件を表現できるが、0.1のときに複数のエラーを返してしまうため、testで1つにまとめている
          .test({
            name: '1以上180以下の整数である',
            message: validationMessage.durationInteger,
            test: (val) =>
              val === undefined ||
              (typeof val === 'number' &&
                Number.isInteger(val) &&
                1 <= val &&
                val <= 180),
          })
          .required(validationMessage.durationInteger),
      })
    } else {
      return yup.object({
        specified: yup.mixed<false>().required(),
      })
    }
  }
)

const buildScheduledStartTime = (
  row: string[],
  mapping: MappingResult
): Specified<Date | undefined> | undefined => {
  if (mapping['scheduled-start-time'] === undefined) {
    return undefined
  }
  const str = getByIndex(row, mapping['scheduled-start-time'])

  if (str === '') {
    return { specified: false }
  }

  // date-fnsのパースでは、例えば 22-1-2 3:4:5のようなフォーマットが通過し、0022/01/02 03:04:05になってしまう
  // 対策として、受け入れるべきフォーマットを正規表現で指定するようにしている
  const formats = [
    {
      regexp: new RegExp('^\\d{4}/\\d{1,2}/\\d{1,2} \\d{1,2}:\\d{1,2}$'),
      dateFormat: 'yyyy/MM/dd HH:mm',
    },
    {
      regexp: new RegExp(
        '^\\d{4}/\\d{1,2}/\\d{1,2} \\d{1,2}:\\d{1,2}:\\d{1,2}$'
      ),
      dateFormat: 'yyyy/MM/dd HH:mm:ss',
    },
    {
      regexp: new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2} \\d{1,2}:\\d{1,2}$'),
      dateFormat: 'yyyy-MM-dd HH:mm',
    },
    {
      regexp: new RegExp(
        '^\\d{4}-\\d{1,2}-\\d{1,2} \\d{1,2}:\\d{1,2}:\\d{1,2}$'
      ),
      dateFormat: 'yyyy-MM-dd HH:mm:ss',
    },
  ]

  for (const format of formats) {
    if (!format.regexp.test(str)) {
      // regexpにマッチしない場合には、対象のformatでの変換は失敗とみなす
      continue
    }

    const date = parse(str, format.dateFormat, new Date(0, 1))
    if (isValid(date)) {
      return { specified: true, value: date }
    }
  }

  // すべてのフォーマットでのパースに失敗したため、パース失敗したパターン
  // yupのバリデーションエラーに集約されるように、invalidな値を返しておく
  return { specified: true, value: undefined }
}

const scheduledStartTimeSchema = yup.lazy(
  (value: Specified<Date> | undefined) => {
    if (
      value === undefined ||
      value.specified === undefined ||
      typeof value.specified !== 'boolean'
    ) {
      return yup.mixed<undefined>()
    }

    if (value.specified) {
      return yup.object({
        specified: yup.mixed<true>().required(),
        value: yup.date().required(validationMessage.scheduledStartTimeFormat),
      })
    } else {
      return yup.object({
        specified: yup.mixed<false>().required(),
      })
    }
  }
)

const buildWebInterviewGuideGUID = (
  row: string[],
  mapping: MappingResult
): Specified<string> | undefined => {
  if (mapping['interview-guide-guid'] === undefined) {
    return undefined
  }
  const str = getByIndex(row, mapping['interview-guide-guid'])

  if (str === '') {
    return { specified: false }
  }

  return { specified: true, value: str }
}

const webInterviewGuideGUIDSchema = yup.lazy(
  (value: Specified<string> | undefined) => {
    if (
      value === undefined ||
      value.specified === undefined ||
      typeof value.specified !== 'boolean'
    ) {
      return yup.mixed<undefined>()
    }

    if (value.specified) {
      return yup.object({
        specified: yup.mixed<true>().required(),
        value: yup.string().required(),
      })
    } else {
      return yup.object({
        specified: yup.mixed<false>().required(),
      })
    }
  }
)

const buildAssignment = (
  row: string[],
  mapping: MappingResult
): number | undefined => {
  if (mapping.assignment === undefined) {
    return undefined
  }
  const str = getByIndex(row, mapping.assignment)
  if (str === '') {
    // assignmentが空文字列でありパースできないパターン
    // yupのバリデーションエラーに集約されるように、invalidな値を返しておく
    return -1
  }

  const assignment = parseFloat(str)

  if (Number.isNaN(assignment)) {
    // assignmentの内容のパースに失敗したパターン
    // yupのバリデーションエラーに集約されるように、invalidな値を返しておく
    return -1
  }

  return assignment
}

const assignmentSchema = yup
  .mixed<Assignment>()
  .oneOf([0, 1, 2], validationMessage.assignmentInteger)

const buildInterviewers = (row: string[], mapping: MappingResult): string[] => {
  if (mapping.interviewer === undefined) {
    return []
  }

  return (
    mapping.interviewer
      .map((index) => getByIndex(row, index))
      // 個々ごとに異なる面接官数を指定できるように、空文字列を許容して、トルツメする
      .filter((item) => {
        return item !== ''
      })
  )
}

const interviewersSchema = yup
  .array()
  .of(
    yup
      .string()
      .email((props) => validationMessage.interviewerEmail(props.originalValue))
      .required()
  )
  .required()

const buildViewers = (row: string[], mapping: MappingResult): string[] => {
  if (mapping.viewer === undefined) {
    return []
  }

  return (
    mapping.viewer
      .map((index) => getByIndex(row, index))
      // 個々ごとに異なる閲覧者数を指定できるように、空文字列を許容して、トルツメする
      .filter((item) => item !== '')
  )
}

const viewersSchema = yup
  .array()
  .of(
    yup
      .string()
      .email((props) => validationMessage.viewerEmail(props.originalValue))
      .required()
  )
  .required()
