import {
  GetWebInterviewResponse,
  WebInterviewStatus,
} from '@blue-agency/proton/biz_hutt_bff'
import { DeletedStatus } from './panelComponents/DeletedStatus'
import { EncodedStatus } from './panelComponents/EncodedStatus'
import { EncodingStatus } from './panelComponents/EncodingStatus'
import { ExpiredStatus } from './panelComponents/ExpiredStatus'
import { FailedStatus } from './panelComponents/FailedStatus'
import { FinishedForNormal } from './panelComponents/FinishedAndNotPermitted'
import { NotInterviewedStatus } from './panelComponents/NotInterviewedStatus'

type Props = {
  webInterviewStatus: WebInterviewStatus
  recordingStatus: GetWebInterviewResponse.RecordingStatus
  recordingUrl: string
}

export function StatusPanel({
  webInterviewStatus,
  recordingStatus,
  recordingUrl,
}: Props) {
  if (webInterviewStatus === WebInterviewStatus.EXPIRED) {
    return <ExpiredStatus />
  }

  const { RecordingStatus } = GetWebInterviewResponse

  // 録画再生が許可されてないとき、recordingUrl が空
  if (recordingStatus === RecordingStatus.ENCODED && recordingUrl === '') {
    return <FinishedForNormal />
  }

  switch (recordingStatus) {
    case RecordingStatus.NOT_INTERVIEWED:
      return <NotInterviewedStatus />
    case RecordingStatus.ENCODING:
      return <EncodingStatus />
    case RecordingStatus.ENCODED:
      return <EncodedStatus />
    case RecordingStatus.FAILED:
      return <FailedStatus />
    case RecordingStatus.DELETED:
      return <DeletedStatus />
    case RecordingStatus.RECORDING_STATUS_UNKNOWN:
      return null
  }
}
