import { useCallback } from 'react'
import { WeakColorSeparator, Modal, Dropdown, Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { SecondaryLink } from '@/components/Link'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useChangeWebInterviewGuideForm } from './useChangeWebInterviewGuideForm'

type Props = {
  active: boolean
  onClose: () => void
}

export function ChangeWebInterviewGuideModal({ active, onClose }: Props) {
  const { submit, register, isSubmitting, guideOptions } =
    useChangeWebInterviewGuideForm()
  const { permittedRpcs } = AuthzContainer.useContainer()

  const onSubmit = useCallback(async () => {
    await submit()
    onClose()
  }, [onClose, submit])

  return (
    <Modal
      active={active}
      title="面接ガイドの編集"
      size="l"
      onClose={onClose}
      stopScroll
    >
      <ModalBody>
        <Row>
          <Label>ガイド</Label>
          <Dropdown
            {...register('guideGuid')}
            size="ll"
            options={guideOptions}
            allowEmpty
            placeholder="未設定"
          />
        </Row>
        <Row>
          <LinkToGuideCreationWrapper
            visible={permittedRpcs?.includes('CreateWebInterviewGuide')}
          >
            <Txt>
              <SecondaryLink
                to={INTERNAL_PATHS.newWebInterviewGuide}
                comlinkPushParams={{
                  action:
                    'click_new_web_interview_guide_link_on_change_web_interview_guide_modal',
                }}
              >
                新しいガイドの追加はこちら
              </SecondaryLink>
            </Txt>
          </LinkToGuideCreationWrapper>
        </Row>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_change_web_interview_guide_button',
          }}
        >
          キャンセル
        </TertiaryButton>
        <PrimaryButton
          widthSize="L1"
          disabled={isSubmitting}
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_save_change_web_interview_guide_button',
          }}
        >
          保存
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 18px 0 60px 0;
`

const Row = styled.div`
  display: flex;
  padding: 0 20px 0 20px;
`

const LinkToGuideCreationWrapper = styled.div<{ visible?: boolean }>`
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 16px 70px;

  ${(props) => {
    // 面接ガイド作成ページへのリンクがある場合/ない場合でモーダルの高さが変わらないようにするため、visibilityで表示を制御している
    if (!props.visible) {
      return 'visibility: hidden'
    }
  }}
`

const Label = styled(Txt).attrs({ size: 'm' })`
  width: 135px;
  line-height: 32px;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > :not(:first-child) {
    margin-left: 20px;
  }
`
