import { ComponentProps } from 'react'
import { SecondaryLinkTxt, withComlinkPushParams } from '@blue-agency/rogue'
import { Link as _Link } from 'react-router-dom'

const LinkWithComlink = withComlinkPushParams(_Link)
type LinkProps = ComponentProps<typeof LinkWithComlink> &
  Pick<ComponentProps<typeof SecondaryLinkTxt>, 'size'>

export const SecondaryLink: React.FC<LinkProps> = ({
  children,
  size,
  ...linkProps
}) => (
  <LinkWithComlink {...linkProps}>
    <SecondaryLinkTxt size={size}>{children}</SecondaryLinkTxt>
  </LinkWithComlink>
)
