import { Txt, theme, Icon } from '@blue-agency/rogue'
import styled from 'styled-components'

export const AppendButton: React.VFC<{
  disabled: boolean
  onClick: () => void
}> = ({ disabled, onClick }) => {
  return (
    <Wrapper onClick={onClick} disabled={disabled}>
      <Txt
        color={disabled ? `${theme.color.gray[2]}` : `${theme.color.navy[1]}`}
      >
        <AppendIcon />
        パートを追加
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
`

const AppendIcon = styled(Icon).attrs({ name: 'plus-circle' })`
  margin-right: 5px;
  position: relative;
  top: 5px;
`
