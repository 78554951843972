import type { CsvBody, KeyTarget, MappingResult } from '../types'
import {
  buildWebInterviewUpdate,
  WebInterviewUpdate,
} from './WebInterviewUpdate'

export type DetailValidationError = {
  rowNumber: number
  message: string
}

export type Response = ErrorResponse | ValidResponse
type ErrorResponse = {
  type: 'error'
  errors: DetailValidationError[]
}
type ValidResponse = {
  type: 'valid'
  webInterviewUpdates: WebInterviewUpdate[]
  keyType: KeyTarget
}

export type Option = {
  // この件数以上エラーがあるときは validation を中止する
  errorLimit: number
}

export async function validateDetail(
  body: CsvBody,
  mapping: MappingResult,
  option?: Option
): Promise<Response> {
  const validationErrors: DetailValidationError[] = []
  const webInterviewUpdates: WebInterviewUpdate[] = []

  // Key(Web面接IDや連携先Web面接ID)が重複しているかを事前計算しておく。
  // 与えるkey一覧に対して、仮に取得失敗してundefinedであっても取り除いて無視する。
  // 後のバリデーション処理にて、取得に失敗する場合にはバリデーションエラーになるようになっている。
  const checkDuplicateKey = generateDuplicateKeyChecker(
    body
      .map((row) => {
        return row[mapping.key]
      })
      .filter((item) => item !== undefined)
  )

  for (let bodyIndex = 0; bodyIndex < body.length; bodyIndex++) {
    const rowNumber = bodyIndex + 1
    const row = body[bodyIndex]
    if (
      option?.errorLimit !== undefined &&
      validationErrors.length >= option.errorLimit
    ) {
      break
    }

    try {
      const webInterviewUpdate = await buildWebInterviewUpdate(row, mapping)

      if (webInterviewUpdate === undefined) {
        throw new Error('エラーが発生しました')
      }

      if (checkDuplicateKey(webInterviewUpdate.key)) {
        // yup の validate に合わせるために errors プロパティを持つオブジェクトを throw する
        // eslint-disable-next-line no-throw-literal
        throw {
          errors: [
            `${keyLabel(mapping.keyType)}が取込ファイル内で重複しています。`,
          ],
        }
      }

      webInterviewUpdates.push(webInterviewUpdate)
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-4.html#defaulting-to-the-unknown-type-in-catch-variables---useunknownincatchvariables

      if (e.errors) {
        for (const error of e.errors) {
          validationErrors.push({
            rowNumber,
            message: error,
          })
        }
      } else {
        // yup の validate 以外の Error のときは throw する
        throw e
      }
    }
  }

  if (validationErrors.length >= 1) {
    return {
      type: 'error',
      errors: validationErrors,
    }
  }

  return {
    type: 'valid',
    webInterviewUpdates,
    keyType: mapping.keyType,
  }
}

const generateDuplicateKeyChecker = (keys: string[]) => {
  const keyCount = keys.reduce<Record<string, number>>((pre, key) => {
    return {
      ...pre,
      [key]: (pre[key] ?? 0) + 1,
    }
  }, {})

  return (key: string) => {
    return (keyCount[key] ?? 0) > 1
  }
}

export const keyLabel: (keyType: KeyTarget) => string = (keyType) => {
  switch (keyType) {
    case 'web-interview-guid':
      return 'Web面接ID'
    case 'ats-interview-id':
      return '連携先Web面接ID'
  }
}
