import { useCallback, useMemo, useRef } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  ClickOutsideListener,
  Icon,
  theme,
  toast,
  Txt,
} from '@blue-agency/rogue'
import { Tooltip as T } from '@blue-agency/rogue/dist/im'
import { captureException } from '@sentry/minimal'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Box } from '@/components/Box'
import { useModal } from '@/hooks/useModal'
import { useToggle } from '@/hooks/useToggle'
import {
  getNextSelectionStatuses,
  getSelectionStatusTransitionText,
  getSelectionStatusColor,
  getSelectionStatusText,
  getSelectionSubStatusText,
  Applicant,
  Selection,
  SelectionStatus,
  NextAction,
  isSelectionFinished,
} from '@/services/applicantService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { NextActionFn } from '../useNextAction'
import { NextActionButton } from './NextActionButton'
import { SelectionStep } from './SelectionStep'
import { ShareSelectionModal } from './ShareSelectionModal'
import { useUpdateSelectionSharingType } from './useUpdateSelectionSharingType'
import { useUpdateSelectionStatus } from './useUpdateSelectionStatus'

// 姓名間のスペース含む最大の表示氏名文字数
const maxNameLength = 9
const maxNameKanaLength = 18

type Props = PropsWithClassName<{
  applicantGuid: string
  applicant: Applicant
  selection: Selection
  nextAction: NextAction
  nextActionFn: NextActionFn
}>
export const Header: React.VFC<Props> = (props) => {
  const applicant = props.applicant
  const name = `${applicant.familyName} ${applicant.givenName}`
  const nameKana = `${applicant.familyNameKana} ${applicant.givenNameKana}`
  const shareSelectionModal = useModal()
  const [isSharing, toggle] = useToggle(props.selection.isSharing)

  const menu = useModal()
  const menuRef = useRef<HTMLDivElement>(null)
  const { mutateAsync: updateSelectionStatus } = useUpdateSelectionStatus(
    props.applicantGuid
  )

  const nextStatuses = getNextSelectionStatuses(
    props.selection.status,
    props.selection.selectionStepsList
  )

  const selectionNames = useMemo(
    () =>
      props.selection.selectionStepsList
        .sort((a, b) => a.sequence - b.sequence)
        .map((s) => s.name),
    [props.selection]
  )

  const currentStepIndex = useMemo(() => {
    const currentStep = props.selection.currentSelectionStepGuid
    const currentSequence = props.selection.selectionStepsList.find(
      (s) => s.guid === currentStep
    )?.sequence
    if (currentSequence === undefined) return 0

    // TODO: 現状はsequenceが1originの前提になっているので、実際のレスポンスの仕様が確定したら修正
    return currentSequence - 1
  }, [props.selection])

  const currentStepName = useMemo(() => {
    const currentStep = props.selection.currentSelectionStepGuid
    return props.selection.selectionStepsList.find(
      (s) => s.guid === currentStep
    )?.name
  }, [props.selection])

  const onClickMenuItem = useCallback(
    async (status: SelectionStatus) => {
      try {
        await updateSelectionStatus({
          guid: props.selection.guid,
          status,
        })
      } catch (e) {
        captureException(e)
        commonErrorToast()
        return
      }
      toast('最終結果を保存しました')
      menu.close()
    },
    [menu, props.selection, updateSelectionStatus]
  )

  const onClickMenuIcon = useCallback(() => {
    menu.active ? menu.close() : menu.open()
  }, [menu])

  const { mutateAsync: toggleSelectionSharigType } =
    useUpdateSelectionSharingType(props.applicantGuid)
  const changeSharingType = useCallback(async () => {
    try {
      await toggleSelectionSharigType({
        selectionGuid: props.selection.guid,
        isSharing: !isSharing,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toggle()
  }, [props.selection, isSharing, toggle, toggleSelectionSharigType])

  return (
    <Wrapper className={props.className}>
      <NameArea>
        <Name data-tip data-for={TOOLTIP_ID_MAP.applicantDetailName}>
          {ellipsizeName(name, maxNameLength)}
        </Name>
        {name.length > maxNameLength && (
          <Tooltip
            id={TOOLTIP_ID_MAP.applicantDetailName}
            arrowPosition="topLeft"
          >
            <TooltipTxt>{name}</TooltipTxt>
          </Tooltip>
        )}
        <NameKana
          data-tip
          data-for={TOOLTIP_ID_MAP.applicantDetailNameKana}
          color={theme.color.gray[1]}
        >
          {ellipsizeName(nameKana, maxNameKanaLength)}
        </NameKana>
        {nameKana.length > maxNameLength && (
          <Tooltip
            id={TOOLTIP_ID_MAP.applicantDetailNameKana}
            arrowPosition="topLeft"
          >
            <TooltipTxt>{nameKana}</TooltipTxt>
          </Tooltip>
        )}
        <Box mt="20px" display="flex" alignItems="center">
          <Dot color={getSelectionStatusColor(props.selection.status)} />
          <Box ml="4px">
            <Txt>{getSelectionStatusText(props.selection.status)}</Txt>
          </Box>
        </Box>
        <Box mt="6px">
          <Txt color={theme.color.gray[1]}>
            {getSelectionSubStatusText(props.selection.subStatus)}
          </Txt>
        </Box>
      </NameArea>
      {selectionNames.length > 0 && (
        <SelectionStepArea>
          <SelectionStep
            stepNames={selectionNames}
            currentIndex={currentStepIndex}
            isSelectionFinished={isSelectionFinished(props.selection.status)}
          />
        </SelectionStepArea>
      )}
      <MenuArea>
        <NextActionButton
          nextAction={props.nextAction}
          onClick={props.nextActionFn}
        />
        <SubMenuArea>
          <ShareArea onClick={shareSelectionModal.open}>
            <ShareIcon />
            <Txt>共有</Txt>
          </ShareArea>
          {nextStatuses.length > 0 && (
            <>
              <VerticalSeparator />
              <ClickOutsideListener targetRef={menuRef} onClick={menu.close}>
                <SubMenuWrapper ref={menuRef}>
                  <MenuIcon onClick={onClickMenuIcon} />
                  {menu.active && (
                    <Menu>
                      {nextStatuses.map((status, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => onClickMenuItem(status)}
                        >
                          <Txt>{getSelectionStatusTransitionText(status)}</Txt>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </SubMenuWrapper>
              </ClickOutsideListener>
            </>
          )}
        </SubMenuArea>
      </MenuArea>
      {shareSelectionModal.active && (
        <ShareSelectionModal
          stepName={currentStepName}
          stepGuid={props.selection.currentSelectionStepGuid}
          isSharing={isSharing}
          changeSharingType={changeSharingType}
          close={shareSelectionModal.close}
        />
      )}
    </Wrapper>
  )
}

const ellipsizeName = (name: string, maxLength: number) => {
  return name.length > maxLength ? name.slice(0, maxLength) + '...' : name
}

const Wrapper = styled.div`
  display: grid;
  grid-template: 'name selection-step menu' auto / 260px 1fr auto;
  padding: 20px 20px 20px 30px;
  background-color: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[4]};
`

const NameArea = styled.div`
  grid-area: name;
`

const Name = styled(Txt).attrs({ size: 'xxl' })``

const NameKana = styled(Txt).attrs({ size: 'm' })``

const Tooltip = styled(T)`
  max-width: 70%;
`

const TooltipTxt = styled(Txt).attrs({ size: 'm' })``

const SelectionStepArea = styled.div`
  grid-area: selection-step;
`

const MenuArea = styled.div`
  grid-area: menu;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
`

const MenuIcon = styled(Icon).attrs({ name: 'more-horizontal' })`
  cursor: pointer;
`

const Menu = styled.div`
  position: absolute;
  top: 24px;
  right: -8px;
  width: 200px;
  border-radius: 0 0 4px 4px;
  background-color: ${theme.color.white[1]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 1;
`

const MenuItem = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const SubMenuArea = styled.div`
  display: flex;
  margin-top: auto;
`

const ShareArea = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ShareIcon = styled(Icon).attrs({ name: 'share', size: 'm' })`
  margin-right: 4px;
`

const VerticalSeparator = styled.div`
  border-left: 1px solid ${theme.color.navy[2]};
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 18px;
  margin: 0 16px 0 16px;
`

const SubMenuWrapper = styled.div`
  position: relative;
`
