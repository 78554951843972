import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  src: string
}>
export const HlsVideo: React.VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) return

    if (Hls.isSupported()) {
      const hls = new Hls({ xhrSetup: (xhr) => (xhr.withCredentials = true) })
      hls.loadSource(props.src)
      hls.attachMedia(videoRef.current)
      return () => {
        hls.removeAllListeners()
        hls.stopLoad()
      }
    } else {
      videoRef.current.src = props.src
    }
  }, [props])

  return (
    <video
      ref={videoRef}
      className={props.className}
      controls
      disablePictureInPicture
      disableRemotePlayback
    />
  )
}
