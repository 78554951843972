import * as yup from 'yup'
import {
  selectionStepResultKeys,
  SelectionStepResultKey,
} from '@/services/applicantService'

export type SelectionStepResultForm = {
  result: SelectionStepResultKey
  nextSelectionStepMasterGuid?: string
  comment?: string
  recInterviewTemplateGuid?: string
}

export type SelectionStepResultCommentForm = {
  comment?: string
}

const commentSchema = yup.string().max(2000, '2000文字以内で入力してください')

export const selectionStepResultFormSchema: yup.SchemaOf<SelectionStepResultForm> =
  yup
    .object({
      result: yup
        .mixed<SelectionStepResultKey>()
        .oneOf(selectionStepResultKeys)
        .required(),
      nextSelectionStepMasterGuid: yup.string(),
      comment: commentSchema,
      recInterviewTemplateGuid: yup.string(),
    })
    .defined()

export const selectionStepResultCommentFormSchema: yup.SchemaOf<SelectionStepResultCommentForm> =
  yup
    .object({
      comment: commentSchema,
    })
    .defined()
