import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListSelectionStepMastersRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from './metadata'

export function useRequestListSelectionStepMasters() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListSelectionStepMasters = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new ListSelectionStepMastersRequest()

    return callBizHuttBffService(
      'listSelectionStepMasters',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return { requestListSelectionStepMasters }
}
