import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateSelectionStepMasterRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SelectionStepMaster } from '@blue-agency/proton/im'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateSelectionStepMasterRequestValue = SelectionStepMaster.AsObject

export function useRequestUpdateSelectionStepMaster() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateSelectionStepMaster = useCallback(
    async (value: UpdateSelectionStepMasterRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateSelectionStepMasterRequest()

      const s = new SelectionStepMaster()
      s.setGuid(value.guid)
      s.setName(value.name)
      s.setType(value.type)
      req.setSelectionStepMaster(s)

      return callBizHuttBffService(
        'updateSelectionStepMaster',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateSelectionStepMaster,
  }
}
