import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateSelectionStepMaster,
  UpdateSelectionStepMasterRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateSelectionStepMaster() {
  const queryClient = useQueryClient()
  const { requestUpdateSelectionStepMaster } =
    useRequestUpdateSelectionStepMaster()

  return useMutation(
    (value: UpdateSelectionStepMasterRequestValue) =>
      requestUpdateSelectionStepMaster(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.selectionStepMasters)
      },
    }
  )
}
