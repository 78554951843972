import { theme, Icon } from '@blue-agency/rogue'
import styled from 'styled-components'
import type { SelectionStepPageProps } from '..'
import { AnswerVideoPreview as A } from './AnswerVideoPreview'
import { useAnswerVideoPreview } from './AnswerVideoPreview/useAnswerVideoPreview'
import { Content as C } from './Content'
import { FooterTabs } from './FooterTabs'
import { useTabs } from './useTabs'

export const SpRoot: React.VFC<SelectionStepPageProps> = (props) => {
  const tabsCtx = useTabs()
  const answerVideoPreviewCtx = useAnswerVideoPreview()
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <AnswerVideoPreview
        // NOTE: showはstyledで追加したprops
        show={
          tabsCtx.selectedTab !== 'selection' && answerVideoPreviewCtx.isPlaying
        }
        videoRef={answerVideoPreviewCtx.videoRef}
        content={answerVideoPreviewCtx.content}
      />
      <Content
        selectedTab={tabsCtx.selectedTab}
        applicantGuid={props.applicantGuid}
        applicant={props.applicant}
        registerTime={props.registerTime}
        targetSelectionStep={props.targetSelectionStep}
        changePlayingAnswerVideo={
          answerVideoPreviewCtx.changePlayingAnswerVideo
        }
        organizationRecInterviewTranscriptionSetting={
          props.organizationRecInterviewTranscriptionSetting
        }
      />
      <Footer selectedTab={tabsCtx.selectedTab} onClick={tabsCtx.switchTab} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const LogoWrapper = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: ${theme.color.white[1]};
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
`

const Logo = styled(Icon).attrs({
  name: 'logo3',
  width: '120px',
  height: '25px',
})``

const Content = styled(C)`
  flex: 1;
  padding-bottom: 40px;
`

const Footer = styled(FooterTabs)`
  position: fixed;
  bottom: 0;
  width: 100%;
`

const AnswerVideoPreview = styled(A)<{ show: boolean }>`
  position: ${({ show }) => (show ? 'sticky' : 'absolute')};
  top: 0;
  z-index: 1;
`
