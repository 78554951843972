import { useEffect, useRef } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { ErrorBox, theme } from '@blue-agency/rogue'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { Link as _Link, generatePath } from 'react-router-dom'
import { usePrevious } from 'react-use'
import styled, { css } from 'styled-components'
import { Box } from '@/components/Box'
import { INTERNAL_PATHS } from '@/services/urlService'
import { InputBody } from './InputBody'
import { VideoSeminarContentNewPageContainer } from './VideoSeminarContentNewPageContainer'

export const InputPage = () => {
  const { videoSeminarGuid, completeInput, form, errorsOnTop } =
    VideoSeminarContentNewPageContainer.useContainer()

  const prevErrorsOnTop = usePrevious(errorsOnTop)
  const topErrorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (topErrorRef.current === null) return
    if (prevErrorsOnTop === undefined) return

    // フォーム上部に表示するタイプのエラーの表示数が増加したとき、エラーに気づいてもらうためにスクロールする
    if (errorsOnTop.length > prevErrorsOnTop.length) {
      topErrorRef.current.scrollIntoView()
    }
  }, [errorsOnTop, prevErrorsOnTop])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Page.Header>
          <Page.HeaderTitle>コンテンツ登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          {errorsOnTop.length > 0 && (
            <Box
              paddingY="20px"
              backgroundColor={theme.color.white[1]}
              ref={topErrorRef}
            >
              <ErrorBox>
                <TopErrors hasMultiple={errorsOnTop.length >= 2}>
                  {errorsOnTop.map((e) => (
                    <li key={e.field}>{e.message}</li>
                  ))}
                </TopErrors>
              </ErrorBox>
            </Box>
          )}
          <InputBody />
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink
              to={generatePath(INTERNAL_PATHS.videoSeminarContents, {
                videoSeminarGuid,
              })}
            >
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action:
                    'click_cancel_register_video_seminar_content_button_on_input_page',
                }}
              >
                キャンセル
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              onClick={async () => {
                const isValid = await form.trigger()
                if (!isValid) return

                completeInput()
              }}
              comlinkPushParams={{
                action: 'click_confirm_video_seminar_new_content_button',
              }}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const TopErrors = styled.ul<{ hasMultiple: boolean }>`
  ${({ hasMultiple }) => {
    if (hasMultiple) {
      return css`
        margin-left: 12px;
      `
    }
    // エラーが1つだけのときはbulletを表示しない
    return css`
      list-style-type: none;
      margin: 0;
      padding: 0;
    `
  }};
`

const BackButtonLink = styled(_Link)`
  color: inherit;
  text-decoration: none;
`
