import { useCallback } from 'react'
import {
  useLoggedInStaff,
  commonErrorToast,
} from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { assertIsDefined } from '@/assertions'
import { SearchCondition } from '@/services/applicantService'
import { DownloadApplicantsCSVModal as Modal } from './DownloadApplicantsCSVModal'
import { useSendApplicantsCSVMail } from './useSendApplicantsCSVMail'

type Props = {
  condition: SearchCondition
  onClose: () => void
}
export const DownloadApplicantsCSVModal: React.VFC<Props> = (props) => {
  const loggedInStaff = useLoggedInStaff()
  assertIsDefined(loggedInStaff)

  const { mutateAsync } = useSendApplicantsCSVMail()

  const onDwonload = useCallback(async () => {
    try {
      await mutateAsync({
        condition: props.condition,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast('CSV出力を開始しました')
    props.onClose()
  }, [mutateAsync, props])

  return (
    <Modal
      email={loggedInStaff.email}
      onClose={props.onClose}
      onDownload={onDwonload}
    />
  )
}
