import React, { useCallback } from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt } from '@blue-agency/rogue'
import { toast } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton, FormRow } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useHistory, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { VideoSeminarForm } from '@/services/videoSeminarService'
import { useUpdateVideoSeminar } from './useUpdateVideoSeminar'

type Props = {
  videoSeminarGuid: string
  backToInput: () => void
  form: VideoSeminarForm | undefined
}

export const ConfirmPage: React.VFC<Props> = ({
  videoSeminarGuid,
  backToInput,
  form,
}) => {
  const { mutateAsync } = useUpdateVideoSeminar()
  const history = useHistory()

  if (!form) {
    throw new Error('form is undefined')
  }

  const updateVideoSeminar = useCallback(async () => {
    try {
      await mutateAsync({
        guid: videoSeminarGuid,
        title: form.title,
        description: form.description,
      })
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
  }, [form, mutateAsync, videoSeminarGuid])

  const onSubmit = useCallback(async () => {
    await updateVideoSeminar()
    history.push(
      generatePath(INTERNAL_PATHS.videoSeminarInfo, {
        videoSeminarGuid,
      })
    )
    toast('ビデオセミナーを保存しました')
  }, [updateVideoSeminar, history, videoSeminarGuid])

  return (
    <>
      <Page.Body>
        <SettingsSection title="内容を確認">
          <FormWrapper>
            <Row title="タイトル">
              <Txt>{form.title}</Txt>
            </Row>
            <WeakColorSeparator />
            <Row title="トップ紹介文">
              <Description>{form.description}</Description>
            </Row>
          </FormWrapper>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            widthSize="L2"
            comlinkPushParams={{
              action: 'click_back_to_input_button_on_edit_video_seminar_page',
            }}
            onClick={backToInput}
          >
            入力画面に戻る
          </TertiaryButton>
          <PrimaryButton
            widthSize="L2"
            comlinkPushParams={{
              action: 'click_save_button_on_edit_video_seminar_page',
            }}
            onClick={onSubmit}
          >
            保存
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}

const FormWrapper = styled.div`
  padding: 16px 20px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '200px',
  subtitleWidth: '0',
})`
  padding: 20px 0;
`

const Description = styled(Txt)`
  word-break: break-all;
`
