export const INTERNAL_PATHS = {
  signin: '/signin',
  signinCallback: '/cb',

  dashboard: '/dashboard',
  controlCenter: '/control-center',

  webInterviews: '/web-interviews',
  webInterview: '/web-interviews/:webInterviewGuid',
  newWebInterviews: '/web-interviews/new',
  assignedWebInterviews: '/web-interviews/assigned',
  uploadWebInterviewsCSV: '/web-interviews/update-by-csv',

  recInterviews: '/rec-interviews',
  questionTemplates: '/rec-interviews/question-templates',
  newQuestionTemplate: '/rec-interviews/question-templates/new',
  questionTemplate: '/rec-interviews/question-templates/:questionTemplateGuid',
  editQuestionTemplate:
    '/rec-interviews/question-templates/:questionTemplateGuid/edit',

  recInterviewTemplates: '/rec-interviews/templates',
  recInterviewTemplate: '/rec-interviews/templates/:guid',
  editRecInterviewTemplate: '/rec-interviews/templates/:guid/edit',
  newRecInterviewTemplate: '/rec-interviews/templates/new',

  selectionStepMasters: '/selection-step-masters',

  applicants: '/applicants',
  applicant: '/applicants/:applicantGuid',
  newApplicant: '/applicants/new',
  editApplicant: '/applicants/:applicantGuid/edit',
  batchNewApplicants: '/applicants/new/upload',
  applicantsBackgroundJobs: '/applicants/background-jobs',

  webInterviewGuides: '/web-interview-guides',
  webInterviewGuide: '/web-interview-guides/:webInterviewGuideGuid',
  editWebInterviewGuide: '/web-interview-guides/:webInterviewGuideGuid/edit',
  newWebInterviewGuide: '/web-interview-guides/new',

  // 選考ステップ評価ページ
  selectionRecInterview: '/selection/rec-interviews/:selectionStepGuid',

  // ビデオセミナー
  videoSeminars: '/video-seminars',
  newVideoSeminar: '/video-seminars/new',
  editVideoSeminar: '/video-seminars/:videoSeminarGuid/edit',
  videoSeminarInfo: '/video-seminars/:videoSeminarGuid/info',
  videoSeminarContents: '/video-seminars/:videoSeminarGuid/contents',
  videoSeminarReport: '/video-seminars/:videoSeminarGuid/report',
  newVideoSeminarContent: '/video-seminars/:videoSeminarGuid/contents/new',
  editVideoSeminarContent:
    '/video-seminars/:videoSeminarGuid/contents/:videoSeminarContentGuid/edit',
  reorderVideoSeminarContents:
    '/video-seminars/:videoSeminarGuid/contents/reorder',

  embedded: '/embedded',
} as const

export const EMBEDDED_INTERNAL_PATHS = {
  webInterview: '/embedded/web-interviews/:webInterviewGuid',
} as const
