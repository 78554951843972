import { Txt, Icon, theme, IconProps } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { assertNever } from '@/assertions'
import { MovieTranscodeStatus } from '@/services/videoSeminarService'

type Props = {
  status: MovieTranscodeStatus
}
export const StatusOverlay: React.VFC<Props> = ({ status }) => {
  if (status === 'done') return null

  const props = getStatusProps(status)
  return (
    <StatusOverlayWrapper>
      <StatusWrapper status={status}>
        <StatusIcon name={props.iconName} color={props.color} />
        <StatusTxt color={props.color}>{props.label}</StatusTxt>
      </StatusWrapper>
    </StatusOverlayWrapper>
  )
}

const getStatusProps = (
  status: Exclude<MovieTranscodeStatus, 'done'>
): { iconName: IconProps['name']; label: string; color: string } => {
  switch (status) {
    case 'failed':
      return {
        iconName: 'caution-filled',
        label: '動画の変換失敗',
        color: theme.color.red[2],
      }
    case 'processing':
      return {
        iconName: 'exclamation',
        label: '動画の変換中',
        color: theme.color.orange[1],
      }
    default:
      assertNever(status)
  }
}

const StatusOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`

const StatusWrapper = styled.div<{
  status: Exclude<MovieTranscodeStatus, 'done'>
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 112px;
  margin: 40px auto;
  border-radius: 4px;
  ${({ status }) => {
    switch (status) {
      case 'failed':
        return css`
          background-color: ${theme.color.red[4]};
        `
      case 'processing':
        return css`
          background-color: ${theme.color.orange[2]};
        `
      default:
        assertNever(status)
    }
  }}
`

const StatusIcon = styled(Icon).attrs({ width: '58px', height: '58px' })<{
  color: string
}>`
  ${({ color }) =>
    css`
      color: ${color};
    `}
`

const StatusTxt = styled(Txt)`
  font-size: 18px;
  margin-top: 4px;
`
