import { theme, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { StaffIcons } from '@/components/StaffIcons'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import type { Assignee } from '@/services/webInterviewService'
import type { Staff } from './WebInterviewPageContainer'

type Props = {
  assignees: (Assignee & Staff)[]
}

type AssigneeContainerProps = {
  assignees: (Assignee & Staff)[]
  role: 'interviewer' | 'viewer'
}

const roleMap = {
  interviewer: {
    tooltip: TOOLTIP_ID_MAP.interviewersOnWebInterview,
    label: '面接官',
  },
  viewer: {
    tooltip: TOOLTIP_ID_MAP.viewersOnWebInterview,
    label: '閲覧者',
  },
} as const

const AssigneeContainer: React.VFC<AssigneeContainerProps> = ({
  assignees,
  role,
}) => {
  const staffs = assignees.map((assignee) => ({
    ...assignee,
    name: assignee.name.familyName,
  }))

  return (
    <AssigneeContent data-tip data-for={roleMap[role].tooltip}>
      <WebInterviewAssignmentTitle>
        {roleMap[role].label}：
      </WebInterviewAssignmentTitle>
      <StaffIcons staffs={staffs} />
      <Tooltip id={roleMap[role].tooltip} arrowPosition="topLeft">
        <AssigneeNameList>
          {assignees.map((assignee) => (
            <li key={assignee.guid}>
              <Txt>{assignee.name.fullName}</Txt>
            </li>
          ))}
        </AssigneeNameList>
      </Tooltip>
    </AssigneeContent>
  )
}

export const SpecifiedAssignees: React.VFC<Props> = ({ assignees }) => {
  const interviewers = assignees.filter(
    (assignee) => assignee.role === 'interviewer'
  )

  const viewers = assignees.filter((assignee) => assignee.role === 'viewer')

  return (
    <Container>
      {interviewers.length === 0 && viewers.length === 0 && (
        <AssigneeContent>
          <WebInterviewAssignmentTitle>面接官：</WebInterviewAssignmentTitle>
          <WebInterviewAssignmentOption>未設定</WebInterviewAssignmentOption>
        </AssigneeContent>
      )}
      {interviewers.length > 0 && (
        <AssigneeContainer assignees={interviewers} role="interviewer" />
      )}
      {viewers.length > 0 && (
        <AssigneeContainer assignees={viewers} role="viewer" />
      )}
    </Container>
  )
}

const Container = styled.div`
  & > :nth-child(n + 2) {
    margin-left: 20px;
  }

  & > :last-child {
    margin-right: 4px;
  }
`

const AssigneeNameList = styled.ul`
  padding 0 20px;
`

const WebInterviewAssignmentTitle = styled(Txt).attrs({
  size: 'm',
  color: theme.color.gray[1],
})``

const WebInterviewAssignmentOption = styled(Txt).attrs({
  size: 'm',
  color: theme.color.red[2],
})``

const AssigneeContent = styled.div`
  align-items: center;
  display: inline-flex;
`
