import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { Modal, Txt, toast, WeakColorSeparator } from '@blue-agency/rogue'
import { DangerButton, TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { Guide } from './types'
import { useDeleteWebInterviewGuideMutation } from './useDeleteWebInterviewGuideMutation'

type Props = {
  active: boolean
  onClose: () => void
  guide: Guide
}
export function DeleteModal(props: Props) {
  const bodyText = `「${props.guide.name}」を削除します。
削除したデータを復元することはできません。`

  const history = useHistory()

  const { mutateAsync: deleteWebInterviewGuide, isLoading } =
    useDeleteWebInterviewGuideMutation()

  const onDelete = useCallback(async () => {
    try {
      await deleteWebInterviewGuide(props.guide.guid)
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    props.onClose()
    history.push(INTERNAL_PATHS.webInterviewGuides)
    toast('面接ガイドを削除しました')
  }, [deleteWebInterviewGuide, props, history])

  return (
    <Modal
      active={props.active}
      title="面接ガイドの削除"
      onClose={props.onClose}
      stopScroll
    >
      <ModalBody>
        <Txt>{bodyText}</Txt>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action: 'click_cancel_button_on_web_interview_guide_delete_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            loading={isLoading}
            onClick={onDelete}
            comlinkPushParams={{
              action: 'click_delete_button_on_web_interview_guide_delete_modal',
            }}
          >
            削除
          </DangerButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
  height: 146px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
