import { useMutation, useQueryClient } from 'react-query'
import {
  AssignRecInterviewToSelectionStepRequestValue,
  useRequestAssignRecInterviewToSelectionStep,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useAssignRecInterviewToSelectionStep(applicantGuid: string) {
  const queryClient = useQueryClient()
  const { requestAssignRecInterviewToSelectionStep } =
    useRequestAssignRecInterviewToSelectionStep()

  return useMutation(
    (value: AssignRecInterviewToSelectionStepRequestValue) =>
      requestAssignRecInterviewToSelectionStep(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, applicantGuid])
      },
    }
  )
}
