import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListAllRecInterviewTemplatesRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from './metadata'

export function useRequestListAllRecInterviewTemplates() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListAllRecInterviewTemplates = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new ListAllRecInterviewTemplatesRequest()

    return callBizHuttBffService(
      'listAllRecInterviewTemplates',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return { requestListAllRecInterviewTemplates }
}
