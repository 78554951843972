import { provide } from '@blue-agency/front-state-management'
import {
  usePromptBeforeUnload,
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Case, Switch } from 'react-if'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { VideoSeminarContentEditPageContainer } from './VideoSeminarContentEditPageContainer'

export const VideoSeminarContentEditPage = provide(
  VideoSeminarContentEditPageContainer
)(() => {
  const { step, videoSeminarContent } =
    VideoSeminarContentEditPageContainer.useContainer()

  usePromptBeforeUnload()

  const { authorizeByRpcName } = AuthzContainer.useContainer()
  if (authorizeByRpcName !== undefined) {
    authorizeByRpcName('StartVideoSeminarMovieUpload')
    authorizeByRpcName('CompleteVideoSeminarMovieUpload')
    authorizeByRpcName('UpdateVideoSeminarContent')
  }

  if (!videoSeminarContent) return <Loading />

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Page.Header>
          <Page.HeaderTitle>コンテンツ編集</Page.HeaderTitle>
        </Page.Header>
        <Switch>
          <Case condition={step === 'input'}>
            <InputPage />
          </Case>
          <Case condition={step === 'confirm'}>
            <ConfirmPage />
          </Case>
        </Switch>
      </Page.Wrapper>
    </PageLayout>
  )
})
