import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateSelectionSharingTypeRequestValue,
  useRequestUpdateSelectionSharingType,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateSelectionSharingType(applicantGuid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateSelectionSharingType } =
    useRequestUpdateSelectionSharingType()

  return useMutation(
    (value: UpdateSelectionSharingTypeRequestValue) =>
      requestUpdateSelectionSharingType(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.applicant, applicantGuid])
      },
    }
  )
}
