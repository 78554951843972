import { useCallback, useMemo } from 'react'
import type { StaffItem } from '@blue-agency/im-shared-front'
import { FieldArrayWithId, useFieldArray } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import { useLatestSelectedUser } from '@/services/webInterviewService'
import { assigneeRoleOptions } from '@/services/webInterviewService'
import type { Form, Staff } from '../NewWebInterviewPageContainer'
import { NewWebInterviewPageContainer } from '../NewWebInterviewPageContainer'

type UseAssigneesForm = {
  control: Control<Form>
}

export type Assignee = {
  guid: string
  role: 'interviewer' | 'viewer'
}

export const useAssigneesForm = ({ control }: UseAssigneesForm) => {
  const { allAssigneeCandidates } = NewWebInterviewPageContainer.useContainer()

  const { select, compareFn } = useLatestSelectedUser()

  const assigneeFieldArray = useFieldArray({
    control,
    name: 'assignees',
  })

  const selectedAssignees = useMemo(
    () =>
      assigneeFieldArray.fields
        .map((field) => {
          const assigneeData = allAssigneeCandidates.find(
            (assignee) => assignee.guid === field.guid
          )
          return assigneeData ? { ...assigneeData, ...field } : undefined
        })
        .filter(
          (
            assignee
          ): assignee is Staff & FieldArrayWithId<Form, 'assignees', 'id'> =>
            !!assignee
        ),
    [allAssigneeCandidates, assigneeFieldArray]
  )

  const appendAssignee = useCallback(
    (staff: StaffItem) => {
      select(staff.guid)
      assigneeFieldArray.append({
        guid: staff.guid,
        role: assigneeRoleOptions[0].value,
      })
    },
    [assigneeFieldArray, select]
  )

  return {
    allAssigneeCandidates,
    selectedAssignees,
    appendAssignee,
    removeAssignee: assigneeFieldArray.remove,
    compareFn,
  }
}
