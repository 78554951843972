import React from 'react'
import { Modal as _Modal, theme, Txt, ErrorBox } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useDeleteModal } from './useDeleteModal'

type Props = {
  active: boolean
  onClose: () => void
  guid: string
  name: string
}

export const DeleteModal: React.VFC<Props> = (props) => {
  const history = useHistory()

  const onSuccessDelete = () => {
    props.onClose()
    history.push(INTERNAL_PATHS.questionTemplates)
  }

  const onClose = () => {
    ctx.setFailedInUseError(false)
    props.onClose()
  }

  const ctx = useDeleteModal({
    onSuccessDelete: onSuccessDelete,
    guid: props.guid,
  })

  return (
    <Modal active={props.active} title="設問の削除" size="m" onClose={onClose}>
      <Body>
        {ctx.failedInUseError && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                この設問はすでに利用されているため削除できませんでした。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <Text>
          「{props.name}」を削除します。
          <br />
          削除したデータを復元することはできません。
        </Text>
      </Body>
      <Bottom>
        <CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action:
              'click_cancel_button_on_delete_rec_interview_question_template_modal',
          }}
        >
          キャンセル
        </CancelButton>
        <SubmitButton
          onClick={ctx.deleteRecInterviewQuestionTemplate}
          comlinkPushParams={{
            action:
              'click_delete_button_on_delete_rec_interview_question_template_modal',
          }}
          disabled={ctx.failedInUseError}
        >
          削除
        </SubmitButton>
      </Bottom>
    </Modal>
  )
}

const Body = styled(Txt)`
  padding: 20px 20px 0;
  flex: 1;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const Text = styled(Txt)``

const Modal = styled(_Modal)`
  height: 260px;
`

const Bottom = styled.div`
  width: 100%;
  height: 76px;
  border-top: 1px solid ${theme.color.gray[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`

const CancelButton = styled(TertiaryButton).attrs({
  widthSize: 'L1',
})``

const SubmitButton = styled(DangerButton).attrs({
  widthSize: 'L1',
})`
  margin-left: 20px;
`
