import { useState, useCallback } from 'react'
import assert from 'assert'
import { useMultipartUpload } from '@/hooks/useMultipartUpload'
import {
  useRequestStartVideoSeminarMovieUpload,
  useRequestCompleteVideoSeminarMovieUpload,
} from '@/services/bffService'

export function useMovieUpload(videoSeminarGuid: string) {
  const [abortController, setAbortController] = useState(new AbortController())

  const multipartUpload = useMultipartUpload(abortController)

  const onCancel = useCallback(() => {
    abortController.abort()
    setAbortController(new AbortController())
  }, [abortController])

  const { requestStartVideoSeminarMovieUpload } =
    useRequestStartVideoSeminarMovieUpload()
  const { requestCompleteVideoSeminarMovieUpload } =
    useRequestCompleteVideoSeminarMovieUpload()

  const upload = useCallback(
    async (video: Blob) => {
      const blobs = multipartUpload.splitBlob(video)

      const startUploadRes = await requestStartVideoSeminarMovieUpload(
        videoSeminarGuid,
        blobs.length
      )

      assert(blobs.length === startUploadRes.getUrlsList().length)

      const parts = blobs.map((blob, i) => ({
        blob,
        url: startUploadRes.getUrlsList()[i],
      }))

      const etags = await multipartUpload.upload(parts)

      const key = startUploadRes.getKey()
      const uploadId = startUploadRes.getUploadId()

      await requestCompleteVideoSeminarMovieUpload(
        videoSeminarGuid,
        key,
        uploadId,
        etags
      )

      return key
    },
    [
      multipartUpload,
      videoSeminarGuid,
      requestStartVideoSeminarMovieUpload,
      requestCompleteVideoSeminarMovieUpload,
    ]
  )

  return {
    upload,
    onCancel,
    multipartUpload,
  }
}
