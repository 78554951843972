import { useQuery } from 'react-query'
import { useRequestListOngoingWebInterviews } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListOngoingWebInterviews() {
  const { requestListOngoingWebInterviews } =
    useRequestListOngoingWebInterviews()

  return useQuery([QUERY_KEY.ongoingWebInterviews], () =>
    requestListOngoingWebInterviews()
  )
}
