import { addHours, isAfter, isEqual } from 'date-fns'

// 提出期限の経過日時指定の日付は「1〜60日」で指定可能
export const dueDurationDays = [...Array(60)].map((_, i) => i + 1)

// 提出期限の経過日時指定の時間は「9:00〜18:00」で指定可能
export const dueDurationHours = [...Array(10)].map((_, i) => i + 9)

// 提出期限の日時指定の時間は「9:00〜18:00」で指定可能
export const dueTimeHours = [...Array(10)].map((_, i) => i + 9)

/**
 * 締切は現在時刻から2時間先以上の猶予を持たせる
 */
export const bufferHours = 2

export const isValidDeadline = (deadline: Date, today: Date): boolean => {
  const bufferedDate = addHours(today, bufferHours)
  return isEqual(deadline, bufferedDate) || isAfter(deadline, bufferedDate)
}
