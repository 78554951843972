import { useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { useDeleteRecInterviewTemplate } from './useDeleteRecInterviewTemplate'

type Args = {
  guid: string
  onSuccessDelete: () => void
}

export const useDeleteModal = ({ guid, onSuccessDelete }: Args) => {
  const { mutateAsync } = useDeleteRecInterviewTemplate()

  const [failedInUseError, setFailedInUseError] = useState<boolean>(false)

  const deleteRecInterviewTemplate = useCallback(async () => {
    try {
      await mutateAsync({ guid })
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.hasMatchErrorDetail('REC_INTERVIEW_TEMPLATE_IN_USE')) {
          setFailedInUseError(true)
          return
        }
      }
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
    toast('録画面接テンプレートを削除しました')
    onSuccessDelete()
  }, [mutateAsync, guid, onSuccessDelete])

  return {
    deleteRecInterviewTemplate,
    failedInUseError,
    setFailedInUseError,
  }
}
