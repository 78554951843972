import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestArchiveVideoSeminarContent,
  ArchiveVideoSeminarContentRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useArchiveVideoSeminarContent() {
  const queryClient = useQueryClient()
  const { requestArchiveVideoSeminarContent } =
    useRequestArchiveVideoSeminarContent()

  return useMutation(
    (value: ArchiveVideoSeminarContentRequestValue) =>
      requestArchiveVideoSeminarContent(value),
    {
      onSuccess: (_, req) =>
        queryClient.invalidateQueries([
          QUERY_KEY.videoSeminar,
          req.videoSeminarGuid,
        ]),
    }
  )
}
