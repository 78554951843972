import { useCallback, useState, useEffect } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Modal,
  WeakColorSeparator,
  Icon,
  theme,
  Txt,
  ErrorBox,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import * as C from '@/components/RecInterviewTemplate'
import { VideoPlayer } from '@/components/VideoPlayer'
import { UploadingModal } from './UploadingModal'
import { ValidateFilesResponse, validateFiles } from './fileValidator'
import { useTemplateUpload } from './useTemplateUpload'

type Props = {
  active: boolean
  type: 'intro' | 'outro'
  onSave: (fileKey: string, blob: Blob) => void
  onCancel: () => void
}

type UploadData = {
  fileName: string
  fileKey: string
  video: Blob | undefined
}

export const VideoModal: React.VFC<Props> = (props) => {
  const [uploadData, setUploadData] = useState<UploadData>()
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<string>()
  const templateUpload = useTemplateUpload()

  useEffect(() => {
    if (props.active) {
      initializeState()
    }
  }, [props.active])

  const titlePrefix = props.type === 'intro' ? 'オープニング' : 'エンディング'

  const initializeState = () => {
    setUploadData(undefined)
    setErrorMessageOnTop(undefined)
  }

  const onSave = useCallback(async () => {
    if (!uploadData || !uploadData.video) return
    props.onSave(uploadData.fileKey, uploadData.video)
  }, [props, uploadData])

  const onDrop = useCallback(
    async (files: File[]) => {
      initializeState()

      let result: ValidateFilesResponse
      try {
        result = validateFiles(files)
      } catch (e) {
        Sentry.captureException(e)
        setErrorMessageOnTop(
          'ファイルの取り込みに失敗しました。ファイルの内容を確認して、再度お試しください。'
        )
        return
      }

      if (result.type === 'error') {
        setErrorMessageOnTop(result.message)
        return
      }

      let key: string = ''
      try {
        key = await templateUpload.upload(result.video)
        /* eslint-disable  @typescript-eslint/no-explicit-any */
      } catch (e: any) {
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-4.html#defaulting-to-the-unknown-type-in-catch-variables---useunknownincatchvariables

        if (e.name === 'AbortError') {
          // キャンセル押下
          return
        }
        Sentry.captureException(e)
        commonErrorToast()
        return
      }

      setUploadData({
        fileName: result.fileName,
        fileKey: key,
        video: result.video,
      })
    },
    [templateUpload]
  )

  const dropzone = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop,
  })

  return (
    <Modal
      size="l"
      title={`${titlePrefix}動画を選択`}
      active={props.active}
      onClose={props.onCancel}
    >
      {errorMessageOnTop && (
        <ErrorBoxWrapper>
          <ErrorBox>{errorMessageOnTop}</ErrorBox>
        </ErrorBoxWrapper>
      )}
      <ModalInner>
        <Label>ファイル選択</Label>
        <div>
          {!uploadData && (
            <Box>
              <FileSelectArea {...dropzone.getRootProps()}>
                <input {...dropzone.getInputProps()} />
                <FileSelectTxtWrapper>
                  <Icon name="add-file" width="24" />
                  <Txt>ファイルをドラッグ＆ドロップ</Txt>
                </FileSelectTxtWrapper>
                <TertiaryButton
                  widthSize="L1"
                  comlinkPushParams={{
                    action: `click_upload_${props.type}_video_file_button`,
                  }}
                >
                  ファイル選択
                </TertiaryButton>
              </FileSelectArea>
              <Box mt="12px">
                <Txt color={theme.color.gray[1]}>
                  アップロード可能なファイル形式はMP4のみです（最大サイズ：200MB）
                </Txt>
              </Box>
            </Box>
          )}
          {uploadData && uploadData.video && (
            <VideoWrapper>
              {/* NOTE: max-heightが218より小さいと再生ボタンが表示されない
              https://blue-agency.atlassian.net/browse/PIM-2419 */}
              <VideoPlayer srcObject={uploadData.video} maxHeight={218} />
              <Txt>{uploadData.fileName}</Txt>
            </VideoWrapper>
          )}
        </div>
      </ModalInner>
      <WeakColorSeparator />
      <ModalBottom>
        <C.CancelButton
          comlinkPushParams={{
            action: `click_cancel_button_on_${props.type}_video_modal`,
          }}
          onClick={props.onCancel}
        />
        <C.SaveButton
          disabled={!uploadData}
          comlinkPushParams={{
            action: `click_save_button_on_${props.type}_video_modal`,
          }}
          onClick={onSave}
        />
      </ModalBottom>
      <UploadingModal
        type={props.type}
        active={templateUpload.uploadingModal.active}
        percent={templateUpload.multipartUpload.progress}
        fileName={uploadData?.fileName ?? ''}
        onCancel={templateUpload.onCancel}
      />
    </Modal>
  )
}

const ModalInner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 16px;
`

const ErrorBoxWrapper = styled.div`
  margin: 20px 20px 0 20px;
`

const Label = styled(C.SmallTxt)`
  min-width: 120px;
  margin-right: 16px;
`

const VideoWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;

  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const FileSelectTxtWrapper = styled.div`
  display: flex;
`

const ModalBottom = styled.div`
  margin: auto 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    height: 36px;
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`
