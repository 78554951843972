import React from 'react'
import { theme } from '@blue-agency/rogue'

export const sizes = ['s', 'm']
type Size = (typeof sizes)[number]

type Props = {
  name: string
  color: string
  size?: Size
  overlapped?: boolean
}

// refs: https://stackoverflow.com/a/54141770
const pattern = /\p{ASCII}+/u

const iconAttrs: Record<
  Size,
  {
    diameter: number
    fontSize: string
  }
> = {
  s: {
    diameter: 36,
    fontSize: theme.fontSize.xs,
  },
  m: {
    diameter: 42,
    fontSize: theme.fontSize.s,
  },
}

export const StaffIcon: React.VFC<Props> = ({
  name,
  color,
  size = 'm',
  overlapped = false,
}) => {
  const displayName = pattern.test(name) ? name.slice(0, 5) : name.slice(0, 3)
  const { diameter, fontSize } = iconAttrs[size]!
  const radius = diameter / 2.0 - 1
  const dimension = diameter * 0.83
  const posX =
    overlapped && displayName.length <= 2 ? diameter * 0.44 : diameter / 2
  const posY = diameter * 0.6

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={diameter / 2}
        cy={diameter / 2}
        r={radius}
        fill={color}
        paintOrder="stroke"
        stroke="#F8FAFC"
        strokeWidth="2"
      />
      <text
        fill="white"
        fontSize={fontSize}
        width={dimension}
        height={dimension}
        textAnchor="middle"
        x={posX}
        y={posY}
      >
        {displayName}
      </text>
    </svg>
  )
}
