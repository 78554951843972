import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListAllRecInterviewTemplatesForOptionRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from './metadata'

export function useRequestListAllRecInterviewTemplatesForOption() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListAllRecInterviewTemplatesForOption = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new ListAllRecInterviewTemplatesForOptionRequest()

    return callBizHuttBffService(
      'listAllRecInterviewTemplatesForOption',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return { requestListAllRecInterviewTemplatesForOption }
}
