type Part = {
  title: string
  description: string
  durationSeconds: number
}

export type PartWithOffset = Part & { startSeconds: number }

export function calcPartOffsets(
  parts: ReadonlyArray<Part>,
  firstPartStartSeconds: number
): PartWithOffset[] {
  let currentOffset = firstPartStartSeconds
  const ret: PartWithOffset[] = []
  for (const part of parts) {
    ret.push({
      ...part,
      startSeconds: currentOffset,
    })
    currentOffset += part.durationSeconds
  }
  return ret
}
