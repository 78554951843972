import { useMemo, useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { SelectionStatus } from '@blue-agency/proton/im'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { Applicant } from '../types'
import { useBatchUpdateSelectionStatus } from '../useBatchUpdateSelectionStatus'
import { BatchUpdateSelectionStatusToFinishedModal as Modal } from './BatchUpdateSelectionStatusToFinishedModal'

type Props = {
  applicants: Applicant[]
  close: () => void
}
export const BatchUpdateSelectionStatusToFinishedModal: React.VFC<Props> = (
  props
) => {
  const { mutateAsync } = useBatchUpdateSelectionStatus()

  const selected = useMemo(
    () => props.applicants.filter((a) => a.selected),
    [props.applicants]
  )

  const request = useCallback(async () => {
    try {
      await mutateAsync({
        condition: {
          type: 'guids',
          applicantGuids: selected.map((a) => a.guid),
        },
        status: SelectionStatus.SELECTION_STATUS_FINISHED,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast(`${selected.length}件のステータスを変更しました`)
    props.close()
  }, [mutateAsync, props, selected])

  return (
    <Modal applicants={selected} onSubmit={request} onClose={props.close} />
  )
}
