import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { AssignSelectionStepToApplicantRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type AssignSelectionStepToApplicantRequestValue = {
  applicantGuid: string
  selectionStepMasterGuid: string
}

export function useRequestAssignSelectionStepToApplicant() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestAssignSelectionStepToApplicant = useCallback(
    async (value: AssignSelectionStepToApplicantRequestValue) => {
      const metadata = await buildMetadata()

      const req = new AssignSelectionStepToApplicantRequest()

      req.setApplicantGuid(value.applicantGuid)
      req.setSelectionStepMasterGuid(value.selectionStepMasterGuid)

      return callBizHuttBffService(
        'assignSelectionStepToApplicant',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestAssignSelectionStepToApplicant,
  }
}
