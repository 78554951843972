import { CommonConfirmPage } from '@/components/WebInterviewGuideForm/CommonConfirmPage'
import { EditWebInterviewGuidePageContainer } from './editWebInterviewGuidePageContainer'

export function ConfirmPage() {
  const { backToInput, completedForm, register, isRegisterLoading } =
    EditWebInterviewGuidePageContainer.useContainer()

  return (
    <CommonConfirmPage
      type="edit"
      completedForm={completedForm}
      isRegisterLoading={isRegisterLoading}
      exec={register}
      backToInput={backToInput}
    />
  )
}
