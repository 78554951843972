/**
 * useQueryParamsでNumberParamを指定したqueryのバリデーション
 * @param num queryの値
 * @param opt shouldBeInt: 整数値に限定するかどうか
 * @returns number | undefined
 */
export const validateNumberParamQuery = (
  // TODO: num: ReturnType<typeof NumberParam['decode']> のようにuse-query-paramsの型定義から取るようにする
  // NumberParamをimportしてくると QueryParamConfig<number, number> になる場合があるので自前で定義
  num: number | null | undefined,
  opt?: {
    shouldBeInt?: boolean
  }
): number | undefined => {
  if (num === 0) return 0

  // null | undefined | NaN
  if (!num) return undefined

  // 整数でない場合 undefined
  if (opt?.shouldBeInt && !Number.isInteger(num)) return undefined

  return num
}
