import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateSelectionStepResultCommentRequestValue,
  useRequestUpdateSelectionStepResultComment,
} from '@/services/bffService/useRequestUpdateSelectionStepResultComment'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateSelectionStepResultComment(applicantGuid: string) {
  const queryClient = useQueryClient()
  const { requestUpdateSelectionStepResultComment } =
    useRequestUpdateSelectionStepResultComment()

  return useMutation(
    (value: UpdateSelectionStepResultCommentRequestValue) =>
      requestUpdateSelectionStepResultComment(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, applicantGuid])
      },
    }
  )
}
