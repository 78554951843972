import { ListApplicantEventsResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { Cookie } from '../cookieService'
import { AudienceRetention } from '../videoSeminarService'

/**
 * ビデオセミナーレポート機能仕様：https://stadium.kibe.la/notes/29045
 */

type Event = {
  type: 'videoSeminar'
  data: VideoSeminarEvent
}

export type VideoSeminarContentReport = {
  contentGuid: string
  contentTitle: string
  totalViewCount: number
  totalViewTimeSeconds: number
  movieDurationSeconds: number
  audienceRetention: AudienceRetention
  movieUrl: string
  sequence: number
}

export type VideoSeminarEvent = {
  seminarGuid: string
  seminarTitle: string
  registerTime: Date
  participateTime?: Date
  contentReports: VideoSeminarContentReport[]
  presignedCookies: Cookie[]
}

export const mapProtoToApplicantEvents = (
  proto: ListApplicantEventsResponse
): Event[] =>
  proto.getEventsList().map((event) => {
    let res: Event
    switch (event.getEventCase()) {
      case ListApplicantEventsResponse.Event.EventCase.VIDEO_SEMINAR:
        const videoSeminar = event.getVideoSeminar()
        if (videoSeminar === undefined) {
          throw new Error('videoSeminar not found')
        }
        res = {
          type: 'videoSeminar',
          data: mapProtoToVideoSeminarEvent(videoSeminar),
        }
        break
      case ListApplicantEventsResponse.Event.EventCase.EVENT_NOT_SET:
        throw new Error('event not set')
    }
    return res
  })

const mapProtoToVideoSeminarEvent = (
  proto: ListApplicantEventsResponse.Event.VideoSeminar
): VideoSeminarEvent => {
  const registerTime = proto.getRegisterTime()
  if (registerTime === undefined) {
    throw new Error('registerTime not found')
  }
  return {
    seminarGuid: proto.getSeminarGuid(),
    seminarTitle: proto.getSeminarTitle(),
    registerTime: registerTime.toDate(),
    participateTime: proto.getParticipateTime()?.toDate(),
    contentReports: proto.getContentReportsList().map((report) => {
      const audienceRetention = report.getAudienceRetention()
      if (audienceRetention === undefined) {
        throw new Error('audienceRetention not found')
      }
      return {
        contentGuid: report.getContentGuid(),
        contentTitle: report.getContentTitle(),
        totalViewCount: report.getTotalViewCount(),
        totalViewTimeSeconds: report.getTotalViewTimeSeconds(),
        movieDurationSeconds: report.getMovieDurationSeconds(),
        audienceRetention: mapProtoToAudienceRetention(audienceRetention),
        movieUrl: report.getMovieUrl(),
        sequence: report.getSequence(),
      }
    }),
    presignedCookies: proto.getPresignedCookiesList().map((c) => c.toObject()),
  }
}

const mapProtoToAudienceRetention = (
  proto: ListApplicantEventsResponse.Event.VideoSeminar.ContentReport.AudienceRetention
): AudienceRetention => {
  return {
    playbackLogIntervalSeconds: proto.getPlaybackLogIntervalSeconds(),
    playbackLogs: proto.getPlaybackLogsList().map((log) => ({
      playbackCount: log.getPlaybackCount(),
      playbackTimeSeconds: log.getPlaybackTimeSeconds(),
    })),
  }
}
