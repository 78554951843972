import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListWebInterviewGuidesRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListWebInterviewGuides() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListWebInterviewGuides = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new ListWebInterviewGuidesRequest()

    return callBizHuttBffService(
      'listWebInterviewGuides',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return { requestListWebInterviewGuides }
}
