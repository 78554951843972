import React, { useCallback } from 'react'
import { provide } from '@blue-agency/front-state-management'
import {
  DetailPage as Page,
  PageLayoutWithGlonavi,
} from '@blue-agency/im-shared-front'
import {
  WeakColorSeparator,
  Txt as _Txt,
  Icon,
  theme,
} from '@blue-agency/rogue'
import { FormRow } from '@blue-agency/rogue/im'
import { Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { Box } from '@/components/Box'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { VideoPlayer } from '@/components/VideoPlayer'
import { SectionInner } from '@/components/form'
import { useModal } from '@/hooks/useModal'
import { QuestionTemplatePageContainer } from '@/pages/QuestionTemplatePage/QuestionTemplatePageContainer'
import { AuthzContainer } from '@/services/authzService'
import {
  getQuestionFormatLabel,
  getVideoQuestionlMaxDurationSecondsLabel,
  getVideoQuestionlMaxRecCountLabel,
} from '@/services/questionTemplateService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { DeleteModal } from './DeleteModal'

export const QuestionTemplatePage: React.VFC = provide(
  QuestionTemplatePageContainer
)(() => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('GetRecInterviewQuestionTemplate')

  const { questionTemplateGuid, data, isLoading } =
    QuestionTemplatePageContainer.useContainer()

  const deleteModal = useModal(false)

  const handleClickDelete = useCallback(() => {
    deleteModal.open()
  }, [deleteModal])

  if (isLoading) {
    return <Loading />
  }
  assertIsDefined(data)

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>設問詳細</Page.HeaderTitle>
          <Page.HeaderRight>
            <BackToListPageLink to={INTERNAL_PATHS.questionTemplates}>
              一覧に戻る
            </BackToListPageLink>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="設問情報">
            <SectionInner>
              <RightControl>
                <EditButton
                  to={generatePath(INTERNAL_PATHS.editQuestionTemplate, {
                    questionTemplateGuid,
                  })}
                >
                  <EditIcon />
                  <Txt color={theme.color.navy[1]}>編集</Txt>
                </EditButton>
                <DeleteButton to="#" onClick={handleClickDelete}>
                  <DeleteIcon />
                  <Txt color={theme.color.red[2]}>削除</Txt>
                </DeleteButton>
              </RightControl>
              <Row title="設問名">
                <Txt>{data.name}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問文">
                <Txt>{data.content}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問の注釈">
                <Txt>{data.note}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問動画">
                {data.videoPresignedUrl && (
                  <Box width={270} height={152}>
                    <VideoPlayer src={data.videoPresignedUrl} />
                  </Box>
                )}
              </Row>
              <WeakColorSeparator />
              <Row title="回答" subtitle="形式">
                <Txt>{getQuestionFormatLabel(data.format)}</Txt>
              </Row>
              {data.format === 'video' && (
                <>
                  <InnerRow subtitle="回答時間制限">
                    <Txt>
                      {getVideoQuestionlMaxDurationSecondsLabel(
                        data.maxDurationSeconds.toString()
                      )}
                    </Txt>
                  </InnerRow>
                  <InnerRow subtitle="撮影回数制限">
                    <Txt>
                      {getVideoQuestionlMaxRecCountLabel(
                        data.maxRecCount.toString()
                      )}
                    </Txt>
                  </InnerRow>
                </>
              )}
            </SectionInner>
          </SettingsSection>
        </Page.Body>
        <DeleteModal
          active={deleteModal.active}
          onClose={deleteModal.close}
          guid={questionTemplateGuid}
          name={data.name}
        />
      </Page.Wrapper>
    </PageLayoutWithGlonavi>
  )
})

const Txt = styled(_Txt).attrs({ size: 'm' })``

const BackToListPageLink = styled(Link)`
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
`

const RightControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const EditButton = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  > :first-child {
    margin-right: 4px;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${theme.color.navy[1]};
  }
`

const EditIcon = styled(Icon).attrs({ name: 'edit', size: 'm' })`
  color: ${theme.color.navy[1]};
`

const DeleteButton = styled(Link)`
  margin-left: 30px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${theme.color.red[2]};

  > :first-child {
    margin-right: 4px;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${theme.color.red[2]};
  }
`

const DeleteIcon = styled(Icon).attrs({ name: 'delete' })`
  vertical-align: middle;
  margin-right: 7px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '120px',
  subtitleWidth: '80px',
})`
  padding: 20px 0;
`

const InnerRow = styled(Row)`
  padding-top: 0;
`
