import React, { useCallback, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Assignment,
  WEB_INTERVIEW_COUNT_MAX,
  durationMinutesSchema,
  scheduledStartTimeShape,
} from '@/services/webInterviewService'
import { NewWebInterviewPageContainer } from './NewWebInterviewPageContainer'
import type { Form } from './NewWebInterviewPageContainer'

const countValidationErrorMessage = '一度に登録できるのは1,000件までとなります'

const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    count: yup
      .number()
      .required('入力してください')
      .typeError(countValidationErrorMessage)
      .min(1, countValidationErrorMessage)
      .max(WEB_INTERVIEW_COUNT_MAX, countValidationErrorMessage)
      .integer(countValidationErrorMessage),
    name: yup.string().max(25, '25文字以内で入力してください'),
    assignment: yup.mixed<Assignment>().required('選択してください'),
    assignees: yup.mixed<Form['assignees']>(),
    durationMinutes: durationMinutesSchema,
    ...scheduledStartTimeShape,
  })
  .defined()

export function useInputPage() {
  const { completedForm, completeInput, guides } =
    NewWebInterviewPageContainer.useContainer()

  const {
    register,
    control,
    handleSubmit,
    formState,
    watch,
    getValues,
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: completedForm ?? {
      count: 1,
      assignment: 'specified',
      assignees: [],
    },
  })

  const guideOptions = guides.map((g) => ({
    label: g.name,
    value: g.guid,
  }))

  const onSubmit = useMemo(
    () => handleSubmit(completeInput),
    [completeInput, handleSubmit]
  )

  const checkKeyDown = useCallback<React.KeyboardEventHandler<HTMLFormElement>>(
    (e) => {
      // Enter キーを押下されたときに Form を送信しない
      if (e.key === 'Enter') e.preventDefault()
    },
    []
  )

  return {
    register,
    control,
    errors: formState.errors,
    isSubmitting: formState.isSubmitting,
    onSubmit,
    checkKeyDown,
    currentAssignment: watch('assignment'),
    watch,
    setValue,
    guideOptions,
    getValues,
  }
}
