import { useMemo } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { Tooltip as T } from '@blue-agency/rogue/dist/im'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Box } from '@/components/Box'
import { SecondaryLink } from '@/components/Link'
import { SelectionStep } from '@/pages/ApplicantPage/Header/SelectionStep'
import {
  getSelectionStatusColor,
  getSelectionStatusText,
  Applicant,
  Selection,
  isSelectionFinished,
} from '@/services/applicantService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { INTERNAL_PATHS } from '@/services/urlService'

// 姓名間のスペース含む最大の表示氏名文字数
const maxNameLength = 9
const maxNameKanaLength = 18

type Props = PropsWithClassName<{
  applicantGuid: string
  applicant: Applicant
  selection: Selection
  showLinkToApplicantPage?: boolean
}>
export const Header: React.VFC<Props> = (props) => {
  const applicant = props.applicant
  const name = `${applicant.familyName} ${applicant.givenName}`
  const nameKana = `${applicant.familyNameKana} ${applicant.givenNameKana}`

  const selectionNames = useMemo(
    () =>
      props.selection.selectionStepsList
        .sort((a, b) => a.sequence - b.sequence)
        .map((s) => s.name),
    [props.selection]
  )

  const currentStepIndex = useMemo(() => {
    const currentStep = props.selection.currentSelectionStepGuid
    const currentSequence = props.selection.selectionStepsList.find(
      (s) => s.guid === currentStep
    )?.sequence
    if (currentSequence === undefined) return 0

    // TODO: 現状はsequenceが1originの前提になっているので、実際のレスポンスの仕様が確定したら修正
    return currentSequence - 1
  }, [props.selection])

  return (
    <Wrapper className={props.className}>
      <Left>
        <NameArea>
          <Name data-tip data-for={TOOLTIP_ID_MAP.applicantDetailName}>
            {ellipsizeName(name, maxNameLength)}
          </Name>
          {name.length > maxNameLength && (
            <Tooltip
              id={TOOLTIP_ID_MAP.applicantDetailName}
              arrowPosition="topLeft"
            >
              <TooltipTxt>{name}</TooltipTxt>
            </Tooltip>
          )}
          <NameKana
            data-tip
            data-for={TOOLTIP_ID_MAP.applicantDetailNameKana}
            color={theme.color.gray[1]}
          >
            {ellipsizeName(nameKana, maxNameKanaLength)}
          </NameKana>
        </NameArea>
        <StatusArea>
          <Dot color={getSelectionStatusColor(props.selection.status)} />
          <Box ml="4px">
            <Txt>{getSelectionStatusText(props.selection.status)}</Txt>
          </Box>
        </StatusArea>
        {selectionNames.length > 0 && (
          <SelectionStepArea>
            <SelectionStep
              stepNames={selectionNames}
              currentIndex={currentStepIndex}
              isSelectionFinished={isSelectionFinished(props.selection.status)}
            />
          </SelectionStepArea>
        )}
      </Left>
      <Right>
        {props.showLinkToApplicantPage && (
          <SecondaryLink
            comlinkPushParams={{
              action: 'open_applicant_page_from_selection_rec_interview_page',
            }}
            to={generatePath(INTERNAL_PATHS.applicant, {
              applicantGuid: props.applicantGuid,
            })}
          >
            応募者詳細へ
          </SecondaryLink>
        )}
      </Right>
    </Wrapper>
  )
}

const ellipsizeName = (name: string, maxLength: number) => {
  return name.length > maxLength ? name.slice(0, maxLength) + '...' : name
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[4]};
`

const Left = styled.div`
  flex: 1;
  display: flex;
`

const Right = styled.div`
  display: flex;
  align-items: flex-end;
`

const NameArea = styled.div`
  max-width: 260px;
`

const StatusArea = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  height: 20px;
  margin-left: 30px;
  margin-top: 8px;
`

const Name = styled(Txt).attrs({ size: 'xxl' })``

const NameKana = styled(Txt).attrs({ size: 'm' })``

const Tooltip = styled(T)`
  max-width: 70%;
`

const TooltipTxt = styled(Txt).attrs({ size: 'm' })``

const SelectionStepArea = styled.div`
  flex: 1;
  margin-top: 6px;
`

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`
