import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import {
  getApplicantFullName,
  Applicant,
  Selection as SelectionType,
} from '@/services/applicantService'
import { Action, Store } from '../applicantInputsReducer'
import { TabType } from '../useApplicantTabs'
import { SetNextActionFn } from '../useNextAction'
import { Event as E } from './Event'
import { History as H } from './History'
import { Profile as P } from './Profile'
import { Selection as S } from './Selection'

type Props = PropsWithClassName<{
  selectedTab: TabType
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  applicantInputState: Store
  applicantInputDispatch: React.Dispatch<Action>
  selection: SelectionType
  setNextActionFn: SetNextActionFn
  registrationRoute: string
  organizationRecInterviewTranscriptionSetting: boolean
}>
export const Content: React.VFC<Props> = (props) => {
  const content = (() => {
    switch (props.selectedTab) {
      case 'profile':
        return (
          <Profile
            applicantGuid={props.applicantGuid}
            applicant={props.applicant}
            registerTime={props.registerTime}
            registrationRoute={props.registrationRoute}
            editable
          />
        )
      case 'selection':
        return (
          <Selection
            applicantGuid={props.applicantGuid}
            applicantName={getApplicantFullName(props.applicant)}
            applicantInputState={props.applicantInputState}
            applicantInputDispatch={props.applicantInputDispatch}
            selection={props.selection}
            setNextActionFn={props.setNextActionFn}
            organizationRecInterviewTranscriptionSetting={
              props.organizationRecInterviewTranscriptionSetting
            }
          />
        )
      case 'event':
        return <Event applicantGuid={props.applicantGuid} />
      case 'history':
        return <History selectionGuid={props.selection.guid} />
    }
  })()

  return <div className={props.className}>{content}</div>
}

const Profile = styled(P)`
  height: 100%;
`

const Selection = styled(S)`
  height: 100%;
`

const Event = styled(E)`
  height: 100%;
`

const History = styled(H)`
  height: 100%;
`
