import { useCallback, useMemo, useEffect } from 'react'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { theme, Txt, toast } from '@blue-agency/rogue'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import urlJoin from 'url-join'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { useModal } from '@/hooks/useModal'
import {
  StepActionType,
  Selection as SelectionType,
} from '@/services/applicantService'
import { writeTextToClipboard } from '@/services/clipboardService'
import { calculateDeadline } from '@/services/selectionRecInterviewService/calculateDeadline'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Action, Store } from '../../applicantInputsReducer'
import { SetNextActionFn } from '../../useNextAction'
import { AssignTemplateModal } from './AssignTemplateModal'
import { ChangeSelectionRecInterviewDeadlineModal } from './ChangeSelectionRecInterviewDeadlineModal'
import { DeleteSelectionStepModal } from './DeleteSelectionStepModal'
import { RegisterSelectionStepModal } from './RegisterSelectionStepModal'
import { RequestSelectionRecInterviewAnswerModal } from './RequestSelectionRecInterviewAnswerModal'
import { RevokeRequestSelectionRecInterviewAnswerModal } from './RevokeRequestSelectionRecInterviewAnswerModal'
import { SelectionStep } from './SelectionStep'
import { SendRequestSelectionRecInterviewAnswerModal } from './SendRequestSelectionRecInterviewAnswerMailModal'

type Props = PropsWithClassName<{
  applicantGuid: string
  applicantName: string
  applicantInputState: Store
  applicantInputDispatch: React.Dispatch<Action>
  selection: SelectionType
  setNextActionFn: SetNextActionFn
  organizationRecInterviewTranscriptionSetting: boolean
}>
export const Selection: React.VFC<Props> = ({
  applicantGuid,
  applicantName,
  applicantInputState,
  applicantInputDispatch,
  selection,
  setNextActionFn,
  className,
  organizationRecInterviewTranscriptionSetting,
}) => {
  const selectionSteps = useMemo(
    () =>
      selection.selectionStepsList
        // 最新の選考ステップが上にくる
        .sort((a, b) => b.sequence - a.sequence),
    [selection]
  )
  const currentSelectionStepGuid = selection.currentSelectionStepGuid
  const currentSelectionStep = useMemo(() => {
    return selectionSteps.find((s) => s.guid === currentSelectionStepGuid)
  }, [selectionSteps, currentSelectionStepGuid])

  const registerSelectionStepModal = useModal()
  const deleteSelectionStepModal = useModal()
  const assignTemplateModal = useModal()
  const requestSelectionRecInterviewAnswerModal = useModal()
  const sendRequestSelectionRecInterviewAnswerMailModal = useModal()
  const changeSelectionRecInterviewDeadlineModal = useModal()
  const revokeRequestSelectionRecInterviewAnswerModal = useModal()

  const onClick = useCallback(
    (guid: string) => {
      if (currentSelectionStepGuid === guid) {
        applicantInputDispatch({
          type: 'OPEN_CURRENT_SELECTION_STEP_RESULT',
          payload: { selectionStepGuid: guid },
        })
        return
      }
      applicantInputDispatch({
        type: 'OPEN_SELECTION_STEP_RESULT_COMMENT',
        payload: { selectionStepGuid: guid },
      })
    },
    [applicantInputDispatch, currentSelectionStepGuid]
  )

  const onMenuClick = useCallback(
    (type: StepActionType, guid: string) => {
      switch (type) {
        case 'DELETE':
          deleteSelectionStepModal.open()
          break
        case 'COPY_REQUEST_SUBMISSION_URL':
          const requestSubmissionUrl =
            getInternalUrls().mySelectionRecInterview.replace(
              ':selectionStepGuid',
              guid
            )
          writeTextToClipboard(requestSubmissionUrl)
          toast('回答ページリンクをコピーしました')
          break
        case 'SEND_SUBMISSION_REQUEST_EMAIL':
          sendRequestSelectionRecInterviewAnswerMailModal.open()
          break
        case 'REVOKE_REQUEST_SUBMISSION_REC_INTERVIEW':
          revokeRequestSelectionRecInterviewAnswerModal.open()
          break
        case 'ASSIGN_REC_INTERVIEW_TEMPLATE':
          assignTemplateModal.open()
          break
        case 'REQUEST_SUBMISSION_REC_INTERVIEW':
          requestSelectionRecInterviewAnswerModal.open()
          break
        case 'CHANGE_SELECTION_REC_INTERVIEW_DEADLINE':
          changeSelectionRecInterviewDeadlineModal.open()
          break
        case 'COPY_SELECTION_STEP_PAGE_URL':
          const selectionStepPagePath = generatePath(
            INTERNAL_PATHS.selectionRecInterview,
            {
              selectionStepGuid: guid,
            }
          )
          const selectionStepPageUrl = urlJoin(
            window.location.origin,
            selectionStepPagePath
          )
          writeTextToClipboard(selectionStepPageUrl)
          toast('評価画面リンクをコピーしました')
          break
      }
    },
    [
      deleteSelectionStepModal,
      sendRequestSelectionRecInterviewAnswerMailModal,
      revokeRequestSelectionRecInterviewAnswerModal,
      assignTemplateModal,
      requestSelectionRecInterviewAnswerModal,
      changeSelectionRecInterviewDeadlineModal,
    ]
  )

  const onDelete = useCallback(() => {
    // NOTE: 最新の選考を削除時に閉じないと、削除された選考ステップのresultを使おうとしてエラーになる箇所がある
    applicantInputDispatch({
      type: 'CLOSE_CURRENT_SELECTION_STEP_RESULT',
    })
  }, [applicantInputDispatch])

  const deadline = useMemo(() => {
    const selectionRecInterview = currentSelectionStep?.selectionRecInterview
    if (!selectionRecInterview) return undefined

    // 提出依頼後、deadlineが存在する場合はそれを返す
    if (selectionRecInterview.deadline) return selectionRecInterview.deadline

    // 提出依頼前、deadlineが未確定な場合はフロントで計算する
    const today = new Date()
    return calculateDeadline({
      baseDate: today,
      templateDeadline: selectionRecInterview?.templateDeadline,
    })
  }, [currentSelectionStep])

  // 「次のアクション」の登録
  useEffect(() => {
    const fn = () =>
      applicantInputDispatch({
        type: 'OPEN_CURRENT_SELECTION_STEP_RESULT',
        payload: {
          selectionStepGuid: currentSelectionStepGuid,
        },
      })
    setNextActionFn('ASSESS_SELECTION_STEP', fn)
    setNextActionFn('REGISTER_NEXT_SELECTION_STEP', fn)
    setNextActionFn(
      'REGISTER_INITIAL_SELECTION_STEP',
      registerSelectionStepModal.open
    )
    setNextActionFn('ASSIGN_REC_INTERVIEW_TEMPLATE', assignTemplateModal.open)
    setNextActionFn(
      'REQUEST_SUBMISSION_REC_INTERVIEW',
      requestSelectionRecInterviewAnswerModal.open
    )
    setNextActionFn(
      'CHANGE_REC_INTERVIEW_DEADLINE',
      changeSelectionRecInterviewDeadlineModal.open
    )
  }, [
    setNextActionFn,
    registerSelectionStepModal,
    assignTemplateModal,
    requestSelectionRecInterviewAnswerModal,
    applicantInputDispatch,
    currentSelectionStepGuid,
    changeSelectionRecInterviewDeadlineModal,
  ])

  if (selectionSteps.length === 0) {
    return (
      <Wrapper className={className}>
        <EmptyContent>
          <EmptyMessage>選考はありません</EmptyMessage>
          <RegisterSelectionStepModal
            active={registerSelectionStepModal.active}
            applicantGuid={applicantGuid}
            close={registerSelectionStepModal.close}
          />
        </EmptyContent>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      {selectionSteps.map((s) => {
        const isCurrentSelectionStep = currentSelectionStepGuid === s.guid

        return (
          <SelectionStep
            key={s.guid}
            guid={s.guid}
            name={s.name}
            result={s.result}
            comment={s.comment}
            registrationRoute={s.registrationRoute}
            registerTime={s.registerTime}
            lastAssessTime={s.lastAssessTime}
            isCurrentSelectionStep={isCurrentSelectionStep}
            selected={applicantInputState.selectedSelectionStepGuid === s.guid}
            selectionRecInterview={s.selectionRecInterview}
            status={selection.status}
            subStatus={selection.subStatus}
            appendix={s.selectionStepAppendix}
            onClick={onClick}
            onMenuClick={onMenuClick}
            organizationRecInterviewTranscriptionSetting={
              organizationRecInterviewTranscriptionSetting
            }
          />
        )
      })}
      {deleteSelectionStepModal.active && (
        <DeleteSelectionStepModal
          applicantGuid={applicantGuid}
          selectionGuid={selection.guid}
          // NOTE: 削除できる選考 === currentSelectionStep
          selectionStepGuid={currentSelectionStepGuid}
          selectionStepName={currentSelectionStep?.name ?? ''}
          close={deleteSelectionStepModal.close}
          onDelete={onDelete}
        />
      )}
      <AssignTemplateModal
        active={assignTemplateModal.active}
        applicantGuid={applicantGuid}
        selectionGuid={selection.guid}
        // NOTE: テンプレ設定できる選考 === currentSelectionStep
        selectionStepGuid={currentSelectionStepGuid}
        close={assignTemplateModal.close}
      />
      {deadline !== undefined && (
        <>
          <RequestSelectionRecInterviewAnswerModal
            active={requestSelectionRecInterviewAnswerModal.active}
            applicantGuid={applicantGuid}
            applicantName={applicantName}
            templateName={
              currentSelectionStep?.selectionRecInterview?.name ?? ''
            }
            deadline={deadline}
            // NOTE: 提出依頼できる選考 === currentSelectionStep
            selectionStepGuid={currentSelectionStepGuid}
            close={requestSelectionRecInterviewAnswerModal.close}
          />
          <SendRequestSelectionRecInterviewAnswerModal
            active={sendRequestSelectionRecInterviewAnswerMailModal.active}
            applicantGuid={applicantGuid}
            applicantName={applicantName}
            deadline={deadline}
            // NOTE: 提出依頼を再送信できる選考 === currentSelectionStep
            selectionStepGuid={currentSelectionStepGuid}
            close={sendRequestSelectionRecInterviewAnswerMailModal.close}
          />
          <RevokeRequestSelectionRecInterviewAnswerModal
            active={revokeRequestSelectionRecInterviewAnswerModal.active}
            applicantGuid={applicantGuid}
            // NOTE: 提出依頼を取消できる選考 === currentSelectionStep
            selectionStepGuid={currentSelectionStepGuid}
            close={revokeRequestSelectionRecInterviewAnswerModal.close}
          />
          <ChangeSelectionRecInterviewDeadlineModal
            active={changeSelectionRecInterviewDeadlineModal.active}
            applicantGuid={applicantGuid}
            deadline={deadline}
            // NOTE: 提出期限を変更できる選考 === currentSelectionStep
            selectionStepGuid={currentSelectionStepGuid}
            close={changeSelectionRecInterviewDeadlineModal.close}
          />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  background-color: ${theme.color.gray[5]};
`

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.color.white[1]};
`

const EmptyMessage = styled(Txt).attrs({ size: 'l' })`
  margin-top: 20px;
`
