import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import type { TestOptions } from 'yup'
import { useModal } from '@/hooks/useModal'
import { validateMapping } from '@/pages/WebInterviewUpdateByFilePage/Mapping/validateMapping'
import {
  DetailValidationError,
  validateDetail,
} from '@/pages/WebInterviewUpdateByFilePage/csv/validateDetail'
import { MappingOutput } from '@/pages/WebInterviewUpdateByFilePage/useWebInterviewUpdateByFilePage'
import type { CSVData, MappingResult, MappingTarget } from '../types'

type FromType = TestOptions['options']['from']

// Note: Dropdownにて未選択時は型定義によらず空文字列になるため、ハンドリングできるように型定義に加える
export type Targets = (MappingTarget | '')[]

type Form = {
  targets: Targets
}

type Props = {
  csvData: CSVData
  initialMappingResult?: MappingResult
  goPrevious: () => void
  goNext: (input: MappingOutput) => void
}

const formSchema = yup.object().shape({
  targets: yup
    .array()
    .of(
      yup
        .mixed<MappingTarget | ''>()
        .test({
          name: '「Web面接ID」である場合、「連携先Web面接ID」が選択されていない',
          message: 'Web面接ID、連携先Web面接IDは同時に選択できません',
          test: (value, ctx) => {
            // ctx の型定義に from が無いので、無理矢理対応する
            // https://github.com/jquense/yup/issues/1445
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const from = (ctx as any).from as FromType
            if (from === undefined) {
              return false
            }
            const grandParent = from[0]
            if (grandParent === undefined) {
              return false
            }

            const targets = grandParent.value.targets
            if (value === 'web-interview-guid') {
              return !targets.includes('ats-interview-id')
            }

            if (value === 'ats-interview-id') {
              return !targets.includes('web-interview-guid')
            }

            return true
          },
        })
        .defined()
    )
    .defined(),
})

export const useMappingPage = (props: Props) => {
  const validationErrorModal = useModal()
  const [detailValidationErrors, setDetailValidationErrors] = useState(
    [] as DetailValidationError[]
  )

  const {
    register,
    control,
    handleSubmit,
    formState,
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      targets: !!props.initialMappingResult
        ? convertToTargets(props.csvData, props.initialMappingResult)
        : [],
    },
  })

  const targetOptions: { label: string; value: MappingTarget }[] =
    useMemo(() => {
      return [
        { label: 'Web面接ID', value: 'web-interview-guid' },
        { label: '連携先Web面接ID', value: 'ats-interview-id' },
        { label: '面接名', value: 'name' },
        { label: '予定日時', value: 'scheduled-start-time' },
        { label: '目安時間', value: 'duration' },
        { label: '面接ガイドID', value: 'interview-guide-guid' },
        { label: '担当設定', value: 'assignment' },
        { label: '面接官', value: 'interviewer' },
        { label: '閲覧者', value: 'viewer' },
      ]
    }, [])

  const watchTargets = useWatch({ control, name: 'targets' })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const nameRegExp = new RegExp('^targets.[0-9]+$')
      if (
        typeof name === 'string' &&
        nameRegExp.test(name) &&
        type === 'change'
      ) {
        trigger('targets')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, trigger])

  const countByTarget: Record<MappingTarget, number> = useMemo(() => {
    return watchTargets.reduce<Record<MappingTarget, number>>(
      (prev, current) => {
        if (!current) {
          return prev
        }

        return {
          ...prev,
          [current]: (prev[current] ?? 0) + 1,
        }
      },
      {} as Record<MappingTarget, number>
    )
  }, [watchTargets])

  const targetOptionsFn = useCallback(
    (index) => {
      const disableOptionValues: Set<MappingTarget> = new Set<MappingTarget>()
      const countByTargetAtIndex = { ...countByTarget }
      const currentTarget = watchTargets[index]
      if (!!currentTarget) {
        // 設定数から自身のフォームによる影響を除くために1減らす
        countByTargetAtIndex[currentTarget] -= 1
      }

      if (countByTargetAtIndex['web-interview-guid'] > 0) {
        disableOptionValues.add('web-interview-guid')
      }

      if (countByTargetAtIndex['ats-interview-id'] > 0) {
        disableOptionValues.add('ats-interview-id')
      }

      if (countByTargetAtIndex['name'] > 0) {
        disableOptionValues.add('name')
      }

      if (countByTargetAtIndex['scheduled-start-time'] > 0) {
        disableOptionValues.add('scheduled-start-time')
      }

      if (countByTargetAtIndex['duration'] > 0) {
        disableOptionValues.add('duration')
      }

      if (countByTargetAtIndex['interview-guide-guid'] > 0) {
        disableOptionValues.add('interview-guide-guid')
      }

      if (countByTargetAtIndex['assignment'] > 0) {
        disableOptionValues.add('assignment')
      }

      // 面接官は、10項目までを指定できる
      if (countByTargetAtIndex['interviewer'] > 9) {
        disableOptionValues.add('interviewer')
      }

      // 閲覧者は、10項目までを指定できる
      if (countByTargetAtIndex['viewer'] > 9) {
        disableOptionValues.add('viewer')
      }

      return targetOptions.filter(({ value }) => {
        return !disableOptionValues.has(value)
      })
    },
    [targetOptions, countByTarget, watchTargets]
  )

  const topErrorRef = useRef<HTMLDivElement>(null)
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<
    string | undefined
  >()

  const onClickPreviousButton = useCallback(() => {
    props.goPrevious()
  }, [props])

  const onSubmit = useMemo(() => {
    const submit = async () => {
      const formValues = getValues().targets
      const validationResult = validateMapping(
        formValues.map<MappingTarget | undefined>((value) => {
          return value === '' ? undefined : value
        })
      )

      if (validationResult.type === 'error') {
        setErrorMessageOnTop(validationResult.message)
        topErrorRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        return
      }

      const validationDetailResult = await validateDetail(
        props.csvData.csvBody,
        validationResult.result,
        { errorLimit: 10 }
      )

      if (validationDetailResult.type === 'error') {
        setDetailValidationErrors(validationDetailResult.errors)
        validationErrorModal.open()
        return
      }

      props.goNext({
        mapping: validationResult.result,
        webInterviewUpdates: validationDetailResult.webInterviewUpdates,
      })
    }

    return handleSubmit(submit)
  }, [
    handleSubmit,
    props,
    getValues,
    setDetailValidationErrors,
    validationErrorModal,
  ])

  const closeValidationErrorModal = useCallback(() => {
    validationErrorModal.close()
    setDetailValidationErrors([])
  }, [validationErrorModal, setDetailValidationErrors])

  return {
    register,
    control,
    errors: formState.errors,
    isSubmitting: formState.isSubmitting,
    onSubmit,
    onClickPreviousButton,
    watch,
    getValues,
    setValue,
    targetOptionsFn,
    topErrorRef,
    errorMessageOnTop,
    validationErrorModalActive: validationErrorModal.active,
    closeValidationErrorModal,
    detailValidationErrors,
  }
}

// MappingResultからDropboxの選択状態を復元する
const convertToTargets = (
  csvData: CSVData,
  mappingResult: MappingResult
): Targets => {
  const targets: Targets = csvData.csvHeader.map(() => '')

  targets[mappingResult.key] = mappingResult.keyType
  if (!!mappingResult.name) {
    targets[mappingResult.name] = 'name'
  }
  if (!!mappingResult['scheduled-start-time']) {
    targets[mappingResult['scheduled-start-time']] = 'scheduled-start-time'
  }
  if (!!mappingResult.duration) {
    targets[mappingResult.duration] = 'duration'
  }
  if (!!mappingResult['interview-guide-guid']) {
    targets[mappingResult['interview-guide-guid']] = 'interview-guide-guid'
  }
  if (!!mappingResult.assignment) {
    targets[mappingResult.assignment] = 'assignment'
  }
  for (const interviewerIndex of mappingResult.interviewer ?? []) {
    targets[interviewerIndex] = 'interviewer'
  }
  for (const viewerIndex of mappingResult.viewer ?? []) {
    targets[viewerIndex] = 'viewer'
  }
  return targets
}
