import React from 'react'
import { SecondaryButton } from '@blue-agency/rogue/im'
import { useCsvDownload } from './useCsvDownload'

export const CsvDownloadButton = () => {
  const { isLoading, download } = useCsvDownload()
  return (
    <SecondaryButton
      widthSize="L1"
      onClick={download}
      comlinkPushParams={{
        action: 'click_download_web_interviews_csv',
      }}
      loading={isLoading}
    >
      CSV出力
    </SecondaryButton>
  )
}
