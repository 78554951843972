import { useMemo, useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { Applicant } from '../types'
import { useBatchDeleteApplicants } from '../useBatchDeleteApplicants'
import { BatchDeleteApplicantsModal as Modal } from './BatchDeleteApplicantsModal'

type Props = {
  applicants: Applicant[]
  close: () => void
}
export const BatchDeleteApplicantsModal: React.VFC<Props> = (props) => {
  const { mutateAsync } = useBatchDeleteApplicants()

  const selected = useMemo(
    () => props.applicants.filter((a) => a.selected),
    [props.applicants]
  )

  const request = useCallback(async () => {
    try {
      await mutateAsync({
        condition: {
          type: 'guids',
          applicantGuids: selected.map((a) => a.guid),
        },
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast(`${selected.length}件の応募者を削除しました`)
    props.close()
  }, [mutateAsync, props, selected])

  return (
    <Modal applicants={selected} onSubmit={request} onClose={props.close} />
  )
}
