import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { useElapsedTimeProgress } from '@/pages/ControlCenterPage/useElapsedTimeProgress'

type Props = {
  scheduledStartTime: Date
  scheduledFinishTime: Date
  startedTime: Date
  finishedTime?: Date
  loadTime: Date
}

export const ElapsedTimeProgress: React.VFC<Props> = (props) => {
  const {
    pre,
    redPer,
    greenPer,
    over,
    elapsedDuration,
    isFinished,
    finishedDuration,
  } = useElapsedTimeProgress({
    scheduledStartTime: props.scheduledStartTime,
    scheduledFinishTime: props.scheduledFinishTime,
    startedTime: props.startedTime,
    currentTime: props.loadTime,
    finishedTime: props.finishedTime,
  })

  return (
    <Wrapper>
      {isFinished ? (
        <FinishedWrapper>
          <FinishedTxtWrapper>
            <Txt size="m" color={theme.color.navy[1]}>
              終了
            </Txt>
            <FinishedDurationTxt>
              （<span>{finishedDuration}</span>分実施）
            </FinishedDurationTxt>
          </FinishedTxtWrapper>
        </FinishedWrapper>
      ) : (
        <OngoingWrapper>
          <TimeTxt>
            {elapsedDuration}
            <span>分</span>
          </TimeTxt>
          <Progress>
            <ProgressContent pre={pre} over={over}>
              {pre && <PreArea />}
              <RedArea per={redPer} />
              <GreenArea per={greenPer} />
              {over && <OverArea />}
            </ProgressContent>
          </Progress>
        </OngoingWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const OngoingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

const TimeTxt = styled(Txt).attrs({
  size: 'l',
  color: theme.color.navy[1],
})`
  > span {
    font-size: ${theme.fontSize.m};
  }
`

const Progress = styled.div`
  width: 160px;
  height: 12px;
  position: relative;
`

const ProgressContent = styled.div<{ pre: boolean; over: boolean }>`
  position: absolute;
  top: 0;
  ${({ pre }) =>
    pre &&
    css`
      left: -10px;
    `}
  width: calc(100% ${({ pre }) => pre && '+ 10px'} ${({ over }) =>
    over && '+ 10px'}); // pre + over
  height: 100%;
  background-color: #c4c4c4;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`

const PreArea = styled.div`
  width: 10px;
  background-color: ${theme.color.green[5]};
  border-right: 1px dashed ${theme.color.white[1]};
  box-sizing: border-box;
`

const RedArea = styled.div<{ per: number }>`
  background-color: ${theme.color.red[2]};
  width: ${({ per }) => 160 * per}px;
  height: 100%;
`

const GreenArea = styled.div<{ per: number }>`
  background-color: ${theme.color.green[4]};
  width: ${({ per }) => 160 * per}px;
  height: 100%;
`

const OverArea = styled.div`
  width: 10px;
  background-color: ${theme.color.red[2]};
  border-left: 1px dashed ${theme.color.white[1]};
  box-sizing: border-box;
`

const FinishedWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
`

const FinishedTxtWrapper = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  align-items: end;
`

const FinishedDurationTxt = styled(Txt).attrs({
  size: 'm',
  color: theme.color.navy[1],
})`
  > span {
    font-size: 18px;
  }
`
