import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateVideoSeminarRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateVideoSeminarRequestValue = {
  guid: string
  title: string
  description: string
}

export function useRequestUpdateVideoSeminar() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateVideoSeminar = useCallback(
    async (v: UpdateVideoSeminarRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateVideoSeminarRequest()
      req.setVideoSeminarGuid(v.guid)
      req.setTitle(v.title)
      req.setDescription(v.description)

      return callBizHuttBffService(
        'updateVideoSeminar',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateVideoSeminar,
  }
}
