import { useMutation, useQueryClient } from 'react-query'
import { useRequestDeleteWebInterviewGuide } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteWebInterviewGuideMutation() {
  const queryClient = useQueryClient()
  const { requestDeleteWebInterviewGuide } = useRequestDeleteWebInterviewGuide()

  return useMutation(
    async (guid: string) => {
      await requestDeleteWebInterviewGuide(guid)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviewGuides)
      },
    }
  )
}
