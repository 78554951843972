import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithChildren, PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName & PropsWithChildren

export const TableBase = styled.table<Props>`
  background: ${theme.color.white[1]};
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 73px;
  thead,
  tbody {
    display: block;
  }
  tbody tr {
    &:hover {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    }
  }
  th {
    color: ${theme.color.navy[1]};
    background: ${theme.color.navy[3]};
    font-size: ${theme.fontSize.m};
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    line-height: 1.5;
    height: 44px;
  }
  td {
    color: ${theme.color.navy[1]};
    font-size: ${theme.fontSize.m};
    min-height: 60px;
    height: 60px;

    border-bottom: 1px solid ${theme.color.gray[3]};
    vertical-align: middle;
    line-height: 1.5;
    overflow: hidden;
  }
`
