import { SelectionStep as SelectionStepType } from '@/services/applicantService'
import { ChangePlayingAnswerVideo } from '../../AnswerVideoPreview/useAnswerVideoPreview'
import { ApplicantNameHeader } from '../ApplicantNameHeader'
import { SelectionStep } from './SelectionStep'

type Props = {
  applicantName: string
  applicantNameKana?: string
  targetSelectionStep: SelectionStepType
  changePlayingAnswerVideo: ChangePlayingAnswerVideo
  organizationRecInterviewTranscriptionSetting: boolean
}
export const Selection: React.VFC<Props> = (props) => {
  return (
    <div>
      <ApplicantNameHeader
        applicantName={props.applicantName}
        applicantNameKana={props.applicantNameKana}
      />
      <SelectionStep
        targetSelectionStep={props.targetSelectionStep}
        changePlayingAnswerVideo={props.changePlayingAnswerVideo}
        organizationRecInterviewTranscriptionSetting={
          props.organizationRecInterviewTranscriptionSetting
        }
      />
    </div>
  )
}
