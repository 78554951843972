import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetApplicantRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetApplicant() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetApplicant = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new GetApplicantRequest()

      req.setGuid(guid)

      return callBizHuttBffService(
        'getApplicant',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestGetApplicant,
  }
}
