import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ArchiveVideoSeminarContentRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ArchiveVideoSeminarContentRequestValue = {
  videoSeminarGuid: string
  videoSeminarContentGuid: string
}
export function useRequestArchiveVideoSeminarContent() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestArchiveVideoSeminarContent = useCallback(
    async (val: ArchiveVideoSeminarContentRequestValue) => {
      const metadata = await buildMetadata()

      const req = new ArchiveVideoSeminarContentRequest()
      req.setVideoSeminarContentGuid(val.videoSeminarContentGuid)

      return callBizHuttBffService(
        'archiveVideoSeminarContent',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestArchiveVideoSeminarContent,
  }
}
