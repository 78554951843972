import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { AssignRecInterviewToSelectionStepRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type AssignRecInterviewToSelectionStepRequestValue = {
  selectionGuid: string
  selectionStepGuid: string
  recInterviewTemplateGuid: string
}
export function useRequestAssignRecInterviewToSelectionStep() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestAssignRecInterviewToSelectionStep = useCallback(
    async (val: AssignRecInterviewToSelectionStepRequestValue) => {
      const metadata = await buildMetadata()

      const req = new AssignRecInterviewToSelectionStepRequest()
      req.setSelectionGuid(val.selectionGuid)
      req.setSelectionStepGuid(val.selectionStepGuid)
      req.setRecInterviewTemplateGuid(val.recInterviewTemplateGuid)

      return callBizHuttBffService(
        'assignRecInterviewToSelectionStep',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestAssignRecInterviewToSelectionStep,
  }
}
