import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { SendApplicantsCSVMailRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SearchCondition } from '../applicantService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import { toSearchApplicantCondition } from './toSearchApplicantCondition'

export type SendApplicantsCSVMailArgs = {
  condition: SearchCondition
}

export function useRequestSendApplicantsCSVMail() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestSendApplicantsCSVMail = useCallback(
    async ({ condition }: SendApplicantsCSVMailArgs) => {
      const metadata = await buildMetadata()

      const req = new SendApplicantsCSVMailRequest()
      req.setCondition(toSearchApplicantCondition(condition))

      return callBizHuttBffService(
        'sendApplicantsCSVMail',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestSendApplicantsCSVMail }
}
