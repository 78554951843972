import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateRecInterviewTemplateRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import {
  toRecInterviewTemplateInput,
  RecInterviewTemplateInputValue,
} from './toRecInterviewTemplateInput'

export function useRequestUpdateRecInterviewTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateRecInterviewTemplate = useCallback(
    async (guid: string, value: RecInterviewTemplateInputValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateRecInterviewTemplateRequest()
      req.setGuid(guid)
      req.setTemplate(toRecInterviewTemplateInput(value))

      return callBizHuttBffService(
        'updateRecInterviewTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateRecInterviewTemplate,
  }
}
