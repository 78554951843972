import { useCallback, useEffect, useState } from 'react'
import { useApplicantQueryParam, TabType } from '@/services/applicantService'

export { tabs } from '@/services/applicantService'
export type { TabType } from '@/services/applicantService'
export type SwitchTab = (tab: TabType) => void

export const useApplicantTabs = () => {
  const { tabParam, setQuery } = useApplicantQueryParam()
  const [selectedTab, setSelectedTab] = useState(tabParam)

  const switchTab = useCallback<SwitchTab>((tab: TabType) => {
    setSelectedTab(tab)
  }, [])

  useEffect(() => {
    setQuery(
      {
        t: selectedTab,
      },
      'replaceIn'
    )
  }, [selectedTab, setQuery])

  return {
    selectedTab,
    switchTab,
  }
}
