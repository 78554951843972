import { GetVideoSeminarReportResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'

export type PlaybackLog = {
  playbackTimeSeconds: number
  playbackCount: number
}

export type AudienceRetention = {
  playbackLogIntervalSeconds: number
  playbackLogs: PlaybackLog[]
}

export type VideoSeminarContentReport = {
  contentGuid: string
  contentTitle: string
  totalViewCount: number
  totalViewTimeSeconds: number
  averageViewTimeSeconds: number
  movieDurationSeconds: number
  audienceRetention: AudienceRetention
  sequence: number
}

export type VideoSeminarReport = {
  totalViewCount: number
  visitCount: number
  contentReports: VideoSeminarContentReport[]
}

export const mapProtoToVideoSeminarReport = (
  proto: GetVideoSeminarReportResponse
): VideoSeminarReport => {
  return {
    totalViewCount: proto.getTotalViewCount(),
    visitCount: proto.getVisitCount(),
    contentReports: proto
      .getContentReportsList()
      .map(mapProtoToVideoSeminarContentReport),
  }
}

const mapProtoToVideoSeminarContentReport = (
  proto: GetVideoSeminarReportResponse.ContentReport
): VideoSeminarContentReport => {
  const audienceRetention = proto.getAudienceRetention()
  if (audienceRetention === undefined) {
    throw new Error('audienceRetention not found')
  }
  return {
    contentGuid: proto.getContentGuid(),
    contentTitle: proto.getContentTitle(),
    totalViewCount: proto.getTotalViewCount(),
    totalViewTimeSeconds: proto.getTotalViewTimeSeconds(),
    averageViewTimeSeconds: proto.getAverageViewTimeSeconds(),
    movieDurationSeconds: proto.getMovieDurationSeconds(),
    audienceRetention: mapProtoToAudienceRetention(audienceRetention),
    sequence: proto.getSequence(),
  }
}

const mapProtoToAudienceRetention = (
  proto: GetVideoSeminarReportResponse.ContentReport.AudienceRetention
): AudienceRetention => {
  return {
    playbackLogIntervalSeconds: proto.getPlaybackLogIntervalSeconds(),
    playbackLogs: proto.getPlaybackLogsList().map((log) => ({
      playbackCount: log.getPlaybackCount(),
      playbackTimeSeconds: log.getPlaybackTimeSeconds(),
    })),
  }
}
