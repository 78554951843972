import { toast } from '@blue-agency/rogue'
import { useHistory } from 'react-router'
import { useMount } from 'react-use'
import { ApplicantForm } from '@/services/applicantService'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  applicants: ApplicantForm[]
}
export const Completed: React.VFC<Props> = ({ applicants }) => {
  const history = useHistory()

  useMount(() => {
    toast(`${applicants.length}件の応募者を登録しました`)
    history.push(INTERNAL_PATHS.applicants)
  })

  return <></>
}
