import { useCallback, useState } from 'react'

const steps = ['basicInfo', 'intro', 'questions', 'outro', 'confirm'] as const

export type Step = (typeof steps)[number]

export const useStep = () => {
  const [step, setStep] = useState<Step>('basicInfo')

  const nextStep = useCallback(() => {
    if (step === 'confirm') {
      alert('TODO: submit the form data, and move to the list page')
      return
    }
    setStep((prev) => steps[steps.indexOf(prev) + 1])
  }, [step, setStep])

  const prevStep = useCallback(() => {
    if (step === 'basicInfo') {
      alert('TODO: return to the list page')
      return
    }
    setStep((prev) => steps[steps.indexOf(prev) - 1])
  }, [step, setStep])

  return {
    step,
    nextStep,
    prevStep,
  }
}
