import { useQuery } from 'react-query'
import { QUERY_KEY } from '@/services/queryKeyService'
import { useRequestListWebInterviewGuides } from '../../services/bffService/useRequestListWebInterviewGuides'

export function useListWebInterviewGuides() {
  const { requestListWebInterviewGuides } = useRequestListWebInterviewGuides()

  return useQuery([QUERY_KEY.webInterviewGuides], () =>
    requestListWebInterviewGuides()
  )
}
