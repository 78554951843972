import { useQuery } from 'react-query'
import { useRequestGetWebInterview } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetWebInterview(webInterviewGuid: string) {
  const { requestGetWebInterview } = useRequestGetWebInterview()

  return useQuery([QUERY_KEY.webInterview, webInterviewGuid], () =>
    requestGetWebInterview(webInterviewGuid)
  )
}
