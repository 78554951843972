import React, { useCallback, useEffect, useState } from 'react'
import {
  usePromptBeforeUnload,
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Case, Switch } from 'react-if'
import { useParams } from 'react-router'
import { AuthzContainer } from '@/services/authzService'
import {
  useGetVideoSeminar,
  mapProtoToVideoSeminar,
  VideoSeminarForm,
} from '@/services/videoSeminarService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'

type Step = 'input' | 'confirm'

export const EditVideoSeminarPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('UpdateVideoSeminar')

  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()

  usePromptBeforeUnload()

  const [step, setStep] = useState<Step>('input')
  const [form, setForm] = useState<VideoSeminarForm>()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: VideoSeminarForm) => {
    setForm(form)
    setStep('confirm')
  }, [])

  const { data } = useGetVideoSeminar(videoSeminarGuid)

  useEffect(() => {
    if (data === undefined) return undefined
    const v = mapProtoToVideoSeminar(data, videoSeminarGuid)
    setForm({
      title: v.title,
      description: v.description,
    })
  }, [data, videoSeminarGuid])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Page.Header>
          <Page.HeaderTitle>ビデオセミナー編集</Page.HeaderTitle>
        </Page.Header>
        <Switch>
          <Case condition={step === 'input'}>
            <InputPage
              videoSeminarGuid={videoSeminarGuid}
              form={form}
              completeInput={completeInput}
            />
          </Case>
          <Case condition={step === 'confirm'}>
            <ConfirmPage
              videoSeminarGuid={videoSeminarGuid}
              form={form}
              backToInput={backToInput}
            />
          </Case>
        </Switch>
      </Page.Wrapper>
    </PageLayout>
  )
}
