import React, { useMemo } from 'react'
import { WeakColorSeparator } from '@blue-agency/rogue'
import { FormRow } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { QuestionRow } from '@/components/RecInterviewTemplate'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import { VideoPlayer } from '@/components/VideoPlayer'
import {
  mapQuestionFormat,
  useAllRecInterviewQuestionTemplates,
} from '@/services/questionTemplateService'
import { Question } from '../types'
import { StepParam } from './types'

export const Confirm: React.VFC<StepParam> = ({ form: { template } }) => {
  const { data } = useAllRecInterviewQuestionTemplates()
  const questionDict = useMemo<Record<string, Question>>(
    () =>
      data?.getQuestionTemplatesList().reduce((acc, q) => {
        acc[q.getGuid()] = {
          guid: q.getGuid(),
          name: q.getName(),
          content: q.getContent(),
          note: q.getNote(),
          format: mapQuestionFormat(q.getFormat()),
        }
        return acc
      }, {} as Record<string, Question>) ?? {},
    [data]
  )

  return (
    <SettingsSection title="内容の確認">
      <C.FormBlockContainer>
        <Row title="テンプレート名">
          <C.ItemValue>{template.name}</C.ItemValue>
        </Row>
        <WeakColorSeparator />
        <Row title={'作成済みの\n動画ファイルの提出'}>
          <C.ItemValue>
            {template.allowRecordedVideo === 'allowed'
              ? '許可する'
              : '許可しない'}
          </C.ItemValue>
        </Row>
        <WeakColorSeparator />
        <Row title="テンプレート名">
          <C.ItemValue>
            {template.due.type === 'time' ? (
              <C.DueTimeText time={template.due.time} />
            ) : (
              <C.DueDurationText duration={template.due.duration} />
            )}
          </C.ItemValue>
        </Row>
        <WeakColorSeparator />
        <Row title="応募者の開始ページ" subtitle={'企業からの\nメッセージ'}>
          <C.ItemValue>
            {template.introVideoURL && (
              <Box width={270}>
                <VideoPlayer src={template.introVideoURL} />
              </Box>
            )}
            <p>{template.introMessage}</p>
          </C.ItemValue>
        </Row>
        <WeakColorSeparator />
        <NoSubtitleAreaRow title="応募者の設問回答ページ">
          <QuestionRowWrapper>
            {template.questions.map((q, index) => (
              <QuestionRow
                key={q.guid}
                index={index}
                question={questionDict[q.guid]!}
                required={q.required}
              />
            ))}
          </QuestionRowWrapper>
        </NoSubtitleAreaRow>
        <WeakColorSeparator />
        <Row title="応募者の終了ページ" subtitle={'企業からの\nメッセージ'}>
          <C.ItemValue>
            {template.outroVideoURL && (
              <Box width={270}>
                <VideoPlayer src={template.outroVideoURL} />
              </Box>
            )}
            <p>{template.outroMessage}</p>
          </C.ItemValue>
        </Row>
      </C.FormBlockContainer>
    </SettingsSection>
  )
}

const Row = styled(FormRow).attrs({
  titleWidth: '180px',
  subtitleWidth: '72px',
})`
  padding: 20px 0;
`

const NoSubtitleAreaRow = styled(FormRow).attrs({
  titleWidth: '180px',
  subtitleWidth: '0',
})`
  padding: 20px 0;
`

const QuestionRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -28px;
`
