import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Dropdown,
  Icon,
  LineClampedTxt,
  RadioGroup,
  theme,
  Txt as _Txt,
} from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { AddButton } from '@/components/AddButton'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import {
  mapQuestionFormat,
  useAllRecInterviewQuestionTemplates,
} from '@/services/questionTemplateService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { Question } from '../types'
import { StepParam } from './types'
import { questionsMax, questionsMin } from './useRecInterviewTemplateForm'

export const InputQuestions: React.VFC<StepParam> = ({
  form: {
    register,
    errors,
    questionFieldArray,
    onAddQuestion,
    onRemoveQuestion,
    onChangeQuestionGuid,
    onChangeQuestionRequired,
    onMoveQuestion,
    reloadQuestionFieldArray,
  },
}) => {
  reloadQuestionFieldArray()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [questionFieldArray])

  const { data } = useAllRecInterviewQuestionTemplates()

  const questionsOptions = useMemo(
    () =>
      data
        ?.getQuestionTemplatesList()
        .map((q) => ({
          label: q.getName(),
          value: q.getGuid(),
        }))
        .sort((x, y) => x.label.localeCompare(y.label)) ?? [],
    [data]
  )

  const questionDict = useMemo<Record<string, Question>>(
    () =>
      data?.getQuestionTemplatesList().reduce((acc, q) => {
        acc[q.getGuid()] = {
          guid: q.getGuid(),
          name: q.getName(),
          content: q.getContent(),
          note: q.getNote(),
          format: mapQuestionFormat(q.getFormat()),
        }
        return acc
      }, {} as Record<string, Question>) ?? {},
    [data]
  )

  const questions = useMemo(() => {
    return questionFieldArray.map((field) => ({
      ...field,
      ...(questionDict[field.guid ?? ''] ?? {
        name: '',
        content: '',
      }),
    }))
  }, [questionFieldArray, questionDict])

  const indexFromId = useCallback(
    (id?: string) =>
      questionFieldArray
        .map((question, index) => (question.id === id ? index : undefined))
        .filter((index) => index !== undefined)[0],
    [questionFieldArray]
  )

  const errorMessageById = useCallback(
    (id?: string) => {
      const err = errors.questions
      if (err === undefined) return

      const index = indexFromId(id)
      if (index === undefined) return

      return err[index]?.guid?.message
    },
    [indexFromId, errors]
  )

  /**
   * NOTE: 2種類のフォームフィールドで別々に状態を管理している。
   * 行内のフォームフィールドがDnDによる順序変動の影響を受けるがゆえの対策であり、
   * react-hook-formの管理下となるフィールドは別途下方のinput[type=hidden]に記述している。
   *
   * `_questions.${question.id}.*`
   *   - react-hook-formの管理外のフィールド
   *   - ※`question.id`はreact-hook-formによって発行されるIDであり、削除するまでは固定値
   *
   * `questions.${index}.*`
   *   - react-hook-formのuseFieldArrayの管理下のフィールド
   *   - `index`はソートのたびに変動する値であり、画面上に表示されている順序と同等
   */
  return (
    <SettingsSection title="応募者の設問回答ページ">
      <DragDropContext
        onDragEnd={(result) =>
          onMoveQuestion(result.source.index, result.destination?.index ?? 0)
        }
      >
        <Droppable droppableId="questions">
          {(provided, snapshot) => (
            <C.FormBlockContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {questions.map((question, index) => (
                // refs: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md
                <Draggable
                  index={index}
                  key={`${question.id}`}
                  draggableId={`${question.id}`}
                >
                  {(provided, snapshot) => (
                    <Row
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        ...(snapshot.isDragging
                          ? rowStyleInDragging
                          : rowStyleDefault),
                      }}
                    >
                      <QuestionNumber>問{index + 1}</QuestionNumber>
                      <Selector
                        name={`_questions.${question.id}.guid`}
                        options={questionsOptions}
                        value={question.guid}
                        onChange={(e) =>
                          onChangeQuestionGuid(index, e.target.value)
                        }
                        onBlurError={errorMessageById(question.id)}
                      />
                      <Content data-tip data-for={tooltipId(question.guid)}>
                        {question.content}
                      </Content>
                      {question.guid !== '' ? (
                        <Required
                          name={`_questions.${question.id}.required`}
                          defaultValue={question.required}
                          onChange={(e) => {
                            if (
                              e.target.value === '1' ||
                              e.target.value === '0'
                            ) {
                              onChangeQuestionRequired(index, e.target.value)
                            }
                          }}
                        />
                      ) : (
                        <div />
                      )}
                      <MoveButton
                        isDraggable={questions.length > questionsMin}
                        {...provided.dragHandleProps}
                      />
                      {questions.length > 1 ? (
                        <DeleteButton onClick={() => onRemoveQuestion(index)} />
                      ) : (
                        <div />
                      )}
                    </Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </C.FormBlockContainer>
          )}
        </Droppable>
        <ListBottom>
          <AddButton
            onClick={onAddQuestion}
            active={questionFieldArray.length < questionsMax}
          />
        </ListBottom>
      </DragDropContext>
      {questionFieldArray.map((question, index) => (
        <div key={question.id}>
          <input
            {...register(`questions.${index}.guid` as const)}
            value={question.guid}
            type="hidden"
          />
          <input
            {...register(`questions.${index}.required` as const)}
            value={question.required}
            type="hidden"
          />
        </div>
      ))}
      {Object.values(questionDict).map((question) => (
        <Tooltip
          id={tooltipId(question.guid)}
          key={question.guid}
          arrowPosition="topLeft"
        >
          {question.content}
        </Tooltip>
      ))}
    </SettingsSection>
  )
}

const tooltipId = (guid: string): string =>
  `${TOOLTIP_ID_MAP.recInterviewTemplateQuestion}-content-${guid}`

const rowStyleDefault: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
}

const rowStyleInDragging: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
  opacity: '0.6',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
}

const ListBottom = styled.div`
  width: 100%;
  padding: 24px 36px;
`

const Row = styled.div`
  height: 70px;
  background-color: ${theme.color.white[1]}cc;
  border-bottom: solid 1px ${theme.color.gray[4]};

  display: grid;
  grid-template-columns: 40px 300px 220px 300px 30px 30px;
  grid-template-rows: 1fr;
  grid-template-areas: 'number selector content required sort-button delete-button';
  align-items: center;

  & > * {
    margin: 0 8px;
    :is(:first-child) {
      margin-left: 0;
    }
  }
`
const QuestionNumber = styled(_Txt).attrs({ size: 'm' })`
  grid-area: number;
`

const Selector = styled(Dropdown).attrs({
  size: 'l',
  placeholder: '設問を選択してください',
})`
  grid-area: selector;
`

const Content = styled(LineClampedTxt).attrs({
  size: 'm',
  line: 2,
})`
  grid-area: content;
  padding-right: 20px;
`

const Required = styled(RadioGroup).attrs({
  direction: 'row',
  options: [
    { label: '必須', value: '1' },
    { label: '任意', value: '0' },
  ],
})`
  grid-area: required;
  align-items: center;
  /* FIXME: direction="row" の場合は label に margin-bottom は設定されないはずだが、なぜか設定されているので !important で上書きしている */
  /* https://github.com/blue-agency/rogue/blob/master/src/components/RadioGroup/index.tsx#L32 */
  label {
    margin-bottom: 0 !important;
  }
`

const QuestionSortButtonIcon = styled(Icon).attrs({ name: 'move-all' })`
  grid-area: sort-button;
  :hover {
    cursor: pointer;
  }
`

// refs: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/dragging-svgs.md
// > react-beautiful-dnd does not support the usage of <svg>
// > for a <Draggable /> or it's drag handle.
const MoveButton: React.VFC<{ isDraggable: boolean }> = ({
  isDraggable,
  ...props
}) => <div {...props}>{isDraggable && <QuestionSortButtonIcon />}</div>

const DeleteButton = styled(Icon).attrs({ name: 'delete' })`
  grid-area: delete-button;
  :hover {
    cursor: pointer;
  }
`
