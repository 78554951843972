import { useMutation, useQueryClient } from 'react-query'
import {
  CreateApplicantRequestValue,
  useRequestCreateApplicant,
} from '@/services/bffService/useRequestCreateApplicant'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateApplicant() {
  const queryClient = useQueryClient()
  const { requestCreateApplicant } = useRequestCreateApplicant()

  return useMutation(
    (value: CreateApplicantRequestValue) => requestCreateApplicant(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
      },
    }
  )
}
