import { theme, Txt } from '@blue-agency/rogue'
import { format } from 'date-fns'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Box } from '@/components/Box'
import { TableBase } from '@/components/TableBase'
import { getBackgroundJobName } from '@/services/applicantService'
import { BackgroundJob } from '@/services/applicantService'
import { BackgroundJobStatus } from './BackgroundJobStatus'

type Props = {
  jobs: BackgroundJob[]
  onClickErrorDetail: (job: BackgroundJob) => void
}
export const List: React.VFC<Props> = (props) => {
  const isEmpty = props.jobs.length === 0

  return (
    <Wrapper>
      <MainWrapper>
        <Table>
          <thead>
            <tr>
              <Th property="status">ステータス</Th>
              <Th property="name">処理名</Th>
              <Th property="spacing" />
              <Th property="startDate">開始日時</Th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty &&
              props.jobs.map((job) => (
                <tr key={job.jobGuid}>
                  <Td property="status">
                    <Box>
                      <BackgroundJobStatus status={job.status} />
                      {job.status === 'failed' && (
                        <ErrorDetailLink
                          onClick={() => props.onClickErrorDetail(job)}
                        />
                      )}
                    </Box>
                  </Td>
                  <Td property="name">{getBackgroundJobName(job.job)}</Td>
                  <Td property="spacing" />
                  <Td property="startDate">
                    <RegisterTime time={job.startedTime} />
                  </Td>
                </tr>
              ))}
          </tbody>
        </Table>
        {isEmpty && (
          <Txt size="l" textAlign="center">
            処理はありません
          </Txt>
        )}
      </MainWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`

const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
`

type Property = 'status' | 'name' | 'spacing' | 'startDate'

const width: Record<Property, number> = {
  status: 148,
  name: 200,
  spacing: 0,
  startDate: 120,
}

type TableCellProps = {
  property: Property
}

const Table = styled(TableBase)`
  width: 100%;

  /* header */
  tr:nth-of-type(1) th {
    background: ${theme.color.gray[4]};
  }

  th {
    display: flex;
    align-items: center;
    padding-left: 28px;
  }

  tr {
    display: flex;
    align-items: center;
  }

  tbody tr {
    &:hover {
      box-shadow: none;
    }
  }

  td {
    display: flex;
    align-items: center;
    padding-left: 28px;
    height: 60px;
    border-bottom: 1px solid ${theme.color.gray[4]};
  }
`

const Th = styled.th<TableCellProps>`
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  status: css`
    flex: 0 0 ${width.status}px;
  `,
  name: css`
    flex: 0 0 ${width.name}px;
  `,
  spacing: css`
    flex: 1 0 auto;
  `,
  startDate: css`
    flex: 0 0 ${width.startDate}px;
  `,
}

const RegisterTime: React.VFC<{ time?: Date }> = ({ time }) => {
  if (time === undefined) return null

  const first = format(time, 'yyyy/MM/dd')
  const last = format(time, 'H:mm')

  return (
    <Box>
      <Txt>{first}</Txt>
      <Txt color={theme.color.navy[1]}>{last}</Txt>
    </Box>
  )
}

type ErrorDetailLinkProps = {
  onClick: () => void
}
const ErrorDetailLink: React.VFC<ErrorDetailLinkProps> = ({ onClick }) => (
  <ErrorLinkWrapper onClick={onClick}>
    <ErrorLinkTxt>エラー詳細</ErrorLinkTxt>
  </ErrorLinkWrapper>
)

const ErrorLinkWrapper = styled.div`
  cursor: pointer;
`

const ErrorLinkTxt = styled(Txt).attrs({
  size: 's',
})`
  margin-top: 3px;
  color: ${theme.color.blue[2]};
`
