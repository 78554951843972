import React, { useCallback, useMemo } from 'react'
import { StaffsAutosuggest } from '@blue-agency/im-shared-front'
import { Icon, LineClampedTxt, theme } from '@blue-agency/rogue'
import { Dropdown } from '@blue-agency/rogue/im'
import type { Control } from 'react-hook-form'
import { UseFormRegister } from 'react-hook-form'
import styled from 'styled-components'
import { StaffIcon } from '@/components/StaffIcon'
import { assigneeRoleOptions } from '@/services/webInterviewService'
import { Form } from '../NewWebInterviewPageContainer'
import { useAssigneesForm } from './useAssigneesForm'

type Props = {
  register: UseFormRegister<Form>
  control: Control<Form>
}

export const AssigneesForm: React.VFC<Props> = ({ register, control }) => {
  const {
    allAssigneeCandidates,
    selectedAssignees,
    appendAssignee,
    removeAssignee,
    compareFn,
  } = useAssigneesForm({ control })

  const ignoreStaffGuids = useMemo(
    () => selectedAssignees.map((assignee) => assignee.guid),
    [selectedAssignees]
  )

  const onRemoveButtonClick = useCallback(
    (index: number) => () => removeAssignee(index),
    [removeAssignee]
  )

  return (
    <>
      <StaffsAutosuggest
        staffs={allAssigneeCandidates}
        ignoreStaffGuids={ignoreStaffGuids}
        onSelected={appendAssignee}
        sortCompareFn={compareFn}
      />
      {selectedAssignees.length > 0 && (
        <ul>
          {selectedAssignees.map((assignee, index) => (
            <SelectedAssignee key={assignee.guid}>
              <StaffIcon
                name={assignee.name.familyName}
                color={assignee.iconColorCode}
                size="s"
              />
              <AssigneeDescription>
                <AssigneeName>{assignee.name.fullName}</AssigneeName>
                <AssigneeEmail>{assignee.email}</AssigneeEmail>
              </AssigneeDescription>
              <input
                {...register(`assignees.${index}.guid` as const)}
                type="hidden"
                value={assignee.guid}
              />
              <Dropdown
                {...register(`assignees.${index}.role` as const)}
                size="s"
                options={assigneeRoleOptions}
                value={assignee.role}
              />
              <RemoveButton onClick={onRemoveButtonClick(index)}>
                <Icon name="close" size="s" />
              </RemoveButton>
            </SelectedAssignee>
          ))}
        </ul>
      )}
    </>
  )
}

const SelectedAssignee = styled.li`
  align-items: center;
  display: flex;
  padding: 6px;
  width: 460px;

  &:hover {
    background: ${theme.color.gray[5]};
  }
`

const AssigneeDescription = styled.div`
  margin-left: 4px;
  width: 152px;
`

const AssigneeName = styled(LineClampedTxt).attrs({ size: 'm', line: 1 })``
const AssigneeEmail = styled(LineClampedTxt).attrs({
  size: 's',
  line: 1,
  color: theme.color.gray[1],
})``

const RemoveButton = styled.div.attrs({
  role: 'button',
  'aria-label': '担当者から削除',
})`
  align-items: center;
  display: none;
  height: 18px;
  justify-content: center;
  margin-left: auto;
  width: 18px;

  ${SelectedAssignee}:hover & {
    display: flex;
  }
`
