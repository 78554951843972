import { CommonInputPage } from '@/components/WebInterviewGuideForm/CommonInputPage'
import type { Form } from '@/services/webInterviewGuideService'
import { EditWebInterviewGuidePageContainer } from './editWebInterviewGuidePageContainer'

type Props = {
  originalGuide: Form
  guid: string
}
export function InputPage({ originalGuide, guid }: Props) {
  const { completedForm, completeInput } =
    EditWebInterviewGuidePageContainer.useContainer()

  return (
    <CommonInputPage
      type="edit"
      guid={guid}
      originalGuide={originalGuide}
      completedForm={completedForm}
      completeInput={completeInput}
    />
  )
}
