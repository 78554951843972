import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  title: string
  children: React.ReactNode
}>

export const SettingsSection = (props: Props): React.ReactElement => {
  return (
    <div className={props.className}>
      <Header>
        <Txt color={theme.color.navy[1]}>{props.title}</Txt>
      </Header>
      {props.children}
    </div>
  )
}

const Header = styled.div`
  background-color: ${theme.color.navy[3]};
  padding: 8px 20px;
`
