import { Txt, NewTabLink } from '@blue-agency/rogue'
import { EXTERNAL_PATHS } from '@/services/urlService'

type PageType =
  | 'interview_guide_list_page'
  | 'interview_guide_create_page'
  | 'interview_guide_edit_page'
  | 'interview_guide_detail_page'

type Props = {
  pageType: PageType
}

export function LinkToInterviewGuideExamples({ pageType }: Props) {
  return (
    <Txt>
      設定の参考例は
      <NewTabLink
        href={EXTERNAL_PATHS.help.interviewGuideExamples}
        action={`click_interview_guide_examples_link_on_${pageType}`}
      >
        こちら
      </NewTabLink>
    </Txt>
  )
}
