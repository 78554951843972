import { useMemo } from 'react'
import assert from 'assert'

export type Props = {
  scheduledStartTime: Date
  scheduledFinishTime: Date
  startedTime: Date
  currentTime: Date
  finishedTime?: Date
}

export type ReturnType = {
  pre: boolean
  redPer: number
  greenPer: number
  over: boolean
  elapsedDuration: number
  isFinished: boolean
  finishedDuration: number
}

export const useElapsedTimeProgress = ({
  scheduledStartTime,
  scheduledFinishTime,
  startedTime,
  currentTime,
  finishedTime,
}: Props): ReturnType => {
  const pre = useMemo(() => {
    // 2分以上前に面接開始
    return startedTime.getTime() <= scheduledStartTime.getTime() - 2 * 60 * 1000
  }, [scheduledStartTime, startedTime])

  const redPer = useMemo(() => {
    const delayMilliSeconds =
      startedTime.getTime() - scheduledStartTime.getTime()
    const totalMilliSeconds =
      scheduledFinishTime.getTime() - scheduledStartTime.getTime()
    assert(totalMilliSeconds > 0)
    return Math.min(Math.max(delayMilliSeconds / totalMilliSeconds, 0), 1)
  }, [scheduledStartTime, scheduledFinishTime, startedTime])

  const greenPer = useMemo(() => {
    const currentMilliSeconds =
      currentTime.getTime() - scheduledStartTime.getTime()
    const totalMilliSeconds =
      scheduledFinishTime.getTime() - scheduledStartTime.getTime()
    assert(totalMilliSeconds > 0)

    // 開始予定時刻に対して(-2秒 < 開始時刻 <= 0秒)の場合にはグリーン部分が短くなりすぎないように、1分ぶん表示する
    const startedMilliSeconds =
      startedTime.getTime() - scheduledStartTime.getTime()
    const isSmallGreen =
      -2 * 60 * 1000 < startedMilliSeconds && startedMilliSeconds <= 0
    const minimumGreenPer = isSmallGreen ? (60 * 1000) / totalMilliSeconds : 0

    return Math.max(
      minimumGreenPer,
      Math.max(Math.min(currentMilliSeconds / totalMilliSeconds, 1) - redPer, 0)
    )
  }, [
    scheduledStartTime,
    scheduledFinishTime,
    startedTime,
    currentTime,
    redPer,
  ])

  const over = currentTime > scheduledFinishTime

  const elapsedDuration = useMemo(() => {
    return Math.ceil(
      (currentTime.getTime() - startedTime.getTime()) / (60 * 1000)
    )
  }, [startedTime, currentTime])

  const isFinished = !!finishedTime

  const finishedDuration = useMemo(() => {
    if (!finishedTime) {
      return 0
    }
    return Math.ceil(
      (finishedTime.getTime() - startedTime.getTime()) / (60 * 1000)
    )
  }, [startedTime, finishedTime])

  return {
    pre,
    redPer,
    greenPer,
    over,
    elapsedDuration,
    isFinished,
    finishedDuration,
  }
}
