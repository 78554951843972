import { useMemo } from 'react'
import { ListStaffsResponse } from '@blue-agency/proton/biz_hutt_bff'
import assert from 'assert'
import { useListStaffs } from './useListStaffs'

export const useStaffs = () => {
  const { data } = useListStaffs()

  const staffs = useMemo(() => {
    return (
      data
        ?.getStaffsList()
        .filter(
          (staff) =>
            staff.getStatus() === ListStaffsResponse.Status.ACTIVATED ||
            staff.getStatus() === ListStaffsResponse.Status.SUSPENDED
        )
        .map((staff_) => {
          const staff = staff_.toObject()
          assert(staff.name)
          assert(staff.nameKana)
          return {
            guid: staff.guid,
            name: {
              familyName: staff.name.familyName,
              givenName: staff.name.givenName,
            },
            nameKana: {
              familyName: staff.nameKana.familyNameKana,
              givenName: staff.nameKana.givenNameKana,
            },
            email: staff.email,
          }
          // API から値が返却されるまでは空リストを返す
        }) ?? []
    )
  }, [data])

  return staffs
}
