import { useCallback, useMemo } from 'react'
import type { StaffItem } from '@blue-agency/im-shared-front'
import assert from 'assert'
import { FieldArrayWithId, useFieldArray } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import {
  assigneeRoleOptions,
  useLatestSelectedUser,
} from '@/services/webInterviewService'
import type { Staff } from '../../WebInterviewPageContainer'
import { WebInterviewPageContainer } from '../../WebInterviewPageContainer'
import type { Form } from '../useWebInterviewAssignmentForm'

type UseAssigneesForm = {
  control: Control<Form>
  allAssigneeCandidates: Staff[]
}

export const useAssigneesForm = ({
  control,
  allAssigneeCandidates,
}: UseAssigneesForm) => {
  const { webInterview } = WebInterviewPageContainer.useContainer()

  const { select, compareFn } = useLatestSelectedUser()

  assert(webInterview)

  const assigneeFieldArray = useFieldArray({
    control,
    name: 'assignees',
  })

  const selectedAssignees = useMemo(
    () =>
      assigneeFieldArray.fields
        .map((field) => {
          assert(webInterview.assignees)
          const assigneeData =
            allAssigneeCandidates.find(
              (assignee) => assignee.guid === field.guid
            ) ??
            // 担当者候補になければ利用停止ユーザーなので、面接の担当者情報から諸情報を取得する
            webInterview.assignees.find(
              (assignee) => assignee.guid === field.guid
            )
          return assigneeData ? { ...assigneeData, ...field } : undefined
        })
        .filter(
          (
            assignee
          ): assignee is Staff & FieldArrayWithId<Form, 'assignees', 'id'> =>
            !!assignee
        ),
    [allAssigneeCandidates, assigneeFieldArray, webInterview.assignees]
  )

  const appendAssignee = useCallback(
    (staff: StaffItem) => {
      select(staff.guid)
      assigneeFieldArray.append({
        guid: staff.guid,
        role: assigneeRoleOptions[0].value,
      })
    },
    [assigneeFieldArray, select]
  )

  return {
    allAssigneeCandidates,
    selectedAssignees,
    appendAssignee,
    removeAssignee: assigneeFieldArray.remove,
    compareFn,
  }
}
