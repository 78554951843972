import { useCallback, useState } from 'react'
import assert from 'assert'

export type UploaderParams = {
  blob: Blob
  url: string
  abortController?: AbortController
}

/**
 * @returns {Promise<string>} アップロード時にレスポンスで返却されるEtag
 */
type PartUploader = (uploaderParams: UploaderParams) => Promise<string>

export const useMultipartUpload = (
  abortController?: AbortController,
  partUploader: PartUploader = defaultPartUploader
) => {
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const resetState = useCallback(() => {
    setIsError(false)
    setIsUploading(false)
    setIsUploaded(false)
    setProgress(0)
  }, [])

  const upload = useCallback(
    async (parts: { blob: Blob; url: string }[]) => {
      setProgress(0)
      setIsError(false)
      setIsUploaded(false)
      setIsUploading(true)

      let etagList: string[]
      try {
        etagList = await Promise.all(
          parts.map(async (part) => {
            const etag = await partUploader({
              blob: part.blob,
              url: part.url,
              abortController,
            })

            setProgress((prev) => {
              const next = prev + 100 / parts.length
              return next
            })

            return etag
          })
        ) /* eslint-disable  @typescript-eslint/no-explicit-any */
      } catch (err: any) {
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-4.html#defaulting-to-the-unknown-type-in-catch-variables---useunknownincatchvariables
        if (err.name === 'AbortError') {
          resetState()
          throw err
        }
        setIsError(true)
        setIsUploading(false)
        throw err
      }

      setIsError(false)
      setIsUploading(false)
      setIsUploaded(true)

      return etagList
    },
    [resetState, abortController, partUploader]
  )

  return {
    progress,
    upload,
    splitBlob,
    isUploading,
    isError,
    isUploaded,
    resetState,
  }
}

const splitBlob = (blob: Blob): Blob[] => {
  const partSize = 5 * 1024 * 1024 // 5MB/chunk
  const partNum = Math.ceil(blob.size / partSize)
  const parts: Blob[] = []
  for (let i = 0; i < partNum; i++) {
    const startByte = partSize * i
    const endByte = partSize * (i + 1)
    const part = blob.slice(startByte, endByte)
    parts.push(part)
  }

  return parts
}

const defaultPartUploader: PartUploader = async ({
  blob,
  url,
  abortController,
}) => {
  const res = await fetch(url, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': blob.type,
    },
    mode: 'cors',
    signal: abortController?.signal,
  })

  const etag = res.headers.get('ETag')
  assert(etag)
  return etag
}
