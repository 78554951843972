import React from 'react'
import { useRef } from 'react'
import { Txt, Icon, theme, ClickOutsideListener } from '@blue-agency/rogue'
import { InputText } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { EditableContent } from '../EditableContent'
import { useWebInterviewNameForm } from './useWebInterviewNameForm'

type Props = {
  value: string
  editable: boolean
  editing: boolean
  startEditing: () => void
  finishEditing: () => void
}

export const WebInterviewName = React.forwardRef<HTMLInputElement, Props>(
  (
    { value: initialValue, editable, editing, startEditing, finishEditing },
    ref
  ) => {
    const { register, errorMessage, submit } =
      useWebInterviewNameForm(finishEditing)

    const editContainerRef = useRef(null)

    return (
      <>
        {editing ? (
          <ClickOutsideListener
            targetRef={editContainerRef}
            onClick={finishEditing}
          >
            <EditContainer ref={editContainerRef}>
              <InputText
                {...register('name')}
                defaultValue={initialValue}
                fontSize="l"
                size="ll"
                onBlurError={errorMessage}
                errorNoWrap
                autoComplete="off"
              />
              <IconButton aria-label="編集を保存" onClick={submit}>
                <SubmitIcon />
              </IconButton>
              <IconButton aria-label="編集を中止" onClick={finishEditing}>
                <CancelIcon />
              </IconButton>
            </EditContainer>
          </ClickOutsideListener>
        ) : (
          <EditableContent
            editable={editable}
            description="面接名を編集"
            onClick={startEditing}
            width="460px"
          >
            <Txt size="l">{initialValue}</Txt>
          </EditableContent>
        )}
      </>
    )
  }
)

const EditContainer = styled.div`
  align-items: center;
  display: flex;

  & > :not(:first-child) {
    margin-left: 12px;
  }
`

const IconButton = styled.button`
  display: inline-block;
  background: none;
  border: none;
  height: 18px;
  padding: 0;
  width: 18px;
  cursor: pointer;
`

const SubmitIcon = styled(Icon).attrs({ name: 'check' })`
  color: ${theme.color.green[4]};
`

const CancelIcon = styled(Icon).attrs({ name: 'close2' })`
  color: ${theme.color.navy[1]};
`
