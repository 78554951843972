import { theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

/**
 * TabTypeを型引数で渡す e.g. genTab<"profile" | "selection">(...)
 * 具体的な使い方はStorybookを参照
 * @param tabs TabTypeのreadonlyな配列
 * @param mapTabName TabTypeからUI上の表示文言(またはJSX要素)にマッピングする関数
 * @returns
 */
export function genTab<T extends string>(
  tabs: readonly T[],
  mapTabName: (tab: T) => string | JSX.Element
): React.VFC<
  PropsWithClassName<{
    selectedTab: T
    onClick: (tab: T) => void
  }>
> {
  return (props) => (
    <Wrapper className={props.className}>
      {tabs.map((tab) => {
        const selected = props.selectedTab === tab
        return (
          <Tab key={tab} selected={selected} onClick={() => props.onClick(tab)}>
            <Txt color={selected ? theme.color.green[4] : theme.color.navy[1]}>
              {mapTabName(tab)}
            </Txt>
          </Tab>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Tab = styled.div<{ selected: boolean }>`
  width: 100%;
  padding: 8px 0 8px 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? theme.color.green[8] : theme.color.white[1]};

  ${({ selected }) =>
    selected &&
    css`
      border-right: 3px solid ${theme.color.green[4]};
    `};
  cursor: pointer;
`
