import { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { QuestionRequired } from '@/services/recInterviewTemplateService'
import { Form, FormQuestion, formSchema } from './formSchema'

export const questionsMin = 1
export const questionsMax = 30

export type RecInterviewTemplateForm = ReturnType<
  typeof useRecInterviewTemplateForm
>

export const useRecInterviewTemplateForm = (initial: Form) => {
  const [template, setTemplate] = useState<Form>(initial)

  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    setValue,
    setFocus,
  } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: template,
  })

  const watchDueType: string = watch('due.type', 'time')
  useEffect(() => {
    if (template.due.type === watchDueType) return
    setTemplate((prev) => ({
      ...prev,
      due: {
        ...prev.due,
        type: watchDueType,
      },
    }))
    trigger('due')
  }, [setTemplate, template.due.type, trigger, watchDueType])

  const questionFieldArray = useFieldArray({
    control,
    name: 'questions',
  })
  /**
   * 設問の初期値を再読み込みするためのコールバック関数。
   *
   * FieldArray管理下の`input[type=hidden]`は設問入力のステップにのみ存在するため、
   * `input[type=hidden]`が存在しないステップでFieldArrayがクリアされた状態となる。
   * これを回避するためには設問入力のステップに遷移した際にこのコールバック関数を呼ぶ必要がある。
   */
  const reloadQuestionFieldArray = useCallback(() => {
    if (questionFieldArray.fields.length === 0) {
      questionFieldArray.prepend(initial.questions)
    }
  }, [initial.questions, questionFieldArray])
  const onAddQuestion = useCallback(() => {
    if (questionFieldArray.fields.length < questionsMax) {
      questionFieldArray.append({
        guid: '',
        required: '1',
      } as FormQuestion)
    }
  }, [questionFieldArray])
  const onRemoveQuestion = useCallback(
    (index: number) => {
      if (questionFieldArray.fields.length > questionsMin) {
        questionFieldArray.remove(index)
        clearErrors()
      }
    },
    [questionFieldArray, clearErrors]
  )
  const onChangeQuestionGuid = useCallback(
    (index: number, guid: string) => {
      const field = questionFieldArray.fields[index]
      questionFieldArray.update(index, {
        required: field.required,
        guid,
      })
      clearErrors()
    },
    [questionFieldArray, clearErrors]
  )
  const onChangeQuestionRequired = useCallback(
    (index: number, required: QuestionRequired) => {
      const field = questionFieldArray.fields[index]
      questionFieldArray.update(index, {
        guid: field.guid,
        required,
      })
    },
    [questionFieldArray]
  )
  const onMoveQuestion = useCallback(
    (from: number, to: number) => {
      questionFieldArray.move(from, to)
    },
    [questionFieldArray]
  )

  const checkQuestionDuplicates = useCallback((): boolean => {
    const indexesPerGuid = getValues().questions.reduce((prev, q, index) => {
      if (prev[q.guid] === undefined) prev[q.guid] = []
      prev[q.guid].push(index)
      return prev
    }, {} as Record<string, number[]>)

    return (
      Object.entries(indexesPerGuid)
        .filter(([, indexes]) => indexes.length > 1)
        .map(([guid, indexes]) => {
          indexes.forEach((index) => {
            setError(`questions.${index}.guid`, {
              type: 'manual',
              message:
                guid === '' ? '設問を選択してください' : '設問が重複しています',
            })
          })
          return true
        }).length === 0
    )
  }, [getValues, setError])

  const updateTemplate = useCallback(() => {
    const values = getValues()

    if (values.questions !== undefined) {
      values.questions = values.questions.filter((q) => q as FormQuestion)
    }
    setTemplate(values)
  }, [getValues])

  return {
    register,
    errors,
    clearErrors,
    trigger,
    control,
    setValue,
    getValues,
    setFocus,

    template,
    updateTemplate,

    questionFieldArray: questionFieldArray.fields,
    reloadQuestionFieldArray,
    onAddQuestion,
    onRemoveQuestion,
    onChangeQuestionGuid,
    onChangeQuestionRequired,
    onMoveQuestion,
    checkQuestionDuplicates,
  }
}
