import { Txt } from '@blue-agency/rogue'
import { format } from 'date-fns'
import styled from 'styled-components'
import { GenderType } from '@/services/applicantService'
import * as C from '../formComponents'
import { genderOptions } from '../options'
import { UseFormResponse } from '../useApplicantForm'

const getGenderLabel = (type: GenderType) =>
  genderOptions.find((o) => o.value === type)?.label ?? ''

type Props = Pick<UseFormResponse, 'formCtx'>
export const BodyDetails: React.VFC<Props> = ({ formCtx }) => {
  const watch = formCtx.watch()

  return (
    <>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>住所</C.RowLabelMain>
          <C.RowLabelSub>郵便番号</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>{watch.postalCode}</RowTxt>
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>住所</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>{watch.address}</RowTxt>
      </C.Row>

      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>性別</C.RowLabelMain>
          <C.RowLabelSub>性別</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>{getGenderLabel(watch.gender ?? '')}</RowTxt>
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>性別備考</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>{watch.genderNote}</RowTxt>
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>生年月日</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>
          {watch.birthDate && format(watch.birthDate, 'yyyy/MM/dd')}
        </RowTxt>
      </C.Row>
      <C.Separator />

      {watch.educationalBackgroundsList.map((v, i) => {
        return (
          <div key={i}>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelMain>{i === 0 && '最終'}学歴</C.RowLabelMain>
                <C.RowLabelSub>学校名</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.schoolName}</RowTxt>
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>学部・学科</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.faculty}</RowTxt>
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>備考</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.note}</RowTxt>
            </C.Row>

            <C.Separator />
          </div>
        )
      })}

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>学歴備考</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.educationalBackgroundNote}</RowTxt>
      </C.Row>
      <C.Separator />

      {watch.workHistoriesList.map((v, i) => {
        return (
          <div key={i}>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelMain>職務経歴</C.RowLabelMain>
                <C.RowLabelSub>会社名</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.companyName}</RowTxt>
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>職務内容</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.jobDescription}</RowTxt>
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>備考</C.RowLabelSub>
              </C.RowLabel>
              <RowTxt>{v.note}</RowTxt>
            </C.Row>
            <C.Separator />
          </div>
        )
      })}

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>職務経歴備考</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.workHistoryNote}</RowTxt>
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>備考</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.note}</RowTxt>
      </C.Row>

      <MarginBottom />
    </>
  )
}

const MarginBottom = styled.div`
  height: 50px;
`

const RowTxt = styled(Txt)`
  word-break: break-all;
  padding-top: 5px;
`
