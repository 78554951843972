import { Icon, theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { VideoPlayer } from '@/components/VideoPlayer'
import { buttonResetStyle } from '@/styles'

type Props = {
  onSelectFiles: (files: File[]) => void
  onUnselect: () => void
  selectedVideoURL?: string
}

export const VideoInput: React.VFC<Props> = ({
  onSelectFiles,
  onUnselect,
  selectedVideoURL,
}) => {
  const dropzone = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop: onSelectFiles,
  })
  return selectedVideoURL ? (
    <Box position="relative" width={270} height={152}>
      <VideoPlayer src={selectedVideoURL} />
      <Box position="absolute" top={-12} right={-12}>
        <IconButton type="button" onClick={onUnselect}>
          <Icon name="close2" width="24" height="24" />
        </IconButton>
      </Box>
    </Box>
  ) : (
    <FileSelectArea {...dropzone.getRootProps()}>
      <input {...dropzone.getInputProps()} />
      <Box display="flex">
        <Icon name="add-file" width="24" />
        <Txt>ファイルをドラッグ＆ドロップ</Txt>
      </Box>
      <TertiaryButton
        widthSize="L1"
        comlinkPushParams={{
          action: 'click_upload_question_template_video_file_button',
        }}
      >
        ファイル選択
      </TertiaryButton>
    </FileSelectArea>
  )
}

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;
  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const IconButton = styled.button`
  ${buttonResetStyle}
  width: 24px;
  height: 24px;
`
