import { useMemo } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

export const tabs = ['profile', 'selection', 'event', 'history'] as const
export type TabType = (typeof tabs)[number]
const defaultTab: TabType = 'selection'

export const useApplicantQueryParam = () => {
  const [query, setQuery] = useQueryParams({
    t: StringParam, // tab: TabType
  })

  const tabParam = useMemo(() => {
    return getValidTabParam(query.t ?? null)
  }, [query.t])

  return {
    tabParam,
    setQuery,
  }
}

const getValidTabParam = (param: string | null): TabType => {
  if (
    param === 'profile' ||
    param === 'selection' ||
    param === 'event' ||
    param === 'history'
  ) {
    return param
  }
  return defaultTab
}
