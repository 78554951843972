import { useMemo, useCallback } from 'react'
import { toast } from '@blue-agency/rogue'
import { useModal } from '@/hooks/useModal'
import { writeTextToClipboard } from '@/services/clipboardService'
import type { Guide } from './types'
import { useGetWebInterviewGuide } from './useGetWebInterviewGuide'

type Response = {
  guide?: Guide
  isLoading: boolean
  deleteModal: ReturnType<typeof useModal>
  copyGuideGUID: () => void
}

export function useWebInterviewGuide(guid: string): Response {
  const deleteModal = useModal(false)

  const { data, isLoading } = useGetWebInterviewGuide(guid)

  const guide = useMemo(() => {
    const g = data?.getWebInterviewGuide()
    if (g === undefined) {
      return undefined
    }
    const registerTime = g.getRegisterTime()?.toDate()
    if (registerTime === undefined) {
      throw new Error('registerTime is undefined')
    }
    return {
      guid: g.getGuid(),
      name: g.getName(),
      registerTime,
      parts: g.getPartsList().map((part) => part.toObject()),
      firstPartStartSeconds: g.getFirstPartStartSeconds(),
    }
  }, [data])

  const copyGuideGUID = useCallback(() => {
    writeTextToClipboard(guid)
    toast('ガイドIDをコピーしました')
  }, [guid])

  return {
    guide,
    isLoading,
    deleteModal,
    copyGuideGUID,
  }
}
