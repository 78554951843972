import { useMemo } from 'react'
import { Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/dist/im'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { INTERNAL_PATHS } from '@/services/urlService'
import { mapProtoToVideoSeminarReport } from '@/services/videoSeminarService'
import { VideoSeminarReport } from './VideoSeminarReport'
import { useGetVideoSeminarReport } from './useGetVideoSeminarReport'

type Props = {
  videoSeminarGuid: string
}
export const ReportPage: React.VFC<Props> = (props) => {
  const { data, isLoading } = useGetVideoSeminarReport(props.videoSeminarGuid)

  const videoSeminarReport = useMemo(() => {
    return data !== undefined ? mapProtoToVideoSeminarReport(data) : undefined
  }, [data])

  if (isLoading || videoSeminarReport === undefined) {
    return <Loading />
  }

  if (videoSeminarReport.contentReports.length === 0) {
    return <Empty videoSeminarGuid={props.videoSeminarGuid} />
  }

  return <VideoSeminarReport videoSeminarReport={videoSeminarReport} />
}

type EmptyProps = {
  videoSeminarGuid: string
}
const Empty: React.VFC<EmptyProps> = ({ videoSeminarGuid }) => {
  return (
    <EmptyWrapper>
      <Txt size="l">コンテンツが存在しないためレポートは表示できません</Txt>
      <RegisterButtonLink
        to={generatePath(INTERNAL_PATHS.newVideoSeminarContent, {
          videoSeminarGuid,
        })}
      >
        <PrimaryButton
          widthSize="L2"
          comlinkPushParams={{
            action: 'click_register_video_seminar_content_on_report_tab',
          }}
        >
          コンテンツ登録
        </PrimaryButton>
      </RegisterButtonLink>
    </EmptyWrapper>
  )
}

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-top: 20px;
`

const RegisterButtonLink = styled(Link)`
  margin-top: 28px;
  color: inherit;
  text-decoration: none;
`
