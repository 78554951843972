import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestCreateRecInterviewTemplate,
  RecInterviewTemplateInputValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { Form } from './steps/formSchema'

export function useCreateRecInterviewTemplate() {
  const queryClient = useQueryClient()
  const { requestCreateRecInterviewTemplate } =
    useRequestCreateRecInterviewTemplate()

  return useMutation(
    (value: RecInterviewTemplateInputValue) =>
      requestCreateRecInterviewTemplate(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.allRecInterviewTemplates)
      },
    }
  )
}

export const toRecInterviewTemplateInputValue = (
  template: Form
): RecInterviewTemplateInputValue => ({
  name: template.name,
  dueTimestamp:
    template.due.type === 'time'
      ? (() => {
          const d = new Date(template.due.time.time)
          d.setHours(template.due.time.hour)
          return Number(d)
        })()
      : undefined,
  allowRecordedVideo: template.allowRecordedVideo === 'allowed' ? true : false,
  dueDuration: template.due.type === 'days' ? template.due.duration : undefined,
  introMessage: template.introMessage,
  questions: template.questions.map((q) => ({
    guid: q.guid,
    required: q.required === '1',
  })),
  outroMessage: template.outroMessage,
  introVideoFileKey: template.introVideoFileKey,
  outroVideoFileKey: template.outroVideoFileKey,
})
