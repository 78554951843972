import { useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import type { Guides } from './types'
import { useListWebInterviewGuides } from './useListWebInterviewGuides'

type Response = {
  isLoading: boolean
  guides: Guides
}

function useWebInterviewGuidesPage(): Response {
  const { data, isLoading } = useListWebInterviewGuides()

  const guides = useMemo(() => {
    if (data === undefined) {
      return []
    }
    return data.getWebInterviewGuidesList().map((guide) => {
      const registerTime = guide.getRegisterTime()?.toDate()
      if (!registerTime) {
        throw new Error('registerTime is undefined')
      }
      return {
        guid: guide.getGuid(),
        name: guide.getName(),
        registerTime,
      }
    })
  }, [data])

  return {
    guides,
    isLoading,
  }
}

export const WebInterviewGuidesPageContainer = createContainer(
  useWebInterviewGuidesPage
)
