import { useCallback, useMemo } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router'
import { assertNever } from '@/assertions'
import { INTERNAL_PATHS } from '@/services/urlService'

export const tabs = ['info', 'contents', 'report'] as const
export type TabType = (typeof tabs)[number]
export type SwitchTab = (tab: TabType) => void

export const useTabs = (videoSeminarGuid: string) => {
  const history = useHistory()
  const location = useLocation()

  const selectedTab = useMemo<TabType>(() => {
    // NOTE: ビデオセミナー詳細ページはタブをパスで出し分けている
    const paths = location.pathname.split('/')
    switch (paths[paths.length - 1]) {
      case 'info':
        return 'info'
      case 'contents':
        return 'contents'
      case 'report':
        return 'report'
      default:
        return 'info'
    }
  }, [location])

  const switchTab = useCallback<SwitchTab>(
    (tab: TabType) => {
      switch (tab) {
        case 'info':
          history.push(
            generatePath(INTERNAL_PATHS.videoSeminarInfo, { videoSeminarGuid })
          )
          break
        case 'contents':
          history.push(
            generatePath(INTERNAL_PATHS.videoSeminarContents, {
              videoSeminarGuid,
            })
          )
          break
        case 'report':
          history.push(
            generatePath(INTERNAL_PATHS.videoSeminarReport, {
              videoSeminarGuid,
            })
          )
          break
        default:
          assertNever(tab)
      }
    },
    [history, videoSeminarGuid]
  )

  return {
    selectedTab,
    switchTab,
  }
}
