import { useEffect } from 'react'
import { InputText, Modal, WeakColorSeparator } from '@blue-agency/rogue'
import styled from 'styled-components'
import * as C from '@/components/RecInterviewTemplate'
import { RecInterviewTemplateForm } from './useRecInterviewTemplateForm'

type Props = {
  active: boolean
  type: 'intro' | 'outro'
  form: RecInterviewTemplateForm
  placeholder: string
  onBlurError?: string
  onSave: () => void
  onCancel: () => void
}

export const MessageModal: React.VFC<Props> = (props) => {
  useEffect(() => {
    if (props.active) {
      props.form.setFocus(`${props.type}Message`)
    }
  }, [props])

  return (
    <Modal
      size="l"
      title="企業からのメッセージの編集"
      active={props.active}
      onClose={props.onCancel}
    >
      <ModalInner>
        <Label>企業からのメッセージ</Label>
        <TextArea
          {...props.form.register(`${props.type}Message`)}
          placeholder={props.placeholder}
          height="120px"
          onBlurError={props.onBlurError}
        />
      </ModalInner>
      <WeakColorSeparator />
      <ModalBottom>
        <C.CancelButton
          comlinkPushParams={{
            action: `click_cancel_button_on_${props.type}_modal`,
          }}
          onClick={props.onCancel}
        />
        <C.SaveButton
          comlinkPushParams={{
            action: `click_save_button_on_${props.type}_modal`,
          }}
          onClick={props.onSave}
        />
      </ModalBottom>
    </Modal>
  )
}

const ModalInner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 16px;
`

const Label = styled(C.SmallTxt)`
  min-width: 120px;
  margin-right: 16px;
`

const TextArea = styled(InputText).attrs({ type: 'textarea' })`
  & textarea {
    max-height: 340px;
  }
`

const ModalBottom = styled.div`
  margin: auto 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    height: 36px;
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`
