import React from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import {
  SelectionStepMaster,
  SelectionStepType,
} from '@blue-agency/proton/web/v2/im'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { TableBase } from '@/components/TableBase'
import { useModal } from '@/hooks/useModal'
import { AuthzContainer } from '@/services/authzService'
import { CreateModal } from './CreateModal'
import { DeleteModal } from './DeleteModal'
import { UpdateModal } from './UpdateModal'
import { useListSelectionStepMasters } from './useListSelectionStepMasters'

const getTypeName = (type: SelectionStepType): string => {
  switch (type) {
    case SelectionStepType.SELECTION_STEP_TYPE_UNKNOWN:
      throw new Error('invalid type')
    case SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW:
      return '録画面接'
  }
}

export const SelectionStepMastersPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('ListSelectionStepMasters')
  authorizeByRpcName && authorizeByRpcName('CreateSelectionStepMaster')
  authorizeByRpcName && authorizeByRpcName('UpdateSelectionStepMaster')
  authorizeByRpcName && authorizeByRpcName('DeleteSelectionStepMaster')

  const ctx = useListSelectionStepMasters()

  const createModal = useModal(false)
  const updateModal = useModal(false)
  const deleteModal = useModal(false)
  const [selectedSelectionStepMaster, setSelectedSelectionStepMaster] =
    useState<SelectionStepMaster.AsObject>()

  const handleClickRow = useCallback(
    (selectionStepMaster: SelectionStepMaster.AsObject) => () => {
      setSelectedSelectionStepMaster(selectionStepMaster)
      updateModal.open()
    },
    [updateModal]
  )

  const handleDelete = useCallback(
    (selectionStepMaster: SelectionStepMaster.AsObject) => () => {
      setSelectedSelectionStepMaster(selectionStepMaster)
      deleteModal.open()
    },
    [deleteModal]
  )

  if (ctx.isLoading) {
    return <Loading />
  }

  const selectionStepMasters: SelectionStepMaster.AsObject[] =
    ctx.data?.getSelectionStepMastersList().map((s) => ({
      guid: s.getGuid(),
      name: s.getName(),
      type: s.getType(),
    })) ?? []

  const isEmpty = selectionStepMasters.length === 0

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader title="選考ステップ設定" />
      <Wrapper>
        <MainWrapper>
          <Table>
            <thead>
              <tr>
                <Th property="name">選考ステップ名</Th>
                <Th property="type">選考種別</Th>
                <Th property="padding" />
                <Th property="addOrDelete" />
              </tr>
              {!isEmpty && (
                <tr>
                  <Th property="name" />
                  <Th property="type" />
                  <Th property="padding" />
                  <Th property="addOrDelete">
                    <AddButton onClick={createModal.open}>
                      <AddIcon />
                      <AddText>追加</AddText>
                    </AddButton>
                  </Th>
                </tr>
              )}
            </thead>
            <tbody>
              {selectionStepMasters.map((selectionStepMaster) => (
                <tr key={selectionStepMaster.guid}>
                  <Td
                    property="name"
                    onClick={handleClickRow(selectionStepMaster)}
                  >
                    {selectionStepMaster.name}
                  </Td>
                  <Td
                    property="type"
                    onClick={handleClickRow(selectionStepMaster)}
                  >
                    {getTypeName(selectionStepMaster.type)}
                  </Td>
                  <Td
                    property="padding"
                    onClick={handleClickRow(selectionStepMaster)}
                  />
                  <Td property="addOrDelete">
                    <DeleteClickArea
                      onClick={handleDelete(selectionStepMaster)}
                    >
                      <DeleteIconWrapper>
                        <Icon name="delete" />
                      </DeleteIconWrapper>
                    </DeleteClickArea>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
          {isEmpty && (
            <EmptyListMessage>
              <Txt size="l">登録された情報はありません</Txt>
              <CreateButtonOnEmptyListMessage onClick={createModal.open}>
                選考ステップ登録
              </CreateButtonOnEmptyListMessage>
            </EmptyListMessage>
          )}
        </MainWrapper>
      </Wrapper>
      <CreateModal active={createModal.active} onClose={createModal.close} />
      {selectedSelectionStepMaster && (
        <UpdateModal
          selectionStepMaster={selectedSelectionStepMaster}
          active={updateModal.active}
          onClose={updateModal.close}
        />
      )}
      {selectedSelectionStepMaster && (
        <DeleteModal
          active={deleteModal.active}
          onClose={deleteModal.close}
          guid={selectedSelectionStepMaster.guid}
          name={selectedSelectionStepMaster.name}
        />
      )}
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`

const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
`

type Property = 'name' | 'type' | 'padding' | 'addOrDelete'

const width: Record<Property, number> = {
  name: 254,
  type: 200,
  padding: 0,
  addOrDelete: 70,
}

type TableCellProps = {
  property: Property
}

const Table = styled(TableBase)`
  width: 100%;

  /* header */
  tr:nth-of-type(1) th {
    background: ${theme.color.gray[4]};
  }

  /* 追加ボタンのある行 */
  tr:nth-of-type(2) th {
    background: ${theme.color.white[1]};
    border-bottom: 2px solid ${theme.color.gray[4]};
    height: 35px;
  }

  th {
    display: flex;
    align-items: center;
    padding-left: 28px;
  }

  tr {
    display: flex;
    align-items: center;
  }

  td {
    display: flex;
    align-items: center;
    padding-left: 28px;
    height: 42px;
    min-height: 42px;
    border-bottom: 1px solid ${theme.color.gray[4]};
  }
`

const Th = styled.th<TableCellProps>`
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  ${({ property }) => propertyStyles[property]}
  ${({ property }) =>
    property !== 'addOrDelete' &&
    css`
      cursor: pointer;
    `}
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 18px;
`

const AddIcon = styled(Icon).attrs({ name: 'add', width: '11px' })`
  margin-right: 4px;
`

const AddText = styled.span`
  ${AddButton}:hover && {
    text-decoration: underline;
  }
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 37px;
`

const CreateButtonOnEmptyListMessage = styled(PrimaryButton).attrs({
  widthSize: 'L1',
  comlinkPushParams: {
    action: 'click_register_selection_step_master',
  },
})`
  margin-top: 25px;
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    flex: 0 0 ${width.name}px;
  `,
  type: css`
    flex: 0 0 ${width.type}px;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
  addOrDelete: css`
    flex: 0 0 ${width.addOrDelete}px;
  `,
}

const DeleteClickArea = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

const DeleteIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
  padding-top: 3px;

  ${DeleteClickArea}:hover && {
    background-color: ${theme.color.gray[4]};
  }
`
