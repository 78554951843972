import React, { useCallback, useMemo, useState } from 'react'
import { theme, Txt, Sort as _Sort } from '@blue-agency/rogue'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { assertNever } from '@/assertions'
import { Count as _Count } from '@/components/videoSeminar/Count'
import { EllipsisTitleSpan } from '@/components/videoSeminar/EllipsisTitleSpan'
import { FormatTime as _FormatTime } from '@/components/videoSeminar/FormatTime'
import { LineChart } from '@/components/videoSeminar/LineChart'
import {
  VideoSeminarContentReport,
  VideoSeminarReport as VideoSeminarReportType,
} from '@/services/videoSeminarService'

type SortColumn = keyof Pick<
  VideoSeminarContentReport,
  | 'sequence'
  | 'movieDurationSeconds'
  | 'totalViewCount'
  | 'totalViewTimeSeconds'
  | 'averageViewTimeSeconds'
>
type SortOrder = 'asc' | 'desc'
type SortType = {
  column: SortColumn
  order: SortOrder
}
const getDefaultSortOrder = (column: SortColumn): SortOrder => {
  switch (column) {
    case 'sequence':
      return 'asc'
    case 'movieDurationSeconds':
    case 'totalViewCount':
    case 'totalViewTimeSeconds':
    case 'averageViewTimeSeconds':
      return 'desc'
    default:
      assertNever(column)
  }
}
const maxCount = 999999
const defaultSort: SortType = {
  column: 'sequence',
  order: 'asc',
}
type Props = PropsWithClassName<{
  videoSeminarReport: VideoSeminarReportType
}>
export const VideoSeminarReport: React.VFC<Props> = ({
  videoSeminarReport,
  className,
}) => {
  const [selectedContent, setSelectedContent] =
    useState<VideoSeminarContentReport>(
      videoSeminarReport.contentReports[0] ?? undefined
    )

  const [sort, setSort] = useState<SortType>(defaultSort)

  const genSortHandler = useCallback(
    (column: SortColumn) => () => {
      // 現在対象になっているカラムならasc/descを入れ替える
      // 現在対象になっていないカラムならデフォルトのorderでソートする
      setSort((prev) => {
        if (prev.column !== column) {
          return {
            column,
            order: getDefaultSortOrder(column),
          }
        }
        return {
          column,
          order: prev.order === 'asc' ? 'desc' : 'asc',
        }
      })
    },
    []
  )

  const renderSort = (column: SortColumn) => (
    <Sort
      weakColor={theme.color.navy[2]}
      order={sort.column === column ? sort.order : 'none'}
      onClick={genSortHandler(column)}
    />
  )

  const sortedReports = useMemo(() => {
    return videoSeminarReport.contentReports.slice().sort((a, b) => {
      if (sort.order === 'asc') {
        return a[sort.column] - b[sort.column]
      }
      return b[sort.column] - a[sort.column]
    })
  }, [videoSeminarReport, sort])

  if (sortedReports.length === 0) return null

  return (
    <Wrapper className={className}>
      <ReportTopContents>
        <TableArea>
          <Table>
            <TableHead>
              <TableHeaderRow>
                <Th property="no">
                  <Txt>No</Txt>
                  {renderSort('sequence')}
                </Th>
                <Th property="title">
                  <Txt>タイトル</Txt>
                </Th>
                <Th property="totalViewCount">
                  <Txt>再生回数</Txt>
                  {renderSort('totalViewCount')}
                </Th>
                <Th property="totalViewTime">
                  <Txt>総視聴時間</Txt>
                  {renderSort('totalViewTimeSeconds')}
                </Th>
                <Th property="averageViewTime">
                  <Txt>平均視聴時間</Txt>
                  {renderSort('averageViewTimeSeconds')}
                </Th>
                <Th property="movieDuration">
                  <Txt>動画の長さ</Txt>
                  {renderSort('movieDurationSeconds')}
                </Th>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {sortedReports.map((report) => {
                const selected =
                  selectedContent.contentGuid === report.contentGuid
                return (
                  <TableBodyRow
                    key={report.contentGuid}
                    selected={selected}
                    onClick={() => setSelectedContent(report)}
                  >
                    <Td property="no">{report.sequence}</Td>
                    <Td property="title">
                      <ContentTitle selected={selected}>
                        <EllipsisTitleSpan
                          title={report.contentTitle}
                          maxLength={20}
                        />
                      </ContentTitle>
                    </Td>
                    <Td property="totalViewCount">
                      <Count count={report.totalViewCount} max={maxCount} />
                    </Td>
                    <Td property="totalViewTime">
                      <FormatTime
                        timeSeconds={report.totalViewTimeSeconds}
                        maxDigitsOfHoursToShow={2}
                      />
                    </Td>
                    <Td property="averageViewTime">
                      <FormatTime
                        timeSeconds={report.averageViewTimeSeconds}
                        maxDigitsOfHoursToShow={1}
                      />
                    </Td>
                    <Td property="movieDuration">
                      <FormatTime
                        timeSeconds={report.movieDurationSeconds}
                        maxDigitsOfHoursToShow={1}
                      />
                    </Td>
                  </TableBodyRow>
                )
              })}
            </TableBody>
          </Table>
        </TableArea>
        <Counters>
          <Counter>
            <Txt bold>総再生回数</Txt>
            {videoSeminarReport.totalViewCount > maxCount ? (
              <TotalCountSmall>
                {videoSeminarReport.totalViewCount.toLocaleString()}
              </TotalCountSmall>
            ) : (
              <TotalCount>
                {videoSeminarReport.totalViewCount.toLocaleString()}
              </TotalCount>
            )}
            <Txt>回</Txt>
          </Counter>
          <Counter>
            <Txt bold>訪問者数</Txt>
            {videoSeminarReport.visitCount > maxCount ? (
              <TotalCountSmall>
                {videoSeminarReport.visitCount.toLocaleString()}
              </TotalCountSmall>
            ) : (
              <TotalCount>
                {videoSeminarReport.visitCount.toLocaleString()}
              </TotalCount>
            )}
            <Txt>人</Txt>
          </Counter>
        </Counters>
      </ReportTopContents>
      <ChartTitle>視聴維持グラフ ({selectedContent.contentTitle})</ChartTitle>
      <LineChart
        totalCounts={selectedContent.totalViewCount}
        movieDurationSeconds={selectedContent.movieDurationSeconds}
        logs={selectedContent.audienceRetention.playbackLogs}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  padding: 20px;
`

const ReportTopContents = styled.div`
  display: flex;
`

const Counters = styled.div`
  padding: 20px 16px;
  margin: 0 30px;
  width: 216px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 1px ${theme.color.gray[4]};
  border-radius: 5px;
`

const Counter = styled.div`
  display: flex;
`

const TotalCount = styled(Txt).attrs({
  color: theme.color.green[4],
  size: 'xxl',
})`
  line-height: 0.7;
  margin: 0 6px 0 auto;
`

const TotalCountSmall = styled(Txt).attrs({
  color: theme.color.green[4],
  size: 'l',
})`
  line-height: 1.4;
  margin: 0 6px 0 auto;
`

const TableArea = styled.div`
  width: 890px;
  max-height: 200px;
  overflow-y: auto;
`

const Table = styled.table`
  width: 872px;
`

const TableHead = styled.thead`
  height: 34px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const TableBody = styled.tbody``

const TableHeaderRow = styled.tr`
  position: relative;
  display: flex;
  align-items: center;
`

const TableBodyRow = styled.tr<{ selected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${theme.color.green[8]};
    `}
  &:hover {
    background-color: ${theme.color.green[8]};
  }
  border-radius: 12px;
  height: 30px;
  margin: 4px 0;
  cursor: pointer;
`

type Property =
  | 'no'
  | 'title'
  | 'totalViewCount'
  | 'totalViewTime'
  | 'averageViewTime'
  | 'movieDuration'
type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 8px;
  background-color: ${theme.color.gray[4]};
  margin-right: 2px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  /* thは全て左寄せ (ソートアイコンは右寄せになるようspace-between指定) */
  justify-content: space-between;
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  height: 50px;
  margin-right: 2px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  no: css`
    flex: 0 0 66px;
    justify-content: flex-end;
  `,
  title: css`
    flex: 0 0 314px;
    justify-content: flex-start;
  `,
  totalViewCount: css`
    flex: 0 0 106px;
    justify-content: flex-end;
  `,
  totalViewTime: css`
    flex: 0 0 131px;
    justify-content: flex-end;
  `,
  averageViewTime: css`
    flex: 0 0 123px;
    justify-content: flex-end;
  `,
  movieDuration: css`
    flex: 0 0 122px;
    justify-content: flex-end;
  `,
}

const Sort = styled(_Sort)`
  margin-left: 8px;
`

const ChartTitle = styled(Txt).attrs({ bold: true })`
  margin: 20px 0 12px;
`

const ContentTitle = styled(Txt)<{ selected: boolean }>`
  ${({ selected }) =>
    !selected &&
    css`
      color: ${theme.color.blue[2]};
    `}
`

const FormatTime = styled(_FormatTime)`
  font-size: ${theme.fontSize.l};
  & > span {
    font-size: ${theme.fontSize.xs};
  }
`

const Count = styled(_Count)`
  font-size: ${theme.fontSize.l};
  & > span {
    font-size: ${theme.fontSize.xs};
  }
`
