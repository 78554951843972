import * as yup from 'yup'

/**
 * VideoSeminar
 */
export type VideoSeminarForm = {
  title: string
  description: string
}

export const videoSeminarFormSchema: yup.SchemaOf<VideoSeminarForm> = yup
  .object()
  .shape({
    title: yup
      .string()
      .min(1, '入力してください')
      .max(50, '50文字以内で入力してください'),
    description: yup.string().max(3_000, '3,000文字以内で入力して下さい'),
  })
  .defined()

/**
 * VideoSeminarContent
 */
export type VideoSeminarContentForm = {
  title: string
  description: string
  thumbnailFile: File | undefined
  movieFile: File
  movieKey: string
}

const allowedMovieExt = new RegExp('.mp4$', 'i')
const allowedImageExt = new RegExp('(.jpg|.jpeg|.png)$', 'i')

const titleSchema = yup
  .string()
  .min(1, '入力してください')
  .max(50, '50文字以内で入力してください')
const descriptionSchema = yup
  .string()
  .max(100_000, '100,000文字以内で入力して下さい')
const thumbnailSchema = yup
  .mixed<File>()
  .test(
    'thumbnail extension validation',
    'ファイルの拡張子がjpg/jpeg/pngではありません。適切なファイルをアップロードしてください。',
    (file) => {
      // サムネイル画像は必須項目ではないため、undefinedのときはvalidとして扱う
      if (file === undefined) return true
      if (!allowedImageExt.test(file.name)) return false
      return true
    }
  )
  .test(
    'thumbnail file size validation',
    'ファイルの容量が大きすぎるため、アップロードできませんでした。\n※アップロードファイルのサイズ上限は2MBです。',
    (file) => {
      // サムネイル画像は必須項目ではないため、undefinedのときはvalidとして扱う
      if (file === undefined) return true
      if (file.size > 2_000_000) return false
      return true
    }
  )

export const videoSeminarContentFormSchema: yup.SchemaOf<VideoSeminarContentForm> =
  yup
    .object()
    .shape({
      title: titleSchema,
      movieFile: yup
        .mixed<File>()
        .required('動画をアップロードしてください')
        .test(
          'movie extension validation',
          'ファイルの拡張子がmp4ではありません。適切なファイルをアップロードしてください。',
          (file) => {
            if (file === undefined) return false
            if (!allowedMovieExt.test(file.name)) return false
            return true
          }
        )
        .test(
          'movie file size validation',
          'ファイルの容量が大きすぎるため、アップロードできませんでした。\n※アップロードファイルのサイズ上限は1GBです。',
          (file) => {
            if (file === undefined) return false
            if (file.size > 1_000_000_000) return false
            return true
          }
        ),
      movieKey: yup.string().min(1, '動画アップロード完了までお待ちください。'),
      thumbnailFile: thumbnailSchema,
      description: descriptionSchema,
    })
    .defined()

export const videoSeminarContentEditFormSchema: yup.SchemaOf<VideoSeminarContentForm> =
  yup
    .object()
    .shape({
      title: titleSchema,
      movieFile: yup
        .mixed<File>()
        .test(
          'movie extension validation',
          'ファイルの拡張子がmp4ではありません。適切なファイルをアップロードしてください。',
          (file) => {
            // 編集時は再アップロードは必須ではないため、undefinedのときはvalidとして扱う
            if (file === undefined) return true
            if (!allowedMovieExt.test(file.name)) return false
            return true
          }
        )
        .test(
          'movie file size validation',
          'ファイルの容量が大きすぎるため、アップロードできませんでした。\n※アップロードファイルのサイズ上限は1GBです。',
          (file) => {
            // 編集時は再アップロードは必須ではないため、undefinedのときはvalidとして扱う
            if (file === undefined) return true
            if (file.size > 1_000_000_000) return false
            return true
          }
        ),
      movieKey: yup.string(),
      thumbnailFile: thumbnailSchema,
      description: descriptionSchema,
    })
    .defined()
