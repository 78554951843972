import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestCreateWebInterviewGuide,
  CreateWebInterviewGuideRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateWebInterviewGuide() {
  const queryClient = useQueryClient()
  const { requestCreateWebInterviewGuide } = useRequestCreateWebInterviewGuide()

  return useMutation(
    (value: CreateWebInterviewGuideRequestValue) =>
      requestCreateWebInterviewGuide(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviewGuides)
      },
    }
  )
}
