import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { buttonResetStyle } from '@/styles'
import { pageSize } from './useApplicantsPage'

export const CheckNavigator: React.VFC<{
  actionOnCondition: boolean
  searchedApplicantsCount: number
  selectedApplicantsCount: number
  allApplicantsSelected: boolean
  onClearActionOnCondition: () => void
  onClearSelectApplicant: () => void
  onActionOnCondition: () => void
}> = (props) => {
  if (props.selectedApplicantsCount === 0) {
    return null
  }

  if (props.actionOnCondition) {
    return (
      <SelectedTextWrapper>
        <Txt bold>検索条件に一致した全件を選択中</Txt>
        <SelectButton onClick={props.onClearActionOnCondition}>
          選択を解除
        </SelectButton>
      </SelectedTextWrapper>
    )
  }

  // NOTE:一覧画面に見えている件数=検索結果の場合は、検索条件をまたいだ検索は行わない
  const canActionOnCondition =
    props.searchedApplicantsCount > pageSize && props.allApplicantsSelected
  return (
    <SelectedTextWrapper>
      {props.allApplicantsSelected && (
        <Box mr="3px">
          <Txt bold>全</Txt>
        </Box>
      )}
      <SelectedCount bold>{props.selectedApplicantsCount}</SelectedCount>
      <Txt bold>件選択中</Txt>
      {canActionOnCondition ? (
        <SelectButton onClick={props.onActionOnCondition}>
          検索条件に一致した全件を選択
        </SelectButton>
      ) : (
        <SelectButton onClick={props.onClearSelectApplicant}>
          選択を解除
        </SelectButton>
      )}
    </SelectedTextWrapper>
  )
}

const SelectedTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SelectedCount = styled(Txt).attrs({ size: 'l' })`
  margin-right: 3px;
`

const SelectButton = styled.button`
  ${buttonResetStyle};

  color: ${theme.color.blue[2]};
  font-size: ${theme.fontSize.m};

  margin-left: 16px;
`
