import { useCallback, useMemo } from 'react'
import {
  WeakColorSeparator,
  Txt,
  theme,
  Icon as _Icon,
  SecondaryLinkTxt,
  toast,
} from '@blue-agency/rogue'
import { FormRow, TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useModal } from '@/hooks/useModal'
import { writeTextToClipboard } from '@/services/clipboardService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { generateGuestUrl } from '@/services/videoSeminarService'
import { PublicSettingModal } from './PublicSettingModal'

type Props = {
  videoSeminarGuid: string
  title: string
  description: string
  isPublic: boolean
}
export const InfoPage: React.VFC<Props> = (props) => {
  const guestUrl = useMemo(
    () => generateGuestUrl(props.videoSeminarGuid),
    [props.videoSeminarGuid]
  )

  const publicSettingModal = useModal()

  const copyVideoSeminarGuid = useCallback(() => {
    writeTextToClipboard(props.videoSeminarGuid)
    toast('ビデオセミナーIDをコピーしました')
  }, [props.videoSeminarGuid])

  const copyGuestUrl = useCallback(() => {
    writeTextToClipboard(guestUrl)
    toast('ゲスト参加者リンクをコピーしました')
  }, [guestUrl])

  return (
    <>
      <Wrapper>
        <MenuArea>
          <OpenPublicSettingModalButton onClick={publicSettingModal.open}>
            <Icon name="eye" />
            公開設定
          </OpenPublicSettingModalButton>
          <EditButton
            to={generatePath(INTERNAL_PATHS.editVideoSeminar, {
              videoSeminarGuid: props.videoSeminarGuid,
            })}
          >
            <Icon name="edit" />
            編集
          </EditButton>
        </MenuArea>
        <Row title="タイトル">
          <Txt>{props.title}</Txt>
        </Row>
        <WeakColorSeparator />
        <Row title="ビデオセミナーID">
          <Txt>{props.videoSeminarGuid}</Txt>
          <CopyButton
            onClick={copyVideoSeminarGuid}
            comlinkPushParams={{ action: 'copy_videoSeminarGuid' }}
            widthSize="M"
          >
            コピー
          </CopyButton>
        </Row>
        <WeakColorSeparator />
        <Row title="ゲスト参加者ページ">
          <GuestUrlLink href={guestUrl}>
            <SecondaryLinkTxt>{guestUrl}</SecondaryLinkTxt>
          </GuestUrlLink>
          <CopyButton
            onClick={copyGuestUrl}
            comlinkPushParams={{ action: 'copy_video_seminar_guest_url' }}
            widthSize="L1"
          >
            リンクをコピー
          </CopyButton>
        </Row>
        <WeakColorSeparator />
        <Row title="トップ紹介文">
          <Txt>{props.description}</Txt>
        </Row>
        <WeakColorSeparator />
      </Wrapper>
      {publicSettingModal.active && (
        <PublicSettingModal
          videoSeminarGuid={props.videoSeminarGuid}
          onClose={publicSettingModal.close}
          isPublic={props.isPublic}
        />
      )}
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`

const MenuArea = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
  display: flex;
`

const OpenPublicSettingModalButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const EditButton = styled(Link)`
  margin-left: 26px;
  color: ${theme.color.navy[1]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled(_Icon)`
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 3px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '200px',
  subtitleWidth: '0',
})`
  padding: 20px 0;
`

const CopyButton = styled(TertiaryButton).attrs({
  heightSize: 'S',
})`
  margin-left: 8px;
`

const GuestUrlLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  padding: 4px 8px;
  background-color: ${theme.color.gray[5]};
`
