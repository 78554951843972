import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { useWebInterviewUpdateByFilePage } from '@/pages/WebInterviewUpdateByFilePage/useWebInterviewUpdateByFilePage'
import { AuthzContainer } from '@/services/authzService'
import { Completed } from './Completed'
import { Confirm } from './Confirm'
import { Input } from './Input'
import { Mapping } from './Mapping'

export const WebInterviewUpdateByFilePage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('BatchUpdateWebInterviews')

  usePromptBeforeUnload()

  const ctx = useWebInterviewUpdateByFilePage()

  switch (ctx.step) {
    case 'input':
      return <Input {...ctx} />
    case 'mapping':
      return <Mapping {...ctx} />
    case 'confirm':
      return <Confirm {...ctx} />
    case 'completed':
      return <Completed {...ctx} />
  }
}
