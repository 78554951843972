import { useMemo, useCallback } from 'react'
import assert from 'assert'
import { NewWebInterviewPageContainer } from './NewWebInterviewPageContainer'

export function useConfirmPage() {
  const {
    completedForm: form,
    register,
    isRegisterLoading,
    backToInput,
    allAssigneeCandidates,
    guides,
  } = NewWebInterviewPageContainer.useContainer()

  if (form === undefined) {
    throw new Error('form is undefined')
  }

  const interviewers = useMemo(
    () =>
      form.assignees
        ?.filter((assignee) => assignee.role === 'interviewer')
        .map((interviewer) => {
          const interviewerData = allAssigneeCandidates.find(
            (assignee) => assignee.guid === interviewer.guid
          )
          assert(interviewerData)
          return interviewerData
        }),
    [form, allAssigneeCandidates]
  )

  const viewers = useMemo(
    () =>
      form.assignees
        ?.filter((assignee) => assignee.role === 'viewer')
        .map((viewer) => {
          const interviewerData = allAssigneeCandidates.find(
            (assignee) => assignee.guid === viewer.guid
          )
          assert(interviewerData)
          return interviewerData
        }),
    [form, allAssigneeCandidates]
  )

  const lookupGuideNameByGuid = useCallback(
    (guid: string) => {
      return guides.find((g) => g.guid === guid)?.name
    },
    [guides]
  )

  return {
    form,
    isRegisterLoading,
    register,
    backToInput,
    interviewers,
    viewers,
    lookupGuideNameByGuid,
  }
}
