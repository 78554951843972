import { Icon, theme, Txt } from '@blue-agency/rogue'
import { format } from 'date-fns'
import styled from 'styled-components'

type Props = {
  scheduledStartTime: Date | undefined
  durationMinutes: number | undefined
}

export function WebInterviewScheduleAndDurationInner(props: Props) {
  return (
    <Wrapper>
      <ScheduledStartTime>
        <ScheduledStartTimeIcon />
        <Content>
          <Label>予定日時：</Label>
          <Value>
            {props.scheduledStartTime === undefined
              ? '未設定'
              : format(props.scheduledStartTime, 'yyyy/MM/dd HH:mm')}
          </Value>
        </Content>
      </ScheduledStartTime>
      <Duration>
        <DurationIcon />
        <Content>
          <Label>目安：</Label>
          {props.durationMinutes === undefined ? (
            <Value>未設定</Value>
          ) : (
            <Value>
              {props.durationMinutes}
              <Unit>分</Unit>
            </Value>
          )}
        </Content>
      </Duration>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 18px;
  }
`

const ScheduledStartTime = styled.div`
  display: flex;
  align-items: center;
`

const Duration = styled.div`
  display: flex;
  align-items: center;
`

const ScheduledStartTimeIcon = styled(Icon).attrs({
  name: 'calendar-with-timer',
  size: 'm',
})`
  color: ${theme.color.navy[1]};
`

const DurationIcon = styled(Icon).attrs({
  name: 'duration',
  size: 'm',
})`
  color: ${theme.color.navy[1]};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 4px 0 4px;
`

const Label = styled(Txt).attrs({
  size: 'm',
})`
  color: ${theme.color.gray[1]};
`

const Value = styled(Txt).attrs({ size: 'm' })``

const Unit = styled.span`
  font-size: ${theme.fontSize.m};
`
