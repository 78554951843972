import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ReorderVideoSeminarContentsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ReorderVideoSeminarContentsRequestValue = {
  videoSeminarGuid: string
  videoSeminarContentGuids: string[]
}

export function useRequestReorderVideoSeminarContents() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestReorderVideoSeminarContents = useCallback(
    async (v: ReorderVideoSeminarContentsRequestValue) => {
      const metadata = await buildMetadata()

      const req = new ReorderVideoSeminarContentsRequest()
      req.setVideoSeminarGuid(v.videoSeminarGuid)
      req.setVideoSeminarContentGuidsList(v.videoSeminarContentGuids)

      return callBizHuttBffService(
        'reorderVideoSeminarContents',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestReorderVideoSeminarContents,
  }
}
