import { useQuery } from 'react-query'
import { useRequestListAllRecInterviewTemplates } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListAllRecInterviewTemplates() {
  const { requestListAllRecInterviewTemplates } =
    useRequestListAllRecInterviewTemplates()

  return useQuery(
    [QUERY_KEY.allRecInterviewTemplates],
    requestListAllRecInterviewTemplates
  )
}
