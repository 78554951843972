import { format } from 'date-fns'
import { ja } from 'date-fns/locale'

// FIXME: firefoxのみ、半角スペースがないとdatepickerでエラーが発生する
// https://github.com/blue-agency/biz-hutt-front/pull/564
export const dateFormatString = 'yyyy/MM/dd (E)'

export function formatTime(d: Date): string {
  return format(d, dateFormatString, { locale: ja })
}
