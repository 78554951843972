import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { mapBackgroundJobFromProto } from '@/services/applicantService'
import { useRequestListSelectionBackgroundJobs } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useApplicantsBackgroundJob = () => {
  const { data, isLoading } = useListSelectionBackgroundJobs()

  const jobs = useMemo(() => {
    if (data === undefined) return undefined
    return data.getSelectionBackgroundJobsList().map(mapBackgroundJobFromProto)
  }, [data])

  return {
    jobs,
    isLoading,
  }
}

const useListSelectionBackgroundJobs = () => {
  const { requestListSelectionBackgroundJobs } =
    useRequestListSelectionBackgroundJobs()

  return useQuery(QUERY_KEY.applicantsBackgroundJobs, () =>
    requestListSelectionBackgroundJobs()
  )
}
