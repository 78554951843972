import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRequestGetApplicant } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useApplicant = () => {
  const { applicantGuid } = useParams<{ applicantGuid: string }>()

  const { data } = useGetApplicant(applicantGuid)

  const applicant = useMemo(() => data?.getApplicant(), [data])

  return {
    applicantGuid,
    applicant,
  }
}

const useGetApplicant = (guid: string) => {
  const { requestGetApplicant } = useRequestGetApplicant()

  return useQuery([QUERY_KEY.applicant, guid], () => requestGetApplicant(guid))
}
