import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestUpdateWebInterviewGuide,
  UpdateWebInterviewGuideRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateWebInterviewGuide() {
  const queryClient = useQueryClient()
  const { requestUpdateWebInterviewGuide } = useRequestUpdateWebInterviewGuide()

  return useMutation(
    (value: UpdateWebInterviewGuideRequestValue) =>
      requestUpdateWebInterviewGuide(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviewGuides)
        queryClient.invalidateQueries([QUERY_KEY.webInterviewGuide, req.guid])
      },
    }
  )
}
