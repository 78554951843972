import { useCallback } from 'react'
import { AuthContainer } from '@blue-agency/im-shared-front'

export function useMetadata() {
  const { authClient } = AuthContainer.useContainer()

  const buildMetadata = useCallback(async () => {
    const token = await authClient.getVerifiedToken()
    return {
      authorization: token,
    }
  }, [authClient])

  return {
    buildMetadata,
  }
}
