import React, { useCallback } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt, theme } from '@blue-agency/rogue'
import { toast } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton, FormRow } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useHistory, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { VideoSeminarForm } from '@/services/videoSeminarService'
import { useCreateVideoSeminar } from './useCreateVideoSeminar'

type Props = {
  backToInput: () => void
  form: VideoSeminarForm | undefined
}

export const ConfirmPage: React.VFC<Props> = ({ backToInput, form }) => {
  const { mutateAsync } = useCreateVideoSeminar()
  const history = useHistory()

  if (!form) {
    throw new Error('form is undefined')
  }

  const createVideoSeminar = useCallback(async () => {
    try {
      const res = await mutateAsync({
        title: form.title,
        description: form.description,
      })

      return res.getVideoSeminarGuid()
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }
  }, [form, mutateAsync])

  const onSubmit = useCallback(async () => {
    const videoSeminarGuid = await createVideoSeminar()
    if (!videoSeminarGuid) {
      return
    }
    history.push(
      generatePath(INTERNAL_PATHS.videoSeminarInfo, {
        videoSeminarGuid: videoSeminarGuid,
      })
    )
    toast('ビデオセミナーを登録しました')
  }, [createVideoSeminar, history])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>ビデオセミナー登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容を確認">
            <Row title="タイトル">
              <Txt>{form.title}</Txt>
            </Row>
            <WeakColorSeparator />
            <Row title="トップ紹介文">
              <Description>{form.description}</Description>
            </Row>
            <WeakColorSeparator />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_cancel_register_video_seminar_button',
              }}
              onClick={backToInput}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_register_video_seminar_button',
              }}
              onClick={onSubmit}
            >
              登録
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Row = styled(FormRow)`
  padding: 20px;
`
const Description = styled(Txt)`
  word-break: break-all;
`
