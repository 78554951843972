import { useState, useCallback } from 'react'
import {
  WeakColorSeparator,
  Modal,
  Txt,
  CheckBox as _CheckBox,
  theme,
  ErrorBox,
  Icon,
} from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Count as Count_ } from '@/components/Count'
import { InfoBox } from '@/components/InfoBox'
import {
  getSelectionStatusText,
  SelectionStatus,
} from '@/services/applicantService'

type Props = {
  toStatus: SelectionStatus
  limit: number
  error: BatchUpdateSelectionStatusOnConditionErrorType
  onSubmit: () => void
  onClose: () => void
}

export type BatchUpdateSelectionStatusOnConditionErrorType =
  | { level: 'none' }
  | { level: 'overLimit' }
  | { level: 'includeArrival' }

export const BatchUpdateSelectionStatusOnConditionModal: React.VFC<Props> = ({
  toStatus,
  limit,
  error,
  onSubmit,
  onClose,
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const toggleConfirmed = useCallback(() => {
    setConfirmed((prevState) => !prevState)
  }, [])
  const canSubmit = error.level === 'none' && confirmed
  const actionText = getSelectionStatusText(toStatus)

  return (
    <Modal
      active
      title={`応募者を${actionText}に変更`}
      size="l"
      onClose={onClose}
      stopScroll
    >
      <ModalBody>
        {error.level === 'overLimit' && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                対象件数が上限の
                <Count count={limit} />
                件を超えています。
                <br />
                件数を減らして、再度実行してください。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        {error.level === 'includeArrival' && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                「新着」の応募者が含まれている可能性があります。
                <br />
                検索条件から「新着」を解除してください。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <DescriptionWrapper>
          <Txt>
            指定した検索条件に一致した、すべての応募者のステータスを
            <Bold>「{actionText}」</Bold>に変更します。
            <br />
            検索条件に一致する応募者が処理中に追加された場合、その応募者も対象となります。
          </Txt>
          <InfoBox
            info={[
              '処理の完了まで、数分かかる場合があります。',
              <ProcessInfoWrapper key="info-process-key">
                <Txt>進捗は</Txt>
                <ProcessIcon />
                <Txt>処理状況から確認ができます。</Txt>
              </ProcessInfoWrapper>,
            ]}
          />
        </DescriptionWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <Row title="確認" required>
          <CheckBox
            checked={confirmed}
            onChange={toggleConfirmed}
            size={18}
            checkboxLabel="内容に誤りがないことを確認しました"
          />
        </Row>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: `click_batch_update_selection_status_to_${toStatus}_on_search_condition_cancel_button`,
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={onSubmit}
            comlinkPushParams={{
              action: `click_batch_update_selection_status_to_${toStatus}_on_search_condition_button`,
            }}
          >
            一括変更
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const Count = styled(Count_).attrs({
  color: theme.color.red[2],
  size: theme.fontSize.s,
})`
  display: inline-block;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const Bold = styled.span`
  font-weight: bold;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '183px', subtitleWidth: '0' })`
  margin-bottom: 10px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`

const ProcessInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ProcessIcon = styled(Icon).attrs({ name: 'process' })`
  display: 'inline-block';
  width: 18px;
  height: 18px;
  margin: 0 5px;
`
