import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

const TabWrapper = styled.div`
  overflow: hidden;
`

const TabsHeader = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ isMobile }) =>
    isMobile ? `1px solid ${theme.color.gray[4]}` : 'none'};
  padding: ${({ isMobile }) => (isMobile ? '0 8px' : '0')};
  margin-top: ${({ isMobile }) => (isMobile ? '18px' : '0')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'none')};
`

const Tabs = styled.div<{ isMobile?: boolean; isOverflow: boolean }>`
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  position: relative;
  width: ${({ isMobile }) =>
    isMobile ? 'auto' : 'calc(100vw - 160px - 40px - 48px)'};
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari 対応 */
  }
  border-top: ${({ isOverflow }) =>
    isOverflow ? `1px solid ${theme.color.gray[4]}` : 'none'};
  border-left: ${({ isOverflow }) =>
    isOverflow ? `1px solid ${theme.color.gray[4]}` : 'none'};
  border-right: ${({ isOverflow }) =>
    isOverflow ? `1px solid ${theme.color.gray[4]}` : 'none'};
`

const TabsInner = styled.ul<{ isMobile?: boolean; isOverflow: boolean }>`
  display: flex;

  border-top: ${({ isOverflow }) =>
    isOverflow ? 'none' : `1px solid ${theme.color.gray[4]}`};
  border-left: ${({ isOverflow }) =>
    isOverflow ? 'none' : `1px solid ${theme.color.gray[4]}`};
  border-right: ${({ isOverflow }) =>
    isOverflow ? 'none' : `1px solid ${theme.color.gray[4]}`};
`

const TabItem = styled.li<{ current: boolean }>`
  flex: 1;
  order: -1;
  position: relative;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  list-style: none;
  min-width: 113px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  padding: 8px;
  font-weight: ${({ current }) => (current ? 'bold' : 'normal')};
  border-bottom: 5px solid
    ${({ current }) => (current ? theme.color.navy[1] : theme.color.white[1])};
  line-height: 1.8;
`

const TabContentsWrapper = styled.div<{ isPc?: boolean }>`
  padding: 16px 12px;
  border: ${({ isPc }) => (isPc ? `1px solid ${theme.color.gray[4]}` : '0')};
  border-radius: ${({ isPc }) => (isPc ? '4px' : '0')};
`

const TabContents = styled.div<{ current: boolean; isMobile?: boolean }>`
  height: ${({ current }) => (current ? 'auto' : '0')};
  opacity: ${({ current }) => (current ? '1' : '0')};
  order: ${({ current }) => (current ? '1' : '0')};
  pointer-events: ${({ current }) => (current ? 'auto' : 'none')};
  left: 0;
  position: sticky;
  max-width: ${({ isMobile }) =>
    isMobile ? 'auto' : 'calc(100vw - 160px - 40px - 40px)'};
`

type TabNavButtonProps = {
  active?: boolean
}

const TabNavButton = styled.button<TabNavButtonProps>`
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid
    ${({ active }) => (active ? theme.color.navy[1] : theme.color.navy[3])};
  border-radius: 50px;
  background: ${({ active }) =>
    active ? theme.color.navy[1] : theme.color.navy[3]};
  color: ${theme.color.white[1]};
  cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
  align-items: center;
  padding: 2px;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
`

const TabNavPrevButton = styled(TabNavButton).attrs<TabNavButtonProps>(
  (props) => ({
    active: props.active,
  })
)<TabNavButtonProps>`
  margin-right: 6px;
`
const TabNavNextButton = styled(TabNavButton).attrs<TabNavButtonProps>(
  (props) => ({
    active: props.active,
  })
)<TabNavButtonProps>`
  margin-left: 6px;
  svg {
    transform: rotate(180deg);
  }
`

export {
  TabWrapper,
  TabsHeader,
  Tabs,
  TabsInner,
  TabItem,
  TabContentsWrapper,
  TabContents,
  TabNavPrevButton,
  TabNavNextButton,
}
