import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Applicant, SelectionStep } from '@/services/applicantService'
import { ChangePlayingAnswerVideo } from '../AnswerVideoPreview/useAnswerVideoPreview'
import type { TabType } from '../useTabs'
import { Event } from './Event'
import { Profile } from './Profile'
import { Selection } from './Selection'

type Props = PropsWithClassName<{
  selectedTab: TabType
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  targetSelectionStep: SelectionStep
  changePlayingAnswerVideo: ChangePlayingAnswerVideo
  organizationRecInterviewTranscriptionSetting: boolean
}>
export const Content: React.VFC<Props> = (props) => {
  const applicantName = `${props.applicant.familyName} ${props.applicant.givenName}`
  const applicantNameKana = `${props.applicant.familyNameKana} ${props.applicant.givenNameKana}`

  useEffect(() => {
    window.scroll({ top: 0 })
  }, [props.selectedTab])

  const content = (() => {
    switch (props.selectedTab) {
      case 'profile':
        return (
          <Profile
            applicantGuid={props.applicantGuid}
            applicant={props.applicant}
            registerTime={props.registerTime}
          />
        )
      case 'selection':
        /**
         * 評価ページSPでは、選考タブ以外でも回答動画を再生し続ける必要がある。
         * そのため、タブが切り替わっても選考タブをレンダリングし続けて音声を再生し続けて、別タブ上部での映像はAnswerVideoPreviewで表示する
         */
        return null
      case 'event':
        return (
          <Event
            applicantGuid={props.applicantGuid}
            applicantName={applicantName}
            applicantNameKana={applicantNameKana}
          />
        )
    }
  })()

  return (
    <Wrapper className={props.className}>
      <SelectionWrapper isSelectionSelected={props.selectedTab === 'selection'}>
        <Selection
          applicantName={applicantName}
          applicantNameKana={applicantNameKana}
          targetSelectionStep={props.targetSelectionStep}
          changePlayingAnswerVideo={props.changePlayingAnswerVideo}
          organizationRecInterviewTranscriptionSetting={
            props.organizationRecInterviewTranscriptionSetting
          }
        />
      </SelectionWrapper>
      {content}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
`

const SelectionWrapper = styled.div<{ isSelectionSelected: boolean }>`
  ${({ isSelectionSelected }) =>
    !isSelectionSelected &&
    css`
      visibility: hidden;
      max-height: 0;
      overflow-y: hidden;
    `}
`
