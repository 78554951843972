import { useCallback, useMemo } from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SelectionStepResult, Selection } from '@/services/applicantService'
import { Store, Action } from '../applicantInputsReducer'
import { CurrentSelectionStepResult } from './CurrentSelectionStepResult'
import { SelectionStepResultComment } from './SelectionStepResultComment'

type Props = PropsWithClassName<{
  applicantGuid: string
  applicantInputState: Store
  applicantInputDispatch: React.Dispatch<Action>
  selection: Selection
}>
export const Input: React.VFC<Props> = (props) => {
  const onCurrentSelectionStepResultClose = useCallback(() => {
    props.applicantInputDispatch({
      type: 'CLOSE_CURRENT_SELECTION_STEP_RESULT',
    })
  }, [props])

  const onSelectionStepResultCommentClose = useCallback(() => {
    props.applicantInputDispatch({
      type: 'CLOSE_SELECTION_STEP_RESULT_COMMENT',
    })
  }, [props])

  const selectedSelectionStep = useMemo(() => {
    return props.selection.selectionStepsList.find(
      (s) => s.guid === props.applicantInputState.selectedSelectionStepGuid
    )
  }, [props.selection, props.applicantInputState])

  const content = (() => {
    switch (props.applicantInputState.input) {
      case 'currentSelectionStepResult':
        return (
          <CurrentSelectionStepResult
            applicantGuid={props.applicantGuid}
            selectionStepGuid={selectedSelectionStep?.guid ?? ''}
            selectionStepName={selectedSelectionStep?.name ?? ''}
            selectionStepResult={
              selectedSelectionStep?.result ??
              SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN
            }
            comment={selectedSelectionStep?.comment ?? ''}
            onClose={onCurrentSelectionStepResultClose}
          />
        )
      case 'selectionStepResultComment':
        return (
          <SelectionStepResultComment
            applicantGuid={props.applicantGuid}
            selectionGuid={props.selection.guid}
            selectionStepGuid={selectedSelectionStep?.guid ?? ''}
            selectionStepName={selectedSelectionStep?.name ?? ''}
            selectionStepResult={
              selectedSelectionStep?.result ??
              SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN
            }
            comment={selectedSelectionStep?.comment ?? ''}
            onClose={onSelectionStepResultCommentClose}
          />
        )
    }
  })()

  return <div className={props.className}>{content}</div>
}
