import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { mapReqRecInterviewJobFailureFromProto } from '@/services/applicantService'
import { useRequestListRequestRecInterviewAnswerFailureDetails } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useListRequestRecInterviewAnswerFailureDetails = (
  guid: string
) => {
  const { data, isLoading } = useFailureDetails(guid)

  const failure = useMemo(() => {
    if (data === undefined) return undefined
    return mapReqRecInterviewJobFailureFromProto(data)
  }, [data])

  return {
    failure,
    isLoading,
  }
}

const useFailureDetails = (guid: string) => {
  const { requestListRequestRecInterviewAnswerFailureDetailsRequest } =
    useRequestListRequestRecInterviewAnswerFailureDetails()

  return useQuery([QUERY_KEY.reqRecInteviewJobFailure, guid], () =>
    requestListRequestRecInterviewAnswerFailureDetailsRequest(guid)
  )
}
