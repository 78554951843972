import { useCallback, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListUpcomingWebInterviewsResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { add } from 'date-fns'
import { differenceInMinutes } from 'date-fns'
import { assertIsDefined } from '@/assertions'
import type {
  TabType,
  OngoingInterview,
  UpcomingInterview,
  Interviewer,
  Interviewee,
  RequestingInterviewee,
} from '@/pages/ControlCenterPage/types'
import { useListOngoingWebInterviews } from '@/pages/ControlCenterPage/useListOngoingWebInterviews'
import { useListUpcomingWebInterviews } from '@/pages/ControlCenterPage/useListUpcomingWebInterviews'

const PER_PAGE = 100

const useControlCenterPage = () => {
  const [tabType, setTabType] = useState<TabType>('upcoming')
  const [loadTime, setLoadTime] = useState<Date>(new Date())
  const loggedInStaff = useLoggedInStaff()
  const {
    data: ongoingData,
    refetch: refetchOngoing,
    isLoading: isOngoingLoading,
    isFetching: isOngoingFetching,
  } = useListOngoingWebInterviews()
  const {
    data: upcomingData,
    refetch: refetchUpcoming,
    isLoading: isUpcomingLoading,
    isFetching: isUpcomingFetching,
  } = useListUpcomingWebInterviews()

  const isLoading =
    isOngoingLoading || isUpcomingLoading || loggedInStaff === undefined
  const isFetching = isOngoingFetching || isUpcomingFetching
  const isRefetching = isFetching && !isLoading

  const ongoingList = useMemo<OngoingInterview[]>(() => {
    const webInterviewsList = ongoingData?.getWebInterviewsList() ?? []
    const translatedOngoingInterviews = webInterviewsList.map(
      (webInterview): OngoingInterview => {
        const startTime = webInterview.getStartTime()?.toDate()
        assertIsDefined(startTime)

        const { scheduledStartTime, scheduledFinishTime } = complementTime({
          scheduledStartTime: webInterview.getScheduledStartTime()?.toDate(),
          durationSeconds: webInterview.getDurationSeconds()?.getValue(),
          startTime: startTime,
        })

        const interviewers: Interviewer[] = webInterview
          .getInterviewersList()
          .map((i) => {
            const joined = i.getJoined()
            if (joined !== undefined) {
              const name = joined.getName()
              assertIsDefined(name)
              return {
                joined: true,
                soraClientId: joined.getSoraClientId(),
                staffGuid: joined.getStaffGuid(),
                familyName: name.getFamilyName(),
                fullName: `${name.getFamilyName()} ${name.getGivenName()}`,
                iconColorCode: joined.getIconColorCode(),
                joinTime: joined.getJoinTime()!.toDate(),
                assigned: joined.getAssigned(),
              }
            }
            const notJoined = i.getNotJoined()
            if (notJoined !== undefined) {
              const name = notJoined.getName()
              assertIsDefined(name)
              return {
                joined: false,
                staffGuid: notJoined.getStaffGuid(),
                familyName: name.getFamilyName(),
                fullName: `${name.getFamilyName()} ${name.getGivenName()}`,
                iconColorCode: notJoined.getIconColorCode(),
              }
            }

            throw new Error('unreachable')
          })

        const interviewees: Interviewee[] = webInterview
          .getIntervieweesList()
          .map((i) => {
            return {
              soraClientId: i.getSoraClientId(),
              displayName: i.getName(),
              joinTime: i.getJoinTime()!.toDate(),
            }
          })

        const requestingInterviewees: RequestingInterviewee[] = webInterview
          .getRequestingIntervieweesList()
          .map((ri) => {
            return {
              displayName: ri.getName(),
              requestTime: ri.getRequestTime()!.toDate(),
            }
          })

        return {
          guid: webInterview.getGuid(),
          name: webInterview.getName(),
          scheduledStartTime,
          scheduledFinishTime,
          startTime,
          finishTime: webInterview.getFinishTime()?.toDate(),
          interviewers,
          interviewees,
          requestingInterviewees,
        }
      }
    )
    return translatedOngoingInterviews
  }, [ongoingData])

  const upcomingList = useMemo<UpcomingInterview[]>(() => {
    const webInterviewsList = upcomingData?.getWebInterviewsList() ?? []
    const translatedUpcomingInterviews = webInterviewsList.map(
      (webInterview): UpcomingInterview => {
        const scheduledStartDate = webInterview
          .getScheduledStartTime()
          ?.toDate()
        assertIsDefined(scheduledStartDate)

        const scheduledFinishDate = calcScheduledFinishDate(webInterview)

        const interviewers: Interviewer[] = webInterview
          .getInterviewersList()
          .map((i) => {
            const joined = i.getJoined()
            if (joined !== undefined) {
              const name = joined.getName()
              assertIsDefined(name)
              return {
                joined: true,
                soraClientId: joined.getSoraClientId(),
                staffGuid: joined.getStaffGuid(),
                familyName: name.getFamilyName(),
                fullName: `${name.getFamilyName()} ${name.getGivenName()}`,
                iconColorCode: joined.getIconColorCode(),
                joinTime: joined.getJoinTime()!.toDate(),
                assigned: joined.getAssigned(),
              }
            }
            const notJoined = i.getNotJoined()
            if (notJoined !== undefined) {
              const name = notJoined.getName()
              assertIsDefined(name)
              return {
                joined: false,
                staffGuid: notJoined.getStaffGuid(),
                familyName: name.getFamilyName(),
                fullName: `${name.getFamilyName()} ${name.getGivenName()}`,
                iconColorCode: notJoined.getIconColorCode(),
              }
            }

            throw new Error('unreachable')
          })

        const requestingInterviewees: RequestingInterviewee[] = webInterview
          .getRequestingIntervieweesList()
          .map((ri) => {
            return {
              displayName: ri.getName(),
              requestTime: ri.getRequestTime()!.toDate(),
            }
          })

        const isPassed = (currentDate: Date) => {
          return scheduledStartDate < currentDate
        }

        const isPassedRecently = (currentDate: Date) => {
          const nearCurrentDate = new Date(currentDate.getTime())
          nearCurrentDate.setMinutes(nearCurrentDate.getMinutes() - 30)
          return nearCurrentDate < scheduledStartDate && isPassed(currentDate)
        }

        const getLatestIntervieweeLogTimeDiff = () => {
          const latestIntervieweeLogTimestamp =
            webInterview.getLatestIntervieweeLogTimestamp()
          if (!latestIntervieweeLogTimestamp) return undefined
          return differenceInMinutes(
            new Date().getTime(),
            latestIntervieweeLogTimestamp.toDate().getTime(),
            { roundingMethod: 'ceil' }
          )
        }

        const latestIntervieweeLogTimeDiff = getLatestIntervieweeLogTimeDiff()

        return {
          guid: webInterview.getGuid(),
          scheduledStartTime: scheduledStartDate,
          scheduledFinishTime: scheduledFinishDate,
          name: webInterview.getName(),
          interviewers,
          requestingInterviewees,
          latestIntervieweeLogTimeDiff,
          isPassed,
          isPassedRecently,
        }
      }
    )
    return translatedUpcomingInterviews
  }, [upcomingData])

  const upcomingPassedRecentlyCount = useCallback(
    (currentDate: Date) => {
      return upcomingList.filter((upcomingInterview) => {
        return upcomingInterview.isPassedRecently(currentDate)
      }).length
    },
    [upcomingList]
  )

  const upcomingCount = upcomingList.length

  const ongoingCount = ongoingList.length

  const isEnablePageControl = tabType === 'upcoming'

  // pagination for upcoming
  const [currentPage, setCurrentPage] = useState<number>(1)
  const onSelectPage = useCallback(
    (page) => {
      setCurrentPage(page)
    },
    [setCurrentPage]
  )
  const pageCount = Math.ceil(upcomingCount / 100)
  const upcomingListAtPage = useMemo(() => {
    return upcomingList.slice(
      PER_PAGE * (currentPage - 1),
      PER_PAGE * currentPage
    )
  }, [upcomingList, currentPage])

  const onReload = useCallback(() => {
    setLoadTime(new Date())
    refetchOngoing({ cancelRefetch: true })
    refetchUpcoming({ cancelRefetch: true })
  }, [setLoadTime, refetchOngoing, refetchUpcoming])

  return {
    tabType,
    setTabType,
    currentPage,
    pageCount,
    onSelectPage,
    isEnablePageControl,
    upcomingList: upcomingListAtPage,
    upcomingCount,
    upcomingPassedRecentlyCount,
    ongoingList,
    ongoingCount,
    loadTime,
    onReload,
    isLoading,
    isRefetching,
    loggedInStaff,
  }
}

type ComplementTimeProps = {
  scheduledStartTime?: Date
  durationSeconds?: number
  startTime: Date
}

type ComplementTimeResult = {
  scheduledStartTime: Date
  scheduledFinishTime: Date
}

const complementTime = ({
  scheduledStartTime,
  durationSeconds,
  startTime,
}: ComplementTimeProps): ComplementTimeResult => {
  if (scheduledStartTime === undefined) {
    if (durationSeconds === undefined) {
      const scheduledFinishTime = new Date(startTime.getTime())
      scheduledFinishTime.setMinutes(scheduledFinishTime.getMinutes() + 60)
      return {
        scheduledStartTime: startTime,
        scheduledFinishTime: scheduledFinishTime,
      }
    }

    const scheduledFinishTime = new Date(startTime.getTime())
    scheduledFinishTime.setSeconds(
      scheduledFinishTime.getSeconds() + durationSeconds
    )
    return {
      scheduledStartTime: startTime,
      scheduledFinishTime: scheduledFinishTime,
    }
  }

  if (durationSeconds === undefined) {
    const scheduledFinishTime = new Date(scheduledStartTime.getTime())
    scheduledFinishTime.setMinutes(scheduledFinishTime.getMinutes() + 60)

    if (startTime >= scheduledFinishTime) {
      // 開始時間が終了予定時刻を過ぎているため、開始時間基準で終了予定時刻を再計算する
      const scheduledFinishTime = new Date(startTime.getTime())
      scheduledFinishTime.setMinutes(scheduledFinishTime.getMinutes() + 60)

      return {
        scheduledStartTime: startTime,
        scheduledFinishTime: scheduledFinishTime,
      }
    } else {
      return {
        scheduledStartTime: scheduledStartTime,
        scheduledFinishTime: scheduledFinishTime,
      }
    }
  }

  const scheduledFinishTime = new Date(scheduledStartTime.getTime())
  scheduledFinishTime?.setSeconds(
    scheduledFinishTime.getSeconds() + durationSeconds
  )
  if (startTime >= scheduledFinishTime) {
    // 開始時間が終了予定時刻を過ぎているため、開始時間基準で終了予定時刻を再計算する
    const scheduledFinishTime = new Date(startTime.getTime())
    scheduledFinishTime?.setSeconds(
      scheduledFinishTime.getSeconds() + durationSeconds
    )
    return {
      scheduledStartTime: startTime,
      scheduledFinishTime: scheduledFinishTime,
    }
  } else {
    return {
      scheduledStartTime: scheduledStartTime,
      scheduledFinishTime: scheduledFinishTime,
    }
  }
}

function calcScheduledFinishDate(
  webInterview: ListUpcomingWebInterviewsResponse.WebInterview
): Date | undefined {
  const durationSeconds = webInterview.getDurationSeconds()?.getValue()
  if (durationSeconds === undefined) {
    return undefined
  }

  let scheduledFinishDate = webInterview.getScheduledStartTime()?.toDate()
  assertIsDefined(scheduledFinishDate)
  return add(scheduledFinishDate, { seconds: durationSeconds })
}

export const ControlCenterPageContainer = createContainer(useControlCenterPage)
