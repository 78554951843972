import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateSelectionStepResultRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { SelectionStepResult } from '@blue-agency/proton/web/v2/im'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateSelectionStepResultRequestValue = {
  applicantGuid: string
  selectionStepGuid: string
  result: SelectionStepResult
  nextSelectionStepMasterGuid: string
  comment: string
  recInterviewTemplateGuid: string
}
export function useRequestUpdateSelectionStepResult() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateSelectionStepResult = useCallback(
    async (value: UpdateSelectionStepResultRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateSelectionStepResultRequest()
      req.setApplicantGuid(value.applicantGuid)
      req.setSelectionStepGuid(value.selectionStepGuid)
      req.setResult(value.result)
      req.setNextSelectionStepMasterGuid(value.nextSelectionStepMasterGuid)
      req.setComment(value.comment)
      req.setRecInterviewTemplateGuid(value.recInterviewTemplateGuid)

      return callBizHuttBffService(
        'updateSelectionStepResult',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateSelectionStepResult,
  }
}
