import React from 'react'
import { EmbeddedSignInPage } from '@blue-agency/im-shared-front'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'

type Props = {
  children: React.ReactNode
}

export const EmbeddedUnAuthorizedErrorBoundary: React.VFC<Props> = ({
  children,
}) => {
  return (
    <ErrorBoundary FallbackComponent={buildFallback()}>
      {children}
    </ErrorBoundary>
  )
}

function buildFallback(): React.VFC<FallbackProps> {
  return ({ error }: FallbackProps) => {
    if (error instanceof CustomBizHuttBffGrpcError) {
      if (error.isUnauthenticated) {
        return <EmbeddedSignInPage />
      }
    }

    throw error
  }
}
