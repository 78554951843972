import {
  WeakColorSeparator,
  Modal as _Modal,
  Txt as _Txt,
} from '@blue-agency/rogue'
import {
  InputText,
  DatePicker,
  Dropdown,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { minOptions, hourOptions } from '@/services/webInterviewService'
import { useUpdateWebInterviewScheduleAndDurationForm } from './useUpdateWebInterviewScheduleAndDurationForm'

type Props = {
  active: boolean
  onClose: () => void
}

export function WebInterviewTimeManagementModal({ active, onClose }: Props) {
  const {
    onSubmit,
    register,
    isSubmitting,
    errors,
    watch,
    getValues,
    onDateChange,
  } = useUpdateWebInterviewScheduleAndDurationForm(onClose)

  return (
    <Modal
      active={active}
      title="予定日時 / 目安時間の変更"
      size="l"
      onClose={onClose}
    >
      <ModalBody>
        <Row>
          <Label>予定日時</Label>
          <ScheduledDate>
            <DatePicker
              {...register('scheduledDate')}
              value={watch('scheduledDate') ?? null}
              onChange={onDateChange}
              onBlurError={errors['scheduledDate']?.message}
              errorNoWrap
              placeholder="選択"
            />
          </ScheduledDate>
          <ScheduledTime>
            <Hour>
              <Dropdown
                {...register('scheduledHour')}
                size="ss"
                placeholder="選択"
                allowEmpty
                onBlurError={errors['scheduledHour']?.message}
                options={hourOptions}
                value={getValues('scheduledHour')}
                valueWhenFirstOpened={9}
              />
              <TimeUnit>時</TimeUnit>
            </Hour>
            <Minute>
              <Dropdown
                {...register('scheduledMin')}
                size="ss"
                placeholder="選択"
                allowEmpty
                onBlurError={errors['scheduledMin']?.message}
                options={minOptions}
                value={getValues('scheduledMin')}
              />
              <TimeUnit>分</TimeUnit>
            </Minute>
          </ScheduledTime>
        </Row>
        <WeakColorSeparator />
        <Row>
          <Label>目安時間</Label>
          <InputText
            {...register('durationMinutes')}
            size="ss"
            placeholder="入力"
            onBlurError={errors['durationMinutes']?.message}
            errorNoWrap
          />
          <MinuteTxt>分</MinuteTxt>
        </Row>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_update_web_interview_duration_button',
          }}
        >
          キャンセル
        </TertiaryButton>
        <PrimaryButton
          widthSize="L1"
          disabled={isSubmitting}
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_update_web_interview_duration_button',
          }}
        >
          変更
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  /* NOTE: 一番上のDropdownのエラー表示がモーダルヘッダーに隠れないようにしている */
  && > div:last-of-type {
    overflow: visible;
  }
`

const ModalBody = styled.div`
  padding: 12px 19px 20px 19px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 1px;
`

const Label = styled(_Txt).attrs({ size: 'm' })`
  width: 170px;
  line-height: 32px;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > :not(:first-child) {
    margin-left: 20px;
  }
`

const MinuteTxt = styled(_Txt).attrs({ size: 'm' })`
  margin-left: 8px;
`

const ScheduledDate = styled.div`
  margin-right: 21px;
`

const ScheduledTime = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 13px;
  }
`

const TimeUnit = styled(_Txt).attrs({ size: 'm' })`
  margin-left: 10px;
  line-height: 32px;
`

const Hour = styled.div`
  display: flex;
`

const Minute = styled.div`
  display: flex;
`
