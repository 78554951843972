import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import {
  Txt,
  LineClampedTxt,
  theme,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Count } from '@/components/Count'
import { SettingsSection } from '@/components/SettingsSection'
import { SelectionSharingType } from '@/services/applicantService/applicantTypes'
import type { CSVData } from '@/services/csvService/types'
import { LinkToHelp } from '../LinkToHelp'
import { targetOptions, selectionSharingOptions } from '../options'
import type { Form, Target } from '../types'

type Props = {
  csvData: CSVData
  formData: Form
  goPrevious: () => void
  goNext: () => void
}

function getTargetLabel(target: Target) {
  return targetOptions.find((t) => t.value === target)?.label
}

function getSharingTypeLabel(sharingType: SelectionSharingType) {
  return selectionSharingOptions.find((t) => t.value === sharingType)?.label
}

export const Confirm: React.VFC<Props> = ({
  csvData,
  formData,
  goPrevious,
  goNext,
}) => {
  usePromptBeforeUnload()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>応募者一括登録</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToHelp />
            </SubTextWrapper>
          </Left>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="取込設定の確認">
            <BodyWrapper>
              <ApplicantsCountTxt>
                登録対象件数
                <ApplicantsCount count={csvData.csvBody.length} />件
              </ApplicantsCountTxt>
              <WeakColorSeparator />
              <OptionWrapper>
                <FormRow title="取込オプション" subtitle="一般権限への共有">
                  <Txt>{getSharingTypeLabel(formData.selectionSharing)}</Txt>
                </FormRow>
              </OptionWrapper>
              <Label>応募者情報のひも付け</Label>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Th property="rowID">
                        <TableHeaderTxt>CSV列</TableHeaderTxt>
                      </Th>
                      <Th property="csvHeader">
                        <TableHeaderTxt>CSV1行目（項目名）</TableHeaderTxt>
                      </Th>
                      <Th property="csvFirstData">
                        <TableHeaderTxt>CSV2行目（データ）</TableHeaderTxt>
                      </Th>
                      <Th property="target">
                        <TableHeaderTxt>
                          インタビューメーカーの項目名
                        </TableHeaderTxt>
                      </Th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.csvHeader.map(
                      (header, i) =>
                        formData.targets[i] !== '' && (
                          <TableRow key={i}>
                            <Td property="rowID">
                              <TableBodyTxt>{i + 1}</TableBodyTxt>
                            </Td>
                            <Td property="csvHeader">
                              <TableBodyLineClampedTxt>
                                {header}
                              </TableBodyLineClampedTxt>
                            </Td>
                            <Td property="csvFirstData">
                              {csvData.csvBody[0].length > i && (
                                <TableBodyLineClampedTxt>
                                  {csvData.csvBody[0][i]}
                                </TableBodyLineClampedTxt>
                              )}
                            </Td>
                            <Td property="target">
                              <TableBodyTxt>
                                {getTargetLabel(formData.targets[i])}
                              </TableBodyTxt>
                            </Td>
                          </TableRow>
                        )
                    )}
                    {formData.targets.some((t) => t === '') && (
                      <TableRowSeparator />
                    )}
                    {csvData.csvHeader.map(
                      (header, i) =>
                        formData.targets[i] === '' && (
                          <TableRowW key={i}>
                            <Td property="rowID">
                              <TableBodyTxt color={theme.color.gray[2]}>
                                {i + 1}
                              </TableBodyTxt>
                            </Td>
                            <Td property="csvHeader">
                              <TableBodyLineClampedTxt
                                color={theme.color.gray[2]}
                              >
                                {header}
                              </TableBodyLineClampedTxt>
                            </Td>
                            <Td property="csvFirstData">
                              {csvData.csvBody[0].length > i && (
                                <TableBodyLineClampedTxt
                                  color={theme.color.gray[2]}
                                >
                                  {csvData.csvBody[0][i]}
                                </TableBodyLineClampedTxt>
                              )}
                            </Td>
                            <Td property="target">
                              <TableBodyTxt color={theme.color.gray[2]}>
                                未選択
                              </TableBodyTxt>
                            </Td>
                          </TableRowW>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableWrapper>
            </BodyWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_previous_button_on_batch_new_applicants_confirm',
              }}
              onClick={goPrevious}
            >
              前に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_next_button_on_batch_new_applicants_confirm',
              }}
              onClick={goNext}
            >
              取込を開始
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const BodyWrapper = styled.div`
  padding: 20px;
`

const ApplicantsCountTxt = styled(Txt).attrs({
  size: 'm',
})`
  display: flex;
  align-items: end;
  margin-bottom: 20px;
`

const ApplicantsCount = styled(Count)`
  line-height: 1;
  margin: 0 2px 0 4px;
`

const OptionWrapper = styled.div`
  margin: 20px 0;
`

const Label = styled(Txt)`
  word-break: keep-all;
  margin: 12px 0;
`

const TableWrapper = styled.div`
  margin: 0 28px;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead``

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  &:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

const TableRowSeparator = styled.tr`
  display: flex;
  height: 3px;
  background-color: ${theme.color.gray[4]};
`

const TableRowW = styled.tr`
  display: flex;
  align-items: center;
`

type Property = 'rowID' | 'csvHeader' | 'csvFirstData' | 'target'

const propertyHeaderStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    flex: 0 0 282px;
  `,
}

const propertyBodyStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 0 0 282px;
  `,
}

const Th = styled.th<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  margin-left: 2px;
  background-color: ${theme.color.gray[4]};
  ${({ property }) => propertyHeaderStyles[property]}
`

const Td = styled.td<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  margin-left: 2px;
  ${({ property }) => propertyBodyStyles[property]}
`

const TableHeaderTxt = styled(Txt).attrs({
  size: 's',
})``

const TableBodyTxt = styled(Txt).attrs({
  size: 's',
})``

const TableBodyLineClampedTxt = styled(LineClampedTxt).attrs({
  size: 's',
  line: 2,
})``
