import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateRecInterviewQuestionTemplateRequestValue,
  useRequestUpdateRecInterviewQuestionTemplate,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateRecInterviewQuestionTemplate() {
  const queryClient = useQueryClient()
  const { requestUpdateRecInterviewQuestionTemplate } =
    useRequestUpdateRecInterviewQuestionTemplate()

  return useMutation(
    async (value: UpdateRecInterviewQuestionTemplateRequestValue) => {
      await requestUpdateRecInterviewQuestionTemplate(value)
      return value.questionTemplateGuid
    },
    {
      onSuccess: (guid) => {
        queryClient.invalidateQueries(QUERY_KEY.questionTemplates)
        queryClient.invalidateQueries([QUERY_KEY.questionTemplate, guid])
      },
    }
  )
}
