import { useCallback, useState } from 'react'

type Step = 'input' | 'confirm'

export const useStep = () => {
  const [step, setStep] = useState<Step>('input')

  const toInput = useCallback(() => setStep('input'), [])
  const toConfirm = useCallback(() => setStep('confirm'), [])

  return {
    step,
    toInput,
    toConfirm,
  }
}
