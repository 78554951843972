import React from 'react'
import {
  getInternalUrls,
  NotFoundPage,
  SignInPage,
  NewsModal,
  useInitializeComlinkStaff,
} from '@blue-agency/im-shared-front'
import { Redirect, Route, Switch } from 'react-router-dom'
import { history } from '@/historyModule'
import { useAuthorizedTracking } from '@/hooks/useTracking'
import { ApplicantPage } from '@/pages/ApplicantPage'
import { ApplicantsBackgroundJobsPage } from '@/pages/ApplicantsBackgroundJobsPage'
import { ApplicantsPage } from '@/pages/ApplicantsPage'
import { AssignedWebInterviewsPage } from '@/pages/AssignedWebInterviewsPage'
import { BatchNewApplicantsPage } from '@/pages/BatchNewApplicantsPage'
import { ControlCenterPage } from '@/pages/ControlCenterPage'
import { DashboardPage } from '@/pages/DashboardPage'
import { EditApplicantPage } from '@/pages/EditApplicantPage'
import { EditQuestionTemplatePage } from '@/pages/EditQuestionTemplatePage'
import { EditVideoSeminarPage } from '@/pages/EditVideoSeminarPage'
import { EditWebInterviewGuidePage } from '@/pages/EditWebInterviewGuidePage'
import { NewApplicantPage } from '@/pages/NewApplicantPage'
import { NewQuestionTemplatePage } from '@/pages/NewQuestionTemplatePage'
import { NewVideoSeminarPage } from '@/pages/NewVideoSeminarPage'
import { NewWebInterviewGuidePage } from '@/pages/NewWebInterviewGuidePage'
import { NewWebInterviewPage } from '@/pages/NewWebInterviewPage'
import { QuestionTemplatePage } from '@/pages/QuestionTemplatePage'
import { QuestionTemplatesPage } from '@/pages/QuestionTemplatesPage'
import { RecInterviewTemplateEditPage } from '@/pages/RecInterviewTemplateEditPage'
import { RecInterviewTemplateNewPage } from '@/pages/RecInterviewTemplateNewPage'
import { RecInterviewTemplatePage } from '@/pages/RecInterviewTemplatePage'
import { RecInterviewTemplatesPage } from '@/pages/RecInterviewTemplatesPage'
import { ReorderVideoSeminarContentsPage } from '@/pages/ReorderVideoSeminarContentsPage'
import { SelectionStepMastersPage } from '@/pages/SelectionStepMastersPage'
import { SelectionStepPage } from '@/pages/SelectionStepPage'
import { VideoSeminarContentEditPage } from '@/pages/VideoSeminarContentEditPage'
import { VideoSeminarContentNewPage } from '@/pages/VideoSeminarContentNewPage'
import { VideoSeminarRoot } from '@/pages/VideoSeminarRoot'
import { VideoSeminarsPage } from '@/pages/VideoSeminarsPage'
import { WebInterviewGuidePage } from '@/pages/WebInterviewGuidePage'
import { WebInterviewGuidesPage } from '@/pages/WebInterviewGuidesPage'
import { WebInterviewPage } from '@/pages/WebInterviewPage'
import { WebInterviewUpdateByFilePage } from '@/pages/WebInterviewUpdateByFilePage'
import { WebInterviewsPage } from '@/pages/WebInterviewsPage'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'

export const AuthorizedRoutes: React.VFC = () => {
  useAuthorizedTracking(history)

  useInitializeComlinkStaff()

  return (
    <AuthzContainer.Provider>
      <Switch>
        <Redirect
          exact
          from="/"
          // react-router には pathname 以降を渡さないといけないので
          to={removeOrigin(getInternalUrls().dashboard)}
        />
        <Route
          exact
          path={removeOrigin(getInternalUrls().dashboard)}
          component={DashboardPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.controlCenter}
          component={ControlCenterPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.webInterviews}
          component={WebInterviewsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newWebInterviews}
          component={NewWebInterviewPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.uploadWebInterviewsCSV}
          component={WebInterviewUpdateByFilePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.assignedWebInterviews}
          component={AssignedWebInterviewsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.webInterview}
          component={WebInterviewPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.questionTemplates}
          component={QuestionTemplatesPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newQuestionTemplate}
          component={NewQuestionTemplatePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.questionTemplate}
          component={QuestionTemplatePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editQuestionTemplate}
          component={EditQuestionTemplatePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.recInterviewTemplates}
          component={RecInterviewTemplatesPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newRecInterviewTemplate}
          component={RecInterviewTemplateNewPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.recInterviewTemplate}
          component={RecInterviewTemplatePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editRecInterviewTemplate}
          component={RecInterviewTemplateEditPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.selectionStepMasters}
          component={SelectionStepMastersPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newApplicant}
          component={NewApplicantPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.applicants}
          component={ApplicantsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.applicantsBackgroundJobs}
          component={ApplicantsBackgroundJobsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.batchNewApplicants}
          component={BatchNewApplicantsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.applicant}
          component={ApplicantPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editApplicant}
          component={EditApplicantPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.webInterviewGuides}
          component={WebInterviewGuidesPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newWebInterviewGuide}
          component={NewWebInterviewGuidePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editWebInterviewGuide}
          component={EditWebInterviewGuidePage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.webInterviewGuide}
          component={WebInterviewGuidePage}
        />
        <Route
          exact
          /**
           * ref: https://stadium-co-jp.slack.com/archives/C02A51RBKNF/p1646281816897329
           * 選考ステップの評価ページは、(将来的に実装予定の)Web面接も録画面接も同じSelectionStepPageを利用する
           */
          path={INTERNAL_PATHS.selectionRecInterview}
          component={SelectionStepPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newVideoSeminarContent}
          component={VideoSeminarContentNewPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.newVideoSeminar}
          component={NewVideoSeminarPage}
        />
        <Route
          exact
          path={[
            INTERNAL_PATHS.videoSeminarInfo,
            INTERNAL_PATHS.videoSeminarContents,
            INTERNAL_PATHS.videoSeminarReport,
          ]}
          component={VideoSeminarRoot}
        />
        <Route
          exact
          path={INTERNAL_PATHS.videoSeminars}
          component={VideoSeminarsPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editVideoSeminar}
          component={EditVideoSeminarPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.editVideoSeminarContent}
          component={VideoSeminarContentEditPage}
        />
        <Route
          exact
          path={INTERNAL_PATHS.reorderVideoSeminarContents}
          component={ReorderVideoSeminarContentsPage}
        />
        <Route exact path={getInternalUrls().signIn} component={SignInPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <NewsModal />
    </AuthzContainer.Provider>
  )
}

function removeOrigin(url: string): string {
  return url.split(window.location.origin)[1]
}
