import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { NewWebInterviewPageContainer } from './NewWebInterviewPageContainer'

export const NewWebInterviewPage = provide(NewWebInterviewPageContainer)(
  (): React.ReactElement => {
    const { step } = NewWebInterviewPageContainer.useContainer()

    const { authorizeByRpcName } = AuthzContainer.useContainer()
    authorizeByRpcName && authorizeByRpcName('CreateWebInterviews')

    usePromptBeforeUnload()

    switch (step) {
      case 'input':
        return <InputPage />
      case 'confirm':
        return <ConfirmPage />
    }
  }
)
