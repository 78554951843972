import {
  GetApplicantResponse,
  ListRequestRecInterviewAnswerFailureDetailsResponse,
  ListSelectionBackgroundJobsResponse,
} from '@blue-agency/proton/web/v2/biz_hutt_bff'
import {
  SelectionRecInterview as SelectionRecInterviewProto,
  Applicant as ApplicantProto,
} from '@blue-agency/proton/web/v2/im'
import { RecInterviewTemplateDeadline } from '../recInterviewTemplateService'
import { Applicant } from './applicantTypes'
import {
  BackgroundJob,
  BackgroundJobType,
  BackgroundJobStatus,
  ReqRecInterviewJobFailureDetail,
  ReqRecInterviewJobFailureApplicant,
  ReqRecInterviewJobErrorType,
} from './backgroundJobTypes'
import { isSharing } from './selection'
import {
  QuestionAnswerPair,
  Selection,
  SelectionRecInterview,
  SelectionStepAppendix,
} from './selectionTypes'

/**
 * protonの型からアプリケーションの型へのマッピング
 */
export const mapApplicant = (
  applicantProto?: ApplicantProto
): Applicant | undefined => {
  if (applicantProto === undefined) return undefined
  return {
    ...applicantProto.toObject(),
    birthDate: applicantProto.getBirthDate()?.toDate(),
  }
}

export const mapSelection = (
  selectionProto?: GetApplicantResponse.Selection
): Selection | undefined => {
  if (selectionProto === undefined) return undefined

  return {
    ...selectionProto.toObject(),
    selectionStepsList: selectionProto.getSelectionStepsList().map((ss) => {
      const appendix = ss.getSelectionStepAppendix()
      const registrationRoute = appendix?.getAtsConnectName() || '手動登録'

      return {
        ...ss.toObject(),
        selectionRecInterview: mapSelectionRecInterview(
          ss.getSelectionRecInterviewDetail()?.getSelectionRecInterview(),
          ss.getSelectionRecInterviewDetail()?.getCanWatchAnswer()
        ),
        registrationRoute: registrationRoute,
        registerTime: ss.getRegisterTime()?.toDate(),
        lastAssessTime: ss.getLastAssessTime()?.toDate(),
        selectionStepAppendix: mapSelectionStepAppendix(appendix),
      }
    }),
    isSharing: isSharing(selectionProto.getSharing()?.getSharingType()),
  }
}

export const mapSelectionRecInterview = (
  selectionRefInterviewProto?: SelectionRecInterviewProto,
  canWatchAnswer?: boolean
): SelectionRecInterview | undefined => {
  if (selectionRefInterviewProto === undefined) return undefined
  return {
    recInterviewGuid: selectionRefInterviewProto.getRecInterviewGuid(),
    deadline: selectionRefInterviewProto.getDeadline()?.toDate(),
    submissionTime: selectionRefInterviewProto.getSubmissionTime()?.toDate(),
    questionAnswerPairsList: selectionRefInterviewProto
      .getQuestionAnswerPairsList()
      .map((qap) => mapQuestionAnswerPair(qap)),
    name: selectionRefInterviewProto.getName(),
    templateDeadline: mapDeadlineTemplate(selectionRefInterviewProto),
    canWatchAnswer: canWatchAnswer ?? false,
  }
}

const mapQuestionAnswerPair = (
  questionAnswerPairProto: SelectionRecInterviewProto.QuestionAnswerPair
): QuestionAnswerPair => {
  return {
    questionGuid: questionAnswerPairProto.getQuestionGuid(),
    required: questionAnswerPairProto.getRequired(),
    format: mapFormat(questionAnswerPairProto.getFormatCase()),
    text: questionAnswerPairProto.getText()?.toObject(),
    video: questionAnswerPairProto.getVideo()?.toObject(),
  }
}

const mapDeadlineTemplate = (
  deadlineTemplateCase: SelectionRecInterviewProto
): RecInterviewTemplateDeadline => {
  switch (deadlineTemplateCase.getDeadlineTemplateCase()) {
    case SelectionRecInterviewProto.DeadlineTemplateCase.DUE_DURATION:
      const duration = deadlineTemplateCase.getDueDuration()
      if (duration === undefined) {
        throw new Error('invalid duration')
      }
      return {
        case: 'dueDuration',
        dueDuration: duration.toObject(),
      }
    case SelectionRecInterviewProto.DeadlineTemplateCase.DUE_TIME:
      const dueTime = deadlineTemplateCase.getDueTime()
      if (dueTime === undefined) {
        throw new Error('invalid dueTime')
      }
      return {
        case: 'dueTime',
        dueTime: dueTime.toDate(),
      }
    case SelectionRecInterviewProto.DeadlineTemplateCase
      .DEADLINE_TEMPLATE_NOT_SET:
      throw new Error('invalid deadlineTemplate')
  }
}

const mapFormat = (
  formatCase: SelectionRecInterviewProto.QuestionAnswerPair.FormatCase
): QuestionAnswerPair['format'] => {
  switch (formatCase) {
    case SelectionRecInterviewProto.QuestionAnswerPair.FormatCase.TEXT:
      return 'text'
    case SelectionRecInterviewProto.QuestionAnswerPair.FormatCase.VIDEO:
      return 'video'
    case SelectionRecInterviewProto.QuestionAnswerPair.FormatCase
      .FORMAT_NOT_SET:
      throw new Error('invalid formatCase')
  }
}

export const mapBackgroundJobFromProto = (
  jobProto: ListSelectionBackgroundJobsResponse.SelectionBackgroundJob
): BackgroundJob => {
  const startedTime = jobProto.getStartedAt()?.toDate()
  if (startedTime === undefined) {
    throw new Error('invalid startedTime')
  }

  return {
    ...jobProto.toObject(),
    job: mapJobType(jobProto.getJob()),
    status: mapJobStatus(jobProto.getStatus()),
    startedTime: startedTime,
  }
}

const mapJobType = (
  job: ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Job
): BackgroundJobType => {
  switch (job) {
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Job
      .JOB_REQ_SELECTION_REC_INTERVIEW_ANSWER:
      return 'reqRecInterviewAnswer'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Job
      .JOB_UPDATE_TO_NOT_PASSED:
      return 'updateToNotPassed'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Job
      .JOB_UPDATE_TO_FINISHED:
      return 'updateToFinished'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Job
      .JOB_UNSPECIFIED:
      throw new Error('invalid selection background job type')
  }
}

const mapJobStatus = (
  status: ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Status
): BackgroundJobStatus => {
  switch (status) {
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Status
      .STATUS_IN_PROGRESS:
      return 'inProgress'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Status
      .STATUS_SUCCEEDED:
      return 'success'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Status
      .STATUS_FAILED:
      return 'failed'
    case ListSelectionBackgroundJobsResponse.SelectionBackgroundJob.Status
      .STATUS_UNSPECIFIED:
      throw new Error('invalid selection background status')
  }
}

export const mapReqRecInterviewJobFailureFromProto = (
  detailProto: ListRequestRecInterviewAnswerFailureDetailsResponse
): ReqRecInterviewJobFailureDetail => {
  const detail = detailProto.toObject()
  return {
    ...detail,
    details: detail.failedApplicantsList.map(
      mapReqRecInterviewJobFailureTarget
    ),
  }
}

const mapReqRecInterviewJobFailureTarget = (
  proto: ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.AsObject
): ReqRecInterviewJobFailureApplicant => {
  if (proto.deleted) {
    return {
      target: 'deleted',
      error: mapReqRecInterviewJobFailureDetail(proto.error),
    }
  }
  return {
    target: {
      applicangGuid: proto.applicantGuid,
      familyName: proto.familyName,
      givenName: proto.givenName,
    },
    error: mapReqRecInterviewJobFailureDetail(proto.error),
  }
}

const mapReqRecInterviewJobFailureDetail = (
  reason: ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.Error
): ReqRecInterviewJobErrorType => {
  switch (reason) {
    case ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.Error
      .ERROR_INVALID_STATUS:
      return 'invalidStatus'
    case ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.Error
      .ERROR_NOT_FOUND:
      return 'notFound'
    case ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.Error
      .ERROR_INTERNAL:
      return 'internal'
    case ListRequestRecInterviewAnswerFailureDetailsResponse.Applicant.Error
      .ERROR_UNSPECIFIED:
      throw new Error('invalid req rec interview job failure detail')
  }
}

const mapSelectionStepAppendix = (
  appendix: GetApplicantResponse.SelectionStep.SelectionStepAppendix | undefined
): SelectionStepAppendix | undefined => {
  if (!appendix) return undefined
  return {
    externalRecruitmentName: appendix.getExternalRecruitmentName(),
    externalSelectionName: appendix.getExternalSelectionName(),
    externalRecruitmentId: appendix.getExternalRecruitmentId(),
    externalSelectionId: appendix.getExternalSelectionId(),
  }
}
