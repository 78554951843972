import { WeakColorSeparator, Modal, Txt as _Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  email: string
  onClose: () => void
  onDownload: () => void
}

export const DownloadApplicantsCSVModal: React.VFC<Props> = ({
  email,
  onClose,
  onDownload,
}) => {
  return (
    <Modal active title="CSV出力" size="m" onClose={onClose} stopScroll>
      <ModalBody>
        <Txt>
          <Bold>{email}</Bold> 宛に
          <br />
          ダウンロードリンクがメールで送信されます。 <br />
          送信完了までに数分程度かかる場合がございます。
        </Txt>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action: 'click_download_applicants_csv_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={onDownload}
            comlinkPushParams={{
              action: 'click_download_applicants_csv_button',
            }}
          >
            確定
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
  min-height: 160px;
`

const Txt = styled(_Txt)`
  overflow-wrap: break-word;
`

const Bold = styled.span`
  font-weight: bold;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
