import { useCallback, useRef, useState } from 'react'

export type PlayParams = {
  videoSrc: string
  currentTime: number
  content: string
}
type ChangePlayingAnswerVideoParams =
  | {
      type: 'pause'
    }
  | ({
      type: 'play'
    } & PlayParams)
export type ChangePlayingAnswerVideo = (
  params: ChangePlayingAnswerVideoParams
) => void
export const useAnswerVideoPreview = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [content, setContent] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)

  const changePlayingAnswerVideo = useCallback<ChangePlayingAnswerVideo>(
    (params: ChangePlayingAnswerVideoParams) => {
      if (params.type === 'play') {
        setContent(params.content)
        setIsPlaying(true)
        const ref = videoRef.current
        if (!ref) return
        ref.src = params.videoSrc
        ref.currentTime = params.currentTime
        ref.play()
      }
      if (params.type === 'pause') {
        setContent('')
        setIsPlaying(false)
      }
    },
    []
  )

  return {
    isPlaying,
    videoRef,
    content,
    changePlayingAnswerVideo,
  }
}
