import { assertNever } from '@/assertions'
import {
  BackgroundJobType,
  ReqRecInterviewJobErrorType,
} from './backgroundJobTypes'

export const getBackgroundJobName = (job: BackgroundJobType): string => {
  switch (job) {
    case 'reqRecInterviewAnswer':
      return '一括選考登録'
    case 'updateToNotPassed':
      return '一括不通過'
    case 'updateToFinished':
      return '一括選考終了'
    default:
      assertNever(job)
  }
}

export const getReqRecInterviewJobErrorName = (
  err: ReqRecInterviewJobErrorType
): string => {
  switch (err) {
    case 'invalidStatus':
      return '提出依頼ができないステータスでした。'
    case 'notFound':
      return '対象の応募者が見つかりませんでした。'
    case 'internal':
      return '予期せぬエラーが発生しました。'
    default:
      assertNever(err)
  }
}
