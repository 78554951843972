export type Seconds = number

export const timeFormat = (seconds: Seconds) => {
  const min = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')

  const sec = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0')

  return `${min}:${sec}`
}
