import { theme, Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  name: string
  onClose: () => void
  onSubmit: () => void
}

export const DeleteFormatModal: React.VFC<Props> = ({
  name,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal active title="フォーマットの削除" onClose={onClose} size="m">
      <ModalContent>
        <Txt>「{name}」を削除します。</Txt>
        <Txt color={theme.color.red[2]}>
          削除後は、お問い合わせいただいても復元できません。
        </Txt>
      </ModalContent>
      <WeakColorSeparator />
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_delete_format_modal',
          }}
        >
          キャンセル
        </TertiaryButton>
        <DangerButton
          widthSize="L1"
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'delete_format_button',
          }}
        >
          削除
        </DangerButton>
      </ButtonArea>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 40px;
`

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > *:first-child {
    margin-right: 20px;
  }
`
