import { useCallback, useState, useEffect, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useParams } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  mapProtoToVideoSeminar,
  useGetVideoSeminar,
} from '@/services/videoSeminarService'
import { Content } from './types'
import { useReorderVideoSeminarContents } from './useReorderVideoSeminarContents'

export function useReorderVideoSeminarContentsPage() {
  const history = useHistory()
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('GetVideoSeminar')
  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()
  const { data, isLoading } = useGetVideoSeminar(videoSeminarGuid)
  const videoSeminar = useMemo(() => {
    if (data === undefined) return undefined
    return mapProtoToVideoSeminar(data, videoSeminarGuid)
  }, [data, videoSeminarGuid])

  const [contents, setContents] = useState<Content[]>([])

  useEffect(() => {
    setContents(
      videoSeminar?.contents.map((c) => ({
        guid: c.guid,
        title: c.title,
      })) ?? []
    )
  }, [videoSeminar, setContents])

  const onMoveContent = useCallback(
    (from: number, to: number) => {
      const tempContents = contents.slice()
      const tempContent = contents[from]
      tempContents.splice(from, 1)
      tempContents.splice(to, 0, tempContent)
      setContents(tempContents)
    },
    [contents]
  )

  const { mutateAsync } = useReorderVideoSeminarContents()

  const onSubmit = useCallback(async () => {
    try {
      await mutateAsync({
        videoSeminarGuid: videoSeminarGuid,
        videoSeminarContentGuids: contents.map((c) => c.guid),
      })
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
    history.push(
      generatePath(INTERNAL_PATHS.videoSeminarContents, { videoSeminarGuid })
    )
    toast('コンテンツ表示順を保存しました')
  }, [mutateAsync, videoSeminarGuid, contents, history])

  return {
    videoSeminarGuid,
    isLoading,
    contents,
    onMoveContent,
    onSubmit,
  }
}
