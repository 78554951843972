import { useCallback } from 'react'
import type { UseFieldArrayReturn, UseFormTrigger } from 'react-hook-form'
import type { Form } from './validation'

type Args = Pick<
  UseFieldArrayReturn<Form, 'parts', 'id'>,
  'remove' | 'swap' | 'append'
> & {
  partLength: number
  trigger: UseFormTrigger<Form>
}

export function useManipulateParts({
  partLength,
  swap,
  trigger,
  remove,
  append,
}: Args) {
  const moveUp = useCallback(
    (index: number) => {
      return () => {
        if (index === 0) {
          return
        }

        swap(index, index - 1)
        trigger([
          `parts.${index}.startOffsetMin`,
          `parts.${index - 1}.startOffsetMin`,
        ])
      }
    },
    [swap, trigger]
  )

  const moveDown = useCallback(
    (index: number) => {
      return () => {
        if (index === partLength - 1) {
          return
        }

        swap(index, index + 1)
        trigger([
          `parts.${index}.startOffsetMin`,
          `parts.${index + 1}.startOffsetMin`,
        ])
      }
    },
    [partLength, swap, trigger]
  )

  const removePart = useCallback(
    (index: number) => {
      return () => {
        if (partLength === 1) {
          return
        }
        remove(index)
      }
    },
    [remove, partLength]
  )

  const appendPart = useCallback(() => {
    // 面接ガイドは最大20パートまで
    if (partLength >= 20) {
      return
    }
    append({ title: '', description: '' }, { shouldFocus: false })
  }, [append, partLength])

  return {
    moveUp,
    moveDown,
    removePart,
    appendPart,
  }
}
