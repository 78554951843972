import React from 'react'
import { useCallback } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { InputBody, UseFormResponse } from '@/components/applicantForm'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  applicantFormCtx: UseFormResponse
  guid: string
  enableDetails: () => void
  toConfirm: () => void
}

export const InputPage: React.VFC<Props> = ({
  applicantFormCtx,
  guid,
  enableDetails,
  toConfirm,
}) => {
  const confirm = useCallback(async () => {
    const valid = await applicantFormCtx.formCtx.trigger()
    if (!valid) return
    toConfirm()
  }, [toConfirm, applicantFormCtx.formCtx])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>応募者編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="プロフィール">
            <InputBody
              {...applicantFormCtx}
              isDetailsEnabled
              enableDetails={enableDetails}
            />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink
              to={{
                pathname: generatePath(INTERNAL_PATHS.applicant, {
                  applicantGuid: guid,
                }),
                search: '?t=profile',
              }}
            >
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_cancel_on_edit_applicant_page',
                }}
              >
                キャンセル
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              onClick={confirm}
              comlinkPushParams={{
                action: 'click_confirm_on_edit_applicant_page',
              }}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const BackButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
