import React, { useCallback, useMemo, useState } from 'react'
import { theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { AudienceRetention, PlaybackLog } from '@/services/videoSeminarService'
import { FormatTime } from './FormatTime'
import { TooltipTxt, TooltipTxtStrong, TooltipWrapper } from './tooltip'

const defaultLogIntervalSeconds = 10
const maxBlocks = 500

type Props = {
  audienceRetention: AudienceRetention
  movieDurationSeconds: number
  onClick?: (log: PlaybackLog) => void
}
/** 視聴維持グラフ（応募者個人単位用） */
export const HeatMap: React.VFC<Props> = (props) => {
  const [hoveredLog, setHoveredLog] = useState<PlaybackLog | null>(null)

  const genOnMouseOver = useCallback(
    (log: PlaybackLog) => () => setHoveredLog(log),
    []
  )
  const onMouseOut = useCallback(() => setHoveredLog(null), [])

  const onClick = useCallback(() => {
    if (props.onClick && hoveredLog) {
      props.onClick(hoveredLog)
    }
  }, [props, hoveredLog])

  /**
   * 表示するログの割合の逆数
   * 例)
   *  1の時：全てのログを表示
   *  5の時：5分の1のログを表示
   */
  const reciprocalOfDisplayLogRate = useMemo(() => {
    const len = props.audienceRetention.playbackLogs.length
    return Math.ceil(len / maxBlocks)
  }, [props.audienceRetention])

  const decimatedLogs = useMemo(() => {
    return props.audienceRetention.playbackLogs.filter(
      (_, i) => i % reciprocalOfDisplayLogRate === 0
    )
  }, [props.audienceRetention, reciprocalOfDisplayLogRate])

  /**
   * レポート分析時には10sなどのintervalでまとめて計算している。
   * 例えば1:28の動画の分析データの最後のチャンクは1:21~1:28ではなく1:21~1:30のデータとなる。
   * 最大値としては動画の長さを設定したいため、最後のチャンクだけcurrentTime動画の長さに置き換える。
   */
  const logsMovieDurationApplied = useMemo(() => {
    const lastLogIndex = decimatedLogs.length - 1
    const copy = decimatedLogs.slice()
    copy[lastLogIndex] = {
      playbackTimeSeconds: props.movieDurationSeconds,
      playbackCount: decimatedLogs[lastLogIndex].playbackCount,
    }
    return copy
  }, [props, decimatedLogs])

  return (
    <Wrapper
      onMouseOut={onMouseOut}
      onClick={onClick}
      hasOnClick={props.onClick !== undefined}
    >
      {logsMovieDurationApplied.map((log, i) => {
        if (log.playbackTimeSeconds === 0) return null

        if (i === logsMovieDurationApplied.length - 1) {
          return (
            <LastBlock
              onMouseOver={genOnMouseOver(log)}
              key={log.playbackTimeSeconds}
              count={log.playbackCount}
              defaultIntervalSec={
                (props.audienceRetention.playbackLogIntervalSeconds ??
                  defaultLogIntervalSeconds) * reciprocalOfDisplayLogRate
              }
              lastIntervalSec={
                log.playbackTimeSeconds -
                logsMovieDurationApplied[i - 1].playbackTimeSeconds
              }
            />
          )
        }

        return (
          <Block
            onMouseOver={genOnMouseOver(log)}
            key={log.playbackTimeSeconds}
            count={log.playbackCount}
          />
        )
      })}
      <FormatTimeForMovieDuration
        timeSeconds={props.movieDurationSeconds}
        maxDigitsOfHoursToShow={3}
      />
      {hoveredLog && (
        <TooltipPosition
          currentTime={hoveredLog.playbackTimeSeconds}
          duration={props.movieDurationSeconds}
        >
          <Tooltip
            playbackTimeSeconds={hoveredLog.playbackTimeSeconds}
            playbackCount={hoveredLog.playbackCount}
          />
        </TooltipPosition>
      )}
    </Wrapper>
  )
}

const getBlockColor = (count: number) => {
  if (count < 0) throw new Error('count must be positive number.')

  switch (count) {
    case 0:
      return theme.color.gray[4]
    case 1:
      return `${theme.color.red[2]}4D`
    case 2:
      return `${theme.color.red[2]}80`
    case 3:
      return `${theme.color.red[2]}A1`
    case 4:
      return `${theme.color.red[2]}C2`
    case 5:
      return `${theme.color.red[2]}E0`
    default:
      return theme.color.red[2]
  }
}

const Wrapper = styled.div<{ hasOnClick: boolean }>`
  position: relative;
  height: 28px;
  width: 100%;
  display: flex;
  background-color: ${theme.color.gray[4]};
  ${({ hasOnClick }) =>
    hasOnClick &&
    css`
      cursor: pointer;
    `}
`

const Block = styled.div<{ count: number }>`
  background-color: ${({ count }) => getBlockColor(count)};
  height: 100%;
  flex: 1;
`

const LastBlock = styled(Block)<{
  defaultIntervalSec: number
  lastIntervalSec: number
}>`
  flex: ${(props) => props.lastIntervalSec / props.defaultIntervalSec};
`

const FormatTimeForMovieDuration = styled(FormatTime)`
  position: absolute;
  bottom: 2px;
  right: 2px;
  text-shadow: 1px 1px 1px ${theme.color.white[1]},
    -1px -1px 1px ${theme.color.white[1]}, -1px 1px 1px ${theme.color.white[1]},
    1px -1px 1px ${theme.color.white[1]};
  font-size: ${theme.fontSize.s};
  & > span {
    font-size: ${theme.fontSize.s};
  }
`

const TooltipPosition = styled.div<{
  currentTime: number
  duration: number
}>`
  position: absolute;
  top: -88px;
  left: calc(${(props) => (props.currentTime / props.duration) * 100}% - 114px);
`

const Tooltip: React.VFC<PlaybackLog> = (props) => {
  return (
    <TooltipWrapperWithArrow>
      <TooltipTxt>
        <FormatTime timeSeconds={props.playbackTimeSeconds} />
      </TooltipTxt>
      <TooltipTxt>
        <TooltipTxtStrong>{props.playbackCount}</TooltipTxtStrong>
        回視聴
      </TooltipTxt>
    </TooltipWrapperWithArrow>
  )
}

const TooltipWrapperWithArrow = styled(TooltipWrapper)`
  &&:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 90%;
    margin-left: -7px;
    border: 5px solid transparent;
    border-top: 5px solid #fff;
    z-index: 2;
  }

  &&:after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 90%;
    margin-left: -10px;
    border: 8px solid transparent;
    border-top: 8px solid ${theme.color.navy[1]};
    z-index: 1;
  }
`
