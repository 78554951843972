import { theme, Txt } from '@blue-agency/rogue'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Box } from '@/components/Box'
import { TableBase } from '@/components/TableBase'
import {
  getSelectionStatusColor,
  getSelectionStatusText,
  getSelectionSubStatusText,
} from '@/services/applicantService'
import { Applicant } from './types'

type Props = {
  applicants: Applicant[]
}

export const ApplicantSelectionStatusTable: React.VFC<Props> = ({
  applicants,
}) => {
  if (applicants.length === 0) return null

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="name">応募者</Th>
            <Th property="status">ステータス</Th>
            <Th property="currentStep">最新の選考</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {applicants.map((applicant) => {
            const subStatusTxt = getSelectionSubStatusText(
              applicant.selectionSubStatus
            )
            return (
              <TableRow key={applicant.guid}>
                <Td property="name">
                  <EllipsisTxt size="s" bold>
                    {`${applicant.familyName} ${applicant.givenName}`}
                  </EllipsisTxt>
                </Td>
                <Td property="status">
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Dot
                        color={getSelectionStatusColor(
                          applicant.selectionStatus
                        )}
                      />
                      <Box ml="4px">
                        <Txt size="s">
                          {getSelectionStatusText(applicant.selectionStatus)}
                        </Txt>
                      </Box>
                    </Box>
                    {subStatusTxt && (
                      <Txt size="s" color={theme.color.gray[1]}>
                        {subStatusTxt}
                      </Txt>
                    )}
                  </Box>
                </Td>
                <Td property="currentStep">
                  <EllipsisTxt size="s">
                    {applicant.currentSelectionStepName}
                  </EllipsisTxt>
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 220px;
`

type Property = 'name' | 'status' | 'currentStep'
const width: Record<Property, number> = {
  name: 165,
  status: 160,
  currentStep: 320,
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
  /* TableBaseのスタイルを打ち消し */
  margin-bottom: 0px;
  tbody tr {
    &:hover {
      box-shadow: none;
    }
  }
  th {
    height: 32px;
    background-color: ${theme.color.gray[4]};
  }
  td {
    min-height: 46px;
    height: 46px;
    border-bottom: 0px;
  }
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  status: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.status}px;
    justify-content: flex-start;
  `,
  currentStep: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.currentStep}px;
    justify-content: flex-start;
  `,
}

const EllipsisTxt = styled(Txt)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TableBody = styled.tbody`
  > tr:nth-child(even) td {
    background-color: ${theme.color.gray[5]};
  }
`

const TableRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`
