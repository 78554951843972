import { useQuery } from 'react-query'
import { useRequestListApplicantEvents } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListApplicantEvents(applicantGuid: string) {
  const { requestListApplicantEvents } = useRequestListApplicantEvents()

  return useQuery([QUERY_KEY.applicantEvents, applicantGuid], () =>
    requestListApplicantEvents(applicantGuid)
  )
}
