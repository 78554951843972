import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { H1 } from '@/components/Heading'

type ListPageHeaderProps = {
  title: React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
}

export const ListPageHeader = (
  props: PropsWithClassName<ListPageHeaderProps>
): React.ReactElement => <PageHeader {...props} maxWidth="100%" />

type PageHeaderProps = ListPageHeaderProps & {
  maxWidth?: string
}

export const PageHeader = (props: PageHeaderProps): React.ReactElement => {
  return (
    <HeaderWrapper>
      <Header maxWidth={props.maxWidth ?? '1160px'}>
        <Left>
          <H1>{props.title}</H1>
          <LeftInner>{props.left}</LeftInner>
        </Left>
        <Right>{props.right}</Right>
      </Header>
    </HeaderWrapper>
  )
}
const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  background-color: ${theme.color.white[1]};
`

type HeaderProps = { maxWidth: string }
const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: 60px;
  ${(props) => `max-width: ${props.maxWidth};`}
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const LeftInner = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
