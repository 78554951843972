import { theme } from '@blue-agency/rogue'
import {
  getCurrentStepActions,
  StepActionType,
  SelectionStatus,
  SelectionSubStatus,
} from '@/services/applicantService'

type Menu = { action: StepActionType; label: string; color: string }

export const getCurrentMenus = (
  status: SelectionStatus,
  subStatus: SelectionSubStatus
): Menu[] => {
  return getCurrentStepActions(status, subStatus).map((action) => {
    switch (action) {
      case 'DELETE':
        return {
          action,
          label: '選考を削除',
          color: theme.color.red[2],
        }
      case 'COPY_REQUEST_SUBMISSION_URL':
        return {
          action,
          label: '応募者向け回答ページリンクをコピー',
          color: theme.color.navy[1],
        }
      case 'SEND_SUBMISSION_REQUEST_EMAIL':
        return {
          action,
          label: '提出依頼メールを送信',
          color: theme.color.navy[1],
        }
      case 'ASSIGN_REC_INTERVIEW_TEMPLATE':
        return {
          action,
          label: 'テンプレート設定',
          color: theme.color.navy[1],
        }
      case 'REQUEST_SUBMISSION_REC_INTERVIEW':
        return {
          action,
          label: '提出依頼',
          color: theme.color.navy[1],
        }
      case 'REVOKE_REQUEST_SUBMISSION_REC_INTERVIEW':
        return {
          action,
          label: '提出依頼を取消',
          color: theme.color.navy[1],
        }
      case 'CHANGE_SELECTION_REC_INTERVIEW_DEADLINE':
        return {
          action,
          label: '提出期限を変更',
          color: theme.color.navy[1],
        }
      case 'COPY_SELECTION_STEP_PAGE_URL':
        return {
          action,
          label: '評価画面リンクをコピー',
          color: theme.color.navy[1],
        }
      case 'DETAIL':
        return detailMenu
      default:
        throw new Error('invalid subStatus')
    }
  })
}

export const detailMenu: Menu = {
  action: 'DETAIL',
  label: '選考詳細',
  color: theme.color.navy[1],
}
