import React from 'react'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  recordingUrl: string
}>

export const EncodedStatus = (props: Props): React.ReactElement => {
  return (
    <Recording
      title="Web面接録画"
      src={props.recordingUrl}
      allow="fullscreen"
    />
  )
}

const Recording = styled.iframe`
  height: 100%;
  width: 100%;
`
