import React from 'react'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SimpleStatus } from './SimpleStatus'
import encodingImage from './encoding.svg'

export const EncodingStatus = (
  props: PropsWithClassName
): React.ReactElement | null => {
  return (
    <SimpleStatus
      className={props.className}
      imgSrc={encodingImage}
      message="録画データを生成しています…"
    />
  )
}
