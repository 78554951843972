import { useEffect, useMemo, useReducer, useState } from 'react'
import {
  freewordToArray,
  SEARCH_FREEWORD_COUNT_MAX,
} from '@/services/webInterviewService'

export type AssignedWebInterviewStatus =
  | 'notStarted'
  | 'interviewing'
  | 'interviewed'

type SearchForm = {
  freeword?: string
  status: { [p in AssignedWebInterviewStatus]: boolean }
  interviewerGuid: string | null
  isOnlyViewed: boolean
  registerTime: {
    from: Date | null
    to: Date | null
  }
}

// クエリパラメータにJSON.stringifyしたものを入れるので、極力プロパティ名を短くする
type CompressedSearchForm = {
  f?: string
  s: { [p in 'n' | 'g' | 'd']: 't' | 'f' }
  i: string | null
  o: 't' | 'f'
  r: {
    f: Date | null
    t: Date | null
  }
}

export const compressSearchForm = (s: SearchForm): CompressedSearchForm => ({
  f: s.freeword,
  s: {
    n: s.status.notStarted ? 't' : 'f',
    g: s.status.interviewing ? 't' : 'f',
    d: s.status.interviewed ? 't' : 'f',
  },
  i: s.interviewerGuid,
  o: s.isOnlyViewed ? 't' : 'f',
  r: {
    f: s.registerTime.from,
    t: s.registerTime.to,
  },
})

export const decompressSearchForm = (c: CompressedSearchForm): SearchForm => ({
  freeword: c.f,
  status: {
    notStarted: c.s.n === 't' ? true : false,
    interviewing: c.s.g === 't' ? true : false,
    interviewed: c.s.d === 't' ? true : false,
  },
  interviewerGuid: c.i,
  isOnlyViewed: c.o === 't' ? true : false,
  registerTime: {
    from: c.r.f,
    to: c.r.t,
  },
})

const defaultValues: SearchForm = {
  freeword: '',
  status: {
    notStarted: true,
    interviewing: true,
    interviewed: false,
  },
  interviewerGuid: null,
  isOnlyViewed: false,
  registerTime: {
    from: null,
    to: null,
  },
}

type Actions =
  | { type: 'SET_FREEWORD'; payload: SearchForm['freeword'] }
  | {
      type: 'SET_STATUS_NOT_STARTED'
      payload: SearchForm['status']['notStarted']
    }
  | {
      type: 'SET_STATUS_INTERVIEWING'
      payload: SearchForm['status']['interviewing']
    }
  | {
      type: 'SET_STATUS_INTERVIEWED'
      payload: SearchForm['status']['interviewed']
    }
  | {
      type: 'SET_IS_ONLY_VIEWED'
      payload: SearchForm['isOnlyViewed']
    }
  | { type: 'RESET' }

const reducer = (state: SearchForm, action: Actions) => {
  switch (action.type) {
    case 'SET_FREEWORD':
      return { ...state, freeword: action.payload }
    case 'SET_STATUS_NOT_STARTED':
      return {
        ...state,
        status: { ...state.status, notStarted: action.payload },
      }
    case 'SET_STATUS_INTERVIEWING':
      return {
        ...state,
        status: { ...state.status, interviewing: action.payload },
      }
    case 'SET_STATUS_INTERVIEWED':
      return {
        ...state,
        status: { ...state.status, interviewed: action.payload },
      }
    case 'SET_IS_ONLY_VIEWED':
      return { ...state, isOnlyViewed: action.payload }
    case 'RESET':
      return defaultValues
    default:
      return state
  }
}

export const useSearchForm = (defaultValuesArg?: SearchForm) => {
  const [formValue, dispatch] = useReducer(
    reducer,
    defaultValuesArg ?? defaultValues
  )
  const [freewordErrorMessage, setErrorMessage] = useState<string>()
  const hasError = useMemo(() => !!freewordErrorMessage, [freewordErrorMessage])
  useEffect(() => {
    const errorCondition =
      typeof formValue.freeword === 'string' &&
      freewordToArray(formValue.freeword).length > SEARCH_FREEWORD_COUNT_MAX

    errorCondition
      ? setErrorMessage(`${SEARCH_FREEWORD_COUNT_MAX}語以下で入力してください`)
      : setErrorMessage(undefined)
  }, [formValue])

  const [validatedFormValue, setValidatedFormValue] = useState<SearchForm>(
    defaultValuesArg ?? defaultValues
  )

  // 主にフリーワード検索での短時間に連続した rpc リクエストを間引く (debounce)
  useEffect(() => {
    const id = setTimeout(() => {
      if (hasError) return
      setValidatedFormValue(formValue)
    }, 300)

    return () => clearTimeout(id)
  }, [formValue, hasError])

  return {
    formValue,
    dispatch,
    validatedFormValue,
    freewordErrorMessage,
  }
}
