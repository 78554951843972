import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  WeakColorSeparator,
  Txt as _Txt,
  Icon,
  NewTabLink,
  RadioGroup as _RadioGroup,
  theme,
} from '@blue-agency/rogue'
import {
  InputText,
  Tooltip,
  DatePicker,
  Dropdown,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { Link as _Link } from 'react-router-dom'
import styled from 'styled-components'
import { AssignmentTooltipInner } from '@/components/AssignmentTooltipInner'
import { SecondaryLink } from '@/components/Link'
import { SettingsSection } from '@/components/SettingsSection'
import { AuthzContainer } from '@/services/authzService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { EXTERNAL_PATHS, INTERNAL_PATHS } from '@/services/urlService'
import {
  assignmentOptions,
  WEB_INTERVIEW_COUNT_MAX,
  minOptions,
  hourOptions,
} from '@/services/webInterviewService'
import { AssigneesForm } from './AssigneesForm'
import * as C from './components'
import { useInputPage } from './useInputPage'

export const InputPage = (): React.ReactElement => {
  const {
    errors,
    register,
    control,
    onSubmit,
    isSubmitting,
    checkKeyDown,
    currentAssignment,
    guideOptions,
    watch,
    setValue,
    getValues,
  } = useInputPage()
  const { permittedRpcs } = AuthzContainer.useContainer()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={onSubmit} onKeyDown={checkKeyDown}>
        <Page.Header>
          <Page.HeaderTitle>Web面接登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="Web面接情報">
            <C.SectionInner>
              <C.AttentionCardWrapper>
                <C.AttentionCard>
                  <Icon name="light-bulb" size="l" />
                  <C.AttentionParagraph>
                    <Txt bold>
                      一度に複数の面接を追加する場合、入力情報が「追加するすべての面接」に反映されます。
                    </Txt>
                    <Txt>
                      担当設定がすべて異なる場合は、1件ずつ登録するか、登録後に個別で情報を編集してください。
                    </Txt>
                  </C.AttentionParagraph>
                </C.AttentionCard>
              </C.AttentionCardWrapper>
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>追加数</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.countOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.countOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <C.TooltipWrapper>
                        <C.TooltipTxt>
                          面接の追加数を入力してください。
                          <br />
                          同時に{WEB_INTERVIEW_COUNT_MAX}
                          件まで追加できます。
                        </C.TooltipTxt>
                      </C.TooltipWrapper>
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.Required />
                <C.InputArea>
                  <InputText
                    {...register('count')}
                    size="ss"
                    onBlurError={errors['count']?.message}
                  />
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>面接名</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.nameOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.nameOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <C.TooltipWrapper>
                        <C.TooltipTxt>
                          社内管理用の面接名です。応募者には表示されません。複数追加される場合、面接名の末尾に自動で連番が付与されます。
                          <br />
                          例）　新卒総合職　→　新卒総合職-0001
                        </C.TooltipTxt>
                        <C.TooltipDetailLinkWrapper>
                          <Txt>
                            <NewTabLink
                              href={EXTERNAL_PATHS.help.interviewName}
                              action="open_help_page_via_register_web_interview__tooltip_name"
                            >
                              詳細はこちら
                            </NewTabLink>
                          </Txt>
                        </C.TooltipDetailLinkWrapper>
                      </C.TooltipWrapper>
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <InputText
                    {...register('name')}
                    size="ll"
                    placeholder="例）【＊＊年卒 新卒採用】総合職 1次面接"
                    onBlurError={errors['name']?.message}
                    autoComplete="off"
                  />
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>予定日時</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <ScheduledDateTimeRow>
                    <DatePicker
                      {...register('scheduledDate')}
                      value={watch('scheduledDate') ?? null}
                      onChange={(date) => {
                        setValue('scheduledDate', date ?? undefined)
                      }}
                      onBlurError={errors['scheduledDate']?.message}
                      errorNoWrap
                      placeholder="選択"
                    />
                    <ScheduledTime>
                      <Hour>
                        <Dropdown
                          {...register('scheduledHour')}
                          size="ss"
                          placeholder="選択"
                          allowEmpty
                          onBlurError={errors['scheduledHour']?.message}
                          options={hourOptions}
                          value={getValues('scheduledHour')}
                          valueWhenFirstOpened={9}
                        />
                        <TimeUnit>時</TimeUnit>
                      </Hour>
                      <Minute>
                        <Dropdown
                          {...register('scheduledMin')}
                          size="ss"
                          placeholder="選択"
                          allowEmpty
                          onBlurError={errors['scheduledMin']?.message}
                          options={minOptions}
                          value={getValues('scheduledMin')}
                        />
                        <TimeUnit>分</TimeUnit>
                      </Minute>
                    </ScheduledTime>
                  </ScheduledDateTimeRow>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>目安時間</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <DurationInput>
                    <InputText
                      {...register('durationMinutes')}
                      size="ss"
                      onBlurError={errors['durationMinutes']?.message}
                      placeholder="入力"
                      errorNoWrap
                    />
                    <MinuteTxt>分</MinuteTxt>
                  </DurationInput>
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlock>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>担当設定</Txt>
                    <div
                      data-tip
                      data-for={TOOLTIP_ID_MAP.assignmentOnRegisterWebInterview}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name="question" size="l" />
                    </div>
                    <Tooltip
                      id={TOOLTIP_ID_MAP.assignmentOnRegisterWebInterview}
                      arrowPosition="topLeft"
                    >
                      <AssignmentTooltipInner />
                    </Tooltip>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.Required />
                <C.InputArea data-testid="assignment">
                  <RadioGroup
                    {...register('assignment')}
                    options={assignmentOptions}
                    direction="row"
                  />
                  {currentAssignment === 'specified' && (
                    <AssigneesForm control={control} register={register} />
                  )}
                </C.InputArea>
              </C.FormBlock>
              <WeakColorSeparator />
              <C.FormBlockWithComplementaryText>
                <C.LabelArea>
                  <C.LabelAreaInner>
                    <Txt>ガイド</Txt>
                  </C.LabelAreaInner>
                </C.LabelArea>
                <C.InputArea>
                  <Dropdown
                    {...register('guideGuid')}
                    size="ll"
                    options={guideOptions}
                    allowEmpty
                    // 確認画面から入力画面に戻ってきたときに、ガイド名が灰色(非アクティブ色)になるのを防ぐため、
                    // 現在選択されているガイドを渡す必要がある
                    value={getValues('guideGuid')}
                  />
                </C.InputArea>
                {permittedRpcs?.includes('CreateWebInterviewGuide') && (
                  <C.ComplementaryTextArea>
                    <LinkToGuideCreationWrapper>
                      <Txt>
                        <SecondaryLink
                          to={INTERNAL_PATHS.newWebInterviewGuide}
                          comlinkPushParams={{
                            action:
                              'click_new_web_interview_guide_link_on_new_web_interview_page',
                          }}
                        >
                          新しいガイドの追加はこちら
                        </SecondaryLink>
                      </Txt>
                    </LinkToGuideCreationWrapper>
                  </C.ComplementaryTextArea>
                )}
              </C.FormBlockWithComplementaryText>
            </C.SectionInner>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink to={INTERNAL_PATHS.webInterviews}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action:
                    'click_cancel_register_web_interview_button_on_input_page',
                }}
              >
                一覧に戻る
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              type="submit"
              disabled={isSubmitting}
              comlinkPushParams={{
                action: 'click_confirm_web_interview_button',
              }}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const LinkToGuideCreationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 16px 268px 0 0;
`

const Txt = styled(_Txt).attrs({ size: 'm' })``

const RadioGroup = styled(_RadioGroup)`
  margin-top: 8px;
`

const BackButtonLink = styled(_Link)`
  color: inherit;
  text-decoration: none;
`

const DurationInput = styled.div`
  display: flex;
  align-items: center;
`

const MinuteTxt = styled(Txt)`
  margin-left: 8px;
`

const ScheduledDateTimeRow = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 19px;
  }
`

const ScheduledTime = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 13px;
  }
`

const TimeUnit = styled(Txt)`
  margin-left: 10px;
  line-height: 32px;
`

const Hour = styled.div`
  display: flex;
`

const Minute = styled.div`
  display: flex;
`
