import { useCallback, useState, useEffect } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { isValidDeadline } from '@/services/recInterviewTemplateService'
import {
  RequestSelectionRecInterviewAnswerModal as Modal,
  ErrorType,
} from './RequestSelectionRecInterviewAnswerModal'
import { useRequestSelectionRecInterviewAnswer } from './useRequestSelectionRecInterviewAnswer'

type Props = {
  active: boolean
  applicantGuid: string
  applicantName: string
  templateName: string
  deadline: Date
  selectionStepGuid: string
  close: () => void
}
export const RequestSelectionRecInterviewAnswerModal: React.VFC<Props> = (
  props
) => {
  const [error, setError] = useState<ErrorType | undefined>(undefined)
  const [deadline, setDeadline] = useState<Date>(props.deadline)
  const { mutateAsync } = useRequestSelectionRecInterviewAnswer()

  const onChangeDeadline = useCallback((date: Date | null) => {
    if (date === null || !isValidDeadline(date, new Date())) {
      setError('pastDate')
      return
    }
    setError(undefined)
    setDeadline(date)
  }, [])

  useEffect(() => {
    setDeadline(props.deadline)
  }, [props.active, props.deadline])

  const request = useCallback(
    // notifyApplicant: 応募者にメール送信するかどうか
    ({ notifyApplicant }: { notifyApplicant: boolean }) =>
      async () => {
        if (!isValidDeadline(deadline, new Date())) {
          setError('pastDate')
          return
        }
        setError(undefined)

        try {
          await mutateAsync({
            applicantGuid: props.applicantGuid,
            deadline,
            notifyApplicant,
            selectionStepGuid: props.selectionStepGuid,
          })
        } catch (e) {
          if (e instanceof CustomBizHuttBffGrpcError) {
            if (e.hasMatchErrorDetail('APPLICANT_EMAIL_NOT_FOUND')) {
              setError('noMailAddress')
              return
            }
            if (
              e.hasMatchErrorDetail('SELECTION_REC_INTERVIEW_ALREADY_EXPIRED')
            ) {
              setError('pastDate')
              return
            }
          }
          captureException(e)
          commonErrorToast()
          return
        }

        if (!notifyApplicant) {
          toast(
            '回答ページリンクを発行しました\n右側のメニューからコピーできます'
          )
        } else {
          toast('提出依頼メールを送信しました')
        }
        props.close()
      },
    [props, mutateAsync, deadline]
  )

  const onClose = useCallback(() => {
    setError(undefined)
    props.close()
  }, [props])

  return (
    <Modal
      active={props.active}
      applicantName={props.applicantName}
      templateName={props.templateName}
      deadline={deadline}
      error={error}
      onClose={onClose}
      onGenerateAnswerLink={request({ notifyApplicant: false })}
      onSendMail={request({ notifyApplicant: true })}
      onChangeDeadline={onChangeDeadline}
    />
  )
}
