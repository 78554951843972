import { useMutation, useQueryClient } from 'react-query'
import { useRequestOpenVideoSeminar } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useOpenVideoSeminar(videoSeminarGuid: string) {
  const queryClient = useQueryClient()
  const { requestOpenVideoSeminar } = useRequestOpenVideoSeminar()

  return useMutation(() => requestOpenVideoSeminar(videoSeminarGuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.videoSeminars)
      queryClient.invalidateQueries([QUERY_KEY.videoSeminar, videoSeminarGuid])
    },
  })
}
