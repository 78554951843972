import { theme, Txt } from '@blue-agency/rogue'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { DateTimeFormat } from '@/components/DateTimeFormat'
import { TableBase } from '@/components/TableBase'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { VideoSeminarForList } from '@/services/videoSeminarService'

type Props = {
  videoSeminars: VideoSeminarForList[]
}
export const List: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="title">タイトル</Th>
            <Th property="publicStatus">公開状況</Th>
            <Th property="padding"></Th>
            <Th property="registerTime">登録日時</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.videoSeminars.length > 0 &&
            props.videoSeminars
              // 登録日時降順
              .sort(
                (a, b) =>
                  b.registeredTime.getTime() - a.registeredTime.getTime()
              )
              .map((videoSeminar) => {
                const path = generatePath(INTERNAL_PATHS.videoSeminarReport, {
                  videoSeminarGuid: videoSeminar.guid,
                })
                return (
                  <TableRow key={videoSeminar.guid}>
                    <Td property="title">
                      <CellLink to={path}>
                        <Txt>{videoSeminar.title}</Txt>
                      </CellLink>
                    </Td>
                    <Td property="publicStatus">
                      <CellLink to={path}>
                        <PublicStatus isPublic={videoSeminar.isPublic}>
                          {videoSeminar.isPublic ? '公開中' : '公開停止中'}
                        </PublicStatus>
                      </CellLink>
                    </Td>
                    <Td property="padding">
                      <CellLink to={path} />
                    </Td>
                    <Td property="registerTime">
                      <CellLink to={path}>
                        <DateTimeFormat
                          dateTime={videoSeminar.registeredTime}
                        />
                      </CellLink>
                    </Td>
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>
      {props.videoSeminars.length === 0 && (
        <Txt size="l" textAlign="center">
          登録された情報はありません
        </Txt>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.color.white[1]};
`

const Table = styled(TableBase)`
  width: 100%;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

type Property = 'title' | 'publicStatus' | 'registerTime' | 'padding'
type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  title: css`
    flex: 0 0 420px;
    justify-content: flex-start;
  `,
  registerTime: css`
    flex: 0 0 120px;
    justify-content: flex-start;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
  publicStatus: css`
    display: flex;
    justify-content: center;
  `,
}

const CellLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -50px;
  padding: 0 50px;
  color: inherit;
  cursor: pointer;
`

const PublicStatus = styled(Txt).attrs({ size: 's' })<{ isPublic: boolean }>`
  width: 70px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isPublic }) => {
    const color = isPublic ? theme.color.green[3] : theme.color.red[2]
    return css`
      border: 1px solid ${color};
      color: ${color};
    `
  }}
`
