import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Event } from '@/pages/ApplicantPage/Content/Event'
import { Profile as P } from '@/pages/ApplicantPage/Content/Profile'
import { Applicant, SelectionStep } from '@/services/applicantService'
import type { TabType } from '../useTabs'
import { Selection as S } from './Selection'

type Props = PropsWithClassName<{
  selectedTab: TabType
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  targetSelectionStep: SelectionStep
  organizationRecInterviewTranscriptionSetting: boolean
}>
export const Content: React.VFC<Props> = (props) => {
  const content = (() => {
    switch (props.selectedTab) {
      case 'profile':
        return (
          <Profile
            applicantGuid={props.applicantGuid}
            applicant={props.applicant}
            registerTime={props.registerTime}
          />
        )
      case 'selection':
        return (
          <Selection
            targetSelectionStep={props.targetSelectionStep}
            organizationRecInterviewTranscriptionSetting={
              props.organizationRecInterviewTranscriptionSetting
            }
          />
        )
      case 'event':
        return <Event applicantGuid={props.applicantGuid} />
    }
  })()

  return <div className={props.className}>{content}</div>
}

const Profile = styled(P)`
  height: 100%;
`

const Selection = styled(S)`
  height: 100%;
`
