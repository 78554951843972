import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { Content } from './Content'
import { Header } from './Header'
import { WebInterviewGuidesPageContainer } from './webInterviewGuidesPageContainer'

export const WebInterviewGuidesPage: React.VFC = provide(
  WebInterviewGuidesPageContainer
)(() => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('ListWebInterviewGuides')

  const { isLoading } = WebInterviewGuidesPageContainer.useContainer()

  if (isLoading) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Header />
      <Content />
    </PageLayoutWithGlonavi>
  )
})
