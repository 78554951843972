import * as Sentry from '@sentry/react'
import Papa from 'papaparse'
import type { CsvBody, CSVData, CsvHeader } from './types'

export type ReadError =
  | 'extensionError'
  | 'noHeaderError'
  | 'noBodyError'
  | 'noFileError'
  | 'requestedMultipleFilesError'
  | 'mulitpleSameHeaderColumnsError'

type Response =
  | {
      type: 'error'
      message: string
    }
  | {
      type: 'valid'
      fileName: string
      csvData: CSVData
    }

const errorMessages: Record<ReadError, string> = {
  extensionError:
    'ファイルの拡張子がcsvではありません。適切なファイルをアップロードしてください。',
  noHeaderError:
    '未入力の項目があります。\nすべての項目に入力して再度アップロードしてください。',
  noBodyError:
    '未入力の項目があります。\nすべての項目に入力して再度アップロードしてください。',
  noFileError: 'ファイルが選択されていません。',
  requestedMultipleFilesError:
    '複数のファイルを一度にアップロードすることはできません。',
  mulitpleSameHeaderColumnsError:
    'CSV1行目に同じ項目名を複数設定することはできません。',
}

const EncodingList = ['utf-8', 'shift-jis'] as const
type Encoding = (typeof EncodingList)[number]

export async function readFile(files: File[]): Promise<Response> {
  if (files.length === 0) {
    return {
      type: 'error',
      message: errorMessages['noFileError'],
    }
  }

  if (files.length > 1) {
    return {
      type: 'error',
      message: errorMessages['requestedMultipleFilesError'],
    }
  }

  const [file] = files

  if (!validateFileExtension(file.name, 'csv')) {
    return {
      type: 'error',
      message: errorMessages['extensionError'],
    }
  }

  const csvString = (await getFileText(file)).trimEnd()

  let header: CsvHeader | undefined
  let body: CsvBody

  try {
    ;[header, body] = parseCsv(csvString)
  } catch (e) {
    Sentry.captureException(e)
    return {
      type: 'error',
      message: errorMessages['noHeaderError'],
    }
  }

  if (header === undefined) {
    return {
      type: 'error',
      message: errorMessages['noHeaderError'],
    }
  }

  if (header.length !== Array.from(new Set(header)).length) {
    return {
      type: 'error',
      message: errorMessages['mulitpleSameHeaderColumnsError'],
    }
  }

  if (body.length === 0) {
    return {
      type: 'error',
      message: errorMessages['noBodyError'],
    }
  }

  return {
    type: 'valid',
    fileName: file.name,
    csvData: {
      csvHeader: header,
      csvBody: body,
    },
  }
}

function parseCsv(text: string): [CsvHeader | undefined, CsvBody] {
  const content = Papa.parse<string[]>(text)

  if (content.errors.length > 0) {
    throw new Error('Failed to parse csv')
  }

  const [header, ...body] = content.data
  return [header, body]
}

function validateFileExtension(fileName: string, extension: string): boolean {
  const chunks = fileName.split('.')
  return chunks.length > 1 && chunks[chunks.length - 1] === extension
}

function getFileText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (!e.target || !e.target.result) {
        reject()
        return
      }
      const buffer = new Uint8Array(e.target.result as ArrayBufferLike)

      // 環境依存文字が含まれたファイルの文字コードを判別することが困難なので、
      // shift-jis, utf-8 でデコードしてみて、対応していない文字コードでデコードした場合に変換される � がファイル中に含まれるかどうかをみる
      // デコードして � が含まれていない場合、対応する文字コードでデコードできたものとする
      for (const encoding of EncodingList) {
        const [succeeded, decoded] = decode(buffer, encoding)
        if (succeeded) {
          resolve(decoded)
        }
      }

      reject()
    }

    reader.readAsArrayBuffer(file)
  })
}

function decode(buffer: Uint8Array, encoding: Encoding): [boolean, string] {
  const decoder = new TextDecoder(encoding)

  const decoded = decoder.decode(buffer)
  const succeeded = !decoded.includes('�')

  return [succeeded, decoded]
}
