import { useEffect, useMemo, useReducer, useState } from 'react'
import {
  freewordToArray,
  SEARCH_FREEWORD_COUNT_MAX,
} from '@/services/webInterviewService'

export type WebInterviewStatus = 'notStarted' | 'interviewing' | 'interviewed'

type SearchForm = {
  freeword?: string
  status: { [p in WebInterviewStatus]: boolean }
  interviewerGuid: string | null
  isAssignmentSpecified: boolean
  registerTime: {
    from: Date | null
    to: Date | null
  }
  scheduledStartTime: {
    from: Date | null
    to: Date | null
  }
}

// クエリパラメータにJSON.stringifyしたものを入れるので、極力プロパティ名を短くする
type CompressedSearchForm = {
  f?: string
  s: { [p in 'n' | 'g' | 'd']: 't' | 'f' }
  i: string | null
  a: 't' | 'f'
  r: {
    f: Date | null
    t: Date | null
  }
  st: {
    f: Date | null
    t: Date | null
  }
}

export const compressSearchForm = (s: SearchForm): CompressedSearchForm => ({
  f: s.freeword,
  s: {
    n: s.status.notStarted ? 't' : 'f',
    g: s.status.interviewing ? 't' : 'f',
    d: s.status.interviewed ? 't' : 'f',
  },
  i: s.interviewerGuid,
  a: s.isAssignmentSpecified ? 't' : 'f',
  r: {
    f: s.registerTime.from,
    t: s.registerTime.to,
  },
  st: {
    f: s.scheduledStartTime.from,
    t: s.scheduledStartTime.to,
  },
})

export const decompressSearchForm = (c: CompressedSearchForm): SearchForm => ({
  freeword: c.f,
  status: {
    notStarted: c.s.n === 't' ? true : false,
    interviewing: c.s.g === 't' ? true : false,
    interviewed: c.s.d === 't' ? true : false,
  },
  interviewerGuid: c.i,
  isAssignmentSpecified: c.a === 't' ? true : false,
  registerTime: {
    from: c.r.f,
    to: c.r.t,
  },
  scheduledStartTime: {
    from: c.st.f,
    to: c.st.t,
  },
})

const defaultValues: SearchForm = {
  freeword: '',
  status: {
    notStarted: true,
    interviewing: true,
    interviewed: true,
  },
  interviewerGuid: null,
  isAssignmentSpecified: false,
  registerTime: {
    from: null,
    to: null,
  },
  scheduledStartTime: {
    from: null,
    to: null,
  },
}

type Actions =
  | { type: 'SET_FREEWORD'; payload: SearchForm['freeword'] }
  | {
      type: 'SET_STATUS_NOT_STARTED'
      payload: SearchForm['status']['notStarted']
    }
  | {
      type: 'SET_STATUS_INTERVIEWING'
      payload: SearchForm['status']['interviewing']
    }
  | {
      type: 'SET_STATUS_INTERVIEWED'
      payload: SearchForm['status']['interviewed']
    }
  | { type: 'SET_INTERVIEWER_GUID'; payload: SearchForm['interviewerGuid'] }
  | {
      type: 'SET_IS_ASSIGNMENT_SPECIFIED'
      payload: SearchForm['isAssignmentSpecified']
    }
  | { type: 'SET_REGISTER_FROM'; payload: SearchForm['registerTime']['from'] }
  | { type: 'SET_REGISTER_TO'; payload: SearchForm['registerTime']['to'] }
  | {
      type: 'SET_SCHEDULED_START_FROM'
      payload: SearchForm['scheduledStartTime']['from']
    }
  | {
      type: 'SET_SCHEDULED_START_TO'
      payload: SearchForm['scheduledStartTime']['to']
    }
  | { type: 'RESET' }

const reducer = (state: SearchForm, action: Actions) => {
  switch (action.type) {
    case 'SET_FREEWORD':
      return { ...state, freeword: action.payload }
    case 'SET_STATUS_NOT_STARTED':
      return {
        ...state,
        status: { ...state.status, notStarted: action.payload },
      }
    case 'SET_STATUS_INTERVIEWING':
      return {
        ...state,
        status: { ...state.status, interviewing: action.payload },
      }
    case 'SET_STATUS_INTERVIEWED':
      return {
        ...state,
        status: { ...state.status, interviewed: action.payload },
      }
    case 'SET_INTERVIEWER_GUID':
      return { ...state, interviewerGuid: action.payload }
    case 'SET_IS_ASSIGNMENT_SPECIFIED':
      return { ...state, isAssignmentSpecified: action.payload }
    case 'SET_REGISTER_FROM':
      return {
        ...state,
        registerTime: { ...state.registerTime, from: action.payload },
      }
    case 'SET_REGISTER_TO':
      return {
        ...state,
        registerTime: { ...state.registerTime, to: action.payload },
      }
    case 'SET_SCHEDULED_START_FROM':
      return {
        ...state,
        scheduledStartTime: {
          ...state.scheduledStartTime,
          from: action.payload,
        },
      }
    case 'SET_SCHEDULED_START_TO':
      return {
        ...state,
        scheduledStartTime: { ...state.scheduledStartTime, to: action.payload },
      }
    case 'RESET':
      return defaultValues
    default:
      return state
  }
}

export const useSearchForm = (defaultValuesArg?: SearchForm) => {
  const [formValue, dispatch] = useReducer(
    reducer,
    defaultValuesArg ?? defaultValues
  )
  const [freewordErrorMessage, setErrorMessage] = useState<string>()
  const hasError = useMemo(() => !!freewordErrorMessage, [freewordErrorMessage])
  useEffect(() => {
    const errorCondition =
      typeof formValue.freeword === 'string' &&
      freewordToArray(formValue.freeword).length > SEARCH_FREEWORD_COUNT_MAX

    errorCondition
      ? setErrorMessage(`${SEARCH_FREEWORD_COUNT_MAX}語以下で入力してください`)
      : setErrorMessage(undefined)
  }, [formValue])

  const [validatedFormValue, setValidatedFormValue] = useState<SearchForm>(
    defaultValuesArg ?? defaultValues
  )

  // 主にフリーワード検索での短時間に連続した rpc リクエストを間引く (debounce)
  useEffect(() => {
    const id = setTimeout(() => {
      if (hasError) return
      setValidatedFormValue(formValue)
    }, 300)

    return () => clearTimeout(id)
  }, [formValue, hasError])

  return {
    formValue,
    dispatch,
    validatedFormValue,
    freewordErrorMessage,
  }
}
