import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteSelectionStepMasterRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type DeleteSelectionStepMasterRequestValue = {
  guid: string
}

export function useRequestDeleteSelectionStepMaster() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteSelectionStepMaster = useCallback(
    async (value: DeleteSelectionStepMasterRequestValue) => {
      const metadata = await buildMetadata()

      const req = new DeleteSelectionStepMasterRequest()

      req.setGuid(value.guid)

      return callBizHuttBffService(
        'deleteSelectionStepMaster',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestDeleteSelectionStepMaster,
  }
}
