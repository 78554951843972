import React from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type AddButtonProps = {
  active?: boolean
  onClick: () => void
}
export const AddButton: React.VFC<AddButtonProps> = ({
  active = true,
  onClick,
}) => (
  <Wrapper active={active} onClick={active ? onClick : () => {}}>
    <IconAdd active={active} />
    <Text active={active}>追加</Text>
  </Wrapper>
)

type Props = { active: boolean }
const Wrapper = styled.div<Props>`
  font-size: ${theme.fontSize.m};
  display: inline-flex;
  flex-direction: row;
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
`
const IconAdd = styled(Icon).attrs({ name: 'add', size: 's' })<Props>`
  ${({ active }) => !active && `color: ${theme.color.gray[2]};`}
`
const Text = styled.div<Props>`
  padding-left: 5px;
  ${({ active }) => !active && `color: ${theme.color.gray[2]};`}
`
