import { Icon, theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { SelectionRecInterviewQuestions as _SelectionRecInterviewQuestions } from '@/pages/ApplicantPage/Content/Selection/SelectionStep/SelectionRecInterviewQuestions'
import {
  getSelectionStepResultText,
  mapResultMeta,
  SelectionStep as SelectionStepType,
} from '@/services/applicantService'

type Props = PropsWithClassName<{
  targetSelectionStep: SelectionStepType
  organizationRecInterviewTranscriptionSetting: boolean
}>

export const SelectionStep: React.VFC<Props> = (props) => {
  const resultMeta = mapResultMeta(props.targetSelectionStep.result)
  return (
    <Wrapper className={props.className}>
      <Title>
        <TitleTxt>{props.targetSelectionStep.name}</TitleTxt>
      </Title>
      <Content>
        <Result color={resultMeta.color}>
          <Icon name={resultMeta.iconName} width="22px" height="22px" />
          <ResultTxt color={resultMeta.color}>
            {getSelectionStepResultText(props.targetSelectionStep.result)}
          </ResultTxt>
        </Result>
        {props.targetSelectionStep.comment !== '' && (
          <>
            <Separator />
            <Comment>{props.targetSelectionStep.comment}</Comment>
          </>
        )}
      </Content>
      {props.targetSelectionStep.selectionRecInterview && (
        <SelectionRecInterviewQuestions
          questionAnswerPairsList={
            props.targetSelectionStep.selectionRecInterview
              .questionAnswerPairsList
          }
          canWatchAnswer // NOTE: 評価ページでは、アクセスできているユーザーは全て閲覧権限がある
          beforeSubmitAnswer={
            props.targetSelectionStep.selectionRecInterview.submissionTime ===
            undefined
          }
          organizationRecInterviewTranscriptionSetting={
            props.organizationRecInterviewTranscriptionSetting
          }
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};
`

const Title = styled.div`
  height: 32px;
  background-color: ${theme.color.navy[3]};
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const TitleTxt = styled(Txt).attrs({ size: 'l' })`
  white-space: nowrap;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  max-width: 500px;
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  margin: 20px;
  padding: 10px 20px;
`

const Result = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`

const ResultTxt = styled(Txt).attrs({ size: 'xl' })`
  margin-left: 12px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 10px;
`

const Comment = styled(Txt)`
  margin-top: 10px;
  max-height: 100px;
  overflow-y: auto;
  word-wrap: break-word;
`

const SelectionRecInterviewQuestions = styled(_SelectionRecInterviewQuestions)`
  padding: 0 20px 20px;
`
