import React, { useMemo } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { assertNever } from '@/assertions'
import { Loading } from '@/components/Loading'
import {
  mapProtoToApplicantEvents,
  useListApplicantEvents,
} from '@/services/applicantService'
import { ApplicantNameHeader } from '../ApplicantNameHeader'
import { VideoSeminarEvent } from './VideoSeminarEvent'

type Props = PropsWithClassName<{
  applicantGuid: string
  applicantName: string
  applicantNameKana?: string
}>

export const Event: React.VFC<Props> = ({
  applicantGuid,
  applicantName,
  applicantNameKana,
  className,
}) => {
  const { data, isLoading } = useListApplicantEvents(applicantGuid)

  const events = useMemo(() => {
    return data === undefined ? [] : mapProtoToApplicantEvents(data)
  }, [data])

  if (isLoading || data === undefined) {
    return <Loading />
  }

  return (
    <Wrapper className={className}>
      <ApplicantNameHeader
        applicantName={applicantName}
        applicantNameKana={applicantNameKana}
      />
      {events.length === 0 ? (
        <EmptyTxt>イベントはありません</EmptyTxt>
      ) : (
        // eslint-disable-next-line array-callback-return
        events.map((e) => {
          switch (e.type) {
            case 'videoSeminar':
              return (
                <VideoSeminarEvent
                  key={e.data.seminarGuid}
                  videoSeminarEvent={e.data}
                />
              )
            default:
              assertNever(e.type)
          }
        })
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  background-color: ${theme.color.white[1]};
`

const EmptyTxt = styled(Txt).attrs({ size: 'l' })`
  margin: 20px auto;
`
