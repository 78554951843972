export type FileValidateError =
  | 'extensionError'
  | 'maxBlobSizeError'
  | 'rejectFilesError'

export type ValidateFilesResponse =
  | {
      type: 'error'
      message: string
    }
  | {
      type: 'valid'
      fileName: string
      video: Blob
    }

const maxVideoBlobSize = 200 * 1024 * 1024 // 200MB
const errorMessages: Record<FileValidateError, string> = {
  extensionError:
    'ファイルの拡張子がMP4ではありません。適切なファイルをアップロードしてください。',
  maxBlobSizeError:
    'ファイルが大きすぎるため、アップロードできませんでした。\n※アップロードファイルのサイズ上限は200MBです。',
  rejectFilesError: '拡張子がMP4のファイルを1つアップロードしてください。',
}

export function validateFiles(files: File[]): ValidateFilesResponse {
  if (files.length !== 1) {
    return {
      type: 'error',
      message: errorMessages['rejectFilesError'],
    }
  }

  const [file] = files

  if (file === undefined) {
    throw new Error('file is undefined')
  }

  if (!isValidFileExtension(file.name, 'mp4')) {
    return {
      type: 'error',
      message: errorMessages['extensionError'],
    }
  }

  const blob = file as Blob
  if (maxVideoBlobSize < blob.size) {
    return {
      type: 'error',
      message: errorMessages['maxBlobSizeError'],
    }
  }

  return {
    type: 'valid',
    fileName: file.name,
    video: blob,
  }
}

function isValidFileExtension(fileName: string, extension: string): boolean {
  const chunks = fileName.split('.')
  return (
    chunks.length > 1 &&
    chunks[chunks.length - 1].toLowerCase() === extension.toLowerCase()
  )
}
