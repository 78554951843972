import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  active: boolean
  onClose: () => void
  onRevoke: () => void
}

export const RevokeRequestSelectionRecInterviewAnswerModal: React.VFC<Props> = (
  props
) => {
  return (
    <Modal
      active={props.active}
      title="提出依頼を取消"
      onClose={props.onClose}
      size="m"
    >
      <ModalContent>
        <ModalTxt>
          録画面接の提出依頼を取消します。
          <br />
          よろしいですか？
        </ModalTxt>
      </ModalContent>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_cancel_button_on_revoke_request_selection_rec_interview_answer_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <DangerButton
            widthSize="L1"
            onClick={props.onRevoke}
            comlinkPushParams={{
              action:
                'click_revoke_button_on_revoke_request_selection_rec_interview_answer_modal',
            }}
          >
            提出依頼を取消
          </DangerButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalTxt = styled(Txt)`
  margin-top: 20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`
