import { assertIsDefined } from '@/assertions'

type VideoQuestionlMaxDurationSecondsOption = {
  label: string
  value: string
}

export const videoQuestionlMaxDurationSecondsOptions: Array<VideoQuestionlMaxDurationSecondsOption> =
  [
    {
      label: '無制限',
      value: '0',
    },
    {
      label: '30秒',
      value: '30',
    },
    {
      label: '1分',
      value: '60',
    },
    {
      label: '1分30秒',
      value: '90',
    },
    {
      label: '2分',
      value: '120',
    },
    {
      label: '3分',
      value: '180',
    },
    {
      label: '5分',
      value: '300',
    },
    {
      label: '10分',
      value: '600',
    },
  ]

export function getVideoQuestionlMaxDurationSecondsLabel(
  value: string
): string {
  const option = videoQuestionlMaxDurationSecondsOptions.find(
    (option) => option.value === value
  )
  assertIsDefined(option)
  return option.label
}
