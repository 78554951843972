import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { generatePath, useHistory } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { TableBase } from '@/components/TableBase'
import { ElapsedTimeProgress } from '@/pages/ControlCenterPage/ElapsedTimeProgress'
import { IntervieweeAvatar } from '@/pages/ControlCenterPage/IntervieweeAvatar'
import { OmittedInterviewerAvatarList } from '@/pages/ControlCenterPage/OmittedInterviewerAvatarList'
import { RequestingInterviewees } from '@/pages/ControlCenterPage/RequestingInterviewees'
import type { OngoingInterview } from '@/pages/ControlCenterPage/types'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  ongoingList: OngoingInterview[]
  loadTime: Date
}

export const OngoingList: React.VFC<Props> = (props) => {
  const history = useHistory()

  return (
    <Wrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="elapsedTime">
              <Txt>経過時間</Txt>
            </Th>
            <Th property="interviewName">
              <Txt>面接名</Txt>
            </Th>
            <Th property="interviewers">
              <Txt>面接官（参加中 / 参加予定）</Txt>
            </Th>
            <Th property="interviewees">
              <Txt>応募者（参加中）</Txt>
            </Th>
            <Th property="requestingInterviewees">
              <Txt>応募者（入室待ち）</Txt>
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ongoingList.map((interview) => {
            const webInterviewPagePath = generatePath(
              INTERNAL_PATHS.webInterview,
              { webInterviewGuid: interview.guid }
            )

            const onClick: React.MouseEventHandler<HTMLTableRowElement> = (
              e
            ) => {
              // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
              if ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) {
                window.open(webInterviewPagePath)
                return
              }
              history.push({
                pathname: webInterviewPagePath,
              })
            }

            return (
              <TableRow
                key={interview.guid}
                onClick={onClick}
                isFinished={!!interview.finishTime}
              >
                <Td property="elapsedTime">
                  <ElapsedTimeProgress
                    scheduledStartTime={interview.scheduledStartTime}
                    scheduledFinishTime={interview.scheduledFinishTime}
                    startedTime={interview.startTime}
                    finishedTime={interview.finishTime}
                    loadTime={props.loadTime}
                  />
                </Td>
                <Td property="interviewName">
                  <Txt size="m">{interview.name}</Txt>
                </Td>
                <Td property="interviewers">
                  <OmittedInterviewerAvatarList
                    interviewers={interview.interviewers}
                    interviewGuid={interview.guid}
                  />
                </Td>
                <Td property="interviewees">
                  <IntervieweeAvatarsWrapper>
                    {interview.interviewees.map(
                      ({ soraClientId, displayName }) => {
                        return (
                          <IntervieweeAvatar
                            key={soraClientId}
                            name={displayName}
                          />
                        )
                      }
                    )}
                  </IntervieweeAvatarsWrapper>
                </Td>
                <Td property="requestingInterviewees">
                  <RequestingInterviewees
                    interviewGuid={interview.guid}
                    requestingInterviewees={interview.requestingInterviewees}
                  />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const width = {
  elapsedTime: 212,
  interviewName: 148,
  interviewers: 220,
  interviewees: 392,
  requestingInterviewees: 168,
}

type Property = keyof typeof width

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;

  tbody tr {
    border-bottom: 1px solid ${theme.color.gray[3]};
  }

  td {
    height: auto;
    border-bottom: none;
  }
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr<{ isFinished?: boolean }>`
  display: flex;
  align-items: center;
  ${TableBody} & {
    cursor: pointer;
  }

  ${({ isFinished }) =>
    isFinished &&
    css`
      background-color: ${theme.color.gray[4]};
    `}
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  elapsedTime: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.elapsedTime}px;
    justify-content: flex-start;
  `,
  interviewName: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.interviewName}px;
    justify-content: flex-start;
  `,
  interviewers: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.interviewers}px;
    justify-content: flex-start;
  `,
  interviewees: css`
    display: flex;
    align-items: center;
    flex: 1 0 ${width.interviewees}px;
    justify-content: flex-start;
  `,
  requestingInterviewees: css`
    display: flex;
    align-items: center;
    flex: 1 0 ${width.requestingInterviewees}px;
    justify-content: flex-start;
  `,
}

const IntervieweeAvatarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 360px;
  flex-wrap: wrap;
  padding: 6px 0;
  row-gap: 4px;
  column-gap: 8px;
`
