import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateWebInterviewAssignmentRequest } from '@blue-agency/proton/biz_hutt_bff'
import {
  WebInterviewAssignmentInput,
  WebInterviewAssignee,
  WebInterviewAssigneeRole,
} from '@blue-agency/proton/im'
import type { Assignee, Assignment } from '@/services/webInterviewService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateWebInterviewAssignmentRequestValue = {
  webInterviewGuid: string
  assignment: Assignment
  assignees?: Assignee[]
}

const encodeAssignee = (assignee: Assignee): WebInterviewAssignee => {
  const encodedAssignee = new WebInterviewAssignee()
  const assigneeRole = (() => {
    switch (assignee.role) {
      case 'interviewer':
        return WebInterviewAssigneeRole.INTERVIEWER
      case 'viewer':
        return WebInterviewAssigneeRole.VIEWER
    }
  })()

  encodedAssignee.setStaffGuid(assignee.guid)
  encodedAssignee.setAssigneeRole(assigneeRole)
  return encodedAssignee
}

export function useRequestUpdateWebInterviewAssignment() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateWebInterviewAssignment = useCallback(
    async (value: UpdateWebInterviewAssignmentRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateWebInterviewAssignmentRequest()
      const assignmentInput = new WebInterviewAssignmentInput()
      switch (value.assignment) {
        case 'specified':
          const specifiedInput = new WebInterviewAssignmentInput.Specified()
          const assigneeList = value.assignees?.map(encodeAssignee) ?? []
          specifiedInput.setAssigneesList(assigneeList)
          specifiedInput.setSpecified(true)
          assignmentInput.setSpecified(specifiedInput)
          break
        case 'unspecified':
          assignmentInput.setUnspecified(true)
          break
        case 'unspecified-cannot-view-detail':
          assignmentInput.setUnspecifiedCannotViewDetail(true)
          break
      }

      req.setWebInterviewGuid(value.webInterviewGuid)
      req.setAssignmentInput(assignmentInput)

      return callBizHuttBffService(
        'updateWebInterviewAssignment',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestUpdateWebInterviewAssignment }
}
