import { RecInterviewQuestionFormat } from '@blue-agency/proton/web/v2/im'
import { assertIsDefined } from '@/assertions'

export const questionFormats = ['text', 'video'] as const

export type QuestionFormat = (typeof questionFormats)[number]

export type QuestionFormatOption = {
  label: string
  value: QuestionFormat
}

export const questionFormatOptions: Array<QuestionFormatOption> = [
  {
    label: '動画',
    value: 'video',
  },
  {
    label: 'フリーテキスト',
    value: 'text',
  },
]

export function getQuestionFormatOption(
  value: QuestionFormat
): QuestionFormatOption {
  const option = questionFormatOptions.find((option) => option.value === value)
  assertIsDefined(option)
  return option
}

export function getQuestionFormatLabel(value: QuestionFormat): string {
  return getQuestionFormatOption(value).label
}

export function mapQuestionFormat(
  format: RecInterviewQuestionFormat
): QuestionFormat {
  switch (format) {
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_UNKNOWN:
      throw new Error('invalid format')
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_TEXT:
      return 'text'
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_VIDEO:
      return 'video'
  }
}
