import React from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { theme, Txt as T } from '@blue-agency/rogue'
import { Dropdown as D, DropdownOption } from '@blue-agency/rogue/im'
import { isExists } from 'date-fns'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  value: Date | undefined
  onChange: (date?: Date) => void
}>

const yearOptions: DropdownOption[] = (() => {
  let res: DropdownOption[] = []
  // NOTE: 1900年以降生まれで、15歳以上を対象とする
  // ref: https://stadium-co-jp.slack.com/archives/C012X3RGT6Y/p1627602130018800?thread_ts=1627552443.007100&cid=C012X3RGT6Y
  for (let i = new Date().getFullYear() - 15; i >= 1900; i--) {
    res.push({
      label: String(i),
      value: String(i),
    })
  }
  return res
})()

const monthOptions: DropdownOption[] = [...Array(12)].map((_, i) => ({
  label: String(i + 1),
  value: String(i),
}))

const dateOptions: DropdownOption[] = [...Array(31)].map((_, i) => ({
  label: String(i + 1),
  value: String(i + 1),
}))

export const BirthDatePicker: React.VFC<Props> = ({
  value,
  onChange,
  className,
}) => {
  const [year, setYear] = useState(value?.getFullYear())
  const [month, setMonth] = useState(value?.getMonth())
  const [date, setDate] = useState(value?.getDate())
  const [invalidDate, setInvalidDate] = useState(false)

  const onChangeYear = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setYear(Number(event.target.value))
    },
    []
  )

  const onChangeMonth = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setMonth(Number(event.target.value))
    },
    []
  )

  const onChangeDate = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDate(Number(event.target.value))
    },
    []
  )

  const reset = useCallback(() => {
    setYear(undefined)
    setMonth(undefined)
    setDate(undefined)
    onChange(undefined)
  }, [onChange])

  useEffect(() => {
    if (year === undefined || month === undefined || date === undefined) return
    const valid = isExists(year, month, date)
    if (valid) {
      setInvalidDate(false)
      // NOTE: storyshotsで"onChange is not a function"が出るためtype guardしている
      if (typeof onChange === 'function') {
        onChange(new Date(year, month, date))
      }
    } else {
      setInvalidDate(true)
    }
  }, [year, month, date, onChange])

  return (
    <Wrapper className={className}>
      <Dropdown
        value={year?.toString()}
        onChange={onChangeYear}
        options={yearOptions}
        size="ss"
        placeholder="選択"
        key={year}
      />
      <Txt>年</Txt>
      <Dropdown
        value={month?.toString()}
        onChange={onChangeMonth}
        options={monthOptions}
        size="ss"
        placeholder="選択"
        key={month}
      />
      <Txt>月</Txt>
      <Dropdown
        value={date?.toString()}
        onChange={onChangeDate}
        options={dateOptions}
        size="ss"
        placeholder="選択"
        key={date}
      />
      <Txt>日</Txt>
      <ResetButton onClick={reset}>
        <ResetTxt>選択を解除</ResetTxt>
      </ResetButton>
      {invalidDate && <ErrorMessage>この日付は選択できません</ErrorMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Dropdown = styled(D)`
  &&:not(:first-of-type) {
    margin-left: 8px;
  }
`

const Txt = styled(T)`
  margin-left: 8px;
`

const ErrorMessage = styled.span`
  position: absolute;
  top: -30px;
  left: 210px;
  padding: 4px 8px;
  background-color: ${theme.color.red[2]};
  opacity: 0.9;
  color: ${theme.color.white[1]};
  font-size: ${theme.fontSize.m};
  :before {
    content: '';
    position: absolute;
    top: 100%;
    left: 8px;
    border: 6px solid transparent;
    border-top: 6px solid ${theme.color.red[2]};
  }
`

const ResetButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const ResetTxt = styled(Txt).attrs({ size: 's' })`
  margin-left: 2px;
  text-decoration: underline;
`
