import { useMutation, useQueryClient } from 'react-query'
import {
  RevokeRequestSelectionRecInterviewAnswerRequestValue,
  useRequestRevokeRequestSelectionRecInterviewAnswer,
} from '@/services/bffService/useRequestRevokeRequestSelectionRecInterviewAnswer'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useRevokeRequestSelectionRecInterviewAnswer() {
  const queryClient = useQueryClient()
  const { requestRevokeRequestSelectionRecInterviewAnswer } =
    useRequestRevokeRequestSelectionRecInterviewAnswer()

  return useMutation(
    (value: RevokeRequestSelectionRecInterviewAnswerRequestValue) =>
      requestRevokeRequestSelectionRecInterviewAnswer(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.applicantGuid])
      },
    }
  )
}
