import React, { useState } from 'react'
import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { InputTextHover } from '@/components/InputTextHover'
import { InputVideoHover } from '@/components/InputVideoHover'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import { useModal } from '@/hooks/useModal'
import { MessageModal } from './Modal'
import { PreviewLayout } from './PreviewLayout'
import { StepParam } from './types'
import { VideoModal } from './video/VideoModal'

export const InputOutro: React.VFC<StepParam> = ({ form }) => {
  const [outroMessage, setOutroMessage] = useState(
    form.getValues('outroMessage')
  )
  const [videoURL, setVideoURL] = useState<string>(
    form.getValues('outroVideoURL')
  )

  const msgModal = useModal()
  const videoModal = useModal()

  const loggedInStaff = useLoggedInStaff()

  const organizationName = loggedInStaff?.organizationName ?? ''

  const onMsgCancel = useCallback(() => {
    msgModal.close()
    form.setValue('outroMessage', outroMessage)
  }, [outroMessage, form, msgModal])

  const onMsgSave = useCallback(async () => {
    const valid = await form.trigger('outroMessage')
    if (!valid) return
    setOutroMessage(form.getValues('outroMessage'))
    msgModal.close()
  }, [form, msgModal])

  const onVideoCancel = useCallback(() => {
    videoModal.close()
  }, [videoModal])

  const onVideoSave = useCallback(
    (fileKey: string, blob: Blob) => {
      form.setValue('outroVideoFileKey', fileKey)
      const url = URL.createObjectURL(blob)
      form.setValue('outroVideoURL', url)
      setVideoURL(url)
      videoModal.close()
    },
    [form, videoModal]
  )

  const onVideoDelete = useCallback(() => {
    form.setValue('outroVideoFileKey', '')
    if (videoURL) URL.revokeObjectURL(videoURL)
    form.setValue('outroVideoURL', '')
    setVideoURL('')
  }, [form, videoURL])

  const placeholder = `例）ご回答ありがとうございました。
  選考の結果については、改めて連絡を差し上げますのでお待ちください。`

  return (
    <SettingsSection title="応募者の終了ページ">
      <PreviewWrapper>
        <PreviewWrapperInner>
          <PreviewLayout
            body={
              <div>
                <IconWrapper>
                  <CheckIcon />
                </IconWrapper>
                <Txt textAlign="center" size="l">
                  お疲れさまでした！
                </Txt>
                <Txt textAlign="center" size="l">
                  回答を提出しました！
                </Txt>
                <InputWrapper>
                  <PreviewHead>{organizationName}からのメッセージ</PreviewHead>
                  <InputVideoHover
                    hoverInner={<C.EditButton onClick={videoModal.open} />}
                    placeholder="エンディング動画を選択してください"
                    videoURL={videoURL}
                    onDelete={onVideoDelete}
                  />
                  <VideoModal
                    active={videoModal.active}
                    onCancel={onVideoCancel}
                    onSave={onVideoSave}
                    type="outro"
                  />
                  <Box mt="14px">
                    <InputTextHover
                      hoverInner={<C.EditButton onClick={msgModal.open} />}
                      placeholder={placeholder}
                      value={outroMessage}
                      onBlurError={form.errors.outroMessage?.message}
                    />
                  </Box>
                  <MessageModal
                    active={msgModal.active}
                    form={form}
                    onCancel={onMsgCancel}
                    onSave={onMsgSave}
                    placeholder={placeholder}
                    onBlurError={form.errors.outroMessage?.message}
                    type="outro"
                  />
                </InputWrapper>
                <Box mt="20px">
                  <Txt>以上で終了となりますので、 ページを閉じてください。</Txt>
                </Box>
              </div>
            }
            bottom={null}
            organizationName={organizationName}
          />
        </PreviewWrapperInner>
      </PreviewWrapper>
    </SettingsSection>
  )
}

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const PreviewWrapperInner = styled.div`
  width: 360px;
  height: 700px;
  padding: 40px 0;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 16px;
`

const CheckIcon = styled(Icon).attrs({
  name: 'check',
  width: '48',
  height: '48',
})`
  color: ${theme.color.green[4]};
`

const PreviewHead = styled(Txt).attrs({ size: 'l', bold: true })`
  margin-bottom: 12px;
`

const InputWrapper = styled.div`
  margin-top: 30px;
`
