import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { BatchUpdateWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from '@/services/bffService/metadata'

export function useRequestBatchUpdateWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestBatchUpdateWebInterviews = useCallback(
    async (req: BatchUpdateWebInterviewsRequest) => {
      const metadata = await buildMetadata()

      return callBizHuttBffService(
        'batchUpdateWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestBatchUpdateWebInterviews,
  }
}
