import React, { useCallback, useState } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
  commonErrorToast,
} from '@blue-agency/im-shared-front'
import {
  LineClampedTxt,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { captureException } from '@sentry/minimal'
import assert from 'assert'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { assertNever } from '@/assertions'
import {
  CSVUploadValidationErrorModal,
  DetailValidationError,
} from '@/components/CSVUploadValidationErrorModal'
import { SettingsSection } from '@/components/SettingsSection'
import { useModal } from '@/hooks/useModal'
import { LinkToHelp } from '@/pages/WebInterviewUpdateByFilePage/LinkToHelp'
import { WebInterviewUpdate } from '@/pages/WebInterviewUpdateByFilePage/csv/WebInterviewUpdate'
import { useBatchUpdateWebInterviews } from '@/pages/WebInterviewUpdateByFilePage/useBatchUpdateWebInterviews'
import { CustomBizHuttBffGrpcError } from '@/services/bffService/customGrpcError'
import type { CSVData, MappingResult } from '../types'

type Props = {
  csvData: CSVData
  mapping: MappingResult
  webInterviewUpdates: WebInterviewUpdate[]
  goPrevious: () => void
  goNext: () => void
}

export const Confirm: React.VFC<Props> = ({
  csvData,
  mapping,
  webInterviewUpdates,
  goPrevious,
  goNext,
}) => {
  const { mutateAsync } = useBatchUpdateWebInterviews()
  const validationErrorModal = useModal()
  const [detailValidationErrors, setDetailValidationErrors] = useState<
    DetailValidationError[]
  >([])

  const getMappingName = useCallback(
    (index: number): string => {
      if (mapping.key === index) {
        const keyType = mapping.keyType
        switch (keyType) {
          case 'web-interview-guid':
            return 'Web面接ID'
          case 'ats-interview-id':
            return '連携先Web面接ID'
          default:
            assertNever(keyType)
        }
      }

      if (mapping.name === index) {
        return '面接名'
      }

      if (mapping['scheduled-start-time'] === index) {
        return '予定日時'
      }

      if (mapping.duration === index) {
        return '目安時間'
      }

      if (mapping['interview-guide-guid'] === index) {
        return '面接ガイドID'
      }

      if (mapping.assignment === index) {
        return '担当設定'
      }

      if (mapping.interviewer?.includes(index)) {
        return '面接官'
      }

      if (mapping.viewer?.includes(index)) {
        return '閲覧者'
      }

      return ''
    },
    [mapping]
  )

  const onClickPreviousButton = useCallback(() => {
    goPrevious()
  }, [goPrevious])

  const onSubmit = useCallback(async () => {
    try {
      await mutateAsync({
        mapping,
        webInterviewUpdates,
      })
      goNext()
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        const fieldViolations = e.getFieldViolations()
        if (fieldViolations.length > 0) {
          setDetailValidationErrors(
            fieldViolations.map((fv) => {
              const matches = fv.getField().match(/^web_interviews\[(\d+)\]/)
              assert(matches !== null)
              const row = parseInt(matches[1])
              return {
                rowNumber: row + 1,
                message: fv.getDescription(),
              }
            })
          )
          validationErrorModal.open()
          return
        }
      }
      captureException(e)
      commonErrorToast()
    }
  }, [mapping, webInterviewUpdates, goNext, mutateAsync, validationErrorModal])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>Web面接一括更新</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToHelp />
            </SubTextWrapper>
          </Left>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="更新内容の確認">
            <BodyWrapper>
              <WebInterviewsCountTxt>
                更新対象件数
                <WebInterviewsCount>
                  {webInterviewUpdates.length}
                </WebInterviewsCount>
                件
              </WebInterviewsCountTxt>
              <WeakColorSeparator />
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Th property="rowID">
                        <TableHeaderTxt>CSV列</TableHeaderTxt>
                      </Th>
                      <Th property="csvHeader">
                        <TableHeaderTxt>CSV1行目（項目名）</TableHeaderTxt>
                      </Th>
                      <Th property="csvFirstData">
                        <TableHeaderTxt>CSV2行目（データ）</TableHeaderTxt>
                      </Th>
                      <Th property="target">
                        <TableHeaderTxt>
                          インタビューメーカーの項目名
                        </TableHeaderTxt>
                      </Th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.csvHeader.map((header, i) => (
                      <TableRow key={i}>
                        <Td property="rowID">
                          <TableBodyTxt>{i + 1}</TableBodyTxt>
                        </Td>
                        <Td property="csvHeader">
                          <TableBodyLineClampedTxt line={2} size="s">
                            {header}
                          </TableBodyLineClampedTxt>
                        </Td>
                        <Td property="csvFirstData">
                          {csvData.csvBody[0].length > i && (
                            <TableBodyLineClampedTxt line={2} size="s">
                              {csvData.csvBody[0][i]}
                            </TableBodyLineClampedTxt>
                          )}
                        </Td>
                        <Td property="target">
                          <TableBodyTxt>{getMappingName(i)}</TableBodyTxt>
                        </Td>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            </BodyWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_previous_button_on_upload_web_interviews_csv_confirm',
              }}
              onClick={onClickPreviousButton}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_next_button_on_upload_web_interviews_csv_confirm',
              }}
              onClick={onSubmit}
            >
              更新
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
      <CSVUploadValidationErrorModal
        title="更新エラー"
        errorBoxText="ファイルを修正して再度アップロードするか、項目のひも付けを確認してください。"
        errorLimit={10}
        closeComlinkAction="click_close_button_on_validation_error_modal_of_update_web_interview_csv_confirm"
        active={validationErrorModal.active}
        detailValidationErrors={detailValidationErrors}
        close={validationErrorModal.close}
      />
    </PageLayout>
  )
}

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const BodyWrapper = styled.div`
  padding: 20px;
`

const WebInterviewsCountTxt = styled(Txt).attrs({
  size: 'm',
})`
  margin-bottom: 20px;
`

const WebInterviewsCount = styled.span`
  font-size: ${theme.fontSize.xxl};
  color: ${theme.color.green[4]};
  margin-left: 4px;
`

const TableWrapper = styled.div`
  margin: 20px 28px;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead``

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  &:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

type Property = 'rowID' | 'csvHeader' | 'csvFirstData' | 'target'

const propertyHeaderStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    flex: 0 0 282px;
  `,
}

const propertyBodyStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 0 0 282px;
  `,
}

const Th = styled.th<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  margin-left: 2px;
  background-color: ${theme.color.gray[4]};

  ${({ property }) => propertyHeaderStyles[property]}
`

const Td = styled.td<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  margin-left: 2px;

  ${({ property }) => propertyBodyStyles[property]}
`

const TableHeaderTxt = styled(Txt)({
  size: 's',
})

const TableBodyTxt = styled(Txt)({
  size: 's',
})

const TableBodyLineClampedTxt = styled(LineClampedTxt)({
  size: 's',
})
