import { Assignment } from './assignment'

type AssignmentOption = {
  label: string
  value: Assignment
}

export const assignmentOptions: Array<AssignmentOption> = [
  {
    label: '指定する',
    value: 'specified',
  },
  {
    label: '指定しない',
    value: 'unspecified',
  },
  {
    label: '指定しない（面接詳細の閲覧不可）',
    value: 'unspecified-cannot-view-detail',
  },
]

export function getAssignmentOption(
  value: Assignment
): AssignmentOption | undefined {
  return assignmentOptions.find((option) => option.value === value)
}
