import { useMutation } from 'react-query'
import {
  SendApplicantsCSVMailArgs,
  useRequestSendApplicantsCSVMail,
} from '@/services/bffService'

export function useSendApplicantsCSVMail() {
  const { requestSendApplicantsCSVMail } = useRequestSendApplicantsCSVMail()

  return useMutation(async (args: SendApplicantsCSVMailArgs) => {
    await requestSendApplicantsCSVMail(args)
  })
}
