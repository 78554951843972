import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import {
  Icon,
  NewTabLink,
  theme,
  Txt,
  withComlinkPushParams,
} from '@blue-agency/rogue'
import { Illustration } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { PageHeader } from '@/components/PageHeader'
import { AuthzContainer } from '@/services/authzService'
import { EXTERNAL_PATHS, INTERNAL_PATHS } from '@/services/urlService'

export const DashboardPage: React.VFC = () => {
  const { permittedRpcs } = AuthzContainer.useContainer()
  const isDisplayableControlCenter =
    permittedRpcs?.includes('ListUpcomingWebInterviews') ?? false

  if (permittedRpcs === undefined) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <PageHeader
        title="ダッシュボード"
        left={<Txt>※ダッシュボード機能は、現在開発中です。</Txt>}
      />
      <Wrapper>
        <Inner>
          <HeadTxtWrapper>
            <Txt size="xl" bold>
              インタビューメーカーへようこそ。面接の準備をしましょう！
            </Txt>
          </HeadTxtWrapper>
          <Row>
            <IconWrapper>
              <PcIcon />
            </IconWrapper>
            <div>
              <Txt size="l">まずは推奨環境のご確認をお願いします</Txt>
              <RowBodyWrapper>
                <Ul>
                  <li>
                    <Txt>PCをご利用ください。</Txt>
                  </li>
                  <li>
                    <Txt>
                      ブラウザは「Google Chrome」または「Microsoft
                      Edge」をご利用ください。
                    </Txt>
                  </li>
                  <li>
                    <Txt>
                      <NewTabLink
                        href="https://fast.com/ja/"
                        action="open_fast.com"
                      >
                        fast.com
                      </NewTabLink>
                      で通信速度を計測し、20Mbps以上であることをご確認ください。
                    </Txt>
                  </li>
                </Ul>
                <RecommendedEnvironmentLinkWrapper>
                  <Txt>
                    <NewTabLink
                      href={EXTERNAL_PATHS.help.recommendedEnvironment}
                      action="open_recommended_environment"
                    >
                      推奨環境を詳しく見る
                    </NewTabLink>
                  </Txt>
                </RecommendedEnvironmentLinkWrapper>
              </RowBodyWrapper>
            </div>
          </Row>
          <Row>
            <IconWrapper>
              <InterviewIcon />
            </IconWrapper>
            <div>
              <Txt size="l">面接を実施するには</Txt>
              <RowBodyWrapper>
                <Txt>
                  「担当の選考」から、あなたの面接予定を確認することができます。
                  <br />
                  ※企業様のご利用方法によっては、面接が表示されない場合がございますので、
                  <br />
                  人事・採用担当者にご確認ください。
                  <br />
                  ※面接開始ページは、面接毎にURLが異なります。
                </Txt>
              </RowBodyWrapper>
            </div>
          </Row>
        </Inner>
        {isDisplayableControlCenter && (
          <Inner>
            <ControlCenterWrapper>
              <ControlCenterHeadTextWrapper>
                <Txt size="xl" bold>
                  コントロールセンター
                </Txt>
              </ControlCenterHeadTextWrapper>
              <ControlCenterBodyTextWrapper>
                <Txt size="m">
                  本日実施予定の面接の開始状況や、実施中の面接への参加状況が確認できます。
                </Txt>
              </ControlCenterBodyTextWrapper>
              <ControlCenterImageWrapper>
                <LinkWithComlink
                  to={INTERNAL_PATHS.controlCenter}
                  comlinkPushParams={{
                    action: 'click_control_center_link_image_on_dashboard',
                  }}
                >
                  <Illustration name="control-center" width="240" />
                </LinkWithComlink>
              </ControlCenterImageWrapper>
              <ControlCenterLink
                to={INTERNAL_PATHS.controlCenter}
                comlinkPushParams={{
                  action: 'click_control_center_link_on_dashboard',
                }}
              >
                <Txt color={theme.color.blue[2]}>コントロールセンターへ</Txt>
              </ControlCenterLink>
            </ControlCenterWrapper>
          </Inner>
        )}
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  padding: 33px 0px;
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-content: start;
  align-items: start;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;

  // webkit 系のブラウザにしか有効でないが、あとでまとめて対応する
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari 用
  }
`

const HeadTxtWrapper = styled.div`
  text-align: center;
`

const Inner = styled.div`
  background-color: ${theme.color.white[1]};
  padding: 18px;
  width: 650px;
  height: 358px;
  min-width: 650px;
  position: relative;
`

const Row = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 18px;
  }

  > *:first-child {
    margin-right: 24px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  border-radius: 50%;
  background-color: ${theme.color.navy[1]};
`

const RowBodyWrapper = styled.div`
  margin-top: 10px;
`

const PcIcon = styled(Icon).attrs({
  name: 'pc',
  width: '56px',
  height: '56px',
})`
  color: ${theme.color.white[1]};
`

const Ul = styled.ul`
  margin-left: 18px;
`

const RecommendedEnvironmentLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const InterviewIcon = styled(Icon).attrs({
  name: 'interview',
  width: '54px',
  height: '54px',
})`
  color: ${theme.color.white[1]};
`

const ControlCenterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ControlCenterHeadTextWrapper = styled.div``

const ControlCenterBodyTextWrapper = styled.div`
  margin-top: 28px;
`

const ControlCenterImageWrapper = styled.div`
  width: 100%;
  height: 221px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const LinkWithComlink = withComlinkPushParams(Link)

const ControlCenterLink = styled(LinkWithComlink)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid ${theme.color.gray[4]};
  box-sizing: content-box;
  text-decoration: none;
`
