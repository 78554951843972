import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { StartVideoSeminarMovieUploadRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestStartVideoSeminarMovieUpload() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestStartVideoSeminarMovieUpload = useCallback(
    async (videoSeminarGuid: string, partsNum: number) => {
      const metadata = await buildMetadata()
      const req = new StartVideoSeminarMovieUploadRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)
      req.setPartsNum(partsNum)
      return callBizHuttBffService(
        'startVideoSeminarMovieUpload',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestStartVideoSeminarMovieUpload }
}
