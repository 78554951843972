import { theme, Txt, NewTabLink } from '@blue-agency/rogue'
import { generatePath } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { TableBase } from '@/components/TableBase'
import {
  ReqRecInterviewJobFailureDetail,
  getReqRecInterviewJobErrorName,
} from '@/services/applicantService'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  failure: ReqRecInterviewJobFailureDetail
}

export const ApplicantErrorDetailTable: React.VFC<Props> = ({ failure }) => {
  return (
    <TableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="applicantGUID">応募者ID</Th>
            <Th property="applicantName">氏名</Th>
            <Th property="errorName">エラー詳細</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {failure.details.map((detail, index) => {
            const errorName = getReqRecInterviewJobErrorName(detail.error)
            return (
              <TableRow key={index}>
                <Td property="applicantGUID">
                  <Txt color={theme.color.red[2]}>
                    {detail.target !== 'deleted' && detail.target.applicangGuid}
                    {detail.target === 'deleted' && '削除済'}
                  </Txt>
                </Td>
                <Td property="applicantName">
                  {detail.target !== 'deleted' && (
                    <NewTabLink
                      href={generatePath(INTERNAL_PATHS.applicant, {
                        applicantGuid: detail.target.applicangGuid,
                      })}
                      action="click_req_rec_interview_job_failure_detail_to_applicant"
                    >
                      <NameEllipsisTxt>
                        {`${detail.target.familyName} ${detail.target.givenName}`}
                      </NameEllipsisTxt>
                    </NewTabLink>
                  )}
                </Td>
                <Td property="errorName">
                  <Txt color={theme.color.red[2]}>{errorName}</Txt>
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 392px;
`

type Property = 'applicantGUID' | 'applicantName' | 'errorName'
const width: Record<Property, number> = {
  applicantGUID: 260,
  applicantName: 180,
  errorName: 464,
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
  /* TableBaseのスタイルを打ち消し */
  margin-bottom: 0px;
  tbody tr {
    &:hover {
      box-shadow: none;
    }
  }
  th {
    height: 32px;
    background-color: ${theme.color.gray[4]};
  }
  td {
    min-height: 46px;
    height: 46px;
    border-bottom: 0px;
  }
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  applicantGUID: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.applicantGUID}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  applicantName: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.applicantName}px;
    justify-content: flex-start;
  `,
  errorName: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.errorName}px;
    justify-content: flex-start;
  `,
}

const NameEllipsisTxt = styled(Txt)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  color: ${theme.color.blue[2]};
`

const TableBody = styled.tbody`
  > tr:nth-child(even) td {
    background-color: ${theme.color.gray[5]};
  }
`

const TableRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
