import { useCallback, useState, useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { Form } from '@/services/webInterviewGuideService'
import { calcPartOffsets } from '@/services/webInterviewGuideService'
import { useGetWebInterviewGuide } from './useGetWebInterviewGuide'
import { useUpdateWebInterviewGuide } from './useUpdateWebInterviewGuide'

type Step = 'input' | 'confirm'

type Response = {
  step: Step
  backToInput: () => void
  completedForm: Form | undefined
  completeInput: (form: Form) => void
  register: () => Promise<void>
  isRegisterLoading: boolean
  isGuideFetching: boolean
  originalGuide: Form | undefined
  guid: string
}

function useEditWebInterviewGuidePage(): Response {
  const { mutateAsync: updateWebInterviewGuide, isLoading: isRegisterLoading } =
    useUpdateWebInterviewGuide()

  const { webInterviewGuideGuid } = useParams<{
    webInterviewGuideGuid: string
  }>()
  const { data: getGuideResponse, isLoading: isGuideFetching } =
    useGetWebInterviewGuide(webInterviewGuideGuid)

  const originalGuide = useMemo(() => {
    const g = getGuideResponse?.getWebInterviewGuide()
    if (g === undefined) {
      return undefined
    }
    const partsWithOffset = calcPartOffsets(
      g.toObject().partsList,
      g.getFirstPartStartSeconds()
    )

    return {
      name: g.getName(),
      parts: partsWithOffset.map((part) => {
        return {
          // 基本的に60で割り切れるはずだが、念の為切り捨てにしている
          startOffsetMin: Math.floor(part.startSeconds / 60),
          title: part.title,
          description: part.description,
        }
      }),
    }
  }, [getGuideResponse])

  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<Form>()
  const history = useHistory()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: Form) => {
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      throw new Error('completedForm is undefined')
    }

    try {
      await updateWebInterviewGuide({
        ...completedForm,
        guid: webInterviewGuideGuid,
      })
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }

    toast('面接ガイドを保存しました')
    history.push(INTERNAL_PATHS.webInterviewGuides)
  }, [completedForm, updateWebInterviewGuide, history, webInterviewGuideGuid])

  return {
    step,
    backToInput,
    completedForm,
    completeInput,
    register,
    isRegisterLoading,
    isGuideFetching,
    originalGuide,
    guid: webInterviewGuideGuid,
  }
}

export const EditWebInterviewGuidePageContainer = createContainer(
  useEditWebInterviewGuidePage
)
