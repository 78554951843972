import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListApplicantEventsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListApplicantEvents() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListApplicantEvents = useCallback(
    async (applicantGuid: string) => {
      const metadata = await buildMetadata()
      const req = new ListApplicantEventsRequest()
      req.setApplicantGuid(applicantGuid)

      return callBizHuttBffService(
        'listApplicantEvents',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListApplicantEvents }
}
