import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
  usePromptBeforeUnload,
} from '@blue-agency/im-shared-front'
import { theme, Icon, LineClampedTxt, Txt as _Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Link as _Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useReorderVideoSeminarContentsPage } from './useReorderVideoSeminarContentsPage'

export const ReorderVideoSeminarContentsPage = () => {
  const { videoSeminarGuid, isLoading, contents, onMoveContent, onSubmit } =
    useReorderVideoSeminarContentsPage()

  usePromptBeforeUnload()
  if (isLoading || !contents) {
    return <Loading />
  }

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>コンテンツの並替え</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="表示順">
            <DragDropContext
              onDragEnd={(result) =>
                onMoveContent(
                  result.source.index,
                  result.destination?.index ?? 0
                )
              }
            >
              <Description>
                視聴ページ内のコンテンツの表示順を変更します。
                <br />
                ※リストの上から順に表示されます。
              </Description>
              <Droppable droppableId="contents">
                {(provided, snapshot) => (
                  <DraggableBlock
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {contents.map((content, index) => (
                      // refs: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md
                      <Draggable
                        index={index}
                        key={content.guid}
                        draggableId={content.guid}
                      >
                        {(provided, snapshot) => (
                          <Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              ...(snapshot.isDragging
                                ? rowStyleInDragging
                                : rowStyleDefault),
                            }}
                            {...provided.dragHandleProps}
                          >
                            <ContentNumber>{index + 1}</ContentNumber>
                            <ContentTitle>{content.title}</ContentTitle>
                            {contents.length > 1 && <ContentSortButtonIcon />}
                          </Row>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </DraggableBlock>
                )}
              </Droppable>
            </DragDropContext>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink
              to={generatePath(INTERNAL_PATHS.videoSeminarContents, {
                videoSeminarGuid,
              })}
            >
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_cancel_reorder_video_seminar_contents_button',
                }}
              >
                キャンセル
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_reorder_video_seminar_contents_button',
              }}
              onClick={onSubmit}
            >
              保存
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const BackButtonLink = styled(_Link)`
  color: inherit;
  text-decoration: none;
`

const rowStyleDefault: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
}

const rowStyleInDragging: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
  opacity: '0.6',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
}

const Row = styled.div`
  height: 57px;
  background-color: ${theme.color.white[1]}cc;
  border-bottom: solid 1px ${theme.color.gray[4]};

  display: grid;
  grid-template-columns: 40px 872px 30px;
  grid-template-rows: 1fr;
  grid-template-areas: 'number content sort-button';
  align-items: center;

  & > * {
    margin: 0 8px;
    :is(:first-child) {
      margin-left: 0;
    }
  }
`
const ContentNumber = styled(_Txt).attrs({ size: 'm' })`
  padding-left: 18px;
  grid-area: number;
`

export const DraggableBlock = styled.div`
  background-color: ${theme.color.white[1]};
`

const Description = styled(LineClampedTxt).attrs({
  size: 'm',
  line: 2,
})`
  border-bottom: solid 1px ${theme.color.gray[4]};
  grid-area: content;
  padding: 16px 20px;
`

const ContentTitle = styled(LineClampedTxt).attrs({
  size: 'm',
  line: 2,
})`
  grid-area: content;
  padding: 0 20px;
`

const ContentSortButtonIcon = styled(Icon).attrs({ name: 'move-all' })`
  grid-area: sort-button;
  :hover {
    cursor: pointer;
  }
`
