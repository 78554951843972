import { theme, Txt } from '@blue-agency/rogue'
import { differenceInMinutes } from 'date-fns'
import styled from 'styled-components'
import { ControlCenterPageContainer } from '@/pages/ControlCenterPage/ControlCenterPageContainer'
import { ControlCenterTooltip } from '@/pages/ControlCenterPage/ControlCenterTooltip'
import type { RequestingInterviewee } from '@/pages/ControlCenterPage/types'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

type Props = {
  interviewGuid: string
  requestingInterviewees: ReadonlyArray<RequestingInterviewee>
}

export const RequestingInterviewees: React.VFC<Props> = ({
  interviewGuid,
  requestingInterviewees,
}) => {
  const { loadTime } = ControlCenterPageContainer.useContainer()

  if (requestingInterviewees.length === 0) {
    return null
  }

  // 秒以下の部分は切り上げる
  // 例: 待ち時間が 61秒〜120秒 のときは2分と表示する
  // requestingIntervieweesはrequestTimeの降順（新しい参加リクエストが先頭にくる）にソートされているので、[0]をとることで最大の待ち時間を計算できる
  const longest = differenceInMinutes(
    loadTime,
    requestingInterviewees[0].requestTime,
    { roundingMethod: 'ceil' }
  )

  return (
    <>
      <RequestingIntervieweesWrapper
        data-tip
        data-for={`${TOOLTIP_ID_MAP.requestingIntervieweesOnControlCenter}-${interviewGuid}`}
      >
        <Txt size="s">
          <RequestingCount>{requestingInterviewees.length}</RequestingCount>名
        </Txt>
        <Txt size="s">
          <LongestWaitingMinutesLabel>
            最長待ち時間：
          </LongestWaitingMinutesLabel>
          <LongestWaitingMinutes>{longest}</LongestWaitingMinutes>
          <LongestWaitingMinutesUnit>分</LongestWaitingMinutesUnit>
        </Txt>
      </RequestingIntervieweesWrapper>
      <ControlCenterTooltip
        tooltipId={`${TOOLTIP_ID_MAP.requestingIntervieweesOnControlCenter}-${interviewGuid}`}
        arrowPosition="topLeft"
      >
        {requestingInterviewees.map((requestingInterviewee) => {
          const key = `${
            requestingInterviewee.displayName
          }-${requestingInterviewee.requestTime.getMilliseconds()}`
          const elapsed = differenceInMinutes(
            loadTime,
            requestingInterviewee.requestTime,
            { roundingMethod: 'ceil' }
          )

          return (
            <RequestingIntervieweeWrapper key={key}>
              <ElapsedMinuteWrapper>
                ・<ElapsedMinute>{elapsed}</ElapsedMinute>
                <ElapsedMinuteUnit>分</ElapsedMinuteUnit>
              </ElapsedMinuteWrapper>
              <Txt>：{requestingInterviewee.displayName}</Txt>
            </RequestingIntervieweeWrapper>
          )
        })}
      </ControlCenterTooltip>
    </>
  )
}

const RequestingIntervieweeWrapper = styled.div`
  display: flex;
`

const ElapsedMinuteWrapper = styled(Txt)`
  width: 43px;
`

const ElapsedMinute = styled.span`
  font-weight: 700;
`

const ElapsedMinuteUnit = styled.span`
  font-size: ${theme.fontSize.s};
`

const RequestingIntervieweesWrapper = styled.div``

const RequestingCount = styled.span`
  // TODO: rogue にフォントサイズ18pxに対応する名前が用意されていないため、暫定的に直接数値で指定している
  font-size: 18px;
`

const LongestWaitingMinutesUnit = styled.span`
  font-size: ${theme.fontSize.xs};
`

const LongestWaitingMinutesLabel = styled.span`
  color: ${theme.color.gray[1]};
`

const LongestWaitingMinutes = styled.span`
  font-size: ${theme.fontSize.m};
`
