import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Applicant, Gender } from '@/services/applicantService'
import {
  getGenderName,
  birthDateFormat,
  registerTimeFormat,
} from '@/services/applicantService'

type Props = PropsWithClassName<{
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
}>
export const Profile: React.VFC<Props> = ({
  applicantGuid,
  applicant,
  registerTime,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Row>
        <RowTitle>氏名</RowTitle>
        <RowTxt>
          {applicant.familyName} {applicant.givenName}
        </RowTxt>
      </Row>
      <Row>
        <RowTitle>氏名(カナ)</RowTitle>
        <RowTxt>
          {applicant.familyNameKana} {applicant.givenNameKana}
        </RowTxt>
      </Row>
      <Row>
        <RowTitle>im応募者{'\n'}ID</RowTitle>
        <RowTxt>{applicantGuid}</RowTxt>
      </Row>
      {applicant.externalApplicantId && (
        <Row>
          <RowTitle>連携先{'\n'}応募者ID</RowTitle>
          <RowTxt>{applicant.externalApplicantId}</RowTxt>
        </Row>
      )}
      {applicant.birthDate && (
        <Row>
          <RowTitle>生年月日</RowTitle>
          <RowTxt>{birthDateFormat(applicant.birthDate)}</RowTxt>
        </Row>
      )}
      {(applicant.gender !== Gender.GENDER_UNKNOWN || applicant.genderNote) && (
        <Row>
          <RowTitle>性別</RowTitle>
          <RowTxt>
            {applicant.gender !== Gender.GENDER_UNKNOWN &&
              applicant.gender !== undefined && (
                <>
                  {getGenderName(applicant.gender)}
                  <br />
                </>
              )}
            {applicant.genderNote && (
              <>
                <Note>備考：</Note>
                {applicant.genderNote}
              </>
            )}
          </RowTxt>
        </Row>
      )}
      {applicant.email && (
        <Row>
          <RowTitle>メール{'\n'}アドレス</RowTitle>
          <RowTxt>{applicant.email}</RowTxt>
        </Row>
      )}
      {applicant.phoneNumber && (
        <Row>
          <RowTitle>電話番号</RowTitle>
          <RowTxt>{applicant.phoneNumber}</RowTxt>
        </Row>
      )}
      {(applicant.postalCode || applicant.address) && (
        <Row>
          <RowTitle>住所</RowTitle>
          <RowTxt>
            {applicant.postalCode} {applicant.address}
          </RowTxt>
        </Row>
      )}
      {applicant.educationalBackgroundsList.map((e, i) => (
        <Row key={i}>
          <RowTitle>{i === 0 && '最終'}学歴</RowTitle>
          <RowTxt>
            {e.schoolName}
            <br />
            {e.faculty}
            <br />
            {e.note && (
              <>
                <Note>備考：</Note>
                {e.note}
              </>
            )}
          </RowTxt>
        </Row>
      ))}
      {applicant.educationalBackgroundNote && (
        <Row>
          <RowTitle>学歴備考</RowTitle>
          <RowTxt>{applicant.educationalBackgroundNote}</RowTxt>
        </Row>
      )}
      {applicant.workHistoriesList.map((e, i) => (
        <Row key={i}>
          <RowTitle>職務経歴</RowTitle>
          <RowTxt>
            {e.companyName}
            <br />
            {e.jobDescription}
            <br />
            {e.note && (
              <>
                <Note>備考：</Note>
                {e.note}
              </>
            )}
          </RowTxt>
        </Row>
      ))}
      {applicant.workHistoryNote && (
        <Row>
          <RowTitle>職歴備考</RowTitle>
          <RowTxt>{applicant.workHistoryNote}</RowTxt>
        </Row>
      )}
      {applicant.note && (
        <Row>
          <RowTitle>備考</RowTitle>
          <RowTxt>{applicant.note}</RowTxt>
        </Row>
      )}
      <Row>
        <RowTitle>登録日時</RowTitle>
        <RowTxt>{registerTimeFormat(registerTime)}</RowTxt>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  padding-bottom: 20px;
`

const Row = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0 16px;
  display: flex;
`

const RowTitle = styled(Txt).attrs({ bold: true })`
  width: 80px;
  min-width: 80px;
  white-space: pre-wrap;
`

const RowTxt = styled(Txt)`
  overflow-wrap: break-word;
`

const Note = styled.span`
  color: ${theme.color.gray[1]};
`
