import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetPermittedRpcsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetPermittedRpcs() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetPermittedRpcs = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new GetPermittedRpcsRequest()

    return callBizHuttBffService(
      'getPermittedRpcs',
      [req, metadata],
      loggedInStaff?.guid
    )
  }, [buildMetadata, loggedInStaff?.guid])

  return {
    requestGetPermittedRpcs,
  }
}
