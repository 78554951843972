import { createContainer } from '@blue-agency/front-state-management'
import { useParams } from 'react-router'
import { useGetQuestionTemplate } from '@/services/questionTemplateService'

const useQuestionTemplatePage = () => {
  const { questionTemplateGuid } = useParams<{ questionTemplateGuid: string }>()

  const { data, isLoading } = useGetQuestionTemplate(questionTemplateGuid)

  return {
    isLoading,
    data,
    questionTemplateGuid,
  }
}

export const QuestionTemplatePageContainer = createContainer(
  useQuestionTemplatePage
)
