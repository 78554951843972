import React, { useRef, useEffect, useCallback } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme, Txt, CheckBox, Sort, Icon } from '@blue-agency/rogue'
import {
  Pagination,
  SecondaryDropdown,
  SecondaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { useHistory } from 'react-router'
import { generatePath, Link } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Box } from '@/components/Box'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { TableBase } from '@/components/TableBase'
import { useModal } from '@/hooks/useModal'
import {
  getSelectionStatusColor,
  getSelectionStatusText,
  getSelectionSubStatusText,
} from '@/services/applicantService'
import { useApplicantsQueryParam } from '@/services/applicantService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { BatchActionOnSearchConditionModal } from './Action/BatchActionOnSearchConditionModal'
import { BatchDeleteApplicantsModal } from './Action/BatchDeleteApplicantsModal'
import { BatchReqRecInterviewAnswerModalOnGuids } from './Action/BatchReqRecInterviewAnswerModal'
import { BatchUpdateRecInterviewDeadlineModalOnGuids } from './Action/BatchUpdateRecInterviewDeadlineModal'
import { BatchUpdateSelectionStatusToFinishedModal } from './Action/BatchUpdateSelectionStatusToFinishedModal'
import { BatchUpdateSelectionStatusToNotPassedModal } from './Action/BatchUpdateSelectionStatusToNotPassedModal'
import { CheckNavigator } from './CheckNavigator'
import { DownloadApplicantsCSVModal } from './DownloadApplicantsCSVModal'
import { SearchApplicantsModal } from './SearchApplicantsModal'
import { Sidebar } from './Sidebar'
import { useApplicantsPage, OrderBy } from './useApplicantsPage'
import { batchActionOptions, useBatchAction } from './useBatchAction'

export const ApplicantsPage: React.VFC = () => {
  const pageCtx = useApplicantsPage()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const history = useHistory()

  const { scrollTopParam, setScrollTopParam } = useApplicantsQueryParam()

  const { batchAction, onChangeBatchAction } = useBatchAction()

  const updateSelectionStatusToFinishedModal = useModal()
  const updateSelectionStatusToNotPassedModal = useModal()
  const deleteApplicantsModal = useModal()
  const reqSelectionRecInterviewAnswerModal = useModal()
  const updateSelectionRecInterviewDeadline = useModal()
  const batchActionOnConditionModal = useModal()
  const openBatchModal = useCallback(() => {
    if (pageCtx.actionOnCondition) {
      return batchActionOnConditionModal.open()
    }
    switch (batchAction) {
      case 'delete':
        deleteApplicantsModal.open()
        break
      case 'updateRecInterviewDeadline':
        updateSelectionRecInterviewDeadline.open()
        break
      case 'finished':
        updateSelectionStatusToFinishedModal.open()
        break
      case 'notPassed':
        updateSelectionStatusToNotPassedModal.open()
        break
      case 'reqRecInterviewAnswer':
        reqSelectionRecInterviewAnswerModal.open()
        break
      default:
        throw new Error('No corresponding batch action')
    }
  }, [
    pageCtx.actionOnCondition,
    batchAction,
    batchActionOnConditionModal,
    deleteApplicantsModal,
    updateSelectionRecInterviewDeadline,
    updateSelectionStatusToFinishedModal,
    updateSelectionStatusToNotPassedModal,
    reqSelectionRecInterviewAnswerModal,
  ])

  const searchApplicantsModal = useModal()
  const downloadApplicantsCSVModal = useModal()

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0
    }
  }, [pageCtx.currentPage])

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = scrollTopParam ?? 0
    }
  }, [scrollTopParam])

  if (pageCtx.isLoading) {
    return <Loading />
  }

  const emptyListMessage = pageCtx.totalLength === 0 && (
    <EmptyListMessage>
      <Txt size="l">該当する情報はありません</Txt>
    </EmptyListMessage>
  )

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="応募者一覧"
        right={
          <HeaderButtonGroups>
            <NewApplicantsLink to={INTERNAL_PATHS.batchNewApplicants}>
              <SecondaryButton
                widthSize="L1"
                comlinkPushParams={{ action: 'click_batch_register_applicant' }}
              >
                一括登録
              </SecondaryButton>
            </NewApplicantsLink>
            <NewApplicantsLink to={INTERNAL_PATHS.newApplicant}>
              <SecondaryButton
                widthSize="L1"
                comlinkPushParams={{ action: 'click_register_applicant' }}
              >
                個別登録
              </SecondaryButton>
            </NewApplicantsLink>
          </HeaderButtonGroups>
        }
      />
      <Wrapper>
        <SidebarWrapper>
          <Sidebar
            totalLength={pageCtx.totalLength}
            keywords={pageCtx.condition.keywords}
            onChange={pageCtx.onChangeKeywordForSidebar}
            onClickOpenSearchApplicantsModal={searchApplicantsModal.open}
            onResetSearchApplicantsCondition={pageCtx.resetSearchCondition}
            onClickOpenApplicantsCSVModal={downloadApplicantsCSVModal.open}
          />
        </SidebarWrapper>
        <MainWrapper>
          <Controls>
            <div>
              <CheckNavigator
                actionOnCondition={pageCtx.actionOnCondition}
                searchedApplicantsCount={pageCtx.totalLength ?? 0}
                selectedApplicantsCount={pageCtx.selectedApplicantsCount}
                allApplicantsSelected={pageCtx.allApplicantsSelected}
                onClearActionOnCondition={pageCtx.onClearActionOnCondition}
                onClearSelectApplicant={pageCtx.onClearSelectApplicant}
                onActionOnCondition={pageCtx.onActionOnCondition}
              />
            </div>
            <RightControl>
              <BackgroundJobLink href={INTERNAL_PATHS.applicantsBackgroundJobs}>
                <ProcessIcon />
                <ProcessLable>処理状況</ProcessLable>
              </BackgroundJobLink>
              <SecondaryDropdown
                placeholder="一括アクションを選択"
                options={batchActionOptions}
                onChange={onChangeBatchAction}
              />
              <BatchActionButton
                onClick={openBatchModal}
                disabled={!batchAction}
                comlinkPushParams={{
                  action: 'open_batch_action_modal_on_applicants',
                }}
              >
                <Txt>実行</Txt>
              </BatchActionButton>
              <VerticalSeparator />
              <Pagination
                page={pageCtx.currentPage}
                count={pageCtx.pageCount}
                onRequestChange={pageCtx.onRequestChangePage}
              />
            </RightControl>
          </Controls>
          <TableWrapper ref={wrapperRef}>
            <Table>
              <TableHead>
                <TableRow>
                  <Th property="select">
                    <CheckBox
                      checked={pageCtx.allApplicantsSelected}
                      onChange={pageCtx.onSelectAllApplicants}
                      size={16}
                    />
                  </Th>
                  <Th property="name">氏名</Th>
                  <Th property="status">ステータス</Th>
                  <Th property="currentStep">最新の選考</Th>
                  <Th property="padding" />
                  <Th property="registerTime">
                    <span>登録日時</span>
                    <Sort
                      weakColor={theme.color.navy[2]}
                      order={
                        pageCtx.orderBy === OrderBy.ORDER_BY_REGISTER_TIME_DESC
                          ? 'desc'
                          : 'asc'
                      }
                      onClick={pageCtx.toggleOrderBy}
                    />
                  </Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageCtx.applicants.map((applicant) => {
                  const applicantPagePath = generatePath(
                    INTERNAL_PATHS.applicant,
                    { applicantGuid: applicant.guid }
                  )
                  const subStatusTxt = getSelectionSubStatusText(
                    applicant.selectionSubStatus
                  )
                  const onClick = (
                    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    const st = wrapperRef.current?.scrollTop ?? 0
                    setScrollTopParam(st)

                    // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
                    if (
                      (e.ctrlKey && !e.metaKey) ||
                      (!e.ctrlKey && e.metaKey)
                    ) {
                      window.open(applicantPagePath)
                      return
                    }
                    history.push({
                      pathname: applicantPagePath,
                    })
                  }
                  return (
                    <TableRow key={applicant.guid}>
                      <Td property="select">
                        <CheckBox
                          checked={applicant.selected}
                          onChange={() => {
                            pageCtx.onSelectApplicant(applicant.guid)
                          }}
                          size={16}
                        />
                      </Td>
                      <Td property="name">
                        <TableCellLink onClick={onClick}>
                          <EllipsisTxt size="l">
                            {`${applicant.familyName} ${applicant.givenName}`}
                          </EllipsisTxt>
                        </TableCellLink>
                      </Td>
                      <Td property="status">
                        <TableCellLink onClick={onClick}>
                          <Box display="flex" alignItems="center">
                            <Dot
                              color={getSelectionStatusColor(
                                applicant.selectionStatus
                              )}
                            />
                            <Box ml="4px">
                              <Txt>
                                {getSelectionStatusText(
                                  applicant.selectionStatus
                                )}
                              </Txt>
                            </Box>
                          </Box>
                          {subStatusTxt && (
                            <Box mt="4px">
                              <Txt size="s" color={theme.color.gray[1]}>
                                {subStatusTxt}
                              </Txt>
                            </Box>
                          )}
                        </TableCellLink>
                      </Td>
                      <Td property="currentStep">
                        <TableCellLink onClick={onClick}>
                          <EllipsisTxt>
                            {`${applicant.currentSelectionStepName}`}
                          </EllipsisTxt>
                        </TableCellLink>
                      </Td>
                      <Td property="padding">
                        <TableCellLink onClick={onClick} />
                      </Td>
                      <Td property="registerTime">
                        <TableCellLink onClick={onClick}>
                          <RegisterTime time={applicant.registerTime} />
                        </TableCellLink>
                      </Td>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {emptyListMessage}
          </TableWrapper>
        </MainWrapper>
      </Wrapper>
      {searchApplicantsModal.active && (
        <SearchApplicantsModal
          condition={pageCtx.condition}
          initialTotalLength={pageCtx.totalLength ?? 0}
          onClose={searchApplicantsModal.close}
          onSearch={pageCtx.onChangeSearchCondition}
        />
      )}
      {reqSelectionRecInterviewAnswerModal.active && (
        <BatchReqRecInterviewAnswerModalOnGuids
          close={reqSelectionRecInterviewAnswerModal.close}
          applicants={pageCtx.applicants}
        />
      )}
      {deleteApplicantsModal.active && (
        <BatchDeleteApplicantsModal
          close={deleteApplicantsModal.close}
          applicants={pageCtx.applicants}
        />
      )}
      {updateSelectionRecInterviewDeadline.active && (
        <BatchUpdateRecInterviewDeadlineModalOnGuids
          close={updateSelectionRecInterviewDeadline.close}
          applicants={pageCtx.applicants}
        />
      )}
      {updateSelectionStatusToFinishedModal.active && (
        <BatchUpdateSelectionStatusToFinishedModal
          close={updateSelectionStatusToFinishedModal.close}
          applicants={pageCtx.applicants}
        />
      )}
      {updateSelectionStatusToNotPassedModal.active && (
        <BatchUpdateSelectionStatusToNotPassedModal
          close={updateSelectionStatusToNotPassedModal.close}
          applicants={pageCtx.applicants}
        />
      )}
      {batchActionOnConditionModal.active && (
        <BatchActionOnSearchConditionModal
          action={batchAction}
          targetCount={pageCtx.totalLength ?? 0}
          condition={pageCtx.condition}
          close={batchActionOnConditionModal.close}
        />
      )}
      {downloadApplicantsCSVModal.active && (
        <DownloadApplicantsCSVModal
          condition={pageCtx.condition}
          onClose={downloadApplicantsCSVModal.close}
        />
      )}
    </PageLayoutWithGlonavi>
  )
}

const RegisterTime: React.VFC<{ time?: Date }> = ({ time }) => {
  if (time === undefined) return null

  const first = format(time, 'yyyy/MM/dd')
  const last = format(time, 'H:mm')

  return (
    <Box>
      <Txt>{first}</Txt>
      <Txt color={theme.color.navy[1]}>{last}</Txt>
    </Box>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  overflow: hidden;
  flex: 1;
`

const HeaderButtonGroups = styled.div`
  display: flex;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: 7px;
  }
`

const NewApplicantsLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const MainWrapper = styled.div`
  grid-area: main;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BackgroundJobLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 19px;
  color: ${theme.color.navy[1]};
`

const ProcessIcon = styled(Icon).attrs({ name: 'process' })`
  width: 18px;
  height: 18px;
`

const ProcessLable = styled.span`
  font-size: ${theme.fontSize.s};
  margin-left: 7px;
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px 16px 20px 0;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 20px;
`

const RightControl = styled.div`
  display: flex;
  align-items: center;
`

const BatchActionButton = styled(TertiaryButton).attrs({ widthSize: 'S' })`
  padding: 0px;
  margin-left: 6px;
  border: none;
  background-color: ${theme.color.gray[4]};
`

const VerticalSeparator = styled.div`
  border-left: 1px solid ${theme.color.navy[2]};
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 24px;
  margin: 0 15px 0 18px;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

type Property =
  | 'select'
  | 'name'
  | 'status'
  | 'currentStep'
  | 'registerTime'
  | 'padding'

const width: Record<Property, number> = {
  select: 52,
  name: 160,
  status: 180,
  currentStep: 140,
  registerTime: 120,
  padding: 0,
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  select: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.select}px;
  `,
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  status: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.status}px;
    justify-content: flex-start;
  `,
  currentStep: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.currentStep}px;
    justify-content: flex-start;
  `,
  registerTime: css`
    flex: 0 0 ${width.registerTime}px;
    justify-content: flex-start;
    > *:first-child {
      margin-right: 7px;
    }
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const TableCellLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  margin: 0 -15px;
  padding: 0 15px;
  color: inherit;
  cursor: pointer;
`

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const EllipsisTxt = styled(Txt)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
