import { RecInterviewQuestionFormat } from '@blue-agency/proton/im'
import { useQuery } from 'react-query'
import { useListAllRecInterviewQuestionTemplates } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { QuestionTemplate } from './types'

const mapFormat = (
  f: RecInterviewQuestionFormat
): QuestionTemplate['format'] => {
  switch (f) {
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_TEXT:
      return 'text'
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_VIDEO:
      return 'video'
    case RecInterviewQuestionFormat.REC_INTERVIEW_QUESTION_FORMAT_UNKNOWN:
      throw new Error('Got unknown rec interview question format')
  }
}

export function useListQuestionTemplates() {
  const { requestListAllRecInterviewQuestionTemplates } =
    useListAllRecInterviewQuestionTemplates()

  return useQuery([QUERY_KEY.questionTemplates], async () => {
    const qtList = await requestListAllRecInterviewQuestionTemplates()

    const questionTemplates = qtList.getQuestionTemplatesList().map((qt) => {
      return {
        guid: qt.getGuid(),
        name: qt.getName(),
        content: qt.getContent(),
        format: mapFormat(qt.getFormat()),
      }
    })

    return {
      questionTemplates,
    }
  })
}
