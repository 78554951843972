import React, { useState, useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { Txt, NewTabLink } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { InputTextHover } from '@/components/InputTextHover'
import { InputVideoHover } from '@/components/InputVideoHover'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import { useModal } from '@/hooks/useModal'
import { MessageModal } from './Modal'
import { PreviewLayout } from './PreviewLayout'
import { StepParam } from './types'
import { VideoModal } from './video/VideoModal'

export const InputIntro: React.VFC<StepParam> = ({ form }) => {
  const [introMessage, setIntroMessage] = useState(
    form.getValues('introMessage')
  )
  const [videoURL, setVideoURL] = useState<string>(
    form.getValues('introVideoURL')
  )

  const videoModal = useModal()
  const msgModal = useModal()

  const loggedInStaff = useLoggedInStaff()

  const organizationName = loggedInStaff?.organizationName ?? ''

  const onMsgCancel = useCallback(() => {
    msgModal.close()
    form.setValue('introMessage', introMessage)
  }, [introMessage, form, msgModal])

  const onMsgSave = useCallback(async () => {
    const valid = await form.trigger('introMessage')
    if (!valid) return
    setIntroMessage(form.getValues('introMessage'))
    msgModal.close()
  }, [form, msgModal])

  const onVideoCancel = useCallback(() => {
    videoModal.close()
  }, [videoModal])

  const onVideoSave = useCallback(
    (fileKey: string, blob: Blob) => {
      form.setValue('introVideoFileKey', fileKey)
      const url = URL.createObjectURL(blob)
      form.setValue('introVideoURL', url)
      setVideoURL(url)
      videoModal.close()
    },
    [form, videoModal]
  )

  const onVideoDelete = useCallback(() => {
    form.setValue('introVideoFileKey', '')
    if (videoURL) URL.revokeObjectURL(videoURL)
    form.setValue('introVideoURL', '')
    setVideoURL('')
  }, [form, videoURL])

  const placeholder = `例）${organizationName}にご興味を持っていただき、ありがとうございます。
  不慣れな方もいらっしゃるとは思いますが、無理にテンションを上げて回答したり、言葉に詰まって何回も撮り直ししたりする必要はありません。
  「普段通りの自分でいること」を心がけてご回答ください！`

  return (
    <SettingsSection title="応募者の開始ページ">
      <PreviewWrapper>
        <PreviewWrapperInner>
          <PreviewLayout
            body={
              <div>
                <Box>
                  <Txt textAlign="center" size="l">
                    {organizationName}
                  </Txt>
                  <Txt textAlign="center" size="xl">
                    選考ステップ名
                  </Txt>
                </Box>
                <PreviewHead>{organizationName}からのメッセージ</PreviewHead>
                <InputVideoHover
                  hoverInner={<C.EditButton onClick={videoModal.open} />}
                  placeholder="オープニング動画を選択してください"
                  videoURL={videoURL}
                  onDelete={onVideoDelete}
                />
                <VideoModalWrapper>
                  <VideoModal
                    active={videoModal.active}
                    onCancel={onVideoCancel}
                    onSave={onVideoSave}
                    type="intro"
                  />
                </VideoModalWrapper>
                <InputTextHoverWrapper>
                  <InputTextHover
                    hoverInner={<C.EditButton onClick={msgModal.open} />}
                    placeholder={placeholder}
                    value={introMessage}
                    onBlurError={form.errors.introMessage?.message}
                  />
                </InputTextHoverWrapper>
                <MessageModal
                  active={msgModal.active}
                  form={form}
                  onCancel={onMsgCancel}
                  onSave={onMsgSave}
                  placeholder={placeholder}
                  onBlurError={form.errors.introMessage?.message}
                  type="intro"
                />
                <Box m="20px 0 10px">
                  <Txt size="l" bold>
                    インタビューメーカーについて
                  </Txt>
                </Box>
                <Txt>
                  「インタビューメーカー」は、株式会社スタジアムが提供する録画面接サービスです。ご利用にあたっては、インタビューメーカーの利用規約及びプライバシーポリシーへの同意が必要です。
                </Txt>
              </div>
            }
            bottom={<PreviewBottom />}
            organizationName={organizationName}
          />
        </PreviewWrapperInner>
      </PreviewWrapper>
    </SettingsSection>
  )
}

const PreviewBottom = () => {
  return (
    <PreviewBottomWrapper>
      <Txt textAlign="center">
        インタビューメーカーの
        <br />
        <DisabledNewTabLink href="" action="open_terms_and_pp">
          利用規約及びプライバシーポリシー
        </DisabledNewTabLink>
        に
      </Txt>
      <PreviewButton
        comlinkPushParams={{ action: 'click_next_button_on_preview' }}
      >
        同意して次に進む
      </PreviewButton>
    </PreviewBottomWrapper>
  )
}

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const PreviewWrapperInner = styled.div`
  width: 360px;
  height: 700px;
  padding: 40px 0;
`

const PreviewBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PreviewHead = styled(Txt).attrs({ size: 'l', bold: true })`
  margin-top: 30px;
  margin-bottom: 12px;
`

const PreviewButton = styled(SecondaryButton).attrs({
  widthSize: 'MAX',
  heightSize: 'L',
})`
  /* 機能しないボタンなので pointer にしない */
  cursor: default;
  width: 328px;
  margin-top: 12px;
`

const VideoModalWrapper = styled.div`
  width: 100%;
`

const InputTextHoverWrapper = styled.div`
  margin-top: 10px;
`

const DisabledNewTabLink = styled(NewTabLink)`
  pointer-events: none;
`
