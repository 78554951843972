import { useCallback, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { Name, NameKana } from '@blue-agency/im-shared-front'
import { GetWebInterviewResponse } from '@blue-agency/proton/biz_hutt_bff'
import {
  WebInterviewAssignment,
  WebInterviewAssigneeRole,
} from '@blue-agency/proton/im'
import { toast } from '@blue-agency/rogue'
import assert from 'assert'
import { useParams } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'
import { WithLoading } from '@/@types/withLoading'
import { timestampToDate } from '@/services/bffService'
import { writeTextToClipboard } from '@/services/clipboardService'
import type {
  Assignee,
  Assignment,
  TimelineEvent,
} from '@/services/webInterviewService'
import { useGetWebInterview } from './useGetWebInterview'
import { useWebInterviewNameEdit } from './useWebInterviewNameEdit'

export type Staff = {
  guid: string
  name: Name
  nameKana: NameKana
  email: string
  iconColorCode: string
  suspended?: boolean
}

export type WebInterview = Omit<
  GetWebInterviewResponse.WebInterview.AsObject,
  | 'assignment'
  | 'registerTime'
  | 'startTime'
  | 'finishTime'
  | 'expireTime'
  | 'guide'
  | 'duration'
  | 'scheduledStartTime'
  | 'timelineList'
> & {
  assignment: Assignment
  assignees?: (Assignee & Staff)[]
  registerTime: Date
  startTime: Date | undefined
  finishTime: Date | undefined
  expireTime: Date | undefined
  guide: Guide | undefined
  durationMinutes: number | undefined
  scheduledStartTime: Date | undefined
  timelineList: TimelineEvent[]
}

type Part = {
  title: string
  description: string
  startSeconds: number
}

type Guide = {
  guid: string
  name: string
  parts: Part[]
}

type Response = WithLoading<
  {
    webInterview: WebInterview
    copyInterviewerUrl: () => void
    copyIntervieweeUrl: () => void
    webInterviewPanelRef: (instance: HTMLDivElement | null) => void
    webInterviewPanelWidth: number | undefined
    hasRecording: boolean
    hasMinutes: boolean
  } & ReturnType<typeof useWebInterviewNameEdit>
>

function useWebInterviewPage(): Response {
  const {
    webInterviewNameEditing,
    startEditingWebInterviewName,
    finishEditingWebInterviewName,
    webInterviewNameRef,
  } = useWebInterviewNameEdit()
  const { webInterviewGuid } = useParams<{ webInterviewGuid: string }>()
  const { data: webInterviewData, isLoading: loadingWebInterview } =
    useGetWebInterview(webInterviewGuid)

  const webInterview = useMemo<WebInterview | undefined>(() => {
    const interview = webInterviewData?.getWebInterview()?.toObject()
    if (!interview) return undefined

    const registerTime = timestampToDate(interview.registerTime)
    if (!registerTime) throw new Error('registerTime is undefined')

    const startTime = timestampToDate(interview.startTime)
    const finishTime = timestampToDate(interview.finishTime)
    const expireTime = timestampToDate(interview.expireTime)

    const decodeWebInterviewAssignment = (
      assignment: WebInterviewAssignment
    ) => {
      switch (assignment) {
        case WebInterviewAssignment.SPECIFIED:
          return 'specified'
        case WebInterviewAssignment.UNSPECIFIED:
          return 'unspecified'
        case WebInterviewAssignment.UNSPECIFIED_CANNOT_VIEW_DETAIL:
          return 'unspecified-cannot-view-detail'
        case WebInterviewAssignment.WEB_INTERVIEW_ASSIGNMENT_UNKNOWN:
          throw Error('Unexpected assignement received')
      }
    }

    const decodeAssigneeRole = (role: WebInterviewAssigneeRole) => {
      switch (role) {
        case WebInterviewAssigneeRole.INTERVIEWER:
          return 'interviewer'
        case WebInterviewAssigneeRole.VIEWER:
          return 'viewer'
        case WebInterviewAssigneeRole.WEB_INTERVIEW_ASSIGNEE_ROLE_UNKNOWN:
          throw Error('Unexpected assigneeRole received')
      }
    }

    const decodeAssignee = (
      assignee: GetWebInterviewResponse.Assignee.AsObject
    ) => {
      assert(assignee.name)
      assert(assignee.nameKana)

      return {
        ...assignee,
        guid: assignee.staffGuid,
        name: new Name(assignee.name.familyName, assignee.name.givenName),
        nameKana: new NameKana(
          assignee.nameKana.familyNameKana,
          assignee.nameKana.givenNameKana
        ),
        role: decodeAssigneeRole(assignee.role),
      } as const
    }

    const assignees: (Assignee & Staff)[] =
      interview.assigneesList.map(decodeAssignee)

    let durationMinutes: number | undefined = undefined
    if (interview.duration?.specified?.specified) {
      durationMinutes = interview.duration.specified.durationSeconds / 60
    }

    const scheduledStartTime = webInterviewData
      ?.getWebInterview()
      ?.getScheduledStartTime()
      ?.toDate()

    let guideParts: Part[] = []
    if (!!interview.guide) {
      let currentOffsetSeconds: number =
        interview.guide?.firstPartStartSeconds ?? 0
      for (const part of interview.guide?.partsList) {
        guideParts.push({
          title: part.title,
          description: part.description,
          startSeconds: currentOffsetSeconds,
        })
        currentOffsetSeconds += part.durationSeconds
      }
    }

    const timelineList: TimelineEvent[] = []
    for (const tl of interview.timelineList) {
      const interviewerJoin = tl.interviewerJoin
      if (interviewerJoin !== undefined) {
        // name, nameKana, joinTime は型の上ではoptionalになっているが、実際は必ず存在する
        const name = {
          familyName: interviewerJoin.name!.familyName,
          givenName: interviewerJoin.name!.givenName,
        }
        const nameKana = {
          familyNameKana: interviewerJoin.nameKana!.familyNameKana,
          givenNameKana: interviewerJoin.nameKana!.givenNameKana,
        }
        timelineList.push({
          ...interviewerJoin,
          kind: 'interviewerJoin',
          name,
          nameKana,
          joinTime: timestampToDate(interviewerJoin.joinTime)!,
        })
      }

      const intervieweeJoin = tl.intervieweeJoin
      if (intervieweeJoin !== undefined) {
        timelineList.push({
          ...intervieweeJoin,
          kind: 'intervieweeJoin',
          // joinTime は型の上ではoptionalになっているが、実際は必ず存在する
          joinTime: timestampToDate(intervieweeJoin.joinTime)!,
        })
      }

      // 現状BFFから返却されるタイムラインイベントは
      // - 面接官の入室
      // - 応募者の入室
      // の2種類しかないため、ここに到達することはないはずだが、型レベルでこの制約を確認することができない
      // これら2種類以外が来た場合には無効なイベントとして扱い、無視する
    }

    return {
      ...interview,
      assignment: decodeWebInterviewAssignment(interview.assignment),
      assignees,
      registerTime,
      startTime,
      finishTime,
      expireTime,
      durationMinutes,
      scheduledStartTime,
      guide:
        interview.guide !== undefined
          ? {
              guid: interview.guide.guid,
              name: interview.guide.name,
              parts: guideParts,
            }
          : undefined,
      timelineList,
    }
  }, [webInterviewData])

  const copyInterviewerUrl = useCallback(() => {
    const interviewerUrl = webInterview?.interviewerUrl
    if (!interviewerUrl) throw new Error('interviewerUrl is undefined')

    writeTextToClipboard(interviewerUrl)
    toast('面接官用リンクをコピーしました')
  }, [webInterview])

  const copyIntervieweeUrl = useCallback(() => {
    const intervieweeUrl = webInterview?.intervieweeUrl
    if (!intervieweeUrl) throw new Error('intervieweeUrl is undefined')

    writeTextToClipboard(intervieweeUrl)
    toast('応募者用リンクをコピーしました')
  }, [webInterview])

  const [webInterviewPanelWidth, setWebInterviewPanelWidth] = useState(0)

  // webInterviewPanel のレスポンシブ処理
  const { ref: webInterviewPanelRef } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      if (width === undefined) return
      setWebInterviewPanelWidth((prev) =>
        Math.abs(prev - width) < 20 ? prev : width
      )
    },
  })

  // 閲覧できる録画データが存在している場合に true
  const hasRecording = useMemo(() => {
    if (webInterview === undefined) {
      return false
    }
    return (
      webInterview.recordingStatus ===
        GetWebInterviewResponse.RecordingStatus.ENCODED &&
      webInterview.recordingUrl.length > 0
    )
  }, [webInterview])

  // 閲覧できる議事録URLが存在するかつ、録画データが存在している場合に true
  // BFFからのrecodingUrlは閲覧権限が存在する場合のみ返してる値なので、議事録URLも同じ条件に載せるため参照してる
  const hasMinutes = useMemo(() => {
    if (webInterview === undefined) {
      return false
    }
    return (
      webInterview.minutesUrl.length > 0 && webInterview.recordingUrl.length > 0
    )
  }, [webInterview])

  if (loadingWebInterview) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    webInterview: webInterview!,
    copyInterviewerUrl,
    copyIntervieweeUrl,
    webInterviewPanelRef,
    webInterviewPanelWidth,
    webInterviewNameEditing,
    startEditingWebInterviewName,
    finishEditingWebInterviewName,
    webInterviewNameRef,
    hasRecording,
    hasMinutes,
  }
}

export const WebInterviewPageContainer = createContainer(useWebInterviewPage)
