import { useMutation } from 'react-query'
import { useRequestGetAddressByPostalCode } from '@/services/bffService'

export function useGetAddressByPostalCode() {
  const { requestGetAddressByPostalCode } = useRequestGetAddressByPostalCode()

  return useMutation((postalCode: string) =>
    requestGetAddressByPostalCode(postalCode)
  )
}
