import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateVideoSeminarRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateVideoSeminarRequestValue = {
  title: string
  description: string
}

export function useRequestCreateVideoSeminar() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateVideoSeminar = useCallback(
    async (v: CreateVideoSeminarRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateVideoSeminarRequest()
      req.setTitle(v.title)
      req.setDescription(v.description)

      return callBizHuttBffService(
        'createVideoSeminar',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateVideoSeminar,
  }
}
