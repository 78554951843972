import { useMutation, useQueryClient } from 'react-query'
import {
  CreateWebInterviewsRequestValue,
  useRequestCreateWebInterviews,
} from '@/services/bffService/useRequestCreateWebInterviews'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateWebInterviews() {
  const queryClient = useQueryClient()
  const { requestCreateWebInterviews } = useRequestCreateWebInterviews()

  return useMutation(
    (value: CreateWebInterviewsRequestValue) =>
      requestCreateWebInterviews(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviews)
        queryClient.invalidateQueries(QUERY_KEY.assignedWebInterviews, {
          refetchInactive: true,
        })
        queryClient.invalidateQueries(QUERY_KEY.upcomingWebInterviews)
        queryClient.invalidateQueries(QUERY_KEY.ongoingWebInterviews)
      },
    }
  )
}
