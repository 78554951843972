import React, { useCallback, useEffect, useRef } from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import {
  CheckBox,
  Icon,
  LineClampedTxt,
  Sort,
  theme,
  Txt,
} from '@blue-agency/rogue'
import {
  Pagination,
  SecondaryDropdown,
  Tooltip,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { useHistory } from 'react-router'
import { generatePath, Link } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { DateTimeFormat } from '@/components/DateTimeFormat'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { TableBase } from '@/components/TableBase'
import { WebInterviewStatusComponent } from '@/components/WebInterviewStatusComponent'
import { useModal } from '@/hooks/useModal'
import { CsvUploadButton } from '@/pages/WebInterviewsPage/CsvUploadButton'
import { timestampToDate } from '@/services/bffService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { buttonResetStyle } from '@/styles'
import { AssigneeIcons } from './AssigneeIcons'
import { BatchDeleteWebInterviewsModal } from './BatchDeleteWebInterviewsModal'
import { ChangeWebInterviewGuidesModal } from './ChangeWebInterviewGuidesModal/ChangeWebInterviewGuidesModal'
import { CsvDownloadButton } from './CsvDownloadButton'
import { InterviewersOrAssignment } from './InterviewersOrAssignment'
import { Sidebar } from './Sidebar'
import { WebInterviewsPageContainer } from './WebInterviewsPageContainer'
import { batchActionOptions, useBatchAction } from './useBatchAction'

export const WebInterviewsPage = provide(WebInterviewsPageContainer)(
  (): React.ReactElement => {
    const pageCtx = WebInterviewsPageContainer.useContainer()

    const {
      active: isDeleteWebInterviewsModalActive,
      open: openDeleteWebInterviewsModal,
      close: onCloseDeleteWebInterviewsModal,
    } = useModal()

    const {
      active: isChangeWebInterviewGuidesModalActive,
      open: openChangeWebInterviewGuidesModal,
      close: onCloseChangeWebInterviewGuidesModal,
    } = useModal()

    const { batchAction, onChangeBatchAction } = useBatchAction()

    const openBatchModal = useCallback(() => {
      switch (batchAction) {
        case 'delete':
          openDeleteWebInterviewsModal()
          break
        case 'changeInterviewGuide':
          openChangeWebInterviewGuidesModal()
          break
        default:
          throw new Error('No corresponding batch action')
      }
    }, [
      batchAction,
      openDeleteWebInterviewsModal,
      openChangeWebInterviewGuidesModal,
    ])

    const wrapperRef = useRef<HTMLDivElement>(null)

    const history = useHistory()

    useEffect(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = 0
      }
    }, [pageCtx.currentPage])

    useEffect(() => {
      const st = pageCtx.scrollTopQuery
      if (wrapperRef.current && st) {
        wrapperRef.current.scrollTop = Number(st)
      }
    }, [pageCtx.scrollTopQuery])

    if (pageCtx.isLoading) {
      return <Loading />
    }

    const emptyListMessage = pageCtx.webInterviews.length === 0 && (
      <EmptyListMessage>
        <Txt size="l">該当する情報はありません</Txt>
      </EmptyListMessage>
    )

    return (
      <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
        <ListPageHeader
          title="Web面接一覧"
          right={
            <HeaderButtonGroups>
              <CsvUploadButton />
              <CsvDownloadButton />
              <NewWebInterviewsLink to={INTERNAL_PATHS.newWebInterviews}>
                <PrimaryButton
                  widthSize="L1"
                  comlinkPushParams={{ action: 'click_register_web_interview' }}
                >
                  Web面接登録
                </PrimaryButton>
              </NewWebInterviewsLink>
            </HeaderButtonGroups>
          }
        />
        <Wrapper>
          <SidebarWrapper>
            <Sidebar />
          </SidebarWrapper>
          <MainWrapper>
            <Controls>
              <div>
                {pageCtx.selectedWebInterviewCount > 0 && (
                  <SelectedTextWrapper>
                    <SelectedCount>
                      {pageCtx.selectedWebInterviewCount}
                    </SelectedCount>
                    <Txt>件選択中</Txt>
                    <ClearSelectButton
                      onClick={pageCtx.onClearSelectWebInterview}
                    >
                      選択を解除
                    </ClearSelectButton>
                  </SelectedTextWrapper>
                )}
              </div>
              <RightControl>
                <SecondaryDropdown
                  placeholder="一括アクションを選択"
                  options={batchActionOptions}
                  onChange={onChangeBatchAction}
                />
                <BatchActionButton
                  onClick={openBatchModal}
                  disabled={!batchAction}
                  comlinkPushParams={{
                    action: 'open_modal_batch_delete_interview',
                  }}
                >
                  <Txt size="s">実行</Txt>
                </BatchActionButton>

                <VerticalSeparator />
                <Pagination
                  page={pageCtx.currentPage}
                  count={pageCtx.pageCount}
                  onRequestChange={pageCtx.onRequestChangePage}
                />
              </RightControl>
            </Controls>
            <TableWrapper ref={wrapperRef}>
              <Table>
                <TableHead>
                  <TableRow>
                    <Th property="select">
                      <CheckBox
                        checked={pageCtx.allWebInterviewSelected}
                        onChange={pageCtx.onSelectAllWebInterview}
                        size={16}
                      />
                    </Th>
                    <Th property="status">ステータス</Th>
                    <Th property="name">
                      <span>面接名</span>
                      <Sort
                        weakColor={theme.color.navy[2]}
                        order={
                          pageCtx.sortBy.column === 'name'
                            ? pageCtx.sortBy.desc
                              ? 'desc'
                              : 'asc'
                            : 'none'
                        }
                        onClick={() => pageCtx.toggleSortBy('name')}
                      />
                      <QuestionIconWrapper
                        data-tip
                        data-for={TOOLTIP_ID_MAP.nameOnWebInterviewList}
                      >
                        <QuestionIcon />
                      </QuestionIconWrapper>
                      <Tooltip
                        id={TOOLTIP_ID_MAP.nameOnWebInterviewList}
                        arrowPosition="topLeft"
                      >
                        <Txt>
                          社内管理用の面接名。応募者には表示されません。
                        </Txt>
                      </Tooltip>
                    </Th>
                    <Th property="scheduledStartTimeAndDuration">
                      <VerticalLabels>
                        <VerticalLabel>予定日時</VerticalLabel>
                        <VerticalLabel>目安時間</VerticalLabel>
                      </VerticalLabels>
                      <Sort
                        weakColor={theme.color.navy[2]}
                        order={
                          pageCtx.sortBy.column === 'scheduledStartTime'
                            ? pageCtx.sortBy.desc
                              ? 'desc'
                              : 'asc'
                            : 'none'
                        }
                        onClick={() =>
                          pageCtx.toggleSortBy('scheduledStartTime')
                        }
                      />
                    </Th>
                    <Th property="interviewers">面接官</Th>
                    <Th property="viewers">閲覧者</Th>
                    <Th property="interviewGuide">面接ガイド</Th>
                    <Th property="padding" />
                    <Th property="registerTime">
                      <span>登録日時</span>
                      <Sort
                        weakColor={theme.color.navy[2]}
                        order={
                          pageCtx.sortBy.column === 'registerTime'
                            ? pageCtx.sortBy.desc
                              ? 'desc'
                              : 'asc'
                            : 'none'
                        }
                        onClick={() => pageCtx.toggleSortBy('registerTime')}
                      />
                    </Th>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageCtx.webInterviews.map((webInterview) => {
                    const webInterviewPagePath = generatePath(
                      INTERNAL_PATHS.webInterview,
                      { webInterviewGuid: webInterview.guid }
                    )
                    const interviewers = webInterview.assignees?.filter(
                      (assignee) => assignee.role === 'interviewer'
                    )
                    const viewers = webInterview.assignees?.filter(
                      (assignee) => assignee.role === 'viewer'
                    )
                    const scheduledStartDate = timestampToDate(
                      webInterview.scheduledStartTime
                    )
                    const onClick = (
                      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => {
                      const st = wrapperRef.current?.scrollTop ?? 0
                      pageCtx.setScrollTopQuery(st)
                      pageCtx.saveQueryToLocalStorage(webInterview.guid)
                      // ctrlキーとクリックで別タブ遷移、デフォルトは同タブ遷移
                      if (
                        (e.ctrlKey && !e.metaKey) ||
                        (!e.ctrlKey && e.metaKey)
                      ) {
                        window.open(webInterviewPagePath)
                        return
                      }
                      history.push({
                        pathname: webInterviewPagePath,
                      })
                    }
                    return (
                      <TableRow key={webInterview.guid}>
                        <Td property="select">
                          <CheckBox
                            checked={webInterview.selected}
                            onChange={() =>
                              pageCtx.onSelectWebInterview(webInterview.guid)
                            }
                            size={16}
                          />
                        </Td>
                        <Td property="status">
                          <TableCellLink onClick={onClick}>
                            <WebInterviewStatusComponent
                              status={webInterview.status}
                            />
                          </TableCellLink>
                        </Td>
                        <Td property="name">
                          <TableCellLink onClick={onClick}>
                            <Txt>{webInterview.name}</Txt>
                          </TableCellLink>
                        </Td>
                        <Td property="scheduledStartTimeAndDuration">
                          <TableCellLink onClick={onClick}>
                            <VerticalLabels>
                              {!!scheduledStartDate && (
                                <VerticalLabel>
                                  {format(scheduledStartDate, 'yy/MM/dd HH:mm')}
                                </VerticalLabel>
                              )}
                              {webInterview.duration &&
                                webInterview.duration.specified && (
                                  <VerticalLabel>
                                    {Math.floor(
                                      webInterview.duration.specified
                                        .durationSeconds / 60
                                    )}
                                    分
                                  </VerticalLabel>
                                )}
                            </VerticalLabels>
                          </TableCellLink>
                        </Td>
                        <Td property="interviewers">
                          <TableCellLink onClick={onClick}>
                            <InterviewersOrAssignment
                              webInterviewGuid={webInterview.guid}
                              assignment={webInterview.assignment}
                              interviewers={interviewers}
                              maxDisplayCount={3}
                            />
                          </TableCellLink>
                        </Td>
                        <Td property="viewers">
                          <TableCellLink onClick={onClick}>
                            {viewers && viewers.length > 0 && (
                              <AssigneeIcons
                                assignees={viewers}
                                tooltipId={`${TOOLTIP_ID_MAP.viewersOnWebInterviews}_${webInterview.guid}`}
                                maxDisplayCount={1}
                              />
                            )}
                          </TableCellLink>
                        </Td>
                        <Td property="interviewGuide">
                          <TableCellLink onClick={onClick}>
                            {webInterview.interviewGuide && (
                              <InterviewGuide>
                                {webInterview.interviewGuide.name}
                              </InterviewGuide>
                            )}
                          </TableCellLink>
                        </Td>
                        <Td property="padding">
                          <TableCellLink onClick={onClick} />
                        </Td>
                        <Td property="registerTime">
                          <TableCellLink onClick={onClick}>
                            <DateTimeFormat
                              dateTime={webInterview.registerTime}
                            />
                          </TableCellLink>
                        </Td>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              {emptyListMessage}
            </TableWrapper>
          </MainWrapper>
        </Wrapper>
        <BatchDeleteWebInterviewsModal
          active={isDeleteWebInterviewsModalActive}
          onCloseModal={onCloseDeleteWebInterviewsModal}
          webInterviews={pageCtx.webInterviews}
        />
        <ChangeWebInterviewGuidesModal
          active={isChangeWebInterviewGuidesModalActive}
          onCloseModal={onCloseChangeWebInterviewGuidesModal}
          webInterviews={pageCtx.webInterviews}
        />
      </PageLayoutWithGlonavi>
    )
  }
)

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  overflow: auto;
  flex: 1;
`

const HeaderButtonGroups = styled.div`
  display: flex;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: 7px;
  }
`

const NewWebInterviewsLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const MainWrapper = styled.div`
  grid-area: main;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  padding: 20px 16px 20px 0;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 20px;
`

const RightControl = styled.div`
  display: flex;
  align-items: center;
`

const BatchActionButton = styled(TertiaryButton).attrs({
  widthSize: 'S',
})`
  padding: 0px;
  margin-left: 6px;
  border: none;
  background-color: ${theme.color.gray[4]};
`

const VerticalSeparator = styled.div`
  border-left: 1px solid ${theme.color.navy[2]};
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 24px;
  margin: 0 15px 0 18px;
`

const SelectedTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SelectedCount = styled(Txt).attrs({ size: 'l' })`
  margin-right: 6px;
`

const ClearSelectButton = styled.button`
  ${buttonResetStyle};

  color: ${theme.color.blue[2]};
  font-size: ${theme.fontSize.m};

  margin-left: 16px;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

type Property =
  | 'select'
  | 'status'
  | 'scheduledStartTimeAndDuration'
  | 'name'
  | 'interviewers'
  | 'viewers'
  | 'interviewGuide'
  | 'registerTime'
  | 'padding'

const width: Record<Property, number> = {
  select: 52,
  name: 220,
  status: 104,
  scheduledStartTimeAndDuration: 140,
  interviewers: 200,
  viewers: 80,
  interviewGuide: 148,
  registerTime: 120,
  padding: 0,
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

// TODO: あとから TableBase とあわせたコンポーネントにする

const Table = styled(TableBase)`
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  select: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.select}px;
  `,
  scheduledStartTimeAndDuration: css`
    flex: 0 0 ${width.scheduledStartTimeAndDuration}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  interviewers: css`
    flex: 0 0 ${width.interviewers}px;
    justify-content: flex-start;
  `,
  viewers: css`
    flex: 0 0 ${width.viewers}px;
    justify-content: flex-start;
  `,
  interviewGuide: css`
    flex: 0 0 ${width.interviewGuide}px;
    justify-content: flex-start;
  `,
  status: css`
    flex: 0 0 ${width.status}px;
    justify-content: flex-start;
  `,
  registerTime: css`
    flex: 0 0 ${width.registerTime}px;
    justify-content: flex-start;
    > *:first-child {
      margin-right: 7px;
    }
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const QuestionIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
`

const TableCellLink = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -15px;
  padding: 0 15px;
  color: inherit;
  cursor: pointer;
`

const VerticalLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`

const VerticalLabel = styled.div``

const InterviewGuide = styled(LineClampedTxt).attrs({ size: 'm', line: 2 })``
