import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { Name } from '@blue-agency/im-shared-front'
import { SearchWebInterviewsResponse } from '@blue-agency/proton/biz_hutt_bff'
import {
  WebInterviewAssignment,
  WebInterviewAssigneeRole,
} from '@blue-agency/proton/im'
import assert from 'assert'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { WithLoading } from '@/@types/withLoading'
import { useSortBy } from '@/hooks/useSortBy'
import {
  SortBy,
  compressSortBy,
  decompressSortBy,
  timestampToDate,
} from '@/services/bffService'
import { validateNumberParamQuery } from '@/services/queryParamService'
import { useWebInterviewsStorage } from '@/services/webInterviewService'
import type { WebInterview, Assignee, Staff } from '../types'
import {
  useSearchForm,
  compressSearchForm,
  decompressSearchForm,
} from './useSearchForm'
import { useSearchWebInterviews } from './useSearchWebInterviews'
import { useStaffs } from './useStaffs'

type Response = WithLoading<
  {
    webInterviews: WebInterview[]
    totalLength: number
    currentPage: number
    pageCount: number
    sortBy: SortBy
    allWebInterviewSelected: boolean
    selectedWebInterviewCount: number
    onSelectAllWebInterview: () => void
    onRequestChangePage: (page: number) => void
    onSelectWebInterview: (interviewGuid: string) => void
    onClearSelectWebInterview: () => void
    toggleSortBy: (column: SortBy['column']) => void
    staffs: Staff[]
    setScrollTopQuery: (st: number) => void
    scrollTopQuery: number
    saveQueryToLocalStorage: (guid: string) => void
  } & ReturnType<typeof useSearchForm>
>

const sizePerPage = 100

function useWebInterviewsPage(): Response {
  const [query, setQuery] = useQueryParams({
    cp: NumberParam, // currentPage
    sb: StringParam, // sortBy
    sc: StringParam, // searchCondition
    st: NumberParam, // scrollTop
  })
  const scrollTopQueryRef = useRef(0)
  const setScrollTopQuery = (st: number) => {
    scrollTopQueryRef.current = st
    setQuery({ st: st }, 'pushIn')
  }
  const scrollTopQuery = validateNumberParamQuery(query.st) ?? 0

  const wis = useWebInterviewsStorage()
  const saveQueryToLocalStorage = (guid: string) => {
    // NOTE: この関数が呼ばれるタイミングでstを計算しており、setQueryしてからquery.st経由でstを取得すると計算前のstが入ってしまうのでref経由で渡す
    const queryStr = `?cp=${query.cp}&sb=${query.sb}&sc=${query.sc}&st=${scrollTopQueryRef.current}`
    wis.saveQueryAndListToBack('webInterviews', guid, queryStr)
  }

  const [currentPage, setCurrentPage] = useState(
    validateNumberParamQuery(query.cp, { shouldBeInt: true }) || 1
  )

  const defaultSortBy = (() => {
    if (!query.sb) return undefined
    try {
      return decompressSortBy(JSON.parse(query.sb))
    } catch {
      return undefined
    }
  })()
  const { sortBy, toggleSortBy } = useSortBy<SortBy>({
    initialSortBy: {
      column: defaultSortBy?.column ?? 'registerTime',
      desc: defaultSortBy?.desc ?? true,
    },
    descFirstColumns: ['registerTime', 'scheduledStartTime'],
  })

  const defaultSearchFormValue = (() => {
    if (!query.sc) return undefined
    try {
      const j = decompressSearchForm(JSON.parse(query.sc))
      return {
        ...j,
        registerTime: {
          from: j.registerTime.from && new Date(j.registerTime.from),
          to: j.registerTime.to && new Date(j.registerTime.to),
        },
      }
    } catch {
      return undefined
    }
  })()
  const { formValue, dispatch, validatedFormValue, freewordErrorMessage } =
    useSearchForm(defaultSearchFormValue)

  const { data, isLoading } = useSearchWebInterviews({
    pageSize: sizePerPage,
    page: currentPage,
    sortBy,
    condition: validatedFormValue,
  })
  const [selectedInterviewGuidMap, setSelectedInterviewGuidMap] = useState<
    Record<string, boolean>
  >({})

  const webInterviews = useMemo(() => {
    return data?.toObject().webInterviewsList.map((interview, index) => {
      const registerTime = timestampToDate(interview.registerTime)
      if (!registerTime) {
        throw new Error('registerTime is undefined')
      }
      const selected = !!selectedInterviewGuidMap[interview.guid]

      const decodeWebInterviewAssignment = (
        assignment: WebInterviewAssignment
      ) => {
        switch (assignment) {
          case WebInterviewAssignment.SPECIFIED:
            return 'specified' as const
          case WebInterviewAssignment.UNSPECIFIED:
            return 'unspecified' as const
          case WebInterviewAssignment.UNSPECIFIED_CANNOT_VIEW_DETAIL:
            return 'unspecified-cannot-view-detail' as const
          case WebInterviewAssignment.WEB_INTERVIEW_ASSIGNMENT_UNKNOWN:
            throw Error('Unexpected assignement received')
        }
      }

      const decodeAssigneeRole = (role: WebInterviewAssigneeRole) => {
        switch (role) {
          case WebInterviewAssigneeRole.INTERVIEWER:
            return 'interviewer'
          case WebInterviewAssigneeRole.VIEWER:
            return 'viewer'
          case WebInterviewAssigneeRole.WEB_INTERVIEW_ASSIGNEE_ROLE_UNKNOWN:
            throw Error('Unexpected assigneeRole received')
        }
      }

      const decodeAssignee = (
        assignee: SearchWebInterviewsResponse.Assignee.AsObject
      ) => {
        assert(assignee.name)

        return {
          ...assignee,
          guid: assignee.staffGuid,
          name: new Name(assignee.name.familyName, assignee.name.givenName),
          role: decodeAssigneeRole(assignee.role),
        } as const
      }
      const assignees: Assignee[] = interview.assigneesList.map(decodeAssignee)

      return {
        ...interview,
        registerTime,
        assignment: decodeWebInterviewAssignment(interview.assignment),
        assignees,
        selected,
      }
    })
  }, [data, selectedInterviewGuidMap])

  const totalLength = useMemo(() => data?.toObject().totalLength, [data])

  const pageCount = useMemo(() => {
    return totalLength && Math.ceil(totalLength / sizePerPage)
  }, [totalLength])

  const selectedWebInterviewCount = useMemo(() => {
    return Object.values(selectedInterviewGuidMap).filter(Boolean).length
  }, [selectedInterviewGuidMap])

  const allWebInterviewSelected = useMemo(() => {
    const webInterviewsList = data?.toObject().webInterviewsList
    if (!webInterviewsList) {
      return undefined
    }

    return webInterviewsList.length === selectedWebInterviewCount
  }, [data, selectedWebInterviewCount])

  const onSelectWebInterview = useCallback((interviewGuid: string) => {
    setSelectedInterviewGuidMap((prev) => ({
      ...prev,
      [interviewGuid]: !prev[interviewGuid],
    }))
  }, [])

  const onClearSelectWebInterview = useCallback(() => {
    setSelectedInterviewGuidMap({})
  }, [])

  const onSelectAllWebInterview = useCallback(() => {
    if (allWebInterviewSelected) {
      setSelectedInterviewGuidMap({})
      return
    }

    const webInterviewsList = data?.toObject().webInterviewsList
    if (!webInterviewsList) {
      throw new Error('webInterviewsList is undefined')
    }

    const newMap = webInterviewsList.reduce<Record<string, boolean>>(
      (acc, current) => {
        acc[current.guid] = true
        return acc
      },
      {}
    )
    setSelectedInterviewGuidMap(newMap)
  }, [allWebInterviewSelected, data])

  const onRequestChangePage = useCallback((page: number) => {
    setCurrentPage(page)
  }, [])

  useEffect(() => {
    const cleanupSelected = () => {
      setSelectedInterviewGuidMap({})
    }
    cleanupSelected()
  }, [data])

  useEffect(() => {
    setQuery(
      {
        cp: currentPage,
        sb: JSON.stringify(compressSortBy(sortBy)),
        sc: JSON.stringify(compressSearchForm(validatedFormValue)),
      },
      'replaceIn'
    )
  }, [currentPage, sortBy, validatedFormValue, setQuery])

  /**
   * 検索条件が変わった時に1ページ目に戻す
   * queryからcurrentPageを復元する場合、queryの値で初期化した後に初回useEffectが走ると1ページ目に戻されてしまうので、
   * 初回レンダリングでは処理をスキップしてvalidatedFormValueに変更があった時のみ1ページ目に戻すようにする
   */
  const isFirstRenderingRef = useRef(true)
  useEffect(() => {
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false
      return
    }
    setCurrentPage(1)
  }, [validatedFormValue])

  const staffs = useStaffs()

  if (isLoading) {
    return {
      isLoading,
    }
  }

  return {
    isLoading,
    webInterviews: webInterviews!,
    totalLength: totalLength!,
    currentPage,
    pageCount: pageCount!,
    sortBy,
    onSelectAllWebInterview,
    allWebInterviewSelected: allWebInterviewSelected!,
    selectedWebInterviewCount,
    onRequestChangePage,
    onSelectWebInterview,
    toggleSortBy,
    onClearSelectWebInterview,
    formValue,
    dispatch,
    validatedFormValue,
    freewordErrorMessage,
    staffs,
    setScrollTopQuery,
    scrollTopQuery,
    saveQueryToLocalStorage,
  }
}

export const WebInterviewsPageContainer = createContainer(useWebInterviewsPage)
