import { useMemo } from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { ErrorBox, theme } from '@blue-agency/rogue'
import { TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { Link as _Link, generatePath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Box } from '@/components/Box'
import { INTERNAL_PATHS } from '@/services/urlService'
import { VideoSeminarContentEditPageContainer } from '../VideoSeminarContentEditPageContainer'
import { InputBody } from './InputBody'

export const InputPage = () => {
  const { completeInput, form, videoSeminarGuid, movieState } =
    VideoSeminarContentEditPageContainer.useContainer()

  const errorsOnTop = useMemo(() => {
    const { errors } = form.formState
    const res = []

    // movieFile で発生するエラーはファイル形式、ファイルサイズなど、アップロード前のバリデーションエラー
    // 一方、movieKeyで発生するエラーは、アップロード中が何らかの理由で失敗したことを表す
    // 発生タイミングが異なるため別のエラーとなっているが、ユーザーに見えるエラー内容
    // としては同じようなものになるので、どちらか一方のみを表示するようにする
    if (errors.movieFile !== undefined) {
      res.push({
        message: errors.movieFile.message,
        field: 'movieFile',
      })
    } else if (errors.movieKey !== undefined) {
      res.push({
        message: errors.movieKey.message,
        field: 'movieKey',
      })
    }

    if (errors.thumbnailFile !== undefined) {
      res.push({
        message: errors.thumbnailFile.message,
        field: 'thumbnailFile',
      })
    }

    return res
  }, [form.formState])

  return (
    <>
      <Page.Body>
        {errorsOnTop.length > 0 && (
          <Box paddingY="20px" backgroundColor={theme.color.white[1]}>
            <ErrorBox>
              <TopErrors hasMultiple={errorsOnTop.length >= 2}>
                {errorsOnTop.map((e) => (
                  <li key={e.field}>{e.message}</li>
                ))}
              </TopErrors>
            </ErrorBox>
          </Box>
        )}
        <InputBody />
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <BackButtonLink
            to={generatePath(INTERNAL_PATHS.videoSeminarContents, {
              videoSeminarGuid,
            })}
          >
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_cancel_edit_video_seminar_content_button_on_input_page',
              }}
            >
              キャンセル
            </TertiaryButton>
          </BackButtonLink>
          <PrimaryButton
            widthSize="L2"
            onClick={async () => {
              const isValid = await form.trigger()
              if (!isValid) return

              // NOTE: アップロード中はSubmitできないようにする
              if (movieState === 'uploading') {
                form.setError('movieKey', {
                  message: '動画アップロード完了までお待ちください。',
                })
                return
              }

              completeInput()
            }}
            comlinkPushParams={{
              action: 'click_confirm_video_seminar_edit_content_button',
            }}
          >
            内容を確認
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}

const TopErrors = styled.ul<{ hasMultiple: boolean }>`
  ${({ hasMultiple }) => {
    if (hasMultiple) {
      return css`
        margin-left: 12px;
      `
    }
    // エラーが1つだけのときはbulletを表示しない
    return css`
      list-style-type: none;
      margin: 0;
      padding: 0;
    `
  }};
`

const BackButtonLink = styled(_Link)`
  color: inherit;
  text-decoration: none;
`
