import { BatchUpdateWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import {
  WebInterviewAssigneeRole,
  WebInterviewDuration,
} from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  Assignment,
  Specified,
  WebInterviewUpdate,
} from '@/pages/WebInterviewUpdateByFilePage/csv/WebInterviewUpdate'
import {
  KeyTarget,
  MappingResult,
} from '@/pages/WebInterviewUpdateByFilePage/types'
import { AssigneeRole } from '@/services/webInterviewService'

type Props = {
  mapping: MappingResult
  webInterviewUpdates: WebInterviewUpdate[]
}

export function buildRequest(props: Props) {
  const req = new BatchUpdateWebInterviewsRequest()
  req.setWebInterviewsList(buildWebInterviews(props.webInterviewUpdates))
  req.setUpdateTarget(buildUpdateTarget(props.mapping))
  return req
}

function buildUpdateTarget(mapping: MappingResult) {
  const ut = new BatchUpdateWebInterviewsRequest.UpdateTarget()
  ut.setKeyType(buildKeyType(mapping.keyType))
  ut.setName(mapping.name !== undefined)
  ut.setDuration(mapping.duration !== undefined)
  ut.setScheduledStartTime(mapping['scheduled-start-time'] !== undefined)
  ut.setWebInterviewGuideGuid(mapping['interview-guide-guid'] !== undefined)
  ut.setAssignmentInput(
    mapping.assignment !== undefined ||
      (mapping.interviewer ?? []).length !== 0 ||
      (mapping.viewer ?? []).length !== 0
  )
  return ut
}

function buildWebInterviews(webInterviewUpdates: WebInterviewUpdate[]) {
  return webInterviewUpdates.map(buildWebInterview)
}

function buildKeyType(
  keyType: KeyTarget
): BatchUpdateWebInterviewsRequest.UpdateTarget.KeyType {
  switch (keyType) {
    case 'web-interview-guid':
      return BatchUpdateWebInterviewsRequest.UpdateTarget.KeyType
        .WEB_INTERVIEW_GUID
    case 'ats-interview-id':
      return BatchUpdateWebInterviewsRequest.UpdateTarget.KeyType
        .ATS_INTERVIEW_ID
  }
}

function buildWebInterview(webInterviewUpdate: WebInterviewUpdate) {
  const wi = new BatchUpdateWebInterviewsRequest.WebInterview()
  wi.setKey(webInterviewUpdate.key)
  wi.setName(webInterviewUpdate.name ?? '')
  wi.setDuration(buildDuration(webInterviewUpdate.durationMinutes))
  wi.setScheduledStartTime(
    buildScheduledStartTime(webInterviewUpdate.scheduledStartTime)
  )
  wi.setWebInterviewGuideGuid(
    buildWebInterviewGuideGuid(webInterviewUpdate.webInterviewGuideGuid)
  )
  wi.setAssignmentInput(
    buildAssignmentInput(
      webInterviewUpdate.assignment,
      webInterviewUpdate.interviewers,
      webInterviewUpdate.viewers
    )
  )
  return wi
}

function buildDuration(durationMinutes: Specified<number> | undefined) {
  const d = new WebInterviewDuration()

  if (durationMinutes === undefined) {
    d.setUnspecified(true)
    return d
  }

  if (durationMinutes.specified) {
    const specified = new WebInterviewDuration.Specified()
    specified.setSpecified(true)
    specified.setDurationSeconds(durationMinutes.value * 60)
    d.setSpecified(specified)
  } else {
    d.setUnspecified(true)
  }

  return d
}

function buildScheduledStartTime(
  scheduledStartTime: Specified<Date> | undefined
) {
  if (scheduledStartTime === undefined) {
    return
  }

  if (scheduledStartTime.specified) {
    return Timestamp.fromDate(scheduledStartTime.value)
  } else {
    return
  }
}

function buildWebInterviewGuideGuid(
  webInterviewGuideGuid: Specified<string> | undefined
) {
  if (webInterviewGuideGuid === undefined) {
    return ''
  }

  if (webInterviewGuideGuid.specified) {
    return webInterviewGuideGuid.value
  } else {
    return ''
  }
}

export type Assignee = {
  email: string
  assignee_role: AssigneeRole
}

function buildAssignmentInput(
  assignment: Assignment | undefined,
  interviewer: string[],
  viewer: string[]
) {
  const assignmentInput =
    new BatchUpdateWebInterviewsRequest.WebInterviewAssignmentInput()

  const ivs: Assignee[] = interviewer.map((email) => ({
    email,
    assignee_role: 'interviewer',
  }))
  const vs: Assignee[] = viewer.map((email) => ({
    email,
    assignee_role: 'viewer',
  }))
  const assignees = ivs.concat(vs)

  // 担当設定がundefinedである場合は、以下の挙動を実現するために「指定する」とする。
  // - 面接官や閲覧者が紐付け設定にて指定されている場合には、それら担当者を割り当てて「指定する」にする
  // - 面接官や閲覧者が紐付け設定にて指定されていない場合には、UpdateTarget.assignment === falseにより更新対象外とする
  const ass = assignment ?? 0

  // 0: 指定する, 1: 指定しない, 2: 指定しない（面接詳細の閲覧不可）
  switch (ass) {
    case 0:
      assignmentInput.setSpecified(buildSpecified(assignees))
      break
    case 1:
      assignmentInput.setUnspecified(true)
      break
    case 2:
      assignmentInput.setUnspecifiedCannotViewDetail(true)
      break
  }
  return assignmentInput
}

function buildSpecified(assignees: Assignee[]) {
  const specified =
    new BatchUpdateWebInterviewsRequest.WebInterviewAssignmentInput.Specified()
  specified.setSpecified(true)
  specified.setAssigneesList(
    assignees.map((assignee) => {
      const ass = new BatchUpdateWebInterviewsRequest.WebInterviewAssignee()
      ass.setEmail(assignee.email)
      ass.setAssigneeRole(buildAssigneeRole(assignee.assignee_role))
      return ass
    })
  )
  return specified
}

function buildAssigneeRole(ass: AssigneeRole): WebInterviewAssigneeRole {
  switch (ass) {
    case 'interviewer':
      return WebInterviewAssigneeRole.INTERVIEWER
    case 'viewer':
      return WebInterviewAssigneeRole.VIEWER
  }
}
