import { useMutation, useQueryClient } from 'react-query'
import { buildRequest } from '@/pages/WebInterviewUpdateByFilePage/buildRequest'
import { WebInterviewUpdate } from '@/pages/WebInterviewUpdateByFilePage/csv/WebInterviewUpdate'
import { MappingResult } from '@/pages/WebInterviewUpdateByFilePage/types'
import { useRequestBatchUpdateWebInterviews } from '@/services/bffService/useRequestBatchUpdateWebInterviews'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchUpdateWebInterviews() {
  const queryClient = useQueryClient()
  const { requestBatchUpdateWebInterviews } =
    useRequestBatchUpdateWebInterviews()

  return useMutation(
    async (value: {
      mapping: MappingResult
      webInterviewUpdates: WebInterviewUpdate[]
    }) => {
      return await requestBatchUpdateWebInterviews(buildRequest(value))
    },
    {
      onSuccess: (_, value) => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviews)
        switch (value.mapping.keyType) {
          case 'web-interview-guid':
            queryClient.invalidateQueries({
              predicate: (query) =>
                query.queryKey[0] === QUERY_KEY.webInterviews &&
                value.webInterviewUpdates
                  .map((wi) => wi.key)
                  .includes(query.queryKey[1] as string),
            })
            break
          case 'ats-interview-id':
            // NOTE: ATS_INTERVIEW_IDを指定した更新の場合、Web面接GUIDが不明であるため、応募者詳細全体をinvalidateする
            queryClient.invalidateQueries(QUERY_KEY.webInterview)
            break
        }
      },
    }
  )
}
