import React, { useCallback, useState } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { theme, toast } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { generatePath, useHistory } from 'react-router-dom'
import { SettingsSection } from '@/components/SettingsSection'
import {
  ConfirmBody,
  DuplicateApplicantModal,
  DuplicateApplicant,
  UseFormResponse,
} from '@/components/applicantForm'
import { useModal } from '@/hooks/useModal'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useUpdateApplicant } from './useUpdateApplicant'

type Props = {
  formCtx: UseFormResponse['formCtx']
  guid: string
  toInput: () => void
}

export const ConfirmPage: React.VFC<Props> = ({ formCtx, guid, toInput }) => {
  const [duplicateApplicants, setDuplicateApplicants] = useState<
    DuplicateApplicant[]
  >([])
  const duplicateApplicantModal = useModal()

  const { mutateAsync } = useUpdateApplicant()
  const history = useHistory()

  const register = useCallback(
    (allowDuplicate: boolean) => async () => {
      const values = formCtx.getValues()

      try {
        await mutateAsync({
          guid,
          allowDuplicate,
          applicant: values,
        })
      } catch (e) {
        if (e instanceof CustomBizHuttBffGrpcError) {
          if (e.hasMatchErrorDetail('DUPLICATE_EXTERNAL_APPLICANT_ID')) {
            const metadata = e.getErrorDetailMetadataMap(
              'DUPLICATE_EXTERNAL_APPLICANT_ID'
            )
            if (metadata === undefined) return
            const d = metadata.toArray().map((v) => ({
              guid: v[0],
              name: v[1],
            }))
            setDuplicateApplicants(d)
            duplicateApplicantModal.open()
            return
          }
        }

        Sentry.captureException(e)
        commonErrorToast()
        return
      }

      toast('応募者情報を保存しました')
      history.push({
        pathname: generatePath(INTERNAL_PATHS.applicant, {
          applicantGuid: guid,
        }),
        search: '?t=profile',
      })
    },
    [formCtx, mutateAsync, history, guid, duplicateApplicantModal]
  )

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>応募者編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容の確認">
            <ConfirmBody formCtx={formCtx} isDetailsEnabled />
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              onClick={toInput}
              comlinkPushParams={{
                action: 'click_back_to_input_on_edit_applicant_page',
              }}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              onClick={register(false)}
              comlinkPushParams={{
                action: 'click_register_on_edit_applicant_page',
              }}
            >
              保存
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
      <DuplicateApplicantModal
        active={duplicateApplicantModal.active}
        externalApplicantId={formCtx.getValues('externalApplicantId') ?? ''}
        duplicateApplicants={duplicateApplicants}
        onCancel={duplicateApplicantModal.close}
        onRegister={register(true)}
      />
    </PageLayout>
  )
}
