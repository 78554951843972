import React from 'react'
import { RadioGroup, WeakColorSeparator } from '@blue-agency/rogue'
import { InputText, DatePicker, Dropdown, FormRow } from '@blue-agency/rogue/im'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import * as C from '@/components/RecInterviewTemplate'
import { SettingsSection } from '@/components/SettingsSection'
import {
  dateFormatString,
  dueDurationDays,
  dueDurationHours,
  formatTime,
} from '@/services/recInterviewTemplateService'
import { StepParam } from './types'

export const InputBasicInfo: React.VFC<StepParam> = ({
  form: { template, register, errors, control, setValue },
}) => (
  <SettingsSection title="基本情報">
    <FormWrapper>
      <Row title="テンプレート名" required>
        <InputName
          {...register('name')}
          placeholder="例）＊＊卒新卒採用総合職用"
          defaultValue={template.name}
          onBlurError={errors.name?.message}
        />
      </Row>
      <WeakColorSeparator />
      <Row title={'作成済みの\n動画ファイル提出'}>
        <AllowRecordedVideoRadioGroup
          {...register('allowRecordedVideo')}
          defaultValue={template.allowRecordedVideo}
        />
      </Row>
      <WeakColorSeparator />

      <Row title="提出期限" labelHeight="48px">
        <DueRadioGroup>
          <DueRadioGroupType
            {...register('due.type')}
            defaultValue={template.due.type}
          />
          <DueRadioGroupInputs>
            <DateTime onClick={() => setValue('due.type', 'time')}>
              <Controller
                name="due.time.time"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePicker
                      value={field.value ? new Date(field.value) : null}
                      onChange={(d: Date | null) => {
                        if (d === null) {
                          field.onChange('')
                        }
                        if (d instanceof Date) {
                          field.onChange(formatTime(d))
                        }
                      }}
                      dateFormat={dateFormatString}
                      onBlurError={
                        // FIXME: fieldState.error.messageだとonBlur時に反映されない
                        field.value === '' ? '入力してください' : undefined
                      }
                    />
                  )
                }}
              />
              <C.SText>の</C.SText>
              <Dropdown
                {...register('due.time.hour')}
                options={dueDurationHours.map((v) => ({
                  value: v,
                  label: v.toString(),
                }))}
                size="ss"
              />
              <C.SText>時まで</C.SText>
            </DateTime>
            <DateTime onClick={() => setValue('due.type', 'days')}>
              <C.SText>提出依頼から</C.SText>
              <Dropdown
                {...register('due.duration.days')}
                options={dueDurationDays.map((v) => ({
                  value: v,
                  label: v.toString(),
                }))}
                size="ss"
              />
              <C.SText>日後の</C.SText>
              <Dropdown
                {...register('due.duration.hour')}
                options={dueDurationHours.map((v) => ({
                  value: v,
                  label: v.toString(),
                }))}
                size="ss"
              />
              <C.SText>時まで</C.SText>
            </DateTime>
          </DueRadioGroupInputs>
        </DueRadioGroup>
      </Row>
    </FormWrapper>
  </SettingsSection>
)

const FormWrapper = styled.div`
  padding: 0 16px;
`

const InputName = styled(InputText).attrs({ size: 'll' })``

const AllowRecordedVideoRadioGroup = styled(RadioGroup).attrs({
  direction: 'row',
  options: [
    { label: '許可する', value: 'allowed' },
    { label: '許可しない', value: 'notAllowed' },
  ],
})``

const DueRadioGroup = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'due-selector due-inputs';
`

const DueRadioGroupType = styled(RadioGroup).attrs({
  direction: 'column',
  hideLabel: true,
  options: [
    { label: '日時指定', value: 'time' },
    { label: '日数指定', value: 'days' },
  ],
})`
  grid-area: due-selector;
  align-items: center;
  justify-content: center;
  & > label {
    height: 30px;
  }
`

const DueRadioGroupInputs = styled.div`
  grid-area: due-inputs;
  & > span {
    margin: auto auto;
  }
`

const DateTime = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 400px;
`

const Row = styled(FormRow).attrs({
  subtitleWidth: '0',
})`
  padding: 20px 0;
`
