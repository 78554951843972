import React, { useEffect } from 'react'
import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { WeakColorSeparator } from '@blue-agency/rogue'
import {
  TertiaryButton,
  PrimaryButton,
  FormRow,
  InputText,
} from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link as _Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  VideoSeminarForm,
  videoSeminarFormSchema,
} from '@/services/videoSeminarService'

type Props = {
  videoSeminarGuid: string
  form: VideoSeminarForm | undefined
  completeInput: (form: VideoSeminarForm) => void
}

export const InputPage: React.VFC<Props> = ({
  videoSeminarGuid,
  form,
  completeInput,
}) => {
  const { register, formState, trigger, getValues, setValue } =
    useForm<VideoSeminarForm>({
      resolver: yupResolver(videoSeminarFormSchema),
      mode: 'onBlur',
      defaultValues: form,
    })

  const onConfirm = async () => {
    const valid = await trigger()
    if (!valid) return
    completeInput(getValues())
  }

  useEffect(() => {
    if (!form) return
    setValue('title', form.title)
    setValue('description', form.description)
  }, [form, setValue])

  return (
    <>
      <Page.Body>
        <SettingsSection title="基本情報">
          <FormWrapper>
            <Row title="タイトル" required>
              <InputTitle
                {...register('title')}
                onBlurError={formState.errors.title?.message}
                placeholder="例）新卒採用　セミナー動画"
              />
            </Row>
            <WeakColorSeparator />
            <Row title="トップ紹介文">
              <InputDescription
                {...register('description')}
                onBlurError={formState.errors.description?.message}
                placeholder={
                  '例）株式会社＊＊にご興味を持っていただき、ありがとうございます。\nより深く＊＊を知ってもらえるように、さまざまな動画を公開しています。'
                }
              />
            </Row>
          </FormWrapper>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <BackButtonLink
            to={generatePath(INTERNAL_PATHS.videoSeminarInfo, {
              videoSeminarGuid,
            })}
          >
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_cancel_button_on_edit_video_seminar_page',
              }}
            >
              キャンセル
            </TertiaryButton>
          </BackButtonLink>
          <PrimaryButton
            widthSize="L2"
            disabled={formState.isSubmitting}
            comlinkPushParams={{
              action: 'click_confirm_button_on_video_seminar_edit_page',
            }}
            onClick={onConfirm}
          >
            内容を確認
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}

const BackButtonLink = styled(_Link)`
  color: inherit;
  text-decoration: none;
`

const FormWrapper = styled.div`
  padding: 16px 20px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '200px',
  subtitleWidth: '0',
})`
  padding: 20px 0;
`

const InputTitle = styled(InputText).attrs({
  size: 'll',
})``

const InputDescription = styled(InputText).attrs({
  size: 'll',
  type: 'textarea',
  height: '86px',
})``
