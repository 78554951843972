import { Txt, Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export function DeletedStatus() {
  return (
    <Wrapper>
      <ImageWrapper>
        <DeletedRecordingIcon />
      </ImageWrapper>
      <Message>
        <Txt>録画は既に削除されています</Txt>
      </Message>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 52px;
  background-color: ${theme.color.gray[5]};
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
`

const DeletedRecordingIcon = styled(Icon).attrs({
  name: 'deleted-recording',
  size: 'll',
})`
  fill: none;
`
