import { Modal, theme, Toggle, Txt } from '@blue-agency/rogue'
import { FormRow, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  isPublic: boolean
  onToggle: () => void
  onClose: () => void
}
export const Presenter: React.VFC<Props> = (props) => {
  return (
    <Modal active title="公開設定" onClose={props.onClose} size="l" stopScroll>
      <ModalContent>
        <Description>
          セミナーページ（ゲスト参加者ページを含む）を応募者が視聴できる状態にする設定です。
          <br />
          「公開停止中」にしている場合、応募者はセミナーページを閲覧できません。
        </Description>
        <Row title="セミナーページの公開状況">
          <Toggle checked={props.isPublic} onChange={props.onToggle} />
          <ToggleLabelTxt>
            {props.isPublic ? '公開中' : '公開停止中'}
          </ToggleLabelTxt>
        </Row>
      </ModalContent>
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={props.onClose}
          comlinkPushParams={{
            action: 'click_close_button_on_video_seminar_public_setting_modal',
          }}
        >
          閉じる
        </TertiaryButton>
      </ButtonArea>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const Description = styled(Txt)``

const Row = styled(FormRow).attrs({
  titleWidth: '84px',
  subtitleWidth: '0',
})`
  margin-top: 20px;
`

const ToggleLabelTxt = styled(Txt)`
  margin-left: 8px;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-top: 1px solid ${theme.color.gray[4]};
`
