import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestCreateSelectionStepMaster,
  CreateSelectionStepMasterRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateSelectionStepMaster() {
  const queryClient = useQueryClient()
  const { requestCreateSelectionStepMaster } =
    useRequestCreateSelectionStepMaster()

  return useMutation(
    (value: CreateSelectionStepMasterRequestValue) =>
      requestCreateSelectionStepMaster(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.selectionStepMasters)
      },
    }
  )
}
