import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { NewWebInterviewGuidePageContainer } from './newWebInterviewGuidePageContainer'

export const NewWebInterviewGuidePage = provide(
  NewWebInterviewGuidePageContainer
)((): React.ReactElement => {
  const { step } = NewWebInterviewGuidePageContainer.useContainer()

  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('CreateWebInterviewGuide')

  usePromptBeforeUnload()

  switch (step) {
    case 'input':
      return <InputPage />
    case 'confirm':
      return <ConfirmPage />
  }
})
