export const TOOLTIP_ID_MAP = {
  nameOnWebInterviewList: 'nameOnWebInterviewList',

  countOnRegisterWebInterview: 'countOnRegisterWebInterview',
  nameOnRegisterWebInterview: 'nameOnRegisterWebInterview',
  assignmentOnRegisterWebInterview: 'assignmentOnRegisterWebInterview',
  interviewersOnWebInterviews: 'interviewersOnWebInterviews',
  viewersOnWebInterviews: 'viewersOnWebInterviews',
  assignmentOnWebInterview: 'assignmentOnWebInterview',
  interviewersOnWebInterview: 'interviewersOnWebInterview',
  viewersOnWebInterview: 'viewersOnWebInterview',

  recInterviewTemplateQuestion: 'recInterviewTemplateQuestion',

  applicantDetailName: 'applicantDetailName',
  applicantDetailNameKana: 'applicantDetailNameKana',
  applicantDetailStepName: 'applicantDetailStepName',
  applicantDetailStepNames: 'applicantDetailStepNames',
  leadApplicantDetailStepNames: 'leadApplicantDetailStepNames',
  videoSeminarEventReport: 'videoSeminarEventReport',
  videoSeminarEventReportFormatTime: 'videoSeminarEventReportFormatTime',
  videoSeminarEventReportEllipsisTitle: 'videoSeminarEventReportEllipsisTitle',

  interviewersOnControlCenter: 'interviewersOnControlCenter',
  interviewerFullNameOnControlCenter: 'interviewerFullNameOnControlCenter',
  requestingIntervieweesOnControlCenter:
    'requestingIntervieweesOnControlCenter',
  omittedInterviewersOnControlCenterOngoing:
    'omittedInterviewersOnControlCenterOngoing',

  searchApplicantsMailFailure: 'searchApplicantsMailFailure',

  shareSelection: 'shareSelection',

  movieUploadOnVideoSeminarContentRegister:
    'movieUploadOnVideoSeminarContentRegister',

  videoSeminarContentStatus: 'videoSeminarContentStatus',
} as const
