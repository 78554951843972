import React from 'react'
import { PermissionDenied } from '@blue-agency/im-shared-front'
import {
  GetWebInterviewResponse,
  WebInterviewStatus,
} from '@blue-agency/proton/biz_hutt_bff'
import { useParams } from 'react-router-dom'
import { assertIsDefined } from '@/assertions'
import { Loading } from '@/components/Loading'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { DeletedStatus } from './panelComponents/DeletedStatus'
import { EncodedStatus } from './panelComponents/EncodedStatus'
import { EncodingStatus } from './panelComponents/EncodingStatus'
import { ExpiredStatus } from './panelComponents/ExpiredStatus'
import { FailedStatus } from './panelComponents/FailedStatus'
import { FinishedAndNotPermitted } from './panelComponents/FinishedAndNotPermitted'
import { NotInterviewedStatus } from './panelComponents/NotInterviewedStatus'
import { PermissionDeniedStatus } from './panelComponents/permissionDeniedStatus'
import { useGetWebInterview } from './useGetWebInterview'

export const EmbeddedWebInterviewPage: React.VFC = () => {
  const { webInterviewGuid } = useParams<{ webInterviewGuid: string }>()
  const ctx = useGetWebInterview(webInterviewGuid)

  if (ctx.isLoading) {
    return <Loading />
  }

  if (ctx.isError) {
    const e = ctx.error
    if (e instanceof CustomBizHuttBffGrpcError) {
      if (e.isNotFound) {
        if (e.hasMatchErrorDetail('WEB_INTERVIEW_DELETED')) {
          return <DeletedStatus />
        }
      }
    }
    if (e instanceof PermissionDenied) {
      return <PermissionDeniedStatus />
    }
    throw e
  }

  const webInterview = ctx.data?.getWebInterview()?.toObject()
  assertIsDefined(webInterview)

  const renderStatusPanel = () => {
    if (webInterview.status === WebInterviewStatus.EXPIRED) {
      return <ExpiredStatus />
    }

    const { RecordingStatus } = GetWebInterviewResponse

    // 録画再生が許可されてないとき、recordingUrl が空
    if (
      webInterview.recordingStatus === RecordingStatus.ENCODED &&
      webInterview.recordingUrl === ''
    ) {
      return <FinishedAndNotPermitted />
    }

    switch (webInterview.recordingStatus) {
      case RecordingStatus.NOT_INTERVIEWED:
        return <NotInterviewedStatus />
      case RecordingStatus.ENCODING:
        return <EncodingStatus />
      case RecordingStatus.ENCODED:
        return <EncodedStatus recordingUrl={webInterview.recordingUrl} />
      case RecordingStatus.FAILED:
        return <FailedStatus />
      default:
        return null
    }
  }
  return renderStatusPanel()
}
