import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { Applicant } from '@blue-agency/proton/web/v2/im'
import { Loading } from '@/components/Loading'
import { Form } from '@/components/applicantForm'
import { getGenderType } from '@/services/applicantService'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { useForm } from './hooks/useForm'
import { useStep } from './hooks/useStep'
import { useApplicant } from './useApplicant'

export const EditApplicantPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName &&
    void authorizeByRpcName('GetApplicant') &&
    authorizeByRpcName('UpdateApplicant')

  const ctx = useApplicant()

  if (!ctx.applicant) {
    return <Loading />
  }

  return (
    <EditApplicantPageInner
      applicantGuid={ctx.applicantGuid}
      applicant={ctx.applicant}
    />
  )
}

type Props = {
  applicantGuid: string
  applicant: Applicant
}
const EditApplicantPageInner: React.VFC<Props> = (props) => {
  usePromptBeforeUnload()

  const defaultValues = buildDefaultValue(props.applicant)

  const stepCtx = useStep()
  const formCtx = useForm(defaultValues)

  switch (stepCtx.step) {
    case 'input':
      return (
        <InputPage
          applicantFormCtx={formCtx.applicantFormCtx}
          guid={props.applicantGuid}
          enableDetails={() => {}}
          toConfirm={stepCtx.toConfirm}
        />
      )
    case 'confirm':
      return (
        <ConfirmPage
          formCtx={formCtx.applicantFormCtx.formCtx}
          guid={props.applicantGuid}
          toInput={stepCtx.toInput}
        />
      )
  }
}

const buildDefaultValue = (applicant: Applicant): Form => ({
  externalApplicantId: applicant.getExternalApplicantId() ?? '',
  familyName: applicant.getFamilyName() ?? '',
  givenName: applicant.getGivenName() ?? '',
  familyNameKana: applicant.getFamilyNameKana() ?? '',
  givenNameKana: applicant.getGivenNameKana() ?? '',
  email: applicant.getEmail() ?? '',
  postalCode: applicant.getPostalCode() ?? '',
  address: applicant.getAddress() ?? '',
  gender: getGenderType(applicant.getGender()),
  genderNote: applicant.getGenderNote() ?? '',
  birthDate: applicant.getBirthDate()?.toDate(),
  phoneNumber: applicant.getPhoneNumber() ?? '',
  educationalBackgroundsList:
    applicant.getEducationalBackgroundsList().length > 0
      ? applicant.getEducationalBackgroundsList().map((e) => ({
          schoolName: e.getSchoolName() ?? '',
          faculty: e.getFaculty() ?? '',
          note: e.getNote() ?? '',
        }))
      : [
          {
            schoolName: '',
            faculty: '',
            note: '',
          },
        ],
  educationalBackgroundNote: applicant.getEducationalBackgroundNote() ?? '',
  workHistoriesList:
    applicant.getWorkHistoriesList().length > 0
      ? applicant.getWorkHistoriesList().map((w) => ({
          companyName: w.getCompanyName() ?? '',
          jobDescription: w.getJobDescription() ?? '',
          note: w.getNote() ?? '',
        }))
      : [
          {
            companyName: '',
            jobDescription: '',
            note: '',
          },
        ],
  workHistoryNote: applicant.getWorkHistoryNote() ?? '',
  note: applicant.getNote() ?? '',
})
