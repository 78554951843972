import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ChangeWebInterviewGuideOfWebInterviewsRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type ChangeWebInterviewGuideOfWebInterviewsRequestValue = {
  guideGuid: string
  webInterviewGuids: string[]
}

export function useRequestChangeWebInterviewGuideOfWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestChangeWebInterviewGuideOfWebInterviews = useCallback(
    async (value: ChangeWebInterviewGuideOfWebInterviewsRequestValue) => {
      const metadata = await buildMetadata()

      const req = new ChangeWebInterviewGuideOfWebInterviewsRequest()
      req.setWebInterviewGuidsList(value.webInterviewGuids)
      req.setWebInterviewGuideGuid(value.guideGuid)

      return callBizHuttBffService(
        'changeWebInterviewGuideOfWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestChangeWebInterviewGuideOfWebInterviews,
  }
}
