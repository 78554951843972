import { useMutation, useQueryClient } from 'react-query'
import {
  RequestSelectionRecInterviewAnswerRequestValue,
  useRequestRequestSelectionRecInterviewAnswer,
} from '@/services/bffService/useRequestRequestSelectionRecInterviewAnswer'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSendRequestSelectionRecInterviewAnswerMail() {
  const queryClient = useQueryClient()
  // 提出依頼メール送信にもRequestRequestSelectionRecInterviewAnswerを使う
  const { requestRequestSelectionRecInterviewAnswer } =
    useRequestRequestSelectionRecInterviewAnswer()

  return useMutation(
    (value: RequestSelectionRecInterviewAnswerRequestValue) =>
      requestRequestSelectionRecInterviewAnswer(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.applicantGuid])
      },
    }
  )
}
