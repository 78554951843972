import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateSelectionStepResultCommentRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateSelectionStepResultCommentRequestValue = {
  selectionGuid: string
  selectionStepGuid: string
  comment: string
}
export function useRequestUpdateSelectionStepResultComment() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateSelectionStepResultComment = useCallback(
    async (value: UpdateSelectionStepResultCommentRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateSelectionStepResultCommentRequest()
      req.setSelectionGuid(value.selectionGuid)
      req.setSelectionStepGuid(value.selectionStepGuid)
      req.setComment(value.comment)

      return callBizHuttBffService(
        'updateSelectionStepResultComment',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateSelectionStepResultComment,
  }
}
