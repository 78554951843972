import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { AssignSelectionStepToApplicantWithRecInterviewTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type AssignSelectionStepToApplicantWithRecInterviewTemplateRequestValue =
  {
    applicantGuid: string
    selectionStepMasterGuid: string
    recInterviewTemplateGuid: string
  }

export function useRequestAssignSelectionStepToApplicantWithRecInterviewTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestAssignSelectionStepToApplicantWithRecInterviewTemplate =
    useCallback(
      async (
        value: AssignSelectionStepToApplicantWithRecInterviewTemplateRequestValue
      ) => {
        const metadata = await buildMetadata()

        const req =
          new AssignSelectionStepToApplicantWithRecInterviewTemplateRequest()

        req.setApplicantGuid(value.applicantGuid)
        req.setSelectionStepMasterGuid(value.selectionStepMasterGuid)
        req.setRecInterviewTemplateGuid(value.recInterviewTemplateGuid)

        return callBizHuttBffService(
          'assignSelectionStepToApplicantWithRecInterviewTemplate',
          [req, metadata],
          loggedInStaff?.guid
        )
      },
      [buildMetadata, loggedInStaff?.guid]
    )

  return {
    requestAssignSelectionStepToApplicantWithRecInterviewTemplate,
  }
}
