import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateSelectionStepResultRequestValue,
  useRequestUpdateSelectionStepResult,
} from '@/services/bffService/useRequestUpdateSelectionStepResult'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateSelectionStepResult() {
  const queryClient = useQueryClient()
  const { requestUpdateSelectionStepResult } =
    useRequestUpdateSelectionStepResult()

  return useMutation(
    (value: UpdateSelectionStepResultRequestValue) =>
      requestUpdateSelectionStepResult(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.applicantGuid])
      },
    }
  )
}
