import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { Icon, SecondaryLinkTxt, theme, Txt } from '@blue-agency/rogue'
import { Switch, Case } from 'react-if'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { EllipsisTxt, useEllipsisTxt } from '@/components/EllipsisTxt'
import {
  TabWrapper,
  TabsHeader,
  Tabs,
  TabsInner,
  TabItem,
  TabContentsWrapper,
  TabContents,
  TabNavPrevButton,
  TabNavNextButton,
} from '@/components/RecInterviewTemplate'
import { useSelectionRecInterviewTab } from '@/hooks/useSelectionRecInterviewTab'
import {
  QuestionAnswerPair,
  TextQuestion,
  VideoQuestion,
  VideoFileTranscodeStatus,
  VideoFileTranscriptionStatus,
} from '@/services/applicantService'

type Props = PropsWithClassName<{
  questionAnswerPairsList: QuestionAnswerPair[]
  canWatchAnswer: boolean
  beforeSubmitAnswer: boolean
  organizationRecInterviewTranscriptionSetting: boolean
}>

export const SelectionRecInterviewQuestions: React.VFC<Props> = (props) => {
  // 回答閲覧権限がない時でも、変換中/変換失敗の場合は優先して表示する必要がある
  const permissionDeniedStatus = props.canWatchAnswer
    ? undefined
    : getPrimaryVideoStatus(props.questionAnswerPairsList)

  const tabHooks = useSelectionRecInterviewTab({
    questionAnswerPairsList: props.questionAnswerPairsList,
  })
  const {
    currentTabIndex,
    showTabNavPrev,
    showTabNavNext,
    nextNavAction,
    prevNavAction,
    tabOverflow,
    tabsRef,
    setCurrentTabIndex,
    videoPlaybackRate,
    setVideoPlaybackRate,
  } = tabHooks

  return (
    <div className={props.className}>
      {!props.beforeSubmitAnswer && permissionDeniedStatus && (
        <Wrapper>
          <Switch>
            <Case condition={permissionDeniedStatus === 'transcoded'}>
              <TranscodedStatusTxt>
                <LockIconWrapper>
                  <Icon name="lock" />
                </LockIconWrapper>
                回答を閲覧する権限がありません
              </TranscodedStatusTxt>
            </Case>
            <Case condition={permissionDeniedStatus === 'transcoding'}>
              <TranscodingStatus />
            </Case>
            <Case condition={permissionDeniedStatus === 'failed'}>
              <FailedStatus />
            </Case>
          </Switch>
        </Wrapper>
      )}

      <TabWrapper>
        <TabsHeader>
          {tabOverflow && (
            <TabNavPrevButton active={showTabNavPrev} onClick={prevNavAction}>
              <Icon name="arrow" size="m" />
            </TabNavPrevButton>
          )}
          <Tabs isOverflow={tabOverflow} ref={tabsRef}>
            <TabsInner isOverflow={tabOverflow}>
              {props.questionAnswerPairsList.map((q, i) => (
                <TabItem
                  key={i}
                  onClick={() => setCurrentTabIndex(i)}
                  current={i === currentTabIndex}
                >
                  {q.format === 'text' && q.text?.question?.name}
                  {q.format === 'video' && q.video?.question?.name}
                </TabItem>
              ))}
            </TabsInner>
          </Tabs>
          {tabOverflow && (
            <TabNavNextButton active={showTabNavNext} onClick={nextNavAction}>
              <Icon name="arrow" size="m" />
            </TabNavNextButton>
          )}
        </TabsHeader>
        <TabContentsWrapper isPc>
          {props.questionAnswerPairsList.map((q, i) => (
            <TabContents key={q.questionGuid} current={i === currentTabIndex}>
              {q.format === 'text' && (
                <TextQuestionComponent
                  key={q.questionGuid}
                  index={i}
                  textQuestionAnsertPair={q.text!}
                  canWatchAnswer={props.canWatchAnswer}
                  beforeSubmitAnswer={props.beforeSubmitAnswer}
                />
              )}
              {q.format === 'video' && (
                <VideoQuestionComponent
                  key={q.questionGuid}
                  index={i}
                  videoQuestionAnsertPair={q.video!}
                  canWatchAnswer={props.canWatchAnswer}
                  beforeSubmitAnswer={props.beforeSubmitAnswer}
                  currentTabIndex={currentTabIndex}
                  videoPlaybackRate={videoPlaybackRate}
                  setVideoPlaybackRate={setVideoPlaybackRate}
                  organizationRecInterviewTranscriptionSetting={
                    props.organizationRecInterviewTranscriptionSetting
                  }
                />
              )}
            </TabContents>
          ))}
        </TabContentsWrapper>
      </TabWrapper>
    </div>
  )
}

type TextQuestionProps = {
  index: number
  textQuestionAnsertPair: TextQuestion
  canWatchAnswer: boolean
  beforeSubmitAnswer: boolean
}

const TextQuestionComponent: React.VFC<TextQuestionProps> = (props) => {
  const content = props.textQuestionAnsertPair.answer?.content
  const answerCondition = getAnswerCondition({
    status: undefined,
    content,
    canWatchAnswer: props.canWatchAnswer,
    beforeSubmitAnswer: props.beforeSubmitAnswer,
  })
  return (
    <Wrapper>
      <Title>
        <Bold>問 {props.index + 1}</Bold>
        {props.textQuestionAnsertPair.question?.name}
      </Title>
      <ContentWithShowMore
        content={props.textQuestionAnsertPair.question?.content}
        note={props.textQuestionAnsertPair.question?.note}
      />
      <Switch>
        <Case condition={answerCondition === 'beforeSubmitAnswer'} />
        <Case condition={answerCondition === 'permissionDenied'} />
        <Case condition={answerCondition === 'noAnswer'}>
          <NoAnswerTxt>未回答</NoAnswerTxt>
        </Case>
        <Case condition={answerCondition === 'hasAnswer'}>
          <Answer>{content}</Answer>
        </Case>
      </Switch>
    </Wrapper>
  )
}

type VideoQuestionProps = {
  index: number
  videoQuestionAnsertPair: VideoQuestion
  canWatchAnswer: boolean
  beforeSubmitAnswer: boolean
  currentTabIndex: number
  videoPlaybackRate: number
  setVideoPlaybackRate: (value: number) => void
  organizationRecInterviewTranscriptionSetting: boolean
}
const VideoQuestionComponent: React.VFC<VideoQuestionProps> = ({
  index,
  videoQuestionAnsertPair,
  canWatchAnswer,
  beforeSubmitAnswer,
  currentTabIndex,
  videoPlaybackRate,
  setVideoPlaybackRate,
  organizationRecInterviewTranscriptionSetting,
}) => {
  const status = videoQuestionAnsertPair.answer?.transcodeStatus
  const filekey = videoQuestionAnsertPair.answer?.fileKey
  const videoFileTranscription =
    videoQuestionAnsertPair.answer?.videoFileTranscription

  const answerCondition = getAnswerCondition({
    status,
    content: filekey,
    canWatchAnswer: canWatchAnswer,
    beforeSubmitAnswer: beforeSubmitAnswer,
  })

  const videoRef = useRef<HTMLVideoElement>(null)

  const changePlaybackRate = useCallback(
    (event: Event) => {
      const target = event.target as HTMLVideoElement
      setVideoPlaybackRate(target.playbackRate)
    },
    [setVideoPlaybackRate]
  )

  useEffect(() => {
    const ref = videoRef.current
    if (!ref) return
    ref.addEventListener('ratechange', changePlaybackRate)
    return () => {
      ref.removeEventListener('ratechange', changePlaybackRate)
    }
  }, [changePlaybackRate])

  useEffect(() => {
    const ref = videoRef.current
    if (!ref) return

    if (index !== currentTabIndex) {
      // アクティブなタブでなければ一時停止させる
      ref.pause()
    } else {
      // アクティブなタブなら再生させる
      ref.play()
    }
    ref.playbackRate = videoPlaybackRate
  }, [currentTabIndex, index, videoPlaybackRate])

  const hiddenVideoFileTranscription = useMemo(
    () =>
      !organizationRecInterviewTranscriptionSetting ||
      status === VideoFileTranscodeStatus.TRANSCODING ||
      (videoFileTranscription?.videoFileTranscriptionStatus ===
        VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_COMPLETED &&
        !videoFileTranscription?.transcription),
    [
      status,
      videoFileTranscription,
      organizationRecInterviewTranscriptionSetting,
    ]
  )

  return (
    <>
      <Wrapper>
        <Title>
          <Bold>問 {index + 1}</Bold>
          {videoQuestionAnsertPair.question?.name}
        </Title>
        <ContentWithShowMore
          content={videoQuestionAnsertPair.question?.content}
          note={videoQuestionAnsertPair.question?.note}
        />
        <Switch>
          <Case condition={answerCondition === 'beforeSubmitAnswer'} />
          <Case condition={answerCondition === 'permissionDenied'} />
          <Case condition={answerCondition === 'noAnswer'}>
            <NoAnswerTxt>未回答</NoAnswerTxt>
          </Case>
          <Case condition={answerCondition === 'hasAnswer'}>
            <Switch>
              <Case condition={status === VideoFileTranscodeStatus.TRANSCODED}>
                <VideoWrapper
                  bgColor={
                    hiddenVideoFileTranscription
                      ? 'transparent'
                      : theme.color.gray[5]
                  }
                >
                  <Video
                    src={filekey}
                    controlsList="nodownload"
                    controls
                    ref={videoRef}
                  />
                  {!hiddenVideoFileTranscription && (
                    <VideoFileTranscriptionComponent
                      videoFileTranscriptionStatus={
                        videoFileTranscription?.videoFileTranscriptionStatus
                      }
                      transcription={videoFileTranscription?.transcription}
                    />
                  )}
                </VideoWrapper>
              </Case>
              <Case condition={status === VideoFileTranscodeStatus.TRANSCODING}>
                <>
                  <Margin />
                  <TranscodingStatus />
                </>
              </Case>
              <Case condition={status === VideoFileTranscodeStatus.FAILED}>
                <>
                  <Margin />
                  <FailedStatus />
                </>
              </Case>
            </Switch>
          </Case>
        </Switch>
      </Wrapper>
    </>
  )
}

type VideoFileTranscriptionProps = {
  videoFileTranscriptionStatus: VideoFileTranscriptionStatus | undefined
  transcription: string | undefined
}

const VideoFileTranscriptionComponent: React.FC<
  VideoFileTranscriptionProps
> = ({ videoFileTranscriptionStatus, transcription }) => {
  return (
    <VideoTransliteration>
      <VideoTransliterationTitle>文字起こし（β版）</VideoTransliterationTitle>
      <VideoTransliterationBody>
        {videoFileTranscriptionStatus ===
        VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_COMPLETED ? (
          <VideoTransliterationSuccess>
            {transcription}
          </VideoTransliterationSuccess>
        ) : videoFileTranscriptionStatus ===
          VideoFileTranscriptionStatus.VIDEO_FILE_TRANSCRIPTION_STATUS_FAILED ? (
          <VideoTransliterationIconWrapper color={theme.color.red.iM}>
            <Icon name="exclamation" />
            <VideoTransliterationIconBody>
              <VideoTransliterationIconTitle>
                文字起こしができませんでした
              </VideoTransliterationIconTitle>
              音声が入ってない、音声が小さい、動画が短すぎる
              などの可能性があります。
            </VideoTransliterationIconBody>
          </VideoTransliterationIconWrapper>
        ) : (
          <VideoTransliterationIconWrapper color="#3182CE">
            <Icon name="exclamation" />
            <VideoTransliterationIconBody>
              <VideoTransliterationIconTitle>
                文字起こし準備中...
              </VideoTransliterationIconTitle>
              しばらくお待ちください。
            </VideoTransliterationIconBody>
          </VideoTransliterationIconWrapper>
        )}
      </VideoTransliterationBody>
    </VideoTransliteration>
  )
}

const FailedStatus = () => (
  <TranscodedStatusTxt>
    <FaildIconWrapper>
      <Icon name="exclamation" />
    </FaildIconWrapper>
    録画データの変換に失敗しました
  </TranscodedStatusTxt>
)

const TranscodingStatus = () => (
  <TranscodedStatusTxt>録画データを変換しています…</TranscodedStatusTxt>
)

type ContentWithShowMoreProps = {
  content?: string
  note?: string
}
const ContentWithShowMore: React.VFC<ContentWithShowMoreProps> = (props) => {
  const contentEllipsis = useEllipsisTxt()
  const [disableEllipsis, setDisableEllipsis] = useState(false)
  const showMore = useCallback(() => setDisableEllipsis(true), [])

  return (
    <ContentWithShowMoreWrapper>
      <Content
        ref={contentEllipsis.ellipsisTxtRef}
        disableEllipsis={disableEllipsis}
      >
        {props.content}
      </Content>
      {props.note && disableEllipsis && <Note>{props.note}</Note>}
      {shouldShowMore({
        isContentEllipsized: contentEllipsis.isEllipsized,
        disableEllipsis,
        hasNote: !!props.note,
      }) && (
        <ShowMore onClick={showMore}>
          <SecondaryLinkTxt size="s">もっと見る</SecondaryLinkTxt>
        </ShowMore>
      )}
    </ContentWithShowMoreWrapper>
  )
}

export const shouldShowMore = (params: {
  isContentEllipsized: boolean
  disableEllipsis: boolean
  hasNote: boolean
}): boolean => {
  if (params.disableEllipsis) return false
  if (params.hasNote) return true
  if (params.isContentEllipsized) return true
  return false
}

/**
 * 採用Aの変換ステータス/権限無しバッジ表示のためのステータス取得
 * @param selectionRecInterviewPairList
 * @returns 'failed' | 'transcoding' | 'transcoded' をこの優先度順で返す
 */
export const getPrimaryVideoStatus = (
  selectionRecInterviewPairList: QuestionAnswerPair[]
): 'failed' | 'transcoding' | 'transcoded' => {
  let isTranscoding = false

  for (const p of selectionRecInterviewPairList) {
    if (p.format !== 'video') continue

    if (
      p.video?.answer?.transcodeStatus === VideoFileTranscodeStatus.TRANSCODING
    ) {
      isTranscoding = true
    }

    if (p.video?.answer?.transcodeStatus === VideoFileTranscodeStatus.FAILED) {
      return 'failed'
    }
  }

  if (isTranscoding) return 'transcoding'
  return 'transcoded'
}

type GetAnswerConditionParam = {
  status: VideoFileTranscodeStatus | undefined
  content: string | undefined
  canWatchAnswer: boolean
  beforeSubmitAnswer: boolean
}
const getAnswerCondition = (
  param: GetAnswerConditionParam
): 'beforeSubmitAnswer' | 'noAnswer' | 'permissionDenied' | 'hasAnswer' => {
  if (param.beforeSubmitAnswer) return 'beforeSubmitAnswer'
  if (!param.canWatchAnswer) return 'permissionDenied'
  if (param.content || param.status) return 'hasAnswer'
  return 'noAnswer'
}

const Wrapper = styled.div`
  &&:not(:first-of-type) {
    margin-top: 20px;
  }
`

const Title = styled(Txt).attrs({
  size: 's',
  color: theme.color.white[1],
})`
  display: inline-block;
  background-color: ${theme.color.navy[1]};
  border-radius: 4px;
  padding: 2px 7px;
`

const Content = styled(EllipsisTxt).attrs({ size: 's' })`
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const Note = styled(Txt).attrs({
  size: 's',
  color: theme.color.gray[1],
})`
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const Bold = styled.span`
  font-weight: bold;
  margin-right: 4px;
`

const Answer = styled(Txt).attrs({ size: 's' })`
  border-radius: 4px;
  background-color: ${theme.color.gray[5]};
  margin-top: 8px;
  padding: 6px;
  max-width: 1012px;
  line-height: 18px;
`

const VideoWrapper = styled.div<{ bgColor: string }>`
  display: flex;
  background-color: ${({ bgColor }) => bgColor};
  padding: 8px;
  margin-top: 8px;
`

const Video = styled.video`
  max-width: 515px;
  max-height: 330px;
  width: 100%;
`
const VideoTransliteration = styled.div`
  margin-left: 8px;
  border: solid 1px ${theme.color.gray[4]};
  border-radius: 4px;
  background-color: ${theme.color.white[1]};
  line-height: 21px;
  flex: 1;
`

const VideoTransliterationTitle = styled(Txt).attrs({
  bold: true,
  size: 'm',
})`
  border-bottom: solid 1px ${theme.color.gray[4]};
  padding: 12px;
`

const VideoTransliterationBody = styled.div`
  padding: 12px;
`

const VideoTransliterationSuccess = styled(Txt).attrs({
  size: 'm',
})`
  overflow-y: auto;
`
const VideoTransliterationIconWrapper = styled.div<{ color: string }>`
  display: flex;
  svg {
    font-size: ${theme.fontSize.xl};
    color: ${({ color }) => color};
  }
`

const VideoTransliterationIconTitle = styled.div`
  font-weight: bold;
`

const VideoTransliterationIconBody = styled.div`
  margin-left: 14px;
  flex: 1;
`

const TranscodedStatusTxt = styled(Txt)`
  display: inline-flex;
  background-color: ${theme.color.gray[4]};
  border-radius: 4px;
  padding: 0 10px;
  height: 40px;
  align-items: center;
`

const Margin = styled.div`
  height: 8px;
`

const FaildIconWrapper = styled.span`
  color: ${theme.color.red[2]};
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const LockIconWrapper = styled.span`
  color: ${theme.color.navy[1]};
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const NoAnswerTxt = styled(Txt).attrs({ size: 'm', bold: true })`
  margin-top: 4px;
  margin-left: 2px;
`

const ContentWithShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ShowMore = styled.div.attrs({ role: 'button' })`
  align-self: flex-end;
`
