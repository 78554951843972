import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { Form } from '@/services/questionTemplateService/formSchema'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useCreateQuestionTemplate } from './useCreateQuestionTemplate'

type Step = 'input' | 'confirm'

function useNewQuestionTemplatePage() {
  const { mutateAsync: createQuestionTemplate, isLoading } =
    useCreateQuestionTemplate()
  const [step, setStep] = useState<Step>('input')
  const [completedForm, setCompletedForm] = useState<Form>()
  const history = useHistory()

  const backToInput = useCallback(() => {
    setStep('input')
  }, [])

  const completeInput = useCallback((form: Form) => {
    setCompletedForm(form)
    setStep('confirm')
  }, [])

  const register = useCallback(async () => {
    if (!completedForm) {
      throw new Error('completedForm is undefined')
    }

    try {
      await createQuestionTemplate({
        ...completedForm,
        videoFileKey: completedForm.videoFile?.key,
      })
    } catch (e) {
      Sentry.captureException(e)

      commonErrorToast()
      return
    }

    toast('設問を登録しました')
    history.push(INTERNAL_PATHS.questionTemplates)
  }, [completedForm, createQuestionTemplate, history])

  return {
    step,
    completeInput,
    backToInput,
    completedForm,
    register,
    isRegisterLoading: isLoading,
  }
}

export const NewQuestionTemplatePageContainer = createContainer(
  useNewQuestionTemplatePage
)
