import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import styled from 'styled-components'
import { SelectionStepAppendix } from '@/services/applicantService'

type Props = {
  selectionStepName: string
  selectionStepGuid: string
  registrationRoute: string
  registerTime?: Date
  lastAssessTime?: Date
  appendix?: SelectionStepAppendix
  close: () => void
}

export const DisplaySelectionStepDetailModal: React.VFC<Props> = ({
  selectionStepName,
  selectionStepGuid,
  registrationRoute,
  registerTime,
  lastAssessTime,
  appendix,
  close,
}) => {
  const modalTitle = `選考詳細（${selectionStepName}）`
  const lastAssessTimeLabel = '選考結果\n最終更新日時'
  const externalSelectionNameLabel = '連携先\n選考ステップ名'
  const externalSelectionIdLabel = '連携先\n選考ステップID'
  return (
    <Modal active title={modalTitle} onClose={close} size="m">
      <ModalContent>
        <Row>
          <Title>登録経路</Title>
          <Txt>{registrationRoute}</Txt>
        </Row>
        <Row>
          <Title>選考ID</Title>
          <Txt>{selectionStepGuid}</Txt>
        </Row>
        <Row>
          <Title>登録日時</Title>
          <Txt>{dateToString(registerTime)}</Txt>
        </Row>
        <Row>
          <Title>{lastAssessTimeLabel}</Title>
          <Txt>{dateToString(lastAssessTime)}</Txt>
        </Row>
        {appendix && (
          <>
            <Row>
              <Title>連携先求人名</Title>
              <Txt>{appendix.externalRecruitmentName}</Txt>
            </Row>
            <Row>
              <Title>{externalSelectionNameLabel}</Title>
              <Txt>{appendix.externalSelectionName}</Txt>
            </Row>
            <Row>
              <Title>連携先求人ID</Title>
              <Txt>{appendix.externalRecruitmentId}</Txt>
            </Row>
            <Row>
              <Title>{externalSelectionIdLabel}</Title>
              <Txt>{appendix.externalSelectionId}</Txt>
            </Row>
          </>
        )}
      </ModalContent>
      <WeakColorSeparator />
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={close}
          comlinkPushParams={{
            action: 'click_close_button_on_display_selection_step_detail_modal',
          }}
        >
          閉じる
        </TertiaryButton>
      </ButtonArea>
    </Modal>
  )
}

const dateToString = (date: Date | undefined) => {
  if (date === undefined) return ''
  return format(date, 'yyyy/MM/dd HH:mm')
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 40px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`

const Title = styled(Txt).attrs({ bold: true })`
  width: 164px;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`
