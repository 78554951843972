import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteRecInterviewQuestionTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type DeleteRecInterviewQuestionTemplateRequestValue = {
  guid: string
}

export function useRequestDeleteRecInterviewQuestionTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteRecInterviewQuestionTemplate = useCallback(
    async (value: DeleteRecInterviewQuestionTemplateRequestValue) => {
      const metadata = await buildMetadata()

      const req = new DeleteRecInterviewQuestionTemplateRequest()

      req.setGuid(value.guid)

      return callBizHuttBffService(
        'deleteRecInterviewQuestionTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestDeleteRecInterviewQuestionTemplate,
  }
}
