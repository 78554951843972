import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetWebInterviewRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetWebInterview() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetWebInterview = useCallback(
    async (webInterviewGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetWebInterviewRequest()

      req.setWebInterviewGuid(webInterviewGuid)

      return callBizHuttBffService(
        'getWebInterview',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetWebInterview }
}
