// 応募者詳細右部分にある各種情報入力モーダル部分
export type InputType =
  | 'currentSelectionStepResult'
  | 'selectionStepResultComment'
  | undefined

export type Store = {
  input: InputType
  selectedSelectionStepGuid?: string
}

export const initialState: Store = {
  input: undefined,
  selectedSelectionStepGuid: undefined,
}

export type Action =
  | {
      type: 'OPEN_CURRENT_SELECTION_STEP_RESULT'
      payload: { selectionStepGuid: string }
    }
  | { type: 'CLOSE_CURRENT_SELECTION_STEP_RESULT' }
  | {
      type: 'OPEN_SELECTION_STEP_RESULT_COMMENT'
      payload: { selectionStepGuid: string }
    }
  | { type: 'CLOSE_SELECTION_STEP_RESULT_COMMENT' }

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case 'OPEN_CURRENT_SELECTION_STEP_RESULT':
      return {
        ...state,
        input: 'currentSelectionStepResult',
        selectedSelectionStepGuid: action.payload.selectionStepGuid,
      }
    case 'CLOSE_CURRENT_SELECTION_STEP_RESULT':
    case 'CLOSE_SELECTION_STEP_RESULT_COMMENT':
      return {
        ...state,
        input: undefined,
        selectedSelectionStepGuid: undefined,
      }
    case 'OPEN_SELECTION_STEP_RESULT_COMMENT':
      return {
        ...state,
        input: 'selectionStepResultComment',
        selectedSelectionStepGuid: action.payload.selectionStepGuid,
      }
    default:
      return state
  }
}

export default reducer
