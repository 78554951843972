import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
  usePromptBeforeUnload,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import * as C from '@/components/RecInterviewTemplate'
import { AuthzContainer } from '@/services/authzService'
import {
  InputQuestions,
  InputIntro,
  InputOutro,
  Confirm,
  InputBasicInfo,
  RecInterviewTemplateForm,
} from './steps'
import { useRecInterviewTemplateNewPage } from './useRecInterviewTemplateNewPage'
import { Step } from './useStep'

export const RecInterviewTemplateNewPage = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('CreateRecInterviewTemplate')

  usePromptBeforeUnload()

  const { step, form, onClickPrev, onClickNext } =
    useRecInterviewTemplateNewPage()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={() => {}} onKeyDown={() => {}}>
        <Page.Header>
          <Page.HeaderTitle>録画面接テンプレート登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <Content step={step} form={form} />
        </Page.Body>
        <Page.Bottom>
          <Buttons
            step={step}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
          />
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

type ContentProps = {
  step: Step
  form: RecInterviewTemplateForm
}
export const Content: React.VFC<ContentProps> = ({ step, ...params }) => {
  switch (step) {
    case 'basicInfo':
      return <InputBasicInfo {...params} />
    case 'intro':
      return <InputIntro {...params} />
    case 'questions':
      return <InputQuestions {...params} />
    case 'outro':
      return <InputOutro {...params} />
    case 'confirm':
      return <Confirm {...params} />
    default:
      throw new Error('unexpected step')
  }
}

type ButtonsProps = {
  step: Step
  onClickNext: () => void
  onClickPrev: () => void
}
const Buttons: React.VFC<ButtonsProps> = ({
  step,
  onClickNext,
  onClickPrev,
}) => {
  switch (step) {
    case 'basicInfo':
      return (
        <Page.BottomButtons>
          <C.BackToListButton
            comlinkPushParams={{ action: 'click_back_button' }}
            onClick={onClickPrev}
          />
          <C.NextInputButton
            comlinkPushParams={{ action: `click_next_button_on_${step}` }}
            onClick={onClickNext}
          />
        </Page.BottomButtons>
      )
    case 'outro':
      return (
        <Page.BottomButtons>
          <C.PrevInputButton
            comlinkPushParams={{ action: `click_prev_button_on_${step}` }}
            onClick={onClickPrev}
          />
          <C.ConfirmButton
            comlinkPushParams={{ action: `click_next_button_on_${step}` }}
            onClick={onClickNext}
          />
        </Page.BottomButtons>
      )
    case 'confirm':
      return (
        <Page.BottomButtons>
          <C.PrevInputButton
            comlinkPushParams={{ action: `click_prev_button_on_${step}` }}
            onClick={onClickPrev}
          />
          <C.SubmitButton
            comlinkPushParams={{
              action: 'click_submit_rec_interview_template_button',
            }}
            onClick={onClickNext}
          />
        </Page.BottomButtons>
      )
  }
  return (
    <Page.BottomButtons>
      <C.PrevInputButton
        comlinkPushParams={{ action: `click_prev_button_on_${step}` }}
        onClick={onClickPrev}
      />
      <C.NextInputButton
        comlinkPushParams={{ action: `click_next_button_on_${step}` }}
        onClick={onClickNext}
      />
    </Page.BottomButtons>
  )
}
