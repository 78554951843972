import React from 'react'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'

export const EncodedStatus = (
  props: PropsWithClassName
): React.ReactElement | null => {
  const pageCtx = WebInterviewPageContainer.useContainer()

  if (pageCtx.isLoading) {
    return null
  }

  return (
    <Content className={props.className}>
      <Recording
        title="Web面接録画"
        src={pageCtx.webInterview.recordingUrl}
        allow="fullscreen"
      />
    </Content>
  )
}

const Content = styled.div`
  height: 100%;
  width: 100%;
`

const Recording = styled.iframe`
  height: 100%;
  width: 100%;
`
