import * as yup from 'yup'
import type { TestOptions } from 'yup'

type FromType = TestOptions['options']['from']

export type Form = {
  name: string
  parts: Part[]
}

type Part = {
  startOffsetMin: number
  title: string
  description: string
}

type InputPart = { [key in keyof Part]: string }

const validationMessage = {
  input: '入力してください',
  length50: '50文字以内で入力してください',
  length2000: '2000文字以内で入力してください',
  durationRange: '0〜179の数字を入力してください',
  durationInteger: '0〜179の整数を入力してください',
  durationUnsorted:
    '各パートの開始時間の値が上から「小さい順」に並べてください',
}

export const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .required(validationMessage.input)
      .max(50, validationMessage.length50),
    parts: yup
      .array(
        yup
          .object()
          .shape({
            startOffsetMin: yup
              .number()
              .typeError(validationMessage.durationRange)
              .required(validationMessage.input)
              .min(0, validationMessage.durationRange)
              .max(179, validationMessage.durationRange)
              .integer(validationMessage.durationInteger)
              .test({
                name: 'offset sorted',
                message: validationMessage.durationUnsorted,
                test: (_value, ctx) => {
                  // ctx.options の型定義に index が無いので、無理矢理対応する
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const index = (ctx.options as any).index
                  if (typeof index !== 'number') {
                    return false
                  }
                  if (index === 0) {
                    return true
                  }

                  // ctx の型定義に from が無いので、無理矢理対応する
                  // https://github.com/jquense/yup/issues/1445
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const from = (ctx as any).from as FromType
                  if (from === undefined) {
                    return false
                  }
                  const grandParent = from[1]
                  if (grandParent === undefined) {
                    return false
                  }

                  const parts = grandParent.value.parts as InputPart[]
                  if (parts.length === 0) {
                    return false
                  }

                  // 1つ前のパートの開始時間と比較して、昇順になっているかを確認する
                  const prevOffset = parseInt(parts[index - 1].startOffsetMin)
                  const currentOffset = parseInt(parts[index].startOffsetMin)
                  if (isNaN(prevOffset) || isNaN(currentOffset)) {
                    // prevOffset, currentOffset のどちらかが空欄だった場合、このバリデーションの結果としては true としておく
                    // （別バリデーションにひっかかる）
                    return true
                  }
                  return currentOffset > prevOffset
                },
              }),
            title: yup
              .string()
              .required('入力してください')
              .max(50, validationMessage.length50),
            description: yup
              .string()
              .required('入力してください')
              .max(2000, validationMessage.length2000),
          })
          .defined()
      )
      .min(1)
      .defined(),
  })
  .defined()
