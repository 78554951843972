import React from 'react'
import {
  PageLayoutWithGlonavi,
  DetailPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { LinkToInterviewGuideExamples } from '@/components/LinkToInterviewGuideExamples'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Content } from './Content'
import { DeleteModal } from './DeleteModal'
import { useWebInterviewGuide } from './useWebInterviewGuide'

export const WebInterviewGuidePage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('GetWebInterviewGuide')

  const { webInterviewGuideGuid } = useParams<{
    webInterviewGuideGuid: string
  }>()

  const { guide, isLoading, deleteModal, copyGuideGUID } = useWebInterviewGuide(
    webInterviewGuideGuid
  )

  if (isLoading || guide === undefined) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>面接ガイド詳細</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToInterviewGuideExamples pageType="interview_guide_detail_page" />
            </SubTextWrapper>
          </Left>
          <Page.HeaderRight>
            <LinkTo to={INTERNAL_PATHS.webInterviewGuides}>
              <Txt>一覧に戻る</Txt>
            </LinkTo>
          </Page.HeaderRight>
        </Page.Header>
        <Page.Body>
          <Content
            guide={guide}
            openDeleteModal={deleteModal.open}
            copyGuideGUID={copyGuideGUID}
          />
        </Page.Body>
      </Page.Wrapper>
      <DeleteModal
        active={deleteModal.active}
        onClose={deleteModal.close}
        guide={guide}
      />
    </PageLayoutWithGlonavi>
  )
}

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const LinkTo = styled(Link)`
  color: ${theme.color.navy[1]};
`
