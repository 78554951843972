import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { DetailValidationError } from '@/components/CSVUploadValidationErrorModal'
import { useModal } from '@/hooks/useModal'
import { ApplicantForm } from '@/services/applicantService'
import { CSVData } from '@/services/csvService'
import { targetOptions } from '../options'
import type { MappingTarget, Form } from '../types'
import { validateApplicants, buildApplicants } from './applicants'
import { hasFamilyName } from './validateTargets'

type Props = {
  csvData: CSVData
  initialFormData?: Form
  goNext: (formData: Form, applicantsData: ApplicantForm[]) => void
}

export const useMappingPage = (props: Props) => {
  const validationErrorModal = useModal()
  const [validationErrors, setValidationErrors] = useState<
    DetailValidationError[]
  >([])
  const topErrorRef = useRef<HTMLDivElement>(null)
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<
    string | undefined
  >()
  const {
    register,
    control,
    formState,
    watch,
    getValues,
    setValue,
    handleSubmit,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: props.initialFormData ?? {
      targets: [],
      selectionSharing: 'SHARING_TYPE_UNSHARED',
    },
  })

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.targets && hasFamilyName(value.targets)) {
        setErrorMessageOnTop(undefined)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const watchTargets = useWatch({ control, name: 'targets' })
  const countByTarget = useMemo(() => {
    const record = watchTargets.reduce<Record<MappingTarget, number>>(
      (prev, current) => {
        if (!current) {
          return prev
        }

        return {
          ...prev,
          [current]: (prev[current] ?? 0) + 1,
        }
      },
      {} as Record<MappingTarget, number>
    )
    return { ...record }
  }, [watchTargets])

  const getTargetOptions = useCallback(
    (index) => {
      return targetOptions.filter((t) => {
        return (
          watchTargets[index] === t.value ||
          t.max === undefined ||
          (countByTarget[t.value] ?? 0) < t.max
        )
      })
    },
    [countByTarget, watchTargets]
  )

  const onSubmit = useMemo(() => {
    const submit = async () => {
      const targets = getValues().targets
      if (!hasFamilyName(targets)) {
        setErrorMessageOnTop(
          '姓は必須項目です。いずれかの項目に姓を選択してください。'
        )
        topErrorRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        return
      }
      const applicants = buildApplicants(targets, props.csvData)
      const result = await validateApplicants(applicants)
      if (result !== true) {
        setValidationErrors(result)
        validationErrorModal.open()
        return
      }

      props.goNext(getValues(), applicants)
    }
    return handleSubmit(submit)
  }, [props, getValues, handleSubmit, validationErrorModal])

  const closeValidationErrorModal = useCallback(() => {
    validationErrorModal.close()
    setValidationErrors([])
  }, [validationErrorModal])

  return {
    register,
    control,
    errors: formState.errors,
    isSubmitting: formState.isSubmitting,
    onSubmit,
    watch,
    getValues,
    setValue,
    getTargetOptions,
    topErrorRef,
    errorMessageOnTop,
    validationErrorModalActive: validationErrorModal.active,
    validationErrors,
    closeValidationErrorModal,
  }
}
