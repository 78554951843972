import React from 'react'
import { ErrorBox, theme, Txt } from '@blue-agency/rogue'
import { DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import * as C from '../modalComponents'
import { useDeleteModal } from './useDeleteModal'

type Props = {
  active: boolean
  onClose: () => void
  guid: string
  name: string
}

export const DeleteModal: React.VFC<Props> = (props) => {
  const ctx = useDeleteModal({
    onSuccessDelete: props.onClose,
    guid: props.guid,
  })

  const onClose = () => {
    ctx.setFailedInUseError(false)
    props.onClose()
  }

  return (
    <C.Modal
      active={props.active}
      title="選考ステップの削除"
      size="m"
      onClose={onClose}
    >
      <Box padding="20px" paddingBottom="40px">
        {ctx.failedInUseError && (
          <Box marginBottom="20px">
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                この選考はすでに利用されているため、削除することはできません。
              </Txt>
            </ErrorBox>
          </Box>
        )}
        <Txt>
          「{props.name}」を削除します。
          <br />
          削除したデータを復元することはできません。
        </Txt>
      </Box>
      <C.Bottom>
        <C.CancelButton
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_delete_selection_step_master_modal',
          }}
        >
          キャンセル
        </C.CancelButton>
        <SubmitButton
          onClick={ctx.deleteSelectionStepMaster}
          comlinkPushParams={{
            action: 'click_delete_button_on_delete_selection_step_master_modal',
          }}
        >
          削除
        </SubmitButton>
      </C.Bottom>
    </C.Modal>
  )
}

const SubmitButton = styled(DangerButton).attrs({
  widthSize: 'L1',
})`
  margin-left: 20px;
`
