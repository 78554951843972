import * as yup from 'yup'
import { assertNever } from '@/assertions'
import { DetailValidationError } from '@/components/CSVUploadValidationErrorModal'
import { ApplicantForm } from '@/services/applicantService'
import { CSVData } from '@/services/csvService'
import { Targets } from '../types'

const formSchema: yup.SchemaOf<ApplicantForm> = yup
  .object()
  .shape({
    externalApplicantId: yup
      .string()
      .matches(
        /^[\x20-\x7e]{0,128}$/,
        '連携先応募者IDは半角英数記号128文字以下で登録してください。'
      ),
    familyName: yup
      .string()
      .max(255, '姓は255文字以内で登録してください。')
      .required('姓は必須項目です。データを登録してください。'),
    givenName: yup.string().max(255, '名は255文字以内で登録してください。'),
    familyNameKana: yup
      .string()
      .max(255, '姓（カナ）は255文字以内で登録してください。'),
    givenNameKana: yup
      .string()
      .max(255, '名（カナ）は255文字以内で登録してください。'),
    email: yup
      .string()
      .max(255, 'メールアドレスは半角英数記号255文字以下で登録してください。')
      .email('メールアドレスが不正です。'),
    phoneNumber: yup
      .string()
      .matches(
        /^[0-9]{0,20}$/,
        '電話番号は半角数字20文字以下で登録してください。'
      ),
    note: yup
      .string()
      .max(100_000, '備考は100,000文字以下で登録してください。'),
  })
  .defined()

export type ValidationResult = true | DetailValidationError[]

export async function validateApplicants(
  applicants: ApplicantForm[]
): Promise<ValidationResult> {
  const validationErrors: DetailValidationError[] = []
  for (let i = 0; i < applicants.length; i++) {
    await formSchema
      .validate(applicants[i], { abortEarly: false })
      .catch((e) => {
        if (e.errors) {
          for (const error of e.errors) {
            validationErrors.push({
              rowNumber: i + 1,
              message: error,
            })
          }
        }
      })
  }
  if (validationErrors.length === 0) {
    return true
  }
  return validationErrors
}

export function buildApplicants(targets: Targets, csv: CSVData) {
  const applicants: ApplicantForm[] = []
  csv.csvBody.forEach((row) => {
    applicants.push(
      targets.reduce<ApplicantForm>((res, target, index) => {
        switch (target) {
          case 'familyName':
          case 'givenName':
          case 'familyNameKana':
          case 'givenNameKana':
          case 'email':
          case 'externalApplicantId':
            return {
              ...res,
              [target]: row[index],
            }
          case 'phoneNumber':
            const phoneNumber = row[index]
              .replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
              })
              .replace(/[-ー 　]/g, '')
            return {
              ...res,
              phoneNumber,
            }
          case 'note':
            const note = `[${csv.csvHeader[index]}]${row[index]}`
            return {
              ...res,
              note:
                res['note'] !== undefined ? `${res['note']}\n${note}` : note,
            }
          case '':
          case undefined:
            return res
          default:
            assertNever(target)
            return res
        }
      }, {} as ApplicantForm)
    )
  })
  return applicants
}
