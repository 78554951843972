import styled from 'styled-components'
import { GuideList } from './GuideList'
import { WebInterviewGuidesPageContainer } from './webInterviewGuidesPageContainer'

export function Content() {
  const { guides } = WebInterviewGuidesPageContainer.useContainer()
  return (
    <GuideListWrapper>
      <GuideList guides={guides} />
    </GuideListWrapper>
  )
}

const GuideListWrapper = styled.div`
  padding-left: 248px;
  flex: 1 0 auto;
`
