import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateRecInterviewQuestionTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { RecInterviewQuestionTemplateInput } from '@blue-agency/proton/im'
import { QuestionFormat } from '@/services/questionTemplateService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateRecInterviewQuestionTemplateRequestValue = {
  name: string
  content: string
  note?: string
  videoFileKey?: string
  format: QuestionFormat
  maxRecCount?: string
  maxDurationSeconds?: string
}

export function useRequestCreateRecInterviewQuestionTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateRecInterviewQuestionTemplate = useCallback(
    async (value: CreateRecInterviewQuestionTemplateRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateRecInterviewQuestionTemplateRequest()

      const template = new RecInterviewQuestionTemplateInput()
      template.setName(value.name)
      template.setContent(value.content)
      if (value.note) template.setNote(value.note)
      if (value.videoFileKey) template.setVideoFileKey(value.videoFileKey)

      switch (value.format) {
        case 'text':
          template.setText(new RecInterviewQuestionTemplateInput.Text())
          break
        case 'video':
          const video = new RecInterviewQuestionTemplateInput.Video()
          if (value.maxDurationSeconds)
            video.setMaxDurationSeconds(parseInt(value.maxDurationSeconds))
          if (value.maxRecCount)
            video.setMaxRecCount(parseInt(value.maxRecCount))
          template.setVideo(video)
          break
      }

      req.setQuestionTemplate(template)

      return callBizHuttBffService(
        'createRecInterviewQuestionTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateRecInterviewQuestionTemplate,
  }
}
