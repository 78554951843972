import { useCallback, useEffect, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import type {
  Assignee,
  Assignment,
  AssigneeRole,
} from '@/services/webInterviewService'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'
import { useAllAssigneeCandidates } from './useAllAssigneeCandidates'
import { useUpdateWebInterviewAssignment } from './useUpdateWebInterviewAssignment'

export type Form = {
  assignment: Assignment
  assignees: Assignee[]
}

const formSchema: yup.SchemaOf<Form> = yup.object().shape({
  assignment: yup.mixed<Assignment>().defined(),
  assignees: yup.array<Assignee[]>().of(
    yup.object({
      guid: yup.string().defined(),
      role: yup
        .mixed<AssigneeRole>()
        .oneOf(['interviewer', 'viewer'])
        .defined(),
    })
  ),
})

export function useWebInterviewAssignmentForm() {
  const { webInterview } = WebInterviewPageContainer.useContainer()
  assert(webInterview)

  const allAssigneeCandidates = useAllAssigneeCandidates()

  const { control, register, handleSubmit, formState, watch, reset } =
    useForm<Form>({
      resolver: yupResolver(formSchema),
      mode: 'onSubmit',
      defaultValues: {
        assignment: webInterview.assignment,
        assignees: webInterview.assignees,
      },
    })

  // 担当設定が更新されたときにフォームのデフォルト値もリセットする
  useEffect(() => {
    reset({
      assignment: webInterview.assignment,
      assignees: webInterview.assignees,
    })
  }, [webInterview, reset])

  const { mutateAsync: mutateWebInterviewAssignment } =
    useUpdateWebInterviewAssignment()

  const submit_ = useCallback(
    async (form: Form) => {
      try {
        await mutateWebInterviewAssignment({
          webInterviewGuid: webInterview.guid,
          assignment: form.assignment,
          assignees: form.assignees,
        })
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }

      toast('担当設定を変更しました')
    },
    [webInterview, mutateWebInterviewAssignment]
  )

  const submit = useMemo(() => handleSubmit(submit_), [handleSubmit, submit_])

  return {
    control,
    register,
    submit,
    isSubmitting: formState.isSubmitting,
    currentAssignment: watch('assignment'),
    allAssigneeCandidates,
  }
}
