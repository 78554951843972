import { BackgroundJob } from '@/services/applicantService'
import { DetailBaseModal } from './DetailBaseModal'
import { ReqSelectionRecInterviewErrorDetailModal } from './ReqSelectionRecInterviewErrorDetailModal'

export type ModalBaseProps = {
  job: BackgroundJob
  onClose: () => void
}
export const ErrorDetailModal: React.VFC<ModalBaseProps> = (props) => {
  if (props.job.status !== 'failed') {
    throw new Error('invalid job status for opening error detail modal')
  }
  switch (props.job.job) {
    case 'reqRecInterviewAnswer':
      return <ReqSelectionRecInterviewErrorDetailModal {...props} />
    case 'updateToFinished':
    case 'updateToNotPassed':
      return (
        <DetailBaseModal
          {...props}
          error="処理が全件失敗しました。"
          modalSize="l"
        />
      )
    default:
      throw new Error('no corresponding job type')
  }
}
