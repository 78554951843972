import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import styled from 'styled-components'

type Props = {
  loadTime: Date
  onReload: () => void
  isRefetching: boolean
  staffGuid: string
}

export const ReloadButton: React.VFC<Props> = (props) => {
  return (
    <ReloadButtonWrapper>
      <TertiaryButton
        widthSize="S"
        heightSize="S"
        onClick={props.onReload}
        comlinkPushParams={{
          action: 'click_reload_button_on_control_center',
          metadata: { staffGuid: props.staffGuid },
        }}
        disabled={props.isRefetching}
      >
        更新
      </TertiaryButton>
      <ReloadTimeWrapper>
        <Txt size="s" color={theme.color.gray[1]}>
          {format(props.loadTime, 'HH:mm')}更新
        </Txt>
      </ReloadTimeWrapper>
    </ReloadButtonWrapper>
  )
}

const ReloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ReloadTimeWrapper = styled.div`
  margin-top: 4px;
`
