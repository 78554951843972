import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestDeleteRecInterviewQuestionTemplate,
  DeleteRecInterviewQuestionTemplateRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteRecInterviewQuestionTemplate() {
  const queryClient = useQueryClient()
  const { requestDeleteRecInterviewQuestionTemplate } =
    useRequestDeleteRecInterviewQuestionTemplate()

  return useMutation(
    (value: DeleteRecInterviewQuestionTemplateRequestValue) =>
      requestDeleteRecInterviewQuestionTemplate(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.questionTemplates)
      },
    }
  )
}
