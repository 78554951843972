import { useCallback, useMemo, useState } from 'react'
import { SearchApplicantsRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { DropdownOption } from '@blue-agency/rogue/im'
import { SearchCondition } from '@/services/applicantService'
import { trimEmptyWordsCondition } from '../useApplicantsPage'
import { useSearchApplicants } from '../useSearchApplicants'
import { SearchApplicantsModal as Modal } from './SearchApplicantsModal'
import { useListSelectionStepMasters } from './useListSelectionStepMasters'

const dummyPageSize = 1
const dummyPage = 1
const dummyOrderBy = SearchApplicantsRequest.OrderBy.ORDER_BY_REGISTER_TIME_ASC

type Props = {
  condition: SearchCondition
  initialTotalLength: number
  onClose: () => void
  onSearch: (condition: SearchCondition) => void
}
export const SearchApplicantsModal: React.VFC<Props> = (props) => {
  const { data: ssmsData, isLoading } = useListSelectionStepMasters()

  const selectionStepMasters = useMemo<DropdownOption[]>(() => {
    return (
      ssmsData?.getSelectionStepMastersList().map((s) => ({
        value: s.getGuid(),
        label: s.getName(),
      })) ?? []
    )
  }, [ssmsData])

  const [condition, setCondition] = useState<SearchCondition>(props.condition)

  const { data } = useSearchApplicants({
    pageSize: dummyPageSize,
    page: dummyPage,
    orderBy: dummyOrderBy,
    condition: trimEmptyWordsCondition(condition),
  })

  const totalLength = useMemo(() => {
    return data?.getTotalLength() ?? props.initialTotalLength
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onChange = useCallback(async (condition: SearchCondition) => {
    setCondition(condition)
  }, [])

  // NOTE: 選考ステップマスターがLoadできていないと初期値をセットできないので待つ
  if (isLoading) {
    return null
  }

  return (
    <Modal
      condition={props.condition}
      totalLength={totalLength}
      selectionStepMasters={selectionStepMasters}
      onClose={props.onClose}
      onSearch={props.onSearch}
      onChange={onChange}
    />
  )
}
