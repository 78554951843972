import { useMutation, useQueryClient } from 'react-query'
import { useRequestCloseVideoSeminar } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCloseVideoSeminar(videoSeminarGuid: string) {
  const queryClient = useQueryClient()
  const { requestCloseVideoSeminar } = useRequestCloseVideoSeminar()

  return useMutation(() => requestCloseVideoSeminar(videoSeminarGuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.videoSeminars)
      queryClient.invalidateQueries([QUERY_KEY.videoSeminar, videoSeminarGuid])
    },
  })
}
