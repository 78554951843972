import { useCallback, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { add, set } from 'date-fns'
import { useHistory } from 'react-router'
import { formatTime } from '@/services/recInterviewTemplateService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Form } from './steps/formSchema'
import { useRecInterviewTemplateForm } from './steps/useRecInterviewTemplateForm'
import {
  toRecInterviewTemplateInputValue,
  useCreateRecInterviewTemplate,
} from './useCreateRecInterviewTemplate'
import { useStep } from './useStep'

export const defaultDueDays = 14
export const defaultDueHour = 17

// TODO: JST以外の環境に対応する
export const newDueDatetime = (now: Date) =>
  add(
    set(now, {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }),
    {
      days: defaultDueDays,
      hours: defaultDueHour,
    }
  )

export const useRecInterviewTemplateNewPage = () => {
  const history = useHistory()

  const moveToListPage = useCallback(
    () => history.push(INTERNAL_PATHS.recInterviewTemplates),
    [history]
  )

  const step = useStep()

  const initial = useMemo<Form>(() => {
    const maxDatetime = newDueDatetime(new Date())
    const defaultDue = {
      type: 'time',
      time: {
        time: formatTime(maxDatetime),
        hour: maxDatetime.getHours(),
      },
      duration: {
        days: defaultDueDays,
        hour: defaultDueHour,
      },
    }
    return {
      name: '',
      allowRecordedVideo: 'allowed',
      due: defaultDue,
      introMessage: '',
      introVideoFileKey: '',
      introVideoURL: '',
      questions: [
        {
          guid: '',
          required: '1',
        },
      ],
      outroMessage: '',
      outroVideoFileKey: '',
      outroVideoURL: '',
    }
  }, [])
  const form = useRecInterviewTemplateForm(initial)

  const { mutateAsync: createRecInterviewTemplate } =
    useCreateRecInterviewTemplate()

  const onClickPrev = useCallback(() => {
    if (step.step === 'basicInfo') {
      moveToListPage()
      return
    }
    form.updateTemplate()
    form.clearErrors()
    step.prevStep()
  }, [form, step, moveToListPage])

  const onClickNext = useCallback(async () => {
    const isValid = await (async () => {
      switch (step.step) {
        case 'basicInfo':
          return await form.trigger(['allowRecordedVideo', 'due', 'name'])
        case 'intro':
          return await form.trigger('introMessage')
        case 'questions':
          return await form.trigger('questions')
        case 'outro':
          return await form.trigger('outroMessage')
        default:
          return true
      }
    })()
    if (!isValid) return

    if (step.step === 'questions' && !form.checkQuestionDuplicates()) return

    if (step.step === 'confirm') {
      try {
        await createRecInterviewTemplate(
          toRecInterviewTemplateInputValue(form.template)
        )
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }
      toast('録画面接テンプレートを登録しました')
      moveToListPage()
      return
    }

    form.updateTemplate()
    step.nextStep()
  }, [form, step, createRecInterviewTemplate, moveToListPage])

  return {
    step: step.step,
    onClickPrev,
    onClickNext,
    form,
  }
}
