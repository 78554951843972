import { useMutation, useQueryClient } from 'react-query'
import {
  BatchDeleteWebInterviewsRequestValue,
  useRequestBatchDeleteWebInterviews,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchDeleteWebInterviewsMutation() {
  const queryClient = useQueryClient()
  const { requestBatchDeleteWebInterviews } =
    useRequestBatchDeleteWebInterviews()

  return useMutation(
    async (value: BatchDeleteWebInterviewsRequestValue) => {
      await requestBatchDeleteWebInterviews(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.webInterviews)
        queryClient.invalidateQueries(QUERY_KEY.assignedWebInterviews, {
          refetchInactive: true,
        })
        queryClient.invalidateQueries(QUERY_KEY.upcomingWebInterviews)
        queryClient.invalidateQueries(QUERY_KEY.ongoingWebInterviews)
      },
    }
  )
}
