import { Icon, Txt, theme } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Box } from '@/components/Box'

type Props = {
  kind: string
  onSelect: (files: File[]) => void
  constraintTexts?: ReadonlyArray<string>
}

// TODO: Storybook作る
export const FileSelectBox: React.VFC<Props> = ({
  kind,
  onSelect,
  constraintTexts = [],
}) => {
  const dropzone = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop: onSelect,
  })

  return (
    <DragDropBox>
      <FileSelectArea {...dropzone.getRootProps()}>
        <input {...dropzone.getInputProps()} />
        <FileSelectTxtWrapper>
          <Icon name="add-file" width="24" />
          <Txt size="s">ファイルをドラッグ＆ドロップ</Txt>
        </FileSelectTxtWrapper>
        <TertiaryButton
          widthSize="L1"
          comlinkPushParams={{
            action: `click_upload_${kind}_file_button`,
          }}
        >
          <Txt size="s">ファイル選択</Txt>
        </TertiaryButton>
      </FileSelectArea>
      <ConstraintWrapper>
        <Constraint>
          {constraintTexts.map((text) => (
            <ConstraintText key={text}>{text}</ConstraintText>
          ))}
        </Constraint>
      </ConstraintWrapper>
    </DragDropBox>
  )
}

const DragDropBox = styled(Box)`
  padding-top: 8px;
`

const FileSelectTxtWrapper = styled.div`
  display: flex;
`

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;

  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const ConstraintWrapper = styled.div`
  padding: 8px 0;
`

const Constraint = styled.ul`
  list-style-type: none;
`

const ConstraintText = styled.li`
  color: ${theme.color.gray[1]};
  line-height: 18px;
  font-size: ${theme.fontSize.s};
  &::before {
    content: '※';
  }
`
