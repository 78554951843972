import { useEffect, useState } from 'react'
import { InputText, Icon, Txt as _Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/dist/im'
import { DevTool } from '@hookform/devtools'
import styled from 'styled-components'
import * as C from '../formComponents'
import { UseFormResponse } from '../useApplicantForm'
import { BodyDetails } from './BodyDetails'

type Props = UseFormResponse & {
  isDetailsEnabled: boolean
  enableDetails: () => void
}
export const InputBody: React.VFC<Props> = ({
  isDetailsEnabled,
  enableDetails,
  ...useFormResponse
}) => {
  const { formCtx } = useFormResponse
  const [existExternalApplicantId, setExistExternalApplicantId] = useState(true)
  useEffect(() => {
    if (!existExternalApplicantId) return
    setExistExternalApplicantId(formCtx.getValues('externalApplicantId') !== '')
  }, [formCtx, existExternalApplicantId])
  return (
    <Wrapper>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>氏名</C.RowLabelMain>
          <C.RowLabelSub>姓名</C.RowLabelSub>
          <C.RowLabelRequired />
        </C.RowLabel>
        <InputText
          {...formCtx.register('familyName')}
          placeholder="例) 佐藤"
          onBlurError={formCtx.formState.errors.familyName?.message}
        />
        <Spacer />
        <InputText
          {...formCtx.register('givenName')}
          placeholder="例) 太郎"
          onBlurError={formCtx.formState.errors.givenName?.message}
        />
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>カナ</C.RowLabelSub>
        </C.RowLabel>
        <InputText
          {...formCtx.register('familyNameKana')}
          placeholder="例) サトウ"
          onBlurError={formCtx.formState.errors.familyNameKana?.message}
        />
        <Spacer />
        <InputText
          {...formCtx.register('givenNameKana')}
          placeholder="例) タロウ"
          onBlurError={formCtx.formState.errors.givenNameKana?.message}
        />
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>メールアドレス</C.RowLabelMain>
        </C.RowLabel>
        <InputText
          {...formCtx.register('email')}
          placeholder="例) taro.sato@example.com"
          onBlurError={formCtx.formState.errors.email?.message}
          size="ll"
        />
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>電話番号</C.RowLabelMain>
        </C.RowLabel>
        <InputText
          {...formCtx.register('phoneNumber')}
          placeholder="例) 09001234567"
          onBlurError={formCtx.formState.errors.phoneNumber?.message}
        />
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>連携先応募者ID</C.RowLabelMain>
        </C.RowLabel>
        {existExternalApplicantId ? (
          <Txt>{formCtx.getValues('externalApplicantId')}</Txt>
        ) : (
          <InputText
            {...formCtx.register('externalApplicantId', {
              setValueAs: (value) => value.trim(),
            })}
            placeholder="入力してください"
            onBlurError={formCtx.formState.errors.externalApplicantId?.message}
            size="ll"
            autoComplete="off"
          />
        )}
      </C.Row>
      <C.Separator />

      {isDetailsEnabled ? (
        <BodyDetails {...useFormResponse} />
      ) : (
        <ShowDetailsButton
          onClick={enableDetails}
          icon={<Icon name="add" width="14px" />}
          widthSize="L2"
          comlinkPushParams={{
            action: 'show_details_on_register_applicant_page',
          }}
        >
          詳細情報を入力
        </ShowDetailsButton>
      )}

      <DevTool control={formCtx.control} placement="bottom-right" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const Spacer = styled.div`
  width: 20px;
`

const ShowDetailsButton = styled(TertiaryButton)`
  margin: 30px auto;
`

const Txt = styled(_Txt)`
  margin-top: 6px;
`
