import { useMutation, useQueryClient } from 'react-query'
import { useRequestUpdateWebInterviewAssignment } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateWebInterviewAssignment() {
  const queryClient = useQueryClient()
  const { requestUpdateWebInterviewAssignment } =
    useRequestUpdateWebInterviewAssignment()

  return useMutation(requestUpdateWebInterviewAssignment, {
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(QUERY_KEY.webInterviews)
      queryClient.invalidateQueries([
        QUERY_KEY.webInterview,
        req.webInterviewGuid,
      ])
      queryClient.invalidateQueries(QUERY_KEY.assignedWebInterviews, {
        refetchInactive: true,
      })
    },
  })
}
