import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Txt } from '@blue-agency/rogue'
import { DatePicker, Dropdown } from '@blue-agency/rogue/im'
import { isAfter, isSameDay, setHours } from 'date-fns'
import styled from 'styled-components'
import {
  dueDurationHours,
  isValidDeadline,
  bufferHours,
} from '@/services/recInterviewTemplateService'

type Props = {
  deadline: Date
  onChangeDeadline: (date: Date | null) => void
  disabled?: boolean
}
export const DeadlinePicker: React.VFC<Props> = (props) => {
  const [date, setDate] = useState(props.deadline)
  const [hour, setHour] = useState(props.deadline.getHours())
  const [pastError, setPastError] = useState('')

  const handleChangeDate = useCallback(
    (d: Date | null) => {
      setPastError('')
      if (d === null) return
      const deadline = setHours(d, hour)
      setDate(deadline)
      props.onChangeDeadline(setHours(d, hour))
      if (!isValidDeadline(deadline, new Date())) {
        setPastError(`現在時刻から${bufferHours}時間以上先で設定してください`)
      }
    },
    [hour, props]
  )

  const handleChangeHour = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPastError('')
      const hour = Number(e.target.value)
      setHour(hour)
      props.onChangeDeadline(setHours(date, hour))
    },
    [date, props]
  )

  // NOTE: 締切が当日の場合は、選択できる時間が限られる
  const unavailableHours = useMemo(() => {
    return dueDurationHours.filter((hour) => {
      const deadline = setHours(date, hour)
      return !isValidDeadline(deadline, new Date())
    })
  }, [date])

  useEffect(() => {
    setDate(props.deadline)
    setHour(props.deadline.getHours())
    setPastError('')
    if (!isValidDeadline(props.deadline, new Date())) {
      setPastError(`現在時刻から${bufferHours}時間以上先で設定してください`)
    }
  }, [props.deadline])

  return (
    <Wrapper>
      <DatePicker
        name="deadline"
        value={date}
        onChange={handleChangeDate}
        filterDate={(date: Date) => {
          const today = new Date()
          return isSameDay(date, today) || isAfter(date, today)
        }}
        onBlurError={pastError}
        readonly={props.disabled}
        errorNoWrap
      />
      <DateTimeTxt>の</DateTimeTxt>
      <HourDropdown
        options={dueDurationHours.map((v) => ({
          value: v,
          label: v.toString(),
        }))}
        disabledValues={unavailableHours}
        value={hour}
        key={hour} // NOTE:hourのstateが変わった時にdropdownを再レンダリングして更新されるようにする
        onChange={handleChangeHour}
        disabled={props.disabled}
      />
      <DateTimeTxt>時まで</DateTimeTxt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const HourDropdown = styled(Dropdown).attrs({
  size: 'ss',
  onBlurErrorWidth: '500px',
})`
  margin-left: 12px;
`

const DateTimeTxt = styled(Txt).attrs({ size: 's' })`
  margin-left: 14px;
`
