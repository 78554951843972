import { Icon, NewTabLink, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { EXTERNAL_PATHS } from '@/services/urlService'

export const LinkToHelp: React.VFC = () => {
  return (
    <Wrapper>
      <QuestionIcon />
      <Txt size="s">ご利用方法は</Txt>
      <HelpLink
        href={EXTERNAL_PATHS.help.importApplicants}
        action="click_help_link_on_batch_new_applicant"
      >
        ヘルプページ
      </HelpLink>
      <Txt size="s">をご確認ください</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question',
  size: 'm',
})`
  margin-right: 4px;
`

const HelpLink = styled(NewTabLink)`
  color: ${theme.color.blue[2]};
  font-size: ${theme.fontSize.s};
`
