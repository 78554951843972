import { useCallback, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'
import { useUpdateWebInterviewName } from './useUpdateWebInterviewName'

export type Form = {
  name: string
}

export function useWebInterviewNameForm(finishEditing: () => void) {
  const { mutateAsync: mutateWebInterviewName } = useUpdateWebInterviewName()

  const { webInterview } = WebInterviewPageContainer.useContainer()

  const submit_ = useCallback(
    async (form: Form) => {
      try {
        await mutateWebInterviewName({
          // 面接名 dom を表示する前に webInterview を取得していることを信頼する
          webInterviewGuid: webInterview!.guid,
          name: form.name,
        })

        finishEditing()
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }
    },
    [finishEditing, webInterview, mutateWebInterviewName]
  )

  const formSchema: yup.SchemaOf<Form> = yup.object().shape({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: undefined,
  })

  const submit = useMemo(() => handleSubmit(submit_), [handleSubmit, submit_])

  return {
    submit,
    register,
    errorMessage: errors['name']?.message,
  }
}
