export const QUERY_KEY = {
  webInterview: 'webInterview',
  webInterviews: 'webInterviews',
  assignedWebInterviews: 'assignedWebInterviews',
  assigneeCandidates: 'assigneeCandidates',
  loggedInStaff: 'loggedInStaff',
  getGlobalNavigation: 'getGlobalNavigation',
  permittedRpcs: 'permittedRpcs',
  questionTemplates: 'questionTemplates',
  questionTemplate: 'questionTemplate',
  staffs: 'staffs',
  allRecInterviewQuestionTemplates: 'allRecInterviewQuestionTemplates',
  allRecInterviewTemplates: 'allRecInterviewTemplates',
  allRecInterviewTemplatesForOption: 'allRecInterviewTemplatesForOption',
  recInterviewTemplate: 'recInterviewTemplate',
  recInterview: 'recInterview',
  selectionStepMasters: 'selectionStepMasters',
  applicants: 'applicants',
  applicantsBackgroundJobs: 'applicantsBackgroundJobs',
  reqRecInteviewJobFailure: 'reqRecInteviewJobFailure',
  applicant: 'applicant',
  webInterviewGuides: 'webInterviewGuides',
  webInterviewGuide: 'webInterviewGuide',
  selectionSendMailHistories: 'selectionSendMailHistories',
  ongoingWebInterviews: 'ongoingWebInterviews',
  upcomingWebInterviews: 'upcomingWebInterviews',
  selectionStep: 'selectionStep',
  videoSeminars: 'videoSeminars',
  videoSeminar: 'videoSeminar',
  applicantCsvFormat: 'applicantCsvFormat',
  applicantCsvFormats: 'applicantCsvFormats',
  applicantEvents: 'applicantEvents',
  videoSeminarReport: 'videoSeminarReport',
} as const
