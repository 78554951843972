import * as React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

const maxTotalSteps = 5
const maxFutureSteps = 3
const maxNameLength = 7

type Props = {
  stepNames: string[]
  currentIndex: number
  isSelectionFinished?: boolean
}

export const SelectionStep: React.VFC<Props> = (props) => {
  const doneSteps = props.stepNames.slice(0, props.currentIndex)
  const currentStep = props.stepNames[props.currentIndex]
  const futureSteps = props.stepNames.slice(props.currentIndex + 1)
  let hiddenSteps = props.stepNames.slice(
    0,
    props.stepNames.length - maxTotalSteps
  )
  // 選考数が最大数以内の時は全て表示
  if (props.stepNames.length <= maxTotalSteps) {
    return (
      <Wrapper>
        {doneSteps.map((name, i) => {
          return (
            <StepWrapper key={i}>
              <LineWrapper>
                <Dot />
                <Bar />
              </LineWrapper>
              <Name name={name} index={i} />
            </StepWrapper>
          )
        })}
        <StepWrapper key={props.currentIndex}>
          <LineWrapper>
            <Dot isBig={!props.isSelectionFinished} />
            {!props.isSelectionFinished && <Bar isCurrentOrFuture />}
          </LineWrapper>
          <Name name={currentStep} index={props.currentIndex} />
        </StepWrapper>
        {futureSteps.map((name, i) => {
          const index = i + doneSteps.length + 1
          return (
            <StepWrapper key={index}>
              <LineWrapper>
                <Dot isFuture />
                <Bar isCurrentOrFuture />
              </LineWrapper>
              <Name name={name} index={index} />
            </StepWrapper>
          )
        })}
      </Wrapper>
    )
  }

  // 将来の選考数が最大数以内の時は、過去の選考を可能な限り表示
  if (futureSteps.length <= maxFutureSteps) {
    const displayableDoneStepsLength = maxTotalSteps - (futureSteps.length + 1)
    const displayDoneSteps = doneSteps.slice(-displayableDoneStepsLength)
    return (
      <Wrapper>
        <Less hiddenSteps={hiddenSteps} />
        {displayDoneSteps.map((name, i) => {
          return (
            <StepWrapper key={i}>
              <LineWrapper>
                <Dot />
                <Bar />
              </LineWrapper>
              <Name name={name} index={i} />
            </StepWrapper>
          )
        })}
        <StepWrapper key={props.currentIndex}>
          <LineWrapper>
            <Dot isBig={!props.isSelectionFinished} />
            {!props.isSelectionFinished && <Bar isCurrentOrFuture />}
          </LineWrapper>
          <Name name={currentStep} index={props.currentIndex} />
        </StepWrapper>
        {futureSteps.map((name, i) => {
          const index = i + displayDoneSteps.length + 1
          return (
            <StepWrapper key={index}>
              <LineWrapper>
                <Dot isFuture />
                <Bar isCurrentOrFuture />
              </LineWrapper>
              <Name name={name} index={index} />
            </StepWrapper>
          )
        })}
      </Wrapper>
    )
  }

  hiddenSteps = props.stepNames.slice(
    0,
    props.stepNames.length -
      (maxTotalSteps + futureSteps.length - maxFutureSteps)
  )
  return (
    <Wrapper>
      <Less hiddenSteps={hiddenSteps} />
      <StepWrapper key={0}>
        <LineWrapper>
          <Dot />
          <Bar />
        </LineWrapper>
        <Name name={doneSteps[doneSteps.length - 1]} index={0} />
      </StepWrapper>
      <StepWrapper key={1}>
        <LineWrapper>
          <Dot isBig={!props.isSelectionFinished} />
          <Bar isCurrentOrFuture />
        </LineWrapper>
        <Name name={currentStep} index={1} />
      </StepWrapper>
      {futureSteps.slice(0, maxFutureSteps).map((name, i) => {
        const index = i + 2
        return (
          <StepWrapper key={index}>
            <LineWrapper>
              <Dot isFuture />
              {maxFutureSteps === i + 1 ? (
                <More futureSteps={futureSteps} />
              ) : (
                <Bar isCurrentOrFuture />
              )}
            </LineWrapper>
            <Name name={name} index={index} />
          </StepWrapper>
        )
      })}
    </Wrapper>
  )
}

const Name: React.VFC<{ name: string; index: number }> = (props) => {
  return (
    <>
      <Txt
        data-tip
        /**
         * id+indexだけだとStorybookのDocsタブで見たときに他のStoryの同じindexでもTooltipが表示されてしまう
         * 1画面に1つしかSelectionStepは存在しない想定なので本来は不要だがnameも付けている
         */
        data-for={
          TOOLTIP_ID_MAP.applicantDetailStepName + props.index + props.name
        }
      >
        {ellipsizeName(props.name, maxNameLength)}
      </Txt>
      {props.name.length > maxNameLength && (
        <Tooltip
          id={TOOLTIP_ID_MAP.applicantDetailStepName + props.index + props.name}
          arrowPosition="topLeft"
        >
          <TooltipTxt>{props.name}</TooltipTxt>
        </Tooltip>
      )}
    </>
  )
}

const More: React.VFC<{ futureSteps: string[] }> = (props) => {
  return (
    <>
      <MoreDots data-tip data-for={TOOLTIP_ID_MAP.applicantDetailStepNames}>
        <MoreDot />
        <MoreDot />
        <MoreDot />
      </MoreDots>
      <Tooltip
        id={TOOLTIP_ID_MAP.applicantDetailStepNames}
        arrowPosition="topLeft"
      >
        <TooltipTxt>
          {props.futureSteps.reduce((prev, current, i) =>
            i > 2 ? `${prev}・${current}\n` : ''
          )}
        </TooltipTxt>
      </Tooltip>
    </>
  )
}

const Less: React.VFC<{ hiddenSteps: string[] }> = (props) => {
  return (
    <>
      <LessDots
        data-tip
        data-for={
          TOOLTIP_ID_MAP.leadApplicantDetailStepNames + props.hiddenSteps.length
        }
      >
        <LessDot />
        <LessDot />
        <LessDot />
      </LessDots>
      <Tooltip
        id={
          TOOLTIP_ID_MAP.leadApplicantDetailStepNames + props.hiddenSteps.length
        }
        arrowPosition="topRight"
      >
        <TooltipTxt>
          {props.hiddenSteps.map((stepName) => `・${stepName}\n`)}
        </TooltipTxt>
      </Tooltip>
    </>
  )
}

const ellipsizeName = (name: string, maxLength: number) => {
  return name.length > maxLength ? name.slice(0, maxLength) + '...' : name
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LineWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`

const Dot = styled.div<{ isBig?: boolean; isFuture?: boolean }>`
  width: ${({ isBig }) => (isBig ? '12' : '8')}px;
  height: ${({ isBig }) => (isBig ? '12' : '8')}px;
  z-index: 1;
  border-radius: 50%;
  background-color: ${({ isFuture }) =>
    isFuture ? theme.color.gray[3] : theme.color.navy[1]};
`

const Bar = styled.div<{ isCurrentOrFuture?: boolean }>`
  width: 110px;
  height: 4px;
  margin-left: -1px;
  margin-right: -1px;
  background-color: ${({ isCurrentOrFuture }) =>
    isCurrentOrFuture ? theme.color.gray[3] : theme.color.navy[1]};
`

const MoreDots = styled.div`
  display: flex;
  padding: 12px 5px;
`

const MoreDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${theme.color.gray[3]};
  margin-left: 2px;
`

const LessDots = styled.div`
  display: flex;
  padding: 12px 5px;
  margin-bottom: 18px;
`
const LessDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${theme.color.navy[1]};
  margin-left: 2px;
`

const TooltipTxt = styled(Txt).attrs({ size: 'm' })`
  line-height: 18px;
`
