export const tabs = ['upcoming', 'ongoing'] as const
export type TabType = (typeof tabs)[number]

export type Interviewer =
  | {
      joined: true
      soraClientId: string
      staffGuid: string
      familyName: string
      fullName: string
      iconColorCode: string
      joinTime: Date
      assigned: boolean
    }
  | {
      joined: false
      staffGuid: string
      familyName: string
      fullName: string
      iconColorCode: string
    }

export type Interviewee = {
  soraClientId: string
  displayName: string
  joinTime: Date
}

export type RequestingInterviewee = {
  displayName: string
  requestTime: Date
}

export type UpcomingInterview = {
  guid: string
  scheduledStartTime: Date
  scheduledFinishTime?: Date
  name: string
  interviewers: Interviewer[]
  requestingInterviewees: RequestingInterviewee[]
  latestIntervieweeLogTimeDiff?: number
  isPassed: (currentDate: Date) => boolean // 開始予定時刻を過ぎている
  isPassedRecently: (currentDate: Date) => boolean // 開始予定時刻を過ぎて60分経っていない
}

export type OngoingInterview = {
  guid: string
  name: string
  scheduledStartTime: Date
  scheduledFinishTime: Date
  startTime: Date
  finishTime?: Date
  interviewers: Interviewer[]
  interviewees: Interviewee[]
  requestingInterviewees: RequestingInterviewee[]
}
