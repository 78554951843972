import {
  RecInterviewDueDuration,
  RecInterviewTemplateInput,
} from '@blue-agency/proton/web/v2/im/rec_interview_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export type RecInterviewTemplateInputValue = Omit<
  RecInterviewTemplateInput.AsObject,
  'dueTime' | 'questionsList'
> & {
  dueTimestamp?: number
  questions: {
    guid: string
    required: boolean
  }[]
}

export const toRecInterviewTemplateInput = (
  value: RecInterviewTemplateInputValue
) => {
  const input = new RecInterviewTemplateInput()

  input.setName(value.name)

  input.setAllowRecordedVideo(value.allowRecordedVideo)

  if (value.dueTimestamp !== undefined) {
    input.setDueTime(new Timestamp().setSeconds(value.dueTimestamp / 1000))
  } else if (value.dueDuration !== undefined) {
    const duration = new RecInterviewDueDuration()
    duration.setDays(value.dueDuration?.days!)
    duration.setHour(value.dueDuration?.hour!)
    input.setDueDuration(duration)
  }

  if (value.introMessage !== undefined)
    input.setIntroMessage(value.introMessage)

  if (value.introVideoFileKey !== undefined)
    input.setIntroVideoFileKey(value.introVideoFileKey)

  value.questions.forEach((question) => {
    const q = new RecInterviewTemplateInput.Question()
    q.setQuestionTemplateGuid(question.guid)
    q.setRequired(question.required)
    input.addQuestions(q)
  })

  if (value.outroMessage !== undefined)
    input.setOutroMessage(value.outroMessage)

  if (value.outroVideoFileKey !== undefined)
    input.setOutroVideoFileKey(value.outroVideoFileKey)

  return input
}
