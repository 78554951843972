import { ChangeEvent, useCallback, useState } from 'react'

type BatchAction = 'delete' | 'changeInterviewGuide'
type BatchActionOption = {
  value: BatchAction
  label: string
}
export const batchActionOptions: BatchActionOption[] = [
  { value: 'delete', label: '面接を削除する' },
  { value: 'changeInterviewGuide', label: '面接ガイドを設定する' },
]

export const useBatchAction = () => {
  const [batchAction, setBatchAction] = useState<BatchAction | undefined>(
    undefined
  )
  const onChangeBatchAction = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      switch (e.target.value) {
        case 'delete':
          setBatchAction(e.target.value)
          break
        case 'changeInterviewGuide':
          setBatchAction(e.target.value)
          break
        default:
          throw new Error('No corresponding batch action')
      }
    },
    []
  )

  return {
    batchAction,
    onChangeBatchAction,
  }
}
