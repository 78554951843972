import { useMemo, useCallback } from 'react'
import { useQueryParams, NumberParam, StringParam } from 'use-query-params'
import { validateNumberParamQuery } from '@/services/queryParamService'
import { SearchCondition } from './searchApplicantTypes'
import { SelectionStatus, SelectionSubStatus } from './selectionEnums'

export const useApplicantsQueryParam = () => {
  const [query, setQuery] = useQueryParams({
    cp: NumberParam, // currentPage
    ob: NumberParam, // orderBy
    st: NumberParam, // scrollTop
    sc: StringParam, // searchCondition
  })

  const currentPageParam = useMemo(() => {
    return validateNumberParamQuery(query.cp, { shouldBeInt: true })
  }, [query.cp])

  const orderByParam = useMemo(() => {
    return validateNumberParamQuery(query.ob, { shouldBeInt: true })
  }, [query.ob])

  const scrollTopParam = useMemo(() => {
    return validateNumberParamQuery(query.st)
  }, [query.st])

  const searchConditionParam = useMemo(() => {
    if (query.sc !== null && query.sc !== undefined) {
      return decompressSearchCondition(JSON.parse(query.sc))
    }
  }, [query.sc])

  const setQueryParams = useCallback(
    (
      currentPage: number,
      orderBy: number,
      searchCondition: SearchCondition
    ) => {
      setQuery(
        {
          cp: currentPage,
          ob: orderBy,
          sc: JSON.stringify(compressSearchCondition(searchCondition)),
        },
        'replaceIn'
      )
    },
    [setQuery]
  )

  const setScrollTopParam = useCallback(
    (scrollTop: number) => {
      setQuery({ st: scrollTop }, 'pushIn')
    },
    [setQuery]
  )

  return {
    currentPageParam,
    orderByParam,
    scrollTopParam,
    searchConditionParam,
    setScrollTopParam,
    setQueryParams,
  }
}

type CompressedSearchCondition = {
  k?: string
  s?: SelectionStatus[]
  b?: SelectionSubStatus[]
  n?: string
  r?: {
    f?: Date
    t?: Date
  }
  c?: string
  e?: string
  m?: 't' | 'f'
  er?: string
  es?: string
}

const compressSearchCondition = (
  s: SearchCondition
): CompressedSearchCondition => ({
  k: s.keywords?.join(' '),
  s: s.statuses,
  b: s.subStatuses,
  n: s.notes?.join(' '),
  r: {
    f: s.registerTime?.from,
    t: s.registerTime?.to,
  },
  c: s.currentSelectionStepMasterGuid,
  e: s.externalApplicantId,
  m: s.mailFailure ? 't' : 'f',
  er: s.currentSelectionStepExternalRecruitmentName,
  es: s.currentSelectionStepExternalSelectionName,
})

const decompressSearchCondition = (
  c: CompressedSearchCondition
): SearchCondition => ({
  keywords: c.k?.trim().split(/\s+/) ?? [],
  statuses: c.s || [],
  subStatuses: c.b || [],
  notes: c.n?.trim().split(/\s+/) ?? [],
  registerTime: {
    from: c.r?.f !== undefined ? new Date(c.r.f) : undefined,
    to: c.r?.t !== undefined ? new Date(c.r.t) : undefined,
  },
  currentSelectionStepMasterGuid: c.c,
  externalApplicantId: c.e,
  mailFailure: c.m === 't',
  currentSelectionStepExternalRecruitmentName: c.er ?? '',
  currentSelectionStepExternalSelectionName: c.es ?? '',
})
