import { useCallback, useEffect, useState, useMemo } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

export const tabs = ['profile', 'selection', 'event'] as const
export type TabType = (typeof tabs)[number]
const defaultTab: TabType = 'selection'
export type SwitchTab = (tab: TabType) => void

export const useTabs = () => {
  const { tabParam, setQuery } = useSelectionRecInterviewQueryParam()
  const [selectedTab, setSelectedTab] = useState(tabParam)

  const switchTab = useCallback<SwitchTab>((tab: TabType) => {
    setSelectedTab(tab)
  }, [])

  useEffect(() => {
    setQuery(
      {
        t: selectedTab,
      },
      'replaceIn'
    )
  }, [selectedTab, setQuery])

  return {
    selectedTab,
    switchTab,
  }
}

const useSelectionRecInterviewQueryParam = () => {
  const [query, setQuery] = useQueryParams({
    t: StringParam, // tab: TabType
  })

  const tabParam = useMemo(() => {
    return getValidTabParam(query.t ?? null)
  }, [query.t])

  return {
    tabParam,
    setQuery,
  }
}

const getValidTabParam = (param: string | null): TabType => {
  if (param === 'profile' || param === 'selection' || param === 'event') {
    return param
  }
  return defaultTab
}
