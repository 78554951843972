import { useCallback, useMemo } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  durationMinutesSchema,
  scheduledStartTimeShape,
  buildScheduledStartTime,
} from '@/services/webInterviewService'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'
import { useUpdateWebInterviewDuration } from './useUpdateWebInterviewDuration'
import { useUpdateWebInterviewScheduledStartTime } from './useUpdateWebInterviewScheduledStartTime'

export type Form = {
  durationMinutes: number
  scheduledDate?: Date

  // 選択される値は数値だが、
  // - dropdown で値が選択されなかった場合、空文字列となること
  // - 入力確認画面から入力画面に戻ったときに、dropdownのデフォルト値を設定する必要があること
  // などの理由により、string型で取り扱うほうが都合が良い
  scheduledHour: string

  // 選択される値は数値だが、
  // - dropdown で値が選択されなかった場合、空文字列となること
  // - 入力確認画面から入力画面に戻ったときに、dropdownのデフォルト値を設定する必要があること
  // などの理由により、string型で取り扱うほうが都合が良い
  scheduledMin: string
}

const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    durationMinutes: durationMinutesSchema,
    ...scheduledStartTimeShape,
  })
  .defined()

export function useUpdateWebInterviewScheduleAndDurationForm(
  closeModal: () => void
) {
  const { webInterview } = WebInterviewPageContainer.useContainer()
  assert(webInterview)

  const { register, handleSubmit, formState, watch, getValues, setValue } =
    useForm<Form>({
      resolver: yupResolver(formSchema),
      mode: 'onChange',
      defaultValues: {
        durationMinutes: webInterview.durationMinutes,
        scheduledDate: webInterview.scheduledStartTime,
        scheduledHour:
          webInterview.scheduledStartTime?.getHours()?.toString() ?? '',
        scheduledMin:
          webInterview.scheduledStartTime?.getMinutes()?.toString() ?? '',
      },
    })

  const { mutateAsync: updateWebInterviewDuration } =
    useUpdateWebInterviewDuration()

  const { mutateAsync: updateWebInterviewScheduledStartTime } =
    useUpdateWebInterviewScheduledStartTime()

  const submit = useCallback(
    async (form: Form) => {
      try {
        await Promise.all([
          updateWebInterviewDuration({
            webInterviewGuid: webInterview.guid,
            durationMinutes: form.durationMinutes,
          }),
          updateWebInterviewScheduledStartTime({
            webInterviewGuid: webInterview.guid,
            scheduledStartTime: buildScheduledStartTime(form),
          }),
        ])
      } catch (e) {
        Sentry.captureException(e)

        commonErrorToast()
        return
      }

      closeModal()
      toast('予定日時 / 目安時間を変更しました')
    },
    [
      webInterview,
      updateWebInterviewDuration,
      updateWebInterviewScheduledStartTime,
      closeModal,
    ]
  )

  const onSubmit = useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  const onDateChange = useCallback(
    (date: Date | null) => {
      setValue('scheduledDate', date ?? undefined)
    },
    [setValue]
  )

  return {
    onSubmit,
    register,
    isSubmitting: formState.isSubmitting,
    errors: formState.errors,
    watch,
    getValues,
    onDateChange,
  }
}
