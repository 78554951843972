import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  completedCount: number
}

export const Completed: React.VFC<Props> = ({ completedCount }) => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>Web面接一括更新</Page.HeaderTitle>
          </Left>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="登録完了">
            <BodyWrapper>
              <BodyTxt>
                <CompletedCount>{completedCount}</CompletedCount>
                件のWeb面接の更新が完了しました
              </BodyTxt>
            </BodyWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.webInterviews}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action:
                    'click_back_to_web_interviews_on_upload_web_interviews_csv_completed',
                }}
              >
                一覧へ戻る
              </TertiaryButton>
            </NoStyledLink>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Left = styled.div`
  display: flex;
`

const BodyWrapper = styled.div`
  margin: 48px 20px;
  text-align: center;
`

const BodyTxt = styled(Txt).attrs({
  size: 'l',
})``

const CompletedCount = styled.span`
  font-size: ${theme.fontSize.xxl};
  color: ${theme.color.green[4]};
  margin-left: 2px;
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
