import React, { useCallback, useState } from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { ErrorBox, Icon, theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { LinkToHelp } from '@/pages/WebInterviewUpdateByFilePage/LinkToHelp'
import { validatePrecondition } from '@/pages/WebInterviewUpdateByFilePage/csv'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { CSVInputData } from '../types'

type Props = {
  initialInputData?: CSVInputData
  goNext: (csvInputData: CSVInputData) => void
}

export const Input: React.VFC<Props> = ({ initialInputData, goNext }) => {
  const [inputData, setInputData] = useState(initialInputData)
  const [errorMessageOnTop, setErrorMessageOnTop] = useState<string>()

  const onDrop = useCallback(
    async (files: File[]) => {
      if (
        inputData &&
        !window.confirm(
          '既に選択されている取込ファイルが存在します。\n上書きしてよろしいでしょうか？'
        )
      ) {
        return
      }

      setErrorMessageOnTop(undefined)
      setInputData(undefined)
      try {
        const result = await validatePrecondition(files)

        if (result.type === 'error') {
          setErrorMessageOnTop(result.message)
          return
        }

        setInputData({
          fileName: result.fileName,
          csvData: result.csvData,
        })
      } catch (e) {
        Sentry.captureException(e)
        setErrorMessageOnTop(
          'ファイルの取り込みに失敗しました。ファイルの内容を確認して、再度お試しください。'
        )
        return
      }
    },
    [inputData]
  )

  const dropzone = useDropzone({ multiple: false, maxFiles: 1, onDrop })

  const onNextButtonClick = useCallback(() => {
    if (goNext === undefined) throw new Error('goNext is undefined')
    if (inputData === undefined) throw new Error('inputData is undefined')

    goNext(inputData)
  }, [goNext, inputData])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>Web面接一括更新</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToHelp />
            </SubTextWrapper>
          </Left>
        </Page.Header>
        <Page.Body>
          {errorMessageOnTop && (
            <ErrorBoxWrapper>
              <ErrorBox size="s">{errorMessageOnTop}</ErrorBox>
            </ErrorBoxWrapper>
          )}
          <SettingsSection title="取込設定">
            <FileSelectWrapper>
              <FileSelectTitleTxt>ファイル選択</FileSelectTitleTxt>
              <FileSelectAreaWrapper>
                {inputData && (
                  <FileNameWrapper>
                    <Txt>{inputData.fileName}</Txt>
                  </FileNameWrapper>
                )}
                {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                <FileSelectArea {...dropzone.getRootProps()}>
                  {/* 解消しづらいエラー
                    https://github.com/blue-agency/biz-anakin-front/pull/680#discussion_r593085142
                    @ts-ignore */}
                  <input {...dropzone.getInputProps()} />
                  <FileSelectTxtWrapper>
                    <Icon name="add-file" width="24" />
                    <Txt>ファイルをドラッグ＆ドロップ</Txt>
                  </FileSelectTxtWrapper>
                  <TertiaryButton
                    widthSize="L1"
                    comlinkPushParams={{ action: 'click_upload_csv_button' }}
                  >
                    ファイル選択
                  </TertiaryButton>
                </FileSelectArea>
              </FileSelectAreaWrapper>
            </FileSelectWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <NoStyledLink to={INTERNAL_PATHS.webInterviews}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action:
                    'click_previous_button_on_upload_web_interviews_csv_input',
                }}
              >
                一覧画面に戻る
              </TertiaryButton>
            </NoStyledLink>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_next_button_on_upload_web_interviews_csv_input',
              }}
              disabled={inputData === undefined}
              onClick={onNextButtonClick}
            >
              次の設定画面へ
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const FileSelectWrapper = styled.div`
  margin: 24px 20px;
  display: flex;
`

const FileSelectTitleTxt = styled(Txt).attrs({
  size: 'm',
})``

const FileSelectAreaWrapper = styled.div`
  margin-left: 140px;
`
const FileNameWrapper = styled.div`
  margin-bottom: 16px;
`

const FileSelectArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 460px;
  height: 76px;
  border-radius: 4px;
  border: 1px dashed ${theme.color.gray[2]};
  cursor: pointer;
`

const FileSelectTxtWrapper = styled.div`
  display: flex;
`

const NoStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`
