import React from 'react'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { CompletedPage } from './CompletedPage'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { useForm } from './hooks/useForm'
import { useStep } from './hooks/useStep'

export const NewApplicantPage: React.VFC = () => {
  const { authorizeByRpcName, permittedRpcs } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('CreateApplicant')

  usePromptBeforeUnload()

  const stepCtx = useStep()
  const formCtx = useForm()

  if (permittedRpcs === undefined) return <Loading />

  switch (stepCtx.step) {
    case 'input':
      return (
        <InputPage
          applicantFormCtx={formCtx.applicantFormCtx}
          isDetailsEnabled={formCtx.isDetailsEnabled}
          enableDetails={formCtx.enableDetails}
          toConfirm={stepCtx.toConfirm}
        />
      )
    case 'confirm':
      return (
        <ConfirmPage
          formCtx={formCtx.applicantFormCtx.formCtx}
          isDetailsEnabled={formCtx.isDetailsEnabled}
          disableDetails={formCtx.disableDetails}
          toInput={stepCtx.toInput}
          toCompleted={stepCtx.toCompleted}
        />
      )
    case 'completed':
      return <CompletedPage toInput={stepCtx.toInput} />
  }
}
