import {
  theme,
  Modal,
  InputText,
  Txt,
  ErrorBox,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import {
  FormRow as _FormRow,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

type Props = {
  errorMessage?: string
  onClose: () => void
  onSubmit: (name: string) => void
}

type Form = {
  name: string
}
export const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(30, '30文字以内で入力してください')
      .required('入力してください'),
  })
  .defined()

export const CreateFormatModal: React.VFC<Props> = ({
  errorMessage,
  onClose,
  onSubmit,
}) => {
  const form = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  })

  return (
    <Modal active title="フォーマットの登録" onClose={onClose} size="l">
      <ModalContent>
        {errorMessage && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>{errorMessage}</Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <Txt>今回の設定内容をフォーマットとして登録します。</Txt>
        <Txt>
          次回以降フォーマットを呼出し、設定をスキップすることができます。
        </Txt>
        <FormRow title="フォーマット名" required>
          <InputText
            {...form.register('name')}
            placeholder="例) 採用媒体名"
            onBlurError={form.formState.errors.name?.message}
            size="ll"
            autoComplete="off"
          />
        </FormRow>
      </ModalContent>
      <WeakColorSeparator />
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_create_format_modal',
          }}
        >
          キャンセル
        </TertiaryButton>
        <PrimaryButton
          widthSize="L1"
          disabled={!form.formState.isValid}
          onClick={() => onSubmit(form.getValues('name'))}
          comlinkPushParams={{
            action: 'click_create_format_modal_button',
          }}
        >
          登録
        </PrimaryButton>
      </ButtonArea>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 40px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const FormRow = styled(_FormRow).attrs({
  titleWidth: '140px',
  subtitleWidth: '0',
})`
  margin-top: 16px;
`

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > *:first-child {
    margin-right: 20px;
  }
`
