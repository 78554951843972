import React, { useCallback } from 'react'
import {
  WeakColorSeparator,
  Icon,
  Tooltip,
  Modal,
  RadioGroup as RadioGroup_,
  Txt,
  RequireTxt,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { AssignmentTooltipInner } from '@/components/AssignmentTooltipInner'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'
import { assignmentOptions } from '@/services/webInterviewService'
import { AssigneesForm } from './AssigneesForm'
import { useWebInterviewAssignmentForm } from './useWebInterviewAssignmentForm'

type Props = {
  active: boolean
  onClose: () => void
}

export const WebInterviewAssignmentModal: React.VFC<Props> = ({
  active,
  onClose,
}) => {
  const {
    control,
    register,
    submit,
    isSubmitting,
    currentAssignment,
    allAssigneeCandidates,
  } = useWebInterviewAssignmentForm()

  const onSubmit = useCallback(async () => {
    await submit()
    onClose()
  }, [onClose, submit])

  return (
    <Modal active={active} title="担当設定の変更" size="l" onClose={onClose}>
      <FormContainer>
        <LabelArea>
          <Txt>担当設定</Txt>
          <div
            data-tip
            data-for={TOOLTIP_ID_MAP.assignmentOnWebInterview}
            style={{ cursor: 'pointer' }}
          >
            <Icon name="question" size="l" />
          </div>
          <Tooltip
            id={TOOLTIP_ID_MAP.assignmentOnWebInterview}
            arrowPosition="topLeft"
          >
            <AssignmentTooltipInner />
          </Tooltip>
        </LabelArea>
        <Required />
        <InputArea data-testid="assignment">
          <RadioGroup
            {...register('assignment')}
            options={assignmentOptions}
            direction="row"
          />
          {currentAssignment === 'specified' && (
            <AssigneeFormWrapper>
              <AssigneesForm
                allAssigneeCandidates={allAssigneeCandidates}
                control={control}
                register={register}
              />
            </AssigneeFormWrapper>
          )}
        </InputArea>
      </FormContainer>
      <WeakColorSeparator />
      <ModalFooter>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_web_interview_assignment_button',
          }}
        >
          キャンセル
        </TertiaryButton>
        <PrimaryButton
          widthSize="L1"
          disabled={isSubmitting}
          onClick={onSubmit}
          comlinkPushParams={{
            action: 'click_confirm_web_interview_assignment_button',
          }}
        >
          変更
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > :not(:first-child) {
    margin-left: 20px;
  }
`

const modalMinHeight = 436
const modalMaxHeight = 500
const headerHeight = 39
// セパレータ 1px 込みの高さ
const bottomHeight = 77

const FormContainer = styled.div`
  padding: 20px 20px 40px;
  display: grid;
  grid-template-columns: 140px 28px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'label-area required-area input-area';
  min-height: ${modalMinHeight - headerHeight - bottomHeight}px;
  max-height: ${modalMaxHeight - headerHeight - bottomHeight}px;
  overflow-y: auto;
`

const LabelArea = styled.div`
  grid-area: label-area;
  height: 22px;
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 8px;
  }
`

const Required = styled(RequireTxt).attrs({
  size: 's',
  children: '必須',
})`
  align-items: center;
  display: flex;
  grid-area: required-area;
  height: 22px;
`

const RadioGroup = styled(RadioGroup_)`
  align-items: center;
  display: flex;
  height: 22px;
`

const InputArea = styled.div`
  grid-area: input-area;
`

const AssigneeFormWrapper = styled.div`
  margin-top: 20px;
`
