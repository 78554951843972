import { useRef, useEffect } from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { Pagination } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { PageHeader } from '@/components/PageHeader'
import { AssignedWebInterviewsPageContainer } from './AssignedWebInterviewsContainer'
import type { UsePageState } from './AssignedWebInterviewsContainer'
import { Sidebar } from './Sidebar'
import { WebInterviewsTable } from './WebInterviewsTable'

export const AssignedWebInterviewsPage = provide(
  AssignedWebInterviewsPageContainer
)((): React.ReactElement => {
  const ctx = AssignedWebInterviewsPageContainer.useContainer()

  if (ctx.isLoading) {
    return <Loading />
  } else {
    return <PageComponent {...ctx} />
  }
})

type PageProps = UsePageState
const PageComponent: React.VFC<PageProps> = ({
  currentPage,
  pageCount,
  webInterviews,
  webInterviewCount,
  sortBy,
  changePage,
  toggleSortBy,
  setScrollTopQuery,
  scrollTopQuery,
  saveQueryAndListToBack,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0
    }
  }, [currentPage])

  useEffect(() => {
    const st = scrollTopQuery
    if (wrapperRef.current && st) {
      wrapperRef.current.scrollTop = Number(st)
    }
  }, [scrollTopQuery])

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <PageHeader title="担当の選考（Web面接）" maxWidth="100%" />
      <Wrapper>
        <SidebarWrapper>
          <Sidebar webInterviewCount={webInterviewCount} />
        </SidebarWrapper>
        <MainWrapper>
          <PaginationWrapper>
            <Pagination
              page={currentPage}
              count={pageCount}
              onRequestChange={changePage}
            />
          </PaginationWrapper>
          <TableWrapper ref={wrapperRef}>
            <WebInterviewsTable
              webInterviews={webInterviews}
              sortBy={sortBy}
              toggleSortBy={toggleSortBy}
              setScrollTopQuery={setScrollTopQuery}
              saveQueryAndListToBack={saveQueryAndListToBack}
              wrapperRef={wrapperRef}
            />
            {webInterviews.length === 0 && (
              <EmptyListMessage>
                <Txt size="l">該当する情報はありません</Txt>
              </EmptyListMessage>
            )}
          </TableWrapper>
        </MainWrapper>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  overflow: hidden;
  flex: 1;
`

const MainWrapper = styled.div`
  grid-area: main;
  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[4]};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const SidebarWrapper = styled.div`
  grid-area: sidebar;
  padding: 20px 16px 20px 0;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`
