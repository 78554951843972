import { useMutation, useQueryClient } from 'react-query'
import {
  BatchUpdateSelectionStatusRequestValue,
  useRequestBatchUpdateSelectionStatus,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchUpdateSelectionStatus() {
  const queryClient = useQueryClient()
  const { requestBatchUpdateSelectionStatus } =
    useRequestBatchUpdateSelectionStatus()

  return useMutation(
    async (value: BatchUpdateSelectionStatusRequestValue) => {
      await requestBatchUpdateSelectionStatus(value)
    },
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        switch (req.condition.type) {
          case 'guids':
            queryClient.invalidateQueries({
              predicate: (query) =>
                query.queryKey[0] === QUERY_KEY.applicant &&
                req.condition.type.includes(query.queryKey[1] as string),
            })
            break
          case 'searchCondition':
            // NOTE:検索条件を指定した一括更新の場合、応募者GUIDが不明なので、応募者詳細全体をinvalidateする
            queryClient.invalidateQueries(QUERY_KEY.applicant)
            setTimeout(() => {
              queryClient.invalidateQueries(QUERY_KEY.applicants)
              queryClient.invalidateQueries(QUERY_KEY.applicant)
            }, 10000) // NOTE：非同期処理が完了することを期待して10秒後にキャッシュを飛ばす(10秒は根拠のない値)
            break
        }
      },
    }
  )
}
