import React, { useMemo, useState } from 'react'
import { theme, InputText, Icon } from '@blue-agency/rogue'
import { LineClampedTxt } from '@blue-agency/rogue'
import Autosuggest from 'react-autosuggest'
import styled from 'styled-components'

type Props = {
  staffs: StaffItem[]
  suggestionsMax?: number
  name: string
  value: string
  onChange: (newValue: string) => void
  onClear: () => void
  onSelected: (staff: StaffItem) => void
}

export const StaffSelect = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      staffs: _staffs,
      suggestionsMax = 10,
      name,
      value,
      onChange,
      onClear,
      onSelected,
    },
    ref
  ) => {
    const staffs = useMemo(() => new Staffs(_staffs), [_staffs])
    const suggestions = useMemo(
      () => staffs.filter(value, suggestionsMax),
      [staffs, value, suggestionsMax]
    )

    const getSuggestionValue = (_staff: StaffItem) => value

    const renderSuggestion: Autosuggest.RenderSuggestion<StaffItem> = (
      suggestion,
      { isHighlighted }
    ) => <Option staff={suggestion} isHighlighted={isHighlighted} />

    // FIXME: ESCの後にinputを2回クリックしないとサジェストが再表示されない
    const [suggestionsRevealed, setSuggestionsRevealed] = useState<
      boolean | undefined
    >()

    return (
      <Wrapper>
        <Autosuggest
          theme={myTheme}
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          renderInputComponent={(props) => <Input {...props} />}
          renderSuggestion={renderSuggestion}
          focusInputOnSuggestionClick={false}
          shouldRenderSuggestions={(_value, reason) => {
            switch (reason) {
              case 'suggestions-revealed':
                setSuggestionsRevealed(true)
                return true
              case 'input-blurred':
              case 'escape-pressed':
                setSuggestionsRevealed(false)
                return false
              case 'render':
                return suggestionsRevealed ?? false
            }
            return true
          }}
          inputProps={{
            ref,
            name,
            value,
            placeholder: '氏名、メールアドレスで検索',
            onChange: (_event, { method, newValue }) => {
              switch (method) {
                case 'type':
                  setSuggestionsRevealed(true)
                  onChange(newValue)
                  return
                case 'enter':
                  setSuggestionsRevealed(false)
                  return
              }
            },
            onClick: (event) => {
              if (suggestionsRevealed) {
                const target = event.target as HTMLInputElement
                target.blur()
              }
              setSuggestionsRevealed(!suggestionsRevealed)
            },
          }}
          onSuggestionsFetchRequested={({ value }) => {}}
          onSuggestionsClearRequested={() => {
            setSuggestionsRevealed(true)
          }}
          onSuggestionSelected={(_event, { suggestion, suggestionValue }) => {
            setSuggestionsRevealed(false)
            onSelected(suggestion)
          }}
        />
        {value && (
          <IconButton onClick={onClear}>
            <CancelIcon />
          </IconButton>
        )}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  position: relative;
`

const IconButton = styled.div.attrs({
  role: 'button',
  'aria-label': '選択をクリア',
})`
  align-items: center;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
`

const CancelIcon = styled(Icon).attrs({
  name: 'close2',
  height: '10px',
  width: '10px',
})`
  color: ${theme.color.gray[5]};
  & path:not(:first-child) {
    fill: black;
  }
`

export type StaffItem = {
  guid: string
  email: string
  name: { familyName: string; givenName: string }
  nameKana: { familyName: string; givenName: string }
}
export class Staffs {
  private staffs: StaffItem[]

  constructor(staffs: StaffItem[]) {
    this.staffs = staffs.sort((a, b) => a.email.localeCompare(b.email))
  }

  get all() {
    return this.staffs
  }

  filter(value: string, max: number): StaffItem[] {
    return this.staffs.filter(this.filterStaff(value)).slice(0, max)
  }

  private filterStaff(query: string) {
    return (staff: StaffItem): boolean => {
      if (query === '') return true
      const strings = [
        staff.email,
        `${staff.name.familyName} ${staff.name.givenName}`,
        `${staff.nameKana.familyName} ${staff.nameKana.givenName}`,
        staff.nameKana.familyName,
        staff.nameKana.givenName,
      ]
      return query
        .split(/ |　/)
        .every((q) => strings.some((s) => s.includes(q.trim())))
    }
  }
}

type OptionProps = {
  staff: StaffItem
  isHighlighted: boolean
}
const Option: React.VFC<OptionProps> = ({ staff, isHighlighted }) => (
  <OptionRow isHighlighted={isHighlighted}>
    <OptionName>
      {staff.name.familyName} {staff.name.givenName}{' '}
      <OptionEmail>{staff.email}</OptionEmail>
    </OptionName>
  </OptionRow>
)

type OptionRowParams = {
  isHighlighted: boolean
}
const OptionRow = styled.div<OptionRowParams>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  padding: 12px;
  :hover {
    cursor: pointer;
  }
  border-bottom: solid 1px ${theme.color.gray[4]};
  ${(props) =>
    props.isHighlighted && `background-color: ${theme.color.gray[4]}`}
`

const OptionName = styled(LineClampedTxt).attrs({ line: 1, size: 's' })``

const OptionEmail = styled.span`
  color: ${theme.color.gray[1]};
  font-size: ${theme.fontSize.xs};
`

type InputProps = Omit<
  Autosuggest.RenderInputComponentProps,
  'size' | 'type'
> & {
  label?: string
}
const Input: React.VFC<InputProps> = React.forwardRef((props, ref) => {
  return (
    <InputText
      {...{
        ...props,
        height: props.height as string | undefined,
        defaultValue: props.defaultValue as string | undefined,
      }}
      ref={ref}
      size="max"
    />
  )
})

const myTheme: Autosuggest.Theme = {
  container: {
    position: 'relative',
  },
  suggestionsContainer: {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 4px 4px',
    display: 'block',
    position: 'absolute',
    top: '32px',
    backgroundColor: theme.color.white[1],
    zIndex: 1,
  },
  suggestion: {
    listStyleType: 'none',
  },
  input: {
    cursor: 'pointer',
  },
}
