import { useQuery } from 'react-query'
import { useRequestSearchAssignedWebInterviews } from '@/services/bffService'
import type { SearchAssignedWebInterviewsArgs } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSearchAssignedWebInterviews(
  args: SearchAssignedWebInterviewsArgs
) {
  const { requestSearchAssignedWebInterviews } =
    useRequestSearchAssignedWebInterviews()

  return useQuery(
    [QUERY_KEY.assignedWebInterviews, args.page, args.sortBy, args.condition],
    () => requestSearchAssignedWebInterviews(args),
    {
      keepPreviousData: true,
    }
  )
}
