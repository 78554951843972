import React from 'react'
import { WebInterviewStatus } from '@blue-agency/proton/biz_hutt_bff'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  status: WebInterviewStatus
}>

export const WebInterviewStatusComponent = (
  props: Props
): React.ReactElement => {
  return (
    <Wrapper>
      <Box className={props.className} status={props.status}>
        <Txt status={props.status}>{textMap[props.status]}</Txt>
      </Box>
    </Wrapper>
  )
}

const textMap: Record<WebInterviewStatus, string> = {
  [WebInterviewStatus.WEB_INTERVIEW_STATUS_UNKNOWN]: 'UNKNOWN',
  [WebInterviewStatus.NOT_STARTED]: '未実施',
  [WebInterviewStatus.INTERVIEWING]: '面接中',
  [WebInterviewStatus.INTERVIEWED]: '実施済',
  [WebInterviewStatus.EXPIRED]: '期限切れ',
}

const colorMap: Record<WebInterviewStatus, string> = {
  [WebInterviewStatus.WEB_INTERVIEW_STATUS_UNKNOWN]: 'UNKNOWN',
  [WebInterviewStatus.NOT_STARTED]: theme.color.orange[1],
  [WebInterviewStatus.INTERVIEWING]: theme.color.green[3],
  [WebInterviewStatus.INTERVIEWED]: theme.color.gray[2],
  [WebInterviewStatus.EXPIRED]: theme.color.red[1],
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 9px;
`

const Box = styled.div<{ status: WebInterviewStatus }>`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${({ status }) => colorMap[status]};

  height: 24px;
  width: 46px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Txt = styled(_Txt).attrs({ size: 's' })<{ status: WebInterviewStatus }>`
  color: ${({ status }) => colorMap[status]};
`
