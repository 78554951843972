import React, { ReactElement, useState } from 'react'
import { theme, Icon } from '@blue-agency/rogue'
import styled from 'styled-components'
import { VideoPlayer } from '@/components/VideoPlayer'

type InputVideoHoverProps = {
  videoURL: string
  placeholder: string
  hoverInner: ReactElement
  onDelete: () => void
}

export const InputVideoHover: React.VFC<InputVideoHoverProps> = ({
  hoverInner,
  videoURL,
  placeholder,
  onDelete,
}) => {
  const [hover, setHover] = useState(false)

  return (
    <Wrapper
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {videoURL && (
        <>
          <DeleteIconWrapper onClick={onDelete} hover={hover}>
            <DeleteIcon name="close2" width="22px" height="22px" />
          </DeleteIconWrapper>
          <VideoPlayer src={videoURL} />
        </>
      )}
      {!videoURL && (
        <>
          <VideoArea>
            <PlayIconWrapper>
              <PlayIcon name="play" />
            </PlayIconWrapper>
            <SelectText>{placeholder}</SelectText>
          </VideoArea>
          <HoverArea hover={hover}>{hoverInner}</HoverArea>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
`

const VideoArea = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: dotted 1px ${theme.color.gray[2]};
  border-radius: 4px;
  line-height: 18px;
  padding: 8px 14px;

  text-align: center;
`

const DeleteIconWrapper = styled.div<{ hover: boolean }>`
  display: ${({ hover }) => (hover ? 'block' : 'none')};
  width: 22px;
  height: 22px;
  position: absolute;
  top: -22px;
  right: 0px;
  cursor: pointer;
  // opacityで設定するとIconも変わるのでrgbaで指定
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px 4px 0px 0px;
  text-align: center;
`

const DeleteIcon = styled(Icon)`
  color: ${theme.color.navy[1]};
`

const PlayIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 45px;

  width: 44px;
  height: 44px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border-color: white;
  }
`

const PlayIcon = styled(Icon)`
  color: #ccc;
  width: 21px;
  height: 18px;
  ${PlayIconWrapper} & {
    position: absolute;
    left: 29%;
    top: 29%;
  }
  ${PlayIconWrapper}:hover & {
    color: white;
  }
`

const SelectText = styled.span`
  display: inline-block;
  width: 100%;
  margin-top: 14px;

  font-family: inherit;
  font-size: ${theme.fontSize.m};
  color: ${theme.color.gray[2]};
`

const HoverArea = styled.div<{ hover: boolean }>`
  display: ${({ hover }) => (hover ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: ${theme.color.green[4]};
  opacity: 0.75;
  border: none;
  color: ${theme.color.white[1]};
  cursor: pointer;
`
