import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestReorderVideoSeminarContents,
  ReorderVideoSeminarContentsRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useReorderVideoSeminarContents() {
  const queryClient = useQueryClient()
  const { requestReorderVideoSeminarContents } =
    useRequestReorderVideoSeminarContents()
  return useMutation(
    (value: ReorderVideoSeminarContentsRequestValue) =>
      requestReorderVideoSeminarContents(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries([
          QUERY_KEY.videoSeminar,
          req.videoSeminarGuid,
        ])
      },
    }
  )
}
