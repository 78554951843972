import { useQuery } from 'react-query'
import { useRequestGetVideoSeminarReport } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetVideoSeminarReport(videoSeminarGuid: string) {
  const { requestGetVideoSeminarReport } = useRequestGetVideoSeminarReport()

  return useQuery([QUERY_KEY.videoSeminarReport, videoSeminarGuid], () =>
    requestGetVideoSeminarReport(videoSeminarGuid)
  )
}
