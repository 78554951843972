import { FixedBottomFormPage as Page } from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt } from '@blue-agency/rogue'
import { FormRow, TertiaryButton, PrimaryButton } from '@blue-agency/rogue/im'
import { Switch, Case } from 'react-if'
import styled from 'styled-components'
import { SettingsSection } from '@/components/SettingsSection'
import { HlsVideo } from './HlsVideo'
import { TranscodeStatus as _TranscodeStatus } from './TranscodeStatus'
import { VideoSeminarContentEditPageContainer } from './VideoSeminarContentEditPageContainer'

export const ConfirmPage: React.VFC = () => {
  const {
    videoSeminarContent,
    moviePreviewURL,
    thumbnailPreviewURL,
    thumbnailState,
    movieState,
    form,
    videoRefForMovieDuration,
    onSubmit,
    backToInput,
  } = VideoSeminarContentEditPageContainer.useContainer()

  return (
    <>
      <Page.Body>
        <SettingsSection title="内容を確認">
          <FormWrapper>
            <Row title="タイトル">
              <Txt>{form.watch('title')}</Txt>
            </Row>
            <WeakColorSeparator />
            <Row title="動画">
              {videoSeminarContent && !form.watch('movieKey') && (
                <TranscodeStatus
                  status={videoSeminarContent.movieTranscodeStatus}
                />
              )}
              <Switch>
                <Case condition={movieState === 'uploaded'}>
                  <MoviePreview ref={videoRefForMovieDuration}>
                    <source src={moviePreviewURL} type="video/mp4" />
                  </MoviePreview>
                </Case>
                <Case condition={movieState === 'done'}>
                  {videoSeminarContent && (
                    <HlsMoviePreview src={videoSeminarContent.movieUrl} />
                  )}
                </Case>
              </Switch>
            </Row>
            <WeakColorSeparator />
            <Row title="サムネイル画像">
              <Switch>
                <Case condition={thumbnailState === 'selected'}>
                  <ThumbnailPreview
                    alt="サムネイル画像"
                    src={thumbnailPreviewURL}
                  />
                </Case>
                <Case condition={thumbnailState === 'uploadedThumbnailExists'}>
                  <ThumbnailPreview
                    alt="サムネイル画像"
                    src={videoSeminarContent?.thumbnailUrl}
                  />
                </Case>
              </Switch>
            </Row>
            <WeakColorSeparator />
            <Row title="説明">
              <Txt>{form.watch('description')}</Txt>
            </Row>
          </FormWrapper>
        </SettingsSection>
      </Page.Body>
      <Page.Bottom>
        <Page.BottomButtons>
          <TertiaryButton
            widthSize="L2"
            onClick={backToInput}
            comlinkPushParams={{
              action:
                'click_back_to_edit_video_seminar_content_input_page_button_on_confirm_page',
            }}
          >
            入力画面に戻る
          </TertiaryButton>
          <PrimaryButton
            widthSize="L2"
            onClick={onSubmit}
            comlinkPushParams={{
              action: 'click_save_video_seminar_content_button',
            }}
          >
            保存
          </PrimaryButton>
        </Page.BottomButtons>
      </Page.Bottom>
    </>
  )
}

const FormWrapper = styled.div`
  padding: 0 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '148px', subtitleWidth: '0' })`
  padding: 20px 0;
`

const MoviePreview = styled.video.attrs({ controls: true })`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const HlsMoviePreview = styled(HlsVideo)`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const ThumbnailPreview = styled.img`
  object-fit: cover;
  width: 280px;
  height: 186px;
`

const TranscodeStatus = styled(_TranscodeStatus)`
  margin-bottom: 8px;
`
