import { SearchApplicantsCondition } from '@blue-agency/proton/biz_hutt_bff'
import { TimestampRange } from '@blue-agency/proton/web/v2/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { SearchCondition } from '../applicantService'

export const toSearchApplicantCondition = (condition: SearchCondition) => {
  const c = new SearchApplicantsCondition()
  c.setKeywordsList(condition.keywords ?? [])
  c.setStatusesList(condition.statuses ?? [])
  c.setSubStatusesList(condition.subStatuses ?? [])
  c.setCurrentSelectionStepMasterGuid(
    condition.currentSelectionStepMasterGuid ?? ''
  )
  c.setExternalApplicantId(condition.externalApplicantId ?? '')
  c.setNotesList(condition.notes ?? [])
  const t = new TimestampRange()
  if (condition.registerTime?.from) {
    t.setFrom(Timestamp.fromDate(condition.registerTime.from))
  }
  if (condition.registerTime?.to) {
    t.setTo(Timestamp.fromDate(condition.registerTime.to))
  }
  c.setRegisterTime(t)
  c.setMailFailure(condition.mailFailure)
  c.setCurrentSelectionStepExternalRecruitmentName(
    condition.currentSelectionStepExternalRecruitmentName ?? ''
  )
  c.setCurrentSelectionStepExternalSelectionName(
    condition.currentSelectionStepExternalSelectionName ?? ''
  )
  return c
}
