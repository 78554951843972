import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateVideoSeminarContentRequest } from '@blue-agency/proton/biz_hutt_bff'
import { assertNever } from '@/assertions'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateVideoSeminarContentRequestValueMovie =
  | { type: 'noUpdate' }
  | { type: 'update'; originalMovieKey: string; movieDurationSeconds: number }
export type UpdateVideoSeminarContentRequestValueThumbnail =
  | { type: 'delete' }
  | { type: 'noUpdate' }
  | { type: 'update'; thumbnailImage: Uint8Array }

export type UpdateVideoSeminarContentRequestValue = {
  videoSeminarContentGuid: string
  title: string
  description: string
  movie: UpdateVideoSeminarContentRequestValueMovie
  thumbnail: UpdateVideoSeminarContentRequestValueThumbnail
}

export function useRequestUpdateVideoSeminarContent() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateVideoSeminarContent = useCallback(
    async (v: UpdateVideoSeminarContentRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateVideoSeminarContentRequest()
      req.setVideoSeminarContentGuid(v.videoSeminarContentGuid)
      req.setTitle(v.title)
      req.setDescription(v.description)
      switch (v.movie.type) {
        case 'noUpdate':
          req.setNoMovieUpdate(true)
          break
        case 'update': {
          const movie = new UpdateVideoSeminarContentRequest.Movie()
          movie.setOriginalMovieKey(v.movie.originalMovieKey)
          movie.setMovieDurationSeconds(
            Math.floor(v.movie.movieDurationSeconds)
          )
          req.setNewMovie(movie)
          break
        }
        default:
          assertNever(v.movie)
      }
      switch (v.thumbnail.type) {
        case 'delete':
          req.setDelete(true)
          break
        case 'noUpdate':
          req.setNoThumbnailUpdate(true)
          break
        case 'update':
          req.setImage(v.thumbnail.thumbnailImage)
          break
        default:
          assertNever(v.thumbnail)
      }

      return callBizHuttBffService(
        'updateVideoSeminarContent',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateVideoSeminarContent,
  }
}
