import { comlinkPush } from '@blue-agency/im-shared-front'
import { BizHuttBffServicePromiseClient } from '@blue-agency/proton/biz_hutt_bff'
import { callWithRetry } from './callWithRetry'
import { logRpcErr } from './logRpcErr'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')

const bizHuttClient = new BizHuttBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([bizHuttClient])
}

export function callBizHuttBffService<
  T extends keyof BizHuttBffServicePromiseClient
>(
  rpcName: T,
  req: Parameters<BizHuttBffServicePromiseClient[T]>,
  loggedInStaffGuid: string | undefined
): ReturnType<BizHuttBffServicePromiseClient[T]> {
  const metadata: Record<string, string> = {
    rpc: rpcName,
  }

  if (loggedInStaffGuid) {
    metadata['loggedInStaffGuid'] = loggedInStaffGuid
  }

  comlinkPush({
    action: 'call_rpc',
    metadata,
  })

  // @ts-expect-error
  return callWithRetry(() =>
    // @ts-expect-error
    bizHuttClient[rpcName].apply(bizHuttClient, req).catch((e) => {
      logRpcErr(rpcName, e, loggedInStaffGuid)
      throw e
    })
  )
}
