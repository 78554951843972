import { useQuery } from 'react-query'
import {
  SearchWebInterviewsArgs,
  useRequestSearchWebInterviews,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useSearchWebInterviews(args: SearchWebInterviewsArgs) {
  const { requestSearchWebInterviews } = useRequestSearchWebInterviews()

  return useQuery(
    [QUERY_KEY.webInterviews, args.page, args.sortBy, args.condition],
    () => requestSearchWebInterviews(args),
    {
      keepPreviousData: true,
    }
  )
}
