import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { LineClampedTxt, theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Link, generatePath } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Loading } from '@/components/Loading'
import { ListPageHeader } from '@/components/PageHeader'
import { TableBase } from '@/components/TableBase'
import { AuthzContainer } from '@/services/authzService'
import { getQuestionFormatLabel } from '@/services/questionTemplateService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { QuestionTemplate } from './types'
import { useListQuestionTemplates } from './useListQuestionTemplates'

const generateQuestionTemplatePath = (guid: QuestionTemplate['guid']) =>
  generatePath(INTERNAL_PATHS.questionTemplate, {
    questionTemplateGuid: guid,
  })

export const QuestionTemplatesPage: React.VFC = () => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName &&
    authorizeByRpcName('ListAllRecInterviewQuestionTemplates')

  const pageCtx = useListQuestionTemplates()

  if (pageCtx.isLoading) {
    return <Loading />
  }

  const questionTemplates = pageCtx.data?.questionTemplates ?? []

  const emptyListMessage = questionTemplates.length === 0 && (
    <EmptyListMessage>
      <Txt size="l">登録された情報はありません</Txt>
    </EmptyListMessage>
  )

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <ListPageHeader
        title="設問一覧"
        right={
          <HeaderButtonGroups>
            <NewQuestionTemplateLink to={INTERNAL_PATHS.newQuestionTemplate}>
              <PrimaryButton
                widthSize="L1"
                comlinkPushParams={{
                  action:
                    'click_move_to_rec_interview_question_template_registration_page',
                }}
              >
                設問登録
              </PrimaryButton>
            </NewQuestionTemplateLink>
          </HeaderButtonGroups>
        }
      />
      <Wrapper>
        <MainWrapper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <Th property="name">設問名</Th>
                  <Th property="content">設問文</Th>
                  <Th property="format">回答形式</Th>
                  <Th property="padding" />
                </TableRow>
              </TableHead>
              <TableBody>
                {questionTemplates.map((qt) => {
                  const qtPath = generateQuestionTemplatePath(qt.guid)
                  return (
                    <TableRow key={qt.guid}>
                      <Td property="name">
                        <TableCellLink to={qtPath}>{qt.name}</TableCellLink>
                      </Td>
                      <Td property="content">
                        <TableCellLink to={qtPath}>
                          <LineClampedTxt line={2}>{qt.content}</LineClampedTxt>
                        </TableCellLink>
                      </Td>
                      <Td property="format">
                        <TableCellLink to={qtPath}>
                          {getQuestionFormatLabel(qt.format)}
                        </TableCellLink>
                      </Td>
                      <Td property="padding">
                        <TableCellLink to={qtPath} />
                      </Td>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {emptyListMessage}
          </TableWrapper>
        </MainWrapper>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';

  overflow: hidden;
  flex: 1;
`

const HeaderButtonGroups = styled.div`
  display: flex;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: 7px;
  }
`

const NewQuestionTemplateLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const MainWrapper = styled.div`
  grid-area: main;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

type Property = 'name' | 'content' | 'format' | 'padding'

const width: Record<Property, number> = {
  name: 260,
  content: 510,
  format: 200,
  padding: 30,
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

const Table = styled(TableBase)`
  th:last-of-type {
    justify-content: flex-start;
    padding-left: ${width.padding}px;
  }
  width: 100%;
  min-width: ${Object.values(width).reduce((acc, val) => acc + val)}px;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 2;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-left: 28px !important;
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 70px !important;
  ${({ property }) => propertyStyles[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    display: flex;
    align-items: center;
    flex: 0 0 ${width.name}px;
    justify-content: flex-start;

    ${TableHead} && {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
  content: css`
    flex: 0 0 ${width.content}px;
    justify-content: flex-start;
  `,
  format: css`
    flex: 0 0 ${width.format}px;
    justify-content: flex-start;
    > *:first-child {
      margin-right: 7px;
    }
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}

const TableCellLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -15px;
  padding: 0 28px;
  color: inherit;
`
