import { useMutation, useQueryClient } from 'react-query'
import {
  AssignSelectionStepToApplicantRequestValue,
  useRequestAssignSelectionStepToApplicant,
  AssignSelectionStepToApplicantWithRecInterviewTemplateRequestValue,
  useRequestAssignSelectionStepToApplicantWithRecInterviewTemplate,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useAssignSelectionStepToApplicant() {
  const queryClient = useQueryClient()
  const { requestAssignSelectionStepToApplicant } =
    useRequestAssignSelectionStepToApplicant()

  return useMutation(
    (value: AssignSelectionStepToApplicantRequestValue) =>
      requestAssignSelectionStepToApplicant(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.applicantGuid])
      },
    }
  )
}

export function useAssignSelectionStepToApplicantWithRecInterviewTemplate() {
  const queryClient = useQueryClient()
  const { requestAssignSelectionStepToApplicantWithRecInterviewTemplate } =
    useRequestAssignSelectionStepToApplicantWithRecInterviewTemplate()

  return useMutation(
    (
      value: AssignSelectionStepToApplicantWithRecInterviewTemplateRequestValue
    ) => requestAssignSelectionStepToApplicantWithRecInterviewTemplate(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, req.applicantGuid])
      },
    }
  )
}
