import { yupResolver } from '@hookform/resolvers/yup'
import {
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
} from 'react-hook-form'
import { Form, formSchema } from './schema'

export type UseFormResponse = {
  formCtx: UseFormReturn<Form>
  educationalBackgroundsListCtx: UseFieldArrayReturn<
    Form,
    'educationalBackgroundsList',
    'id'
  >
  workHistoriesListCtx: UseFieldArrayReturn<Form, 'workHistoriesList', 'id'>
}

export const useApplicantForm = (defaultValues: Form): UseFormResponse => {
  const formCtx = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues,
  })

  const educationalBackgroundsListCtx = useFieldArray({
    control: formCtx.control,
    name: 'educationalBackgroundsList',
  })

  const workHistoriesListCtx = useFieldArray({
    control: formCtx.control,
    name: 'workHistoriesList',
  })

  return {
    formCtx,
    educationalBackgroundsListCtx,
    workHistoriesListCtx,
  }
}
