import { useQuery } from 'react-query'
import { useRequestListSelectionStepMasters } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListSelectionStepMasters() {
  const { requestListSelectionStepMasters } =
    useRequestListSelectionStepMasters()

  return useQuery(
    [QUERY_KEY.selectionStepMasters],
    requestListSelectionStepMasters,
    {
      staleTime: 5 * 60 * 1000,
    }
  )
}
