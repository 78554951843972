import { NewTabLink } from '@blue-agency/rogue'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { useCalendarDates } from '@/hooks/useCalendarDates'
import { WebInterview } from '@/pages/WebInterviewPage/WebInterviewPageContainer'

type Props = {
  name: WebInterview['name']
  interviewerUrl: WebInterview['intervieweeUrl']
  intervieweeUrl: WebInterview['intervieweeUrl']
  scheduledStartTime?: WebInterview['scheduledStartTime']
  durationMinutes?: WebInterview['durationMinutes']
  assigneesEmails?: string
}

export const OutlookCalendarLink: React.VFC<Props> = ({
  name,
  interviewerUrl,
  intervieweeUrl,
  scheduledStartTime,
  durationMinutes,
  assigneesEmails,
}) => {
  const dateFormat = (dateObj: Date) => {
    return `${format(dateObj, 'yyyy-MM-dd', { locale: ja })}T${format(
      dateObj,
      'HH:mm',
      { locale: ja }
    )}`
  }

  const { startTime, endTime } = useCalendarDates(
    scheduledStartTime,
    durationMinutes
  )

  return (
    <NewTabLink
      href={`https://outlook.live.com/calendar/action/compose?subject=${name}&body=面接官：${interviewerUrl}<br />応募者：${intervieweeUrl}<br />録画：${
        window.location.href
      }&startdt=${dateFormat(startTime)}&enddt=${dateFormat(
        endTime
      )}&allday=false&to=${assigneesEmails}`}
      action="click_subscribe_to_outlook_calendar"
    >
      Outlookカレンダーに登録
    </NewTabLink>
  )
}
