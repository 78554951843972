import React, { useMemo } from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import { Route, Switch, useParams } from 'react-router'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  mapProtoToVideoSeminar,
  useGetVideoSeminar,
} from '@/services/videoSeminarService'
import { ContentsPage } from './ContentsPage'
import { Header } from './Header'
import { InfoPage } from './InfoPage'
import { ReportPage } from './ReportPage'
import { Tabs } from './Tabs'
import { useTabs } from './useTabs'

export const VideoSeminarRoot = () => {
  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()

  const { selectedTab, switchTab } = useTabs(videoSeminarGuid)

  const { data, isLoading } = useGetVideoSeminar(videoSeminarGuid)

  const videoSeminar = useMemo(() => {
    if (data === undefined) return undefined
    return mapProtoToVideoSeminar(data, videoSeminarGuid)
  }, [data, videoSeminarGuid])

  const hasFailedStatus = useMemo(
    () =>
      videoSeminar?.contents.some((c) => c.movieTranscodeStatus === 'failed') ??
      false,
    [videoSeminar]
  )

  const hasProcessingStatus = useMemo(
    () =>
      videoSeminar?.contents.some(
        (c) => c.movieTranscodeStatus === 'processing'
      ) ?? false,
    [videoSeminar]
  )

  if (isLoading || !videoSeminar) {
    return <Loading />
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Wrapper>
        <Header
          videoSeminarName={videoSeminar.title}
          isPublic={videoSeminar.isPublic}
        />
        <Main>
          <Sidebar>
            <Tabs
              selectedTab={selectedTab}
              onClick={switchTab}
              hasFailedStatus={hasFailedStatus}
              hasProcessingStatus={hasProcessingStatus}
            />
          </Sidebar>
          <PageWrapper>
            <Switch>
              <Route
                exact
                path={INTERNAL_PATHS.videoSeminarInfo}
                render={() => (
                  <InfoPage
                    videoSeminarGuid={videoSeminarGuid}
                    title={videoSeminar.title}
                    description={videoSeminar.description}
                    isPublic={videoSeminar.isPublic}
                  />
                )}
              />
              <Route
                exact
                path={INTERNAL_PATHS.videoSeminarContents}
                render={() => (
                  <ContentsPage
                    videoSeminarGuid={videoSeminarGuid}
                    contents={videoSeminar.contents}
                  />
                )}
              />
              <Route
                exact
                path={INTERNAL_PATHS.videoSeminarReport}
                render={() => (
                  <ReportPage videoSeminarGuid={videoSeminarGuid} />
                )}
              />
            </Switch>
          </PageWrapper>
        </Main>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Main = styled.main`
  display: flex;
  flex: 1;
`

const Sidebar = styled.aside`
  position: sticky;
  top: 0;
  align-self: start;
  width: 160px;
`

const PageWrapper = styled.div`
  margin: 12px 0 0 12px;
  background-color: ${theme.color.white[1]};
  flex: 1;
`
