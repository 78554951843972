import { useQuery } from 'react-query'
import { useRequestGetWebInterviewGuide } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useGetWebInterviewGuide(webInterviewGuideGuid: string) {
  const { requestGetWebInterviewGuide } = useRequestGetWebInterviewGuide()

  return useQuery([QUERY_KEY.webInterviewGuide, webInterviewGuideGuid], () =>
    requestGetWebInterviewGuide(webInterviewGuideGuid)
  )
}
