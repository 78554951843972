import { assertIsDefined } from '@/assertions'

type VideoQuestionlMaxRecCountOption = {
  label: string
  value: string
}

export const videoQuestionlMaxRecCountOptions: Array<VideoQuestionlMaxRecCountOption> =
  [
    {
      label: '無制限',
      value: '0',
    },
    {
      label: '1回',
      value: '1',
    },
    {
      label: '2回',
      value: '2',
    },
    {
      label: '3回',
      value: '3',
    },
    {
      label: '4回',
      value: '4',
    },
    {
      label: '5回',
      value: '5',
    },
  ]

export function getVideoQuestionlMaxRecCountLabel(value: string): string {
  const option = videoQuestionlMaxRecCountOptions.find(
    (option) => option.value === value
  )
  assertIsDefined(option)
  return option.label
}
