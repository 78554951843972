import React from 'react'
import { Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Box } from '@/components/Box'
import { NextAction } from '@/services/applicantService'

type Props = {
  nextAction: NextAction
  onClick: () => void
}
export const NextActionButton: React.VFC<Props> = (props) => (
  <Box display="flex" flexDirection="column">
    <Txt>次のアクション{props.nextAction === 'NONE' && '：なし'}</Txt>
    {props.nextAction !== 'NONE' && (
      <PrimaryButton
        onClick={props.onClick}
        widthSize="L1"
        comlinkPushParams={{ action: `click_next_action_${props.nextAction}` }}
      >
        {getNextActionButtonText(props.nextAction)}
      </PrimaryButton>
    )}
  </Box>
)

const getNextActionButtonText = (nextAction: NextAction): string => {
  switch (nextAction) {
    case 'ASSESS_SELECTION_STEP':
      return '選考結果確定'
    case 'ASSIGN_REC_INTERVIEW_TEMPLATE':
      return 'テンプレート設定'
    case 'CHANGE_REC_INTERVIEW_DEADLINE':
      return '提出期限変更'
    case 'NONE':
      throw new Error('next-action-none does NOT have a button text')
    case 'REGISTER_INITIAL_SELECTION_STEP':
    case 'REGISTER_NEXT_SELECTION_STEP':
      return '選考追加'
    case 'REQUEST_SUBMISSION_REC_INTERVIEW':
      return '提出依頼'
  }
}
