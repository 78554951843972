import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CloseVideoSeminarRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCloseVideoSeminar() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCloseVideoSeminar = useCallback(
    async (videoSeminarGuid: string) => {
      const metadata = await buildMetadata()

      const req = new CloseVideoSeminarRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)

      return callBizHuttBffService(
        'closeVideoSeminar',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCloseVideoSeminar,
  }
}
