import { FormRow } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import * as C from '@/components/RecInterviewTemplate'
import {
  getQuestionFormatLabel,
  getVideoQuestionlMaxDurationSecondsLabel,
  getVideoQuestionlMaxRecCountLabel,
} from '@/services/questionTemplateService'
import { TemplateDetailQuestion } from '@/services/recInterviewTemplateService/types'

type QuestionProps = PropsWithClassName<{
  question: TemplateDetailQuestion
  index: number
}>
export const QuestionRow: React.VFC<QuestionProps> = (props) => {
  return (
    <QuestionItem>
      <Row
        title={props.index === 0 ? '応募者の設問回答ページ' : ''}
        subtitle={<SubTitle index={props.index} title="設問名" />}
      >
        <C.ItemValue>{props.question.name}</C.ItemValue>
      </Row>
      <Row title="" subtitle={<SubTitle title="設問文" />}>
        <C.ItemValue>{props.question.content}</C.ItemValue>
      </Row>
      <Row title="" subtitle={<SubTitle title="注釈" />}>
        <C.ItemValue>{props.question.note}</C.ItemValue>
      </Row>
      <Row title="" subtitle={<SubTitle title="必須 / 任意" />}>
        <C.ItemValue>{props.question.required ? '必須' : '任意'}</C.ItemValue>
      </Row>
      <Row title="" subtitle={<SubTitle title="回答形式" />}>
        <C.ItemValue>
          {getQuestionFormatLabel(props.question.format.format)}
        </C.ItemValue>
      </Row>
      {props.question.format.format === 'video' && (
        <>
          <Row title="" subtitle={<SubTitle title="回答時間制限" />}>
            <C.ItemValue>
              {getVideoQuestionlMaxDurationSecondsLabel(
                props.question.format.maxDurationSeconds.toString()
              )}
            </C.ItemValue>
          </Row>
          <Row title="" subtitle={<SubTitle title="撮影回数制限" />}>
            <C.ItemValue>
              {getVideoQuestionlMaxRecCountLabel(
                props.question.format.maxRecCount.toString()
              )}
            </C.ItemValue>
          </Row>
        </>
      )}
    </QuestionItem>
  )
}

const QuestionItem = styled.div``

type SubTitleProps = {
  index?: number
  title: string
}
const SubTitle: React.VFC<SubTitleProps> = (props) => {
  const number = props.index !== undefined ? `問${props.index + 1}` : undefined
  return (
    <C.ItemLabel>
      <ItemLabelQuestionNumber>{number}</ItemLabelQuestionNumber>
      <ItemLabelQuestionLabel>{props.title}</ItemLabelQuestionLabel>
    </C.ItemLabel>
  )
}

const Row = styled(FormRow).attrs({
  titleWidth: '180px',
  subtitleWidth: '108px',
  labelHeight: '20px',
})`
  padding: 20px 0;
  :not(:first-child) {
    padding-top: 0;
  }
`

const ItemLabelQuestionNumber = styled.div`
  width: 30px;
`

const ItemLabelQuestionLabel = styled.div`
  width: 78px;
`
