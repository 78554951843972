import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import {
  WeakColorSeparator,
  Txt as _Txt,
  InputText,
  theme,
  Dropdown,
  ErrorBox,
} from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { SettingsSection } from '@/components/SettingsSection'
import { SectionInner } from '@/components/form'
import { VideoInput, UploadingModal } from '@/components/questionTemplate'
import {
  questionFormatOptions,
  videoQuestionlMaxRecCountOptions,
  videoQuestionlMaxDurationSecondsOptions,
} from '@/services/questionTemplateService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useInputPage } from './useInputPage'

export const InputPage: React.VFC = () => {
  const {
    errors,
    register,
    onSubmit,
    isSubmitting,
    preventSubmit,
    watch,
    onSelectFiles,
    onUnselect,
    multipartUpload,
    uploadingModal,
    onCancelUpload,
    errorMessageOnTop,
  } = useInputPage()
  const videoFile = watch('videoFile')
  const format = watch('format')
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper onSubmit={onSubmit}>
        <Page.Header>
          <Page.HeaderTitle>設問登録</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          {errorMessageOnTop && (
            <Box paddingY="20px" backgroundColor={theme.color.gray[5]}>
              <ErrorBox>
                <Txt color={theme.color.red[2]}>{errorMessageOnTop}</Txt>
              </ErrorBox>
            </Box>
          )}
          <SettingsSection title="設問">
            <SectionInner>
              <Row title="設問名" required>
                <InputArea>
                  <InputText
                    {...register('name')}
                    size="ll"
                    placeholder="例）自己PR"
                    onBlurError={errors['name']?.message}
                    // TODO: InputHTMLAttributesをpropsの型に追加したい
                    // @ts-ignore
                    onKeyPress={preventSubmit}
                  />
                  <HelpTxt color={theme.color.gray[1]}>
                    社内管理用の設問名です。応募者には表示されません。
                  </HelpTxt>
                </InputArea>
              </Row>
              <WeakColorSeparator />
              <Row title="設問文" required>
                <TextArea
                  {...register('content')}
                  type="textarea"
                  placeholder="例）2分程度を目安に自己PRをしてください。"
                  onBlurError={errors['content']?.message}
                />
              </Row>
              <WeakColorSeparator />
              <Row title="設問の注釈">
                <TextArea
                  {...register('note')}
                  placeholder="例）背景や服装、撮影場所の指定はありません。"
                  onBlurError={errors['note']?.message}
                />
              </Row>
              <WeakColorSeparator />
              <Row title="設問動画">
                <InputArea>
                  <VideoInput
                    selectedVideoURL={videoFile?.url}
                    onSelectFiles={onSelectFiles}
                    onUnselect={onUnselect}
                  />
                  {!videoFile && (
                    <HelpTxt color={theme.color.gray[1]}>
                      アップロード可能なファイル形式はMP4のみです（最大サイズ：200MB）
                    </HelpTxt>
                  )}
                </InputArea>
              </Row>
              <UploadingModal
                active={uploadingModal.active}
                percent={multipartUpload.progress}
                fileName={videoFile?.name ?? ''}
                onCancel={onCancelUpload}
              />
              <WeakColorSeparator />
              <Row title="回答" subtitle="形式" required>
                <InputArea>
                  <Dropdown
                    {...register('format')}
                    options={questionFormatOptions}
                    onBlurError={errors['format']?.message}
                  />
                  <HelpTxt color={theme.color.gray[1]}>
                    回答形式は一度設定すると変更できません。
                  </HelpTxt>
                </InputArea>
              </Row>
              {format === 'video' && (
                <>
                  <InnerRow subtitle="回答時間制限">
                    <Dropdown
                      {...register('maxDurationSeconds')}
                      value="0"
                      options={videoQuestionlMaxDurationSecondsOptions}
                    />
                  </InnerRow>
                  <InnerRow subtitle="撮影回数制限">
                    <Dropdown
                      {...register('maxRecCount')}
                      value="0"
                      options={videoQuestionlMaxRecCountOptions}
                    />
                  </InnerRow>
                </>
              )}
            </SectionInner>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <BackButtonLink to={INTERNAL_PATHS.questionTemplates}>
              <TertiaryButton
                widthSize="L2"
                comlinkPushParams={{
                  action: 'click_cancel_rec_interview_question_template_button',
                }}
              >
                一覧に戻る
              </TertiaryButton>
            </BackButtonLink>
            <PrimaryButton
              widthSize="L2"
              type="submit"
              disabled={isSubmitting}
              comlinkPushParams={{
                action: 'click_confirm_rec_interview_question_template_button',
              }}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Txt = styled(_Txt).attrs({ size: 'm' })``

const HelpTxt = styled(_Txt)`
  margin-top: 12px;
`

const BackButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const TextArea = styled(InputText).attrs({ type: 'textarea' })`
  min-height: 80px;
`

const Row = styled(FormRow).attrs({
  titleWidth: '120px',
  subtitleWidth: '80px',
})`
  padding: 20px 0;
`
const InnerRow = styled(Row)`
  padding-top: 0;
`

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`
