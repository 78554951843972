import { useCallback, useState } from 'react'
import { StartRecInterviewTemplateFileUploadResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { useModal } from '@/hooks/useModal'
import { useMultipartUpload } from '@/hooks/useMultipartUpload'
import {
  useRequestCompleteRecInterviewTemplateFileUpload,
  useRequestStartRecInterviewTemplateFileUpload,
} from '@/services/bffService'

export function useTemplateUpload() {
  const [abortController, setAbortController] = useState(new AbortController())
  const multipartUpload = useMultipartUpload(abortController)
  const uploadingModal = useModal()
  const { requestStartRecInterviewTemplateFileUpload } =
    useRequestStartRecInterviewTemplateFileUpload()
  const { requestCompleteRecInterviewTemplateFileUpload } =
    useRequestCompleteRecInterviewTemplateFileUpload()

  const onCancel = useCallback(() => {
    abortController.abort()
    setAbortController(new AbortController())
  }, [abortController])

  const upload = async (video: Blob) => {
    const cleanup = () => {
      uploadingModal.close()
      multipartUpload.resetState()
    }

    uploadingModal.open()

    const chunks = multipartUpload.splitBlob(video)
    let startUploadRes: StartRecInterviewTemplateFileUploadResponse
    try {
      startUploadRes = await requestStartRecInterviewTemplateFileUpload(
        chunks.length,
        video.type
      )
    } catch (e) {
      cleanup()
      throw e
    }

    let etagList: string[]
    try {
      etagList = await multipartUpload.upload(
        chunks.map((chunk, i) => ({
          blob: chunk,
          url: startUploadRes.getUrlsList()[i],
        }))
      )
    } catch (e) {
      cleanup()
      throw e
    }

    try {
      await requestCompleteRecInterviewTemplateFileUpload(
        startUploadRes.getKey(),
        startUploadRes.getUploadId(),
        etagList
      )
    } catch (e) {
      cleanup()
      throw e
    }

    cleanup()
    return startUploadRes.getKey()
  }

  return {
    onCancel,
    upload,
    multipartUpload,
    uploadingModal,
  }
}
