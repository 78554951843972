import React from 'react'
import { assistant, useLoggedInStaff } from '@blue-agency/im-shared-front'
import { WebInterviewStatus } from '@blue-agency/proton/biz_hutt_bff'
import { A, Txt } from '@blue-agency/rogue'
import { PrimaryButton, SecondaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { WebInterviewPageContainer } from '../WebInterviewPageContainer'
import notInterviewedImage from './not-interviewed.svg'

export const NotInterviewedStatus: React.VFC = () => {
  const pageCtx = WebInterviewPageContainer.useContainer()
  const loggedInStaff = useLoggedInStaff()

  if (pageCtx.isLoading || loggedInStaff === undefined) {
    // TODO: ローディング表示
    return null
  }

  const { webInterview } = pageCtx

  return (
    <Wrapper>
      <ImageWrapper>
        <WebInterviewStatusImage src={notInterviewedImage} alt="" />
      </ImageWrapper>
      <ButtonGroup>
        {/* NOTE: authzType による表示出し分けは避けたいが一時的な対応
          https://github.com/blue-agency/im-shared-front/pull/82#issuecomment-784807223 */}
        {loggedInStaff.authzType.type === assistant.type ? (
          <PrimaryButton
            widthSize="L2"
            comlinkPushParams={{ action: 'click_copy_interviewer_url' }}
            onClick={pageCtx.copyInterviewerUrl}
          >
            面接官用リンクをコピー
          </PrimaryButton>
        ) : (
          <OpenWebInterviewAnchor
            href={webInterview.interviewerUrl}
            target="_blank"
            rel="noopener noreferrer"
            comlinkPushParams={{
              action: 'click_open_interview_page_anchor',
            }}
          >
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{ action: 'click_open_interview_page' }}
            >
              {webInterview.status === WebInterviewStatus.NOT_STARTED &&
                '面接を実施'}
              {webInterview.status === WebInterviewStatus.INTERVIEWING &&
                '面接に参加'}
            </PrimaryButton>
          </OpenWebInterviewAnchor>
        )}
        <SecondaryButton
          widthSize="L2"
          comlinkPushParams={{ action: 'click_copy_interviewee_url' }}
          onClick={pageCtx.copyIntervieweeUrl}
        >
          応募者用リンクをコピー
        </SecondaryButton>
        <Txt>面接を終了すると、録画データが表示されます。</Txt>
      </ButtonGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 214px;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
`

const WebInterviewStatusImage = styled.img`
  height: 200px;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px;
`

const OpenWebInterviewAnchor = styled(A)`
  color: inherit;
  text-decoration: none;
  width: 160px;
`
