import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

export const TooltipWrapper = styled.div`
  position: relative;
  border: 2px solid ${theme.color.navy[1]};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  width: 130px;
  padding: 8px 12px;
  background-color: ${theme.color.white[1]};
`

export const TooltipTxt = styled(Txt).attrs({ size: 's' })`
  line-height: 1.7;
`

export const TooltipTxtStrong = styled.span`
  font-size: 18px;
`
