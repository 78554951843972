import { Gender } from '@blue-agency/proton/web/v2/im'
import { GenderType, getGenderName } from '@/services/applicantService'

export const genderOptions: Array<{ label: string; value: GenderType }> = [
  {
    label: getGenderName(Gender.GENDER_MALE),
    value: 'GENDER_MALE',
  },
  {
    label: getGenderName(Gender.GENDER_FEMALE),
    value: 'GENDER_FEMALE',
  },
  {
    label: getGenderName(Gender.GENDER_OTHER),
    value: 'GENDER_OTHER',
  },
  {
    label: getGenderName(Gender.GENDER_NO_ANSWER),
    value: 'GENDER_NO_ANSWER',
  },
]
