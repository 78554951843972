import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Txt, LineClampedTxt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { StaffIcons } from '@/components/StaffIcons'
import type { Assignee } from './types'

type Props = {
  assignees: Assignee[]
  tooltipId: string
  maxDisplayCount?: number
}

const maxDisplayCountInToolTip = 11

/**
 * 「anchorRefが画面の半分より上に位置するかどうか」を一定間隔で判定する
 * @param anchorRef 対象の ref node
 * @returns
 */
const useIsUpside = (anchorRef: React.MutableRefObject<HTMLElement | null>) => {
  const [isUpside, setIsUpside] = useState<boolean>()

  useEffect(() => {
    const id = setInterval(() => {
      if (!anchorRef.current) return
      const { top, height } = anchorRef.current.getBoundingClientRect()
      const isUpside_ = top + height / 2 < window.innerHeight / 2
      setIsUpside(isUpside_)
    }, 200)
    return () => clearInterval(id)
  }, [anchorRef])

  return isUpside
}

export const AssigneeIcons: React.VFC<Props> = ({
  tooltipId,
  assignees,
  maxDisplayCount,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  const isUpside = useIsUpside(anchorRef)

  const tooltipPosition = useMemo(
    () => (isUpside ? 'topRight' : 'bottomRight'),
    [isUpside]
  )

  return (
    <div ref={anchorRef} data-tip data-for={tooltipId}>
      <StaffIcons
        staffs={assignees.map((assignee) => ({
          ...assignee,
          name: assignee.name.familyName,
        }))}
        maxDisplayCount={maxDisplayCount}
      />
      <Tooltip id={tooltipId} arrowPosition={tooltipPosition}>
        <AssigneeNameList>
          {assignees.slice(0, maxDisplayCountInToolTip).map((assignee) => (
            <li key={assignee.guid}>
              <LineClampedTxt size="s" line={1}>
                {assignee.name.fullName}
              </LineClampedTxt>
            </li>
          ))}
          {assignees.length > maxDisplayCountInToolTip && (
            <ListEllipsis>
              <Txt size="s">︙</Txt>
            </ListEllipsis>
          )}
        </AssigneeNameList>
      </Tooltip>
    </div>
  )
}

const AssigneeNameList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 117px;
`

const ListEllipsis = styled.li`
  list-style: none;
  align-self: center;
`
