import { useCallback } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { genTab } from '@/components/Tabs'
import { ControlCenterPageContainer } from '@/pages/ControlCenterPage/ControlCenterPageContainer'
import { tabs } from '@/pages/ControlCenterPage/types'
import type { TabType } from '@/pages/ControlCenterPage/types'

export const Tabs = () => {
  const { upcomingCount, ongoingCount, tabType, setTabType } =
    ControlCenterPageContainer.useContainer()

  const mapTabName = useCallback(
    (tab: TabType): JSX.Element => {
      switch (tab) {
        case 'upcoming':
          return (
            <>
              開始前の面接
              <br />（{upcomingCount}
              <CountUnit>件</CountUnit>）
            </>
          )
        case 'ongoing':
          return (
            <>
              実施中の面接
              <br />（{ongoingCount}
              <CountUnit>件</CountUnit>）
            </>
          )
      }
    },
    [upcomingCount, ongoingCount]
  )

  const Tabs = genTab<TabType>(tabs, mapTabName)
  return <Tabs selectedTab={tabType} onClick={setTabType} />
}

const CountUnit = styled.span`
  font-size: ${theme.fontSize.xs};
`
