import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import {
  ErrorBox,
  theme,
  Txt,
  LineClampedTxt,
  RadioGroup,
} from '@blue-agency/rogue'
import {
  Dropdown,
  FormRow,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { CSVUploadValidationErrorModal } from '@/components/CSVUploadValidationErrorModal'
import { SettingsSection } from '@/components/SettingsSection'
import { ApplicantForm } from '@/services/applicantService'
import { CSVData } from '@/services/csvService'
import { LinkToHelp } from '../LinkToHelp'
import { selectionSharingOptions } from '../options'
import type { Form } from '../types'
import { useMappingPage } from './useMappingPage'

type Props = {
  csvData: CSVData
  initialFormData?: Form
  goPrevious: () => void
  goNext: (formData: Form, applicantsData: ApplicantForm[]) => void
}

export const Mapping: React.VFC<Props> = (props) => {
  const ctx = useMappingPage(props)

  usePromptBeforeUnload()

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Left>
            <Page.HeaderTitle>応募者一括登録</Page.HeaderTitle>
            <SubTextWrapper>
              <LinkToHelp />
            </SubTextWrapper>
          </Left>
        </Page.Header>
        <Page.Body>
          {ctx.errorMessageOnTop && (
            <ErrorBoxWrapper ref={ctx.topErrorRef}>
              <ErrorBox size="s">{ctx.errorMessageOnTop}</ErrorBox>
            </ErrorBoxWrapper>
          )}
          <SettingsSection title="取込設定">
            <FormWrapper>
              <FormRow title="取込オプション" subtitle="一般権限への共有">
                <RadioGroup
                  {...ctx.register('selectionSharing')}
                  options={selectionSharingOptions}
                  direction="row"
                />
              </FormRow>
            </FormWrapper>
            <Label>応募者情報のひも付け</Label>
            <TableWrapper>
              <Table>
                <Thead>
                  <TRow>
                    <Th property="rowID">
                      <TableHeaderTxt>CSV列</TableHeaderTxt>
                    </Th>
                    <Th property="csvHeader">
                      <TableHeaderTxt>CSV1行目（項目名）</TableHeaderTxt>
                    </Th>
                    <Th property="csvFirstData">
                      <TableHeaderTxt>CSV2行目（データ）</TableHeaderTxt>
                    </Th>
                    <Th property="target">
                      <TableHeaderTxt>
                        インタビューメーカーの項目名
                      </TableHeaderTxt>
                    </Th>
                  </TRow>
                </Thead>
                <TBody>
                  {props.csvData.csvHeader.map((header, i) => (
                    <TRow key={i}>
                      <Td property="rowID">
                        <TableBodyTxt>{i + 1}</TableBodyTxt>
                      </Td>
                      <Td property="csvHeader">
                        <TableBodyLineClampedTxt>
                          {header}
                        </TableBodyLineClampedTxt>
                      </Td>
                      <Td property="csvFirstData">
                        {props.csvData.csvBody[0].length > i && (
                          <TableBodyLineClampedTxt>
                            {props.csvData.csvBody[0][i]}
                          </TableBodyLineClampedTxt>
                        )}
                      </Td>
                      <Td property="target">
                        <Dropdown
                          {...ctx.register(`targets.${i}`)}
                          size="max"
                          onBlurError={(() => {
                            const target =
                              ctx.errors.targets && ctx.errors.targets[i]
                            return target ? target.message : ''
                          })()}
                          options={ctx.getTargetOptions(i)}
                          value={ctx.getValues(`targets.${i}`)}
                          placeholder="未選択"
                          allowEmpty
                        />
                      </Td>
                    </TRow>
                  ))}
                </TBody>
              </Table>
            </TableWrapper>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_previous_button_on_batch_new_applicants_mapping',
              }}
              onClick={props.goPrevious}
            >
              前に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              comlinkPushParams={{
                action: 'click_next_button_on_batch_new_applicants_mapping',
              }}
              onClick={ctx.onSubmit}
            >
              内容を確認
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
      <CSVUploadValidationErrorModal
        title="取込エラー"
        errorBoxText="ファイルを修正して再度アップロードしてください。"
        errorLimit={10}
        closeComlinkAction="click_error_modal_close_button_on_batch_new_applicants_mapping"
        active={ctx.validationErrorModalActive}
        detailValidationErrors={ctx.validationErrors}
        close={ctx.closeValidationErrorModal}
      />
    </PageLayout>
  )
}

const Left = styled.div`
  display: flex;
`

const SubTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`

const ErrorBoxWrapper = styled.div`
  margin: 16px 0 20px;
`

const FormWrapper = styled.div`
  margin: 20px 16px;
`
const Label = styled(Txt)`
  word-break: keep-all;
  margin: 20px 16px;
`

const TableWrapper = styled.div`
  margin: 0 48px;
`

const Table = styled.table``

const Thead = styled.thead``

const TBody = styled.tbody`
  margin-top: 8px;
`

const TRow = styled.tr`
  display: flex;
  align-items: center;
  &:nth-child(even) {
    background-color: ${theme.color.gray[5]};
  }
`

type Property = 'rowID' | 'csvHeader' | 'csvFirstData' | 'target'

const propertyHeaderStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    flex: 0 0 282px;
  `,
}

const propertyBodyStyles: Record<Property, FlattenSimpleInterpolation> = {
  rowID: css`
    flex: 0 0 60px;
    padding: 0;
    justify-content: center;
  `,
  csvHeader: css`
    flex: 0 0 239px;
  `,
  csvFirstData: css`
    flex: 1 0 279px;
  `,
  target: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 0 0 282px;
    white-space: nowrap;
  `,
}

const Th = styled.th<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  margin-left: 2px;
  background-color: ${theme.color.gray[4]};
  ${({ property }) => propertyHeaderStyles[property]}
`

const Td = styled.td<{ property: Property }>`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  margin-left: 2px;
  ${({ property }) => propertyBodyStyles[property]}
`

const TableHeaderTxt = styled(Txt)({
  size: 's',
})

const TableBodyTxt = styled(Txt)({
  size: 's',
})

const TableBodyLineClampedTxt = styled(LineClampedTxt).attrs({
  line: 2,
  size: 's',
})``
