import React from 'react'
import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import failedImage from './failed.svg'

export const FailedStatus = (): React.ReactElement => {
  return (
    <Wrapper>
      <ImageWrapper>
        <WebInterviewStatusImage src={failedImage} alt="" />
      </ImageWrapper>
      <Txt>録画データの生成に失敗しました</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 40%;
  max-width: 300px;
`

const WebInterviewStatusImage = styled.img`
  margin-top: 28px;
  margin-bottom: 30px;
  width: 100%;
`
