import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import { captureException } from '@sentry/react'
import { RevokeRequestSelectionRecInterviewAnswerModal as Modal } from './RevokeRequestSelectionRecInterviewAnswerModal'
import { useRevokeRequestSelectionRecInterviewAnswer } from './useRevokeRequestSelectionRecInterviewAnswer'

type Props = {
  active: boolean
  applicantGuid: string
  selectionStepGuid: string
  close: () => void
}
export const RevokeRequestSelectionRecInterviewAnswerModal: React.VFC<Props> = (
  props
) => {
  const { mutateAsync } = useRevokeRequestSelectionRecInterviewAnswer()

  const request = useCallback(async () => {
    try {
      await mutateAsync({
        applicantGuid: props.applicantGuid,
        selectionStepGuid: props.selectionStepGuid,
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast('提出依頼を取消しました')
    props.close()
  }, [props, mutateAsync])

  const onClose = useCallback(() => {
    props.close()
  }, [props])

  return <Modal active={props.active} onClose={onClose} onRevoke={request} />
}
