import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { Modal, theme, Txt, CheckBox } from '@blue-agency/rogue'
import { toast } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import * as Sentry from '@sentry/react'
import styled from 'styled-components'
import { useToggle } from '@/hooks/useToggle'
import { ContentForList } from '../Content'
import { useArchiveVideoSeminarContent } from './useArchiveVideoSeminarContent'

type Props = {
  videoSeminarGuid: string
  videoSeminarContent: ContentForList
  onClose: () => void
}
export const DeleteContentModal: React.VFC<Props> = (props) => {
  const [isChecked, toggle] = useToggle(false)

  const { mutateAsync } = useArchiveVideoSeminarContent()

  const deleteVideoSeminarContent = useCallback(async () => {
    try {
      await mutateAsync({
        videoSeminarGuid: props.videoSeminarGuid,
        videoSeminarContentGuid: props.videoSeminarContent.guid,
      })
    } catch (e) {
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
    toast('コンテンツを削除しました')
    props.onClose()
  }, [mutateAsync, props])

  return (
    <Modal
      active
      title="コンテンツを削除"
      onClose={props.onClose}
      size="l"
      stopScroll
    >
      <ModalContent>
        <Description>
          「{props.videoSeminarContent.title}」を削除します。
        </Description>
        <Txt bold>
          削除後は、お問い合わせいただいても復元できませんのでご注意ください。
        </Txt>
      </ModalContent>
      <WrapperCheckBox>
        <Txt>確認</Txt>
        <WrapperText>
          <RequiredTxt>必須</RequiredTxt>
          <CheckBox
            name="delete"
            checkboxLabel="該当のコンテンツを完全に削除"
            onChange={toggle}
          />
        </WrapperText>
      </WrapperCheckBox>
      <ButtonArea>
        <CancelButton
          onClick={props.onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_delete_video_seminar_content_modal',
          }}
        >
          キャンセル
        </CancelButton>
        <SubmitButton
          onClick={deleteVideoSeminarContent}
          comlinkPushParams={{
            action: 'click_delete_button_on_delete_video_seminar_content_modal',
          }}
          disabled={!isChecked}
        >
          削除
        </SubmitButton>
      </ButtonArea>
    </Modal>
  )
}
const WrapperText = styled.div`
  display: flex;
  align-content: center;
  gap: 10px;
`

const RequiredTxt = styled(Txt).attrs({
  color: theme.color.red[2],
  bold: true,
  size: 'xs',
})``

const WrapperCheckBox = styled.div`
  border-top: 1px solid ${theme.color.gray[4]};
  display: flex;
  gap: 108px;
  padding: 20px 20px 0 20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 100px 20px;
`

const Description = styled(Txt)``

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const CancelButton = styled(TertiaryButton).attrs({
  widthSize: 'L1',
})``

const SubmitButton = styled(DangerButton).attrs({
  widthSize: 'L1',
})`
  margin-left: 20px;
`
