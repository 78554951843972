import { useCallback, useEffect, useMemo, useState } from 'react'
import { StartRecInterviewTemplateFileUploadResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { generatePath } from 'react-router'
import { useModal } from '@/hooks/useModal'
import { useMultipartUpload } from '@/hooks/useMultipartUpload'
import {
  useRequestCompleteRecInterviewTemplateFileUpload,
  useRequestStartRecInterviewTemplateFileUpload,
} from '@/services/bffService'
import { useGetQuestionTemplate } from '@/services/questionTemplateService'
import { Form, formSchema } from '@/services/questionTemplateService/formSchema'
import { INTERNAL_PATHS } from '@/services/urlService'
import { validateFiles } from '../RecInterviewTemplateNewPage/steps/video/fileValidator'
import { EditQuestionTemplatePageContainer } from './EditQuestionTemplatePageContainer'

const errorMessage = {
  general: 'アップロードに失敗しました。もう一度お試しください。',
  fileSizeLimitExceeded:
    'ファイルが大きすぎるため、アップロードできませんでした。\n※アップロードファイルのサイズ上限は200MBです。',
}

export function useInputPage() {
  const uploadingModal = useModal()
  const [abortController, setAbortController] = useState(new AbortController())
  const multipartUpload = useMultipartUpload(abortController)
  const { requestStartRecInterviewTemplateFileUpload } =
    useRequestStartRecInterviewTemplateFileUpload()
  const { requestCompleteRecInterviewTemplateFileUpload } =
    useRequestCompleteRecInterviewTemplateFileUpload()

  const [errorMessageOnTop, setErrorMessageOnTop] = useState('')

  const { completeInput, questionTemplateGuid } =
    EditQuestionTemplatePageContainer.useContainer()
  const { data: questionTemplate, isLoading } =
    useGetQuestionTemplate(questionTemplateGuid)
  const { register, handleSubmit, formState, setValue, watch } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  })

  const onSubmit = useMemo(
    () => handleSubmit(completeInput),
    [completeInput, handleSubmit]
  )

  const preventSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.key === 'Enter' && e.preventDefault()
    },
    []
  )

  const onSelectFiles = useCallback(
    async (files: File[]) => {
      setErrorMessageOnTop('')

      const result = validateFiles(files)
      if (result.type === 'error') {
        setErrorMessageOnTop(result.message)
        return
      }
      const file = files[0]

      setValue('videoFile', {
        key: '',
        url: '',
        name: file.name,
        type: file.type,
      })

      const cleanup = () => {
        uploadingModal.close()
        multipartUpload.resetState()
        setValue('videoFile', undefined)
      }

      uploadingModal.open()

      const chunks = multipartUpload.splitBlob(file)
      let startUploadRes: StartRecInterviewTemplateFileUploadResponse
      try {
        startUploadRes = await requestStartRecInterviewTemplateFileUpload(
          chunks.length,
          file.type
        )
      } catch (err) {
        setErrorMessageOnTop(errorMessage.general)
        cleanup()
        throw err
      }

      let etagList: string[]
      try {
        etagList = await multipartUpload.upload(
          chunks.map((chunk, i) => ({
            blob: chunk,
            url: startUploadRes.getUrlsList()[i],
          }))
        )
        /* eslint-disable  @typescript-eslint/no-explicit-any */
      } catch (err: any) {
        if (err.name === 'AbortError') {
          // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-4.html#defaulting-to-the-unknown-type-in-catch-variables---useunknownincatchvariables
          cleanup()
          return
        }
        setErrorMessageOnTop(errorMessage.general)
        cleanup()
        throw err
      }

      try {
        await requestCompleteRecInterviewTemplateFileUpload(
          startUploadRes.getKey(),
          startUploadRes.getUploadId(),
          etagList
        )
      } catch (err) {
        setErrorMessageOnTop(errorMessage.general)
        cleanup()
        throw err
      }

      uploadingModal.close()
      multipartUpload.resetState()
      const url = URL.createObjectURL(file)
      setValue('videoFile', {
        key: startUploadRes.getKey(),
        url,
        name: file.name,
        type: file.type,
      })
    },
    [
      setValue,
      multipartUpload,
      uploadingModal,
      requestStartRecInterviewTemplateFileUpload,
      requestCompleteRecInterviewTemplateFileUpload,
    ]
  )

  const questionTemplatePath = generatePath(INTERNAL_PATHS.questionTemplate, {
    questionTemplateGuid,
  })

  useEffect(() => {
    // フォーマットはフォームとして表示されないので、手動でセットす
    if (questionTemplate) {
      register('format')
      setValue('format', questionTemplate.format)

      register('videoFile')
      if (questionTemplate.videoFileKey && questionTemplate.videoPresignedUrl) {
        setValue('videoFile', {
          key: questionTemplate.videoFileKey,
          url: questionTemplate.videoPresignedUrl,
          name: '',
          type: '',
        })
      }
    }
  }, [questionTemplate, register, setValue])

  const onUnselect = useCallback(() => {
    setValue('videoFile', undefined)
  }, [setValue])

  const onCancelUpload = useCallback(() => {
    abortController.abort()
    setAbortController(new AbortController())
  }, [abortController])

  return {
    isLoading,
    register,
    errors: formState.errors,
    isSubmitting: formState.isSubmitting,
    onSubmit,
    preventSubmit,
    questionTemplate,
    questionTemplatePath,
    watch,
    onSelectFiles,
    onUnselect,
    onCancelUpload,
    multipartUpload,
    uploadingModal,
    errorMessageOnTop,
  }
}
