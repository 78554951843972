import { Modal, WeakColorSeparator, ErrorBox } from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { bufferHours } from '@/services/recInterviewTemplateService'
import { DeadlinePicker } from '../DeadlinePicker'

export type ErrorType = 'pastDate'
type Props = {
  active: boolean
  deadline: Date
  error?: ErrorType
  onClose: () => void
  onSendMail: () => void
  onChangeDeadline: (date: Date | null) => void
}

const errorMessageMap: Record<ErrorType, string> = {
  pastDate: `提出期限は現在時刻から${bufferHours}時間以上先で設定してください。`,
}

export const ChangeSelectionRecInterviewDeadlineModal: React.VFC<Props> = (
  props
) => {
  return (
    <Modal
      active={props.active}
      title="提出期限を変更"
      onClose={props.onClose}
      size="m"
      overflowVisible
    >
      <ModalContent>
        {props.error && (
          <ErrorMessage>{errorMessageMap[props.error]}</ErrorMessage>
        )}
        <Row title="提出期限" required>
          <DeadlinePicker
            deadline={props.deadline}
            onChangeDeadline={props.onChangeDeadline}
          />
        </Row>
      </ModalContent>
      <Separator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_cancel_button_on_change_selection_rec_interview_deadline_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={props.onSendMail}
            disabled={!!props.error}
            comlinkPushParams={{
              action:
                'click_send_mail_button_on_change_selection_rec_interview_deadline_modal',
            }}
          >
            変更
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ErrorMessage = styled(ErrorBox)`
  margin-top: 20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const Row = styled(FormRow).attrs({ titleWidth: '130px', subtitleWidth: '0' })`
  margin-top: 20px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
