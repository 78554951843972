import React, { useRef, useMemo } from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { WebInterviewStatus } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { theme, A, Txt } from '@blue-agency/rogue'
import { useHistory } from 'react-router'
import { useMount, useUnmount } from 'react-use'
import styled, { css } from 'styled-components'
import { GoogleCalendarLink } from '@/components/GoogleCalendarLink'
import { Loading } from '@/components/Loading'
import { OutlookCalendarLink } from '@/components/OutlookCalendarLink'
import { WebInterviewStatusComponent } from '@/components/WebInterviewStatusComponent'
import { useModal } from '@/hooks/useModal'
import { AuthzContainer } from '@/services/authzService'
import { INTERNAL_PATHS } from '@/services/urlService'
import { useWebInterviewsStorage } from '@/services/webInterviewService'
import { ChangeWebInterviewGuideModal } from './ChangeWebInterviewGuideModal'
import { EditableContent } from './EditableContent'
import { MinutesLink } from './MinutesLink'
import { StatusPanel } from './StatusPanel'
import { Timeline } from './Timeline'
import { WebInterviewAssignmentInner } from './WebInterviewAssignmentInner'
import { WebInterviewAssignmentModal } from './WebInterviewAssignmentModal'
import { WebInterviewGuide } from './WebInterviewGuide'
import { WebInterviewGuideName } from './WebInterviewGuideName'
import { WebInterviewName } from './WebInterviewName'
import { WebInterviewPageContainer } from './WebInterviewPageContainer'
import { WebInterviewScheduleAndDurationInner } from './WebInterviewScheduleAndDurationInner'
import { WebInterviewTimeManagementModal } from './WebInterviewTimeManagementModal'

export const WebInterviewPage: React.VFC = provide(WebInterviewPageContainer)(
  () => {
    const pageCtx = WebInterviewPageContainer.useContainer()
    const {
      active: timeManagementModalActive,
      open: openTimeManagementModal,
      close: closeTimeManagementModal,
    } = useModal()
    const {
      active: assignmentModalActive,
      open: openAssignmentModal,
      close: closeAssignmentModal,
    } = useModal()
    const {
      active: changeGuideModalActive,
      open: openChangeGuideModal,
      close: closeChangeGuideModal,
    } = useModal()
    const { permittedRpcs } = AuthzContainer.useContainer()

    const wis = useWebInterviewsStorage()
    const history = useHistory()
    const guidRef = useRef('')

    useUnmount(() => {
      wis.clearStorages(guidRef.current)
    })

    useMount(() => {
      window.addEventListener('beforeunload', () => {
        wis.clearStorages(guidRef.current)
      })
    })

    const isScheduleAndDurationEditable = useMemo(() => {
      if (permittedRpcs === undefined) {
        return false
      }

      return (
        permittedRpcs.includes('UpdateWebInterviewDuration') &&
        permittedRpcs.includes('UpdateWebInterviewScheduledStartTime')
      )
    }, [permittedRpcs])

    if (pageCtx.isLoading) {
      return <Loading />
    }

    const { webInterview } = pageCtx
    guidRef.current = webInterview.guid

    const backToList = () => {
      const listType = wis.getListToBack(webInterview.guid)
      const query = wis.getQuery(listType, webInterview.guid)
      const path =
        listType === 'webInterviews'
          ? INTERNAL_PATHS.webInterviews
          : INTERNAL_PATHS.assignedWebInterviews
      history.push({
        pathname: path,
        search: query,
      })
    }

    const assigneesEmails = webInterview?.assignees
      ?.filter((assigne) => assigne.role === 'interviewer')
      .map((assigne) => assigne.email)
      .join(',')

    return (
      <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
        <Wrapper>
          <Header>
            <HeaderRow>
              <HeaderLeft>
                <WebInterviewStatusComponent status={webInterview.status} />
                <WebInterviewName
                  value={webInterview.name}
                  editable={
                    permittedRpcs?.includes('UpdateWebInterviewName') ?? false
                  }
                  editing={pageCtx.webInterviewNameEditing}
                  startEditing={pageCtx.startEditingWebInterviewName}
                  finishEditing={pageCtx.finishEditingWebInterviewName}
                  ref={pageCtx.webInterviewNameRef}
                />
              </HeaderLeft>
              <HeaderRight>
                {webInterview.status < WebInterviewStatus.INTERVIEWING && (
                  <>
                    <GoogleCalendarTxt size="s">
                      <GoogleCalendarLink
                        name={pageCtx.webInterview.name}
                        intervieweeUrl={pageCtx.webInterview.intervieweeUrl}
                        interviewerUrl={pageCtx.webInterview.interviewerUrl}
                        scheduledStartTime={
                          pageCtx.webInterview.scheduledStartTime
                        }
                        durationMinutes={pageCtx.webInterview.durationMinutes}
                        assigneesEmails={assigneesEmails}
                      />
                    </GoogleCalendarTxt>
                    <OutlookCalendarTxt size="s">
                      <OutlookCalendarLink
                        name={pageCtx.webInterview.name}
                        intervieweeUrl={pageCtx.webInterview.intervieweeUrl}
                        interviewerUrl={pageCtx.webInterview.interviewerUrl}
                        scheduledStartTime={
                          pageCtx.webInterview.scheduledStartTime
                        }
                        durationMinutes={pageCtx.webInterview.durationMinutes}
                        assigneesEmails={assigneesEmails}
                      />
                    </OutlookCalendarTxt>
                  </>
                )}
                {permittedRpcs?.includes('SearchWebInterviews') && (
                  <div>
                    <BackToWebInterviewsLink onClick={backToList}>
                      <A
                        comlinkPushParams={{
                          action: 'click_back_to_web_interviews_page',
                        }}
                      >
                        一覧に戻る
                      </A>
                    </BackToWebInterviewsLink>
                  </div>
                )}
              </HeaderRight>
            </HeaderRow>
            <HeaderRow>
              <HeaderLeft>
                <WebInterviewDuration>
                  <EditableContent
                    editable={isScheduleAndDurationEditable}
                    onClick={openTimeManagementModal}
                  >
                    <WebInterviewScheduleAndDurationInner
                      scheduledStartTime={webInterview.scheduledStartTime}
                      durationMinutes={webInterview.durationMinutes}
                    />
                  </EditableContent>
                </WebInterviewDuration>
                <WebInterviewAssignment>
                  <EditableContent
                    editable={
                      permittedRpcs?.includes('UpdateWebInterviewAssignment') ??
                      false
                    }
                    onClick={openAssignmentModal}
                  >
                    <WebInterviewAssignmentInner
                      assignment={webInterview.assignment}
                      assignees={webInterview.assignees}
                    />
                  </EditableContent>
                </WebInterviewAssignment>
                {permittedRpcs?.includes('UpdateWebInterviewDuration') &&
                  // durationModalActive が falsy のときはマウント自体をしないようにしている
                  // これにより、モーダルをオープンしたときに毎回入力欄にデフォルト値が正しくセットされる
                  timeManagementModalActive && (
                    <WebInterviewTimeManagementModal
                      active
                      onClose={closeTimeManagementModal}
                    />
                  )}
                {permittedRpcs?.includes(
                  'ListWebInterviewAssigneeCandidates'
                ) && (
                  <WebInterviewAssignmentModal
                    active={assignmentModalActive}
                    onClose={closeAssignmentModal}
                  />
                )}
                <EditableContent
                  editable={
                    permittedRpcs?.includes('ChangeWebInterviewGuide') ?? false
                  }
                  onClick={openChangeGuideModal}
                >
                  <WebInterviewGuideName guideName={webInterview.guide?.name} />
                </EditableContent>
                {permittedRpcs?.includes('ChangeWebInterviewGuide') && (
                  <ChangeWebInterviewGuideModal
                    active={changeGuideModalActive}
                    onClose={closeChangeGuideModal}
                  />
                )}
              </HeaderLeft>
            </HeaderRow>
          </Header>
          <Body>
            <BodyMain>
              {pageCtx.hasMinutes && (
                <MinutesLink minutesUrl={webInterview.minutesUrl} />
              )}
              <WebInterviewPanel
                ref={pageCtx.webInterviewPanelRef}
                width={pageCtx.webInterviewPanelWidth}
                hasRecording={pageCtx.hasRecording}
              >
                <StatusPanel
                  webInterviewStatus={webInterview.status}
                  recordingStatus={webInterview.recordingStatus}
                  recordingUrl={webInterview.recordingUrl}
                />
              </WebInterviewPanel>
              {webInterview.guide !== undefined && (
                <WebInterviewGuideWrapper>
                  <WebInterviewGuide parts={webInterview.guide?.parts} />
                </WebInterviewGuideWrapper>
              )}
            </BodyMain>
            <BodySide>
              <Timeline webInterview={webInterview} />
            </BodySide>
          </Body>
        </Wrapper>
      </PageLayoutWithGlonavi>
    )
  }
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const Header = styled.div`
  background-color: ${theme.color.gray[5]};
  padding: 15px 24px 0px 27px;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderLeft = styled.div`
  display: flex;
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const WebInterviewDuration = styled.div`
  display: flex;
  align-items: center;
`

const WebInterviewAssignment = styled.div`
  display: flex;
  align-items: center;
`

const BackToWebInterviewsLink = styled.div`
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.m};
  cursor: pointer;
`

const Body = styled.div`
  ${({ theme }) =>
    !theme.responsive.spPortrait &&
    css`
      flex: 1;
      display: flex;
    `}
  background-color: ${theme.color.white[1]};
  padding: 20px 20px;
`

const BodyMain = styled.div`
  min-width: 703px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WebInterviewGuideWrapper = styled.div`
  margin: 12px 0;
  max-height: 300px;
`

const WebInterviewPanel = styled.div<{ width?: number; hasRecording: boolean }>`
  width: 100%;
  background-color: ${theme.color.gray[5]};

  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ width, hasRecording }) => {
    if (width && hasRecording) {
      return css`
        height: ${width * (1 / 1.7)}px;
      `
    }
  }}
`

const BodySide = styled.div`
  ${({ theme }) =>
    !theme.responsive.spPortrait &&
    css`
      padding-left: 20px;
      width: 470px;
    `}
`

const GoogleCalendarTxt = styled(Txt)`
  margin-right: 8px;
`

const OutlookCalendarTxt = styled(Txt)`
  margin-right: 16px;
`
