import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CompleteVideoSeminarMovieUploadRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCompleteVideoSeminarMovieUpload() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCompleteVideoSeminarMovieUpload = useCallback(
    async (
      videoSeminarGuid: string,
      key: string,
      uploadId: string,
      etags: ReadonlyArray<string>
    ) => {
      const metadata = await buildMetadata()
      const req = new CompleteVideoSeminarMovieUploadRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)
      req.setKey(key)
      req.setUploadId(uploadId)
      req.setPartsList(
        etags.map((e, i) => {
          const p = new CompleteVideoSeminarMovieUploadRequest.Part()
          p.setEtag(e)
          p.setPartNumber(i + 1)
          return p
        })
      )

      return callBizHuttBffService(
        'completeVideoSeminarMovieUpload',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestCompleteVideoSeminarMovieUpload }
}
