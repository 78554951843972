import React from 'react'
import {
  AuthContainer,
  getInternalUrls,
  NewWindowChatBot,
} from '@blue-agency/im-shared-front'
import { Responsive, useIsIPad, useResponsive } from '@blue-agency/rogue'
import { Switch, Route } from 'react-router-dom'
import { INTERNAL_PATHS } from '@/services/urlService'
import { AuthorizedRoutes } from './AuthorizedRoutes'
import { EmbeddedRoutes } from './EmbeddedRoutes'
import { EmbeddedUnAuthorizedErrorBoundary } from './EmbeddedUnAuthorizedErrorBoundary'
import { UnauthorizedRoutes } from './UnauthorizedRoutes'

// 一括ログアウト時にiframeでログアウトするためのエンドポイント
// 未ログインでもアクセスされる
// e.g. anakinログイン済み・hutt未ログインのときanakinでログアウト
const FrontchannelLogout = () => {
  const { authClient } = AuthContainer.useContainer()
  authClient.frontchannelLogout()
  return null
}

export const AuthRoutes: React.VFC = () => {
  return (
    <Switch>
      <Route
        exact
        path={getInternalUrls().frontchannelLogout.bizHuttFront}
        component={FrontchannelLogout}
      />
      <Route
        path={INTERNAL_PATHS.embedded}
        component={() => (
          <AuthContainer.Provider initialState={{ embedded: true }}>
            <EmbeddedUnAuthorizedErrorBoundary>
              <EmbeddedRoutes />
            </EmbeddedUnAuthorizedErrorBoundary>
          </AuthContainer.Provider>
        )}
      />
      <InternalRoutes />
    </Switch>
  )
}

const InternalRoutes = () => {
  const { isLoggedIn } = AuthContainer.useContainer()
  const { responsive } = useResponsive()
  const { isIPad } = useIsIPad()

  if (isLoggedIn) {
    return (
      <>
        <AuthorizedRoutes />
        {showChatBot(responsive, isIPad) && <NewWindowChatBot />}
      </>
    )
  }

  return (
    <>
      <UnauthorizedRoutes />
      {showChatBot(responsive, isIPad) && <NewWindowChatBot />}
    </>
  )
}

export const showChatBot = (
  responsive: Responsive,
  isIPad: boolean
): boolean => {
  if (responsive.pc) {
    return true
  }

  if (isIPad && responsive.spLandscape) {
    return true
  }

  return false
}
