import { useCallback, useMemo } from 'react'
import { theme } from '@blue-agency/rogue'
import {
  LineChart as LibLineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  TooltipProps,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'
import { PlaybackLog } from '@/services/videoSeminarService'
import { FormatTime, formatTime } from './FormatTime'
import { TooltipTxt, TooltipTxtStrong, TooltipWrapper } from './tooltip'

type Props = {
  totalCounts: number
  movieDurationSeconds: number
  logs: PlaybackLog[]
  onClick?: (log: PlaybackLog) => void
}

/** 視聴維持グラフ（コンテンツ単位用） */
export const LineChart: React.VFC<Props> = (props) => {
  const data = useMemo(() => {
    return props.logs.map((log, i) => {
      const rate =
        props.totalCounts === 0
          ? 0
          : Math.floor((log.playbackCount / props.totalCounts) * 100)

      /**
       * レポート分析時には10sなどのintervalでまとめて計算している。
       * 例えば1:28の動画の分析データの最後のチャンクは1:21~1:28ではなく1:21~1:30のデータとなる。
       * x軸の最大値としては動画の長さを設定したいため、最後のチャンクだけcurrentTimeを置き換える。
       */
      const isLastLog = i === props.logs.length - 1
      const currentTime = isLastLog
        ? props.movieDurationSeconds
        : log.playbackTimeSeconds

      return {
        rate,
        currentTime,
      }
    })
  }, [props])

  const onClick = useCallback(
    (c: CategoricalChartState) => {
      if (props.onClick && c.activePayload) {
        props.onClick({
          playbackTimeSeconds: Number(c.activeLabel),
          playbackCount: c.activePayload[0].value,
        })
      }
    },
    [props]
  )

  return (
    <LibLineChart width={872} height={200} data={data} onClick={onClick}>
      <XAxis
        dataKey="currentTime"
        tickLine={false}
        fontSize={theme.fontSize.s}
        interval="preserveStartEnd"
        tickFormatter={(value: number) => {
          // x軸のラベルには0sと動画の長さ(最大値)だけ表示する
          if (value !== 0 && value !== props.movieDurationSeconds) {
            return ''
          }
          return formatTime(value)
        }}
      />
      <YAxis
        unit="%"
        tickLine={false}
        fontSize={theme.fontSize.s}
        domain={[0, (dataMax: number) => Math.max(dataMax, 100)]}
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line
        type="monotone"
        legendType="none"
        strokeWidth={2}
        dot={false}
        dataKey="rate"
        stroke={theme.color.green[4]}
        activeDot={{ r: 6 }}
      />
    </LibLineChart>
  )
}

const CustomTooltip = (props: TooltipProps<number, string>) => {
  if (!props.active) return null

  return (
    <TooltipWrapper>
      <TooltipTxt>
        <FormatTime timeSeconds={props.label} />
      </TooltipTxt>
      {props.payload && (
        <TooltipTxt>
          <TooltipTxtStrong>{props.payload[0].value}</TooltipTxtStrong>%
          (維持率)
        </TooltipTxt>
      )}
    </TooltipWrapper>
  )
}
