import React from 'react'
import {
  PageLayout,
  FixedBottomFormPage as Page,
} from '@blue-agency/im-shared-front'
import { WeakColorSeparator, Txt as _Txt, theme } from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { Box } from '@/components/Box'
import { SettingsSection } from '@/components/SettingsSection'
import { VideoPlayer } from '@/components/VideoPlayer'
import { SectionInner } from '@/components/form'
import {
  getQuestionFormatOption,
  getVideoQuestionlMaxDurationSecondsLabel,
  getVideoQuestionlMaxRecCountLabel,
} from '@/services/questionTemplateService'
import { useConfirmPage } from './useConfirmPage'

export const ConfirmPage: React.VFC = () => {
  const { form, register, isUpdateLoading, backToInput } = useConfirmPage()

  const formatLabel = getQuestionFormatOption(form.format)?.label
  assertIsDefined(formatLabel)

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Page.Wrapper>
        <Page.Header>
          <Page.HeaderTitle>設問詳細編集</Page.HeaderTitle>
        </Page.Header>
        <Page.Body>
          <SettingsSection title="内容を確認">
            <SectionInner>
              <Row title="設問名">
                <Txt>{form.name}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問文">
                <Txt>{form.content}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問の注釈">
                <Txt>{form.note}</Txt>
              </Row>
              <WeakColorSeparator />
              <Row title="設問動画">
                {form.videoFile && (
                  <Box width={270} height={152}>
                    <VideoPlayer src={form.videoFile.url} />
                  </Box>
                )}
              </Row>
              <WeakColorSeparator />
              <Row title="回答" subtitle="形式">
                <Txt>{formatLabel}</Txt>
              </Row>
              {form.format === 'video' && (
                <>
                  <InnerRow subtitle="回答時間制限">
                    <Txt>
                      {getVideoQuestionlMaxDurationSecondsLabel(
                        form.maxDurationSeconds ?? '0'
                      )}
                    </Txt>
                  </InnerRow>
                  <InnerRow subtitle="撮影回数制限">
                    <Txt>
                      {getVideoQuestionlMaxRecCountLabel(
                        form.maxRecCount ?? '0'
                      )}
                    </Txt>
                  </InnerRow>
                </>
              )}
            </SectionInner>
          </SettingsSection>
        </Page.Body>
        <Page.Bottom>
          <Page.BottomButtons>
            <TertiaryButton
              widthSize="L2"
              comlinkPushParams={{
                action:
                  'click_edit_rec_interview_question_template_form_back_to_input_button',
              }}
              onClick={backToInput}
            >
              入力画面に戻る
            </TertiaryButton>
            <PrimaryButton
              widthSize="L2"
              disabled={isUpdateLoading}
              comlinkPushParams={{
                action:
                  'click_edit_rec_interview_question_template_form_submit_button',
              }}
              onClick={register}
            >
              保存
            </PrimaryButton>
          </Page.BottomButtons>
        </Page.Bottom>
      </Page.Wrapper>
    </PageLayout>
  )
}

const Row = styled(FormRow).attrs({
  titleWidth: '120px',
  subtitleWidth: '80px',
})`
  padding: 20px 0;
`

const InnerRow = styled(Row)`
  padding-top: 0;
`

const Txt = styled(_Txt).attrs({ size: 'm' })``
