import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateSelectionStatusRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SelectionStatus } from '@blue-agency/proton/im'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateSelectionStatusRequestValue = {
  guid: string
  status: SelectionStatus
}

export function useRequestUpdateSelectionStatus() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateSelectionStatus = useCallback(
    async (value: UpdateSelectionStatusRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateSelectionStatusRequest()
      req.setGuid(value.guid)
      req.setStatus(value.status)

      return callBizHuttBffService(
        'updateSelectionStatus',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUpdateSelectionStatus }
}
