import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ArchiveWebInterviewGuideRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestDeleteWebInterviewGuide() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteWebInterviewGuide = useCallback(
    async (guid: string) => {
      const metadata = await buildMetadata()

      const req = new ArchiveWebInterviewGuideRequest()

      req.setWebInterviewGuideGuid(guid)

      return callBizHuttBffService(
        'archiveWebInterviewGuide',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestDeleteWebInterviewGuide,
  }
}
