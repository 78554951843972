import { useCallback, useState } from 'react'
import { Form, useApplicantForm } from '@/components/applicantForm'

const defaultValues: Form = {
  externalApplicantId: '',
  familyName: '',
  givenName: '',
  familyNameKana: '',
  givenNameKana: '',
  email: '',
  postalCode: '',
  address: '',
  gender: '',
  genderNote: '',
  birthDate: undefined,
  phoneNumber: '',
  educationalBackgroundsList: [
    {
      schoolName: '',
      faculty: '',
      note: '',
    },
  ],
  educationalBackgroundNote: '',
  workHistoriesList: [
    {
      companyName: '',
      jobDescription: '',
      note: '',
    },
  ],
  workHistoryNote: '',
  note: '',
}

export const useForm = () => {
  const applicantFormCtx = useApplicantForm(defaultValues)

  // 「住所」以下の詳細情報を入力するかどうか
  const [isDetailsEnabled, setIsDetailsEnabled] = useState(false)

  const enableDetails = useCallback(() => setIsDetailsEnabled(true), [])
  const disableDetails = useCallback(() => setIsDetailsEnabled(false), [])

  return {
    applicantFormCtx,

    isDetailsEnabled,
    enableDetails,
    disableDetails,
  }
}
