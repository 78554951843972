import { useEffect, useRef, useState } from 'react'
import { Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'

export const EllipsisTxt = styled(Txt)<{ disableEllipsis?: boolean }>`
  word-break: break-all;
  ${({ disableEllipsis }) =>
    !disableEllipsis &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

/**
 * @returns {React.RefObject<HTMLParagraphElement>}
 *  ellipsisTxtRef: EllipsisTxtに渡すref
 * @returns {boolean}
 *  isEllipsized: EllipsisTxtがellipsizeされているかどうか
 *  ※mount時にのみ計算しているため、その後スタイルが変更されてellipsizeされなくなった場合でも値は再計算されない
 */
export const useEllipsisTxt = () => {
  const ellipsisTxtRef = useRef<HTMLParagraphElement>(null)
  const [isEllipsized, setIsEllipsized] = useState(false)

  useEffect(() => {
    if (!ellipsisTxtRef.current) return
    setIsEllipsized(
      ellipsisTxtRef.current.offsetWidth !== ellipsisTxtRef.current.scrollWidth
    )
  }, [])

  return {
    ellipsisTxtRef,
    isEllipsized,
  }
}
