import * as yup from 'yup'
import {
  Due,
  DueTime,
  Question,
  QuestionRequired,
  Template,
} from '@/services/recInterviewTemplateService'

export type FormDueTime = Omit<DueTime, 'timestamp'> & { time: string }

export type FormDue = Omit<Due, 'time'> & { time: FormDueTime }

export type FormQuestion = Pick<Question, 'guid' | 'required'>

export type Form = Omit<
  Template,
  'guid' | 'allowRecordedVideo' | 'due' | 'questions'
> & {
  // NOTE: ラジオボタンで扱いやすくするため、フォームではbooleanではなくstringにする
  allowRecordedVideo: 'allowed' | 'notAllowed'
  due: FormDue
  questions: FormQuestion[]
  // NOTE:Templateとして必要ではないが、動画再生用にblobのObjectURLやpresignedURLを保持する
  introVideoURL: string
  outroVideoURL: string
}

const stringSchema = (max?: number) => {
  const schema = yup.string().defined()
  return max === undefined
    ? schema
    : schema.max(max!, `${max}以内で入力してください`)
}

const requiredStringSchema = (max?: number) =>
  stringSchema(max).required('入力してください').min(1, '入力してください')

const requiredNumberSchema = (min?: number, max?: number) => {
  let schema = yup.number().defined().required('入力してください')
  // NOTE: 時刻指定を実装する際には要件に応じてメッセージを調整すること
  schema = max === undefined ? schema : schema.max(max, '範囲外の値です')
  schema = min === undefined ? schema : schema.min(min, '範囲外の値です')
  return schema
}

export const dueSchema: yup.SchemaOf<FormDue> = yup
  .object()
  .defined()
  .required()
  .shape({
    type: requiredStringSchema().oneOf(['time', 'days']),
    time: yup
      .object()
      .defined()
      .shape({
        time: requiredStringSchema(),
        hour: requiredNumberSchema(9, 18),
      }),
    duration: yup
      .object()
      .defined()
      .shape({
        days: requiredNumberSchema(1, 60),
        hour: requiredNumberSchema(9, 18),
      }),
  })

const questionSchema: yup.SchemaOf<FormQuestion> = yup
  .object()
  .defined()
  .shape({
    guid: yup
      .string()
      .defined()
      .required('設問を選択してください')
      .min(1, '設問を選択してください'),
    required: yup
      .mixed()
      .oneOf<QuestionRequired>(['1', '0'])
      .defined()
      .required('入力してください'),
  })

export const formSchema: yup.SchemaOf<Form> = yup.object().shape({
  // step:basicInfo
  name: requiredStringSchema(30),
  allowRecordedVideo: yup
    .mixed<'allowed' | 'notAllowed'>()
    .oneOf(['allowed', 'notAllowed'])
    .required(),
  due: dueSchema,

  // step:intro
  introMessage: stringSchema(255),
  introVideoFileKey: yup.mixed().required(),
  introVideoURL: yup.mixed().required(),

  // step:questions
  questions: yup.array().of(questionSchema).defined().min(1).max(30),

  // step:outro
  outroMessage: stringSchema(255),
  outroVideoFileKey: yup.mixed().required(),
  outroVideoURL: yup.mixed().required(),
})
