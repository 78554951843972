import { useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { SelectionStepType } from '@blue-agency/proton/web/v2/im'
import { toast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CreateSelectionStepMasterRequestValue } from '@/services/bffService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService/customGrpcError'
import { useCreateSelectionStepMaster } from './useCreateSelectionStepMaster'

const formSchema: yup.SchemaOf<CreateSelectionStepMasterRequestValue> = yup
  .object({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
    type: yup.number().required(),
  })
  .defined()

type Args = {
  onSuccessCreate: () => void
}

export const useCreateModal = ({ onSuccessCreate }: Args) => {
  const { mutateAsync } = useCreateSelectionStepMaster()
  const [submitError, setSubmitError] = useState('')

  const resetSubmitError = useCallback(() => {
    setSubmitError('')
  }, [])

  const formCtx = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      type: SelectionStepType.SELECTION_STEP_TYPE_REC_INTERVIEW,
    },
  })

  const submit = useCallback(
    async (data: CreateSelectionStepMasterRequestValue) => {
      try {
        await mutateAsync(data)
      } catch (e) {
        if (e instanceof CustomBizHuttBffGrpcError) {
          if (
            e.hasMatchErrorDetail('NUM_SELECTION_STEP_MASTER_LIMIT_REACHED')
          ) {
            setSubmitError(
              '登録可能な選考ステップが上限に達しているため、登録できません。'
            )
            return
          }
        }
        Sentry.captureException(e)
        commonErrorToast()
        return
      }
      toast('選考ステップを保存しました')
      onSuccessCreate()
      formCtx.reset()
    },
    [mutateAsync, onSuccessCreate, formCtx]
  )

  const register = useCallback(() => {
    formCtx.handleSubmit(submit)()
  }, [formCtx, submit])

  return {
    formCtx,
    register,
    submitError,
    resetSubmitError,
  }
}
