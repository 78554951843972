import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  applicantName: string
  applicantNameKana?: string
}>
export const ApplicantNameHeader: React.VFC<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      <NameWrapper>
        <NameTxt>{props.applicantName}</NameTxt>
        <NameTxtKana>{props.applicantNameKana}</NameTxtKana>
      </NameWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
`

const NameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 8px 12px;
`

const NameTxt = styled(Txt)`
  margin-right: 8px;
`
const NameTxtKana = styled(Txt).attrs({
  size: 's',
  color: theme.color.gray[1],
})``
