import { useCallback } from 'react'
import { useRef } from 'react'
import {
  ClickOutsideListener,
  Icon,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Box } from '@/components/Box'
import { useModal } from '@/hooks/useModal'
import {
  getSelectionStepResultText,
  StepActionType,
  SelectionRecInterview,
  SelectionStepResult,
  SelectionSubStatus,
  SelectionStatus,
  SelectionStepAppendix,
  deadlineFormat,
  mapResultMeta,
} from '@/services/applicantService'
import { DisplaySelectionStepDetailModal } from '../DisplaySelectionStepDetailModal'
import { SelectionRecInterviewQuestions as _SelectionRecInterviewQuestions } from './SelectionRecInterviewQuestions'
import { getCurrentMenus, detailMenu } from './getCurrentMenus'

type Props = PropsWithClassName<{
  guid: string
  isCurrentSelectionStep: boolean
  name: string
  result: SelectionStepResult
  comment: string
  registrationRoute: string
  registerTime?: Date
  lastAssessTime?: Date
  selected: boolean
  selectionRecInterview?: SelectionRecInterview
  appendix?: SelectionStepAppendix
  status: SelectionStatus
  subStatus: SelectionSubStatus
  onClick: (guid: string) => void
  onMenuClick: (type: StepActionType, guid: string) => void
  organizationRecInterviewTranscriptionSetting: boolean
}>

export const SelectionStep: React.VFC<Props> = (props) => {
  const resultMeta = mapResultMeta(props.result)

  const menuRef = useRef<HTMLDivElement>(null)

  const menu = useModal()
  const displaySelectionStepDetailModal = useModal()

  const menus = getCurrentMenus(props.status, props.subStatus)

  const onClick = useCallback(() => {
    props.onClick(props.guid)
  }, [props])

  const onMenuIconClick = useCallback(() => {
    menu.active ? menu.close() : menu.open()
  }, [menu])

  const onMenuClick = useCallback(
    (action: StepActionType) => () => {
      props.onMenuClick(action, props.guid)
      menu.close()
      // FIXME: 1つ上の階層でdisplaySelectionStepDetailModalを管理する
      if (action === 'DETAIL') {
        displaySelectionStepDetailModal.open()
      }
    },
    [props, menu, displaySelectionStepDetailModal]
  )

  return (
    <Wrapper className={props.className}>
      <Title>
        <TitleTxt>{props.name}</TitleTxt>
      </Title>
      <TopArea>
        <Box display="flex">
          {props.selectionRecInterview && (
            <Txt>
              <GraySpan>テンプレート：</GraySpan>
              {props.selectionRecInterview.name}
            </Txt>
          )}
          <Box width="20px" />
          {props.selectionRecInterview?.deadline && (
            <Txt>
              <GraySpan>提出期限：</GraySpan>
              {deadlineFormat(props.selectionRecInterview.deadline)}
            </Txt>
          )}
        </Box>
        <ClickOutsideListener targetRef={menuRef} onClick={menu.close}>
          <MenuWrapper ref={menuRef}>
            <MenuIcon onClick={onMenuIconClick} />
            {menu.active && (
              <Menu>
                {props.isCurrentSelectionStep ? (
                  menus.length > 0 &&
                  menus.map((m) => (
                    <MenuItem key={m.action} onClick={onMenuClick(m.action)}>
                      <Txt size="s" color={m.color}>
                        {m.label}
                      </Txt>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem onClick={onMenuClick(detailMenu.action)}>
                    <Txt size="s" color={detailMenu.color}>
                      {detailMenu.label}
                    </Txt>
                  </MenuItem>
                )}
              </Menu>
            )}
          </MenuWrapper>
        </ClickOutsideListener>
      </TopArea>
      <Content onClick={onClick} selected={props.selected}>
        <EditIcon />
        <Result color={resultMeta.color}>
          <Icon name={resultMeta.iconName} width="22px" height="22px" />
          <ResultTxt color={resultMeta.color}>
            {getSelectionStepResultText(props.result)}
          </ResultTxt>
        </Result>
        {props.comment !== '' && (
          <>
            <Separator />
            <Comment>{props.comment}</Comment>
          </>
        )}
      </Content>
      {props.selectionRecInterview && (
        <SelectionRecInterviewQuestions
          questionAnswerPairsList={
            props.selectionRecInterview.questionAnswerPairsList
          }
          canWatchAnswer={props.selectionRecInterview.canWatchAnswer}
          beforeSubmitAnswer={
            props.selectionRecInterview.submissionTime === undefined
          }
          organizationRecInterviewTranscriptionSetting={
            props.organizationRecInterviewTranscriptionSetting
          }
        />
      )}
      {displaySelectionStepDetailModal.active && (
        <DisplaySelectionStepDetailModal
          selectionStepName={props.name}
          selectionStepGuid={props.guid}
          registrationRoute={props.registrationRoute}
          registerTime={props.registerTime}
          lastAssessTime={props.lastAssessTime}
          appendix={props.appendix}
          close={displaySelectionStepDetailModal.close}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};
`

const Title = styled.div`
  height: 32px;
  background-color: ${theme.color.navy[3]};
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const TitleTxt = styled(Txt).attrs({ size: 'l' })`
  white-space: nowrap;
  overflow: hidden;
`

const TopArea = styled.div`
  height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`

const MenuIcon = styled(Icon).attrs({ name: 'more-horizontal' })`
  cursor: pointer;
  margin-left: 30px;
  margin-right: 20px;
`

const Content = styled.div<{ selected: boolean }>`
  position: relative;
  max-width: 500px;
  border: 1px solid
    ${({ selected }) => (selected ? theme.color.green[4] : theme.color.gray[4])};
  border-radius: 4px;
  margin: 0 20px 20px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.gray[4]};
    border: 1px solid
      ${({ selected }) =>
        selected ? theme.color.green[4] : theme.color.gray[3]};
  }
`

const EditIcon = styled(Icon).attrs({ name: 'edit' })`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;

  ${Content}:hover && {
    display: block;
  }
`

const Result = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`

const ResultTxt = styled(Txt).attrs({ size: 'xl' })`
  margin-left: 12px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 10px;
`

const Comment = styled(Txt)`
  margin-top: 10px;
  max-height: 100px;
  overflow-y: auto;
  word-wrap: break-word;
`

const MenuWrapper = styled.div`
  position: relative;
`

const Menu = styled.div`
  position: absolute;
  top: 24px;
  right: 12px;
  width: 236px;
  border-radius: 0 0 4px 4px;
  background-color: ${theme.color.white[1]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 1;
`

const MenuItem = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const SelectionRecInterviewQuestions = styled(_SelectionRecInterviewQuestions)`
  padding: 0 20px 20px;
`

const GraySpan = styled.span`
  color: ${theme.color.gray[1]};
`
