import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateVideoSeminarContentRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateVideoSeminarContentRequestValue = {
  videoSeminarGuid: string
  title: string
  description: string
  originalMovieKey: string
  thumbnailImage: Uint8Array
  movieDurationSeconds: number
}

export function useRequestCreateVideoSeminarContent() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateVideoSeminarContent = useCallback(
    async (v: CreateVideoSeminarContentRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateVideoSeminarContentRequest()
      req.setVideoSeminarGuid(v.videoSeminarGuid)
      req.setTitle(v.title)
      req.setDescription(v.description)
      req.setOriginalMovieKey(v.originalMovieKey)
      req.setThumbnailImage(v.thumbnailImage)
      req.setMovieDurationSeconds(Math.floor(v.movieDurationSeconds))

      return callBizHuttBffService(
        'createVideoSeminarContent',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateVideoSeminarContent,
  }
}
