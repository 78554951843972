import { useCallback } from 'react'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type ArrowPosition = React.ComponentProps<typeof Tooltip>['arrowPosition']

type Props = {
  tooltipId: string
  arrowPosition: ArrowPosition
  children: React.ReactNode
}

export const ControlCenterTooltip: React.VFC<Props> = (props) => {
  // コントロールセンターのテーブルにおいて、それぞれの行に対して面接詳細画面に遷移するための onClick イベントハンドラが指定してある
  // tooltip上ではこの挙動を無効化したいため、イベントの伝播を停止するハンドラをセットする
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
    },
    []
  )

  // Tooltip に直接 onClick を指定することが出来ないので、ラッパーで包む
  return (
    <TooltipWrapper onClick={onClick}>
      <Tooltip id={props.tooltipId} arrowPosition={props.arrowPosition}>
        {props.children}
      </Tooltip>
    </TooltipWrapper>
  )
}

const TooltipWrapper = styled.div`
  cursor: default;
`
