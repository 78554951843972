import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useRequestListWebInterviewGuidesForLink } from '@/services/bffService/useRequestListWebInterviewGuidesForLink'
import { QUERY_KEY } from '@/services/queryKeyService'

type Guide = {
  name: string
  guid: string
}

export function useListWebInterviewGuides(): Guide[] {
  const { data } = useInnerListWebInterviewGuides()

  const guides = useMemo(() => {
    if (data === undefined) {
      return []
    }
    return data.getWebInterviewGuidesList().map((g) => {
      return {
        name: g.getName(),
        guid: g.getGuid(),
      }
    })
  }, [data])

  return guides
}

function useInnerListWebInterviewGuides() {
  const { requestListWebInterviewGuidesForLink } =
    useRequestListWebInterviewGuidesForLink()

  return useQuery([QUERY_KEY.webInterviewGuides], () =>
    requestListWebInterviewGuidesForLink()
  )
}
