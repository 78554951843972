import React from 'react'
import { SecondaryLinkTxt, theme, Txt } from '@blue-agency/rogue'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = {
  videoSeminarName: string
  isPublic: boolean
}
export const Header: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Left>
        <Txt size="xl">{props.videoSeminarName}</Txt>
        <PublicStatus isPublic={props.isPublic}>
          {props.isPublic ? '公開中' : '公開停止中'}
        </PublicStatus>
      </Left>
      <Link to={INTERNAL_PATHS.videoSeminars}>
        <SecondaryLinkTxt>一覧に戻る</SecondaryLinkTxt>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding: 0 20px;
  background: ${theme.color.white[1]};
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const PublicStatus = styled(Txt).attrs({ size: 's' })<{ isPublic: boolean }>`
  width: 70px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  ${({ isPublic }) => {
    const color = isPublic ? theme.color.green[3] : theme.color.red[2]
    return css`
      border: 1px solid ${color};
      color: ${color};
    `
  }}
`
