import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateSelectionSharingTypeRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { SharingTypeInput } from '@blue-agency/proton/web/v2/im/sharing_type_pb'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateSelectionSharingTypeRequestValue = {
  selectionGuid: string
  isSharing: boolean
}
export function useRequestUpdateSelectionSharingType() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateSelectionSharingType = useCallback(
    async (value: UpdateSelectionSharingTypeRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateSelectionSharingTypeRequest()
      req.setSelectionGuid(value.selectionGuid)
      const sharingType = new SharingTypeInput()
      if (value.isSharing) {
        sharingType.setAll(true)
      } else {
        sharingType.setUnshared(true)
      }
      req.setSharingType(sharingType)

      return callBizHuttBffService(
        'updateSelectionSharingType',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateSelectionSharingType,
  }
}
