import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestChangeWebInterviewGuide,
  ChangeWebInterviewGuideRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useChangeWebInterviewGuide() {
  const queryClient = useQueryClient()
  const { requestChangeWebInterviewGuide } = useRequestChangeWebInterviewGuide()

  return useMutation(
    (value: ChangeWebInterviewGuideRequestValue) =>
      requestChangeWebInterviewGuide(value),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries([
          QUERY_KEY.webInterview,
          req.webInterviewGuid,
        ])
      },
    }
  )
}
