import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateWebInterviewScheduledStartTimeRequest } from '@blue-agency/proton/biz_hutt_bff'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type UpdateWebInterviewScheduledStartTimeRequestValue = {
  webInterviewGuid: string
  scheduledStartTime: Date | undefined
}

export function useRequestUpdateWebInterviewScheduledStartTime() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateWebInterviewScheduledStartTime = useCallback(
    async (value: UpdateWebInterviewScheduledStartTimeRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateWebInterviewScheduledStartTimeRequest()

      req.setWebInterviewGuid(value.webInterviewGuid)
      if (value.scheduledStartTime !== undefined) {
        req.setScheduledStartTime(Timestamp.fromDate(value.scheduledStartTime))
      }

      return callBizHuttBffService(
        'updateWebInterviewScheduledStartTime',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestUpdateWebInterviewScheduledStartTime,
  }
}
