import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateApplicantCsvFormatRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from './metadata'

export type CreateApplicantCsvFormatRequestValue = {
  name: string
}
export function useRequestCreateApplicantCsvFormat() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateApplicantCsvFormat = useCallback(
    async (v: CreateApplicantCsvFormatRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateApplicantCsvFormatRequest()

      req.setName(v.name)

      return callBizHuttBffService(
        'createApplicantCsvFormat',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestCreateApplicantCsvFormat }
}
