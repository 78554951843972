import { ListSelectionSendMailHistoriesResponse } from '@blue-agency/proton/biz_hutt_bff'
import { SelectionSubStatus } from '@blue-agency/proton/web/v2/im'

export {
  SelectionStatus,
  SelectionSubStatus,
  SelectionStepType,
  SelectionStepResult,
  SharingType,
} from '@blue-agency/proton/web/v2/im'

// NOTE: SELECTION_SUB_STATUS_NO_SELECTION_STEP は新着ステータスに属する
export type InProgressSubStatus = Exclude<
  SelectionSubStatus,
  SelectionSubStatus.SELECTION_SUB_STATUS_NO_SELECTION_STEP
>

export enum VideoFileTranscodeStatus {
  VIDEO_FILE_TRANSCODE_STATUS_UNKNOWN = 0,
  TRANSCODING = 1,
  TRANSCODED = 2,
  FAILED = 3,
}

export enum VideoFileTranscriptionStatus {
  VIDEO_FILE_TRANSCRIPTION_STATUS_UNSPECIFIED = 0,
  VIDEO_FILE_TRANSCRIPTION_STATUS_PROCESSING = 1,
  VIDEO_FILE_TRANSCRIPTION_STATUS_COMPLETED = 2,
  VIDEO_FILE_TRANSCRIPTION_STATUS_FAILED = 3,
}

export const SendMailPurpose =
  ListSelectionSendMailHistoriesResponse.SelectionSendMailHistory.Purpose
export type SendMailPurposeType =
  ListSelectionSendMailHistoriesResponse.SelectionSendMailHistory.Purpose

export const SendMailStatus =
  ListSelectionSendMailHistoriesResponse.SelectionSendMailHistory.Status
export type SendMailStatusType =
  ListSelectionSendMailHistoriesResponse.SelectionSendMailHistory.Status
