import { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import type { Form } from '@/services/webInterviewGuideService'
import {
  formSchema,
  useManipulateParts,
} from '@/services/webInterviewGuideService'

export function useInputPage(
  defaultForm: Form,
  completedForm: Form | undefined,
  completeInput: (form: Form) => void
) {
  const { register, formState, trigger, getValues, control } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: completedForm ?? defaultForm,
  })

  const {
    fields: partFields,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'parts',
  })

  const onInputComplete = useCallback(async () => {
    const valid = await trigger()
    if (!valid) {
      return
    }
    completeInput(getValues())
  }, [trigger, getValues, completeInput])

  const preventSubmitOnEnter = useCallback<
    React.KeyboardEventHandler<HTMLFormElement>
  >((e) => {
    e.preventDefault()
  }, [])

  const manipulate = useManipulateParts({
    partLength: partFields.length,
    append,
    remove,
    swap,
    trigger,
  })

  return {
    register,
    errors: formState.errors,
    isSubmitting: formState.isSubmitting,
    onInputComplete,
    trigger,
    preventSubmitOnEnter,
    partFields,
    ...manipulate,
  }
}
