import { useState, useCallback } from 'react'
import {
  WeakColorSeparator,
  Modal,
  theme,
  Txt,
  ErrorBox,
  CheckBox as _CheckBox,
} from '@blue-agency/rogue'
import { FormRow, PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Count as Count_ } from '@/components/Count'
import {
  getSelectionStatusText,
  SelectionStatus,
} from '@/services/applicantService'
import { ApplicantSelectionStatusTable } from '../ApplicantSelectionStatusTable'
import { Applicant } from '../types'

type Props = {
  applicants: Applicant[]
  updatable: boolean
  onSubmit: () => void
  onClose: () => void
}

export const BatchUpdateSelectionStatusToNotPassedModal: React.VFC<Props> = ({
  applicants,
  updatable,
  onSubmit,
  onClose,
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const toggleConfirmed = useCallback(() => {
    setConfirmed((prevState) => !prevState)
  }, [])
  const canSubmit = applicants.length > 0 && confirmed && updatable
  const actionText = getSelectionStatusText(
    SelectionStatus.SELECTION_STATUS_NOT_PASSED
  )
  const errSrcStatusText = getSelectionStatusText(
    SelectionStatus.SELECTION_STATUS_ARRIVAL
  )

  return (
    <Modal
      active
      title={`応募者を${actionText}に変更`}
      size="l"
      onClose={onClose}
      stopScroll
    >
      <ModalBody>
        {!updatable && (
          <ErrorBoxWrapper>
            <ErrorBox>
              <Txt color={theme.color.red[2]}>
                「{errSrcStatusText}」の応募者は、「{actionText}
                」に変更できません。
                <br />
                対象の応募者をもう一度選択して、再度お試しください。
              </Txt>
            </ErrorBox>
          </ErrorBoxWrapper>
        )}
        <DescriptionWrapper>
          <Count count={applicants.length} />
          <InlineTxt>
            件の応募者のステータスを<Bold>「{actionText}」</Bold>に変更します。
          </InlineTxt>
        </DescriptionWrapper>
        {applicants.length > 0 && (
          <ApplicantSelectionStatusTable applicants={applicants} />
        )}
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <Row title="確認" required>
          <CheckBox
            checked={confirmed}
            onChange={toggleConfirmed}
            size={18}
            checkboxLabel="内容に誤りがないことを確認しました"
          />
        </Row>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{
              action:
                'click_batch_update_selection_status_to_not_passed_cancel_button',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            disabled={!canSubmit}
            onClick={onSubmit}
            comlinkPushParams={{
              action:
                'click_batch_update_selection_status_to_not_passed_button',
            }}
          >
            一括変更
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const ErrorBoxWrapper = styled.div`
  margin-bottom: 20px;
`

const DescriptionWrapper = styled.div`
  margin-bottom: 20px;
`

const Count = styled(Count_)`
  display: inline-block;
  line-height: 1;
`

const InlineTxt = styled(Txt).attrs({ size: 'm' })`
  display: inline-block;
`

const Bold = styled.span`
  font-weight: bold;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px;
`

const Row = styled(FormRow).attrs({ titleWidth: '183px', subtitleWidth: '0' })`
  margin-bottom: 10px;
`

const CheckBox = styled(_CheckBox)`
  margin-right: 4px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
