import React, { useCallback, useEffect, useState } from 'react'
import { theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { InputText, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { validateWordsCount } from './SearchApplicantsModal/form'

type Props = {
  totalLength?: number
  keywords: string[]
  onChange: (keywords: string[]) => void
  onClickOpenSearchApplicantsModal: () => void
  onResetSearchApplicantsCondition: () => void
  onClickOpenApplicantsCSVModal: () => void
}
export const Sidebar: React.VFC<Props> = (props) => {
  const [keywords, setKeywords] = useState(props.keywords.join(' '))
  const onChangeKeywords = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setKeywords(e.target.value)
    },
    []
  )
  const [keywordsError, setKeywordsError] = useState('')

  useEffect(() => {
    // onChangeイベントのdebounce
    const id = setTimeout(() => {
      const splitted = keywords.trim().split(/\s+/)
      const keywordValidateRes = validateWordsCount(splitted)
      setKeywordsError(keywordValidateRes.errorMessage)
      if (keywordValidateRes.valid) {
        // 入力された末尾のスペースはtrimしない
        props.onChange(keywords.split(/\s+/))
      }
    }, 500)

    return () => clearTimeout(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords])

  useEffect(() => {
    setKeywords(props.keywords.join(' '))
  }, [props.keywords])

  return (
    <Wrapper>
      <InterviewCount>
        <Txt>該当数</Txt>
        <Count>{digitSeparator(props.totalLength)}</Count>
        <Txt>件</Txt>
      </InterviewCount>
      <WeakColorSeparator />
      <SearchWrapper>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Txt>検索</Txt>
          <ResetButton onClick={props.onResetSearchApplicantsCondition}>
            <ResetTxt>初期状態に戻す</ResetTxt>
          </ResetButton>
        </Box>
        <Box mt="16px">
          <InputText
            label="フリーワード"
            placeholder="氏名・電話番号・メールアドレス"
            size="max"
            value={keywords}
            onChange={onChangeKeywords}
            onBlurError={keywordsError}
          />
        </Box>
        <Box mt="16px">
          <SearchModalButton
            onClick={props.onClickOpenSearchApplicantsModal}
            comlinkPushParams={{
              action: 'click_open_search_applicants_modal',
            }}
          >
            詳細条件を指定
          </SearchModalButton>
        </Box>
      </SearchWrapper>
      <WeakColorSeparator />
      <OutputWrapper>
        <Txt>出力</Txt>
        <OutputCSVButton
          comlinkPushParams={{ action: 'click_download_applicant_csv' }}
          onClick={props.onClickOpenApplicantsCSVModal}
        >
          CSV出力
        </OutputCSVButton>
      </OutputWrapper>
    </Wrapper>
  )
}

function digitSeparator(digit: number | undefined): string {
  if (typeof digit === undefined) {
    return ''
  }
  return String(digit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[4]};
  border-left-width: 0;
  border-radius: 0 4px 4px 0;
  display: flex;
  flex-direction: column;
`

const InterviewCount = styled.div`
  align-items: center;
  display: flex;
  padding: 12px;
`

const Count = styled(Txt).attrs({ color: theme.color.green[4], size: 'xxl' })`
  line-height: 1;
  margin: 0 6px 0 auto;
`

const SearchWrapper = styled.div`
  padding: 16px 12px;
`

const SearchModalButton = styled(TertiaryButton).attrs({ widthSize: 'MAX' })``

const ResetButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const ResetTxt = styled(Txt).attrs({ size: 's' })`
  text-decoration: underline;
`

const OutputWrapper = styled.div`
  padding: 16px 12px;
`

const OutputCSVButton = styled(TertiaryButton).attrs({ widthSize: 'MAX' })`
  margin-top: 16px;
`
