import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { Loading } from '@/components/Loading'
import { AuthzContainer } from '@/services/authzService'
import { ConfirmPage } from './ConfirmPage'
import { InputPage } from './InputPage'
import { EditWebInterviewGuidePageContainer } from './editWebInterviewGuidePageContainer'

export const EditWebInterviewGuidePage = provide(
  EditWebInterviewGuidePageContainer
)((): React.ReactElement => {
  const { authorizeByRpcName } = AuthzContainer.useContainer()
  authorizeByRpcName && authorizeByRpcName('UpdateWebInterviewGuide')

  usePromptBeforeUnload()

  const { step, originalGuide, isGuideFetching, guid } =
    EditWebInterviewGuidePageContainer.useContainer()
  if (isGuideFetching || originalGuide === undefined) {
    return <Loading />
  }

  switch (step) {
    case 'input':
      return <InputPage guid={guid} originalGuide={originalGuide} />
    case 'confirm':
      return <ConfirmPage />
  }
})
