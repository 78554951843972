import { useCallback } from 'react'
import {
  alertToast,
  Dropdown,
  Icon,
  InputText,
  theme,
  Txt,
} from '@blue-agency/rogue'
import { useMount } from 'react-use'
import styled, { css } from 'styled-components'
import { BirthDatePicker } from '@/components/BirthDatePicker'
import {
  EducationalBackground,
  maxEducationalBackgroundsNum,
  maxWorkHistoriesNum,
  WorkHistory,
} from '@/services/applicantService'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import * as C from '../formComponents'
import { genderOptions } from '../options'
import { UseFormResponse } from '../useApplicantForm'
import { useGetAddressByPostalCode } from './useGetAddressByPostalCode'

const emptyEducationalBackground: EducationalBackground = {
  schoolName: '',
  faculty: '',
  note: '',
}

const emptyWorkHistory: WorkHistory = {
  companyName: '',
  jobDescription: '',
  note: '',
}

type Props = UseFormResponse
export const BodyDetails: React.VFC<Props> = ({
  formCtx,
  educationalBackgroundsListCtx,
  workHistoriesListCtx,
}) => {
  const { mutateAsync: getAddressByPostalCode } = useGetAddressByPostalCode()

  useMount(() => {
    formCtx.register('birthDate')
  })

  const setValue = formCtx.setValue
  const onChangeBirthDate = useCallback(
    (date?: Date) => {
      setValue('birthDate', date)
    },
    [setValue]
  )

  const addEducationalBackground = useCallback(() => {
    educationalBackgroundsListCtx.append(emptyEducationalBackground)
  }, [educationalBackgroundsListCtx])

  const removeEducationalBackground = useCallback(
    (i: number) => () => {
      educationalBackgroundsListCtx.remove(i)
    },
    [educationalBackgroundsListCtx]
  )

  const addWorkHistory = useCallback(() => {
    workHistoriesListCtx.append(emptyWorkHistory)
  }, [workHistoriesListCtx])

  const removeWorkHistory = useCallback(
    (i: number) => () => {
      workHistoriesListCtx.remove(i)
    },
    [workHistoriesListCtx]
  )

  const autocompleteAddress = useCallback(async () => {
    const postalCode = formCtx.getValues('postalCode')
    if (!postalCode) return
    const res = await getAddressByPostalCode(postalCode).catch((err) => {
      if (err instanceof CustomBizHuttBffGrpcError) {
        if (err.message === 'Address not found') {
          alertToast('住所が見つかりませんでした')
        }
      }
    })
    if (!res) return
    formCtx.setValue('address', res.getAddress())
  }, [formCtx, getAddressByPostalCode])

  return (
    <>
      <MarginTop />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>住所</C.RowLabelMain>
          <C.RowLabelSub>郵便番号</C.RowLabelSub>
        </C.RowLabel>
        <InputText
          {...formCtx.register('postalCode')}
          placeholder="例) 1070052"
          onBlurError={formCtx.formState.errors.postalCode?.message}
          size="s"
        />
        <AutocompleteAdressButton onClick={autocompleteAddress}>
          <AutocompleteAdressButtonTxt>
            郵便番号から住所入力
          </AutocompleteAdressButtonTxt>
        </AutocompleteAdressButton>
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>住所</C.RowLabelSub>
        </C.RowLabel>
        <InputText
          {...formCtx.register('address')}
          placeholder="例) 東京都港区赤坂3-4-3 〇〇ビル6F"
          onBlurError={formCtx.formState.errors.address?.message}
          size="ll"
        />
      </C.Row>

      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>性別</C.RowLabelMain>
          <C.RowLabelSub>性別</C.RowLabelSub>
        </C.RowLabel>
        <Dropdown
          {...formCtx.register('gender')}
          onBlurError={formCtx.formState.errors.gender?.message}
          options={genderOptions}
          allowEmpty
        />
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>性別備考</C.RowLabelSub>
        </C.RowLabel>
        <InputText
          {...formCtx.register('genderNote')}
          placeholder="入力してください"
          onBlurError={formCtx.formState.errors.genderNote?.message}
          size="l"
        />
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>生年月日</C.RowLabelMain>
        </C.RowLabel>
        <BirthDatePicker
          value={formCtx.getValues('birthDate')}
          onChange={onChangeBirthDate}
        />
      </C.Row>
      <C.Separator />

      {educationalBackgroundsListCtx.fields.map((v, i) => {
        return (
          <div key={v.id}>
            {i !== 0 && (
              <C.Row>
                <Spacer />
                <DeleteWrapper onClick={removeEducationalBackground(i)}>
                  <Icon name="delete" width="15px" />
                  <DeleteTxt>削除</DeleteTxt>
                </DeleteWrapper>
              </C.Row>
            )}
            <C.Row>
              <C.RowLabel>
                <C.RowLabelMain>{i === 0 && '最終'}学歴</C.RowLabelMain>
                <C.RowLabelSub>学校名</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(
                  `educationalBackgroundsList.${i}.schoolName` as const
                )}
                onBlurError={
                  formCtx.formState.errors.educationalBackgroundsList
                    ? formCtx.formState.errors.educationalBackgroundsList[i]
                        ?.schoolName?.message
                    : undefined
                }
                placeholder="例) 面接大学"
                size="ll"
              />
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>学部・学科</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(
                  `educationalBackgroundsList.${i}.faculty` as const
                )}
                onBlurError={
                  formCtx.formState.errors.educationalBackgroundsList
                    ? formCtx.formState.errors.educationalBackgroundsList[i]
                        ?.faculty?.message
                    : undefined
                }
                placeholder="例) 面接学部　面接学科"
                size="ll"
              />
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>備考</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(
                  `educationalBackgroundsList.${i}.note` as const
                )}
                onBlurError={
                  formCtx.formState.errors.educationalBackgroundsList
                    ? formCtx.formState.errors.educationalBackgroundsList[i]
                        ?.note?.message
                    : undefined
                }
                placeholder="入力してください"
                type="textarea"
                height="120px"
              />
            </C.Row>
            {educationalBackgroundsListCtx.fields.length === i + 1 && (
              <C.Row>
                <C.RowLabel />
                <AddWrapper
                  disabled={i + 1 >= maxEducationalBackgroundsNum}
                  onClick={
                    i + 1 >= maxEducationalBackgroundsNum
                      ? () => {}
                      : addEducationalBackground
                  }
                >
                  <C.Add
                    text="学歴を追加"
                    color={
                      i + 1 >= maxEducationalBackgroundsNum
                        ? theme.color.gray[2]
                        : undefined
                    }
                  />
                </AddWrapper>
              </C.Row>
            )}
            <C.Separator />
          </div>
        )
      })}

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>学歴備考</C.RowLabelMain>
        </C.RowLabel>
        <InputText
          {...formCtx.register('educationalBackgroundNote')}
          placeholder="入力してください"
          onBlurError={
            formCtx.formState.errors.educationalBackgroundNote?.message
          }
          type="textarea"
          height="120px"
        />
      </C.Row>
      <C.Separator />

      {workHistoriesListCtx.fields.map((v, i) => {
        return (
          <div key={v.id}>
            {i !== 0 && (
              <C.Row>
                <Spacer />
                <DeleteWrapper onClick={removeWorkHistory(i)}>
                  <Icon name="delete" width="15px" />
                  <DeleteTxt>削除</DeleteTxt>
                </DeleteWrapper>
              </C.Row>
            )}
            <C.Row>
              <C.RowLabel>
                <C.RowLabelMain>職務経歴</C.RowLabelMain>
                <C.RowLabelSub>会社名</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(
                  `workHistoriesList.${i}.companyName` as const
                )}
                onBlurError={
                  formCtx.formState.errors.workHistoriesList
                    ? formCtx.formState.errors.workHistoriesList[i]?.companyName
                        ?.message
                    : undefined
                }
                placeholder="例) 株式会社メンセツ"
                size="ll"
              />
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>職務内容</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(
                  `workHistoriesList.${i}.jobDescription` as const
                )}
                onBlurError={
                  formCtx.formState.errors.workHistoriesList
                    ? formCtx.formState.errors.workHistoriesList[i]
                        ?.jobDescription?.message
                    : undefined
                }
                placeholder="入力してください"
                size="ll"
              />
            </C.Row>
            <C.Row>
              <C.RowLabel>
                <C.RowLabelSub>備考</C.RowLabelSub>
              </C.RowLabel>
              <InputText
                {...formCtx.register(`workHistoriesList.${i}.note` as const)}
                onBlurError={
                  formCtx.formState.errors.workHistoriesList
                    ? formCtx.formState.errors.workHistoriesList[i]?.note
                        ?.message
                    : undefined
                }
                placeholder="入力してください"
                type="textarea"
                height="120px"
              />
            </C.Row>
            {workHistoriesListCtx.fields.length === i + 1 && (
              // 最大5つまで
              <C.Row>
                <C.RowLabel />
                <AddWrapper
                  disabled={i + 1 >= maxWorkHistoriesNum}
                  onClick={
                    i + 1 >= maxWorkHistoriesNum ? () => {} : addWorkHistory
                  }
                >
                  <C.Add
                    text="職務経歴を追加"
                    color={
                      i + 1 >= maxWorkHistoriesNum
                        ? theme.color.gray[2]
                        : undefined
                    }
                  />
                </AddWrapper>
              </C.Row>
            )}
            <C.Separator />
          </div>
        )
      })}

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>職務経歴備考</C.RowLabelMain>
        </C.RowLabel>
        <InputText
          {...formCtx.register('workHistoryNote')}
          onBlurError={formCtx.formState.errors.workHistoryNote?.message}
          placeholder="入力してください"
          type="textarea"
          height="120px"
        />
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>備考</C.RowLabelMain>
        </C.RowLabel>
        <InputText
          {...formCtx.register('note')}
          onBlurError={formCtx.formState.errors.note?.message}
          placeholder="入力してください"
          type="textarea"
          height="120px"
        />
      </C.Row>

      <MarginBottom />
    </>
  )
}

const MarginTop = styled.div`
  height: 20px;
`

const MarginBottom = styled.div`
  height: 50px;
`

const AutocompleteAdressButton = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 4px;

  &:hover {
    background: ${theme.color.gray[4]};
    border-radius: 4px;
  }
`

const AutocompleteAdressButtonTxt = styled(Txt).attrs({ size: 's' })`
  text-decoration: underline;
`

const AddWrapper = styled.div.attrs({
  role: 'button',
})<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.color.gray[2]};
    `}
`

const Spacer = styled.div`
  flex: 1;
`

const DeleteWrapper = styled.div.attrs({
  role: 'button',
})`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
`

const DeleteTxt = styled(Txt).attrs({
  size: 'm',
})`
  margin-left: 5px;
`
