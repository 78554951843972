import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import {
  SearchWebInterviewsRequest,
  WebInterviewStatus as Status,
} from '@blue-agency/proton/biz_hutt_bff'
import { TimestampRange } from '@blue-agency/proton/im'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { assertNever } from '@/assertions'
import { SortByState } from '@/hooks/useSortBy'
import {
  freewordToArray,
  SEARCH_FREEWORD_COUNT_MAX,
} from '@/services/webInterviewService'
import type { WebInterviewStatus } from '@/services/webInterviewService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

type SortableColumn = 'registerTime' | 'name' | 'scheduledStartTime'

export type SortBy = SortByState<SortableColumn>

// クエリパラメータにJSON.stringifyしたものを入れるので、極力プロパティ名を短くする
type CompressedSortBy = {
  c: 'r' | 'n' | 's'
  d: 't' | 'f'
}

export const compressSortBy = (s: SortBy): CompressedSortBy => ({
  c: s.column === 'registerTime' ? 'r' : s.column === 'name' ? 'n' : 's',
  d: s.desc ? 't' : 'f',
})

export const decompressSortBy = (c: CompressedSortBy): SortBy => ({
  column:
    c.c === 'r' ? 'registerTime' : c.c === 'n' ? 'name' : 'scheduledStartTime',
  desc: c.d === 't' ? true : false,
})

export type SearchWebInterviewsArgs = {
  pageSize: number
  page: number
  sortBy: SortBy
  condition: {
    freeword?: string
    status: { [p in WebInterviewStatus]: boolean }
    interviewerGuid: string | null
    isAssignmentSpecified: boolean
    registerTime: {
      from: Date | null
      to: Date | null
    }
    scheduledStartTime: {
      from: Date | null
      to: Date | null
    }
  }
}

const mapToOrderBy = (sortBy: SortBy): SearchWebInterviewsRequest.OrderBy => {
  switch (sortBy.column) {
    case 'registerTime':
      if (sortBy.desc)
        return SearchWebInterviewsRequest.OrderBy.REGISTER_TIME_DESC
      return SearchWebInterviewsRequest.OrderBy.REGISTER_TIME_ASC
    case 'name':
      if (sortBy.desc) return SearchWebInterviewsRequest.OrderBy.NAME_DESC
      return SearchWebInterviewsRequest.OrderBy.NAME_ASC
    case 'scheduledStartTime':
      if (sortBy.desc)
        return SearchWebInterviewsRequest.OrderBy.SCHEDULED_START_TIME_DESC
      return SearchWebInterviewsRequest.OrderBy.SCHEDULED_START_TIME_ASC
    default:
      assertNever(sortBy.column)
  }
}

const buildCondition = (condition_: SearchWebInterviewsArgs['condition']) => {
  const condition = new SearchWebInterviewsRequest.Condition()

  const statusList = []
  if (condition_.status.notStarted) statusList.push(Status.NOT_STARTED)
  if (condition_.status.interviewing) statusList.push(Status.INTERVIEWING)
  if (condition_.status.interviewed) statusList.push(Status.INTERVIEWED)
  condition.setStatusesList(statusList)
  condition.setInterviewerUnassigned(condition_.isAssignmentSpecified)
  if (condition_.freeword) {
    condition.setKeywordsList(
      // NOTE: 空白で区切られた、先頭 SEARCH_FREEWORD_COUNT_MAX ワードまでを検索対象とする
      freewordToArray(condition_.freeword).slice(0, SEARCH_FREEWORD_COUNT_MAX)
    )
  }

  if (condition_.interviewerGuid) {
    condition.setStaffGuid(condition_.interviewerGuid)
  }

  const registerTimestampRange = new TimestampRange()
  if (condition_.registerTime.from) {
    const from = Timestamp.fromDate(condition_.registerTime.from)
    registerTimestampRange.setFrom(from)
  }
  if (condition_.registerTime.to) {
    const to = Timestamp.fromDate(condition_.registerTime.to)
    registerTimestampRange.setTo(to)
  }
  condition.setRegisterTime(registerTimestampRange)

  const scheduledStartTimestampRange = new TimestampRange()
  if (condition_.scheduledStartTime.from) {
    const from = Timestamp.fromDate(condition_.scheduledStartTime.from)
    scheduledStartTimestampRange.setFrom(from)
  }
  if (condition_.scheduledStartTime.to) {
    const to = Timestamp.fromDate(condition_.scheduledStartTime.to)
    scheduledStartTimestampRange.setTo(to)
  }
  condition.setScheduledStartTime(scheduledStartTimestampRange)

  return condition
}

export function useRequestSearchWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestSearchWebInterviews = useCallback(
    async ({
      pageSize,
      page,
      sortBy,
      condition: condition_,
    }: SearchWebInterviewsArgs) => {
      const metadata = await buildMetadata()

      const req = new SearchWebInterviewsRequest()

      const pagination = new SearchWebInterviewsRequest.Pagination()
      pagination.setPageSize(pageSize)
      pagination.setPage(page)

      req.setPagination(pagination)

      const orderBy = mapToOrderBy(sortBy)
      req.setOrderBy(orderBy)

      const condition = buildCondition(condition_)
      req.setCondition(condition)

      return callBizHuttBffService(
        'searchWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestSearchWebInterviews }
}
