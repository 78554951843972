import { Modal, InputText, WeakColorSeparator } from '@blue-agency/rogue'
import {
  FormRow as _FormRow,
  PrimaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

type Props = {
  name: string
  onClose: () => void
  onSubmit: (name: string) => void
}

type Form = {
  name: string
}
export const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(30, '30文字以内で入力してください')
      .required('入力してください'),
  })
  .defined()

export const EditFormatNameModal: React.VFC<Props> = ({
  name,
  onClose,
  onSubmit,
}) => {
  const form = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { name: name },
  })

  return (
    <Modal
      active
      title="フォーマット名の編集"
      onClose={onClose}
      size="l"
      overflowVisible
    >
      <ModalContent>
        <FormRow title="フォーマット名" required>
          <InputText
            {...form.register('name')}
            placeholder="例) 採用媒体名"
            onBlurError={form.formState.errors.name?.message}
            size="ll"
            autoComplete="off"
          />
        </FormRow>
      </ModalContent>
      <WeakColorSeparator />
      <ButtonArea>
        <TertiaryButton
          widthSize="L1"
          onClick={onClose}
          comlinkPushParams={{
            action: 'click_cancel_button_on_update_format_name_modal',
          }}
        >
          キャンセル
        </TertiaryButton>
        <PrimaryButton
          widthSize="L1"
          disabled={!form.formState.isValid}
          onClick={() => onSubmit(form.getValues('name'))}
          comlinkPushParams={{
            action: 'click_update_format_name_button',
          }}
        >
          保存
        </PrimaryButton>
      </ButtonArea>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 92px;
`

const FormRow = styled(_FormRow).attrs({
  titleWidth: '140px',
  subtitleWidth: '0',
})``

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > *:first-child {
    margin-right: 20px;
  }
`
