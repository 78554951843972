import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListSelectionSendMailHistoriesRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListSelectionSendMailHistories() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListSelectionSendMailHistories = useCallback(
    async (selectionGuid: string) => {
      const metadata = await buildMetadata()
      const req = new ListSelectionSendMailHistoriesRequest()
      req.setSelectionGuid(selectionGuid)

      return callBizHuttBffService(
        'listSelectionSendMailHistories',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListSelectionSendMailHistories }
}
