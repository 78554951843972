import React, { ReactElement, useState } from 'react'
import { InputTextProps, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type InputTextHoverProps = {
  value?: string | number
  placeholder?: string
  hoverInner: ReactElement
}

export const InputTextHover: React.VFC<
  InputTextHoverProps & InputTextProps
> = ({ hoverInner, value, placeholder }) => {
  const [hover, setHover] = useState(false)

  return (
    <Wrapper
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <TextArea placeholder={placeholder}>{value}</TextArea>
      <HoverArea hover={hover}>{hoverInner}</HoverArea>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const TextArea = styled.pre<{ placeholder?: string }>`
  border: dotted 1px ${theme.color.gray[2]};
  border-radius: 4px;
  line-height: 18px;
  padding: 8px 14px;

  font-family: inherit;
  font-size: ${theme.fontSize.m};
  color: ${theme.color.navy[1]};
  white-space: pre-wrap;
  line-break: anywhere;

  :empty::before {
    content: '${({ placeholder }) => CSS.escape(placeholder ?? '')}';
    color: ${theme.color.gray[2]};
  }
`

const HoverArea = styled.div<{ hover: boolean }>`
  display: ${({ hover }) => (hover ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: ${theme.color.green[4]};
  opacity: 0.75;
  border: none;
  color: ${theme.color.white[1]};
  cursor: pointer;
`
