import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import {
  SearchAssignedWebInterviewsRequest,
  WebInterviewStatus as Status,
} from '@blue-agency/proton/biz_hutt_bff'
import { SortByState } from '@/hooks/useSortBy'
import type { WebInterviewStatus } from '@/services/webInterviewService'
import {
  freewordToArray,
  SEARCH_FREEWORD_COUNT_MAX,
} from '@/services/webInterviewService'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

type SortableColumn = 'registerTime' | 'name' | 'scheduledStartTime'

type SortBy = SortByState<SortableColumn>

export type SearchAssignedWebInterviewsArgs = {
  pageSize: number
  page: number
  sortBy: SortBy
  condition: {
    freeword?: string
    status: { [p in WebInterviewStatus]: boolean }
    interviewerGuid: string | null
    isOnlyViewed: boolean
    registerTime: {
      from: Date | null
      to: Date | null
    }
  }
}

const mapToOrderBy = (
  sortBy: SortBy
): SearchAssignedWebInterviewsRequest.OrderBy => {
  switch (sortBy.column) {
    case 'registerTime':
      if (sortBy.desc)
        return SearchAssignedWebInterviewsRequest.OrderBy.REGISTER_TIME_DESC
      return SearchAssignedWebInterviewsRequest.OrderBy.REGISTER_TIME_ASC
    case 'name':
      if (sortBy.desc)
        return SearchAssignedWebInterviewsRequest.OrderBy.NAME_DESC
      return SearchAssignedWebInterviewsRequest.OrderBy.NAME_ASC
    case 'scheduledStartTime':
      if (sortBy.desc)
        return SearchAssignedWebInterviewsRequest.OrderBy
          .SCHEDULED_START_TIME_DESC
      return SearchAssignedWebInterviewsRequest.OrderBy.SCHEDULED_START_TIME_ASC
    default:
      throw new Error(`${sortBy.column} desc:${sortBy.desc} is not available`)
  }
}
const buildCondition = (
  condition_: SearchAssignedWebInterviewsArgs['condition']
) => {
  const condition = new SearchAssignedWebInterviewsRequest.Condition()

  const statusList = []
  if (condition_.status.notStarted) statusList.push(Status.NOT_STARTED)
  if (condition_.status.interviewing) statusList.push(Status.INTERVIEWING)
  if (condition_.status.interviewed) statusList.push(Status.INTERVIEWED)
  condition.setStatusesList(statusList)

  condition.setViewerOnly(condition_.isOnlyViewed)
  if (condition_.freeword) {
    if (
      freewordToArray(condition_.freeword).length > SEARCH_FREEWORD_COUNT_MAX
    ) {
      throw new Error('search words is exceeded.')
    }
    condition.setKeywordsList(
      // NOTE: 空白で区切られた、先頭 SEARCH_FREEWORD_COUNT_MAX ワードまでを検索対象とする
      freewordToArray(condition_.freeword)
    )
  }

  return condition
}

export function useRequestSearchAssignedWebInterviews() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestSearchAssignedWebInterviews = useCallback(
    async ({
      pageSize,
      page,
      sortBy,
      condition: condition_,
    }: SearchAssignedWebInterviewsArgs) => {
      const metadata = await buildMetadata()

      const req = new SearchAssignedWebInterviewsRequest()

      const pagination = new SearchAssignedWebInterviewsRequest.Pagination()
      pagination.setPageSize(pageSize)
      pagination.setPage(page)

      req.setPagination(pagination)

      const orderBy = mapToOrderBy(sortBy)
      req.setOrderBy(orderBy)

      const condition = buildCondition(condition_)
      req.setCondition(condition)

      return callBizHuttBffService(
        'searchAssignedWebInterviews',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestSearchAssignedWebInterviews }
}
