import React, { useMemo } from 'react'
import { theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Loading } from '@/components/Loading'
import { SendMailHistory as SendMailHistoryType } from '@/services/applicantService'
import { SendMailHistory as _SendMailHistory } from './SendMailHistory'
import { useListSelectionSendMailHistories } from './useListSelectionSendMailHistories'

type Props = PropsWithClassName<{
  selectionGuid: string
}>
export const History: React.VFC<Props> = (props) => {
  const { data, isLoading } = useListSelectionSendMailHistories(
    props.selectionGuid
  )

  const histories = useMemo<SendMailHistoryType[]>(() => {
    return (
      data?.getSelectionSendMailHistoriesList().map((h) => {
        const requestedAt = h.getRequestedAt()?.toDate()
        if (requestedAt === undefined) throw new Error('requestedAt not found')
        return {
          selectionStepName: h.getSelectionStepName(),
          requestedAt,
          purpose: h.getPurpose(),
          status: h.getStatus(),
        }
      }) ?? []
    )
  }, [data])

  if (isLoading || data === undefined) {
    return <Loading />
  }

  return (
    <Wrapper className={props.className}>
      {histories.length === 0 ? (
        <EmptyHistoryTxt>履歴はありません</EmptyHistoryTxt>
      ) : (
        histories.map((h, i) => (
          <React.Fragment key={i}>
            <SendMailHistory {...h} />
            <Separator />
          </React.Fragment>
        ))
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  background-color: ${theme.color.white[1]};
  padding-bottom: 20px;
`

const EmptyHistoryTxt = styled(Txt).attrs({ size: 'l' })`
  margin: 20px auto;
`

const SendMailHistory = styled(_SendMailHistory)`
  margin-top: 16px;
  margin-left: 10px;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 12px;
`
