import { NewQuestionTemplatePageContainer } from './NewQuestionTemplatePageContainer'

export function useConfirmPage() {
  const {
    completedForm: form,
    register,
    isRegisterLoading,
    backToInput,
  } = NewQuestionTemplatePageContainer.useContainer()

  if (form === undefined) {
    throw new Error('form is undefined')
  }

  return {
    form,
    isRegisterLoading,
    register,
    backToInput,
  }
}
