import React from 'react'
import { Modal, Txt, WeakColorSeparator, ErrorBox } from '@blue-agency/rogue'
import {
  FormRow,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { bufferHours } from '@/services/recInterviewTemplateService'
import { DeadlinePicker } from '../DeadlinePicker'

export type ErrorType = 'noMailAddress' | 'pastDate'
type Props = {
  active: boolean
  applicantName: string
  templateName: string
  deadline: Date
  error?: ErrorType
  onClose: () => void
  onGenerateAnswerLink: () => void
  onSendMail: () => void
  onChangeDeadline: (date: Date | null) => void
}

const errorMessageMap: Record<ErrorType, string> = {
  noMailAddress: 'メールアドレスが登録されていません。',
  pastDate: `提出期限は現在時刻から${bufferHours}時間以上先で設定してください。`,
}

const generateAnswerLinkDisabledMap: Record<ErrorType, boolean> = {
  noMailAddress: false,
  pastDate: true,
}

const sendMailDisabledMap: Record<ErrorType, boolean> = {
  noMailAddress: true,
  pastDate: true,
}

export const RequestSelectionRecInterviewAnswerModal: React.VFC<Props> = (
  props
) => {
  return (
    <Modal
      active={props.active}
      title="提出を依頼"
      onClose={props.onClose}
      size="l"
      overflowVisible
    >
      <ModalContent>
        {props.error && (
          <ErrorMessage>{errorMessageMap[props.error]}</ErrorMessage>
        )}
        <ModalTxt>
          {props.applicantName}さんに録画面接の提出を依頼します。
          <br />
          imからメール送信しない場合は、「回答ページリンクを発行」を選択してください。
        </ModalTxt>
        <Row title="テンプレート名">
          <RowTxt>{props.templateName}</RowTxt>
        </Row>
        <Separator />
        <Row title="提出期限" required>
          <DeadlinePicker
            deadline={props.deadline}
            onChangeDeadline={props.onChangeDeadline}
          />
        </Row>
      </ModalContent>
      <Separator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L3"
            onClick={props.onClose}
            comlinkPushParams={{
              action:
                'click_cancel_button_on_request_selection_rec_interview_answer_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <SecondaryButton
            widthSize="L3"
            onClick={props.onGenerateAnswerLink}
            disabled={props.error && generateAnswerLinkDisabledMap[props.error]}
            comlinkPushParams={{
              action:
                'click_generate_answer_link_button_on_request_selection_rec_interview_answer_modal',
            }}
          >
            回答ページリンクを発行
          </SecondaryButton>
          <PrimaryButton
            widthSize="L3"
            onClick={props.onSendMail}
            disabled={props.error && sendMailDisabledMap[props.error]}
            comlinkPushParams={{
              action:
                'click_send_mail_button_on_request_selection_rec_interview_answer_modal',
            }}
          >
            提出依頼メールを送信
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalTxt = styled(Txt).attrs({ size: 'm' })`
  margin-top: 20px;
`

const ErrorMessage = styled(ErrorBox)`
  margin-top: 20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const Row = styled(FormRow).attrs({ titleWidth: '150px', subtitleWidth: '0' })`
  margin-top: 20px;
`

const RowTxt = styled(Txt).attrs({ size: 'm' })``

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
