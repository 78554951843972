import { useMemo } from 'react'
import { GetRecInterviewTemplateResponse } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { formatTime } from '@/services/recInterviewTemplateService'
import {
  Form,
  FormQuestion,
} from '../RecInterviewTemplateNewPage/steps/formSchema'
import {
  newDueDatetime,
  defaultDueDays,
  defaultDueHour,
} from '../RecInterviewTemplateNewPage/useRecInterviewTemplateNewPage'
import { useGetRecInterviewTemplate } from '../RecInterviewTemplatePage/useGetRecInterviewTemplate'

export const useRecInterviewTemplate = (guid: string) => {
  const { isLoading, data } = useGetRecInterviewTemplate(guid)

  const template = useMemo<Form>(
    () => toFormData(isLoading ? new GetRecInterviewTemplateResponse() : data!),
    [isLoading, data]
  )

  return {
    isLoading,
    template,
  }
}

const toFormData = (data: GetRecInterviewTemplateResponse): Form => {
  const dueDatetime = data.hasDueTime()
    ? new Date(data.getDueTime()!.getSeconds() * 1000)
    : newDueDatetime(new Date())

  const dueDuration = data.hasDueDuration()
    ? {
        days: data.getDueDuration()!.getDays(),
        hour: data.getDueDuration()!.getHour(),
      }
    : { days: defaultDueDays, hour: defaultDueHour }

  return {
    name: data.getName(),
    allowRecordedVideo: data.getAllowRecordedVideo() ? 'allowed' : 'notAllowed',
    due: {
      type: data.hasDueTime() ? 'time' : 'days',
      time: {
        time: formatTime(dueDatetime),
        hour: dueDatetime.getHours(),
      },
      duration: dueDuration,
    },
    introMessage: data.getIntroMessage(),
    introVideoFileKey: data.getIntroVideoFileKey(),
    introVideoURL: data.getIntroVideoPresignedUrl(),
    questions: data.getQuestionsList().map(
      (q) =>
        ({
          guid: q.getQuestionTemplateGuid(),
          required: q.getRequired() ? '1' : '0',
        } as FormQuestion)
    ),
    outroMessage: data.getOutroMessage(),
    outroVideoFileKey: data.getOutroVideoFileKey(),
    outroVideoURL: data.getOutroVideoPresignedUrl(),
  }
}
