import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import * as C from '../formComponents'
import { UseFormResponse } from '../useApplicantForm'
import { BodyDetails } from './BodyDetails'

type Props = Pick<UseFormResponse, 'formCtx'> & {
  isDetailsEnabled: boolean
}
export const ConfirmBody: React.VFC<Props> = ({
  formCtx,
  isDetailsEnabled,
}) => {
  const watch = formCtx.watch()

  return (
    <Wrapper>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>氏名</C.RowLabelMain>
          <C.RowLabelSub>姓名</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>
          {watch.familyName} {watch.givenName}
        </RowTxt>
      </C.Row>
      <C.Row>
        <C.RowLabel>
          <C.RowLabelSub>カナ</C.RowLabelSub>
        </C.RowLabel>
        <RowTxt>
          {watch.familyNameKana} {watch.givenNameKana}
        </RowTxt>
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>メールアドレス</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.email}</RowTxt>
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>電話番号</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.phoneNumber}</RowTxt>
      </C.Row>
      <C.Separator />

      <C.Row>
        <C.RowLabel>
          <C.RowLabelMain>連携先応募者ID</C.RowLabelMain>
        </C.RowLabel>
        <RowTxt>{watch.externalApplicantId}</RowTxt>
      </C.Row>
      <C.Separator />

      {isDetailsEnabled && <BodyDetails formCtx={formCtx} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const RowTxt = styled(Txt)`
  word-break: break-all;
  padding-top: 5px;
`
