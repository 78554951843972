import { useMutation, useQueryClient } from 'react-query'
import {
  BatchCreateApplicantsRequestValue,
  useRequestBatchCreateApplicants,
} from '@/services/bffService/useRequestBatchCreateApplicants'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useBatchCreateApplicants() {
  const queryClient = useQueryClient()
  const { requestBatchCreateApplicants } = useRequestBatchCreateApplicants()

  return useMutation(
    (value: BatchCreateApplicantsRequestValue) =>
      requestBatchCreateApplicants(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
      },
    }
  )
}
