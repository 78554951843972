import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  Applicant,
  Selection,
  mapApplicant,
  SelectionStepType,
  SelectionSubStatus,
  SelectionStepResult,
  SelectionStep,
  mapSelectionRecInterview,
} from '@/services/applicantService'
import { useRequestGetSelectionStep } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useSelectionStep = () => {
  const { selectionStepGuid } = useParams<{ selectionStepGuid: string }>()

  const { data } = useGetSelectionStep(selectionStepGuid)

  const applicantGuid = useMemo(() => {
    return data?.getApplicantGuid()
  }, [data])

  const applicant = useMemo<Applicant | undefined>(() => {
    return mapApplicant(data?.getApplicant())
  }, [data])

  const registerTime = useMemo(
    () => data?.getApplicantRegisteredTime()?.toDate(),
    [data]
  )

  const selection = useMemo<Selection | undefined>(() => {
    const s = data?.getSelection()
    if (s === undefined) return undefined
    return {
      guid: s.getGuid(),
      status: s.getStatus(),
      subStatus: SelectionSubStatus.SELECTION_SUB_STATUS_UNKNOWN, // Header表示には不要なのでダミーの値
      selectionStepsList: s.getSelectionStepsList().map((ss) => ({
        guid: ss.getGuid(),
        name: ss.getName(),
        type: SelectionStepType.SELECTION_STEP_TYPE_UNKNOWN, // Header表示には不要なのでダミーの値
        sequence: ss.getSequence(),
        result: SelectionStepResult.SELECTION_STEP_RESULT_UNKNOWN, // Header表示には不要なのでダミーの値
        comment: '', // Header表示には不要なのでダミーの値
        registrationRoute: '', // Header表示には不要なのでダミーの値
      })),
      currentSelectionStepGuid: s.getCurrentSelectionStepGuid(),
      isSharing: false, // Header表示には不要なのでダミーの値
    }
  }, [data])

  const targetSelectionStep = useMemo<SelectionStep | undefined>(() => {
    const ss = data?.getTargetSelectionStep()
    if (ss === undefined) return undefined
    const sri = ss.getSelectionRecInterviewDetail()?.getSelectionRecInterview()
    const canWatchAnswer = true // 評価ページでは、アクセスできているユーザーは全て閲覧権限がある
    return {
      guid: ss.getGuid(),
      name: ss.getName(),
      type: ss.getType(),
      sequence: 0, // 選考ステップは1つしか並ばないので仮で0を入れておく
      result: ss.getResult(),
      comment: ss.getComment(),
      registrationRoute: '', // 登録経路は評価ページでは不要なのでダミーの値
      selectionRecInterview: mapSelectionRecInterview(sri, canWatchAnswer),
    }
  }, [data])

  const organizationRecInterviewTranscriptionSetting = useMemo(
    () => data?.getOrganizationRecInterviewTranscriptionSetting(),
    [data]
  )

  return {
    applicantGuid,
    applicant,
    registerTime,
    selection,
    targetSelectionStep,
    organizationRecInterviewTranscriptionSetting,
  }
}

const useGetSelectionStep = (guid: string) => {
  const { requestGetSelectionStep } = useRequestGetSelectionStep()

  return useQuery([QUERY_KEY.selectionStep, guid], () =>
    requestGetSelectionStep(guid)
  )
}
