import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetRecInterviewQuestionTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetRecInterviewQuestionTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetRecInterviewQuestionTemplate = useCallback(
    async (questionTemplateGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetRecInterviewQuestionTemplateRequest()

      req.setGuid(questionTemplateGuid)

      return callBizHuttBffService(
        'getRecInterviewQuestionTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return { requestGetRecInterviewQuestionTemplate }
}
