import { Modal, NewTabLink, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'

export type DuplicateApplicant = {
  guid: string
  name: string
}
type Props = {
  active: boolean
  externalApplicantId: string
  duplicateApplicants: DuplicateApplicant[]
  onRegister: () => void
  onCancel: () => void
}

export const DuplicateApplicantModal: React.VFC<Props> = (props) => {
  const duplicatedApplicantsLength = props.duplicateApplicants.length
  const displayLength = Math.min(duplicatedApplicantsLength, 3)
  return (
    <Modal
      active={props.active}
      title="重複登録の確認"
      onClose={props.onCancel}
    >
      <ModalBody>
        <Description>
          （連携先応募者ID: {props.externalApplicantId}）は、すでに登録済です。
          <br />
          別の応募者として登録されますがよろしいですか？
        </Description>
        <DuplicateApplicantsTitle>
          同一の連携先応募者IDが設定されている応募者（
          {duplicatedApplicantsLength}件中{displayLength}件まで表示）
        </DuplicateApplicantsTitle>
        <ApplicantsWrapper>
          {props.duplicateApplicants.map((d, i) => {
            if (i > 2) return null
            return (
              <ApplicantLink
                key={i}
                href={generatePath(INTERNAL_PATHS.applicant, {
                  applicantGuid: d.guid,
                })}
                action="open_duplicate_applicant_details"
              >
                {d.name}
              </ApplicantLink>
            )
          })}
        </ApplicantsWrapper>
      </ModalBody>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <TertiaryButton
            widthSize="L1"
            onClick={props.onCancel}
            comlinkPushParams={{
              action: 'click_cancel_button_on_duplicate_applicant_modal',
            }}
          >
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            widthSize="L1"
            onClick={props.onRegister}
            comlinkPushParams={{
              action: 'click_register_button_on_duplicate_applicant_modal',
            }}
          >
            登録
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px;
`

const Description = styled(Txt)`
  word-break: break-all;
`

const DuplicateApplicantsTitle = styled(Txt).attrs({
  size: 'm',
  bold: true,
})`
  margin-top: 20px;
`

const ApplicantsWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`

const ApplicantLink = styled(NewTabLink)`
  margin-top: 6px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:first-child {
    margin-right: 20px;
  }
`
