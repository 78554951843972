import { initImSharedFront } from '@blue-agency/im-shared-front'
import assert from 'assert'

export function initializeImSharedFront() {
  const bizAnakinFrontHost = process.env.REACT_APP_BIZ_ANAKIN_FRONT_HOST
  assert(bizAnakinFrontHost)

  const myHuttFrontHost = process.env.REACT_APP_MY_HUTT_FRONT_HOST
  assert(myHuttFrontHost)

  const skywalkerFrontHost = process.env.REACT_APP_SKYWALKER_FRONT_HOST
  assert(skywalkerFrontHost)

  const logoutEndpoint = process.env.REACT_APP_LOGOUT_ENDPOINT
  assert(logoutEndpoint)

  const openidClientId = process.env.REACT_APP_OPENID_CLIENT_ID
  assert(openidClientId)

  const openidConfigurationEndpoint =
    process.env.REACT_APP_OPENID_CONFIGURATION_ENDPOINT
  assert(openidConfigurationEndpoint)

  const bffHost = process.env.REACT_APP_API_HOST
  assert(bffHost)

  const techtouchOrg = process.env.REACT_APP_TECHTOUCH_ORGANIZATION_UUID ?? ''
  const techtouchAdmin = process.env.REACT_APP_TECHTOUCH_ADMINISTORATOR ?? ''
  const techtouchNormal = process.env.REACT_APP_TECHTOUCH_NORMAL ?? ''
  const techtouchAssistant = process.env.REACT_APP_TECHTOUCH_ASSISTANT ?? ''
  const techtouchUserID = process.env.REACT_APP_TECHTOUCH_USER_ID ?? ''
  const techtouchOrganizationID =
    process.env.REACT_APP_TECHTOUCH_ORGANIZATION_ID ?? ''
  if (techtouchOrg !== '') {
    assert(techtouchAdmin)
    assert(techtouchNormal)
    assert(techtouchAssistant)
    assert(techtouchUserID)
    assert(techtouchOrganizationID)
  }

  initImSharedFront({
    applicationName: 'biz-hutt-front',
    host: {
      bizAnakinFront: bizAnakinFrontHost,
      bizHuttFront: window.location.origin,
      myHuttFront: myHuttFrontHost,
      skywalkerFront: skywalkerFrontHost,
      bff: bffHost,
    },
    logoutEndpoint,
    openidClientId,
    openidConfigurationEndpoint,
    signInPathPrefix: '',
    techtouch: {
      organizationUuid: techtouchOrg,
      administratorProperty: techtouchAdmin,
      normalProperty: techtouchNormal,
      assistantProperty: techtouchAssistant,
      userIdProperty: techtouchUserID,
      organizationIdProperty: techtouchOrganizationID,
    },
  })
}
