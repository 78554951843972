import { useMutation, useQueryClient } from 'react-query'
import {
  UpdateApplicantCsvFormatNameRequestValue,
  useRequestUpdateApplicantCsvFormatName,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useUpdateApplicantCsvFormatName() {
  const queryClient = useQueryClient()
  const { requestUpdateApplicantCsvFormatName } =
    useRequestUpdateApplicantCsvFormatName()

  return useMutation(
    async (value: UpdateApplicantCsvFormatNameRequestValue) => {
      await requestUpdateApplicantCsvFormatName(value)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicantCsvFormats)
      },
    }
  )
}
