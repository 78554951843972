import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { assertNever } from '@/assertions'
import { MovieTranscodeStatus } from '@/services/videoSeminarService'

type Props = PropsWithClassName<{
  status: MovieTranscodeStatus
}>
export const TranscodeStatus: React.VFC<Props> = (props) => {
  switch (props.status) {
    case 'done':
      return null
    case 'failed':
      return (
        <FailedWrapper className={props.className}>
          <Icon name="caution" />
          <FailedTxt>
            動画の変換に失敗しました。お手持ちの動画再生アプリで再生できるかご確認のうえ、再度ファイル選択してください。
          </FailedTxt>
        </FailedWrapper>
      )
    case 'processing':
      return (
        <ProcessingWrapper className={props.className}>
          <Icon name="exclamation" />
          <ProcessingTxt>動画を変換しています…</ProcessingTxt>
        </ProcessingWrapper>
      )
    default:
      assertNever(props.status)
  }
}

const wrapperBaseStyle = css`
  padding: 12px;
  display: flex;
  border-radius: 4px;
  width: 460px;
`

const FailedWrapper = styled.div`
  ${wrapperBaseStyle}
  background-color: ${theme.color.red[4]};
  color: ${theme.color.red[2]};
`

const FailedTxt = styled(Txt).attrs({ color: theme.color.red[2] })`
  margin-left: 4px;
`

const ProcessingWrapper = styled.div`
  ${wrapperBaseStyle}
  background-color: ${theme.color.orange[2]};
  color: ${theme.color.orange[1]};
  justify-content: center;
`

const ProcessingTxt = styled(Txt).attrs({ color: theme.color.orange[1] })`
  margin-left: 4px;
`
