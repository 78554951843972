import * as yup from 'yup'
import {
  ApplicantForm,
  EducationalBackground,
  WorkHistory,
} from '@/services/applicantService'

export type Form = ApplicantForm

const basicStringSchema = (max: number) =>
  yup.string().max(max, `${max}文字以内で入力してください`)

const educationalBackgroundSchema: yup.SchemaOf<EducationalBackground> = yup
  .object()
  .shape({
    // NOTE: 学歴・職歴を入れる際は名前は必須
    // ref: https://github.com/blue-agency/proton/pull/1408#discussion_r677076473
    schoolName: basicStringSchema(255).when(['faculty', 'note'], {
      is: (faculty: string, note: string) => faculty !== '' || note !== '',
      then: yup.string().required('学歴に学校名は必須です'),
    }),
    faculty: basicStringSchema(255),
    note: basicStringSchema(100_000),
  })

const workHistorySchema: yup.SchemaOf<WorkHistory> = yup.object().shape({
  companyName: basicStringSchema(255).when(['jobDescription', 'note'], {
    is: (jobDescription: string, note: string) =>
      jobDescription !== '' || note !== '',
    then: yup.string().required('職務経歴に会社名は必須です'),
  }),
  jobDescription: basicStringSchema(255),
  note: basicStringSchema(100_000),
})

// 仕様: https://stadium.kibe.la/notes/22276
export const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    externalApplicantId: yup
      .string()
      .matches(
        /^[\x20-\x7e]{0,128}$/,
        '半角英数記号128文字以下で入力してください'
      ),
    familyName: basicStringSchema(255).required('入力してください'),
    givenName: basicStringSchema(255),
    familyNameKana: basicStringSchema(255),
    givenNameKana: basicStringSchema(255),
    email: basicStringSchema(255).email('メールアドレスを入力してください'),
    postalCode: yup
      .string()
      .matches(/^[0-9]{0,10}$/, '半角数字10文字以下で入力してください'),
    address: basicStringSchema(1000),
    gender: yup.string(),
    genderNote: basicStringSchema(1000),
    birthDate: yup.date(),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]{0,20}$/, '半角数字20文字以下で入力してください'),
    educationalBackgroundsList: yup
      .array()
      .of(educationalBackgroundSchema)
      .max(3),
    educationalBackgroundNote: basicStringSchema(100_000),
    workHistoriesList: yup.array().of(workHistorySchema).max(5),
    workHistoryNote: basicStringSchema(100_000),
    note: basicStringSchema(100_000),
  })
  .defined()
