import { theme, Txt, Icon } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  info: (string | React.ReactElement)[]
}

export const InfoBox: React.VFC<Props> = ({ info }) => {
  return (
    <TopInfo>
      <Icon name="light-bulb" size="l" />
      <TopInfoBox>
        <Ul>
          {info.map((s, i) => (
            <Li key={i}>{typeof s === 'string' ? <Txt>{s}</Txt> : s}</Li>
          ))}
        </Ul>
      </TopInfoBox>
    </TopInfo>
  )
}

const TopInfo = styled.div`
  display: inline-flex;
  width: 100%;
  background-color: ${theme.color.gray[5]};
  padding: 15px;
  margin-top: 20px;
`

const TopInfoBox = styled.div`
  padding-left: 10px;
`

const Ul = styled.ul`
  margin-left: 18px;
`

const Li = styled.li`
  margin-bottom: 5px;
`
