import { useCallback } from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Applicant, Gender } from '@/services/applicantService'
import {
  getGenderName,
  birthDateFormat,
  registerTimeFormat,
} from '@/services/applicantService'
import { INTERNAL_PATHS } from '@/services/urlService'

type Props = PropsWithClassName<{
  applicantGuid: string
  applicant: Applicant
  registerTime: Date
  registrationRoute?: string
  editable?: boolean
}>
export const Profile: React.VFC<Props> = ({
  applicantGuid,
  applicant,
  registerTime,
  registrationRoute,
  editable,
  className,
}) => {
  const history = useHistory()
  const edit = useCallback(() => {
    history.push(generatePath(INTERNAL_PATHS.editApplicant, { applicantGuid }))
  }, [history, applicantGuid])

  return (
    <Wrapper className={className}>
      {editable && (
        <EditWrapper onClick={edit}>
          <EditIcon />
        </EditWrapper>
      )}
      <Row>
        <RowTitle>im応募者ID</RowTitle>
        <RowTxt>{applicantGuid}</RowTxt>
      </Row>
      {applicant.externalApplicantId && (
        <Row>
          <RowTitle>連携先応募者ID</RowTitle>
          <RowTxt>{applicant.externalApplicantId}</RowTxt>
        </Row>
      )}
      {applicant.birthDate && (
        <Row>
          <RowTitle>生年月日</RowTitle>
          <RowTxt>{birthDateFormat(applicant.birthDate)}</RowTxt>
        </Row>
      )}
      {(applicant.gender !== Gender.GENDER_UNKNOWN || applicant.genderNote) && (
        <Row>
          <RowTitle>性別</RowTitle>
          <RowTxt>
            {applicant.gender !== Gender.GENDER_UNKNOWN &&
              applicant.gender !== undefined && (
                <>
                  {getGenderName(applicant.gender)}
                  <br />
                </>
              )}
            {applicant.genderNote && (
              <>
                <Note>備考：</Note>
                {applicant.genderNote}
              </>
            )}
          </RowTxt>
        </Row>
      )}
      {applicant.email && (
        <Row>
          <RowTitle>メールアドレス</RowTitle>
          <RowTxt>{applicant.email}</RowTxt>
        </Row>
      )}
      {applicant.phoneNumber && (
        <Row>
          <RowTitle>電話番号</RowTitle>
          <RowTxt>{applicant.phoneNumber}</RowTxt>
        </Row>
      )}
      {(applicant.postalCode || applicant.address) && (
        <Row>
          <RowTitle>住所</RowTitle>
          <RowTxt>
            {applicant.postalCode} {applicant.address}
          </RowTxt>
        </Row>
      )}
      {applicant.educationalBackgroundsList.map((e, i) => (
        <Row key={i}>
          <RowTitle>{i === 0 && '最終'}学歴</RowTitle>
          <RowTxt>
            {e.schoolName}
            <br />
            {e.faculty}
            <br />
            {e.note && (
              <>
                <Note>備考：</Note>
                {e.note}
              </>
            )}
          </RowTxt>
        </Row>
      ))}
      {applicant.educationalBackgroundNote && (
        <Row>
          <RowTitle>学歴備考</RowTitle>
          <RowTxt>{applicant.educationalBackgroundNote}</RowTxt>
        </Row>
      )}
      {applicant.workHistoriesList.map((e, i) => (
        <Row key={i}>
          <RowTitle>職務経歴</RowTitle>
          <RowTxt>
            {e.companyName}
            <br />
            {e.jobDescription}
            <br />
            {e.note && (
              <>
                <Note>備考：</Note>
                {e.note}
              </>
            )}
          </RowTxt>
        </Row>
      ))}
      {applicant.workHistoryNote && (
        <Row>
          <RowTitle>職歴備考</RowTitle>
          <RowTxt>{applicant.workHistoryNote}</RowTxt>
        </Row>
      )}
      {applicant.note && (
        <Row>
          <RowTitle>備考</RowTitle>
          <RowTxt>{applicant.note}</RowTxt>
        </Row>
      )}
      {registrationRoute && (
        <Row>
          <RowTitle>登録経路</RowTitle>
          <RowTxt>{registrationRoute}</RowTxt>
        </Row>
      )}
      <Row>
        <RowTitle>登録日時</RowTitle>
        <RowTxt>{registerTimeFormat(registerTime)}</RowTxt>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  background-color: ${theme.color.white[1]};
  padding-bottom: 20px;
`

const Row = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-left: 16px;
  display: flex;
`

const RowTitle = styled(Txt).attrs({ bold: true })`
  width: 140px;
`

const RowTxt = styled(Txt)`
  max-width: 640px;
  overflow-wrap: break-word;
`

const Note = styled.span`
  color: ${theme.color.gray[1]};
`

const EditWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EditIcon = styled(Icon).attrs({ name: 'edit' })``
