import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { RevokeRequestSelectionRecInterviewAnswerRequest } from '@blue-agency/proton/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type RevokeRequestSelectionRecInterviewAnswerRequestValue = {
  applicantGuid: string
  selectionStepGuid: string
}

export function useRequestRevokeRequestSelectionRecInterviewAnswer() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestRevokeRequestSelectionRecInterviewAnswer = useCallback(
    async (value: RevokeRequestSelectionRecInterviewAnswerRequestValue) => {
      const metadata = await buildMetadata()

      const req = new RevokeRequestSelectionRecInterviewAnswerRequest()

      req.setSelectionStepGuid(value.selectionStepGuid)

      return callBizHuttBffService(
        'revokeRequestSelectionRecInterviewAnswer',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestRevokeRequestSelectionRecInterviewAnswer,
  }
}
