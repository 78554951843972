import React, { useCallback, useMemo, useState } from 'react'
import { theme, Txt, Icon, Sort as _Sort } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { assertNever } from '@/assertions'
import { Box } from '@/components/Box'
import { Count as _Count } from '@/components/videoSeminar/Count'
import { EllipsisTitleSpan } from '@/components/videoSeminar/EllipsisTitleSpan'
import { FormatTime as _FormatTime } from '@/components/videoSeminar/FormatTime'
import { HeatMap } from '@/components/videoSeminar/HeatMap'
import {
  VideoSeminarEvent as VideoSeminarEventType,
  VideoSeminarContentReport,
} from '@/services/applicantService'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

type SortColumn = keyof Pick<
  VideoSeminarContentReport,
  'sequence' | 'totalViewCount' | 'totalViewTimeSeconds'
>
type SortOrder = 'asc' | 'desc'
type SortType = {
  column: SortColumn
  order: SortOrder
}
const getDefaultSortOrder = (column: SortColumn): SortOrder => {
  switch (column) {
    case 'sequence':
      return 'asc'
    case 'totalViewCount':
    case 'totalViewTimeSeconds':
      return 'desc'
    default:
      assertNever(column)
  }
}
const defaultSort: SortType = {
  column: 'sequence',
  order: 'asc',
}
type Props = PropsWithClassName<{
  videoSeminarEvent: VideoSeminarEventType
}>
export const VideoSeminarEvent: React.VFC<Props> = ({
  videoSeminarEvent,
  className,
}) => {
  const [sort, setSort] = useState<SortType>(defaultSort)

  const genSortHandler = useCallback(
    (column: SortColumn) => () => {
      // 現在対象になっているカラムならasc/descを入れ替える
      // 現在対象になっていないカラムならデフォルトのorderでソートする
      setSort((prev) => {
        if (prev.column !== column) {
          return {
            column,
            order: getDefaultSortOrder(column),
          }
        }
        return {
          column,
          order: prev.order === 'asc' ? 'desc' : 'asc',
        }
      })
    },
    []
  )

  const renderSort = (column: SortColumn) => (
    <Sort
      weakColor={theme.color.navy[2]}
      order={sort.column === column ? sort.order : 'none'}
      onClick={genSortHandler(column)}
    />
  )

  const sortedReports = useMemo(() => {
    return videoSeminarEvent.contentReports.slice().sort((a, b) => {
      if (sort.order === 'asc') {
        return a[sort.column] - b[sort.column]
      }
      return b[sort.column] - a[sort.column]
    })
  }, [videoSeminarEvent, sort])

  return (
    <Wrapper className={className}>
      <Title>
        <TitleTxt>{videoSeminarEvent.seminarTitle}</TitleTxt>
      </Title>
      <TopArea>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex">
            <Txt>
              <GraySpan>登録日時：</GraySpan>
              {format(videoSeminarEvent.registerTime, 'yyyy/MM/dd  HH:mm')}
            </Txt>
            {videoSeminarEvent.participateTime && (
              <>
                <Box width="20px" />
                <Txt>
                  <GraySpan>参加日時：</GraySpan>
                  {format(
                    videoSeminarEvent.participateTime,
                    'yyyy/MM/dd  HH:mm'
                  )}
                </Txt>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <QuestionIconWrapper
              data-tip
              data-for={TOOLTIP_ID_MAP.videoSeminarEventReport}
            >
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip
              id={TOOLTIP_ID_MAP.videoSeminarEventReport}
              arrowPosition="topRight"
            >
              <Txt>
                表示されている数値、グラフは計測技術の性質上
                <br />
                100％の精度を保証するものではありません。
              </Txt>
            </Tooltip>
            <Txt size="s">レポートに関するご注意事項</Txt>
          </Box>
        </Box>
      </TopArea>
      <TableArea>
        <Table>
          <TableHead>
            <TableRow>
              <Th property="no">
                No
                {renderSort('sequence')}
              </Th>
              <Th property="title">タイトル</Th>
              <Th property="totalViewCount">
                再生回数
                {renderSort('totalViewCount')}
              </Th>
              <Th property="totalViewTime">
                総視聴時間
                {renderSort('totalViewTimeSeconds')}
              </Th>
              <Th property="audienceRetention">視聴回数ヒートマップ</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReports.map((report) => {
              return (
                <TableRow key={report.contentGuid}>
                  <Td property="no">{report.sequence}</Td>
                  <Td property="title">
                    <Txt>
                      <EllipsisTitleSpan
                        title={report.contentTitle}
                        maxLength={40}
                      />
                    </Txt>
                  </Td>
                  <Td property="totalViewCount">
                    <Count count={report.totalViewCount} max={999} />
                  </Td>
                  <Td property="totalViewTime">
                    <FormatTime
                      timeSeconds={report.totalViewTimeSeconds}
                      maxDigitsOfHoursToShow={1}
                    />
                  </Td>
                  <Td property="audienceRetention">
                    <HeatMap
                      audienceRetention={report.audienceRetention}
                      movieDurationSeconds={report.movieDurationSeconds}
                      // TODO: ヒートマップクリックで動画再生
                    />
                  </Td>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableArea>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  border: 1px solid ${theme.color.gray[4]};
`

const Title = styled.div`
  height: 32px;
  background-color: ${theme.color.navy[3]};
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const TitleTxt = styled(Txt).attrs({ size: 'l' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TopArea = styled.div`
  height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const GraySpan = styled.span`
  color: ${theme.color.gray[1]};
`

const TableArea = styled.div`
  width: 100%;
  padding: 0 16px;
`

const Table = styled.table`
  width: 100%;
`

const TableHead = styled.thead`
  height: 34px;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

type Property =
  | 'no'
  | 'title'
  | 'totalViewCount'
  | 'totalViewTime'
  | 'audienceRetention'
type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  background-color: ${theme.color.gray[5]};
  margin-right: 2px;
  border-bottom: 1px solid ${theme.color.gray[4]};
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
  /* thは全て左寄せ (ソートアイコンは右寄せになるようspace-between指定) */
  justify-content: space-between;
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  height: 50px;
  margin-right: 2px;
  border-bottom: 1px solid ${theme.color.gray[4]};
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  no: css`
    flex: 0 0 64px;
    justify-content: flex-end;
  `,
  title: css`
    flex: 0 0 316px;
    justify-content: flex-start;
  `,
  totalViewCount: css`
    flex: 0 0 110px;
    justify-content: flex-end;
  `,
  totalViewTime: css`
    flex: 0 0 140px;
    justify-content: flex-end;
  `,
  audienceRetention: css`
    flex: 1 0 auto;
    min-width: 200px;
    justify-content: flex-start;
  `,
}

const QuestionIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`

const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
`

const Sort = styled(_Sort)`
  margin-left: 8px;
`

const FormatTime = styled(_FormatTime)`
  font-size: ${theme.fontSize.l};
  & > span {
    font-size: ${theme.fontSize.xs};
  }
`

const Count = styled(_Count)`
  font-size: ${theme.fontSize.l};
  & > span {
    font-size: ${theme.fontSize.xs};
  }
`
