import { createContainer } from '@blue-agency/front-state-management'
import { PermissionDenied } from '@blue-agency/im-shared-front'
import { BizHuttBffServicePromiseClient } from '@blue-agency/proton/biz_hutt_bff'
import { useQuery } from 'react-query'
import { useRequestGetPermittedRpcs } from '../bffService'
import { QUERY_KEY } from '../queryKeyService'

type PermittedRpc = Capitalize<keyof BizHuttBffServicePromiseClient>

function useAuthz() {
  const { requestGetPermittedRpcs } = useRequestGetPermittedRpcs()

  const { data, isLoading } = useQuery(
    [QUERY_KEY.loggedInStaff, QUERY_KEY.permittedRpcs],
    requestGetPermittedRpcs
  )

  if (isLoading) {
    return { permittedRpcs: undefined }
  }

  const permittedRpcs = (data?.getPermittedRpcsList() ??
    []) as Array<PermittedRpc>

  const authorizeByRpcName = (rpcName: PermittedRpc) => {
    if (!permittedRpcs.includes(rpcName)) {
      throw new PermissionDenied()
    }
  }

  return { permittedRpcs, authorizeByRpcName }
}

export const AuthzContainer = createContainer(useAuthz)
