import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/dist/im'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { TOOLTIP_ID_MAP } from '@/services/tooltipService'

export const formatTime = (timeSeconds: number): string => {
  const hours = getHours(timeSeconds)
  const minutes = getMinutes(timeSeconds)
  const seconds = getSeconds(timeSeconds)

  return hours > 0
    ? `${hours}時間${minutes}分${seconds}秒`
    : `${minutes}分${seconds}秒`
}

export const FormatTime: React.VFC<
  PropsWithClassName<{
    timeSeconds: number
    /**
     * 表示する時間の桁数の最大値
     * 未指定の場合は省略せずに全て表示
     * e.g. 2を指定した場合は99時間まで表示
     **/
    maxDigitsOfHoursToShow?: number
  }>
> = ({ timeSeconds, maxDigitsOfHoursToShow, className }) => {
  const hours = getHours(timeSeconds)
  const minutes = getMinutes(timeSeconds)
  const seconds = getSeconds(timeSeconds)

  if (
    maxDigitsOfHoursToShow &&
    hours.toString().length > maxDigitsOfHoursToShow
  ) {
    const tooltipID = TOOLTIP_ID_MAP.videoSeminarEventReportFormatTime + uuid()

    return (
      <>
        <Wrapper data-tip data-for={tooltipID} className={className}>
          {'9'.repeat(maxDigitsOfHoursToShow)}
          <Unit>時間</Unit>
          59
          <Unit>分</Unit>
          59+<Unit>秒</Unit>
        </Wrapper>
        <Tooltip id={tooltipID} arrowPosition="topLeft">
          <Txt>{formatTime(timeSeconds)}</Txt>
        </Tooltip>
      </>
    )
  }

  return (
    <Wrapper className={className}>
      {hours > 0 && (
        <>
          {hours}
          <Unit>時間</Unit>
        </>
      )}
      {minutes}
      <Unit>分</Unit>
      {seconds}
      <Unit>秒</Unit>
    </Wrapper>
  )
}

const getHours = (timeSeconds: number) => Math.floor(timeSeconds / 3600)
const getMinutes = (timeSeconds: number) => Math.floor(timeSeconds / 60) % 60
const getSeconds = (timeSeconds: number) => timeSeconds % 60

const Wrapper = styled.span`
  font-size: 18px;
`

const Unit = styled.span`
  font-size: ${theme.fontSize.s};
  margin-right: 1px;
  color: ${theme.color.gray[1]};
`
