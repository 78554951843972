import { useQuery } from 'react-query'
import { useRequestListSelectionSendMailHistories } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useListSelectionSendMailHistories(selectionGuid: string) {
  const { requestListSelectionSendMailHistories } =
    useRequestListSelectionSendMailHistories()

  return useQuery([QUERY_KEY.selectionSendMailHistories, selectionGuid], () =>
    requestListSelectionSendMailHistories(selectionGuid)
  )
}
