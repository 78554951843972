import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateRecInterviewTemplateRequest } from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'
import {
  toRecInterviewTemplateInput,
  RecInterviewTemplateInputValue,
} from './toRecInterviewTemplateInput'

export function useRequestCreateRecInterviewTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateRecInterviewTemplate = useCallback(
    async (value: RecInterviewTemplateInputValue) => {
      const metadata = await buildMetadata()

      const req = new CreateRecInterviewTemplateRequest()
      req.setTemplate(toRecInterviewTemplateInput(value))

      return callBizHuttBffService(
        'createRecInterviewTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateRecInterviewTemplate,
  }
}
