import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateSelectionStepMasterRequest } from '@blue-agency/proton/biz_hutt_bff'
import { SelectionStepType } from '@blue-agency/proton/web/v2/im'
import { callBizHuttBffService } from './bffClient'
import { useMetadata } from './metadata'

export type CreateSelectionStepMasterRequestValue = {
  name: string
  type: SelectionStepType
}

export function useRequestCreateSelectionStepMaster() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateSelectionStepMaster = useCallback(
    async (value: CreateSelectionStepMasterRequestValue) => {
      const metadata = await buildMetadata()

      const req = new CreateSelectionStepMasterRequest()

      req.setName(value.name)
      req.setType(value.type)

      return callBizHuttBffService(
        'createSelectionStepMaster',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff?.guid]
  )

  return {
    requestCreateSelectionStepMaster,
  }
}
