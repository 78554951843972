import { useMutation, useQueryClient } from 'react-query'
import {
  DeleteSelectionStepFromApplicantRequestValue,
  useRequestDeleteSelectionStepFromApplicant,
} from '@/services/bffService/useRequestDeleteSelectionStepFromApplicant'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useDeleteSelectionStepFromApplicant(applicantGuid: string) {
  const queryClient = useQueryClient()
  const { requestDeleteSelectionStepFromApplicant } =
    useRequestDeleteSelectionStepFromApplicant()

  return useMutation(
    (value: DeleteSelectionStepFromApplicantRequestValue) =>
      requestDeleteSelectionStepFromApplicant(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.applicants)
        queryClient.invalidateQueries([QUERY_KEY.applicant, applicantGuid])
      },
    }
  )
}
