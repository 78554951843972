import React from 'react'
import {
  GetRecInterviewTemplateResponse,
  ListAllRecInterviewTemplatesResponse,
} from '@blue-agency/proton/web/v2/biz_hutt_bff'
import { assertIsDefined } from '@/assertions'
import {
  DueDurationText,
  DueTimeText,
  FormDueTime,
} from '@/components/RecInterviewTemplate'
import { DueDuration, formatTime } from '@/services/recInterviewTemplateService'

type Props = {
  template:
    | ListAllRecInterviewTemplatesResponse.RecInterviewTemplate
    | GetRecInterviewTemplateResponse
}

export const Deadline: React.VFC<Props> = ({ template }) => {
  if (template.getDueDuration()) {
    const duration: DueDuration = {
      days: template.getDueDuration()?.getDays() ?? 0,
      hour: template.getDueDuration()?.getHour() ?? 0,
    }
    return <DueDurationText duration={duration} />
  }
  if (template.getDueTime()) {
    const date = template?.getDueTime()?.toDate()
    assertIsDefined(date)

    const formDueTime: FormDueTime = {
      time: formatTime(date),
      hour: date.getHours(),
    }
    return <DueTimeText time={formDueTime} />
  }
  throw new Error('invalid template')
}
