import { useMutation, useQueryClient } from 'react-query'
import {
  useRequestCreateVideoSeminar,
  CreateVideoSeminarRequestValue,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export function useCreateVideoSeminar() {
  const queryClient = useQueryClient()
  const { requestCreateVideoSeminar } = useRequestCreateVideoSeminar()
  return useMutation(
    (value: CreateVideoSeminarRequestValue) => requestCreateVideoSeminar(value),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.videoSeminars)
      },
    }
  )
}
