import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb'

const msToNanos = 1000000

/**
 * BFF から取れる timestamp はすべて nullable なので、
 * nullable チェックを前段でやる手間を減らすために nullable を受け付ける
 */
export function timestampToDate(
  ts?: google_protobuf_timestamp_pb.Timestamp.AsObject
): Date | undefined {
  if (!ts) {
    return undefined
  }
  return new Date(ts.seconds * 1000 + Math.round(ts.nanos / msToNanos))
}
