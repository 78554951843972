import * as yup from 'yup'
import { QuestionFormat } from './questionFormatOption'

export type Form = {
  name: string
  content: string
  note?: string
  videoFile?: {
    key: string
    url: string
    name: string
    type: string
  }
  format: QuestionFormat
  maxRecCount?: string
  maxDurationSeconds?: string
}

export const formSchema: yup.SchemaOf<Form> = yup
  .object()
  .shape({
    name: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
    content: yup
      .string()
      .required('入力してください')
      .max(255, '255文字以内で入力してください'),
    note: yup.string().optional().max(255, '255文字以内で入力してください'),
    format: yup
      .mixed<QuestionFormat>()
      .required()
      .oneOf(['text', 'video'], '選択してください'),
  })
  .defined()
