import { Txt, theme, LineClampedTxt } from '@blue-agency/rogue'
import { generatePath } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { DateTimeFormat } from '@/components/DateTimeFormat'
import { TableBase } from '@/components/TableBase'
import { INTERNAL_PATHS } from '@/services/urlService'
import type { Guides } from './types'

type Props = {
  guides: Guides
}
export function GuideList({ guides }: Props) {
  const emptyListMessage = guides.length === 0 && (
    <EmptyListMessage>
      <Txt size="l">登録された情報はありません</Txt>
    </EmptyListMessage>
  )

  return (
    <GuideListWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <Th property="name">タイトル</Th>
            <Th property="guideID">面接ガイドID</Th>
            <Th property="padding"></Th>
            <Th property="registerTime">登録日時</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {guides.length > 0 &&
            guides.map((guide) => {
              const webInterviewGuidePagePath = generatePath(
                INTERNAL_PATHS.webInterviewGuide,
                {
                  webInterviewGuideGuid: guide.guid,
                }
              )
              const onClick = () => {
                window.location.href = webInterviewGuidePagePath
              }
              return (
                <TableRow key={guide.guid}>
                  <Td property="name">
                    <CellLink onClick={onClick}>
                      <GuideName>{guide.name}</GuideName>
                    </CellLink>
                  </Td>
                  <Td property="guideID">
                    <CellLink onClick={onClick}>{guide.guid}</CellLink>
                  </Td>
                  <Td property="padding">
                    <CellLink onClick={onClick} />
                  </Td>
                  <Td property="registerTime">
                    <CellLink onClick={onClick}>
                      <DateTimeFormat dateTime={guide.registerTime} />
                    </CellLink>
                  </Td>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {emptyListMessage}
    </GuideListWrapper>
  )
}

const EmptyListMessage = styled.div`
  text-align: center;
  padding-top: 56px;
`

const Table = styled(TableBase)`
  width: 100%;
`

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`

const TableBody = styled.tbody``

const TableRow = styled.tr`
  display: flex;
  align-items: center;
`

type Property = 'guideID' | 'name' | 'registerTime' | 'padding'
type TableCellProps = {
  property: Property
}

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 29px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 29px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => propertyStyles[property]}
`

const propertyStyles: Record<Property, FlattenSimpleInterpolation> = {
  name: css`
    flex: 0 0 240px;
    justify-content: flex-start;
  `,
  registerTime: css`
    flex: 0 0 120px;
    justify-content: flex-start;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
  guideID: css`
    display: flex;
    justify-content: center;
  `,
}

const CellLink = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  margin: 0 -50px;
  padding: 0 50px;
  color: inherit;
  cursor: pointer;
`

const GuideListWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.color.white[1]};
`

const GuideName = styled(LineClampedTxt).attrs({ size: 'm', line: 2 })``
