import { useCallback, useState } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { CustomBizHuttBffGrpcError } from '@/services/bffService'
import { useDeleteSelectionStepMaster } from './useDeleteSelectionStepMaster'

type Args = {
  guid: string
  onSuccessDelete: () => void
}

export const useDeleteModal = ({ guid, onSuccessDelete }: Args) => {
  const { mutateAsync } = useDeleteSelectionStepMaster()
  const [failedInUseError, setFailedInUseError] = useState(false)

  const deleteSelectionStepMaster = useCallback(async () => {
    setFailedInUseError(false)
    try {
      await mutateAsync({ guid })
    } catch (e) {
      if (e instanceof CustomBizHuttBffGrpcError) {
        if (e.hasMatchErrorDetail('SELECTION_STEP_MASTER_IN_USE')) {
          setFailedInUseError(true)
          return
        }
      }
      Sentry.captureException(e)
      commonErrorToast()
      return
    }
    toast('選考ステップを削除しました')
    onSuccessDelete()
  }, [mutateAsync, onSuccessDelete, guid])

  return {
    deleteSelectionStepMaster,
    failedInUseError,
    setFailedInUseError,
  }
}
