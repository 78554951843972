import React from 'react'
import {
  administrator,
  PageLayoutWithGlonavi,
} from '@blue-agency/im-shared-front'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import type { SelectionStepPageProps } from '..'
import { Content as C } from './Content'
import { Header as H } from './Header'
import { Tabs } from './Tabs'
import { useTabs } from './useTabs'

export const PcRoot: React.VFC<SelectionStepPageProps> = (props) => {
  const tabsCtx = useTabs()

  const showLinkToApplicantPage =
    props.loggedInStaff.authzType === administrator

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.white[1]}>
      <Wrapper>
        <Header
          applicantGuid={props.applicantGuid}
          applicant={props.applicant}
          selection={props.selection}
          showLinkToApplicantPage={showLinkToApplicantPage}
        />
        <BottomArea>
          <TabsArea
            selectedTab={tabsCtx.selectedTab}
            onClick={tabsCtx.switchTab}
          />
          <ContentArea>
            <Content
              selectedTab={tabsCtx.selectedTab}
              applicantGuid={props.applicantGuid}
              applicant={props.applicant}
              registerTime={props.registerTime}
              targetSelectionStep={props.targetSelectionStep}
              organizationRecInterviewTranscriptionSetting={
                props.organizationRecInterviewTranscriptionSetting
              }
            />
          </ContentArea>
        </BottomArea>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled(H)`
  width: 100%;
  flex: 0 78px;
`

const BottomArea = styled.div`
  display: flex;
  flex: 1 1 auto;
`

const TabsArea = styled(Tabs)`
  flex: 0 0 160px;
  position: sticky;
  top: 0;
  align-self: start;
`

const ContentArea = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: ${theme.color.gray[5]};
  height: 100%;
`

const Content = styled(C)`
  flex: 1 0 auto;
  border-left: 1px solid ${theme.color.gray[4]};
`
