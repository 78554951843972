import { useMemo } from 'react'
import {
  ApplicantCsvMappingDestination,
  SharingTypeInput,
} from '@blue-agency/proton/im'
import { useQuery } from 'react-query'
import { SelectionSharingType } from '@/services/applicantService/applicantTypes'
import { useRequestGetApplicantCsvFormat } from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import type { Target } from '../types'

export const useApplicantCsvForamt = (guid: string | undefined) => {
  const {
    data: getApplicantCsvFormat,
    isLoading: getApplicantCsvFormatLoading,
  } = useGetApplicantCsvFormat(guid)
  const format = useMemo(() => {
    if (!guid || getApplicantCsvFormatLoading || !getApplicantCsvFormat) {
      return undefined
    }
    const mappings = getApplicantCsvFormat.getMappingsList().map((m) => {
      return {
        src: m.getSrc(),
        dst: mapApplicantCsvMappingDestination(m.getDst()),
      }
    })
    return {
      guid: guid,
      selectionSharing: mapSelectionSharingType(
        getApplicantCsvFormat.getSelectionSharingType()
      ),
      mappings: mappings,
    }
  }, [guid, getApplicantCsvFormat, getApplicantCsvFormatLoading])

  return { format }
}

const mapSelectionSharingType = (
  sharingTypeInput: SharingTypeInput | undefined
): SelectionSharingType => {
  if (sharingTypeInput?.getAll()) {
    return 'SHARING_TYPE_ALL'
  }
  return 'SHARING_TYPE_UNSHARED'
}

const mapApplicantCsvMappingDestination = (
  dst: ApplicantCsvMappingDestination
): Target => {
  switch (dst) {
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_FAMILY_NAME:
      return 'familyName'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_GIVEN_NAME:
      return 'givenName'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_FAMILY_NAME_KANA:
      return 'familyNameKana'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_GIVEN_NAME_KANA:
      return 'givenNameKana'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_EMAIL:
      return 'email'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_PHONE_NUMBER:
      return 'phoneNumber'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_EXTERNAL_APPLICANT_ID:
      return 'externalApplicantId'
    case ApplicantCsvMappingDestination.APPLICANT_CSV_MAPPING_DESTINATION_NOTE:
      return 'note'
    default:
      return ''
  }
}

const useGetApplicantCsvFormat = (guid: string | undefined) => {
  const { requestGetApplicantCsvFormat } = useRequestGetApplicantCsvFormat()

  return useQuery(
    [QUERY_KEY.applicantCsvFormat, guid],
    () => requestGetApplicantCsvFormat({ guid: guid }),
    {
      enabled: !!guid,
    }
  )
}
