import { useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import {
  Icon,
  theme,
  Txt,
  InputText,
  WeakColorSeparator,
  toast,
} from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { yupResolver } from '@hookform/resolvers/yup'
import { captureException } from '@sentry/minimal'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import {
  getSelectionStepResultText,
  SelectionStepResult,
} from '@/services/applicantService'
import {
  SelectionStepResultCommentForm,
  selectionStepResultCommentFormSchema,
} from './formSchema'
import { useUpdateSelectionStepResultComment } from './useUpdateSelectionStepResultComment'

type Props = PropsWithClassName<{
  applicantGuid: string
  selectionGuid: string
  selectionStepGuid: string
  selectionStepName: string
  selectionStepResult: SelectionStepResult
  comment: string
  onClose: () => void
}>

export const SelectionStepResultComment: React.VFC<Props> = (props) => {
  const { mutateAsync: updateSelectionStepResultComment } =
    useUpdateSelectionStepResultComment(props.applicantGuid)

  const formCtx = useForm<SelectionStepResultCommentForm>({
    resolver: yupResolver(selectionStepResultCommentFormSchema),
    mode: 'onBlur',
    defaultValues: {
      comment: props.comment,
    },
  })

  const submit = useCallback(async () => {
    const valid = await formCtx.trigger()
    if (!valid) return
    const values = formCtx.getValues()

    try {
      await updateSelectionStepResultComment({
        selectionGuid: props.selectionGuid,
        selectionStepGuid: props.selectionStepGuid,
        comment: values.comment ?? '',
      })
    } catch (e) {
      captureException(e)
      commonErrorToast()
      return
    }

    toast('選考結果を保存しました')
    props.onClose()
  }, [updateSelectionStepResultComment, formCtx, props])

  return (
    <Wrapper className={props.className}>
      <Title>
        <TitleTxt>
          面接結果 ({props.selectionStepName})
          <CloseIcon onClick={props.onClose} />
        </TitleTxt>
      </Title>
      <Content>
        <Row>
          <RowTxt>選考結果</RowTxt>
          <Resultxt>
            {getSelectionStepResultText(props.selectionStepResult)}
          </Resultxt>
        </Row>
        <Separator />
        <Row>
          <RowTxt>コメント</RowTxt>
          <CommentInputWrapper>
            <CommentInput
              {...formCtx.register('comment')}
              onBlurError={
                formCtx.formState.errors.comment?.message ?? undefined
              }
            />
            <Txt color={theme.color.gray[1]}>
              {formCtx.watch('comment')?.length}/2000文字
            </Txt>
          </CommentInputWrapper>
        </Row>
        <SubmitButton onClick={submit}>確定</SubmitButton>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${theme.color.gray[4]};
`

const Title = styled.div`
  height: 32px;
  background-color: ${theme.color.navy[3]};
  display: flex;
  align-items: center;
  padding: 0 16px;
`

const TitleTxt = styled(Txt).attrs({ size: 'l' })`
  white-space: nowrap;
  overflow: hidden;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`

const RowTxt = styled(Txt)`
  width: 30%;
  padding-top: 5px;
`

const Resultxt = styled(Txt)`
  width: 100%;
  padding-top: 5px;
`

const CommentInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const CommentInput = styled(InputText).attrs({
  type: 'textarea',
  size: 'max',
})`
  width: 100%;
`

const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`

const SubmitButton = styled(PrimaryButton).attrs({
  widthSize: 'L1',
  comlinkPushParams: { action: 'update_selection_step_result_comment' },
})`
  margin: 30px auto 20px;
`
