import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { UpdateRecInterviewQuestionTemplateRequest } from '@blue-agency/proton/biz_hutt_bff'
import { RecInterviewQuestionTemplateInput } from '@blue-agency/proton/im'
import { callBizHuttBffService } from '@/services/bffService/bffClient'
import { useMetadata } from '@/services/bffService/metadata'
import { QuestionFormat } from '@/services/questionTemplateService'

export type UpdateRecInterviewQuestionTemplateRequestValue = {
  questionTemplateGuid: string
  name: string
  content: string
  note?: string
  videoFileKey?: string
  format: QuestionFormat
  maxRecCount?: string
  maxDurationSeconds?: string
}

export function useRequestUpdateRecInterviewQuestionTemplate() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestUpdateRecInterviewQuestionTemplate = useCallback(
    async (value: UpdateRecInterviewQuestionTemplateRequestValue) => {
      const metadata = await buildMetadata()

      const req = new UpdateRecInterviewQuestionTemplateRequest()
      req.setGuid(value.questionTemplateGuid)

      const qt = new RecInterviewQuestionTemplateInput()
      qt.setName(value.name)
      qt.setContent(value.content)
      if (value.note !== undefined) {
        qt.setNote(value.note)
      }
      if (value.videoFileKey !== undefined) {
        qt.setVideoFileKey(value.videoFileKey)
      }

      switch (value.format) {
        case 'text':
          qt.setText(new RecInterviewQuestionTemplateInput.Text())
          break
        case 'video':
          const video = new RecInterviewQuestionTemplateInput.Video()
          if (value.maxDurationSeconds)
            video.setMaxDurationSeconds(parseInt(value.maxDurationSeconds))
          if (value.maxRecCount)
            video.setMaxRecCount(parseInt(value.maxRecCount))
          qt.setVideo(video)
          break
      }

      req.setQuestionTemplate(qt)

      return callBizHuttBffService(
        'updateRecInterviewQuestionTemplate',
        [req, metadata],
        loggedInStaff?.guid
      )
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestUpdateRecInterviewQuestionTemplate }
}
